import React from 'react';
import {observer} from 'mobx-react-lite';
import {StatusBar} from 'react-native';
import {useRoot} from '../Root/hooks';

import {useTheme} from '../styling';

export default observer(() => {
  const {appearance} = useRoot();
  const theme = useTheme();
  return (
    <StatusBar
      backgroundColor={theme.palette.bar}
      barStyle={appearance.isDark ? 'light-content' : 'dark-content'}
    />
  );
});
