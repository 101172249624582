import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import {Text, View} from 'react-native';
import AddUserSvg from '../assets/svg/circled/addUser.svg';
import ActionModal from '../modals/ActionModal';
import WarningSvg from '../assets/svg/circled/warning.svg';
import {PageScrollView} from '../containers';
import {ButtonColor, ButtonVariant} from '../components';
import {useStyles, useTheme} from '../styling';
import {sized} from '../Svg';
import ProfileSvg from '../assets/svg/colorless/profileCircle.svg';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';
import {Hoverable} from 'react-native-web-hooks';
import {useIsDesktop} from '../DashboardScreen/hooks/useGetItemLayout';
import {Particles} from './Particles';
import {SignUpBody} from './SignUpBody';
import {SignUpScreenProps} from './types';

export default observer((props: SignUpScreenProps) => {
  const {
    onLogoPress,
    description,
    onSignUpPress,
    showError,
    error,
    openTermsOfUse,
    openPrivacyPolicy,
    hasEmptyFarm,
    showRegisterModal,
    hideRegisterModal,
    registerModalShown,
    registerNewFarm,
  } = props;
  const styles = useStyles(
    (theme) =>
      ({
        root: {
          backgroundColor: theme.palette.primary,
          position: 'relative',
        },
        container: {
          justifyContent: 'center',
          maxWidth: 700,
          ...theme.mediaQuery({
            1200: {
              height: 800,
              justifyContent: 'center',
              marginVertical: 40,
            },
          }),
        },
        title: {
          ...theme.fontByWeight('700'),
          marginTop: 20,
          marginBottom: 15,
          fontSize: 32,
          lineHeight: 42,
          letterSpacing: 0.091,
          color: theme.palette.textPrimary,
          textAlign: 'center',
        },
        description: {
          ...theme.fontByWeight(),
          fontSize: 18,
          lineHeight: 28,
          letterSpacing: 0.04,
          color: theme.chroma(theme.palette.icon).alpha(0.4).hex(),
          textAlign: 'center',
        },
        footer: {
          marginTop: 15,
          marginHorizontal: 20,
        },
        footerText: {
          ...theme.fontByWeight(),
          fontSize: 12,
          lineHeight: 14,
          letterSpacing: 0.131701,
          color: '#ffffff',
          textAlign: 'center',
          opacity: 0.6,
        },
        link: {
          ...theme.fontByWeight('700'),
          color: '#ffffff',
          opacity: 0.6,
        },
        hoveredlink: {
          opacity: 1,
        },
      } as const),
  );
  const strings = useStrings();
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  return (
    <>
      <DesktopWebAppHeader onlyLogo onLogoPress={onLogoPress} />
      <PageScrollView
        contentContainerStyle={styles.container}
        style={styles.root}>
        <SignUpBody
          onPress={onSignUpPress}
          Icon={<AddUserIcon color={theme.select('#494949', '#d1d2d6')} />}
          BtnIcon={<ProfileIcon />}
          btnText={strings['signUp.button']}
          error={error}
          showError={showError}>
          <Text style={styles.title}>{strings['signUp.title']}</Text>
          <Text style={styles.description}>{description}</Text>
        </SignUpBody>
        <View style={styles.footer}>
          <Text style={styles.footerText}>
            {strings['common.bySigningUpYouAgree']}{' '}
            <Hoverable>
              {(isHovered) => (
                <Text
                  onPress={openTermsOfUse}
                  style={[styles.link, isHovered && styles.hoveredlink]}>
                  {strings['common.withTermsOfUse']}
                </Text>
              )}
            </Hoverable>{' '}
            {strings['common.and']}{' '}
            <Hoverable>
              {(isHovered) => (
                <Text
                  onPress={openPrivacyPolicy}
                  style={[styles.link, isHovered && styles.hoveredlink]}>
                  {strings['common.withPrivacyPolicy']}
                </Text>
              )}
            </Hoverable>
          </Text>
          {!hasEmptyFarm && (
            <Text style={styles.footerText}>
              <Text onPress={showRegisterModal} style={styles.link}>
                {strings['signUp.newRegister']}
              </Text>
            </Text>
          )}
          {registerModalShown && (
            <ActionModal
              onDismiss={hideRegisterModal}
              buttons={[
                {
                  text: strings['action.ok'],
                  onPress: registerNewFarm,
                  color: ButtonColor.Default,
                  variant: ButtonVariant.Highlighted,
                },
              ]}
              title={strings['signUp.newRegister']}
              Icon={WarningSvg}>
              {strings['signUp.newRegisterWarning']}
            </ActionModal>
          )}
        </View>
        {isDesktop && <Particles />}
      </PageScrollView>
    </>
  );
});

const ProfileIcon = sized(ProfileSvg, 22, 20);
const AddUserIcon = sized(AddUserSvg, 80);
