import {batchDisposers, Service} from '../structure';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import {computed, observable, makeObservable, runInAction, action} from 'mobx';
import {bind} from '../fp';
import {InAppOffers, PaymentMethod} from '../InAppOffersService';
import {PurchaseId} from '../units';
import {first} from 'lodash';
import {ProposalsState, SETTLED} from '../ProposalsState';
import {RenewAccessScreenState} from './RenewAccessScreenState';

export default abstract class BaseRenewAccessStateImpl
  implements RenewAccessScreenState, Service
{
  @observable private _selectedPurchaseId?: PurchaseId;

  protected constructor(
    protected readonly _root: {
      readonly dashboardStore: DashboardStore;
      readonly proposalsState: ProposalsState;
      readonly inAppOffers: InAppOffers;
    },
    readonly workerId: string,
  ) {
    makeObservable(this);
  }
  abstract selectPaymentMethod(method: PaymentMethod): void;
  abstract get selectedPaymentMethod(): PaymentMethod;
  get selectedPurchaseId() {
    return this._selectedPurchaseId;
  }

  @computed
  get worker() {
    return this._root.dashboardStore.workers.get(this.workerId);
  }

  @computed
  get selectedProposal() {
    if (this._selectedPurchaseId) {
      return this._root.proposalsState.minerProductProposalById?.get(
        this._selectedPurchaseId,
      );
    }
    return undefined;
  }

  get uniqProductIntervalList() {
    return this._root.proposalsState.uniqProductIntervalList;
  }

  @computed
  get isLoading() {
    return (
      this._root.dashboardStore.isLoading || this._root.inAppOffers.isLoading
    );
  }

  selectProposal = bind((id: PurchaseId) => {
    runInAction(() => {
      this._selectedPurchaseId = id;
    });
  }, this);

  @action.bound
  private _selectDefaultPlan() {
    const hashrate = this.worker?.pool_miner_config?.hash_rate;
    if (this.worker && hashrate !== undefined) {
      const proposalByInterval =
        this._root.proposalsState.doubleProposalByIntervalByHashrate.get(
          hashrate,
        );
      if (proposalByInterval === undefined) {
        return;
      }
      const availableList = [...proposalByInterval.values()].flatMap((_) =>
        _.product?.available ? [_.product] : [],
      );
      const firstAvailable = first(availableList);
      if (firstAvailable) {
        this._selectedPurchaseId = firstAvailable.offer.purchaseId;
      }
    }
  }

  private _selectDefaultPlanOnLoaded() {
    if (this._root.proposalsState.isLoadedIn) {
      this._selectDefaultPlan();
    }
    return this._root.proposalsState.events.listen(SETTLED, () => {
      this._selectDefaultPlan();
    });
  }

  subscribe() {
    return batchDisposers(this._selectDefaultPlanOnLoaded());
  }
}
