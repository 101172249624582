import HeaderBackButton from '../../components/HeaderBackButton';
import React from 'react';
import {
  StackNavigationOptions,
  StackScreenProps,
} from '@react-navigation/stack';
import {RootParamList} from './RootStack';
import {Theme} from '../../styling';
import HeaderTitle from '../../components/HeaderTitle';
import {FullLogo} from '../../components';
import {Platform} from 'react-native';
import {HeaderTitleProps} from '@react-navigation/elements';
import {ParamListBase} from '@react-navigation/native';

type RouteName = keyof RootParamList;

export default (
  stackProps: StackScreenProps<ParamListBase>,
  theme: Theme,
  headerHeight: number,
): StackNavigationOptions => {
  const headerShown =
    Platform.OS !== 'web' ||
    (Platform.OS === 'web' && theme.window.width <= 1280);
  const routeName = stackProps.route.name as RouteName;
  return {
    headerTitleAlign: 'center',
    headerStyle: {
      height: headerHeight,
    },
    headerShown: headerShown,
    headerBackTitleVisible: false,
    headerTransparent: false,
    headerLeft: ({canGoBack, onPress}) =>
      canGoBack && <HeaderBackButton onPress={onPress} />,
    headerLeftContainerStyle: {
      paddingHorizontal: 10,
    },
    headerRightContainerStyle: {
      paddingHorizontal: 10,
    },
    headerTitle: (props) => getHeaderTitle(props, routeName),
    cardStyle: {
      flex: 1,
    },
  };
};

export function getHeaderTitle(props: HeaderTitleProps, routeName: RouteName) {
  switch (routeName) {
    case 'Plan':
    case 'Auth':
    case 'PaymentSuccess':
    case 'AccountSwitch':
    case 'AccessRecovery':
      return <FullLogo />;
    default:
      return <HeaderTitle title={props.children} />;
  }
}
