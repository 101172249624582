import {
  Interval,
  IntervalType,
  Plan,
  PlanId,
  PoolMinerConfig,
  SlotCount,
  TariffName,
} from '../../ApiStore';
import {PurchaseId} from '../../units';
import {Locale} from '../../Preferences';
import {Second} from '../../Time';
import {Opaque} from 'type-fest';

export type PaymentMethodMap = ReadonlyMap<
  PaymentMethod,
  {
    price: number;
    uiPrice: string;
    currency: string;
    oldPrice: number | undefined;
    uiOldPrice: string | undefined;
    discounts: OfferDiscount[];

    pricePerMonth: number;
    uiPricePerMonth: string;
    discountsDifferencePrice: number | undefined;
    uiDiscountsDifferencePrice: string | undefined;
    pricePerMonthPerHashrate: number | undefined;
    uiPricePerMonthPerHashrate: string | undefined;
  }
>;

export enum PaymentMethod {
  InApp,
  SOL,
  Matic,
  USDT,
  ETH,
  BTC,
  CT,
  Card,
  TRX,
  BNB,
}

export type OfferDiscount = {
  title: Record<Locale, string>;
  percent: number;
  fromPrice: number;
  uiFromPrice: string;
  toPrice: number;
  uiToPrice: string;
  difference: number;
  uiDifference: string;
};

type BaseOffer = {
  payments: PaymentMethodMap;
  id: PlanId;
  offerId: OfferId;
  purchaseId: PurchaseId;
  interval: Interval;
  priority: number;
  kind: PurchaseKind;
  tariff: TariffName;
  subscriptionOfferTokenAndroid: string | undefined;
};

type BaseMinerOffer = BaseOffer & {
  type: OfferType.Miner;
  purchaseType:
    | OfferPurchaseType.MinerProduct
    | OfferPurchaseType.MinerSubscription
    | OfferPurchaseType.TrialMinerSubscription;
  poolMinerConfig: PoolMinerConfig;
};

type BaseActivatorOffer = BaseOffer & {
  type: OfferType.Activator;
  purchaseType: OfferPurchaseType.ActivatorSubscription;
  options: {
    free_pm: boolean;
    activate_pm_after: Second;
  };
};

const OFFER_ID = Symbol();
export type OfferId = Opaque<string, typeof OFFER_ID>;

export type SlotSubscriptionOffer = BaseOffer & {
  type: OfferType.Slot;
  maxSlots: SlotCount;
  purchaseType: OfferPurchaseType.SlotSubscription;
  pricePerSlotPerMonth: number;
  uiPricePerSlotPerMonth: string;
  bought: boolean;
  trial: Trial | null;
};

export type MinerProductOffer = BaseMinerOffer & {
  purchaseType: OfferPurchaseType.MinerProduct;
};

export type TrialPeriodUnit = 'D' | 'W' | 'M' | 'Y';

export type Trial = {
  value: number;
  unit: TrialPeriodUnit;
};

export type TrialMinerSubscriptionOffer = BaseMinerOffer & {
  purchaseType: OfferPurchaseType.TrialMinerSubscription;
  bought: boolean;
  intervalType: IntervalType;
  trial: Trial | null;
};

export type MinerSubscriptionOffer = BaseMinerOffer & {
  purchaseType: OfferPurchaseType.MinerSubscription;
  bought: boolean;
  trial: Trial | null;
};

export type ActivatorSubscriptionOffer = BaseActivatorOffer & {
  purchaseType: OfferPurchaseType.ActivatorSubscription;
  bought: boolean;
  trial: Trial | null;
};

export type GiftType = 'regular' | 'double' | null;

export type Gift = {
  plan: Plan | null;
  pool_miner_config: PoolMinerConfig | null;
  type: GiftType;
};

export type MinerOffer =
  | MinerProductOffer
  | MinerSubscriptionOffer
  | TrialMinerSubscriptionOffer;
export type ActivatorOffer = ActivatorSubscriptionOffer;

export type Offer = MinerOffer | SlotSubscriptionOffer | ActivatorOffer;

export enum PurchaseKind {
  Subscription,
  Product,
}

export enum OfferPurchaseType {
  MinerSubscription,
  MinerProduct,
  SlotSubscription,
  ActivatorSubscription,
  TrialMinerSubscription,
}

export enum OfferType {
  Miner,
  Slot,
  Activator,
}
