import React from 'react';
import NoDataSvg from '../assets/svg/circled/noData.svg';
import {Text, View, ViewProps} from 'react-native';
import {useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../styling';

interface NoDataProps extends ViewProps {}

const NoData = observer(({style, ...rest}: NoDataProps) => {
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    root: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    noDataText: {
      marginTop: 20,
      ...theme.fontByWeight('bold'),
      fontSize: 22,
      lineHeight: 30,
      color: theme.palette.textPrimary,
    },
  }));
  return (
    <View style={[styles.root, style]} {...rest}>
      <NoDataSvg />
      <Text style={styles.noDataText}>{strings['common.noData']}</Text>
    </View>
  );
});

export default NoData;

export const ScreenFluidNoData = observer(() => {
  const styles = useStyles(() => ({
    root: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));
  return (
    <View style={styles.root}>
      <NoData />
    </View>
  );
});
