export default (
  lower: number,
  upper: number,
  fractionDigits: number,
  maxPoints: number,
) => {
  const lowerFixed = roundFixed(lower, fractionDigits);
  const upperFixed = roundFixed(upper, fractionDigits);
  const delta = Math.abs(upperFixed - lowerFixed);
  const minDelta = 10 ** -fractionDigits;
  return Math.min(Math.round(delta / minDelta) + 1, maxPoints);
};

const roundFixed = (target: number, digit: number) =>
  Math.round(target * 10 ** digit) / 10 ** digit;
