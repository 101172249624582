import {Bound, Either} from '../fp';
import {PuzzleState} from './PuzzleState';
import {CommonError} from '../ApiStore';
import {DefaultError} from '../JsonRpc';

export enum ActivatorStatus {
  Idle,
  NeedActivate,
  Dismissed,
}

export enum SliderStatus {
  Idle,
  Swiping,
  Error,
  Confirming,
}

export interface Position {
  x: number;
  y: number;
}

export interface PoolMinerActivator {
  readonly activatorStatus: ActivatorStatus;
  readonly isVisible: boolean;
  setStatus: (status: ActivatorStatus) => void;
  dismiss: Bound<() => void, PoolMinerActivator>;
  confirm: () => Promise<Either<void, CommonError | DefaultError>>;
  reset: Bound<() => void, PoolMinerActivator>;
  readonly puzzle: PuzzleState;
}
