import {useStrings} from '../../Root/hooks';
import {getFocusedRouteNameFromRoute} from '@react-navigation/native';
import React from 'react';
import {
  StackNavigationOptions,
  StackNavigationProp,
  StackScreenProps,
} from '@react-navigation/stack';
import {RootParamList} from '../RootStack';
import {BottomTabParamList} from './BottomTab';
import HeaderTitle from '../../components/HeaderTitle';
import HeaderActionButton from '../../components/HeaderActionButton';
import SettingsSvg from '../../assets/svg/colorless/settings.svg';
import {sized} from '../../Svg';
import {Theme} from '../../styling';
import {FullLogo, FanSquareAppLogo} from '../../components';
import {LocaleDict} from '../../translation/LocaleStrings';
import {HeaderTitleProps} from '@react-navigation/elements';

const SettingsIcon = sized(SettingsSvg, 18);

type RouteName = keyof BottomTabParamList;

export default (
  stackProps: StackScreenProps<RootParamList>,
  theme: Theme,
): StackNavigationOptions => {
  const strings = useStrings();
  const routeName =
    (getFocusedRouteNameFromRoute(stackProps.route) as RouteName) ??
    'Dashboard';
  return {
    title: getTitle({routeName, strings}),
    headerTitleAlign: 'center',
    headerTitle: (props: HeaderTitleProps) => getHeaderTitle(props),
    headerLeft: () => getHeaderLeft({routeName}),
    headerRight: () => getHeaderRight(stackProps.navigation, routeName, theme),
    headerRightContainerStyle: {
      paddingHorizontal: 10,
    },
    headerLeftContainerStyle: {
      paddingHorizontal: 10,
    },
  };
};

interface BaseProps {
  routeName: RouteName;
  strings?: LocaleDict;
}

interface GetHeaderTitleProps extends BaseProps {
  strings: LocaleDict;
}

function getHeaderLeft({routeName}: BaseProps) {
  switch (routeName) {
    case 'Menu':
    case 'Debug':
      return null;
  }
  return <FanSquareAppLogo />;
}

export function getTitle({routeName, strings}: GetHeaderTitleProps) {
  switch (routeName) {
    case 'Dashboard':
      return strings['screenTitle.dashboard'];
    case 'Statistics':
      return strings['screenTitle.statistics'];
    case 'Notifications':
      return strings['screenTitle.notifications'];
    case 'Withdraw':
      return strings['withdraw.title'];
  }
}

export function getHeaderTitle(props: HeaderTitleProps) {
  switch (props.children) {
    case 'Root':
      return <FullLogo />;
  }
  return <HeaderTitle title={props.children} />;
}

function getHeaderRight(
  navigation: StackNavigationProp<RootParamList>,
  routeName: RouteName,
  theme: Theme,
) {
  switch (routeName) {
    case 'Dashboard':
      return (
        <HeaderActionButton
          onPress={() => navigation.navigate('DashboardSettings')}>
          <SettingsIcon color={theme.palette.icon} />
        </HeaderActionButton>
      );
    case 'Notifications':
      return (
        <HeaderActionButton
          onPress={() => navigation.navigate('NotificationsSettings')}>
          <SettingsIcon color={theme.palette.icon} />
        </HeaderActionButton>
      );
  }
  return null;
}
