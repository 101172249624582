import {observer} from 'mobx-react-lite';
import {Button, ButtonVariant, ScreenCard} from '../components';
import {useStrings} from '../Root/hooks';
import React, {useMemo} from 'react';
import {PageScrollView} from '../containers';
import {createStylesHook} from '../styling';
import {Platform, TouchableWithoutFeedback} from 'react-native';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';

export interface AuthRequestScreenProps {
  goToAuth: () => void;
}

export default observer((props: AuthRequestScreenProps) => {
  const {goToAuth} = props;
  const styles = useStyles();
  const strings = useStrings();
  const footer = useMemo(
    () => (
      <TouchableWithoutFeedback onPress={goToAuth}>
        <Button
          style={styles.button}
          textStyle={styles.buttonText}
          variant={ButtonVariant.Highlighted}>
          {strings['withdraw.authenticationRequired.authorize']}
        </Button>
      </TouchableWithoutFeedback>
    ),
    [goToAuth, strings, styles],
  );
  return (
    <>
      <DesktopWebAppHeader />
      <PageScrollView
        style={styles.root}
        contentContainerStyle={styles.container}>
        <ScreenCard
          title={strings['withdraw.authenticationRequired.title']}
          description={strings['withdraw.authenticationRequired.description']}
          footer={footer}
        />
      </PageScrollView>
    </>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    flex: 1,
  },
  container: {
    padding: 20,
    justifyContent: 'center',
    ...Platform.select({
      web: {
        ...theme.mediaQuery({
          1200: {
            paddingVertical: 150,
          },
        }),
      },
    }),
  },
  button: {
    marginTop: 15,
  },
  buttonText: {
    fontSize: 13,
    lineHeight: 15,
  },
}));
