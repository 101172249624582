import BasePurchaseRequesterImpl from './BasePurchaseRequesterImpl';
import {InAppPurchaseManager, PurchaseResult} from '../InAppPurchaseManager';
import {ApiStore} from '../ApiStore';
import {InAppOffers} from '../InAppOffersService';
import {ErrorRepository} from '../ErrorRepository';
import {PendingPurchasesResolver} from '../PendingPurchasesResolver';
import {PurchaseDiscount} from '../PurchasePromoService';

export default class SubPurchaseRequesterImpl extends BasePurchaseRequesterImpl {
  constructor(
    protected readonly _root: {
      readonly apiStore: ApiStore;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly pendingPurchasesResolver: PendingPurchasesResolver;
      readonly errorRepository: ErrorRepository;
    },
  ) {
    super(_root);
  }

  protected requestProduct(): Promise<PurchaseResult> {
    throw 'Not implemented on this platform';
  }

  protected requestSubscription(): Promise<PurchaseResult> {
    throw 'Not implemented on this platform';
  }
}
