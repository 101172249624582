import {
  InAppPurchaseManager,
  ProductStorePurchase,
  Purchase,
  SubscriptionStorePurchase,
} from './InAppPurchaseManager';
import {ApiStore, AppStoreReceipt, RestoreResult} from '../ApiStore';
import {PurchaseId} from '../units';
import dayjs from 'dayjs';
import {BaseInAppPurchaseManagerImpl} from './BaseInAppPurchaseManagerImpl';
import {Either, error, success} from '../fp';
import {Op} from '../Math';
import {Configuration} from '../Configuration';
import {Millisecond} from '../Time';
import {InAppPurchase} from '../InAppPurchase';
import {ErrorRepository} from '../ErrorRepository';
import {ConnectedClient} from '../ContextClient';
import {Auth} from '../Auth';
import {PurchaseDiscount} from '../PurchasePromoService';
import {GlobalError, SERVER_ERROR} from '../Error';
import {SubscriptionIOS, SubscriptionPlatform} from 'react-native-iap';
import parseIosTrialPeriod from './parseIosTrialPeriod';

type Sub = SubscriptionIOS;

export default class IosInAppPurchaseManagerImpl
  extends BaseInAppPurchaseManagerImpl
  implements InAppPurchaseManager
{
  constructor(
    protected readonly _root: {
      readonly auth: Auth;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly apiStore: ApiStore;
      readonly inAppPurchase: InAppPurchase;
      readonly configuration: Configuration;
      readonly connectedClient: ConnectedClient;
      readonly errorRepository: ErrorRepository;
    },
  ) {
    super(_root);
  }

  async getAvailablePurchases(): Promise<Either<Purchase[], GlobalError>> {
    const receipt_ = await this._getReceipt();
    if (!receipt_.success || receipt_.right === undefined) {
      return success([]);
    }
    const timeout = Op.multiply(this._root.configuration.values.bffTimeout, 3);
    const verify_receipt_ = await this._root.apiStore.client.apply(
      'verify_receipt',
      {receipt: receipt_.right},
      {timeout},
    );
    if (!verify_receipt_.success) {
      return success([]);
    }
    return success(
      verify_receipt_.right.transactions.map((_) => ({
        purchaseId: _.product_id as PurchaseId,
      })),
    );
  }

  private static readonly _unitMap = {
    YEAR: 'year',
    MONTH: 'month',
    WEEK: 'week',
    DAY: 'day',
  } as const;

  private _getSubscriptionDuration(_: Sub) {
    if (!_.subscriptionPeriodNumberIOS || !_.subscriptionPeriodUnitIOS) {
      return undefined;
    }
    return dayjs.duration(
      parseInt(_.subscriptionPeriodNumberIOS, 10),
      IosInAppPurchaseManagerImpl._unitMap[_.subscriptionPeriodUnitIOS],
    );
  }

  async getSubscriptions(
    productIds: PurchaseId[],
  ): Promise<Either<SubscriptionStorePurchase[], GlobalError>> {
    try {
      const subs = await this._root.inAppPurchase.getSubscriptions(productIds);
      return success(
        subs.flatMap((_) => {
          const trial = parseIosTrialPeriod(_);
          return _.platform === SubscriptionPlatform.ios
            ? [
                {
                  purchaseId: _.productId as PurchaseId,
                  price: Number(_.price),
                  subscriptionOfferTokenAndroid: undefined,
                  currency: _.currency,
                  period: this._getSubscriptionDuration(_),
                  trial,
                },
              ]
            : [];
        }),
      );
    } catch (raw) {
      return error(this.createIapError(raw));
    }
  }

  async getProducts(
    purchaseIds: PurchaseId[],
  ): Promise<Either<ProductStorePurchase[], GlobalError>> {
    try {
      const products = await this._root.inAppPurchase.getProducts(purchaseIds);
      return success(
        products.map((_) => ({
          purchaseId: _.productId as PurchaseId,
          price: Number(_.price),
          currency: _.currency,
          title: _.title,
          description: _.description,
        })),
      );
    } catch (raw) {
      return error(this.createIapError(raw));
    }
  }

  async authByPurchaseHistory(): Promise<Either<RestoreResult[], GlobalError>> {
    const receipt_ = await this._getReceipt();
    if (!receipt_.success) {
      return receipt_;
    }
    const response = await this._root.apiStore.client.apply(
      'auth_by_app_store_purchases',
      {receipt: receipt_.right || ('' as AppStoreReceipt)},
      {timeout: Infinity as Millisecond},
    );
    if (!response.success) {
      return error(
        this._root.errorRepository.create({
          kind: SERVER_ERROR,
          raw: response.left,
        }),
      );
    }
    return response;
  }

  private async _getReceipt(): Promise<
    Either<AppStoreReceipt | undefined, GlobalError>
  > {
    try {
      const response = await this._root.inAppPurchase.getReceiptIOS();
      return success(response as AppStoreReceipt);
    } catch (raw) {
      return error(this.createIapError(raw));
    }
  }
}
