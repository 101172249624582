import {MAX_CORES} from './constants';
import {CpuState} from '../ApiStore';

const generateCoreState = (): CpuState => {
  const isHot = Math.random() <= 0.01;
  return {
    temperature: isHot ? 85 + Math.random() * 5 : 60 + Math.random() * 10,
    hot: isHot,
  };
};

export default (_cores: number): CpuState[] => {
  const cores = _cores > MAX_CORES ? MAX_CORES : _cores;
  const result = [];
  for (let i = 0; i < cores; i++) {
    result.push(generateCoreState());
  }
  return result;
};
