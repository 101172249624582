import React from 'react';
import {View, Text, ViewProps} from 'react-native';
import {observer} from 'mobx-react-lite';
import {createStylesHook} from '../styling';
import AddUserSvg from '../assets/svg/circled/addUser.svg';

export interface ScreenPanelProps extends ViewProps {
  title: string;
  description: string;
  footer?: React.ReactNode;
}

export default observer((props: ScreenPanelProps) => {
  const {title, description, footer, style, ...rest} = props;
  const styles = useStyles();
  return (
    <View style={[styles.root, style]} {...rest}>
      <View style={styles.body}>
        <AddUserSvg style={styles.icon} />
        {title !== undefined && <Text style={styles.title}>{title}</Text>}
        {description !== undefined && (
          <Text style={styles.bodyText}>{description}</Text>
        )}
      </View>
      <View style={styles.footer}>{footer}</View>
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    borderRadius: 4,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: theme.palette.border,
    ...theme.mediaQuery({
      1281: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
  },
  body: {
    backgroundColor: theme.palette.background,
    alignItems: 'center',
    paddingVertical: 40,
    paddingHorizontal: 30,
  },
  icon: {
    marginBottom: 20,
    color: theme.palette.textPrimary,
  },
  title: {
    ...theme.fontByWeight('700'),
    textAlign: 'center',
    fontSize: 22,
    lineHeight: 32,
    marginBottom: 20,
    color: theme.palette.textPrimary,
    ...theme.mediaQuery({
      1281: {
        fontSize: 32,
        lineHeight: 40,
      },
    }),
  },
  bodyText: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    lineHeight: 22,
    letterSpacing: 0.04,
    color: theme.palette.textPrimary,
    textAlign: 'center',
    ...theme.mediaQuery({
      1281: {
        fontSize: 16,
        lineHeight: 26,
      },
    }),
  },
  footer: {
    backgroundColor: theme.palette.backdrop,
    padding: 15,
    borderTopWidth: 1,
    borderTopColor: theme.palette.border,
  },
}));
