import {View} from 'react-native';
import {ReadonlyDeep} from 'type-fest';
import {WorkerGroupEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo} from 'react';
import {useRoot} from '../../../../Root/hooks';
import {useNavigation, useRoute} from '@react-navigation/native';
import getWorkerOrder from '../../../getters/getWorkerOrder';
import {merge} from 'lodash';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  PressableOpacity,
} from '../../../../components';
import GroupStats from './GroupStats';
import {HeaderFilter} from '../../HeaderFilter';
import GroupTotalSpeed from './GroupTotalSpeed';
import GroupTotalMined from './GroupTotalMined';
import GroupHeaderSetupButton from './GroupHeaderSetupButton';
import GroupHeaderView from './GroupHeaderView';
import {createStylesHook, useTheme} from '../../../../styling';
import {
  GROUP_HEADER_HEIGHT,
  DESKTOP_GROUP_HEADER_HEIGHT,
  EMPTY_GROUP_HEADER_HEIGHT,
} from '../../../constants';
import {DashboardScreenProps} from '../../../DashboardScreenProps';
import {sized} from '../../../../Svg';
import ReloadSvg from '../../../../assets/svg/colorless/reload.svg';
import {WorkerOrder} from '../../../types';
import {setSortingParams} from '../../groupSortingParams';
import {expr} from 'mobx-utils';
import getTotalOnlineWorkers from '../../../getters/getTotalOnlineWorkers';
import DesktopGroupStats from './DesktopGroupStats';
import {DESKTOP_BREAKPOINT} from '../../../../units/constants';

export interface GroupHeaderProps {
  group: ReadonlyDeep<WorkerGroupEntity>;
  isDefaultGroup: boolean;
}

export default observer((props: GroupHeaderProps) => {
  const {group, isDefaultGroup} = props;
  const styles = useStyles();
  const {workerStateRegistry, dashboardStore, auth} = useRoot();
  const {workers} = group;
  const hashrate = useCallback(
    () => <GroupTotalSpeed workers={workers} />,
    [workers],
  );
  const mined = useCallback(
    () => <GroupTotalMined workers={workers} />,
    [workers],
  );
  const navigation = useNavigation<DashboardScreenProps['navigation']>();
  const route = useRoute<DashboardScreenProps['route']>();
  const groupId = group.id;
  const workerOrder = getWorkerOrder(route.params, groupId);
  const theme = useTheme();
  const onOrderChange = useCallback(
    async (nextWorkerOrder: WorkerOrder) => {
      const {params} = route;
      const patch: typeof params = {
        groupOptionsById: {
          [groupId]: {workerOrder: nextWorkerOrder},
        },
      };
      const next: typeof params = {};
      merge(next, params, patch);
      navigation.setParams(next);
      await setSortingParams({dashboard: next, farmId: auth.accountId});
    },
    [auth.accountId, groupId, navigation, route],
  );

  const buttons = useMemo(
    () => (
      <>
        <PressableOpacity
          disabled={dashboardStore.isLoading}
          onPress={dashboardStore.fetch}
          style={styles.reloadButtonWrapper}>
          <Button
            style={styles.reloadButton}
            variant={ButtonVariant.Text}
            color={ButtonColor.Primary}
            Icon={ReloadIcon}
          />
        </PressableOpacity>
        <GroupHeaderSetupButton group={group} isDefaultGroup={isDefaultGroup} />
      </>
    ),
    [
      dashboardStore,
      group,
      isDefaultGroup,
      styles.reloadButton,
      styles.reloadButtonWrapper,
    ],
  );

  const onlineWorkerCount = expr(() =>
    getTotalOnlineWorkers(workers, workerStateRegistry),
  );

  const footer = useMemo(
    () =>
      theme.window.width >= DESKTOP_BREAKPOINT ? (
        <DesktopGroupStats
          hashrate={hashrate}
          mined={mined}
          order={workerOrder}
          onOrderChange={onOrderChange}
          isEmptyGroup={workers.length === 0}
        />
      ) : (
        <View>
          <GroupStats hashrate={hashrate} mined={mined} />
          <HeaderFilter
            order={workerOrder}
            onOrderChange={onOrderChange}
            isEmptyGroup={workers.length === 0}
          />
        </View>
      ),
    [hashrate, mined, onOrderChange, workerOrder, theme, workers],
  );

  return (
    <GroupHeaderView
      name={group.name}
      slots={onlineWorkerCount}
      maxSlots={group.workers.length}
      buttons={buttons}
      footer={footer}
      style={[styles.root, isDefaultGroup && styles.defaultRoot]}
    />
  );
});

const ReloadIcon = sized(ReloadSvg, 20);

export const useStyles = createStylesHook((theme) => ({
  root: {
    height: GROUP_HEADER_HEIGHT,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        height: DESKTOP_GROUP_HEADER_HEIGHT,
      },
    }),
  },
  defaultRoot: {
    marginTop: 0,
  },
  rootEmpty: {
    height: EMPTY_GROUP_HEADER_HEIGHT,
  },
  reloadButtonWrapper: {
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        marginLeft: 10,
      },
    }),
  },
  reloadButton: {
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
}));
