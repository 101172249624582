import React, {PropsWithChildren} from 'react';
import BaseModal, {ModalProps} from './BaseModal';
import {ModalDescription, ModalTitle} from './components';
import {SvgProps} from 'react-native-svg';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import {createStylesHook} from '../styling';

export interface ActionModalProps extends ModalProps {
  Icon?: React.ComponentType<SvgProps>;
  title?: string;
  onDismiss?: () => void;
}

export default observer((props: PropsWithChildren<ActionModalProps>) => {
  const {Icon, title, children, ...rest} = props;
  const styles = useStyles();
  const renderDescription = () => {
    if (!children) {
      return null;
    }
    if (typeof children === 'string') {
      return (
        <ModalDescription style={!title && styles.description}>
          {children}
        </ModalDescription>
      );
    }
    return children;
  };
  return (
    <BaseModal {...rest}>
      <View style={styles.content}>
        {Icon && (
          <View style={styles.iconView}>
            <Icon />
          </View>
        )}
        {title && <ModalTitle style={styles.title}>{title}</ModalTitle>}
        {renderDescription()}
      </View>
    </BaseModal>
  );
});

const useStyles = createStylesHook((theme) => ({
  content: {
    alignItems: 'center',
    ...theme.mediaQuery({
      1000: {
        paddingVertical: 10,
      },
    }),
  },
  iconView: {
    paddingTop: 15,
  },
  title: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 10,
    ...theme.mediaQuery({
      1000: {
        marginBottom: 20,
      },
    }),
  },
  description: {
    marginTop: 20,
  },
}));
