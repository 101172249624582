import React from 'react';
import {View, Text} from 'react-native';

import {observer} from 'mobx-react-lite';
import {useStyles} from '../../../../../styling';
import {useStrings} from '../../../../../Root/hooks';
import {DESKTOP_BREAKPOINT} from '../../../../../units/constants';

interface CoreCellProps {
  temperature: number | string | (() => React.ReactNode);
  warning?: boolean;
  disabled?: boolean;
  coreNumber: number;
  isShort?: boolean;
}

export const CoreCell = observer(
  ({
    temperature,
    warning,
    disabled,
    coreNumber,
    isShort = false,
  }: CoreCellProps) => {
    const strings = useStrings();
    const styles = useStyles((theme) => ({
      root: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
        height: 40,
        zIndex: 1,
        flexGrow: 1,
        flexBasis: 50,
        borderWidth: 1,
        borderColor: theme.palette.border,
        margin: -1,
        ...theme.mediaQuery({
          [DESKTOP_BREAKPOINT]: {
            height: 'auto',
            flexBasis: '50%',
          },
        }),
      },
      rootWarning: {
        zIndex: 2,
        margin: -1,
        borderColor: theme.palette.error,
      },
      content: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
      shortCoreNubmer: {
        display: 'none',
        ...theme.mediaQuery({
          [DESKTOP_BREAKPOINT]: {
            display: 'flex',
            ...theme.fontByWeight('400'),
            fontSize: 9,
            color: theme.palette.darkText,
          },
        }),
      },
      coreNumber: {
        display: 'none',
        ...theme.mediaQuery({
          [DESKTOP_BREAKPOINT]: {
            display: 'flex',
            position: 'absolute',
            left: 5,
            top: 5,
            ...theme.fontByWeight('400'),
            fontSize: 8,
            color: theme.palette.darkText,
          },
        }),
      },
      temperature: {
        fontSize: 16,
        letterSpacing: 0.05,
        textAlign: 'right',
        color: theme.palette.textPrimary,
      },
      temperatureDisabled: {
        color: theme.palette.textSecondary,
      },
      temperatureWarning: {
        color: theme.palette.error,
      },
      temperatureValue: {
        ...theme.fontByWeight('700'),
      },
      temperatureUnit: {
        ...theme.fontByWeight('400'),
      },
    }));
    return (
      <View style={[styles.root, warning && styles.rootWarning]}>
        {isShort ? (
          <Text style={styles.shortCoreNubmer}>
            {strings['worker.core']} {coreNumber}
          </Text>
        ) : (
          <Text style={styles.coreNumber}>{coreNumber}</Text>
        )}
        <View style={styles.content}>
          <Text
            style={[
              styles.temperature,
              warning && styles.temperatureWarning,
              disabled && styles.temperatureDisabled,
            ]}>
            <Text style={styles.temperatureValue}>
              {typeof temperature === 'function'
                ? temperature()
                : typeof temperature === 'number'
                ? temperature.toFixed(0)
                : temperature}
            </Text>
            <Text style={styles.temperatureUnit}>℃</Text>
          </Text>
        </View>
      </View>
    );
  },
);
