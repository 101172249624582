import React, {useCallback, useState} from 'react';
import {useStrings} from '../../Root/hooks';
import {useRoot} from '../../Root/hooks';
import InputModal from '../../modals/InputModal';
import {ButtonColor, ButtonVariant} from '../../components';
import {observer} from 'mobx-react-lite';
import {useHandleErrorAndNavigate} from '../../ExceptionHandler';

interface AddMiningGroupModalProps {
  onDismiss: () => void;
}

export default observer(({onDismiss}: AddMiningGroupModalProps) => {
  const [groupName, setGroupName] = useState<string>();
  const {connectedClient} = useRoot();
  const strings = useStrings();
  const handleErrorAndNavigate = useHandleErrorAndNavigate();
  const handleDismiss = useCallback(() => {
    setGroupName('');
    onDismiss();
  }, [onDismiss]);
  const onSubmit = useCallback(async () => {
    if (!groupName) {
      return;
    }
    const result = await connectedClient.call('create_group', {
      name: groupName,
      worker_ids: [],
    });
    if (!result.success) {
      handleErrorAndNavigate(result.left);
    }
    handleDismiss();
  }, [connectedClient, handleErrorAndNavigate, groupName, handleDismiss]);
  return (
    <InputModal
      inputProps={{
        autoFocus: true,
        value: groupName,
        onChangeText: setGroupName,
        placeholder: strings['modal.addMiningGroup.subtext'],
      }}
      onDismiss={handleDismiss}
      title={strings['modal.addMiningGroup.title']}
      buttons={[
        {
          text: strings['action.cancel'],
          onPress: onDismiss,
          color: ButtonColor.Default,
          variant: ButtonVariant.Contained,
        },
        {
          text: strings['action.confirm'],
          onPress: onSubmit,
          variant: ButtonVariant.Highlighted,
        },
      ]}
    />
  );
});
