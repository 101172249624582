export default <C extends {}>(a: C, b: C): [C, C] => {
  const aOnly: any = {...a};
  const bOnly: any = {};
  for (const bKey in b) {
    if (!b.hasOwnProperty(bKey)) {
      continue;
    }
    if (a.hasOwnProperty(bKey)) {
      delete aOnly[bKey];
    } else {
      bOnly[bKey] = b[bKey];
    }
  }
  return [aOnly, bOnly];
};
