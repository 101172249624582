import {Http} from './Http';
import {Platform} from 'react-native';
import AndroidHttpImpl from './AndroidHttpImpl';
import HttpImpl from './HttpImpl';

export default class HttpFactory {
  create(): Http {
    if (Platform.OS === 'android') {
      return new AndroidHttpImpl();
    }
    return new HttpImpl();
  }
}
