import React from 'react';
import {observer} from 'mobx-react-lite';
import CalendarSvg from '../assets/svg/colorless/calendar.svg';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  PressableOpacity,
} from '../components';
import {sized} from '../Svg';
import {View} from 'react-native';
import {Container} from '../components/Grid';
import WorkerSlot from './WorkerSlot';
import {useStyles} from '../styling';
import {useRoot, useStrings} from '../Root/hooks';
import {ReadonlyDeep} from 'type-fest';

export type WorkerContainerProps = {
  worker: ReadonlyDeep<WorkerEntity>;
  onSchedulePress: () => void;
  onRenewPress: () => void;
  onDeleted: () => void;
  visibleRenewButton: boolean;
};

export default observer(
  ({
    worker,
    onDeleted,
    onSchedulePress,
    onRenewPress,
    visibleRenewButton,
  }: WorkerContainerProps) => {
    const {currentSubscriptionState} = useRoot();
    const {isPlatformSupported} = currentSubscriptionState;
    const strings = useStrings();
    const styles = useStyles((theme) => ({
      root: {
        backgroundColor: theme.palette.background,
        borderWidth: 1,
        borderColor: theme.palette.border,
        borderRadius: 2,
      },
      footer: {
        paddingHorizontal: 30,
        paddingVertical: 15,
      },
      renew: {
        marginTop: 15,
      },
    }));
    return (
      <Container>
        <View style={styles.root}>
          <WorkerSlot onDeleted={onDeleted} worker={worker} />
          <View style={styles.footer}>
            <PressableOpacity
              onPress={onSchedulePress}
              disabled={!isPlatformSupported}>
              <Button
                Icon={CalendarIcon}
                color={ButtonColor.Primary}
                variant={
                  isPlatformSupported
                    ? ButtonVariant.Contained
                    : ButtonVariant.Disabled
                }>
                {strings['workerDetails.scheduleButton']}
              </Button>
            </PressableOpacity>
            {visibleRenewButton && (
              <PressableOpacity style={styles.renew} onPress={onRenewPress}>
                <Button variant={ButtonVariant.Highlighted}>
                  {strings['workerDetails.renew']}
                </Button>
              </PressableOpacity>
            )}
          </View>
        </View>
      </Container>
    );
  },
);

const CalendarIcon = sized(CalendarSvg, 15);
