import getPluralisationKey, {PluralisationKey} from './getPluralisationKey';
import {useRoot} from '../Root/hooks';
import {Locale} from '../Preferences';

export const getPluralizationDaysUnit = (daysCount: number): string => {
  const {
    translation: {strings, locale},
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useRoot();
  if (locale === Locale.English) {
    if (daysCount === 1) {
      return strings['plurals.duration.day.0'];
    } else {
      return strings['plurals.duration.day.1'];
    }
  } else {
    switch (getPluralisationKey(daysCount)) {
      case PluralisationKey.Zero:
      case PluralisationKey.Many:
        return strings['plurals.duration.day.2'];
      case PluralisationKey.One:
        return strings['plurals.duration.day.0'];
      case PluralisationKey.Few:
        return strings['plurals.duration.day.1'];
      case PluralisationKey.Other:
        return '';
    }
  }
};
export const getPluralizationHoursUnit = (hoursCount: number): string => {
  const {
    translation: {strings, locale},
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useRoot();
  if (locale === Locale.English) {
    if (hoursCount === 1) {
      return strings['plurals.duration.hour.0'];
    } else {
      return strings['plurals.duration.hour.1'];
    }
  } else {
    switch (getPluralisationKey(hoursCount)) {
      case PluralisationKey.Zero:
      case PluralisationKey.Many:
        return strings['plurals.duration.hour.2'];
      case PluralisationKey.One:
        return strings['plurals.duration.hour.0'];
      case PluralisationKey.Few:
        return strings['plurals.duration.hour.1'];
      case PluralisationKey.Other:
        return '';
    }
  }
};
