import React, {useMemo} from 'react';
import {Text, TouchableOpacity, View} from 'react-native';
import {SecondaryMenuItem} from './SecondaryMenuItem';
import {useRoot, useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';

import {Locale} from '../Preferences';

import DeFlag from '../assets/svg/flags/de.svg';
import EsFlag from '../assets/svg/flags/es.svg';
import FrFlag from '../assets/svg/flags/fr.svg';
import GbFlag from '../assets/svg/flags/gb.svg';
import ItFlag from '../assets/svg/flags/it.svg';
import PtBrFlag from '../assets/svg/flags/br.svg';
import RuFlag from '../assets/svg/flags/ru.svg';

import {
  Picker,
  PickerItem,
  RenderPickerItemProps,
  RenderSelectedTextProps,
} from '../components';
import {SvgProps} from 'react-native-svg';
import {useStyles} from '../styling';

type LocalePickerItem = {
  id: Locale;
  title: string;
  icon: (props: SvgProps) => JSX.Element;
};

interface LanguageProps {
  hideLabel?: boolean;
}

export const Language = observer(({hideLabel}: LanguageProps) => {
  const items = useMemo(
    () => [
      {
        id: Locale.English,
        title: 'English',
        shortTitle: 'EN',
        icon: GbFlag,
      },
      {
        id: Locale.French,
        title: 'French',
        shortTitle: 'FR',
        icon: FrFlag,
      },
      {
        id: Locale.German,
        title: 'German',
        shortTitle: 'DE',
        icon: DeFlag,
      },
      {
        id: Locale.Italian,
        title: 'Italian',
        shortTitle: 'IT',
        icon: ItFlag,
      },
      {
        id: Locale.PortugueseBrazil,
        title: 'Portuguese (Brazil)',
        shortTitle: 'BR',
        icon: PtBrFlag,
      },
      {
        id: Locale.Russian,
        title: 'Russian',
        shortTitle: 'RU',
        icon: RuFlag,
      },
      {
        id: Locale.Spanish,
        title: 'Spanish',
        shortTitle: 'ES',
        icon: EsFlag,
      },
    ],
    [],
  );

  const {translation, preferences} = useRoot();
  const strings = useStrings();
  const pickedValue = items.find((item) => item.id === translation.locale)?.id;
  const styles = useStyles(() => ({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 20,
    },
    pickerSelectedView: {
      flexShrink: 1,
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 10,
    },
    pickerItemView: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 15,
      paddingVertical: 17,
    },
    pickerItemText: {
      fontSize: 18,
      lineHeight: 21,
      marginLeft: 15,
      padding: 0,
      flex: 1,
    },
    pickerSelectedText: {
      textTransform: 'uppercase',
    },
    picker: {
      flexBasis: pickedValue ? 90 : 120,
      flexShrink: 1,
      borderRadius: 3,
    },
    modalPanel: {
      maxWidth: 200,
      paddingVertical: 7,
    },
    pickerIcon: {
      marginEnd: 10,
    },
  }));
  const handleChange = (pickedId?: PickerItem['id']) => {
    if (pickedId !== undefined) {
      preferences.setLocale(pickedId as Locale);
    }
  };

  const renderSelectedText = (renderProps: RenderSelectedTextProps) => {
    const {style, otherTextProps, item} =
      renderProps as RenderSelectedTextProps & {item?: LocalePickerItem};
    const Icon = item?.icon;
    const selectedItem = items.find((_) => _.id === item?.id);
    return (
      <View style={styles.pickerSelectedView}>
        {Icon && <Icon width={20} height={20} />}
        <Text style={[style, styles.pickerSelectedText]} {...otherTextProps}>
          {selectedItem?.shortTitle ?? strings['menu.language']}
        </Text>
      </View>
    );
  };
  const renderPickerItem = (renderProps: RenderPickerItemProps) => {
    const {style, onPress, title, item} =
      renderProps as RenderPickerItemProps & {item?: LocalePickerItem};
    const Icon = item?.icon;
    return (
      <TouchableOpacity onPress={onPress} delayPressIn={0}>
        <View style={styles.pickerItemView}>
          {Icon && <Icon width={25} height={20} />}
          <Text style={[style, styles.pickerItemText]}>{title}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.container}>
      {!hideLabel && (
        <SecondaryMenuItem disabled text={strings['menu.language']} />
      )}
      <Picker
        modalPanelStyle={styles.modalPanel}
        iconStyle={styles.pickerIcon}
        renderSelectedText={renderSelectedText}
        renderPickerItem={renderPickerItem}
        onPick={handleChange}
        pickedItemId={pickedValue}
        style={styles.picker}
        items={items}
        closeText={strings['action.cancel']}
      />
    </View>
  );
});
