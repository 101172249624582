import {useCallback} from 'react';
import {useRoot} from '../Root/hooks';

export default () => {
  const root = useRoot();
  return useCallback(() => {
    const {cannon, connectionManager, connection, auth} = root;
    if (!connection.isConnected) {
      cannon.fire();
      connectionManager.try();
    } else {
      if (!auth.isConnected) {
        auth.retrySetup();
      }
    }
  }, [root]);
};
