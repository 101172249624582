import React from 'react';
import {View, StyleSheet} from 'react-native';

import {Divider} from '../components/Divider';
import {observer} from 'mobx-react-lite';
import {AppStoreButton, GooglePlayButton} from '../components/StoreButton';
import {useGoToAppStore, useGoToPlayStore} from '../Navigation/hooks';

export const GetItOnGooglePlay = observer(() => {
  const goToPlayStore = useGoToPlayStore();
  const goToAppStore = useGoToAppStore();
  return (
    <>
      <Divider />
      <View style={styles.root}>
        <GooglePlayButton
          style={[styles.button, styles.googlePlay]}
          onPress={goToPlayStore}
        />
        <AppStoreButton style={styles.button} onPress={goToAppStore} />
      </View>
    </>
  );
});

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 30,
    paddingTop: 20,
  },
  button: {
    marginBottom: 20,
  },
  googlePlay: {
    marginRight: 20,
  },
});
