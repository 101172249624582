import React from 'react';
import {StyleProp, StyleSheet, View, ViewProps, ViewStyle} from 'react-native';
import {observer} from 'mobx-react-lite';

/**
 * @deprecated
 */
export const defaultContainerStyle: StyleProp<ViewStyle> = {
  width: '100%',
  maxWidth: 1100,
  marginLeft: 'auto',
  marginRight: 'auto',
};

export const Container = observer(
  ({children, style, ...rest}: React.PropsWithChildren<ViewProps>) => {
    return (
      <View style={[styles.root, style]} {...rest}>
        {children}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    width: '100%',
    maxWidth: 1100,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});
