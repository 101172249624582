import {
  Authenticated,
  AuthenticationErrorReason,
  AuthenticationFailed,
  AuthenticationSummary,
} from './AuthState';
import {ApiStore} from '../ApiStore';
import {setFernetToken} from '../persistence';
import {
  DeviceIdentification,
  DeviceIdentificationStateKind,
} from '../DeviceIdentification';

export default async (
  accessToken: string,
  root: {
    readonly apiStore: ApiStore;
    readonly deviceIdentification: DeviceIdentification;
  },
): Promise<Authenticated | AuthenticationFailed> => {
  const advertId =
    root.deviceIdentification.state.kind ===
    DeviceIdentificationStateKind.Registered
      ? root.deviceIdentification.state.result.advertId
      : undefined;
  const oauth = await root.apiStore.client.call('oauth', {
    oauth_token: accessToken,
    advert_id: advertId,
  });
  if (!oauth.success) {
    return {
      kind: 'AuthenticationFailed',
      accessToken,
      reason: AuthenticationErrorReason.Link,
      raw: oauth.left,
    };
  }
  const {token: fernetToken} = oauth.right;
  await setFernetToken(fernetToken);
  return {
    kind: 'Authenticated',
    fernetToken,
    accessToken,
    summary: AuthenticationSummary.Link,
  };
};
