export default (series: readonly number[]): [number, number, number[]] => {
  if (series.length <= 2) {
    return [NaN, NaN, series.slice()];
  }
  let min: number = series[0];
  let max: number = series[0];
  for (const value of series) {
    min = value > min ? min : value;
    max = value < max ? max : value;
  }
  const delta = max - min;
  return [min, max, series.map((_) => (_ - min) / delta)];
};
