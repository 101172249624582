import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {
  ActivityIndicator,
  Alert,
  Button,
  Platform,
  Text,
  TextInput,
  View,
  ViewProps,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../Root/hooks';
import {
  AccountType,
  AuthenticatedByLink,
  AuthenticationErrorReason,
  AuthenticationSummary,
  AuthStatus,
} from '../Auth';
import {EmptyFarmView, SubscriptionView} from '../SubscriptionView';
import {ApiMode, Subscription} from '../farmApi';
import {ReadyState} from '../Connection';
import dayjs from 'dayjs';
import {SECOND} from '../utils/time';
import {FarmId} from '../ApiStore';
import {PageScrollView} from '../containers';
import Copyable from './Copyable';
import {ThemeKind} from '../Appearance';
import {Millisecond} from '../Time';
import {getReadableVersion} from 'react-native-device-info';
import {useStyles, useTheme} from '../styling';
import {Sentry} from '../Sentry';
import AsyncStorage from '@react-native-async-storage/async-storage';
import setFernetTokenAsync from '../persistence/setFernetToken';
import useLogExporter from '../LogExporter/useLogExporter';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';

export interface DebugScreenProps {
  goToEnvironmentList?: () => void;
  goToEnvironmentForm?: () => void;
  goToLog?: () => void;
  goToDatabaseImport?: () => void;
}

export default observer((props: DebugScreenProps) => {
  const {
    goToEnvironmentForm = noop,
    goToEnvironmentList = noop,
    goToLog = noop,
  } = props;
  const theme = useTheme();
  const [installReferrer, setInstallReferrer] = useState('');
  const styles = useMemo(
    () =>
      ({
        root: {
          flexGrow: 1,
          backgroundColor: theme.palette.background,
        },
        center: {
          justifyContent: 'center',
          alignItems: 'center',
        },
        container: {
          flexGrow: 1,
        },
        gutter: {
          margin: 10,
          color: theme.palette.textPrimary,
        },
        bold: {
          fontWeight: 'bold',
        },
        input: {
          paddingStart: 0,
          paddingTop: 0,
          paddingEnd: 0,
          paddingBottom: 0,
          color: theme.palette.textSecondary,
        },
        row: {
          flexDirection: 'row',
          flexWrap: 'wrap',
        },
        error: {
          color: 'red',
        },
        itemSelected: {
          borderWidth: 1,
          borderColor: theme.palette.secondary,
        },
      } as const),
    [theme],
  );
  const {
    appLifecycle,
    deviceIdentification,
    auth,
    apiStore,
    appearance,
    configuration,
    clipboard,
    debug,
    disconnect,
    connectionDebugService,
    installIdentification,
    messagingIdentity,
    purchaseDiscount,
  } = useRoot();
  const {saveLog, shareLog, copyLog} = useLogExporter();
  const reconnectAfterDelay = useCallback(
    () => connectionDebugService.reconnectAfterDelay(3200 as Millisecond),
    [connectionDebugService],
  );
  const {tunnelState} = apiStore;
  const [fernetToken, setFernetToken] = useState('');
  const clearFernetToken = useCallback(() => setFernetToken(''), []);
  const pasteFernetToken = useCallback(async () => {
    setFernetToken(await clipboard.getStringAsync());
  }, [clipboard]);
  const signInByFernetToken = useCallback(
    () => auth.authenticateByFernetToken(fernetToken),
    [auth, fernetToken],
  );
  const [farmId, setFarmId] = useState('');
  const [errorText, setErrorText] = useState('');
  useEffect(() => {
    try {
      installIdentification.getRef().then((ref) => {
        if (ref) {
          setInstallReferrer(ref);
        }
      });
    } catch (ignore) {}
  }, [installIdentification]);
  const clearFarmId = useCallback(() => setFarmId(''), []);
  const pasteFarmId = useCallback(async () => {
    setFarmId(await clipboard.getStringAsync());
  }, [clipboard]);
  const signInByFarmId = useCallback(async () => {
    await auth.authenticateByFarmId(parseInt(farmId, 10) as FarmId);
  }, [auth, farmId]);
  const signOut = useCallback(() => auth.signOut(), [auth]);
  const reconnect = useCallback(() => auth.retrySetup(), [auth]);
  const throwError = useCallback(() => {
    throw new Error(errorText ? errorText : 'Sentry error!');
  }, [errorText]);
  const throwNativeCrash = useCallback(() => {
    Sentry.nativeCrash();
  }, []);
  const showClipboardSuccess = useCallback(() => {
    Alert.alert(
      'Success',
      'The value has been copied to clipboard',
      [{style: 'default', text: 'OK'}],
      {cancelable: true},
    );
  }, []);
  const copyInstallReferrer = useCallback(() => {
    clipboard.setString(installReferrer);
    showClipboardSuccess();
  }, [clipboard, installReferrer, showClipboardSuccess]);
  const imitateFreshStart = useCallback(async () => {
    await appLifecycle.purge();
    await deviceIdentification.purge();
    Alert.alert(
      'Success',
      'Now you can restart or reinstall the app',
      [{style: 'default', text: 'OK'}],
      {cancelable: true},
    );
  }, [appLifecycle, deviceIdentification]);
  const clearFernetTokenAsync = useCallback(async () => {
    await setFernetTokenAsync(undefined);
  }, []);
  const purgeAsyncStorage = useCallback(async () => {
    await AsyncStorage.clear();
    await clearFernetTokenAsync();
    Alert.alert(
      'Success',
      'Now you can restart or reinstall the app',
      [{style: 'default', text: 'OK'}],
      {cancelable: true},
    );
  }, [clearFernetTokenAsync]);
  const simulateServiceUnavailability = useCallback(() => {
    // noinspection JSIgnoredPromiseFromCall
    apiStore.client.call('raise_error', {code: 9500});
  }, [apiStore]);
  const {state, status} = auth;
  const signOutButton = (
    <View style={styles.gutter}>
      <Button title="SIGN OUT" onPress={signOut} color="red" />
    </View>
  );
  const socketStatus = (
    <>
      <Text style={styles.gutter}>
        Socket status:{' '}
        <Text style={styles.bold}>{ReadyState[tunnelState.readyState]}</Text>
      </Text>
      {tunnelState.errorMessage !== undefined && (
        <Text style={styles.gutter}>
          Socket error:{' '}
          <Text style={styles.bold}>{tunnelState.errorMessage}</Text>
        </Text>
      )}
      <View style={styles.gutter}>
        <Button
          title="RECONNECT"
          disabled={tunnelState.readyState === ReadyState.Connecting}
          onPress={reconnect}
          color="red"
        />
      </View>
    </>
  );
  const authStatus = (
    <>
      <Text style={styles.gutter}>
        Authorization status:{' '}
        <Text style={styles.bold}>{AuthStatus[status]}</Text>
      </Text>
    </>
  );
  return state === undefined ? (
    <View style={[styles.root, styles.center]}>
      {(tunnelState.readyState === ReadyState.Connecting ||
        auth.status !== AuthStatus.Idle) && (
        <ActivityIndicator
          style={styles.gutter}
          color={theme.palette.secondary}
          size="large"
        />
      )}
      {tunnelState.readyState !== ReadyState.Open ? (
        socketStatus
      ) : (
        <>
          {authStatus}
          {signOutButton}
        </>
      )}
      <View style={styles.gutter}>
        <Button
          title="Switch environment"
          onPress={configuration.nextEnvironment}
          color="red"
        />
      </View>
    </View>
  ) : (
    <>
      <PageScrollView
        style={styles.root}
        contentContainerStyle={styles.container}>
        {socketStatus}
        {authStatus}
        {state.kind === 'AuthenticationFailed' ? (
          <>
            {state.reason === AuthenticationErrorReason.Link ? (
              <Text style={styles.gutter}>Linking has failed</Text>
            ) : (
              <Text style={styles.gutter}>Registration has failed</Text>
            )}
            <ErrorMessage raw={state.raw} />
            {state.reason === AuthenticationErrorReason.Link && (
              <>
                <Text style={styles.gutter}>Access token:</Text>
                <Copyable style={styles.gutter}>{state.accessToken}</Copyable>
              </>
            )}
            <View style={styles.gutter}>
              <Button
                title="RETRY AUTHENTICATION"
                onPress={auth.retrySetup}
                color={theme.palette.secondary}
              />
            </View>
          </>
        ) : (
          <>
            <Text style={styles.gutter}>
              {state.summary === AuthenticationSummary.Custom
                ? 'Authenticated by the given custom token'
                : state.summary === AuthenticationSummary.Registration
                ? 'New farm registered'
                : state.summary === AuthenticationSummary.PurchaseHistory
                ? 'Farm has been restored by the purchase history'
                : state.summary === AuthenticationSummary.LocalStorage
                ? 'Fernet token has been loaded from the local storage'
                : 'Authenticated by the OAuth'}
            </Text>
            <Text style={styles.gutter}>Fernet token:</Text>
            <Copyable style={styles.gutter}>{state.fernetToken}</Copyable>
            {state.summary === AuthenticationSummary.Link && (
              <>
                <Text style={styles.gutter}>Access token:</Text>
                <Copyable style={styles.gutter}>
                  {(state as AuthenticatedByLink).accessToken}
                </Copyable>
              </>
            )}
          </>
        )}
        {state.kind === 'AuthorizationFailed' ? (
          <>
            <Text style={styles.gutter}>Authorization failed</Text>
            <ErrorMessage raw={state.raw} />
            <View style={styles.gutter}>
              <Button
                title="RETRY AUTHORIZATION"
                onPress={auth.retrySetup}
                color={theme.palette.secondary}
              />
            </View>
          </>
        ) : (
          (state.kind === 'Authorized' ||
            state.kind === 'Connected' ||
            state.kind === 'ConnectionFailed') && (
            <>
              <View style={styles.row}>
                <Text style={styles.gutter}>Account type:</Text>
                <Text style={styles.gutter}>
                  {AccountType[state.accountType]}
                </Text>
              </View>
              {state.email !== undefined && (
                <View style={styles.row}>
                  <Text style={styles.gutter}>Account email:</Text>
                  <Text style={styles.gutter}>{state.email}</Text>
                </View>
              )}
              {state.accountIds &&
                [...state.accountIds].map((id) => (
                  <Item
                    key={id}
                    accountId={id}
                    subscription={state.subscriptionMap.get(id)}
                    style={[
                      styles.gutter,
                      state.kind === 'Connected' &&
                        state.accountId === id &&
                        styles.itemSelected,
                    ]}
                  />
                ))}
            </>
          )
        )}
        {state.kind === 'ConnectionFailed' ? (
          <>
            <Text style={styles.gutter}>Connection failed</Text>
            <ErrorMessage raw={state.raw} />
          </>
        ) : null}
        <Text style={styles.gutter}>
          Current API mode is{' '}
          {apiStore.mode === ApiMode.Demo ? (
            <Text style={styles.bold}>Demo</Text>
          ) : (
            <Text style={styles.bold}>Real</Text>
          )}
        </Text>
        <TextInput
          style={[styles.input, styles.gutter]}
          numberOfLines={1}
          placeholder="Custom Fernet token"
          placeholderTextColor={theme.palette.textSecondary}
          value={fernetToken}
          onChangeText={setFernetToken}
        />
        <View style={styles.row}>
          <View style={styles.gutter}>
            <Button title="CLEAR" onPress={clearFernetToken} color="red" />
          </View>
          <View style={styles.gutter}>
            <Button title="PASTE" onPress={pasteFernetToken} />
          </View>
          <View style={styles.gutter}>
            <Button
              title="SIGN IN"
              onPress={signInByFernetToken}
              color="green"
            />
          </View>
          {signOutButton}
        </View>
        <TextInput
          style={[styles.input, styles.gutter]}
          numberOfLines={1}
          placeholder="Farm ID"
          placeholderTextColor={theme.palette.textSecondary}
          value={farmId}
          onChangeText={setFarmId}
        />
        <View style={styles.row}>
          <View style={styles.gutter}>
            <Button title="CLEAR" onPress={clearFarmId} color="red" />
          </View>
          <View style={styles.gutter}>
            <Button title="PASTE" onPress={pasteFarmId} />
          </View>
          <View style={styles.gutter}>
            <Button title="SIGN IN" onPress={signInByFarmId} color="green" />
          </View>
          {signOutButton}
        </View>
        <DatabaseSummary goToDatabaseImport={props.goToDatabaseImport} />
        <Text style={styles.gutter} numberOfLines={1} ellipsizeMode="tail">
          Main socket URL: {configuration.values.mainSocketUrl}
        </Text>
        <View style={styles.row}>
          <View style={styles.gutter}>
            <Button
              title="CREATE ENV"
              onPress={goToEnvironmentForm}
              color="green"
            />
          </View>
          <View style={styles.gutter}>
            <Button title="SWITCH ENV" onPress={goToEnvironmentList} />
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.gutter}>
            <Button title="DISABLE DEBUG" onPress={debug.toggleDebug} />
          </View>
          <View style={styles.gutter}>
            <Button
              title={
                disconnect.enabled
                  ? 'Disable Faraday cage'
                  : 'Enable Faraday cage'
              }
              onPress={disconnect.toggle}
            />
          </View>
          <View style={styles.gutter}>
            <Button title="DISCONNECT" onPress={apiStore.disconnect} />
          </View>
          <View style={styles.gutter}>
            <Button title="SUPPRESS" onPress={reconnectAfterDelay} />
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.row}>
            <View style={styles.gutter}>
              <TextInput
                placeholder="Error text"
                style={[styles.input, styles.gutter]}
                placeholderTextColor={theme.palette.textSecondary}
                onChangeText={setErrorText}
                value={errorText}
              />
            </View>
            <View style={styles.gutter}>
              <Button title="THROW ERROR" onPress={throwError} />
            </View>
            {Platform.OS !== 'web' && (
              <View style={styles.gutter}>
                <Button title="NATIVE CRASH" onPress={throwNativeCrash} />
              </View>
            )}
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.gutter}>
            <Button
              title={debug.logEnabled ? 'DISABLE LOG' : 'ENABLE LOG'}
              onPress={debug.toggleLog}
            />
          </View>
          <View style={styles.gutter}>
            <Button
              title={debug.demoLogEnabled ? 'LOG DEMO' : 'LOG REAL'}
              onPress={debug.toggleDemoLog}
            />
          </View>
          <View style={styles.gutter}>
            <Button title="OPEN LOG" onPress={goToLog} />
          </View>
          <View style={styles.gutter}>
            <Button title="COPY LOG" onPress={copyLog} />
          </View>
          <View style={styles.gutter}>
            <Button title="SHARE LOG" onPress={shareLog} />
          </View>
          <View style={styles.gutter}>
            <Button title="SAVE LOG" onPress={saveLog} />
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.gutter}>
            <Button
              title="IMITATE FRESH INSTALLATION"
              onPress={imitateFreshStart}
            />
          </View>
          <View style={styles.gutter}>
            <Button
              title="PURGE STORAGE"
              onPress={purgeAsyncStorage}
              color="red"
            />
          </View>
          <View style={styles.gutter}>
            <Button
              title="CLEAR FERNET TOKEN"
              onPress={clearFernetTokenAsync}
              color="red"
            />
          </View>
        </View>
        <View style={styles.gutter}>
          <Button
            title={
              appearance.preferredThemeKind === ThemeKind.Auto
                ? 'THEME AUTO MODE'
                : appearance.preferredThemeKind === ThemeKind.Dark
                ? 'THEME DARK MODE'
                : 'THEME LIGHT MODE'
            }
            onPress={appearance.togglePreferredThemeKind}
          />
        </View>
        <Text style={styles.gutter}>{getReadableVersion()}</Text>
        <Text onPress={copyInstallReferrer} style={styles.gutter}>
          Install referrer: {installReferrer ? installReferrer : '-'}
        </Text>
        <Text style={styles.gutter}>
          Discount:{' '}
          {purchaseDiscount.discount
            ? JSON.stringify(purchaseDiscount.discount)
            : '-'}
        </Text>
        <Copyable style={styles.gutter}>{messagingIdentity.token}</Copyable>
        <View style={styles.gutter}>
          <Button
            title="SIMULATE SERVICE UNAVAILABILITY"
            onPress={simulateServiceUnavailability}
            color="red"
          />
        </View>
      </PageScrollView>
    </>
  );
});

const ErrorMessage: FC<{raw: unknown}> = ({raw}) => {
  const styles = useMemo(
    () =>
      ({
        root: {
          margin: 10,
          fontWeight: 'bold',
          color: 'red',
        },
      } as const),
    [],
  );
  return (
    <Copyable style={[styles.root]}>
      {raw instanceof Error
        ? `${raw.name} ${raw.message}`
        : JSON.stringify(raw)}
    </Copyable>
  );
};

interface ItemProps extends ViewProps {
  accountId: FarmId;
  subscription?: Subscription;
}

const Item = observer((props: ItemProps) => {
  const {accountId, subscription, ...rest} = props;
  const {auth} = useRoot();
  const onPress = useCallback(() => {
    return auth.selectAccount(accountId);
  }, [accountId, auth]);
  return subscription ? (
    <TouchableWithoutFeedback onPress={onPress}>
      <SubscriptionView {...subscription} {...rest} />
    </TouchableWithoutFeedback>
  ) : (
    <TouchableWithoutFeedback onPress={onPress}>
      <EmptyFarmView accountId={accountId} {...rest} />
    </TouchableWithoutFeedback>
  );
});

interface DatabaseSummaryProps {
  goToDatabaseImport?: () => void;
}

const DatabaseSummary = observer((props: DatabaseSummaryProps) => {
  const {goToDatabaseImport = noop} = props;
  const styles = useStyles((theme) => ({
    gutter: {margin: 10, color: theme.palette.textPrimary},
    row: {flexDirection: 'row', flexWrap: 'wrap'},
  }));
  const {db, apiStore, clipboard} = useRoot();
  const setNormalMiningSpeed = useCallback(
    () => apiStore.setMiningInterval(),
    [apiStore],
  );
  const setQuickMiningSpeed = useCallback(
    () => apiStore.setMiningInterval(SECOND),
    [apiStore],
  );
  const {databaseExporter} = useRoot();
  const exportDb = useCallback(async () => {
    clipboard.setString(await databaseExporter.export());
    Alert.alert(
      'Success',
      'The value has been copied to clipboard',
      [{style: 'default', text: 'OK'}],
      {cancelable: true},
    );
  }, [clipboard, databaseExporter]);
  return (
    <>
      <Text style={styles.gutter}>
        There are {db.state.groups.length} group(s),{' '}
        {db.state.groups.reduce(
          (sum, group) => sum + Object.keys(group.workersById).length,
          0,
        )}{' '}
        worker(s) and {db.state.logs.length} log(s) in the local database.
      </Text>
      <Text style={styles.gutter}>
        The last statistics update was at{' '}
        {dayjs(db.state.lastWorkerStatsUpdate).format('DD.MM.YY HH:mm')}
      </Text>
      <Text style={styles.gutter}>
        Total mining points:{' '}
        {db.state.groups.reduce(
          (sum0, group) =>
            sum0 +
            Object.values(group.workersById).reduce(
              (sum1, worker) => sum1 + worker.miningResults.length,
              0,
            ),
          0,
        )}
      </Text>
      <Text style={styles.gutter}>
        Total mined amount is{' '}
        {db.state.groups.reduce(
          (sum0, group) =>
            sum0 +
            Object.values(group.workersById).reduce(
              (sum1, worker) =>
                sum1 + worker.miningResults.reduce((sum2, _) => sum2 + _, 0),
              0,
            ),
          0,
        )}{' '}
        BTC
      </Text>
      <Text style={styles.gutter}>
        The balance now have {Object.keys(db.state.balanceByWorkerId).length}{' '}
        worker(s) with total{' '}
        {Object.values(db.state.balanceByWorkerId).reduce(
          (sum, _) => sum + _,
          0,
        )}{' '}
        BTC
      </Text>
      <Text style={styles.gutter}>
        Statistics update interval is {db.state.updateInterval / 1000} second(s)
      </Text>
      <View style={styles.row}>
        <View style={styles.gutter}>
          <Button title="PURGE DATABASE" onPress={db.clear} color="red" />
        </View>
        <View style={styles.gutter}>
          <Button title="IMPORT" onPress={goToDatabaseImport} />
        </View>
        <View style={styles.gutter}>
          <Button title="EXPORT" onPress={exportDb} />
        </View>
        <View style={styles.gutter}>
          {db.state.updateInterval === SECOND ? (
            <Button
              title="NORMAL MINING"
              onPress={setNormalMiningSpeed}
              color="red"
            />
          ) : (
            <Button
              title="QUICK MINING"
              onPress={setQuickMiningSpeed}
              color="red"
            />
          )}
        </View>
      </View>
    </>
  );
});

const noop = () => {};
