import React, {useCallback} from 'react';
import {useNavigation} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import {Container} from '../../components/Grid';
import {DashboardScreenProps} from '../DashboardScreenProps';
import {HeaderInfoSection} from '../sections';
import {PromoSection} from './PromoSection';
import {BannerType} from '../../DashboardBannersState';
import {Banner, BannerVariant} from '../../components';
import {useRoot, useStrings, useTemplates} from '../../Root/hooks';
import {sized} from '../../Svg';
import ErrorRefreshSvg from '../../assets/svg/circled/errorRefresh.svg';
import ErrorLockSvg from '../../assets/svg/circled/errorLock.svg';
import {Platform, View} from 'react-native';
import {useHeaderBannerSectionHeight} from '../hooks/useGetItemLayout';
import AdsBanner from './AdsBanner';
import {expr} from 'mobx-utils';
import {AdSpot} from '../../ApiStore';
import {first} from 'lodash';
import {useForcedContext} from '../../context';
import {DashboardContext} from '../context';

const PADDING_BOTTOM = 10;

export const BaseListHeader = observer(() => {
  const {
    dashboardBannersState,
    poolMinerActivator,
    advertHelper,
    windowDimensionsState,
  } = useRoot();
  const {goToCopyRegistrationLink} = useForcedContext(DashboardContext);
  const isDesktop = expr(() => windowDimensionsState.window.width > 1000);
  const strings = useStrings();
  const templates = useTemplates();
  const advertMobileBanner = expr(() =>
    first(advertHelper.spotByBannerList?.get(AdSpot.DashboardMobileTop)),
  );
  const advertDesktopBanner = expr(() =>
    first(advertHelper.spotByBannerList?.get(AdSpot.DashboardDesktopTop)),
  );
  const desktopBanner = expr(() => advertDesktopBanner);
  const {navigate} = useNavigation<DashboardScreenProps['navigation']>();
  const goToPlan = useCallback(() => navigate('Plan'), [navigate]);
  const goToAuth = useCallback(() => {
    navigate('Auth');
  }, [navigate]);
  const goToWithdraw = useCallback(() => navigate('Withdraw'), [navigate]);
  const goToAddWorker = useCallback(async () => {
    navigate('AddWorker');
  }, [navigate]);
  const headerBannerSectionHeight = useHeaderBannerSectionHeight();
  const height = headerBannerSectionHeight - PADDING_BOTTOM;
  const advertBanner = expr(() =>
    isDesktop ? advertDesktopBanner : advertMobileBanner,
  );
  const getBanner = () => {
    const bannerStyle = {height: height};
    if (desktopBanner && isDesktop) {
      return (
        <AdsBanner
          isDesktop={isDesktop}
          advertItem={desktopBanner}
          height={height}
        />
      );
    }
    if (advertBanner) {
      return;
    }
    const targetBanner = dashboardBannersState.targetBanner;
    switch (targetBanner) {
      case BannerType.PoolMiner:
        return (
          <Banner
            variant={BannerVariant.Error}
            description={strings['banner.poolMiner.confirmation']}
            onPress={poolMinerActivator.reset}
            leftAccessory={<ErrorRefreshIcon />}
            style={bannerStyle}
          />
        );
      case BannerType.Expired:
        return (
          <Banner
            variant={BannerVariant.Error}
            description={strings['banner.subscription.expired']}
            onPress={goToPlan}
            leftAccessory={<ErrorRefreshIcon />}
            style={bannerStyle}
          />
        );
      case BannerType.Demo:
        return (
          <Banner
            variant={BannerVariant.Error}
            description={strings['banner.demo']}
            onPress={goToPlan}
            leftAccessory={<ErrorLockIcon />}
            style={bannerStyle}
          />
        );
      case BannerType.Upgrade:
        const title = templates['banner.upgrade']({
          platform: getFormattedCurrentPlatform(),
        });
        return (
          <Banner
            variant={BannerVariant.Error}
            description={title}
            onPress={goToPlan}
            leftAccessory={<ErrorLockIcon />}
            style={bannerStyle}
          />
        );
      default:
        return null;
    }
  };

  const banner = getBanner();

  return (
    <Container>
      <HeaderInfoSection
        goToAuth={goToAuth}
        goToAddWorker={goToAddWorker}
        goToWithdraw={goToWithdraw}
        banner={advertMobileBanner}
      />
      {banner && (
        <View
          style={{
            height: headerBannerSectionHeight,
            paddingBottom: PADDING_BOTTOM,
          }}>
          {banner}
        </View>
      )}
      <PromoSection goToCopyRegistrationLink={goToCopyRegistrationLink} />
    </Container>
  );
});

const ErrorRefreshIcon = sized(ErrorRefreshSvg, 48);
const ErrorLockIcon = sized(ErrorLockSvg, 48);

const getFormattedCurrentPlatform = () => {
  switch (Platform.OS) {
    case 'android':
      return 'Android';
    case 'ios':
      return 'iOS';
    case 'web':
      return 'Web';
    default:
      return Platform.OS;
  }
};
