import {
  CorrelationId,
  Http,
  NEW_WEB_SOCKET,
  NewWebSocketParams,
  REQUEST,
  RequestParams,
  RESPONSE,
  RESPONSE_BODY,
  ResponseBodyParams,
  ResponseParams,
} from './Http';
import {RouterImpl} from '../structure';

export default class HttpImpl implements Http {
  private _correlationId = 0 as CorrelationId;

  private _generateCorrelationId() {
    return this._correlationId++ as CorrelationId;
  }

  async fetch(input: RequestInfo, init?: RequestInit) {
    const correlationId = this._generateCorrelationId();
    this.io.send(REQUEST, {correlationId, input, init});
    const response = await fetch(input, init);
    this.io.send(RESPONSE, {correlationId, response});
    const boundResponse = response.clone();
    // fixme mock other methods of the response body
    boundResponse.json = async () => {
      const content = await response.json();
      const responseBody = JSON.stringify(content, undefined, 2);
      this.io.send(RESPONSE_BODY, {correlationId, responseBody});
      return content;
    };
    boundResponse.text = async () => {
      const content = await response.text();
      this.io.send(RESPONSE_BODY, {correlationId, responseBody: content});
      return content;
    };
    return boundResponse;
  }

  createWebSocket(uri: string, protocols?: string | string[]) {
    const correlationId = this._generateCorrelationId();
    const socket = new WebSocket(uri, protocols);
    this.io.send(NEW_WEB_SOCKET, {correlationId, uri, protocols, socket});
    return socket;
  }

  public io = new RouterImpl<{
    [REQUEST]: RequestParams;
    [RESPONSE]: ResponseParams;
    [NEW_WEB_SOCKET]: NewWebSocketParams;
    [RESPONSE_BODY]: ResponseBodyParams;
  }>();
}
