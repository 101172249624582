import {ApiStore} from '../ApiStore';
import {FarmLogEntity, GetFarmLogsFromTimestampResponse} from './entities';
import {error, success} from '../fp';
import {Auth} from '../Auth';
import {TimestampPageRequest} from '../TimestampPageRequest';
import {action, comparer, observable, reaction, makeObservable} from 'mobx';
import {Notifications} from './Notifications';
import {Translation} from '../Localization';
import {batchDisposers, Service} from '../structure';
import {ConnectedClient} from '../ContextClient';

export default class NotificationsService implements Notifications, Service {
  @observable _counterOfNewNotifications = 0;

  constructor(
    readonly _root: {
      readonly apiStore: ApiStore;
      readonly connectedClient: ConnectedClient;
      readonly auth: Auth;
      readonly translation: Translation;
    },
  ) {
    makeObservable(this);
  }

  get counterOfNewNotifications(): number {
    return this._counterOfNewNotifications;
  }

  @action.bound incrementCounter() {
    this._counterOfNewNotifications += 1;
  }

  @action.bound clearCounter() {
    this._counterOfNewNotifications = 0;
  }

  private _fetchOnConnect() {
    return reaction(
      () =>
        [this._root.auth.isConnected, this._root.translation.locale] as const,
      ([isConnected]) => {
        this._counterOfNewNotifications = 0;
        if (isConnected) {
          // noinspection JSIgnoredPromiseFromCall
          this.list.refresh();
        }
      },
      {equals: comparer.shallow},
    );
  }

  list = new TimestampPageRequest<FarmLogEntity>(async (timestamp) => {
    const params = {
      limit: 50,
      timestamp,
    };
    const result = await this._root.connectedClient.call(
      'get_logs_from_timestamp',
      params,
    );
    if (!result.success) {
      return error(result.left);
    }
    return success(result.right as GetFarmLogsFromTimestampResponse);
  });

  private _listenToServerUpdates() {
    return this._root.apiStore.server.call(
      'farm_log',
      action((farmLog, response) => {
        this.list.unshift(farmLog);
        this.incrementCounter();
        return response.respond(success(null));
      }),
    );
  }

  subscribe() {
    return batchDisposers(
      this._fetchOnConnect(),
      this._listenToServerUpdates(),
    );
  }
}
