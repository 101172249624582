import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import PageHeader from '../components/PageHeader';
import {Text, TouchableOpacity, View} from 'react-native';
import {Button, ButtonColor} from '../components';
import DashboardSvg from '../assets/svg/colorless/dashboard.svg';
import {createStylesHook, useTheme} from '../styling';
import {useRoot, useStrings} from '../Root/hooks';
import {useNavigation} from '@react-navigation/native';

export default observer(() => {
  const {dashboardStore} = useRoot();
  const strings = useStrings();
  const styles = useStyles();
  const navigation = useNavigation();
  const theme = useTheme();
  const toDashboard = useCallback(() => navigation.goBack(), [navigation]);
  const rate = useMemo(() => {
    const usd = dashboardStore.currentRateRequest.value?.usd;
    const btc = dashboardStore.currentRateRequest.value?.btc;
    if (!usd || !btc) {
      return null;
    }
    return (usd / btc).toFixed(2);
  }, [dashboardStore.currentRateRequest.value]);
  return (
    <>
      <PageHeader
        style={styles.pageHeader}
        title={strings['screenTitle.dashboardSettings']}
        right={
          <View style={styles.content}>
            <View style={styles.ratesView}>
              <Text style={styles.ratesLabel}>BTC / USD</Text>
              <Text style={styles.ratesValue}>{rate}</Text>
            </View>
            <View style={styles.buttonWrapper}>
              <TouchableOpacity activeOpacity={0.8} onPress={toDashboard}>
                <Button
                  color={ButtonColor.Primary}
                  Icon={DashboardSvg}
                  iconProps={{fill: theme.palette.secondary}}
                />
              </TouchableOpacity>
            </View>
          </View>
        }
      />
    </>
  );
});

const useStyles = createStylesHook((theme) => ({
  pageHeader: {
    marginVertical: 20,
  },

  content: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  ratesView: {
    paddingHorizontal: 20,
  },
  ratesLabel: {
    ...theme.fontByWeight('600'),
    fontSize: 10,
    color: theme.palette.textPrimary,
    marginBottom: 5,
  },
  ratesValue: {
    ...theme.fontByWeight(),
    fontSize: 12,
    lineHeight: 14,
    color: theme.palette.textSecondary,
  },
  buttonWrapper: {
    borderLeftWidth: 1,
    borderLeftColor: theme.palette.border,
    height: '100%',
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
}));
