import React from 'react';
import {observer} from 'mobx-react-lite';
import SlotTitle from '../../../../common/SlotTitle';
import {View, Text} from 'react-native';
import {createStylesHook, useTheme} from '../../../../../styling';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {expr} from 'mobx-utils';
import getWorkerDescription from '../../../../getters/getWorkerDescription';

export interface WorkerCharacteristicsProps {
  worker: ReadonlyDeep<WorkerEntity>;
  warning: boolean;
  disabled: boolean;
}

export default observer(
  ({worker, warning, disabled}: WorkerCharacteristicsProps) => {
    const styles = useStyles();
    const specs = expr(() => getWorkerDescription(worker));
    const theme = useTheme();
    const numberOfLines = theme.window.width < 1000 ? 2 : 4;
    return (
      <View>
        <SlotTitle
          worker={worker}
          containerStyle={styles.titleView}
          warning={warning}
          disabled={disabled}
          numberOfLines={1}
        />
        <View style={styles.descriptionView}>
          <Text
            style={styles.description}
            numberOfLines={numberOfLines}
            ellipsizeMode={'tail'}>
            {specs}
          </Text>
        </View>
      </View>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  titleView: {
    paddingBottom: 10,
  },
  descriptionView: {
    justifyContent: 'center',
  },
  description: {
    ...theme.fontByWeight('400'),
    fontSize: 12,
    lineHeight: 22,
    color: theme.palette.darkText,
  },
}));
