import React, {forwardRef, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {FlatList, FlatListProps, ScrollViewProps, View} from 'react-native';
import {useRoute} from '@react-navigation/native';
import {ReadonlyDeep} from 'type-fest';
import {useRoot} from '../../../Root/hooks';
import SimpleSlot from './SimpleSlot';
import {createStylesHook} from '../../../styling';
import formItems from '../../form/formItems';
import {DashboardScreenProps} from '../../DashboardScreenProps';
import {WorkerEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import ListFooter from './ListFooter';
import RefreshControl from '../../../components/RefreshControl';
import {BaseListHeader} from '../../Header/BaseListHeader';
import WorkerListHeader from './WorkerListHeader';

export type SimpleWorkerListProps = ScrollViewProps & {
  hideRefreshControl?: boolean;
};

export type SimpleWorkerListComponent = FlatList<ReadonlyDeep<WorkerEntity>>;

export const SimpleWorkerList = observer(
  forwardRef<SimpleWorkerListComponent>(
    ({hideRefreshControl, ...rest}: SimpleWorkerListProps, ref) => {
      const {dashboardStore, workerStateRegistry} = useRoot();
      const route = useRoute<DashboardScreenProps['route']>();
      const groups = dashboardStore.dashboardRequest.value?.groups || [];
      const workers = groups.map((g) => g.workers).flat();
      const liveWorkers = formItems(
        workers,
        route,
        dashboardStore.getWorkerBalanceById,
        workerStateRegistry,
      );

      const hasWorkers = Object.keys(workers).length > 0;
      const styles = useStyles();
      const Header = useMemo(
        () => (
          <View>
            <BaseListHeader />
            {hasWorkers && <WorkerListHeader />}
          </View>
        ),
        [hasWorkers],
      );

      const renderItem = ({
        item,
        index,
      }: {
        item: ReadonlyDeep<WorkerEntity>;
        index: number;
      }) => (
        <SimpleSlot
          worker={item}
          index={index}
          isLast={workers.length === index + 1}
        />
      );

      return (
        <FlatList
          ref={ref}
          refreshControl={
            hideRefreshControl ? undefined : (
              <RefreshControl
                refreshing={dashboardStore.isLoading}
                onRefresh={dashboardStore.fetch}
              />
            )
          }
          contentContainerStyle={styles.container}
          ListHeaderComponent={Header}
          ListFooterComponent={<ListFooter hasWorkers={hasWorkers} />}
          keyboardShouldPersistTaps="handled"
          initialNumToRender={7}
          data={liveWorkers}
          renderItem={renderItem}
          removeClippedSubviews
          {...rest}
        />
      );
    },
  ),
);

export type ListProps = FlatListProps<ReadonlyDeep<WorkerEntity>>;
const useStyles = createStylesHook((theme) => ({
  container: {
    paddingVertical: 10,
    paddingHorizontal: 5,
    ...theme.mediaQuery({
      1000: {
        paddingHorizontal: 0,
        paddingBottom: 0,
        overflow: 'hidden',
      },
    }),
  },
}));
