import {RefSearcher} from './RefSearcher';
import {Either, error, success} from '../fp';
import {getBundleId, getInstallReferrer} from 'react-native-device-info';
import {LocationSource} from '../Location';

/**
 * Returns install identification for android if we entered the application via com.example://ref
 */
export default class AndroidInstallRefSearcherImpl implements RefSearcher {
  constructor(
    private readonly _root: {
      readonly locationSource: LocationSource;
    },
  ) {}

  async find(): Promise<Either<string, void>> {
    const link = await this._root.locationSource.getInitial();
    if (!link.success) {
      return error(undefined);
    }
    const isRefRoute = link.right.startsWith(`${getBundleId()}://ref`);
    if (isRefRoute) {
      const referrer = await getInstallReferrer();
      return success(referrer);
    }
    return error(undefined);
  }
}
