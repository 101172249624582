import React from 'react';
import {observer} from 'mobx-react-lite';
import {DesktopWebAppFooter} from '../../../DesktopWebAppFooter';
import {Platform, View} from 'react-native';
import DesktopStoreBanner from '../../DesktopStoreBanner';
import {Container} from '../../../components/Grid';
import AddSlotsSection from '../AddSlotsSection';
import AdvancedSection from '../AdvancedSection';
import {variance} from '../../../styling';

interface ListFooterProps {
  hasWorkers: boolean;
}

export default observer(({hasWorkers}: ListFooterProps) => (
  <Root offset={hasWorkers}>
    <Container>
      <AddSlotsSection />
      <AdvancedSection />
      {Platform.OS === 'web' && (
        <Container>
          <DesktopStoreBanner />
        </Container>
      )}
    </Container>
    <DesktopWebAppFooter />
  </Root>
));

const Root = variance(View)(() => ({
  root: {},
  offset: {
    marginTop: 20,
  },
}));
