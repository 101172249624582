import React from 'react';
import {observer} from 'mobx-react-lite';
import {ButtonVariant} from '../components';
import {useRoot, useStrings} from '../Root/hooks';
import ActionModal from '../modals/ActionModal';
import SuccessCheckSvg from '../assets/svg/circled/successCheck.svg';

export const ThanksModal = observer(() => {
  const {rateApp} = useRoot();
  const strings = useStrings();
  return (
    <ActionModal
      buttons={[
        {
          text: strings['action.ok'],
          onPress: rateApp.closeModal,
          variant: ButtonVariant.Highlighted,
        },
      ]}
      onDismiss={rateApp.closeModal}
      Icon={SuccessCheckSvg}>
      {strings['modal.rateThanks.description']}
    </ActionModal>
  );
});
