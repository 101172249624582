import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../../../../Root/hooks';
import {createStylesHook, variance} from '../../../../styling';
import {View, Text} from 'react-native';
import Hashrate from '../../../common/Hashrate';
import {BtcAmount} from '../../../common/BtcAmount';
import {WorkerOrder} from '../../../types';
import {HeaderFilter} from '../../HeaderFilter';

export type DesktopGroupStatsProps = {
  hashrate?: number | (() => React.ReactNode);
  mined?: number | (() => React.ReactNode);
  onOrderChange: (order: WorkerOrder) => void;
  order: WorkerOrder;
  isEmptyGroup?: boolean;
};

export default observer((props: DesktopGroupStatsProps) => {
  const {hashrate = 0, mined = 0, order, onOrderChange} = props;

  const strings = useStrings();
  const styles = useStyles();

  return (
    <Root emptyGroup={props.isEmptyGroup}>
      <HeaderFilter
        onOrderChange={onOrderChange}
        order={order}
        style={styles.panel}
      />
      <View style={styles.cell}>
        <Text style={styles.cellTitle}>
          {strings['workerGroup.totalGroupSpeed']}
        </Text>
        <Hashrate
          value={hashrate}
          style={styles.value}
          unitStyle={styles.value}
        />
      </View>
      <View style={styles.lastCell}>
        <Text style={styles.cellTitle}>
          {strings['workerGroup.minedByGroup']}
        </Text>
        <BtcAmount value={mined} style={styles.value} />
      </View>
    </Root>
  );
});

const Root = variance(View)((theme) => ({
  root: {
    paddingHorizontal: 20,
    paddingVertical: 13,
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: theme.palette.border,
    backgroundColor: theme.palette.backdrop,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 50,
  },
  emptyGroup: {
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
}));

const useStyles = createStylesHook((theme) => ({
  panel: {
    borderWidth: 0,
    padding: 0,
    height: 'auto',
    borderRadius: 10,
    flexBasis: 250,
  },
  cell: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 8,
    flexGrow: 1,
  },
  lastCell: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 8,
    flexBasis: 250,
  },
  cellTitle: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textSecondary,
    fontSize: 12,
    lineHeight: 14,
    marginRight: 7,
  },
  value: {
    fontSize: 18,
    lineHeight: 22,
  },
}));
