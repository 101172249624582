import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {View, Text} from 'react-native';
import {useStrings} from '../Root/hooks';
import getWorkerDescription from '../DashboardScreen/getters/getWorkerDescription';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {Button, ButtonVariant, PressableOpacity} from '../components';

export type HashrateSectionProps = {
  worker: ReadonlyDeep<WorkerEntity>;
  hasAtLeastOnePlan: boolean;
  onAddNewPoolPress: () => void;
};

export const HashrateSection = observer((props: HashrateSectionProps) => {
  const {worker, hasAtLeastOnePlan, onAddNewPoolPress} = props;
  const strings = useStrings();
  const hashrate = worker.pool_miner_config?.hash_rate;
  const title = hashrate ? hashrate : '-';
  const desc = getWorkerDescription(worker);
  return (
    <Root>
      <HashrateTitle>{strings['renewAccess.hashrate']}:</HashrateTitle>
      <HashrateCard disabled={!hasAtLeastOnePlan}>
        <HashrateCardSpeed>{title} H/s</HashrateCardSpeed>
        <HashrateCardDesc>{desc}</HashrateCardDesc>
      </HashrateCard>
      {!hasAtLeastOnePlan && (
        <TouchableButton onPress={onAddNewPoolPress}>
          <Button variant={ButtonVariant.Highlighted}>
            {strings['renewAccess.addNew']}
          </Button>
        </TouchableButton>
      )}
    </Root>
  );
});

const Root = variance(View)((theme) => ({
  root: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.border,
  },
}));

const HashrateTitle = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    marginBottom: 10,
  },
}));

const HashrateCard = variance(View)((theme) => ({
  root: {
    padding: 15,
    borderWidth: 1,
    borderColor: theme.palette.border,
    borderRadius: 2,
  },
  disabled: {
    backgroundColor: theme.chroma(theme.palette.disabled).alpha(0.05).hex(),
  },
}));

const HashrateCardSpeed = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 24,
    lineHeight: 28,
    textAlign: 'center',
    letterSpacing: 0.042,
    color: theme.palette.textPrimary,
    marginBottom: 7,
  },
}));

const HashrateCardDesc = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    fontSize: 12,
    lineHeight: 20,
    textAlign: 'center',
    letterSpacing: 0.05,
  },
}));

const TouchableButton = variance(PressableOpacity)(() => ({
  root: {
    marginTop: 15,
  },
}));
