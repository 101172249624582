import {observer} from 'mobx-react-lite';
import React from 'react';
import {Platform, StyleSheet} from 'react-native';
import {Container} from '../components/Grid';
import RefreshControl from '../components/RefreshControl';
import {variance} from '../styling';
import RestoreButtonBinding from './bindings/RestoreButtonBinding';
import PurchaseIsNotAvailableTable from './common/PurchaseIsNotAvailableTable';
import {PurchaseScreenState} from './states/PurchaseScreenState';
import {GlobalError} from '../Error';
import PurchaseCategoryTabsBinding from './bindings/PurchaseCategoryTabsBinding';
import {PurchaseScreenContext} from './PurchaseScreenContext';
import NeedMoreSlotsModalBinding from './bindings/NeedMoreSlotsModalBinding';
import SlotInfoSectionBinding from './bindings/SlotInfoSectionBinding';
import SuccessfulSubscriptionPurchaseModalBinding from './bindings/SuccessfulSubscriptionPurchaseModalBinding';
import AddPromoModalBinding from './bindings/AddPromoModalBinding';
import {Offer} from '../InAppOffersService';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';
import {PageScrollView} from '../containers';

export type SubscriptionScreenProps = {
  state: PurchaseScreenState;
  error: GlobalError | undefined;
  onPurchasePress: (offer: Offer) => void;
  goToAccessRecovery: () => void;
  goToPlans: () => void;

  goToDashboard: () => void;
  safelyGoToAffiliate: () => void;
  getAffiliateIsPending: () => boolean;

  initialTabNavigationIndex: number;

  refreshing: boolean;
  onRefresh: () => void;

  showNeedSlotsModal: () => void;
  needSlotsVisible: boolean;
  hideNeedSlotsModal: () => void;

  visiblePickHashrateModal: boolean;
  showPickHashrateModal: () => void;
  hidePickHashrateModal: () => void;

  visibleEnterPromoModal: boolean;
  openEnterPromoModal: () => void;
  hideEnterPromoModal: () => void;

  visibleSuccessSubscriptionModal: boolean;
  openSuccessSubscriptionModal: () => void;
  hideSuccessSubscriptionModal: () => void;

  onSelectConfigurationPress: () => void;
  onSelectButtonPress: () => void;
};

export default observer((props: SubscriptionScreenProps) => {
  const {error, refreshing, onRefresh, onSelectConfigurationPress} = props;
  return (
    <PurchaseScreenContext.Provider value={props}>
      <DesktopWebAppHeader />
      <PageScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        contentContainerStyle={styles.container}>
        <SlotInfoSectionBinding />
        <RootContainer>
          {error ? (
            <PurchaseIsNotAvailableTable />
          ) : (
            <PurchaseCategoryTabsBinding
              onSelectConfigurationPress={onSelectConfigurationPress}
            />
          )}
          {Platform.OS !== 'web' && <RestoreButtonBinding />}
        </RootContainer>
      </PageScrollView>
      <AddPromoModalBinding />
      <NeedMoreSlotsModalBinding />
      <SuccessfulSubscriptionPurchaseModalBinding />
    </PurchaseScreenContext.Provider>
  );
});

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
});

const RootContainer = variance(Container)((theme) => ({
  root: {
    flex: 1,
    ...Platform.select({
      web: {
        ...theme.mediaQuery({
          1000: {
            paddingBottom: 20,
          },
        }),
      },
    }),
  },
}));
