import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, Text, Platform} from 'react-native';
import {useStrings} from '../../Root/hooks';
import {variance} from '../../styling';

export default observer(() => {
  const strings = useStrings();
  const priceText =
    Platform.OS === 'ios'
      ? strings['common.price']
      : strings['subscription.pricePerWorker'];
  return (
    <Root>
      <TableCellTitleText>{strings['common.quantity']}</TableCellTitleText>
      <TableCellTitleText>{priceText}</TableCellTitleText>
    </Root>
  );
});

const Root = variance(View)((theme) => ({
  root: {
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 7,
    borderWidth: 1,
    borderColor: theme.palette.border,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    backgroundColor: theme.palette.back,
    flexDirection: 'row',
    ...theme.mediaQuery({
      1000: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderWidth: 0,
        marginBottom: 5,
      },
    }),
  },
}));

const TableCellTitleText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    letterSpacing: 0.04,
    lineHeight: 14,
    fontSize: 12,
    color: theme.select(theme.palette.textSecondary, theme.palette.textPrimary),
  },
}));
