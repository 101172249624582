import React from 'react';
import {Platform, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {SvgProps} from 'react-native-svg';
import {
  BottomTabBarButtonProps,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import DashboardSvg from '../../assets/svg/colorless/dashboard.svg';
import StatisticsSvg from '../../assets/svg/colorless/statistics.svg';
import NotificationSvg from '../../assets/svg/colorless/notification.svg';
import DrawerSvg from '../../assets/svg/colorless/drawer.svg';
import PayoutSvg from '../../assets/svg/colorless/payout.svg';
import BugReportSvg from '../../assets/svg/colorless/bugReport.svg';

import {useRoot, useStrings} from '../../Root/hooks';
import useNotificationBadge from './useNotificationBadge';
import {useStyles, useTheme} from '../../styling';
import {GroupOption} from '../../DashboardScreen';
import {StatisticsScreen} from '../../StatisticsScreen';
import {NotificationListScreen} from '../../NotificationListScreen';
import MenuBinding from './MenuBinding';
import DebugBinding from './DebugBinding';
import WithdrawBindingSwitch from './WithdrawBindingSwitch';
import DashboardBinding from './DashboardBinding';
import useTutorialMeasure from '../../InteractiveTutorial/useTutorialMeasure';
import {PressableOpacity} from '../../components';
import {StackElementKey} from '../../InteractiveTutorial';
import {PartialState} from '@react-navigation/routers/src/types';
import {NavigationState} from '@react-navigation/native';

export type DashboardParams = {
  emptyGroupOptions?: Partial<GroupOption>;
  groupOptionsById?: Record<number, Partial<GroupOption> | undefined>;
  goToAffiliateToken?: string;
  interactiveTutorial?: {
    onSuccess?: PartialState<NavigationState> | NavigationState;
  };
};

export type BottomTabParamList = {
  Dashboard?: DashboardParams;
  Statistics?: {
    groupId?: number;
  };
  Withdraw: undefined;
  Notifications: undefined;
  Menu: {goToAffiliateToken?: string} | undefined;
  Debug: undefined;
};

const {Navigator, Screen} = createBottomTabNavigator<BottomTabParamList>();

export default observer(() => {
  const {debug} = useRoot();
  const tabBarBadge = useNotificationBadge();
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    tabBarBadge: {
      backgroundColor: theme.palette.error,
    },
  }));
  const theme = useTheme();
  const tabBarVisible = !(theme.window.width > 1280 && Platform.OS === 'web');
  return (
    <Navigator
      screenOptions={{
        tabBarStyle: tabBarVisible ? {} : {display: 'none'},
        headerShown: false,
        tabBarShowLabel: false,
      }}>
      <Screen
        name="Dashboard"
        options={{
          tabBarIcon: tabBarIcon(DashboardSvg),
          title: strings['screenTitle.dashboard'],
        }}
        component={DashboardBinding}
      />
      <Screen
        name="Statistics"
        component={StatisticsScreen}
        options={{
          tabBarIcon: tabBarIcon(StatisticsSvg),
          title: strings['screenTitle.statistics'],
          tabBarButton: (props) => (
            <TutorialBottomTabButton
              tutorialKey="TAB_BAR_STATISTICS"
              {...props}
            />
          ),
        }}
      />
      <Screen
        name="Notifications"
        component={NotificationListScreen}
        options={{
          tabBarIcon: tabBarIcon(NotificationSvg),
          tabBarBadge,
          tabBarBadgeStyle: styles.tabBarBadge,
          title: strings['screenTitle.notifications'],
          tabBarButton: (props) => (
            <TutorialBottomTabButton
              tutorialKey="TAB_BAR_NOTIFICATIONS"
              {...props}
            />
          ),
        }}
      />
      <Screen
        name="Withdraw"
        component={WithdrawBindingSwitch}
        options={{
          tabBarIcon: tabBarIcon(PayoutSvg),
          title: strings['withdraw.title'],
        }}
      />
      <Screen
        name="Menu"
        component={MenuBinding}
        options={{
          tabBarIcon: ({focused, size, color}) => (
            // The wrapping View is mandatory for native web
            <View
              style={{
                transform: [{rotate: focused ? '90deg' : '0deg'}],
              }}>
              <DrawerSvg width={size} color={color} />
            </View>
          ),
          tabBarButton: (props) => (
            <TutorialBottomTabButton tutorialKey="TAB_BAR_MENU" {...props} />
          ),
        }}
      />
      {debug.debugEnabled && (
        <Screen
          name="Debug"
          component={DebugBinding}
          options={{tabBarIcon: tabBarIcon(BugReportSvg)}}
        />
      )}
    </Navigator>
  );
});

export type TutorialBottomTabButtonProps = BottomTabBarButtonProps & {
  tutorialKey: StackElementKey;
};

const TutorialBottomTabButton = observer(
  (props: TutorialBottomTabButtonProps) => {
    const {tutorialKey, ...rest} = props;
    const [ref, onLayout, getForceKey] = useTutorialMeasure(tutorialKey);
    return (
      <PressableOpacity
        key={getForceKey()}
        onLayout={onLayout}
        ref={ref}
        {...rest}
      />
    );
  },
);

const tabBarIcon =
  (Icon: React.ComponentType<SvgProps>) =>
  (props: {focused: boolean; color: string; size: number}) =>
    <Icon width={props.size} fill={props.color} color={props.color} />;
