import {LogContent} from './LogContent';
import {Request, Response} from '../JsonRpc';

export default class JsonRpcLogContent implements LogContent {
  constructor(
    public readonly incoming: boolean,
    public readonly message: Request | Response,
  ) {}

  get body() {
    const serialized = JSON.stringify(this.message, undefined, 2);
    return `${this.incoming ? '<--' : '-->'} ${serialized}`;
  }
}
