import React from 'react';
import {
  StyleProp,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import {SvgProps} from 'react-native-svg';

import {variance} from '../styling';

export type CirclePlatformProps = {
  Icon: React.ComponentType<SvgProps>;
  disabled?: boolean;
  scale?: number;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
};

export const CirclePlatform = (props: CirclePlatformProps) => {
  const {Icon, style, scale = 1, disabled, onPress} = props;
  const size = 32 * scale;
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <RootView
        disabled={disabled}
        style={[
          style,
          {
            width: size,
            height: size,
            borderRadius: size / 2,
          },
        ]}>
        <Icon width={14 * scale} height={16 * scale} color="#ffffff" />
      </RootView>
    </TouchableWithoutFeedback>
  );
};

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.chroma(theme.palette.success).brighten(0.1).hex(),
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.75,
    backgroundColor: theme.palette.disabled,
  },
}));
