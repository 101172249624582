import React, {useCallback} from 'react';
import {View} from 'react-native';
import Modal from '../../../modals/BaseModal';
import {ModalTitle} from '../../../modals/components';
import {useStrings} from '../../../Root/hooks';
import {useRoot} from '../../../Root/hooks';
import {MiningGroupBody} from './MiningGroupBody';
import {ButtonVariant} from '../../../components';
import {WorkerGroupEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';
import {expr} from 'mobx-utils';
import {observer} from 'mobx-react-lite';
import {WorkerId} from '../../../ApiStore';
import {useStyles} from '../../../styling';
import {useHandleErrorAndNavigate} from '../../../ExceptionHandler';

interface MoveWorkerToGroupModalProps {
  workerId: WorkerId;
  onDismiss: () => void;
}

const emptyList: WorkerGroupEntity[] = [];

export default observer(
  ({onDismiss, workerId, ...rest}: MoveWorkerToGroupModalProps) => {
    const strings = useStrings();
    const {connectedClient, dashboardStore} = useRoot();
    const handleErrorAndNavigate = useHandleErrorAndNavigate();
    const workerGroupList =
      dashboardStore.dashboardRequest.value?.groups ?? emptyList;

    const groups = expr(() =>
      workerGroupList.filter(
        (group) => !group.workers.some((worker) => worker.id === workerId),
      ),
    );

    const onMoveWorker = useCallback(
      async (groupId: number) => {
        onDismiss();
        const selectedGroup = groups.find((g) => g.id === groupId);
        const selectedGroupWorkersIds = selectedGroup
          ? selectedGroup.workers.map((worker) => worker.id)
          : [];
        const result = await connectedClient.call('set_workers_to_group', {
          group_id: groupId,
          worker_ids: [...selectedGroupWorkersIds, workerId],
        });
        if (!result.success) {
          handleErrorAndNavigate(result.left);
        }
      },
      [connectedClient, handleErrorAndNavigate, groups, onDismiss, workerId],
    );

    const buttons = groups.length
      ? undefined
      : [
          {
            text: strings['action.ok'],
            onPress: onDismiss,
            variant: ButtonVariant.Highlighted,
          },
        ];

    const styles = useStyles((theme) => ({
      title: {
        marginBottom: 20,
        ...theme.mediaQuery({
          1000: {
            marginTop: 15,
            paddingLeft: 20,
          },
        }),
      },
    }));

    return (
      <Modal onDismiss={onDismiss} buttons={buttons} {...rest}>
        <View>
          <ModalTitle style={styles.title}>
            {strings['modal.moveWorkerToGroup.title']}
          </ModalTitle>
          <MiningGroupBody onLinePress={onMoveWorker} groups={groups} />
        </View>
      </Modal>
    );
  },
);
