import {
  getBundleId,
  getReadableVersion,
  getSystemVersion,
} from 'react-native-device-info';
import {Platform} from 'react-native';
import {DeviceId} from '../DeviceIdentification';
import packageFile from '../../package.json';
import {Http} from '../Http';
import {Configuration} from '../Configuration';

export default (
  root: {readonly http: Http; readonly configuration: Configuration},
  deviceId: DeviceId,
) => {
  const appVersion = encodeURIComponent(
    Platform.select({
      web: packageFile.version,
      default: getReadableVersion(),
    }),
  );
  const values = root.configuration.values;
  const path = `${values.mainSocketUrl}${Platform.OS}`;
  const systemVersion =
    Platform.OS === 'web'
      ? encodeURIComponent(navigator.appVersion)
      : encodeURIComponent(getSystemVersion());
  const bundleId =
    Platform.OS === 'web'
      ? values.webBundleId
      : encodeURIComponent(getBundleId());
  const appId =
    Platform.OS === 'web' ? values.webAppId : `${bundleId}.${Platform.OS}`;
  const url = `${path}?v=${appVersion}&app_id=${appId}&device_id=${deviceId}&ov=${systemVersion}&bundle_id=${bundleId}`;
  return root.http.createWebSocket(url);
};
