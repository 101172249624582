import {Client, RpcError} from '../../src/JsonRpc';

export class SocketInstance {
  constructor(private readonly _client: Client) {}

  async send<R>(method: string, params = {}): Promise<R> {
    const result = await this._client.call(method, params);
    if (result.success) {
      return result.right as R;
    }
    throw new RpcError(result.left.message, result.left.code, result.left.data);
  }
}
