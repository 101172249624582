import {observer} from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {RefreshControl, StyleSheet} from 'react-native';
import {RouteProp, useIsFocused} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {RootParamList} from '../Navigation';
import {useRoot} from '../Root/hooks';
import {
  WorkerStatisticsPanelState,
  WorkerStatisticsPanel,
} from '../StatisticsPanel';
import {PageScrollView} from '../containers';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';
import {getWorkerName} from '../DashboardScreen';

export interface WorkerStatisticsScreenProps {
  navigation: StackNavigationProp<RootParamList, 'WorkerStatistics'>;
  route: RouteProp<RootParamList, 'WorkerStatistics'>;
}

export default observer(({navigation, route}: WorkerStatisticsScreenProps) => {
  const {workerId: _workerId} = route.params;
  const root = useRoot();
  const {dashboardStore} = root;
  const workerId = dashboardStore.dashboardRequest.value?.groups
    .flatMap((_) => _.workers)
    .find((_) => _.id === _workerId)?.id;
  const isFocused = useIsFocused();
  useLayoutEffect(() => {
    if (workerId === undefined) {
      navigation.goBack();
    }
  }, [navigation, workerId]);
  const [state] = useState(() => new WorkerStatisticsPanelState(root));
  const fetch = useCallback(() => {
    if (workerId !== undefined) {
      state.fetch(workerId);
    }
  }, [state, workerId]);
  useEffect(() => {
    if (isFocused && workerId !== undefined) {
      state.fetch(workerId);
    }
  }, [isFocused, state, workerId]);
  const workerName = useMemo(() => {
    if (workerId === undefined) {
      return null;
    }
    const worker = dashboardStore.workers.get(workerId);
    if (worker === undefined) {
      return null;
    }
    return getWorkerName(worker);
  }, [dashboardStore.workers, workerId]);
  useEffect(() => {
    if (workerName) {
      navigation.setOptions({
        title: workerName,
        headerTitleContainerStyle: {
          left: 20,
        },
      });
    }
  }, [navigation, workerName]);
  if (workerId === undefined) {
    return null;
  }
  return (
    <>
      <DesktopWebAppHeader />
      <PageScrollView
        refreshControl={
          <RefreshControl refreshing={state.isLoading} onRefresh={fetch} />
        }
        contentContainerStyle={styles.contentContainer}>
        <WorkerStatisticsPanel
          style={styles.panel}
          workerId={workerId}
          state={state}
        />
      </PageScrollView>
    </>
  );
});

const styles = StyleSheet.create({
  contentContainer: {
    paddingBottom: 20,
    paddingHorizontal: 5,
  },
  panel: {
    marginTop: 20,
    borderRadius: 15,
  },
});
