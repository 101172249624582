import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import {useStrings} from '../../Root/hooks';
import TabBar from '../common/TabBar';
import {useForcedContext} from '../../context';
import {PurchaseScreenContext} from '../PurchaseScreenContext';
import SlotTableSectionBinding from '../SlotsTab/SlotTableSectionBinding';
import PoolMinersTabBinding from '../PoolMinersTab/PoolMinersTabBinding';
import {variance} from '../../styling';
import {PurchaseTabsKind} from '../states/PurchaseScreenState';

export type PurchaseCategoryTabsBindingProps = {
  onSelectConfigurationPress: () => void;
};

export default observer(function PurchaseCategoryTabsBinding(
  props: PurchaseCategoryTabsBindingProps,
) {
  const {onSelectConfigurationPress} = props;

  const {
    state: {selectedTab, selectTab, selectedSlotProposal},
  } = useForcedContext(PurchaseScreenContext);
  const strings = useStrings();
  const routes = useMemo(
    () => [
      {key: 'addSlots', title: strings['purchase.tabs.subscriptions']},
      {
        key: 'poolMiners',
        title: strings['purchase.tabs.poolMiners'],
      },
    ],
    [strings],
  );
  return (
    <TabRoot>
      <TabBar
        onSelectTab={selectTab}
        selectedRoute={selectedTab}
        routes={routes}
        slot={selectedSlotProposal}
      />
      {selectedTab === PurchaseTabsKind.Miner && (
        <PoolMinersTabBinding
          onConfigurationPress={onSelectConfigurationPress}
          disableScroll={() => {}}
          enableScroll={() => {}}
        />
      )}
      {selectedTab === PurchaseTabsKind.Slot && <SlotTableSectionBinding />}
    </TabRoot>
  );
});

const TabRoot = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: theme.palette.border,
    margin: 15,
    ...theme.mediaQuery({
      1000: {
        margin: 0,
      },
    }),
  },
}));
