import messaging from '@react-native-firebase/messaging';
import {BusImpl, Disposer, Service} from '../structure';
import {Messaging} from './Messaging';
import {MessageDescription} from './Message';
import {MessageHandler} from './MessageHandler';

export default class MobileMessagingService implements Messaging, Service {
  constructor(
    private readonly _parent: {readonly messageHandler: MessageHandler},
  ) {}

  public readonly income = new BusImpl<MessageDescription>();

  subscribe() {
    return messaging().onMessage(async (message) => {
      if (!message.data || message.notification) {
        return;
      }
      const description = message.data as MessageDescription;
      this.income.send(description);
      const context_ = await this._parent.messageHandler.handle(description);
      if (!context_.success) {
        return;
      }
    }) as Disposer;
  }
}
