import {comparer, reaction} from 'mobx';
import {Auth} from '../Auth';
import {Translation} from '../Localization';
import {ConnectedClient} from '../ContextClient';

export default class NotificationRegistrationService {
  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly translation: Translation;
      readonly connectedClient: ConnectedClient;
    },
  ) {}

  subscribe() {
    return reaction(
      () =>
        [this._root.auth.isConnected, this._root.translation.locale] as const,
      async ([isConnected, locale]) => {
        if (isConnected) {
          await this._root.connectedClient.call('set_lang', {lang: locale});
        }
      },
      {equals: comparer.shallow, fireImmediately: true},
    );
  }
}
