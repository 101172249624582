import {useCallback, useRef} from 'react';
import {getGroupSortingParams} from './groupSortingParams';
import {useRoot} from '../../Root/hooks';
import {useNavigation} from '@react-navigation/native';
import {DashboardScreenProps} from '../DashboardScreenProps';

export default () => {
  const {auth} = useRoot();
  const navigation = useNavigation<DashboardScreenProps['navigation']>();
  const restored = useRef(false);
  return useCallback(async () => {
    const params = await getGroupSortingParams();
    if (params.success && params.right && !restored.current) {
      if (auth.accountId === params.right.farmId) {
        navigation.setParams(params.right.dashboard);
      } else {
        navigation.setParams({
          emptyGroupOptions: undefined,
          groupOptionsById: undefined,
        });
      }
      restored.current = true;
    }
  }, [auth.accountId, navigation]);
};
