import {Cannon} from './Cannon';
import {Service} from '../structure';
import {APP_WINDOW_ACTIVE, AppWindowState} from '../AppWindow';
import {Connection} from './Connection';
import {autorun, flow, observable, makeObservable} from 'mobx';
import {ConnectionManager} from './ConnectionManager';
import {Configuration} from '../Configuration';
import {now} from '../Time';
import {Op} from '../Math';
import {bind} from '../fp';
import {CustomDisconnect} from '../CustomDisconnect';

export default class CannonService implements Cannon, Service {
  @observable private _firingUntil = now();
  @observable private _isFiring = false;

  constructor(
    private readonly _root: {
      readonly appWindowState: AppWindowState;
      readonly connection: Connection;
      readonly connectionManager: ConnectionManager;
      readonly configuration: Configuration;
      readonly customDisconnect: CustomDisconnect;
    },
  ) {
    makeObservable(this);
  }

  get isFiring() {
    return this._isFiring;
  }

  get firingUntil() {
    return this._firingUntil;
  }

  fire = bind(
    flow(function* (this: CannonService) {
      if (this._isFiring) {
        return;
      }
      this._isFiring = true;
      const duration = this._root.configuration.values.cannonDuration;
      this._firingUntil = Op.add(now(), duration);
      try {
        yield this._root.connectionManager.fireCannon(duration);
      } catch (ignore) {
      } finally {
        this._isFiring = false;
      }
    }),
    this,
  );

  subscribe() {
    return autorun(() => {
      if (
        this._root.appWindowState.status === APP_WINDOW_ACTIVE &&
        !this._root.connection.isConnected &&
        !this._root.customDisconnect.active
      ) {
        this.fire();
      }
    });
  }
}
