import {useRoot} from '../Root/hooks';
import useHandleErrorAndExtractPromo from './useHandleErrorAndExtractPromo';
import {useCallback} from 'react';
import dayjs from 'dayjs';
import {ISODateString} from '../Time';
import {MinerOffer, PaymentMethod} from '../InAppOffersService';
import {ErrorHandlerSample} from '../ExceptionHandler/useHandleErrorFactory';
import {PaymentType} from './index';
import {openLink} from '../Links';

export type HookRenewPoolMinerParams = {
  errorHandler: ErrorHandlerSample;
};

export type RenewPoolMinerParams = {
  offer: MinerOffer;
  paymentMethod: PaymentMethod;
  goToPoolMinerActivated(
    poolExpiredDate: ISODateString,
    poolHashrate: number,
  ): void;
  poolMinerId: number;
};

export default function useRenewPoolMiner({
  errorHandler,
}: HookRenewPoolMinerParams) {
  const {
    purchaseRequester,
    translation: {localeTag},
  } = useRoot();
  const handle = useHandleErrorAndExtractPromo({errorHandler});
  return useCallback(
    async (params: RenewPoolMinerParams) => {
      const {offer, poolMinerId, paymentMethod, goToPoolMinerActivated} =
        params;
      const requestParams = {
        purchaseId: offer.purchaseId,
        purchaseTokenAndroid: undefined,
        payment: paymentMethod,
        poolMinerId: poolMinerId,
        poolMinerIdForRenew: undefined,
        subscriptionOfferTokenAndroid: offer.subscriptionOfferTokenAndroid,
      };
      const response = await purchaseRequester.request(requestParams);
      const process_ = await handle(response);
      if (!process_.success) {
        return;
      }
      if (response.success) {
        switch (response.right.kind) {
          case PaymentType.InAppCompleted: {
            const newExpireDate = dayjs()
              .locale(localeTag)
              .add(offer.interval, 'month')
              .toISOString() as ISODateString;
            goToPoolMinerActivated(
              newExpireDate,
              offer.poolMinerConfig.hash_rate,
            );
            break;
          }
          case PaymentType.ExternalOrder: {
            if (response.right.redirectUrl) {
              await openLink(response.right.redirectUrl);
            }
            break;
          }
        }
      }
    },
    [purchaseRequester, handle, localeTag],
  );
}
