import {Interval} from '../ApiStore';
import {
  Offer,
  MinerProductOffer,
  MinerSubscriptionOffer,
} from '../InAppOffersService';
import {Router} from '../structure';
import {MinerProposalsState} from './MinerProposalsState';
import {SlotProposalsState} from './SlotProposalsState';

export const SETTLED = Symbol();

export interface ProposalsState extends DerivedFields {
  readonly isLoadedIn: boolean;
  readonly events: Router<{
    [SETTLED]: void;
  }>;

  reset(): void;
}

interface DerivedFields
  extends Pick<
      MinerProposalsState,
      | 'minerProductProposalList'
      | 'minerSubscriptionProposalList'
      | 'minerProductProposalById'
      | 'minerSubscriptionProposalById'
      | 'doubleProposalByIntervalByHashrate'
      | 'minerProposalById'
      | 'minerProposalList'
      | 'uniqIntervalList'
      | 'minerConfigsByInterval'
      | 'minerConfigByHashrate'
      | 'minerConfigs'
      | 'uniqProductIntervalList'
      | 'doubleProposalList'
    >,
    Pick<
      SlotProposalsState,
      | 'slotSubscriptionProposalById'
      | 'slotSubscriptionProposalList'
      | 'slotUniqIntervalList'
    > {}

export type Proposal<O extends Offer = Offer> = {
  available: boolean;
  offer: O;
};

export type DoubleProposal = {
  product: Proposal<MinerProductOffer> | undefined;
  subscription: ManagedProposalSubscription<MinerSubscriptionOffer> | undefined;
};

export type GroupContextProposal<P> = P & {
  maximumHashrate?: number;
};
export type GroupContextDoubleProposal = GroupContextProposal<DoubleProposal>;

export enum SubscriptionCandidateKind {
  Unavailable,
  CanBeImproved,
  CanBeManaged,
  Available,
}

export type ManagedProposalSubscription<O extends Offer> = Proposal<O> & {
  kind: SubscriptionCandidateKind;
};

export type ProposalByInterval<P> = ReadonlyMap<Interval, P>;
