import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {Platform, Text, View} from 'react-native';
import {Button, ButtonVariant} from '../components';
import {useStrings} from '../Root/hooks';
import RectSvg from '../assets/svg/colorless/rects.svg';
import FlashCheckSvg from '../assets/svg/colored/FlashCheck.svg';
import {TouchableOpacity} from 'react-native-gesture-handler';

export type PaymentCompletedViewProps = {
  toDashboard: () => void;
};

export default observer(function PaymentCompletedView(
  props: PaymentCompletedViewProps,
) {
  const strings = useStrings();
  return (
    <>
      <Body>
        <FlashCheckIcon />
        <Title>{strings['paymentCompleted.title']}</Title>
        <Description>{strings['paymentCompleted.description']}</Description>
      </Body>
      <Footer>
        <Btn onPress={props.toDashboard}>
          <Button variant={ButtonVariant.Highlighted} Icon={RectSvg}>
            {strings['paymentSuccess.openDashboard']}
          </Button>
        </Btn>
      </Footer>
    </>
  );
});

const FlashCheckIcon = variance(FlashCheckSvg)(() => ({
  root: {
    width: 124,
    height: 70,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 20,
  },
}));

const Body = variance(View)((theme) => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 30,
    ...theme.mediaQuery({
      1000: {
        paddingHorizontal: 30,
        paddingTop: 40,
        paddingBottom: 50,
      },
    }),
  },
}));

const Footer = variance(View)((theme) => ({
  root: {
    padding: 15,
    backgroundColor: theme.palette.back,
    borderTopWidth: 1,
    borderTopColor: theme.palette.border,
  },
}));

const Btn = variance(TouchableOpacity)((theme) => ({
  root: {
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
    ...theme.mediaQuery({
      1000: {
        maxWidth: 300,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
  },
}));

const Title = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    textAlign: 'center',
    color: theme.palette.textPrimary,
    marginBottom: 10,
    fontSize: 24,
    lineHeight: 34,
    ...theme.mediaQuery({
      1000: {
        fontSize: 32,
        lineHeight: 40,
      },
    }),
  },
}));

const Description = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.textSecondary,
  },
}));
