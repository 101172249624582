import {
  PurchaseResult,
  Subscription,
  SubscriptionPurchase,
  Purchase as OriginalInAppPurchase,
  ProductPurchase,
  Product,
  PurchaseError,
  requestPurchase,
  requestSubscription,
} from 'react-native-iap';
import {EmitterSubscription} from 'react-native';
import {Router} from '../structure';
import {PurchaseId} from '../units';
import {AppStoreReceipt} from '../ApiStore';
import {AsyncReturnType} from 'type-fest';

export const INIT_CONNECTION_REQUEST = Symbol();
export const INIT_CONNECTION = Symbol();

export const END_CONNECTION = Symbol();
export const END_CONNECTION_REQUEST = Symbol();

export const GET_AVAILABLE_PURCHASES_REQUEST = Symbol();
export const GET_AVAILABLE_PURCHASES = Symbol();

export const GET_PURCHASE_HISTORY_REQUEST = Symbol();
export const GET_PURCHASE_HISTORY = Symbol();

export const GET_SUBSCRIPTIONS_REQUEST = Symbol();
export const GET_SUBSCRIPTIONS = Symbol();

export const GET_PRODUCTS_REQUEST = Symbol();
export const GET_PRODUCTS = Symbol();

export const REQUEST_SUBSCRIPTION_REQUEST = Symbol();
export const REQUEST_SUBSCRIPTION = Symbol();

export const REQUEST_PRODUCT_REQUEST = Symbol();
export const REQUEST_PRODUCT = Symbol();

export const CONSUME_PURCHASE_ANDROID_REQUEST = Symbol();
export const CONSUME_PURCHASE_ANDROID = Symbol();

export const FINISH_TRANSACTION_REQUEST = Symbol();
export const FINISH_TRANSACTION = Symbol();

export const GET_PENDING_PURCHASES_IOS_REQUEST = Symbol();
export const GET_PENDING_PURCHASES_IOS = Symbol();

export const GET_RECEIPT_IOS_REQUEST = Symbol();
export const GET_RECEIPT_IOS = Symbol();

export const PURCHASE_UPDATED_LISTENER = Symbol();

export const PURCHASE_ERROR_LISTENER = Symbol();

export interface InAppPurchase {
  initConnection: () => InitConnectionResponse;
  endConnection: () => Promise<void>;
  getAvailablePurchases: () => Promise<
    (OriginalInAppPurchase | SubscriptionPurchase)[]
  >;
  getPurchaseHistory: () => Promise<
    (OriginalInAppPurchase | SubscriptionPurchase)[]
  >;
  getSubscriptions: (skus: PurchaseId[]) => Promise<Subscription[]>;
  getProducts: (skus: PurchaseId[]) => Promise<Product[]>;
  requestSubscription: (params: RequestPurchaseParams) => Promise<void>;
  requestProduct: (params: RequestPurchaseParams) => Promise<void>;
  finishTransaction(
    purchase: ProductPurchase,
    isConsumable?: boolean,
  ): Promise<PurchaseResult | boolean>;
  getPendingPurchasesIOS: () => Promise<ProductPurchase[]>;
  getReceiptIOS: () => Promise<AppStoreReceipt>;
  purchaseUpdatedListener: (
    listener: (event: OriginalInAppPurchase | SubscriptionPurchase) => void,
  ) => EmitterSubscription;
  purchaseErrorListener: (
    listener: (errorEvent: PurchaseError) => void,
  ) => EmitterSubscription;

  readonly events: Router<IapEvents>;
}

export type RequestPurchaseParams = {
  sku: PurchaseId;
  subscriptionOfferTokenAndroid: string | undefined;
  purchaseTokenAndroid: string | undefined;
  obfuscatedAccountIdAndroid: string | undefined;
  obfuscatedProfileIdAndroid: string | undefined;
};

export type IapEvents = {
  [INIT_CONNECTION_REQUEST]: void;
  [INIT_CONNECTION]: Boolean;

  [END_CONNECTION_REQUEST]: void;
  [END_CONNECTION]: void;

  [GET_AVAILABLE_PURCHASES_REQUEST]: void;
  [GET_AVAILABLE_PURCHASES]: (OriginalInAppPurchase | SubscriptionPurchase)[];

  [GET_PURCHASE_HISTORY_REQUEST]: void;
  [GET_PURCHASE_HISTORY]: (OriginalInAppPurchase | SubscriptionPurchase)[];

  [GET_SUBSCRIPTIONS_REQUEST]: PurchaseId[];
  [GET_SUBSCRIPTIONS]: {
    cache: Subscription[];
    response: Subscription[];
  };

  [GET_PRODUCTS_REQUEST]: PurchaseId[];
  [GET_PRODUCTS]: {
    cache: Product[];
    response: Product[];
  };

  [REQUEST_SUBSCRIPTION_REQUEST]: RequestPurchaseParams;
  [REQUEST_SUBSCRIPTION]: AsyncReturnType<typeof requestSubscription>;

  [REQUEST_PRODUCT_REQUEST]: RequestPurchaseParams;
  [REQUEST_PRODUCT]: AsyncReturnType<typeof requestPurchase>;

  [CONSUME_PURCHASE_ANDROID_REQUEST]: string;
  [CONSUME_PURCHASE_ANDROID]: PurchaseResult;

  [FINISH_TRANSACTION_REQUEST]: ProductPurchase;
  [FINISH_TRANSACTION]: boolean | PurchaseResult;

  [GET_PENDING_PURCHASES_IOS_REQUEST]: void;
  [GET_PENDING_PURCHASES_IOS]: ProductPurchase[];

  [GET_RECEIPT_IOS_REQUEST]: void;
  [GET_RECEIPT_IOS]: string;

  [PURCHASE_UPDATED_LISTENER]: void;

  [PURCHASE_ERROR_LISTENER]: void;
};

export type InitConnectionResponse = Promise<Boolean>;
