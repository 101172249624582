import {variance} from '../../styling';
import {Text, View} from 'react-native';

export const TotalPriceItemContainer = variance(View)((theme) => ({
  root: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.border,
    borderStyle: 'dashed',
    alignItems: 'flex-start',
    ...theme.mediaQuery({
      1000: {
        paddingHorizontal: 0,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    }),
  },
  noBorder: {
    borderBottomWidth: 0,
    ...theme.mediaQuery({
      1000: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      },
    }),
  },
}));

export const TotalPriceItemTop = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    ...theme.mediaQuery({
      1000: {
        marginBottom: 0,
      },
    }),
  },
}));

export const TotalPriceItemTopText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 16,
    marginLeft: 8,
    ...theme.mediaQuery({
      1000: {
        marginLeft: 10,
        ...theme.fontByWeight('400'),
        fontSize: 20,
      },
    }),
  },
}));

export const TopPrice = variance(View)((theme) => ({
  root: {
    marginBottom: 10,
    ...theme.mediaQuery({
      1000: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    }),
  },
}));

export const TopPriceText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 26,
    lineHeight: 31,
  },

  sm: {
    fontSize: 18,
    lineHeight: 21,
    marginBottom: 10,
  },
}));

export const CalculationPriceText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 0.05,
    color: theme.palette.textSecondary,
    ...theme.mediaQuery({
      1000: {
        marginLeft: 'auto',
      },
    }),
  },
}));
