import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {
  LinkingOptions,
  NavigationContainer,
  ParamListBase,
} from '@react-navigation/native';
import ChangePoolMinerSpeed from '../DashboardScreen/modals/ChangePoolMinerSpeed';
import {StyleSheet, View} from 'react-native';
import {AuthStack} from './AuthStack';
import {useRoot, useStrings, useTemplates} from '../Root/hooks';
import {useGetNavigationTheme} from './hooks';
import {DocumentTitleOptions} from '@react-navigation/native/lib/typescript/src/types';
import {PREFIXES} from '../LinkingOptionsProvider/constant';
import {RootStack} from './RootStack';

export default observer(function Navigation() {
  const {
    auth,
    navigationContainerBinding,
    linkingOptionsProvider,
    initialStateNavigation,
  } = useRoot();
  const navigationTheme = useGetNavigationTheme();
  const templates = useTemplates();
  const strings = useStrings();
  const documentTitle: DocumentTitleOptions = useMemo(
    () => ({
      formatter: (options) =>
        options?.title
          ? templates['page.webTitle']({
              title: options?.title,
            })
          : strings['page.defaultWebTitle'],
    }),
    [strings, templates],
  );
  return (
    <>
      {initialStateNavigation.appNavigationIsReadyToRender && (
        <NavigationContainer
          theme={navigationTheme}
          documentTitle={documentTitle}
          linking={linkingOptionsProvider.linkingOptions}
          initialState={initialStateNavigation.initialState}
          {...navigationContainerBinding.props}>
          {auth.initialized && <RootStack />}
        </NavigationContainer>
      )}

      <ChangePoolMinerSpeed />
      <View style={styles.layer}>
        <NavigationContainer
          theme={navigationTheme}
          documentTitle={documentTitle}
          linking={authLinkingOptions}
          independent>
          <AuthStack />
        </NavigationContainer>
      </View>
    </>
  );
});

const styles = StyleSheet.create({
  layer: {
    backgroundColor: 'transparent',
    pointerEvents: 'box-none',
    ...StyleSheet.absoluteFillObject,
  },
});

const authLinkingOptions: LinkingOptions<ParamListBase> = {
  enabled: false,
  prefixes: PREFIXES,
};
