import {useRoot} from '../Root/hooks';
import {useTemplates} from '../Root/hooks';
import {useCallback} from 'react';
import {useHandleErrorAndNavigate} from '../ExceptionHandler';

export default function useShareSetupLink() {
  const templates = useTemplates();
  const {
    connectedClient,
    share,
    configuration: {values},
  } = useRoot();
  const handleErrorAndNavigate = useHandleErrorAndNavigate();
  const shareLink = useCallback(async () => {
    const result = await connectedClient.call('new_worker', {
      configuration: undefined,
    });
    if (!result.success) {
      handleErrorAndNavigate(result.left);
      return;
    }
    const {worker_connection_id} = result.right;
    const message = templates['worker.shareDescription']({
      id: worker_connection_id,
      downloadFarmMinerUrl: values.downloadFarmMinerUrl.replace(
        '{id}',
        worker_connection_id,
      ),
      downloadUrl: values.downloadUrl,
      aboutUrl: values.aboutUrl,
    });
    try {
      await share.shareMessage(message);
    } catch (ignore) {}
  }, [connectedClient, handleErrorAndNavigate, share, templates, values]);
  return {shareLink};
}
