import React, {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import Svg, {Defs, G, Image as SvgImage, Mask, Path} from 'react-native-svg';
import clamp from '../utils/clamp';
import {BUTTON_WIDTH} from './Slider';
import {SliderStatus} from './PoolMinerActivator';
import {useRoot} from '../Root/hooks';
import {IMAGES, PUZZLE_SIZE} from './PuzzleState';
import {Platform, StyleSheet} from 'react-native';

export default observer(() => {
  const {
    poolMinerActivator: {
      puzzle: {
        translationX,
        targetPosition,
        sliderStatus,
        backgroundImageIndex,
        puzzleWidth,
        puzzleHeight,
      },
    },
  } = useRoot();
  const calcPuzzleX = useCallback(
    (value: number) => clamp(value, 0, puzzleWidth - BUTTON_WIDTH),
    [puzzleWidth],
  );
  const calcPuzzleY = useCallback(() => {
    if (!targetPosition) {
      return 0;
    }
    return targetPosition?.y;
  }, [targetPosition]);
  const defaultPuzzleX = calcPuzzleX(0);
  const defaultPuzzleY = calcPuzzleY();
  const [puzzleX, setPuzzleX] = useState(defaultPuzzleX);
  useEffect(() => {
    translationX.addListener((v) => {
      setPuzzleX(calcPuzzleX(v.value));
    });
  }, [calcPuzzleX, calcPuzzleY, translationX]);
  if (!targetPosition || sliderStatus === SliderStatus.Confirming) {
    return null;
  }
  const imageX = -targetPosition.x;
  const imageY = -targetPosition.y;
  const backgroundImageProps = {
    id: 'Background',
    x: imageX,
    y: imageY,
    href: IMAGES[backgroundImageIndex],
    width: puzzleWidth,
    height: puzzleHeight,
    preserveAspectRatio: 'xMidYMid slice',
  };
  return (
    <Svg style={styles.root} width={puzzleWidth} height={puzzleHeight}>
      <Defs>
        <Mask id="Mask">
          <Svg
            width={PUZZLE_SIZE.width}
            height={PUZZLE_SIZE.height}
            viewBox="0 0 63 65">
            <Path
              id="Puzzle"
              d="M21.9142 0.571777C26.0275 0.571777 29.3619 3.90623 29.3619 8.01945C29.3619 9.37847 28.9892 10.6465 28.3534 11.7433H43.016V26.4059C44.1128 25.77 45.3808 25.3974 46.7398 25.3974C50.8531 25.3974 54.1875 28.7318 54.1875 32.845C54.1875 36.9583 50.8531 40.2927 46.7398 40.2927C45.3808 40.2927 44.1128 39.92 43.016 39.2842V53.9468H27.4612C28.6415 52.6289 29.3619 50.8901 29.3619 48.9817C29.3619 44.8684 26.0275 41.534 21.9142 41.534C17.801 41.534 14.4666 44.8684 14.4666 48.9817C14.4666 50.8901 15.1869 52.6289 16.3673 53.9468H0.8125V38.392C2.13037 39.5723 3.86921 40.2927 5.77762 40.2927C9.89084 40.2927 13.2253 36.9583 13.2253 32.845C13.2253 28.7318 9.89084 25.3974 5.77762 25.3974C3.86921 25.3974 2.13037 26.1177 0.8125 27.2981V11.7433H15.4751C14.8393 10.6465 14.4666 9.37847 14.4666 8.01945C14.4666 3.90623 17.801 0.571777 21.9142 0.571777Z"
              fill="#fff"
            />
          </Svg>
        </Mask>
      </Defs>
      <G x={puzzleX - 2} y={defaultPuzzleY - 2}>
        <G x={-2.6} y={-2.5}>
          <Path
            stroke="#fff"
            fill="#fff"
            strokeWidth="3.8125"
            d="M26.1017 3.5719C30.215 3.5719 33.5494 6.90635 33.5494 11.0196C33.5494 12.3786 33.1767 13.6466 32.5409 14.7434H47.2035V29.406C48.3003 28.7702 49.5683 28.3975 50.9273 28.3975C55.0406 28.3975 58.375 31.7319 58.375 35.8452C58.375 39.9584 55.0406 43.2928 50.9273 43.2928C49.5683 43.2928 48.3003 42.9201 47.2035 42.2843V56.9469H31.6487C32.829 55.629 33.5494 53.8902 33.5494 51.9818C33.5494 47.8686 30.215 44.5341 26.1017 44.5341C21.9885 44.5341 18.6541 47.8686 18.6541 51.9818C18.6541 53.8902 19.3744 55.629 20.5548 56.9469H5V41.3921C6.31787 42.5725 8.05671 43.2928 9.96512 43.2928C14.0783 43.2928 17.4128 39.9584 17.4128 35.8452C17.4128 31.7319 14.0783 28.3975 9.96512 28.3975C8.05671 28.3975 6.31787 29.1179 5 30.2982V14.7434H19.6626C19.0268 13.6466 18.6541 12.3786 18.6541 11.0196C18.6541 6.90635 21.9885 3.5719 26.1017 3.5719Z"
          />
        </G>

        {Platform.OS === 'web' ? (
          <image {...backgroundImageProps} mask="url(#Mask)" />
        ) : (
          <SvgImage {...backgroundImageProps} mask="url(#Mask)" />
        )}
      </G>
    </Svg>
  );
});

const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject,
  },
});
