import React from 'react';
import {observer} from 'mobx-react-lite';
import {Platform, Text, View} from 'react-native';
import {sized} from '../Svg';
import WarningSvg from '../assets/svg/circled/warning.svg';
import {useStyles} from '../styling';
import {useStrings} from '../Root/hooks';
import SignInSvg from '../assets/svg/colorless/signIn.svg';
import useOpenStoreAuth from '../PurchaseScreen/hooks/useOpenStoreAuth';
import {Button, ButtonVariant, PressableOpacity} from '../components';

export const PurchaseNotAvailable = observer(() => {
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    body: {
      paddingVertical: 30,
      paddingHorizontal: 15,
      alignItems: 'center',
    },
    footer: {
      padding: 15,
    },
    text: {
      ...theme.fontByWeight('400'),
      color: theme.palette.textSecondary,
      fontSize: 14,
      lineHeight: 22,
      textAlign: 'center',
    },
    icon: {
      marginBottom: 20,
    },
  }));
  const description =
    Platform.OS === 'ios'
      ? strings['subscription.purchaseIsNotAvailable.description.ios']
      : strings['subscription.purchaseIsNotAvailable.description.android'];
  const openStore = useOpenStoreAuth();
  return (
    <View>
      <View style={styles.body}>
        <WarningIcon style={styles.icon} />
        <Text style={styles.text}>{description}</Text>
      </View>
      <View style={styles.footer}>
        <PressableOpacity onPress={openStore}>
          <Button Icon={SignInIcon} variant={ButtonVariant.Highlighted}>
            {strings['subscription.purchaseIsNotAvailable.button']}
          </Button>
        </PressableOpacity>
      </View>
    </View>
  );
});

const WarningIcon = sized(WarningSvg, 60);
const SignInIcon = sized(SignInSvg, 18);
