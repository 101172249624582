import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, StyleSheet, Image} from 'react-native';
import {useStyles} from '../styling';
import {useRoot} from '../Root/hooks';
import {IMAGES} from './PuzzleState';

export default observer(() => {
  const {
    poolMinerActivator: {puzzle},
  } = useRoot();
  const styles = useStyles((theme) => ({
    root: {
      ...StyleSheet.absoluteFillObject,
      borderRadius: 6,
      overflow: 'hidden',
      backgroundColor: theme.palette.backdrop,
    },
    background: {
      height: '100%',
      width: '100%',
    },
  }));
  return (
    <View style={styles.root}>
      <Image
        style={styles.background}
        source={IMAGES[puzzle.backgroundImageIndex]}
        resizeMode="cover"
      />
    </View>
  );
});
