import {SectionListData} from 'react-native';

interface SectionHeader {
  type: 'SECTION_HEADER';
}

interface Row {
  type: 'ROW';
  index: number;
}

interface SectionFooter {
  type: 'SECTION_FOOTER';
}

type ListElement = SectionHeader | Row | SectionFooter;

export interface Parameters<T, S> {
  getItemHeight: () => number;
  getSectionHeaderHeight?: (sectionData: SectionListData<T, S>) => number;
  getSectionFooterHeight?: (sectionData: SectionListData<T, S>) => number;
  listHeaderHeight?: number | (() => number);
  listFooterHeight?: number | (() => number);
}

export default <T = any, S = any>({
    getItemHeight,
    getSectionHeaderHeight = () => 0,
    getSectionFooterHeight = () => 0,
    listHeaderHeight = 0,
    listFooterHeight = 0,
  }: Parameters<T, S>) =>
  (sectionListData: SectionListData<T, S>[] | null, visibleIndex: number) => {
    let i = 0;
    let sectionIndex = 0;
    let elementPointer: ListElement = {type: 'SECTION_HEADER'};
    let offset =
      typeof listHeaderHeight === 'function'
        ? listHeaderHeight()
        : listHeaderHeight;
    offset +=
      typeof listFooterHeight === 'function'
        ? listFooterHeight()
        : listFooterHeight;
    if (!sectionListData) {
      return {length: 0, offset, index: visibleIndex};
    }
    while (i < visibleIndex) {
      switch (elementPointer.type) {
        case 'SECTION_HEADER': {
          const section = sectionListData[sectionIndex];
          offset += getSectionHeaderHeight(section);
          // If this section is empty, we go right to the footer...
          if (section.data.length === 0) {
            elementPointer = {type: 'SECTION_FOOTER'};
            // ...otherwise we make elementPointer point at the first row in this section
          } else {
            elementPointer = {type: 'ROW', index: 0};
          }
          break;
        }
        case 'ROW': {
          const sectionData = sectionListData[sectionIndex].data;
          const rowIndex = elementPointer.index;
          offset += getItemHeight();
          elementPointer.index += 1;
          if (rowIndex === sectionData.length - 1) {
            elementPointer = {type: 'SECTION_FOOTER'};
          }
          break;
        }
        case 'SECTION_FOOTER': {
          const section = sectionListData[sectionIndex];
          offset += getSectionFooterHeight(section);
          sectionIndex += 1;
          elementPointer = {type: 'SECTION_HEADER'};
          break;
        }
      }
      i += 1;
    }

    let length;
    switch (elementPointer.type) {
      case 'SECTION_HEADER':
        length = getSectionHeaderHeight(sectionListData[sectionIndex]);
        break;
      case 'ROW':
        length = getItemHeight();
        break;
      case 'SECTION_FOOTER':
        length = getSectionFooterHeight(sectionListData[sectionIndex]);
        break;
      default:
        throw new Error('Unknown elementPointer.type');
    }
    return {length, offset, index: visibleIndex};
  };
