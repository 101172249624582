import {useRoot} from '../Root/hooks';
import {useEffect, useState} from 'react';
import WithdrawalHistoryRequestFactory from './WithdrawalHistoryRequestFactory';
import {autorun} from 'mobx';

export default () => {
  const root = useRoot();
  const [request] = useState(() =>
    new WithdrawalHistoryRequestFactory(root).create(),
  );
  useEffect(
    () =>
      autorun(() => {
        if (!request.list) {
          request.refresh();
        }
      }),
    [request],
  );
  return request;
};
