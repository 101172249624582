import React from 'react';
import {observer} from 'mobx-react-lite';
import {SelectButton} from '../PurchaseScreen/PoolMinersTab/components/SelectButton';
import {useStrings} from '../Root/hooks';
import {sized} from '../Svg';
import ReloadSvg from '../assets/svg/colorless/reload.svg';
import {Offer, PaymentMethod} from '../InAppOffersService';

export type RenewButtonSectionProps = {
  selectedPoolOffer: Offer | undefined;
  onPress: () => void;
};

export const RenewButtonSection = observer((props: RenewButtonSectionProps) => {
  const {selectedPoolOffer, onPress} = props;
  const strings = useStrings();
  const payment = selectedPoolOffer?.payments.get(PaymentMethod.InApp);
  const button = payment
    ? `${strings['renewAccess.buttonRenewAccess']} / ${payment.price} ${payment.currency}`
    : strings['renewAccess.buttonRenewAccess'];
  return (
    <SelectButton
      disabled={!selectedPoolOffer}
      Icon={ReloadIcon}
      title={button}
      onSelectPress={onPress}
    />
  );
});

const ReloadIcon = sized(ReloadSvg, 20);
