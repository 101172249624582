import React, {useRef} from 'react';
import DayPicker from 'react-day-picker';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';
import 'react-day-picker/lib/style.css';
import './reactDayPicker.css';

import {DatePickerProps} from './DatePicker';

import useOutside from '../../../universal/utils/react/hook/useOutside.web';
import useLocale from './useLocale';
import useStyles from './useStyles';

interface PickerProps extends DatePickerProps {}

export const Picker = observer(
  ({
    styleWeb,
    isVisible,
    onConfirm,
    onCancel,
    date,
    maximumDate,
  }: PickerProps) => {
    const {locale, weekdaysLong, weekdaysShort, firstDayOfWeek, months} =
      useLocale();
    const styles = useStyles();

    const wrapperRef = useRef<View>(null);
    useOutside(wrapperRef, onCancel);

    if (!isVisible) {
      return null;
    }

    const disabledDays = maximumDate ? {after: maximumDate} : undefined;
    return (
      <View style={[styles.root, styleWeb]} ref={wrapperRef}>
        <DayPicker
          disabledDays={disabledDays}
          selectedDays={date}
          month={date}
          locale={locale}
          weekdaysLong={weekdaysLong}
          weekdaysShort={weekdaysShort}
          months={months}
          firstDayOfWeek={firstDayOfWeek}
          onDayClick={onConfirm}
        />
      </View>
    );
  },
);
