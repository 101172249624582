import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {WorkersList} from './WorkersList';
import {Text, TouchableOpacity, View} from 'react-native';
import {getWorkerName} from '../../DashboardScreen';
import {useStrings, useTemplates} from '../../Root/hooks';
import ScheduleState from '../ScheduleState';
import {useStyles, useTheme} from '../../styling';
import {sized} from '../../Svg';
import CaretDownSvg from '../../assets/svg/colorless/caretDown.svg';
import getWorkerDescription from '../../DashboardScreen/getters/getWorkerDescription';
import {useToggle} from '../../../universal/utils/react/hook/useToggle';
import {WorkerType} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import Markdown from '../../components/Markdown';

interface SchedulerHeaderProps {
  state: ScheduleState;
}

export default observer((props: SchedulerHeaderProps) => {
  const {state} = props;
  const styles = useStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.base,
    },
  }));
  return (
    <View style={styles.root}>
      {state.isSingleGroup ? (
        <SingleWorkerGroupHeader {...props} />
      ) : (
        <WorkerGroupHeader {...props} />
      )}
      {state.hasPoolMiner && <SchedulerForPoolMinerBanner {...props} />}
    </View>
  );
});

const WorkerGroupHeader = observer(({state}: SchedulerHeaderProps) => {
  const {workers, userTimezoneMinutes, selectedWorkerIds} = state;
  const onWorkerSelect = useCallback(
    (id: string) => {
      if (state.selectedWorkerIds.has(id)) {
        state.unselectWorkerId(id);
      } else {
        state.selectWorkerId(id);
      }
    },
    [state],
  );
  const styles = useStyles(() => ({
    root: {
      padding: 20,
    },
  }));

  return (
    <View style={styles.root}>
      <WorkersList
        timezoneOffset={userTimezoneMinutes}
        workers={workers}
        selectedWorkerIdList={[...selectedWorkerIds]}
        onWorkerSelect={onWorkerSelect}
      />
    </View>
  );
});

const SingleWorkerGroupHeader = observer(({state}: SchedulerHeaderProps) => {
  const {workers, userTimezoneMinutes} = state;
  const [visibleDropdown, toggleDropdown] = useToggle(false);
  const worker = workers[0];
  const templates = useTemplates();
  const styles = useStyles((theme) => ({
    root: {
      padding: 20,
      backgroundColor: theme.palette.base,
    },
    workerName: {
      ...theme.fontByWeight('700'),
      color: theme.palette.textPrimary,
    },
    timezoneText: {
      ...theme.fontByWeight('700'),
      color: theme.palette.error,
      fontSize: 14,
      marginBottom: 15,
    },
    dropdown: {
      ...theme.mediaQuery({
        1000: {
          maxWidth: 350,
        },
      }),
      paddingVertical: 10,
      paddingLeft: 12,
      paddingRight: 20,
      borderWidth: 1,
      borderRadius: 3,
      borderColor: theme.palette.border,
      backgroundColor: theme.palette.background,
    },
    dropdownCloseIcon: {
      position: 'absolute',
      top: 15,
      right: 10,
    },
    paragraph: {
      color: theme.palette.textPrimary,
      letterSpacing: 0.04,
      fontSize: 14,
      ...theme.fontByWeight('400'),
      marginTop: 0,
      marginBottom: 0,
    },
    bold: {
      ...theme.fontByWeight('700'),
    },
    dropdownList: {
      borderWidth: 1,
      borderColor: theme.palette.border,
      marginTop: -1,
      padding: 10,
      ...theme.mediaQuery({
        1000: {
          maxWidth: 350,
          width: '100%',
        },
      }),
    },
    dropdownText: {
      ...theme.fontByWeight('400'),
      color: theme.palette.textSecondary,
      fontSize: 14,
      lineHeight: 20,
    },
  }));
  const timezoneDiff = (userTimezoneMinutes - worker.utc_offset) / 60;
  const visibleTimezoneMessage =
    worker.utc_offset !== userTimezoneMinutes &&
    worker.worker_type === WorkerType.Regular;
  const differentTimezoneStr =
    timezoneDiff < 0
      ? '+' + Math.abs(timezoneDiff)
      : '-' + Math.abs(timezoneDiff);
  const theme = useTheme();
  const applyToText = templates['scheduler.applyToWorker']({
    workerName: getWorkerName(worker),
  });
  const workerDescText = getWorkerDescription(worker);
  const timezoneText = templates['scheduler.differentTimezone']({
    timezone: differentTimezoneStr,
  });
  return (
    <View style={styles.root}>
      {visibleTimezoneMessage && (
        <Text style={styles.timezoneText}>{timezoneText}</Text>
      )}
      <TouchableOpacity
        activeOpacity={1}
        style={styles.dropdown}
        onPress={toggleDropdown}>
        <Markdown
          styles={{
            strong: styles.bold,
            paragraph: styles.paragraph,
          }}>
          {applyToText}
        </Markdown>
        <CaretDownIcon
          style={styles.dropdownCloseIcon}
          color={theme.palette.icon}
        />
      </TouchableOpacity>
      {visibleDropdown && (
        <View style={styles.dropdownList}>
          <Text style={styles.dropdownText}>{workerDescText}</Text>
        </View>
      )}
    </View>
  );
});
const CaretDownIcon = sized(CaretDownSvg, 10);

const SchedulerForPoolMinerBanner = observer(
  ({state}: SchedulerHeaderProps) => {
    const {isSingleGroup} = state;
    const styles = useStyles((theme) => ({
      root: {
        zIndex: -1,
        paddingHorizontal: 20,
        marginBottom: 20,
      },
      card: {
        paddingHorizontal: 15,
        paddingVertical: 10,
        borderWidth: 1,
        borderColor: theme.palette.primary,
        backgroundColor: theme.chroma(theme.palette.primary).alpha(0.03).hex(),
        borderRadius: 4,
        ...theme.mediaQuery({
          1000: {
            alignSelf: 'flex-start',
          },
        }),
      },
      paragraph: {
        ...theme.fontByWeight('400'),
        color: theme.palette.textPrimary,
        fontSize: 14,
        lineHeight: 22,
        letterSpacing: 0.037,
        marginTop: 0,
        marginBottom: 0,
      },
    }));
    const strings = useStrings();
    const text = isSingleGroup
      ? strings['scheduler.poolMinerBanner.singleGroup']
      : strings['scheduler.poolMinerBanner.group'];
    if (isSingleGroup === null) {
      return null;
    }
    return (
      <View style={styles.root}>
        <View style={styles.card}>
          <Markdown
            styles={{
              paragraph: styles.paragraph,
            }}>
            {text}
          </Markdown>
        </View>
      </View>
    );
  },
);
