import {BaseErrorBody} from './BaseError';
import {CommonError} from '../ApiStore';
import {DefaultError} from '../JsonRpc';

export const SERVER_ERROR = Symbol();

export type ServerError = {
  kind: typeof SERVER_ERROR;
  body: CommonError | DefaultError;
} & BaseErrorBody;
