import {IterableElement, ValueOf} from 'type-fest';

export type LogSettings = ExcludeEverything | IncludeGroups;

export interface ExcludeEverything {
  exclude: true;
  groups: {};
}

export interface IncludeGroups {
  exclude: false;
  groups: {
    [Group in LogGroup]?: ExcludeGroup | IncludeMessages<Group>;
  };
}

export interface ExcludeGroup {
  exclude: true;
  exclude_messages: [];
}

export interface IncludeMessages<Group extends LogGroup = LogGroup> {
  exclude: false;
  exclude_messages: LogGroupMessageMap[Group][];
}

export const logGroupMessageMap = {
  mining: new Set([
    'mining_paused',
    'mining_restarted',
    'new_miner',
    'miner_online',
    'miner_offline',
    'miner_overheated',
  ] as const),
  subscription: new Set([
    'subscription_changed',
    'subscription_active',
    'subscription_inactive',
  ] as const),
  withdrawal: new Set(['withdrawal_processed', 'withdrawal_failed'] as const),
};

type RuntimeLogGroupMessageMap = typeof logGroupMessageMap;

export type LogGroupMessageMap = {
  [Group in keyof RuntimeLogGroupMessageMap]: IterableElement<
    RuntimeLogGroupMessageMap[Group]
  >;
};

export type LogGroup = keyof LogGroupMessageMap;
export type LogMessage = ValueOf<LogGroupMessageMap>;
