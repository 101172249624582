import React, {useCallback} from 'react';
import {TouchableOpacity, View, Text, Platform} from 'react-native';
import {observer} from 'mobx-react-lite';
import {
  CompositeNavigationProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import Dropdown from './Dropdown/Dropdown';
import {BottomTabNavigationProp} from '@react-navigation/bottom-tabs';
import {BottomTabParamList, RootParamList} from '../Navigation';
import {StackNavigationProp} from '@react-navigation/stack';
import {useRoot, useStrings} from '../Root/hooks';
import {useOpenLink} from '../Links';
import {createStylesHook, useTheme} from '../styling';
import {Hoverable} from 'react-native-web-hooks';
import {FullLogo, PressableOpacity} from '../components';
import DashboardIcon from '../assets/svg/colorless/content.svg';
import StatsIcon from '../assets/svg/colorless/stats.svg';
import WithdrawIcon from '../assets/svg/colorless/withdrawal.svg';
import SupportIcon from '../assets/svg/colorless/support.svg';
import ProductsIcon from '../assets/svg/colorless/ct-products.svg';
export interface WebAppHeaderWebProps {
  navigation: CompositeNavigationProp<
    CompositeNavigationProp<
      BottomTabNavigationProp<BottomTabParamList>,
      StackNavigationProp<RootParamList>
    >,
    StackNavigationProp<RootParamList>
  >;
}

export interface AppHeaderWebProps {
  onlyLogo?: boolean;
  onLogoPress?: () => void;
}

export default observer(({onlyLogo, onLogoPress}: AppHeaderWebProps) => {
  const {debug, configuration} = useRoot();
  const theme = useTheme();
  const {
    values: {aboutUrl},
  } = configuration;
  const styles = useWebStyles();
  const {navigate} = useNavigation<WebAppHeaderWebProps['navigation']>();
  const route = useRoute();
  const strings = useStrings();

  const goToDashboard = useCallback(() => navigate('Dashboard'), [navigate]);
  const goToStatistics = useCallback(() => navigate('Statistics'), [navigate]);
  const goToWithdraw = useCallback(() => navigate('Withdraw'), [navigate]);
  const goToProducts = useCallback(() => navigate('Products'), [navigate]);
  const goToSupport = useOpenLink(`${aboutUrl}/feedback/`);

  if (Platform.OS === 'web' && theme.window.width > 1280) {
    return (
      <View style={styles.root}>
        <View style={styles.container}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={onLogoPress || goToDashboard}
            accessibilityRole="link">
            <FullLogo style={styles.logo} />
          </TouchableOpacity>
          {!onlyLogo && (
            <>
              <View style={styles.menuListView}>
                <MenuItem
                  isActive={route.name === 'Dashboard'}
                  onPress={goToDashboard}
                  Icon={<DashboardIcon />}>
                  {strings['screenTitle.dashboard']}
                </MenuItem>
                <MenuItem
                  isActive={route.name === 'Statistics'}
                  onPress={goToStatistics}
                  Icon={<StatsIcon />}>
                  {strings['screenTitle.statistics']}
                </MenuItem>
                <MenuItem
                  isActive={route.name === 'Withdraw'}
                  onPress={goToWithdraw}
                  Icon={<WithdrawIcon />}>
                  {strings['withdraw.title']}
                </MenuItem>
                <MenuItem onPress={goToSupport} Icon={<SupportIcon />}>
                  {strings['menu.support']}
                </MenuItem>
                <MenuItem
                  isActive={route.name === 'Products'}
                  onPress={goToProducts}
                  Icon={<ProductsIcon />}>
                  {strings['menu.ct-products']}
                </MenuItem>
                {debug.debugEnabled && (
                  <MenuItem
                    isActive={route.name === 'Debug'}
                    onPress={() => navigate('Debug')}>
                    DEBUG
                  </MenuItem>
                )}
              </View>
              <Dropdown />
            </>
          )}
        </View>
      </View>
    );
  }
  return null;
});

interface MenuItemProps {
  onPress?: () => void;
  children: string;
  isActive?: boolean;
  Icon?: React.ReactNode;
}
const MenuItem = ({isActive, onPress, children, Icon}: MenuItemProps) => {
  const styles = useWebStyles();
  return (
    <Hoverable>
      {(isHovered) => (
        <PressableOpacity accessibilityRole="link" onPress={onPress}>
          <View style={[styles.touchableItem]}>
            <Text
              style={[
                styles.itemIcon,
                isActive && styles.itemActiveIcon,
                !isActive && isHovered && styles.itemHoveredIcon,
              ]}>
              {Icon && Icon}
            </Text>
            <Text
              style={[
                styles.itemText,
                isActive && styles.itemActiveText,
                !isActive && isHovered && styles.itemHoveredText,
              ]}>
              {children}
            </Text>
          </View>
        </PressableOpacity>
      )}
    </Hoverable>
  );
};

const useWebStyles = createStylesHook((theme) => ({
  root: {
    zIndex: 2,
    borderBottomWidth: 1,
    backgroundColor: theme.palette.background,
    borderBottomColor: theme.chroma(theme.palette.border).alpha(0.6).hex(),
    alignItems: 'center',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 15,
    justifyContent: 'space-between',
  },
  logo: {
    marginLeft: -20,
    width: 300,
    height: 50,
  },
  menuListView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    flex: 1,
    paddingHorizontal: 15,
  },
  touchableItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    paddingHorizontal: 15,
  },
  itemText: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 16,
    textTransform: 'uppercase',
    color: theme.palette.secondary,
    borderBottomWidth: 3,
    borderBottomColor: 'transparent',
    paddingBottom: 8,
    paddingTop: 11,
  },
  itemActiveText: {
    color: theme.palette.textPrimary,
    borderBottomColor: theme.palette.primary,
  },
  itemHoveredText: {
    color: theme.palette.primary,
  },
  itemIcon: {
    color: theme.palette.secondary,
  },
  itemActiveIcon: {
    color: theme.palette.primary,
  },
  itemHoveredIcon: {
    color: theme.palette.primary,
  },
}));
