import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStyles, variance} from '../../../styling';
import {StyleProp, View, ViewStyle} from 'react-native';
import {useTimer} from 'use-timer';
import dayjs from 'dayjs';
import {useStrings, useTemplates} from '../../../Root/hooks';
import Markdown from '../../../components/Markdown';

export type LimitedDiscountProps = {
  onTimeOver: () => void;
  initialSeconds: number;
  style?: StyleProp<ViewStyle>;
};

export default observer(
  ({initialSeconds, onTimeOver, style}: LimitedDiscountProps) => {
    const {time} = useTimer({
      autostart: true,
      initialTime: initialSeconds,
      endTime: 0,
      timerType: 'DECREMENTAL',
      onTimeOver: onTimeOver,
    });
    const readableTime = dayjs.duration(time, 'seconds').format('HH:mm:ss');
    const styles = useStyles((theme) => ({
      paragraph: {
        ...theme.fontByWeight('400'),
        color: theme.palette.textPrimary,
        fontSize: 14,
        lineHeight: 22,
        marginBottom: 0,
        marginTop: 0,
      },
    }));
    const templates = useTemplates();
    const strings = useStrings();
    const timeLeftText = templates['purchase.limitedDiscount.timeLeft']({
      time: readableTime,
    });
    return (
      <Root style={style}>
        <Markdown styles={{paragraph: styles.paragraph}}>
          {strings['purchase.limitedDiscount.title']}
        </Markdown>
        <Markdown styles={{paragraph: styles.paragraph}}>
          {timeLeftText}
        </Markdown>
      </Root>
    );
  },
);

const Root = variance(View)((theme) => ({
  root: {
    padding: 13,
    marginHorizontal: 15,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: theme.palette.success,
    backgroundColor: theme.chroma(theme.palette.success).alpha(0.1).hex(),
  },
}));
