import {action, observable, makeObservable} from 'mobx';
import {
  DuplexTunnel,
  HubTunnel,
  JsonTunnel,
  Request,
  Response,
  StructuredTunnel,
  TunnelKind,
  WebSocketTunnel,
  WebSocketTunnelState,
} from '../JsonRpc';
import {Configuration} from '../Configuration';
import {
  DeviceIdentification,
  DeviceIdentificationStateKind,
  RegisterDeviceErrorReason,
} from '../DeviceIdentification';
import {ConnectionManager, RECONNECT} from '../ConnectionManager';
import SpyService from './SpyService';
import {Spy} from './Spy';
import {batchDisposers, Disposer, Service} from '../structure';
import {ReadyState} from '../Connection';

export default class RealBackend implements Service {
  private _ws?: WebSocket;
  @observable.ref private _wsTunnel?: WebSocketTunnel;
  private _jsonTunnel?: StructuredTunnel;

  private readonly _gateway = new DuplexTunnel(
    ({send, listen}): StructuredTunnel => ({
      tunnelKind: TunnelKind.Structured,
      send,
      listen,
    }),
  );
  private readonly _spy = new SpyService(
    this._gateway.bob as StructuredTunnel<
      Request | Response,
      Request | Response
    >,
  );
  private readonly _gatewayHub = new HubTunnel();
  private _disposer = () => {};

  public readonly tunnelState: WebSocketTunnelState;

  constructor(
    private readonly _root: {
      readonly configuration: Configuration;
      readonly deviceIdentification: DeviceIdentification;
      readonly connectionManager: ConnectionManager;
    },
  ) {
    makeObservable(this);
    const that = this;
    this.tunnelState = {
      get readyState() {
        return that._wsTunnel?.readyState ?? ReadyState.Connecting;
      },
      get errorMessage() {
        const {state} = that._root.deviceIdentification;
        return (
          that._wsTunnel?.errorMessage ??
          (state.kind === DeviceIdentificationStateKind.RegistrationError
            ? `${RegisterDeviceErrorReason[state.error.reason]} ${
                state.error.raw
              }`
            : undefined)
        );
      },
    };
  }

  get tunnel() {
    return this._spy.tunnel;
  }

  get spy(): Spy<Request | Response> {
    return this._spy;
  }

  @action private _connect(ws: WebSocket) {
    this._ws = ws;
    this._wsTunnel = new WebSocketTunnel(this._ws);
    this._jsonTunnel = new JsonTunnel(this._wsTunnel);
    this._disposer();
    this._disposer = batchDisposers(
      this._gatewayHub.connect(this._gateway.alice),
      this._gatewayHub.connect(this._jsonTunnel),
    );
  }

  async connect() {
    const ws = await this._root.connectionManager.connect();
    this._connect(ws);
  }

  disconnect = () => {
    this._wsTunnel?.close();
  };

  reconnect() {
    return this.connect();
  }

  private readonly _onReconnect = (ws: WebSocket) => {
    this._connect(ws);
  };

  private _reconnectAutomatically() {
    return this._root.connectionManager.sockets.listen(
      RECONNECT,
      this._onReconnect,
    );
  }

  subscribe() {
    return batchDisposers(
      this._spy.subscribe(),
      this._reconnectAutomatically(),
      (() => this._disposer()) as Disposer,
    );
  }
}
