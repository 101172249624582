import {WithdrawalStatus, Satoshi, satoshiToBitcoin} from '../ApiStore';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useRoot} from '../Root/hooks';
import {Text, View, ViewProps, ViewStyle} from 'react-native';
import dayjs from 'dayjs';
import ArrowRightSvg from '../assets/svg/colorless/arrowRight.svg';
import {useStyles, useTheme} from '../styling';
import {Millisecond} from '../utils/time';
import {sized} from '../Svg';

export interface WithdrawalProps extends ViewProps {
  amount: Satoshi;
  status: WithdrawalStatus;
  createdAt: Millisecond;
}

export default observer((props: WithdrawalProps) => {
  const {amount, status, createdAt, style, ...rest} = props;
  const styles = useStyles((theme) => ({
    root: {
      borderColor: theme.palette.border,
      borderBottomWidth: 1,
      borderStartWidth: 1,
      borderEndWidth: 1,
      backgroundColor: theme.palette.background,
      width: '100%',
      maxWidth: 1100,
      marginHorizontal: 'auto',
      ...theme.mediaQuery({
        700: {
          flexDirection: 'row',
          alignItems: 'center',
          padding: 20,
        },
      }),
    },
    createdAt: {
      marginTop: 15,
      marginHorizontal: 15,
      ...theme.fontByWeight(),
      fontSize: 13,
      lineHeight: 18,
      letterSpacing: 0.0495238,
      color: theme.palette.textPrimary,
      ...theme.mediaQuery({
        700: {
          marginTop: 0,
          marginHorizontal: 0,
        },
      }),
    },
    content: {
      marginTop: 9,
      marginHorizontal: 15,
      marginBottom: 14,
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'flex-start',
      ...theme.mediaQuery({
        700: {
          marginTop: 0,
          marginBottom: 0,
          marginHorizontal: 0,
        },
      }),
    },
    arrow: {
      marginTop: 3,
    },
    amount: {
      marginStart: 6.88,
      flexGrow: 1,
      flexShrink: 1,
      ...theme.fontByWeight('bold'),
      fontSize: 15,
      lineHeight: 20,
      letterSpacing: 0.0571429,
      color: theme.palette.textPrimary,
    },
    dateView: {
      flexDirection: 'row',
      ...theme.mediaQuery({
        700: {
          flexBasis: '33.3%',
        },
      }),
    },
    sumView: {
      flexDirection: 'row',
      alignItems: 'center',
      ...theme.mediaQuery({
        700: {
          order: 1,
          flexBasis: '50%',
        } as ViewStyle,
      }),
    },
    statusView: {
      marginLeft: 'auto',
      ...theme.mediaQuery({
        700: {
          marginLeft: undefined,
          flexBasis: '50%',
          alignItems: 'flex-start',
        },
      }),
    },
    badgeView: {
      height: 20,
      borderRadius: 10,
      paddingHorizontal: 7,
      justifyContent: 'center',
      backgroundColor: theme.palette.success,
    },
    badgeViewDisabled: {
      backgroundColor: theme.palette.disabled,
    },
    badgeViewFailed: {
      backgroundColor: theme.palette.error,
    },
    badgeText: {
      ...theme.fontByWeight('bold'),
      fontSize: 10,
      lineHeight: 14,
      letterSpacing: 0.0457143,
      textTransform: 'uppercase',
      color: theme.palette.textPrimary,
    },
    badgeTextDisabled: {
      color: theme.palette.textSecondary,
    },
  }));
  const theme = useTheme();
  const {translation} = useRoot();
  const {strings, localeTag} = translation;
  return (
    <View style={[styles.root, style]} {...rest}>
      <View style={styles.dateView}>
        <Text style={styles.createdAt}>
          {dayjs(createdAt).locale(localeTag).format('D MMMM YYYY, HH:mm')}
        </Text>
      </View>
      <View style={styles.content}>
        <View style={styles.sumView}>
          <ArrowRightIcon style={styles.arrow} color={theme.palette.primary} />
          <Text style={styles.amount}>
            {satoshiToBitcoin(amount).toFixed(8)} BTC
          </Text>
        </View>
        <View style={styles.statusView}>
          {status === WithdrawalStatus.Processed ? (
            <View style={styles.badgeView}>
              <Text style={styles.badgeText}>
                {strings['withdrawalHistory.processedStatus']}
              </Text>
            </View>
          ) : status === WithdrawalStatus.Failed ? (
            <View style={[styles.badgeView, styles.badgeViewFailed]}>
              <Text style={styles.badgeText}>
                {strings['withdrawalHistory.failedStatus']}
              </Text>
            </View>
          ) : (
            <View style={[styles.badgeView, styles.badgeViewDisabled]}>
              <Text style={[styles.badgeText, styles.badgeTextDisabled]}>
                {strings['withdrawalHistory.waitingStatus']}
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
});

const ArrowRightIcon = sized(ArrowRightSvg, 15);
