import React from 'react';
import {observer} from 'mobx-react-lite';
import {Container} from '../../../../components/Grid';
import {variance} from '../../../../styling';
import {StyleSheet, View} from 'react-native';
import BalanceInfo, {BALANCE_INFO_HEIGHT} from './BalanceInfo';
import SlotInformation, {
  SLOT_INFORMATION_DESKTOP_HEIGHT,
} from '../common/SlotInformation';
import DesktopHeaderWeb, {DESKTOP_HEADER_WEB_HEIGHT} from './DesktopHeaderWeb';

export const DESKTOP_FARM_INFO_SECTION_HEIGHT =
  BALANCE_INFO_HEIGHT + SLOT_INFORMATION_DESKTOP_HEIGHT + 10 + 2 + 4;

export const DESKTOP_FARM_INFO_SECTION_HEIGHT_WEB =
  DESKTOP_FARM_INFO_SECTION_HEIGHT + DESKTOP_HEADER_WEB_HEIGHT;

export type DesktopFarmInfoSectionProps = {
  goToWithdraw: () => void;
  goToAddWorker: () => void;
  goToAuth: () => void;
};

export default observer((props: DesktopFarmInfoSectionProps) => {
  const {goToAuth} = props;
  return (
    <Container style={styles.container}>
      <RootView>
        <DesktopHeaderWeb goToAuth={goToAuth} />
        <Content>
          <BalanceInfo {...props} />
          <SlotInformation />
        </Content>
      </RootView>
    </Container>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

const RootView = variance(View)(() => ({
  root: {
    paddingBottom: 10,
  },
}));

const Content = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: theme.palette.border,
  },
}));
