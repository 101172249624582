import {JsonSerializable} from '../Json';

/**
 * @deprecated
 */
export default class RpcError<
  T extends JsonSerializable = JsonSerializable,
> extends Error {
  constructor(
    message: string,
    public readonly code: number,
    public readonly data?: T,
  ) {
    super(message); // 'Error' breaks prototype chain here
    if (new.target) {
      Object.setPrototypeOf(this, new.target.prototype); // Restore prototype chain
    }
    this.name = 'RpcError';
  }
}
