import {Text, View, ViewProps} from 'react-native';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useStyles} from '../styling';
import {sized} from '../Svg';
import {FanSquareLogo} from '../components';

export type IdLabelProps = ViewProps & {
  accountId: number;
  isHovered?: boolean;
};

export default observer((props: IdLabelProps) => {
  const {accountId: id, style, isHovered, ...rest} = props;
  const styles = useStyles((theme) => ({
    root: {
      flexDirection: 'row',
    },
    iconView: {
      marginRight: 10,
    },
    text: {
      ...theme.fontByWeight(),
      fontSize: 14,
      lineHeight: 20,
      letterSpacing: 0.04,
      textTransform: 'uppercase',
      color: theme.palette.textPrimary,
    },
    textHovered: {
      color: theme.palette.primary,
    },
    bold: theme.fontByWeight('bold'),
  }));
  return (
    <View style={[styles.root, style]} {...rest}>
      <View style={styles.iconView}>
        <Icon />
      </View>
      <Text
        style={[styles.text, isHovered && styles.textHovered]}
        numberOfLines={1}>
        <Text style={styles.bold}>ID</Text>: {id.toFixed()}
      </Text>
    </View>
  );
});

const Icon = sized(FanSquareLogo, 20);
