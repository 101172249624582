import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Text, View} from 'react-native';

import {defaultContainerStyle} from '../components/Grid';
import Badge, {BadgeVariant} from './Badge';
import FacebookSvg from '../assets/svg/colorless/facebook.svg';
import TelegramSvg from '../assets/svg/colorless/telegram.svg';
import VkontakteSvg from '../assets/svg/colorless/vkontakte.svg';
import InstagramSvg from '../assets/svg/colorless/instagram.svg';
import TwitterSvg from '../assets/svg/colorless/twitter.svg';
import YoutubeSvg from '../assets/svg/colorless/youtube.svg';
import {useRoot, useStrings} from '../Root/hooks';
import {openLink, Target} from '../Links';
import HoveredIcon from './HoveredIcon';
import negateColor from '../styling/util/negateColor';
import {sized} from '../Svg';
import {useTheme} from '../styling';
import {Hoverable} from 'react-native-web-hooks';
import {useSafelyGoToAffiliate} from '../Navigation/hooks';

export default observer(() => {
  const root = useRoot();
  const strings = useStrings();
  const {
    configuration: {values},
    translation: {localeTag},
    auth,
    navigationContainer,
  } = root;
  const styles = useStyles();
  const goToAuthAndThenOpenAffiliate = useCallback(
    () => navigationContainer.ref?.navigate('Auth'),
    [navigationContainer],
  );
  const {safelyGoToAffiliate} = useSafelyGoToAffiliate(
    goToAuthAndThenOpenAffiliate,
  );
  const translatedLinks = useMemo(() => {
    const translate = (link: string) => link.replace('{lang}', localeTag);
    return {
      overviewFarm: translate(values.overviewFarm),
      overviewMiner: translate(values.overviewMiner),
      usefull: translate(values.usefull),
      faq: translate(values.faq),
      cryptotab: translate(values.cryptotab),
      affiliate: translate(values.affiliate),
      terms: translate(values.terms),
      privacy: translate(values.privacy),
      advertise: translate(values.advertise),
      cryptotabBrowser: translate(values.cryptotabBrowser),
      feedback: translate(values.feedback),
      cbclick: translate(values.cbclick),
      cryptotabStart: translate(values.cryptotabStart),
      ctVpn: translate(values.ctVpn),
      tutorialDemo: translate(values.tutorialDemo) + '?page=dashboard',
      tutorialReal: translate(values.tutorialReal) + '?page=dashboard',
      ctabs: translate(values.ctabs),
      nftmaning: translate(values.nftmaning),
    };
  }, [localeTag, values]);
  return (
    <View style={[styles.root, defaultContainerStyle]}>
      <View style={styles.column}>
        <View style={styles.listItem}>
          <Text style={styles.label}>{strings['footer.general']}</Text>
        </View>
        <ListItem href={translatedLinks.overviewFarm}>
          {strings['footer.overview']}
        </ListItem>
        <ListItem href={translatedLinks.overviewMiner}>
          {strings['footer.overview.miner']}
        </ListItem>
        <ListItem href={translatedLinks.faq}>{strings['footer.faq']}</ListItem>
        <ListItem href={translatedLinks.cryptotab}>CryptoTab</ListItem>
        {auth.isConnected && (
          <ListItem onPress={safelyGoToAffiliate}>
            {strings['footer.affiliate']}
          </ListItem>
        )}
        <ListItem href={translatedLinks.usefull}>
          {strings['footer.overview.usefulTips']}
        </ListItem>
      </View>
      <View style={styles.column}>
        <View style={styles.listItem}>
          <Text style={styles.label}>{strings['footer.additional']}</Text>
        </View>
        <ListItem href={translatedLinks.terms}>
          {strings['footer.termsOfUse']}
        </ListItem>
        <ListItem href={translatedLinks.privacy}>
          {strings['footer.privacyPolicy']}
        </ListItem>
        <ListItem href={translatedLinks.advertise}>
          {strings['footer.advertise']}
        </ListItem>
        <ListItem href={translatedLinks.tutorialDemo}>
          {strings['footer.tutorialDemo']}
        </ListItem>
        <ListItem href={translatedLinks.tutorialReal}>
          {strings['footer.real']}
        </ListItem>
      </View>
      <View style={styles.column}>
        <View style={styles.listItem}>
          <Text style={styles.label}>{strings['footer.cryptotabFamily']}</Text>
        </View>
        <ListItem
          href={translatedLinks.nftmaning}
          end={
            <Badge
              variant={BadgeVariant.New}
              style={styles.listItemBadge}
              titleStyle={styles.listItemBadgeText}
              title="New"
            />
          }>
          {strings['footer.nftMining']}
        </ListItem>
        <ListItem href={translatedLinks.cryptotabBrowser}>
          {strings['footer.cryptotab']} {strings['footer.browser']}
        </ListItem>
        <ListItem href={translatedLinks.cbclick}>CB.Click</ListItem>

        <ListItem
          href={translatedLinks.cryptotabStart}
          end={
            <Badge
              variant={BadgeVariant.Primary}
              style={styles.listItemBadge}
              titleStyle={styles.listItemBadgeText}
              title="BONUS"
            />
          }>
          CryptoTab <Text style={styles.itemTextBold}>START</Text>
        </ListItem>
        <ListItem
          href={translatedLinks.ctVpn}
          end={
            <Badge
              variant={BadgeVariant.New}
              style={styles.listItemBadge}
              titleStyle={styles.listItemBadgeText}
              title="New"
            />
          }>
          CT VPN
        </ListItem>
        <ListItem
          href={translatedLinks.ctabs}
          end={
            <Badge
              variant={BadgeVariant.Primary}
              style={styles.listItemBadge}
              titleStyle={styles.listItemBadgeText}
              title="BONUS"
            />
          }>
          CTabs
        </ListItem>
      </View>
      <View style={[styles.column, styles.contactColumn]}>
        <View style={styles.listItem}>
          <Text style={styles.label}>{strings['footer.stayConnected']}</Text>
        </View>
        <View style={styles.socialView}>
          <View style={styles.socialIconList}>
            <HoveredIcon
              href={values.facebook}
              style={styles.socialIcon}
              Icon={FacebookIcon}
            />
            <HoveredIcon
              href={values.telegram}
              style={styles.socialIcon}
              Icon={TelegramIcon}
            />
            <HoveredIcon
              href={values.twitter}
              style={styles.socialIcon}
              Icon={TwitterIcon}
            />
            <HoveredIcon
              href={values.instagram}
              style={styles.socialIcon}
              Icon={InstagramIcon}
            />
            <HoveredIcon
              href={values.youtube}
              style={styles.socialIcon}
              Icon={YoutubeIcon}
            />
            <HoveredIcon
              href={values.vkontakte}
              style={styles.socialIcon}
              Icon={VkontakteIcon}
            />
          </View>
        </View>
        <Text style={styles.contactTextView}>
          <Text style={styles.contactText}>
            {strings['footer.contactSupport']}{' '}
          </Text>
          <Hoverable>
            {(isHovered) => (
              <Text
                onPress={() => openLink(translatedLinks.feedback, Target.Blank)}
                style={[
                  styles.accentText,
                  isHovered && styles.accentTextHovered,
                ]}>
                {strings['footer.here']}
              </Text>
            )}
          </Hoverable>
        </Text>
        <Text style={styles.otherTextView}>
          <Text style={styles.otherText}>
            {strings['footer.otherInquiries']}:{' '}
          </Text>
          <Hoverable>
            {(isHovered) => (
              <Text
                onPress={() => openLink(`mailto:${values.mail}`)}
                style={[
                  styles.accentText,
                  isHovered && styles.accentTextHovered,
                ]}
                accessibilityRole="link">
                {values.mail}
              </Text>
            )}
          </Hoverable>
        </Text>
      </View>
    </View>
  );
});

const FacebookIcon = sized(FacebookSvg, 20);
const TelegramIcon = sized(TelegramSvg, 20, 17);
const VkontakteIcon = sized(VkontakteSvg, 25, 15);
const InstagramIcon = sized(InstagramSvg, 20);
const TwitterIcon = sized(TwitterSvg, 20, 18);
const YoutubeIcon = sized(YoutubeSvg, 25, 18);

interface ListItemProps {
  children: React.ReactNode;
  onPress?: () => void;
  href?: string;
  end?: React.ReactNode;
}

const ListItem = observer(({children, end, href, onPress}: ListItemProps) => {
  const styles = useStyles();
  const handlePress = useCallback(() => {
    if (href) {
      openLink(href, Target.Blank);
    } else if (onPress) {
      onPress();
    }
  }, [href, onPress]);
  return (
    <View style={styles.listItem}>
      <Hoverable>
        {(isHovered) => (
          <Text
            onPress={handlePress}
            style={[styles.itemText, isHovered && styles.itemTextHovered]}>
            {children}
          </Text>
        )}
      </Hoverable>
      {end}
    </View>
  );
});

const useStyles = () => {
  const theme = useTheme();
  return useMemo(
    () =>
      ({
        root: {
          flexDirection: 'row',
          paddingBottom: 35,
          justifyContent: 'space-between',
          gap: 20,
        },
        column: {
          flexGrow: 1,
          flexBasis: 0,
        },
        contactColumn: {
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: 'auto',
        },
        listItem: {
          marginBottom: 18,
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
        },
        slash: {
          ...theme.fontByWeight('400'),
          marginHorizontal: 5,
          color: theme.palette.secondary,
          fontSize: 14,
        },
        label: {
          ...theme.fontByWeight('700'),
          fontSize: 14,
          lineHeight: 16,
          color: theme.select(
            negateColor(theme.palette.textSecondary),
            theme.palette.textSecondary,
          ),
        },
        itemText: {
          ...theme.fontByWeight('400'),
          fontSize: 13,
          lineHeight: 15,
          color: theme.select(
            theme.contrast(theme.palette.textPrimary),
            theme.palette.textPrimary,
          ),
        },
        itemTextHovered: {
          color: theme.palette.primary,
        },
        itemTextBold: {
          ...theme.fontByWeight('700'),
        },
        listItemBadge: {
          marginLeft: 10,
        },
        listItemBadgeText: {
          textTransform: 'uppercase',
        },
        socialView: {
          flexDirection: 'row',
          marginBottom: 22,
        },
        socialIconList: {
          flexDirection: 'row',
          alignItems: 'center',
        },
        joinUsText: {
          ...theme.fontByWeight(),
          fontSize: 13,
          lineHeight: 15,
          color: theme.select(
            negateColor(theme.palette.textSecondary),
            theme.palette.textSecondary,
          ),
        },
        socialIcon: {
          marginRight: 10,
          width: 36,
          height: 36,
          borderWidth: 1,
          // borderColor: theme.chroma(theme.palette.border).alpha(0.8).hex(),
          borderColor: theme.chroma('#ffffff').alpha(0.4).hex(),
          borderRadius: 50,
        },
        contactText: {
          ...theme.fontByWeight('400'),
          color: theme.select(
            theme.contrast(theme.palette.textPrimary),
            theme.palette.textPrimary,
          ),
        },
        otherText: {
          ...theme.fontByWeight('400'),
          color: theme.select(
            theme.contrast(theme.palette.textPrimary),
            theme.palette.textPrimary,
          ),
        },
        accentText: {
          ...theme.fontByWeight('400'),
          color: theme.palette.secondary,
        },
        accentTextHovered: {
          color: theme.palette.primary,
        },
        contactTextView: {
          marginBottom: 10,
          fontSize: 13,
          lineHeight: 15,
        },
        otherTextView: {
          fontSize: 13,
          lineHeight: 15,
        },
      } as const),
    [theme],
  );
};
