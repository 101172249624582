import {
  DemonstrationDatabase,
  DemonstrationDatabaseRecord,
} from './DemonstrationDatabase';
import {defaultsDeep} from 'lodash';
import {DEFAULT_DATABASE} from './DemonstrationDatabaseService';
import {now} from '../Time';
import {DatabaseImporter, DatabaseImportOptions} from './DatabaseImporter';
import {Auth} from '../Auth';

export default class DatabaseImporterImpl implements DatabaseImporter {
  constructor(
    private readonly _root: {
      db: DemonstrationDatabase;
      auth: Auth;
      reset(): void;
    },
  ) {}

  async import(json: string, options?: Partial<DatabaseImportOptions>) {
    const record: DemonstrationDatabaseRecord = JSON.parse(json);
    defaultsDeep(record, DEFAULT_DATABASE);
    if (options?.resetTime) {
      record.lastWorkerStatsUpdate = now();
    }
    await this._root.db.resetState(record);
    await this._root.auth.retrySetup();
  }
}
