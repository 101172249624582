import {LocationSource} from './LocationSource';
import {BusImpl, Disposer, Service} from '../structure';
import {Either, error, success} from '../fp';
import {Url} from '../units';
import {Linking} from 'react-native';
import {CachedConsumableImpl} from '../CachedConsumable';

export default class DeepLinkingLocationSourceService
  implements LocationSource, Service
{
  private readonly _initialLink = new CachedConsumableImpl(() =>
    Linking.getInitialURL(),
  );

  async getInitial(): Promise<Either<Url, unknown>> {
    try {
      const link = await this._initialLink.getCachedConsumable();
      if (link) {
        return success(link as Url);
      }
      return error(undefined);
    } catch (err) {
      return error(err);
    }
  }

  public readonly updates = new BusImpl<Url>();

  private readonly _onLinking = (event: {url: string}) => {
    this.updates.send(event.url as Url);
  };

  subscribe() {
    const _ = Linking.addEventListener('url', this._onLinking);
    return (() => _.remove()) as Disposer;
  }
}
