import React, {useMemo} from 'react';
import {Text, View} from 'react-native';
import {observer} from 'mobx-react-lite';

import BtcSvg from '../assets/svg/colored/btc.svg';

import {useRoot, useStrings} from '../Root/hooks';
import {sized} from '../Svg';
import {useTheme} from '../styling';

export const BtcSection = observer(() => {
  const strings = useStrings();
  const theme = useTheme();
  const styles = useMemo(
    () =>
      ({
        container: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginHorizontal: 20,
          paddingHorizontal: 15,
          borderWidth: 1,
          borderColor: theme.palette.border,
          backgroundColor: theme.palette.background,
          height: 44,
          marginBottom: 20,
          borderRadius: 6,
        },
        btcLine: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
        btcText: {
          ...theme.fontByWeight('700'),
          marginLeft: 6,
          letterSpacing: 0.04,
          fontSize: 18,
          color: theme.palette.textPrimary,
        },
        approxText: {
          ...theme.fontByWeight('400'),
          fontSize: 10,
          lineHeight: 11,
          letterSpacing: 0.03,
          color: theme.palette.textSecondary,
        },
        approxUsdText: {
          ...theme.fontByWeight('700'),
          fontSize: 10,
          lineHeight: 11,
          color: theme.palette.textSecondary,
        },
        approxContainer: {
          flexDirection: 'row',
          alignItems: 'center',
        },
      } as const),
    [theme],
  );

  const {dashboardStore} = useRoot();

  return (
    <View style={styles.container}>
      <View style={styles.btcLine}>
        <BtcIcon width={16} height={16} />
        <Text style={styles.btcText}>{dashboardStore.balance.btc} BTC</Text>
      </View>
      <View style={styles.approxContainer}>
        <Text style={styles.approxText}>{strings['common.approxShort']}. </Text>
        <Text style={styles.approxUsdText}>
          USD {dashboardStore.balance.approxUsd}
        </Text>
      </View>
    </View>
  );
});

const BtcIcon = sized(BtcSvg, 16);
