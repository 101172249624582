import {Palette} from './Coloring';
import lightPalette from './lightPalette';
import negateColor from '../util/negateColor';

const palette: Palette = {
  ...lightPalette,
  additionalPrimary: '#362209',
  additionalSuccess: '#0D340D',
  additionalInfo: '#0A162C',
  additionalPurple: '#2C0B3A',
  background: lightPalette.foreground,
  foreground: lightPalette.background,
  highlight: lightPalette.primary,
  disabled: negateColor(lightPalette.disabled),
  textPrimary: negateColor(lightPalette.textPrimary),
  textSecondary: negateColor(lightPalette.textSecondary),
  backdrop: '#ffffff1A', // #111111 // в темной теме backdrop и lift не отменяет друг друга
  lift: '#ffffff0c', // #1c1c1c
  base: '#111111',
  bar: '#212121',
  back: '#000000',
  border: '#494949',
  icon: '#ffffffcc', // #d5d5d5
  staticLightBlue: '#1AB9E5',
  staticAdditionalLightBlue: '#E2F9FF',
  white: lightPalette.white,
  black: lightPalette.black,
  darkText: '#D1D2D6',
};

export default palette;
