import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import {DesktopWebAppHeader} from '../DesktopWebAppHeader';
import {PageScrollView} from '../containers';
import {useOpenLink} from '../Links';
import common from '../Configuration/common';
import {SignUpBody} from '../SignUpScreen/SignUpBody';
import {sized} from '../Svg';
import FanCaseSvg from '../assets/svg/colorless/fanCase.svg';
import CloseSvg from '../assets/svg/colorless/closeCircle.svg';
import FanPropellerSvg from '../assets/svg/colorless/fanPropeller.svg';
import {Text, View, ViewProps} from 'react-native';
import {View as AnimatableView} from 'react-native-animatable';
import {useStyles, useTheme} from '../styling';
import {Particles} from '../SignUpScreen/Particles';
import {useIsDesktop} from '../DashboardScreen/hooks/useGetItemLayout';

export interface OAuthSplashScreenProps {
  onCancel: () => void;
}

export default observer((props: OAuthSplashScreenProps) => {
  const {onCancel} = props;
  const styles = useStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.primary,
      position: 'relative',
    },
    container: {
      justifyContent: 'center',
      height: 800,
      maxWidth: 700,
      ...theme.mediaQuery({
        1200: {
          justifyContent: 'center',
          marginVertical: 40,
        },
      }),
    },
    panel: {
      marginTop: 20,
      marginHorizontal: 20,
      borderRadius: 15,
      backgroundColor: theme.select(
        theme.palette.background,
        theme.chroma(theme.palette.background).alpha(0.8).hex(),
      ),
    },
    title: {
      marginTop: 20,
      ...theme.fontByWeight('700'),
      fontSize: 26,
      lineHeight: 34,
      color: theme.palette.textPrimary,
      textAlign: 'center',
    },

    gutter: {
      margin: 10,
    },
    button: {
      backgroundColor: theme.select(
        theme.palette.background,
        theme.chroma(theme.palette.background).brighten(0.9).hex(),
      ),
      color: theme.palette.secondary,
      borderWidth: 1,
      borderColor: theme.palette.border,
    },
  }));
  const strings = useStrings();
  const goToCryptoTabFarm = useOpenLink(common.aboutUrl);
  const isDesktop = useIsDesktop();

  return (
    <>
      <DesktopWebAppHeader onlyLogo onLogoPress={goToCryptoTabFarm} />
      <PageScrollView
        contentContainerStyle={styles.container}
        style={styles.root}>
        <SignUpBody
          Icon={<AnimatableFan />}
          onPress={onCancel}
          BtnIcon={<CloseIcon />}
          btnText={strings['action.cancel']}
          style={styles.button}>
          <Text style={styles.title}>
            {strings['oauth.waitingForAuthorizationResponse']}
          </Text>
        </SignUpBody>
        {isDesktop && <Particles />}
      </PageScrollView>
    </>
  );
});

const CloseIcon = sized(CloseSvg, 20);

type AnimatableFanProps = ViewProps & {
  size?: number;
};

export const AnimatableFan = ({
  style,
  size = 80,
  ...rest
}: AnimatableFanProps) => {
  const styles = useStyles((theme) => ({
    root: {
      width: size,
      height: size,
      backgroundColor: '#000000',
      borderRadius: 15,
    },
    layer: {
      position: 'absolute',
      start: 0,
      top: 0,
      end: 0,
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
    proLayer: {
      position: 'absolute',
      right: 0,
      top: size / 2 - 35 / 2,
    },
    fanCase: {},
    fanPropeller: {},
    circle: {
      width: 20,
      height: 20,
      borderRadius: 12.2,
      backgroundColor: theme.palette.primary,
    },
  }));
  const theme = useTheme();
  const propeller = '#ffffff';
  const fanCase = theme.palette.primary;

  return (
    <View style={[styles.root, style]} {...rest}>
      <FanCase color={fanCase} />
      <View style={styles.layer}>
        <AnimatableView
          animation="rotate"
          duration={1000}
          easing="linear"
          iterationCount="infinite"
          useNativeDriver>
          <FanPropeller color={propeller} />
        </AnimatableView>
      </View>
      <View style={styles.layer}>
        <View style={styles.circle} />
      </View>
    </View>
  );
};

const FanCase = sized(FanCaseSvg, 80);
const FanPropeller = sized(FanPropellerSvg, 60);
