import {ViewProps} from 'react-native';
import {observer} from 'mobx-react-lite';
import React from 'react';
import ContainerView from './ContainerView';
import IdLabel from './IdLabel';

export interface EmptyFarmViewProps extends ViewProps {
  accountId: number;
}

export default observer((props: EmptyFarmViewProps) => {
  const {accountId, ...rest} = props;
  return (
    <ContainerView {...rest}>
      <IdLabel accountId={accountId} />
    </ContainerView>
  );
});
