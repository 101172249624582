import {Millisecond} from '../utils/time';
import {ChartViewScope} from './ChartView';
import dayjs from 'dayjs';

export default (
  __from: Millisecond | undefined,
  scope: ChartViewScope,
): {readonly from: Millisecond; readonly to: Millisecond} => {
  const _from = dayjs.min(
    dayjs(__from).startOf('day'),
    dayjs().subtract(1, scope),
  );
  const _to = _from.add(1, scope);
  const from = _from.toDate().getTime();
  const to = _to.toDate().getTime();
  return {from, to};
};
