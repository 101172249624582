import {RefObject, useCallback, useEffect, useState} from 'react';
import {useIsFocused, useScrollToTop} from '@react-navigation/native';
import {FlatList, NativeScrollEvent, NativeSyntheticEvent} from 'react-native';
import {FarmLogEntity} from '../Notifications';
import {useRoot} from '../Root/hooks';

export default function useScrollHandlers(
  ref: RefObject<FlatList<FarmLogEntity>>,
) {
  const isFocused = useIsFocused();
  useScrollToTop(ref);
  const {
    notificationService: {counterOfNewNotifications, clearCounter},
  } = useRoot();
  const [scrollY, setScrollY] = useState(0);
  const checkCounter = useCallback(
    (y: number) => {
      if (isFocused) {
        if (counterOfNewNotifications && y === 0) {
          clearCounter();
        }
      }
    },
    [clearCounter, counterOfNewNotifications, isFocused],
  );
  useEffect(() => {
    if (counterOfNewNotifications) {
      checkCounter(scrollY);
    }
  }, [checkCounter, counterOfNewNotifications, scrollY]);
  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const y_ = event.nativeEvent.contentOffset.y;
    setScrollY(y_);
    checkCounter(y_);
  };
  return {onScroll, scrollY};
}
