import BaseRenewAccessState from './BaseRenewAccessState';
import {InAppOffers, PaymentMethod} from '../InAppOffersService';
import {makeObservable, observable, runInAction} from 'mobx';
import {bind} from '../fp';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import {ProposalsState} from '../ProposalsState';

export default class WebRenewAccessScreenStateImpl extends BaseRenewAccessState {
  @observable private _selectedPaymentMethod = PaymentMethod.Card;

  constructor(
    protected readonly _root: {
      readonly dashboardStore: DashboardStore;
      readonly proposalsState: ProposalsState;
      readonly inAppOffers: InAppOffers;
    },
    readonly workerId: string,
  ) {
    super(_root, workerId);
    makeObservable(this);
  }

  get selectedPaymentMethod() {
    return this._selectedPaymentMethod;
  }

  selectPaymentMethod = bind((method: PaymentMethod) => {
    runInAction(() => {
      this._selectedPaymentMethod = method;
    });
  }, this);
}
