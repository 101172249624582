import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {View} from 'react-native';
import {CalculatorPanel} from '../CalculatorPanel';
import {RatesPanel} from '../RatesPanel';
import {StatisticsPanel, StatisticsPanelState} from '../StatisticsPanel';
import {useRoot} from '../Root/hooks';
import {StackNavigationProp} from '@react-navigation/stack';
import {
  CompositeNavigationProp,
  RouteProp,
  useIsFocused,
} from '@react-navigation/native';
import {BottomTabNavigationProp} from '@react-navigation/bottom-tabs';
import {BottomTabParamList, RootParamList} from '../Navigation';
import {defaultContainerStyle} from '../components/Grid';
import {PageScrollView} from '../containers';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';
import RefreshControl from '../components/RefreshControl';
import {useStyles} from '../styling';
import {NOCALC_TAG} from '../ApiStore';

export interface StatisticsScreenProps {
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<BottomTabParamList, 'Statistics'>,
    StackNavigationProp<RootParamList>
  >;
  route: RouteProp<BottomTabParamList, 'Statistics'>;
}

export default observer(({navigation, route}: StatisticsScreenProps) => {
  const {groupId} = route.params ?? {};
  const root = useRoot();
  const {dashboardStore, auth} = root;
  const group = dashboardStore.dashboardRequest.value?.groups.find(
    (_) => _.id === groupId,
  );
  const isFocused = useIsFocused();
  useLayoutEffect(() => {
    if (groupId !== undefined && group === undefined) {
      navigation.canGoBack() && navigation.goBack();
    }
  }, [navigation, group, groupId]);
  const [state] = useState(() => new StatisticsPanelState(root));
  const fetch = useCallback(() => state.fetch(groupId), [state, groupId]);
  useEffect(() => {
    if (isFocused) {
      state.fetch(groupId);
    }
  }, [isFocused, state, groupId]);
  const styles = useStyles(() => ({
    contentContainer: {
      paddingBottom: 20,
      paddingHorizontal: 5,
    },
    panel: {
      marginTop: 20,
      borderRadius: 15,
    },
  }));
  const visibleCalc =
    auth.state?.kind === 'Connected' ? !auth.state.tags.has(NOCALC_TAG) : true;
  return (
    <>
      <DesktopWebAppHeader />
      <PageScrollView
        refreshControl={
          <RefreshControl refreshing={state.isLoading} onRefresh={fetch} />
        }>
        <View style={[defaultContainerStyle, styles.contentContainer]}>
          <StatisticsPanel
            style={styles.panel}
            groupId={groupId}
            state={state}
          />
          <RatesPanel style={styles.panel} />
          {visibleCalc && <CalculatorPanel style={styles.panel} />}
        </View>
      </PageScrollView>
    </>
  );
});
