import React, {useCallback} from 'react';
import {
  StyleProp,
  TextInput,
  TextInputProps,
  View,
  ViewStyle,
  Text,
  Platform,
  NativeSyntheticEvent,
  TextInputFocusEventData,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useBoolean} from '../hooks';
import {createStylesHook, useTheme} from '../styling';

export interface UnderlineInputProps extends TextInputProps {
  subtext?: string;
  inputContainerStyle?: StyleProp<ViewStyle>;
}

export default observer(
  ({
    style,
    inputContainerStyle,
    subtext,
    onFocus,
    autoFocus,
    ...rest
  }: UnderlineInputProps) => {
    const [focus, setFocus, blur] = useBoolean(autoFocus);
    const theme = useTheme();
    const styles = useStyles();
    const handleFocus = useCallback(
      (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        onFocus && onFocus(e);
        setFocus();
      },
      [onFocus, setFocus],
    );
    return (
      <View style={inputContainerStyle}>
        <TextInput
          onFocus={handleFocus}
          onBlur={blur}
          style={[styles.input, focus && styles.focusedInput, style]}
          autoFocus={autoFocus}
          placeholderTextColor={theme.palette.textSecondary}
          {...rest}
        />
        {subtext && <Text style={styles.subtext}>{subtext}</Text>}
      </View>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  input: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.border,
    paddingTop: 5,
    paddingBottom: 12,
    ...theme.fontByWeight('400'),
    color: theme.palette.textPrimary,
    fontSize: 15,
    ...Platform.select({
      web: {
        outline: 'none',
      },
    }),
    ...theme.mediaQuery({
      1000: {
        fontSize: 16,
      },
    }),
  },
  focusedInput: {
    borderBottomColor: theme.palette.secondary,
  },
  subtext: {
    ...theme.fontByWeight('400'),
    marginTop: 5,
    fontSize: 11,
    lineHeight: 13,
    letterSpacing: 0.05,
    color: theme.palette.textSecondary,
    ...theme.mediaQuery({
      1000: {
        fontSize: 12,
        lineHeight: 14,
      },
    }),
  },
}));
