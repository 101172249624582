import React, {useCallback} from 'react';
import {RootStackBindingProps} from './RootStackBindingProps';
import {observer} from 'mobx-react-lite';
import {AccountSwitchScreen} from '../../AccountSwitchScreen';
import {CommonActions} from '@react-navigation/native';
import {DASHBOARD} from './constants';

export type AccountSwitchBindingProps = RootStackBindingProps<'AccountSwitch'>;

export default observer((props: AccountSwitchBindingProps) => {
  const {navigation} = props;
  const goToDashboard = useCallback(
    () => navigation.dispatch(CommonActions.reset(DASHBOARD)),
    [navigation],
  );
  return <AccountSwitchScreen goToDashboard={goToDashboard} />;
});
