import {Service} from '../structure';
import {Headless} from './Headless';

export default class ConstantHeadlessService implements Headless, Service {
  constructor(public readonly isHeadless: boolean) {}

  get initialized() {
    return true;
  }

  async getIsHeadless() {
    return this.isHeadless;
  }

  subscribe() {}
}
