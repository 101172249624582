import {
  GestureResponderEvent,
  Platform,
  Text,
  TouchableWithoutFeedback,
  View,
  ViewProps,
} from 'react-native';
import {ChartViewScope} from './ChartView';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useRoot} from '../Root/hooks';
import {useStyles} from '../styling';

interface ButtonGroupViewProps extends ViewProps {
  onDayPress?: (event: GestureResponderEvent) => void;
  onMonthPress?: (event: GestureResponderEvent) => void;
  scope: ChartViewScope;
}

export default observer(
  ({onDayPress, onMonthPress, scope, style, ...rest}: ButtonGroupViewProps) => {
    const styles = useStyles((theme) => ({
      root: {
        flexDirection: 'row',
      },
      buttonView: {
        width: 60,
        paddingTop: 11,
        paddingBottom: 9,
        borderColor: theme.palette.border,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        cursor: 'pointer',
      },
      buttonViewStart: {
        borderTopStartRadius: 10,
        borderBottomStartRadius: 10,
        borderStartWidth: 1,
      },
      buttonViewEnd: {
        borderTopEndRadius: 10,
        borderBottomEndRadius: 10,
        borderEndWidth: 1,
      },
      buttonViewActive: {
        borderStartWidth: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderEndWidth: 0,
        backgroundColor: theme.palette.secondary,
      },
      buttonText: {
        ...theme.fontByWeight('bold'),
        fontSize: 10,
        lineHeight: 12,
        textTransform: 'uppercase',
        color: theme.palette.secondary,
        textAlign: 'center',
        ...Platform.select({
          web: {
            userSelect: 'none',
          },
        }),
      },
      buttonTextActive: {
        color: theme.select(
          theme.contrast(theme.palette.textPrimary),
          theme.palette.textPrimary,
        ),
      },
    }));
    const {strings} = useRoot().translation;
    return (
      <View style={[styles.root, style]} {...rest}>
        <TouchableWithoutFeedback onPress={onDayPress}>
          <View
            style={[
              styles.buttonView,
              styles.buttonViewStart,
              scope === ChartViewScope.Day && styles.buttonViewActive,
            ]}>
            <Text
              style={[
                styles.buttonText,
                scope === ChartViewScope.Day && styles.buttonTextActive,
              ]}>
              {strings['common.day']}
            </Text>
          </View>
        </TouchableWithoutFeedback>
        <TouchableWithoutFeedback onPress={onMonthPress}>
          <View
            style={[
              styles.buttonView,
              styles.buttonViewEnd,
              scope === ChartViewScope.Month && styles.buttonViewActive,
            ]}>
            <Text
              style={[
                styles.buttonText,
                scope === ChartViewScope.Month && styles.buttonTextActive,
              ]}>
              {strings['common.month']}
            </Text>
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  },
);
