import {
  action,
  autorun,
  computed,
  observable,
  reaction,
  makeObservable,
} from 'mobx';
import {
  days,
  hours,
  INITIAL_MATRIX,
  INITIAL_UNSAVED_MATRIX,
  USER_TIMEZONE_MINUTES,
} from './constant';
import {Matrix, SelectedSpeed, UnsavedMatrix} from './types';
import joinTwoMatrix from './joinTwoMatrix';
import shiftPointByDiffTimezone from './shiftMatrixByTimezone';
import getDistanceBetweenTwoTimezone from './getDistanceBetweenTwoTimezone';
import matrixToIntervals from './matrixToIntervals';
import {ScheduleStore} from './ScheduleStore';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import intervalsToMatrix from './intervalsToMatrix';
import {batchDisposers} from '../structure';
import {
  WorkerEntity,
  WorkerType,
} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {WorkerId} from '../ApiStore';

export default class ScheduleState {
  @observable private _shadowMatrix: Matrix = INITIAL_MATRIX;
  @observable private _unsavedMatrix: UnsavedMatrix = INITIAL_UNSAVED_MATRIX;

  @observable private _userTimezoneMinutes = USER_TIMEZONE_MINUTES;
  @observable private _selectedSpeed: SelectedSpeed = {speed: 100};
  @observable private _pickedCustomSpeed = 25;
  @observable private _selectedWorkerIds = new Set<WorkerId>();

  constructor(
    private readonly _root: {
      readonly scheduleStore: ScheduleStore;
      readonly dashboardStore: DashboardStore;
    },
    private readonly _workerIds: string[],
  ) {
    makeObservable(this);
  }

  get selectedWorkerIds() {
    return this._selectedWorkerIds;
  }

  get userTimezoneMinutes() {
    return this._userTimezoneMinutes;
  }

  get selectedSpeed() {
    return this._selectedSpeed;
  }

  get pickedCustomSpeed(): number {
    return this._pickedCustomSpeed;
  }

  @computed
  get workers(): WorkerEntity[] {
    return this._workerIds.flatMap((workerId) => {
      const worker = this._root.dashboardStore.workers.get(workerId);
      return worker ? [worker] : [];
    });
  }

  @computed
  get hasPoolMiner(): boolean | null {
    if (this.workers.length === 0) {
      return null;
    }
    return this.workers.some((_) => _.worker_type === WorkerType.Pool);
  }

  @computed
  get isSingleGroup(): boolean | null {
    if (this.workers.length === 0) {
      return null;
    }
    return this.workers.length === 1;
  }

  selectWorkerId = action((id: WorkerId) => {
    this._selectedWorkerIds.add(id);
  });

  unselectWorkerId = action((id: WorkerId) => {
    this._selectedWorkerIds.delete(id);
  });

  @computed
  get isLoading() {
    return this._root.dashboardStore.dashboardRequest.isLoading;
  }

  @computed
  get matrix() {
    return joinTwoMatrix(this._shadowMatrix, this._unsavedMatrix);
  }

  @computed
  get numberOfChangedPoints() {
    return this._unsavedMatrix.flat().filter((_) => _ !== null).length;
  }

  @computed
  get allTimeActive() {
    return this.matrix
      ? days.every((day) =>
          hours.every((hour) => {
            const cell = this.matrix[day][hour];
            return cell.every((unit) => unit.speed);
          }),
        )
      : false;
  }

  resetUnsavedMatrix = action(() => {
    this._unsavedMatrix = INITIAL_UNSAVED_MATRIX;
  });

  changeSpeed = action((selectedSpeed: SelectedSpeed) => {
    if (selectedSpeed.custom) {
      this._pickedCustomSpeed = selectedSpeed.speed;
    }
    this._selectedSpeed = selectedSpeed;
  });

  changeTimezoneOffset = action((newTimezoneOffset: number) => {
    this._unsavedMatrix = shiftPointByDiffTimezone(
      joinTwoMatrix(this._shadowMatrix, this._unsavedMatrix),
      getDistanceBetweenTwoTimezone(
        this._userTimezoneMinutes,
        newTimezoneOffset,
      ),
    );
    this._userTimezoneMinutes = newTimezoneOffset;
  });

  fillPointHorizontal = action((day: number) => {
    this._unsavedMatrix[day] = hours.map((_) => [
      {length: 60, speed: this.selectedSpeed.speed},
    ]);
  });

  fillPointVertical = action((column: number) => {
    this._unsavedMatrix = this._unsavedMatrix.map((row) =>
      row.map((h, j) =>
        column === j ? [{length: 60, speed: this.selectedSpeed.speed}] : h,
      ),
    );
  });

  fillPoint = action((day: number, hour: number) => {
    this._unsavedMatrix[day][hour] = [
      {length: 60, speed: this.selectedSpeed.speed},
    ];
  });

  forceAllTime = action(() => {
    const speed = this.allTimeActive ? 0 : this.selectedSpeed.speed;
    this._unsavedMatrix = this._unsavedMatrix.map((row) =>
      row.map(() => [{length: 60, speed}]),
    );
  });

  save = action(async () => {
    const newMatrix = joinTwoMatrix(this._shadowMatrix, this._unsavedMatrix);
    let shedulerList = [...this.selectedWorkerIds.values()].flatMap((id) => {
      const worker = this._root.dashboardStore.workers.get(id);
      if (!worker) {
        return [];
      }
      const offset = getDistanceBetweenTwoTimezone(
        worker.utc_offset,
        this.userTimezoneMinutes,
      );
      const timezoneOffset = this.isSingleGroup
        ? offset
        : worker.worker_type === WorkerType.Pool
        ? this.userTimezoneMinutes
        : offset;
      return [{id, intervals: matrixToIntervals(newMatrix, timezoneOffset)}];
    });
    const shedulerMap = new Map(
      shedulerList.map(({id, intervals}) => [id, intervals]),
    );
    const settledResult = await this._root.scheduleStore.saveScheduleList(
      shedulerMap,
    );
    for (const _ of settledResult) {
      if (!_.success) {
        return _;
      }
    }
    const [first] = shedulerList;
    const needDelete = first ? first.intervals.length === 0 : false;
    if (needDelete) {
      await this._root.scheduleStore.deleteScheduleRequest.execute(
        Array.from(this.selectedWorkerIds),
      );
      this._shadowMatrix = INITIAL_MATRIX;
    }

    this._shadowMatrix = newMatrix;
    this._unsavedMatrix = INITIAL_UNSAVED_MATRIX;
  });

  private _selectWorkerOnUpdate = () =>
    reaction(
      () => this.workers.length,
      () => {
        let workerIds = new Set<string>();
        if (this.workers.length === 1) {
          workerIds.add(this.workers[0].id);
        } else {
          workerIds = new Set(
            Array.from(this.workers)
              .filter(
                (_) =>
                  _.utc_offset === this.userTimezoneMinutes ||
                  _.worker_type === WorkerType.Pool,
              )
              .map((_) => _.id),
          );
        }
        this._selectedWorkerIds = workerIds;
      },
      {fireImmediately: true},
    );

  private _initialize = () =>
    autorun(() => {
      const worker = this.workers[0];
      if (worker) {
        const intervals =
          this.workers.length > 1 ? [] : worker.schedulers ?? [];
        this._shadowMatrix = intervalsToMatrix(
          intervals,
          getDistanceBetweenTwoTimezone(
            worker.utc_offset,
            this.userTimezoneMinutes,
          ),
        );
      }
    });

  private _initCustomColor = () =>
    reaction(
      () => !this.isLoading,
      (_shouldGet) => {
        if (_shouldGet) {
          const worker = this.workers[0];
          if (worker) {
            const intervals =
              this.workers.length > 1 ? [] : worker.schedulers ?? [];
            if (intervals.length === 0) {
              return;
            }
            const intervals_ = intervals.filter(
              (_) => _.settings.speed !== 100 && _.settings.speed !== 50,
            );
            if (intervals_.length > 0) {
              const max = intervals_.reduce((prev, current) =>
                prev.stop_time - prev.start_time >
                current.stop_time - current.start_time
                  ? prev
                  : current,
              );
              if (max) {
                this._pickedCustomSpeed = max.settings.speed;
              }
            }
          }
        }
      },
      {fireImmediately: true},
    );

  subscribe = () => {
    return batchDisposers(
      this._initialize(),
      this._initCustomColor(),
      this._selectWorkerOnUpdate(),
    );
  };
}
