import React, {useCallback, useEffect, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {WithdrawalHistoryScreen} from '../../WithdrawalHistoryScreen';
import {useSafelyGoToAffiliate} from '../hooks';
import {NavigationState, PartialState} from '@react-navigation/routers';
import {RootParamList} from './RootStack';
import {nanoid} from 'nanoid';
import {AffiliateVariant} from '../hooks/useSafelyGoToAffiliate';

export type WithdrawalHistoryBindingProps =
  RootStackBindingProps<'WithdrawalHistory'>;

export default observer((props: WithdrawalHistoryBindingProps) => {
  const {navigation, route} = props;
  const goToWithdraw = useCallback(
    () => navigation.navigate('Root', {screen: 'Withdraw'}),
    [navigation],
  );

  const goToAuthAndThenOpenAffiliate = useCallback(
    () =>
      navigation.navigate('Auth', {
        onSuccess: createWithdrawalHistoryState(nanoid()),
      }),
    [navigation],
  );
  const {safelyGoToAffiliate} = useSafelyGoToAffiliate(
    goToAuthAndThenOpenAffiliate,
    AffiliateVariant.AppAbout,
  );
  const {goToAffiliateToken} = route.params ?? {};
  useOpenAffiliateOnUniqueToken(goToAffiliateToken, safelyGoToAffiliate);
  return (
    <WithdrawalHistoryScreen
      goToAffiliate={safelyGoToAffiliate}
      goToWithdraw={goToWithdraw}
    />
  );
});

export const createWithdrawalHistoryState = (
  goToAffiliateToken?: string,
): PartialState<NavigationState<RootParamList>> => {
  return {
    index: 1,
    routes: [
      {name: 'Root'},
      {name: 'WithdrawalHistory', params: {goToAffiliateToken}},
    ],
  };
};

export const useOpenAffiliateOnUniqueToken = (
  goToAffiliateToken: string | undefined,
  safelyGoToAffiliate: () => void,
) => {
  const safelyGoToAffiliateRef = useRef(safelyGoToAffiliate);
  safelyGoToAffiliateRef.current = safelyGoToAffiliate;
  useEffect(() => {
    if (goToAffiliateToken !== undefined) {
      safelyGoToAffiliateRef.current();
    }
  }, [goToAffiliateToken]);
};
