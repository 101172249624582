import {useRoot} from '../Root/hooks';
import useHandleErrorAndExtractPromo from './useHandleErrorAndExtractPromo';
import {useCallback} from 'react';
import {ISODateString} from '../Time';
import {MinerOffer, PaymentMethod} from '../InAppOffersService';
import {ErrorHandlerSample} from '../ExceptionHandler/useHandleErrorFactory';
import {PaymentType} from './index';
import dayjs from 'dayjs';
import {openLink} from '../Links';
import {BannerType} from '../DashboardBannersState';

export type HookPurchasePoolMinerParams = {
  errorHandler: ErrorHandlerSample;
};

export type PurchasePoolMinerHookParams = {
  offer: MinerOffer;
  goToPoolMinerActivated(
    poolExpiredDate: ISODateString,
    poolHashrate: number,
  ): void;
  paymentMethod: PaymentMethod;
};

export default function usePurchasePoolMiner({
  errorHandler,
}: HookPurchasePoolMinerParams) {
  const {
    purchaseRequester,
    dashboardBannersState,
    translation: {localeTag},
  } = useRoot();
  const handle = useHandleErrorAndExtractPromo({errorHandler});
  return useCallback(
    async (params: PurchasePoolMinerHookParams) => {
      const {offer, goToPoolMinerActivated, paymentMethod} = params;
      const requestParams = {
        purchaseId: offer.purchaseId,
        payment: paymentMethod,
        purchaseTokenAndroid: undefined,
        poolMinerId: undefined,
        poolMinerIdForRenew: undefined,
        subscriptionOfferTokenAndroid: offer.subscriptionOfferTokenAndroid,
      };
      const response = await purchaseRequester.request(requestParams);
      const process_ = await handle(response);
      if (!process_.success) {
        return;
      }
      if (response.success) {
        dashboardBannersState.delete(BannerType.Demo);
        dashboardBannersState.delete(BannerType.PoolMiner);
        switch (response.right.kind) {
          case PaymentType.InAppCompleted: {
            const newExpireDate = dayjs()
              .locale(localeTag)
              .add(offer.interval, 'month')
              .toISOString() as ISODateString;
            if (offer.poolMinerConfig?.hash_rate) {
              goToPoolMinerActivated(
                newExpireDate,
                offer.poolMinerConfig.hash_rate,
              );
            }
            break;
          }
          case PaymentType.ExternalOrder: {
            if (response.right.redirectUrl) {
              await openLink(response.right.redirectUrl);
            }
            break;
          }
        }
      }
    },
    [dashboardBannersState, purchaseRequester, handle, localeTag],
  );
}
