import React from 'react';
import {
  View,
  ActivityIndicatorProps,
  ViewStyle,
  ActivityIndicator,
  Text,
  TouchableWithoutFeedback,
} from 'react-native';
import {useStyles, useTheme} from '../../styling';
import Close from '../../assets/svg/colorless/close.svg';
import {sized} from '../../Svg';

interface FullScreenActivityIndicatorProps extends ActivityIndicatorProps {
  containerStyle?: ViewStyle;
  onClose?: () => void;
  message?: string;
}

export default (props: FullScreenActivityIndicatorProps) => {
  const {containerStyle, onClose, message, ...rest} = props;

  const theme = useTheme();

  const styles = useStyles(() => ({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      flex: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      justifyContent: 'center',
      alignItems: 'center',
    },
    closeButton: {
      position: 'absolute',
      top: 16,
      end: 16,
    },
    message: {
      marginBottom: 16,
      ...theme.fontByWeight(),
      color: 'white',
      fontSize: 13,
      lineHeight: 20,
      textAlign: 'center',
    },
  }));

  return (
    <View style={[styles.root, containerStyle]}>
      {onClose !== undefined && (
        <TouchableWithoutFeedback onPress={onClose}>
          <CloseIcon
            style={styles.closeButton}
            color={theme.palette.secondary}
          />
        </TouchableWithoutFeedback>
      )}
      {message !== undefined && <Text style={styles.message}>{message}</Text>}
      <ActivityIndicator
        size="large"
        color={theme.palette.secondary}
        {...rest}
      />
    </View>
  );
};

const CloseIcon = sized(Close, 32);
