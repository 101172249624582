import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import CoinSvg from '../assets/svg/colored/coin.svg';
import {SubscriptionViewProps} from './SubscriptionView';
import MarkedLabel from './MarkedLabel';
import RestoredSubscriptionView from './RestoredSubscriptionView';
import {sized} from '../Svg';

export type PermanentSubscriptionViewProps = SubscriptionViewProps & {
  email: string;
};

export default observer((props: PermanentSubscriptionViewProps) => {
  const {email, ...rest} = props;
  const label = useMemo(
    () => <MarkedLabel Mark={CoinIcon} label={email} />,
    [email],
  );
  return <RestoredSubscriptionView label={label} {...rest} />;
});

export const CoinIcon = sized(CoinSvg, 16);
