import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';

import {TouchableOpacity, View} from 'react-native';
import {Button, ButtonVariant, ButtonColor} from '../components';
import {useStrings} from '../Root/hooks';
import {createStylesHook, useTheme} from '../styling';
import ScheduleState from './ScheduleState';
import {useHandleErrorAndNavigate} from '../ExceptionHandler';

interface SchedulerFooterProps {
  state: ScheduleState;
  leave: () => void;
}

export default observer(({state, leave}: SchedulerFooterProps) => {
  const theme = useTheme();
  const strings = useStrings();
  const styles = useStyles();
  const handleErrorAndNavigate = useHandleErrorAndNavigate();
  const handleSave = useCallback(async () => {
    const res = await state.save();
    if (res?.success) {
      handleErrorAndNavigate(res.left);
    }
    leave();
  }, [handleErrorAndNavigate, leave, state]);
  if (theme.window.width <= 1280) {
    return null;
  }
  return (
    <View style={styles.root}>
      <TouchableOpacity style={styles.cancelButton} onPress={leave}>
        <Button color={ButtonColor.Default}>{strings['action.cancel']}</Button>
      </TouchableOpacity>
      <TouchableOpacity onPress={handleSave}>
        <Button variant={ButtonVariant.Highlighted}>
          {strings['action.save']}
        </Button>
      </TouchableOpacity>
    </View>
  );
});

const useStyles = createStylesHook(() => ({
  root: {
    zIndex: -1,
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    marginRight: 15,
  },
}));
