import React, {useMemo, useState, useRef, useCallback} from 'react';
import {View, Text, TouchableOpacity, Dimensions, FlatList} from 'react-native';
import Portal from '../../../Portal';
import {useRoot, useStrings} from '../../../Root/hooks';
import {observer} from 'mobx-react-lite';
import CaretDownSvg from '../../../assets/svg/colorless/caretDown.svg';
import {PressableOpacity} from '../../../components';
import {PickerItem} from './types';
import {sized} from '../../../Svg';
import {useTheme} from '../../../styling';

const INITIAL_DROPDOWN = {
  visible: false,
  xOffset: 0,
  yOffset: 0,
  width: 0,
  maxHeight: 0,
};

export const DropdownPicker = observer(() => {
  const [dropdown, setDropdown] = useState(INITIAL_DROPDOWN);
  const strings = useStrings();
  const dropdownViewRef = useRef<View>(null);
  const {
    configPickerModal: {items, selectedItem, _onSelect},
  } = useRoot();
  const theme = useTheme();
  const styles = useMemo(
    () =>
      ({
        dropdownView: {
          borderBottomColor: theme.palette.border,
          borderBottomWidth: 1,
          paddingBottom: 12,
          paddingRight: 30,
        },
        dropdownArrowView: {
          position: 'absolute',
          top: 7,
          right: 10,
        },
        dropdownText: {
          ...theme.fontByWeight('700'),
          fontSize: 15,
          lineHeight: 17,
          letterSpacing: 0.04,
          color: theme.palette.textPrimary,
        },
        dropdownSelectedText: {
          // ...theme.fontByWeight('700'),
        },
        overlayView: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
        containerView: {
          flex: 1,
        },
        listWrapperScrollView: {
          position: 'absolute',
          backgroundColor: theme.palette.background,
          borderRadius: 3,
          borderWidth: 1,
          borderColor: theme.palette.border,
          ...theme.bar(10),
        },
        listItemView: {
          minHeight: 36,
          justifyContent: 'center',
          paddingVertical: 10,
          paddingHorizontal: 15,
        },
        listItemSelectedView: {
          backgroundColor: theme.palette.secondary,
        },
        listItemText: {
          ...theme.fontByWeight('400'),
          fontSize: 14,
          lineHeight: 16,
          letterSpacing: 0.04,
          color: theme.palette.textPrimary,
        },
        listItemSelectedText: {
          color: theme.contrast(theme.palette.textPrimary),
        },
      } as const),
    [theme],
  );
  const handlePressDropdown = useCallback(() => {
    requestAnimationFrame(() => {
      dropdownViewRef.current?.measure((x, y, width, height, pageX, pageY) => {
        const xOffset = pageX;
        const yOffset = pageY + 25;
        const maxHeight = Dimensions.get('window').height - pageY - 100;
        setDropdown({visible: true, xOffset, yOffset, width, maxHeight});
      });
    });
  }, []);

  const handleItemPick = useCallback(
    (id: PickerItem['id']) => {
      _onSelect(id);
      setDropdown(INITIAL_DROPDOWN);
    },
    [_onSelect],
  );

  return (
    <View ref={dropdownViewRef} renderToHardwareTextureAndroid>
      <PressableOpacity
        activeOpacity={1}
        onPress={handlePressDropdown}
        style={styles.dropdownView}>
        <>
          <Text
            style={[
              styles.dropdownText,
              selectedItem && styles.dropdownSelectedText,
            ]}
            numberOfLines={1}>
            {selectedItem
              ? selectedItem.title
              : strings['worker.selectConfiguration']}
          </Text>
          <View
            style={[
              styles.dropdownArrowView,
              dropdown.visible && {transform: [{rotate: '180deg'}]},
            ]}>
            <CaretDownIcon width={12} height={6} color={theme.palette.icon} />
          </View>
        </>
      </PressableOpacity>
      <Portal>
        {dropdown.visible && (
          <>
            <View style={styles.containerView}>
              <PressableOpacity
                onPress={() => setDropdown(INITIAL_DROPDOWN)}
                activeOpacity={1}
                style={styles.overlayView}
              />
              <View
                style={[
                  styles.listWrapperScrollView,
                  {
                    left: dropdown.xOffset,
                    top: dropdown.yOffset,
                    width: dropdown.width,
                    maxHeight: dropdown.maxHeight,
                  },
                ]}>
                <FlatList
                  data={items}
                  keyExtractor={(item) => item.id.toString()}
                  renderItem={({item}) => {
                    const selected = item.id === selectedItem?.id;
                    return (
                      <TouchableOpacity
                        onPress={() => handleItemPick(item.id)}
                        style={[
                          styles.listItemView,
                          selected && styles.listItemSelectedView,
                        ]}>
                        <Text
                          style={[
                            styles.listItemText,
                            selected && styles.listItemSelectedText,
                          ]}
                          numberOfLines={2}>
                          {item.title}
                        </Text>
                      </TouchableOpacity>
                    );
                  }}
                />
              </View>
            </View>
          </>
        )}
      </Portal>
    </View>
  );
});

const CaretDownIcon = sized(CaretDownSvg, 12, 6);
