import {Subscription, translateAccount, translateFarm} from './Subscription';
import {FarmId, RestoreResult} from '../ApiStore';

export type RestoredSubscriptionMap = Map<FarmId, RestoredSubscription>;

export type RestoredSubscription =
  | TemporaryRestoredSubscription
  | PermanentRestoredSubscription;

export interface TemporaryRestoredSubscription extends Subscription {
  type: 'Temporary';
  token: string;
}

export interface PermanentRestoredSubscription extends Subscription {
  type: 'Permanent';
  email: string;
}

export const translateRestoredAccounts = (
  response: RestoreResult[],
): RestoredSubscriptionMap =>
  new Map(
    response.flatMap<readonly [FarmId, RestoredSubscription]>((account) =>
      account.type === 'farm'
        ? [
            [
              account.id,
              {
                ...translateFarm(account),
                type: 'Temporary',
                token: account.token,
              },
            ],
          ]
        : [...translateAccount(account).values()].map((_) => [
            _.accountId,
            {..._, type: 'Permanent', email: account.email},
          ]),
    ),
  );
