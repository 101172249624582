import {useCallback} from 'react';
import {GlobalError} from '../Error';
import {DefaultError} from '../JsonRpc';
import isRpcError from './IsRpcError';
import {useStrings, useTemplates} from '../Root/hooks';

export type OccurredErrorResult = {
  localizedError: string;
};

export type Error = GlobalError | DefaultError;

export type HookHandleErrorFactoryParams = {
  onWorkerNotManaged: (error: Error) => void;
  onOccurredError: (error: Error, result: OccurredErrorResult) => void;
  onGroupNameIsAlreadyInUse: (error: Error) => void;
  onMethodNotAllowedForYourSubscription: (error: Error) => void;
};

export type ErrorHandlerSample = ReturnType<typeof useErrorHandlerFactory>;

export default function useErrorHandlerFactory(
  params: HookHandleErrorFactoryParams,
) {
  const templates = useTemplates();
  const strings = useStrings();
  const handleOccurredError = useCallback(
    (error: Error) => {
      const {onOccurredError} = params;
      const description = isRpcError(error)
        ? templates['error.occurredWithStatusCode']({
            message: error.message,
            code: error.code,
          })
        : strings['error.occurred'];
      onOccurredError(error, {localizedError: description});
    },
    [params, templates, strings],
  );
  return useCallback(
    (error: Error) => {
      const {
        onWorkerNotManaged,
        onGroupNameIsAlreadyInUse,
        onMethodNotAllowedForYourSubscription,
      } = params;
      if (isRpcError(error)) {
        switch (error.code) {
          case 3007:
            return onWorkerNotManaged(error);
          case 3008:
            return onGroupNameIsAlreadyInUse(error);
          case 5003:
            return onMethodNotAllowedForYourSubscription(error);
          case 5002:
            // Ignored
            break;
          default:
            return handleOccurredError(error);
        }
      }
      handleOccurredError(error);
    },
    [handleOccurredError, params],
  );
}
