import {FarmLogVariant} from './entities';
import {Notification} from './index';

export const SettingsInitialState: Notification[] = [
  {
    id: FarmLogVariant.ShowAll,
    value: true,
    childrenIdList: [
      FarmLogVariant.Mining,
      FarmLogVariant.Subscription,
      FarmLogVariant.Withdrawal,
    ],
  },
  {
    id: FarmLogVariant.Mining,
    parentId: FarmLogVariant.ShowAll,
    value: true,
    childrenIdList: [
      FarmLogVariant.MiningPaused,
      FarmLogVariant.MiningRestarted,
      FarmLogVariant.NewMiner,
      FarmLogVariant.MinerOnline,
      FarmLogVariant.MinerOffline,
      FarmLogVariant.MinerOverheated,
      FarmLogVariant.Confirmation,
    ],
  },
  {
    id: FarmLogVariant.MiningPaused,
    parentId: FarmLogVariant.Mining,
    value: true,
  },
  {
    id: FarmLogVariant.MiningRestarted,
    parentId: FarmLogVariant.Mining,
    value: true,
  },
  {
    id: FarmLogVariant.NewMiner,
    parentId: FarmLogVariant.Mining,
    value: true,
  },
  {
    id: FarmLogVariant.MinerOnline,
    parentId: FarmLogVariant.Mining,
    value: true,
  },
  {
    id: FarmLogVariant.MinerOffline,
    parentId: FarmLogVariant.Mining,
    value: true,
  },
  {
    id: FarmLogVariant.MinerOverheated,
    parentId: FarmLogVariant.Mining,
    value: true,
  },
  {
    id: FarmLogVariant.Confirmation,
    parentId: FarmLogVariant.Mining,
    value: true,
  },

  {
    id: FarmLogVariant.Subscription,
    parentId: FarmLogVariant.ShowAll,
    value: true,
    childrenIdList: [
      FarmLogVariant.SubscriptionChanged,
      FarmLogVariant.SubscriptionActive,
      FarmLogVariant.SubscriptionInactive,
      FarmLogVariant.PoolMinerActivation,
      FarmLogVariant.PoolMinerExpiration,
    ],
  },
  {
    id: FarmLogVariant.SubscriptionChanged,
    parentId: FarmLogVariant.Subscription,
    value: true,
  },
  {
    id: FarmLogVariant.SubscriptionActive,
    parentId: FarmLogVariant.Subscription,
    value: true,
  },
  {
    id: FarmLogVariant.SubscriptionInactive,
    parentId: FarmLogVariant.Subscription,
    value: true,
  },
  {
    id: FarmLogVariant.PoolMinerActivation,
    parentId: FarmLogVariant.Subscription,
    value: true,
  },
  {
    id: FarmLogVariant.PoolMinerExpiration,
    parentId: FarmLogVariant.Subscription,
    value: true,
  },

  {
    id: FarmLogVariant.Withdrawal,
    parentId: FarmLogVariant.ShowAll,
    value: true,
    childrenIdList: [
      FarmLogVariant.WithdrawalProcessed,
      FarmLogVariant.WithdrawalFailed,
    ],
  },
  {
    id: FarmLogVariant.WithdrawalProcessed,
    parentId: FarmLogVariant.Withdrawal,
    value: true,
  },
  {
    id: FarmLogVariant.WithdrawalFailed,
    parentId: FarmLogVariant.Withdrawal,
    value: true,
  },
];
