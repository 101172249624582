import {
  FinishPendingPurchasesResult,
  PendingPurchasesResolver,
} from './PendingPurchasesResolver';
import {Either, error, success} from '../fp';
import {ApiStore, AppStoreReceipt} from '../ApiStore';
import {Op} from '../Math';
import {
  getWaitedTransactions,
  setWaitedTransactions,
} from '../PurchaseRequester/IosPurchaseRequesterImpl';
import delayResolve from '../utils/delayResolve';
import {InAppPurchase} from '../InAppPurchase';
import {ErrorRepository} from '../ErrorRepository';
import {Configuration} from '../Configuration';
import {GlobalError, IAP_ERROR, SERVER_ERROR} from '../Error';

export default class IosPendingPurchasesResolverImpl
  implements PendingPurchasesResolver
{
  constructor(
    private readonly _root: {
      readonly inAppPurchase: InAppPurchase;
      readonly errorRepository: ErrorRepository;
      readonly configuration: Configuration;
      readonly apiStore: ApiStore;
    },
  ) {}

  async resolve(): Promise<FinishPendingPurchasesResult> {
    const receipt_ = await this._getReceipt();
    // If the receipt error means we are in the sandbox and the receipt has not been created yet
    if (!receipt_.success) {
      return success({});
    }
    const receipt = receipt_.right as AppStoreReceipt;
    const timeout = Op.multiply(this._root.configuration.values.bffTimeout, 3);
    const purchases_ = await this._root.inAppPurchase.getPendingPurchasesIOS();
    const waitedTransactions_ = await getWaitedTransactions();
    const meta = waitedTransactions_.success
      ? Object.values(waitedTransactions_.right ?? {})
      : [];
    const appStorePurchases_ = await this._root.apiStore.client.apply(
      'appstore_purchases',
      {receipt: receipt, meta},
      {timeout: timeout},
    );
    if (!appStorePurchases_.success) {
      return error(
        this._root.errorRepository.create({
          kind: SERVER_ERROR,
          raw: appStorePurchases_.left,
        }),
      );
    }
    const pendingTransactionIdList = appStorePurchases_.right.purchases.map(
      (_) => _.transaction_id,
    );
    const neededAtTheFinish = purchases_.flatMap((_) => {
      if (!_.transactionId) {
        return [];
      }
      if (pendingTransactionIdList.includes(_.transactionId)) {
        return [_];
      }
      return [];
    });
    const newWaitedTransactions = waitedTransactions_.success
      ? waitedTransactions_.right ?? {}
      : {};
    for (const transaction of neededAtTheFinish) {
      try {
        if (transaction.transactionId) {
          delete newWaitedTransactions[transaction.transactionId];
          await delayResolve(100, () =>
            this._root.inAppPurchase.finishTransaction(transaction),
          );
        }
      } catch (ignore) {}
    }
    await setWaitedTransactions(newWaitedTransactions);
    return success(appStorePurchases_.right);
  }

  private async _getReceipt(): Promise<
    Either<AppStoreReceipt | undefined, GlobalError>
  > {
    try {
      const response = await this._root.inAppPurchase.getReceiptIOS();
      return success(response as AppStoreReceipt);
    } catch (raw) {
      return error(
        this._root.errorRepository.create({
          kind: IAP_ERROR,
          raw: raw,
        }),
      );
    }
  }
}
