import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../styling';
import {useStrings} from '../Root/hooks';
import {sized} from '../Svg';
import GiftSvg from '../assets/svg/colorless/gift.svg';
import {View, Text} from 'react-native';

export default observer(function QuickStartDiscount() {
  const strings = useStrings();
  const theme = useTheme();
  return (
    <RootView>
      <GiftView>
        <GiftIcon color={theme.palette.background} />
      </GiftView>
      <ContentView>
        <TitleText>
          {strings['quickStartScreen.freeTrialBanner.title']}
        </TitleText>
        <DescriptionText>
          {strings['quickStartScreen.freeTrialBanner.description']}
        </DescriptionText>
      </ContentView>
    </RootView>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    borderWidth: 1,
    borderRadius: 6,
    backgroundColor: theme.palette.primary,
    borderColor: theme.palette.primary,
    flexDirection: 'row',
    overflow: 'hidden',
    minHeight: 56,
  },
}));

const ContentView = variance(View)((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background,
    paddingVertical: 8,
    paddingLeft: 15,
    paddingRight: 25,
  },
}));

const GiftView = variance(View)((theme) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    backgroundColor: theme.palette.primary,
  },
}));

const TitleText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 22,
    color: theme.palette.primary,
  },
}));

const DescriptionText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 12,
    lineHeight: 18,
    color: theme.chroma(theme.palette.textSecondary).alpha(0.8).hex(),
  },
}));

const GiftIcon = sized(GiftSvg, 20, 22);
