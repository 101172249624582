import React, {useCallback, useState} from 'react';

import {useRoot, useStrings} from '../../Root/hooks';
import {WorkerEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {ReadonlyDeep} from 'type-fest';
import {observer} from 'mobx-react-lite';
import getWorkerName from '../getters/getWorkerName';
import InputModal from '../../modals/InputModal';
import {ButtonColor, ButtonVariant} from '../../components';
import {useHandleErrorAndNavigate} from '../../ExceptionHandler';

interface RenameWorkerModalProps {
  worker: ReadonlyDeep<WorkerEntity>;
  onDismiss: () => void;
}

export default observer(({worker, onDismiss}: RenameWorkerModalProps) => {
  const {connectedClient} = useRoot();
  const [workerName, setWorkerName] = useState<string>(getWorkerName(worker));
  const strings = useStrings();
  const errorHandler = useHandleErrorAndNavigate();
  const onSubmit = useCallback(async () => {
    const result = await connectedClient.call('set_worker_name', {
      worker_id: worker.id,
      name: workerName,
    });
    if (!result.success) {
      errorHandler(result.left);
    }
    onDismiss();
  }, [connectedClient, errorHandler, onDismiss, worker.id, workerName]);

  return (
    <InputModal
      inputProps={{
        autoFocus: true,
        defaultValue: workerName,
        onChangeText: setWorkerName,
        subtext: strings['modal.renameWorker.subtext'],
      }}
      onDismiss={onDismiss}
      title={strings['modal.renameWorker.title']}
      buttons={[
        {
          text: strings['action.cancel'],
          onPress: onDismiss,
          color: ButtonColor.Default,
          variant: ButtonVariant.Contained,
        },
        {
          text: strings['action.confirm'],
          onPress: onSubmit,
          variant: ButtonVariant.Highlighted,
        },
      ]}
    />
  );
});
