import React from 'react';
import {observer} from 'mobx-react-lite';
import {PressableOpacity} from '../../../../components';
import {useStyles, variance} from '../../../../styling';
import {Platform, Text, View, ViewProps} from 'react-native';
import {PressableOpacityProps} from '../../../../components/PressableOpacity';
import {Hashrate, Interval, SlotCount} from '../../../../ApiStore';
import getHumanizeDuration from '../../../getters/getHumanizeDuration';
import dayjs from 'dayjs';
import {Millisecond} from '../../../../Time';
import {useRoot, useStrings} from '../../../../Root/hooks';
import {OfferId} from '../../../../InAppOffersService';
import {colored, sized} from '../../../../Svg';
import GiftSvg from '../../../../assets/svg/colorless/gift.svg';

export type IntervalRowProps = PressableOpacityProps & {
  disabled: boolean;
  selected: boolean;
  isLast: boolean;
  duration: Interval;
  hashrate?: Hashrate;
  maxSlot?: SlotCount;
  hideGift?: boolean;
  offerId?: OfferId;
  pricePerMonthPerHashrate?: string;
  priceText: string;
  suffix: string;
  discounts?: number[];
};

const DISCOUNT_HEIGHT = 24;
const DISCOUNT_BOTTOM_OFFSET = 5;

export const IntervalRow = observer((props: IntervalRowProps) => {
  const {
    disabled,
    selected,
    duration,
    pricePerMonthPerHashrate,
    priceText,
    suffix,
    hideGift,
    discounts,
    ...rest
  } = props;
  const {translation} = useRoot();
  const strings = useStrings();
  const getDurationText = (interval: Interval) => {
    return getHumanizeDuration(
      dayjs.duration(interval, 'month').asMilliseconds() as Millisecond,
      translation.localeTag,
    );
  };
  return (
    <Root disabled={disabled} activeOpacity={selected ? 1 : 0.8} {...rest}>
      <RowView selected={selected}>
        <Row outer>
          <Row>
            <RowCheckbox selected={selected} />
            <MonthText selected={selected} disabled={disabled}>
              {getDurationText(duration)}
            </MonthText>
          </Row>
          {duration === 12 && !hideGift && (
            <GiftContainer>
              <GiftIcon />
              <GiftText>{strings['common.gift']}</GiftText>
            </GiftContainer>
          )}
        </Row>
        <RowRightView>
          <PerMonthPerHashrate>{pricePerMonthPerHashrate}</PerMonthPerHashrate>
          <PriceView>
            {priceText ? <PriceText>{priceText}</PriceText> : null}
            <SuffixText> / {suffix}</SuffixText>
          </PriceView>
        </RowRightView>
        {discounts && (
          <Discounts
            style={{
              transform: [
                {
                  translateY: -(
                    ((DISCOUNT_HEIGHT + DISCOUNT_BOTTOM_OFFSET) *
                      (discounts.length - 1 || Number(Platform.OS === 'web')) -
                      DISCOUNT_BOTTOM_OFFSET) /
                    2
                  ),
                },
              ],
            }}>
            {discounts.map((d, idx) => (
              <Discount
                key={d}
                second={idx === 1}
                third={idx === 2}
                noOffset={idx === discounts.length - 1}>
                -{d}%
              </Discount>
            ))}
          </Discounts>
        )}
      </RowView>
    </Root>
  );
});

const GiftIcon = colored(sized(GiftSvg, 12, 12), '#fff');

type CheckboxProps = ViewProps & {
  selected: boolean;
};

const Checkbox = observer(({selected, style, ...rest}: CheckboxProps) => {
  const styles = useStyles((theme) => ({
    root: {
      width: 16,
      height: 16,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: theme.palette.border,
      justifyContent: 'center',
      alignItems: 'center',
    },
    rootSelected: {
      borderWidth: 2,
      borderColor: theme.palette.secondary,
    },
    dot: {
      width: 6,
      height: 6,
      borderRadius: 3,
      backgroundColor: theme.palette.secondary,
    },
  }));
  return (
    <View
      style={[styles.root, selected && styles.rootSelected, style]}
      {...rest}>
      {selected && <View style={styles.dot} />}
    </View>
  );
});

const Root = variance(PressableOpacity)((theme) => ({
  root: {
    ...theme.mediaQuery({
      1000: {
        marginHorizontal: 10,
        flex: 1,
      },
    }),
  },
}));

const PerMonthPerHashrate = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('500'),
    marginTop: 8,
    fontSize: 12,
    color: theme.palette.textSecondary,
  },
}));

const RowView = variance(View)((theme) => ({
  root: {
    minHeight: 106,
    paddingVertical: 15,
    paddingLeft: 15,
    paddingRight: 60,
    marginBottom: 15,
    borderWidth: 1,
    borderRadius: 15,
    borderColor: theme.palette.border,
    ...theme.mediaQuery({
      1000: {
        marginBottom: 0,
        flex: 1,
        minHeight: 131,
      },
    }),
  },
  selected: {
    backgroundColor: theme.palette.additionalInfo,
    borderColor: theme.palette.info,
  },
}));

const Discounts = variance(View)(() => ({
  root: {
    position: 'absolute',
    right: -1,
    top: '50%',
  },
}));

const Discount = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    height: 24,
    width: 54,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 24,
    color: '#fff',
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    backgroundColor: theme.palette.primary,
    marginBottom: 5,
  },
  second: {
    backgroundColor: theme.palette.success,
  },
  third: {
    backgroundColor: '#000',
  },
  noOffset: {
    marginBottom: 0,
  },
}));

const Row = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  outer: {
    flexDirection: 'row',
    alignItems: 'center',
    ...theme.mediaQuery({
      1000: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    }),
  },
}));

const GiftContainer = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.palette.purple,
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8,
    borderRadius: 50,
    ...theme.mediaQuery({
      1000: {
        marginLeft: 0,
        marginTop: 8,
      },
    }),
  },
}));

const GiftText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    textTransform: 'uppercase',
    fontSize: 11,
    color: '#fff',
    marginLeft: 4,
  },
}));

const RowCheckbox = variance(Checkbox)(() => ({
  root: {
    marginRight: 8,
  },
}));

const MonthText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 17,
    lineHeight: 20,
    letterSpacing: 0.056,
    color: theme.palette.textPrimary,
  },
  selected: {
    color: theme.palette.secondary,
  },
  disabled: {
    color: theme.palette.disabled,
  },
}));

const PriceView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
}));

const PriceText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 18,
    color: theme.palette.textPrimary,
  },
}));

const SuffixText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    lineHeight: 16,
    color: theme.palette.textSecondary,
  },
}));

const RowRightView = variance(View)(() => ({
  root: {
    marginTop: 'auto',
  },
}));
