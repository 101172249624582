import {WorkerId} from '../ApiStore';
import {Either} from '../fp';

export interface GroupController {
  create(
    name: string,
    workerIds: WorkerId[],
  ): Promise<Either<void, CreateGroupError>>;

  rename(id: number, name: string): Promise<void>;

  delete(id: number): Promise<void>;

  setWorkers(id: number, workerIds: WorkerId[]): Promise<void>;
}

export interface CreateGroupError {
  reason: CreateGroupErrorReason;
}

export enum CreateGroupErrorReason {
  NameConflict,
}
