import React from 'react';
import {observer} from 'mobx-react-lite';
import {
  MessageComponentProps,
  DefaultFlash,
  MessageType,
} from 'react-native-flash-message';
import {useStyles} from '../styling';

export type FlashMessageProps = MessageComponentProps & {};

export default observer((props: FlashMessageProps) => {
  const {message} = props;
  const type = message.type as MessageType;
  const styles = useStyles((theme) => ({
    root: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    rootSuccess: {
      backgroundColor: theme.chroma(theme.palette.success).alpha(0.95).hex(),
    },
    title: {
      ...theme.fontByWeight('bold'),
      textTransform: 'uppercase',
    },
    text: {
      ...theme.fontByWeight('400'),
    },
  }));

  return (
    <DefaultFlash
      {...props}
      style={[styles.root, type === 'success' && styles.rootSuccess]}
      textStyle={styles.text}
      titleStyle={styles.title}
    />
  );
});
