import React from 'react';
import {observer} from 'mobx-react-lite';
import {Text} from 'react-native';
import {Button, PressableOpacity} from '../../../components';
import {sized} from '../../../Svg';
import ChevronRightSvg from '../../../assets/svg/colorless/chevronRight.svg';
import {createStylesHook} from '../../../styling';
import {DESKTOP_BREAKPOINT} from '../../../units/constants';

export interface SimpleSlotHeaderProps {
  index: number;
  goToDetailsPress: () => void;
}

export default observer(({index, goToDetailsPress}: SimpleSlotHeaderProps) => {
  const styles = useStyles();
  return (
    <>
      <Text style={styles.position}>{index + 1}.</Text>
      <PressableOpacity onPress={goToDetailsPress} style={styles.chevron}>
        <Button Icon={ChevronRightIcon} style={styles.button} />
      </PressableOpacity>
    </>
  );
});

const ChevronRightIcon = sized(ChevronRightSvg, 5, 10);

const useStyles = createStylesHook((theme) => ({
  position: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textSecondary,
    fontSize: 12,
    lineHeight: 14,
    position: 'absolute',
    left: 9,
    top: 23,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 14,
        lineHeight: 16,
        top: 22,
      },
    }),
  },
  chevron: {
    position: 'absolute',
    right: 9,
    top: 20,
  },
  button: {
    width: 24,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
}));
