import {SectionListData} from 'react-native';
import {GroupSection, WorkerItem} from '../../form/formSections';

export default (
  one: SectionListData<WorkerItem, GroupSection>,
  another: SectionListData<WorkerItem, GroupSection>,
) =>
  !one.isEmpty && !another.isEmpty
    ? one.dataToken === another.dataToken &&
      one.isDefaultGroup === another.isDefaultGroup &&
      one.slotsLeftToShow === another.slotsLeftToShow
    : one.isEmpty && another.isEmpty
    ? one.slotsLeftToShow === another.slotsLeftToShow
    : false;
