import {batchDisposers, Disposer, Service} from '../structure';
import {StaticGoogleDeferredDeeplinkImpl} from '../GoogleDeferredDeeplink';
import {observable, reaction, makeObservable} from 'mobx';
import {Auth} from '../Auth';
import {ConnectedClient} from '../ContextClient';
import BatchRefParserImpl from './BatchRefParserImpl';

export default class AndroidDeferredDeeplinkService implements Service {
  @observable private _deeplink?: string;
  private readonly _parser = new BatchRefParserImpl();

  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly connectedClient: ConnectedClient;
    },
  ) {
    makeObservable(this);
  }

  private _setDeeplinkIfNotSet(deeplink: string) {
    if (!this._deeplink) {
      this._deeplink = deeplink;
    }
  }

  private async _getPreviouslySettled() {
    const result = await StaticGoogleDeferredDeeplinkImpl.getDeferredDeepLink();
    if (result.deeplink) {
      this._setDeeplinkIfNotSet(result.deeplink);
    }
  }

  private _subscribeOnDeferred() {
    // noinspection JSIgnoredPromiseFromCall
    this._getPreviouslySettled();
    const listener = StaticGoogleDeferredDeeplinkImpl.addListener((result) => {
      if (result.deeplink) {
        this._setDeeplinkIfNotSet(result.deeplink);
      }
    });
    return (() => listener.remove()) as Disposer;
  }

  private _fetchOnPossible() {
    return reaction(
      () => [this._root.auth.isConnected, this._deeplink] as const,
      ([isConnected, link]) => {
        if (isConnected && link) {
          const result = this._parser.parse(link);
          if (result.success) {
            // noinspection JSIgnoredPromiseFromCall
            this._root.connectedClient.apply('set_utm', {utm: result.right});
          }
        }
      },
    );
  }

  subscribe() {
    return batchDisposers(this._fetchOnPossible(), this._subscribeOnDeferred());
  }
}
