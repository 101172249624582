import {
  DeviceIdentification,
  DeviceIdentificationState,
  DeviceIdentificationStateKind as Kind,
  RegisterDeviceError,
  RegisterDeviceResult,
} from './DeviceIdentification';
import {flow, observable, makeObservable} from 'mobx';
import {AsyncReturnType} from 'type-fest';
import analytics from '@react-native-firebase/analytics';
import getUniqueId from './getUniqueId';
import {Either} from '../fp';
import registerDevice, {purge} from './registerDevice';
import {Configuration} from '../Configuration';
import {getReadableVersion} from 'react-native-device-info';
import {Platform} from 'react-native';
import packageFile from '../../package.json';
import {PlatformName} from '../ApiStore';
import {InstallIdentification} from '../InstallIdentification';
import {Http} from '../Http';
import {AdvertisingIdentifier} from '../AdvertisingIdentifier';

export default class DeviceIdentificationService
  implements DeviceIdentification
{
  @observable.ref private _state?: DeviceIdentificationState;

  constructor(
    private readonly _root: {
      readonly http: Http;
      readonly configuration: Configuration;
      readonly installIdentification: InstallIdentification;
      readonly advertisingIdentifier: AdvertisingIdentifier;
    },
  ) {
    makeObservable(this);
  }

  get state() {
    return this._state ?? {kind: Kind.Registering};
  }

  fetch = flow(function* (this: DeviceIdentificationService) {
    if (this._state?.kind === Kind.Registering) {
      throw new Error('Registration conflict');
    }
    if (this._state?.kind === Kind.Registered) {
      return this._state;
    }
    this._state = {kind: Kind.Registering};
    const uniqueId = yield getUniqueId();
    let referrer: string | undefined;
    try {
      referrer = yield this._root.installIdentification.getRef();
    } catch (ignore) {}
    let appInstanceId: string | undefined;
    try {
      const id: string | null = yield analytics().getAppInstanceId();
      if (id) {
        appInstanceId = id;
      }
    } catch (ignore) {}
    const appVersion = encodeURIComponent(
      Platform.select({
        web: packageFile.version,
        default: getReadableVersion(),
      }),
    );
    const aifa: AsyncReturnType<AdvertisingIdentifier['getInfo']> =
      yield this._root.advertisingIdentifier.getInfo();
    const attribution =
      aifa.success && aifa.right
        ? {
            tenjin: {aifa: aifa.right},
          }
        : {};
    const result: Either<RegisterDeviceResult, RegisterDeviceError> =
      yield registerDevice(
        this._root,
        {
          device_id: uniqueId,
          install_referrer: referrer,
          platform: Platform.OS as PlatformName,
          version: appVersion,
          attribution_map: attribution,
          app_instance_id: appInstanceId,
        },
        this._root.configuration.values.apiUrl,
        this._root.configuration.values.bffTimeout,
      );
    this._state = result.success
      ? {kind: Kind.Registered, result: result.right}
      : {
          kind: Kind.RegistrationError,
          error: result.left,
        };
    if (this._state.kind === Kind.Registered) {
      return this._state;
    } else {
      throw this._state.error.raw;
    }
  });

  async purge() {
    await purge();
  }
}
