import {LogEntity} from '../ApiStore';

export default (previous: LogEntity[], current: LogEntity[]) => {
  if (previous.length === 0) {
    return current.slice();
  }
  const result = [];
  const lastId = previous[0].id;
  for (let i = 0; i < current.length; i++) {
    if (current[i].id === lastId) {
      break;
    }
    result.push(current[i]);
  }
  return result;
};
