import {Either} from '../fp';
import {GlobalError} from '../Error';
import {Opaque} from 'type-fest';

export interface AdvertisingIdentifier {
  getInfo(): Promise<Either<AdvertisingId | null, GlobalError>>;
}

export const ADVERTISING_ID = Symbol.for('Advertising Id');

export type AdvertisingId = Opaque<string, typeof ADVERTISING_ID>;
