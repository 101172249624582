import React, {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {DashboardScreen} from '../../DashboardScreen';
import {BottomTabBindingProps} from './BottomTabBindingProps';
import {
  NavigationState,
  PartialState,
} from '@react-navigation/routers/src/types';
import {RootParamList} from '../RootStack';
import {useNavigationGetIsFocused, useSafelyGoToAffiliate} from '../hooks';
import {AffiliateVariant} from '../hooks/useSafelyGoToAffiliate';
import {BottomTabParamList} from './BottomTab';
import {useRoot} from '../../Root/hooks';
import {autorun, observable, runInAction} from 'mobx';
import {CommonActions} from '@react-navigation/native';
import {expr} from 'mobx-utils';
import {FULFILLED} from '../../AsyncAtom';
import {AdSpot} from '../../ApiStore';
import {ApiMode} from '../../farmApi';
import {RegistrationLinkModalState} from '../../DashboardScreen/DashboardScreen';
import {FINISHED} from '../../InteractiveTutorial';
import {ActivatorStatus} from '../../PoolMinerActivator';

export type DashboardBindingProps = BottomTabBindingProps<'Dashboard'>;

export default observer(function DashboardBinding(
  props: DashboardBindingProps,
) {
  const {navigation} = props;
  const {apiStore, poolMinerActivator} = useRoot();

  useRedirect(props);

  const goToAuthAndThenToDashboard = useCallback(
    () =>
      navigation.navigate('Auth', {
        onSuccess: createDashboardState(),
        variant: 'promo',
      }),
    [navigation],
  );
  const goToPlan = useCallback(() => navigation.navigate('Plan'), [navigation]);

  const [registrationLinkModalState, setRegistrationLinkModalState] =
    useState<RegistrationLinkModalState>({visible: false});

  const goToCopyRegistrationLink = useCallback(
    (code: string) => setRegistrationLinkModalState({visible: true, code}),
    [],
  );
  const dismissRegistrationLinkModal = useCallback(
    () => setRegistrationLinkModalState({visible: false}),
    [],
  );
  const goToActivator = useCallback(
    () => poolMinerActivator.setStatus(ActivatorStatus.NeedActivate),
    [poolMinerActivator],
  );
  const {
    safelyGoToAffiliate: safelyGoToAboutAffiliate,
    getAffiliateIsPending: getAboutAffiliateIsPending,
  } = useSafelyGoToAffiliate(
    goToAuthAndThenToDashboard,
    AffiliateVariant.About,
  );
  const {
    safelyGoToAffiliate: safelyGoToPromoAffiliate,
    getAffiliateIsPending: getPromoAffiliateIsPending,
  } = useSafelyGoToAffiliate(
    goToAuthAndThenToDashboard,
    AffiliateVariant.Promo,
  );
  const isDemo = expr(() => apiStore.mode === ApiMode.Demo);

  return (
    <DashboardScreen
      safelyGoToAboutAffiliate={safelyGoToAboutAffiliate}
      getAboutAffiliateIsPending={getAboutAffiliateIsPending}
      safelyGoToPromoAffiliate={safelyGoToPromoAffiliate}
      getPromoAffiliateIsPending={getPromoAffiliateIsPending}
      goToPlan={goToPlan}
      goToActivator={goToActivator}
      isDemo={isDemo}
      goToCopyRegistrationLink={goToCopyRegistrationLink}
      dismissRegistrationLinkModal={dismissRegistrationLinkModal}
      registrationLinkModalState={registrationLinkModalState}
    />
  );
});

const useRedirect = ({navigation, route}: DashboardBindingProps) => {
  const {interactiveTutorial, advertHelper, quickStartOffer} = useRoot();
  const interactiveTutorialParam = route.params?.interactiveTutorial;
  const getIsFocused = useNavigationGetIsFocused();
  const isCurrentScreen = route.name === 'Dashboard';
  const shouldRedirectToMobileAdSplash = expr(
    () =>
      isCurrentScreen &&
      (advertHelper.state?.status === FULFILLED
        ? advertHelper.state.result.spotByBannerList.get(
            AdSpot.SplashMobile,
          ) !== undefined
        : false) &&
      advertHelper.isDimensionsForShowMobileSplash,
  );
  const shouldRedirectToDesktopAdSplash = expr(
    () =>
      isCurrentScreen &&
      (advertHelper.state?.status === FULFILLED
        ? advertHelper.state.result.spotByBannerList.get(
            AdSpot.SplashDesktop,
          ) !== undefined
        : false) &&
      advertHelper.isDimensionsForShowDesktopSplash,
  );
  const shouldRedirectToAdSplash = expr(
    () => shouldRedirectToMobileAdSplash || shouldRedirectToDesktopAdSplash,
  );
  const shouldRedirectToQuickStart = quickStartOffer.shouldShownModal;

  const [tutorialBusyBox] = useState(() => observable.box(false));
  const getTutorialBusyBox = useCallback(
    () => tutorialBusyBox.get(),
    [tutorialBusyBox],
  );
  const goToAdSplash = useCallback(
    () => navigation.navigate('AdvertSplash'),
    [navigation],
  );

  const goToQuickStart = useCallback(
    () => navigation.navigate('QuickStartModal'),
    [navigation],
  );

  useEffect(() => {
    if (interactiveTutorialParam?.onSuccess !== undefined) {
      runInAction(() => tutorialBusyBox.set(true));
      interactiveTutorial.start();
      interactiveTutorial.events.once(FINISHED, () => {
        const nextState = interactiveTutorialParam?.onSuccess;
        if (nextState) {
          navigation.dispatch(CommonActions.reset(nextState));
        } else {
          runInAction(() => tutorialBusyBox.set(false));
        }
      });
    }
  }, [
    tutorialBusyBox,
    interactiveTutorial,
    interactiveTutorialParam,
    navigation,
  ]);

  useEffect(
    () =>
      autorun(() => {
        if (!getIsFocused() || getTutorialBusyBox() || tutorialBusyBox.get()) {
          return;
        }
        if (shouldRedirectToAdSplash) {
          goToAdSplash();
        } else if (shouldRedirectToQuickStart) {
          goToQuickStart();
        }
      }),
    [
      tutorialBusyBox,
      getTutorialBusyBox,
      getIsFocused,
      goToAdSplash,
      goToQuickStart,
      shouldRedirectToAdSplash,
      shouldRedirectToQuickStart,
    ],
  );
};

export const createDashboardState = (
  params?: BottomTabParamList['Dashboard'],
): PartialState<NavigationState<RootParamList>> => {
  return {
    index: 0,
    routes: [
      {
        name: 'Root',
        state: {
          index: 0,
          routes: [
            {
              name: 'Dashboard',
              params: params,
            },
          ],
        },
      },
    ],
  };
};
