import React, {PropsWithChildren, useMemo} from 'react';
import {View, ViewProps} from 'react-native';
import {CryptoLoader} from '../../components/CryptoLoader';

interface LoaderWrapperProps extends ViewProps {
  loading: boolean;
}

export const LoaderWrapper = ({
  children,
  loading,
  ...rest
}: PropsWithChildren<LoaderWrapperProps>) => {
  const styles = useMemo(
    () =>
      ({
        loaderView: {
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      } as const),
    [],
  );
  const opacity = loading ? 0 : 1;
  return (
    <View {...rest}>
      <View style={{opacity}}>{children}</View>
      {loading && (
        <View style={styles.loaderView}>
          <CryptoLoader />
        </View>
      )}
    </View>
  );
};
