import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {error, success} from '../fp';
import {FERNET_TOKEN} from './keys';
import {SetterResult} from './common';

/**
 * @throws {never}
 */
export default async (token?: string): Promise<SetterResult> => {
  try {
    if (
      SecureStore &&
      SecureStore.isAvailableAsync &&
      (await SecureStore.isAvailableAsync())
    ) {
      if (token === undefined) {
        await SecureStore.deleteItemAsync(FERNET_TOKEN);
      } else {
        await SecureStore.setItemAsync(FERNET_TOKEN, token);
      }
    } else {
      if (token === undefined) {
        await AsyncStorage.removeItem(FERNET_TOKEN);
      } else {
        await AsyncStorage.setItem(FERNET_TOKEN, token);
      }
    }
    return success(undefined);
  } catch (raw) {
    return error(raw);
  }
};
