import {observable, runInAction, makeObservable} from 'mobx';
import {define, TUTORIAL_PARAMS} from '../persistence';
import {Service} from '../structure';
import {TutorialStorage, TutorialRecord} from './TutorialStorage';

export default class TutorialStorageService
  implements Service, TutorialStorage
{
  private _record?: TutorialRecord;
  @observable private _isReady = false;

  constructor() {
    makeObservable(this);
  }

  async markDone() {
    runInAction(() => (this._record = {done: true}));
    await this._persist();
  }

  get isReady() {
    return this._isReady;
  }

  get record() {
    return this._record;
  }

  private async _persist() {
    if (this._record) {
      return setTutorialParams(this._record);
    }
  }

  private _init() {
    getTutorialParams().then((res) => {
      runInAction(() => {
        if (res.success) {
          this._record = res.right || {done: false};
        } else {
          this._record = {done: true};
        }
        this._isReady = true;
      });
    });
  }

  subscribe() {
    this._init();
    return undefined;
  }
}

export const [getTutorialParams, setTutorialParams] =
  define<TutorialRecord>(TUTORIAL_PARAMS);
