import React from 'react';
import {View, Text, ViewProps} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../styling';
import {useRoot, useStrings} from '../Root/hooks';
import {expr} from 'mobx-utils';
import {first} from 'lodash';
import {AdSpot} from '../ApiStore';
import AdsBanner from './AdsBanner';
import {DESKTOP_BREAKPOINT} from '../units/constants';

export default observer((props: ViewProps) => {
  const {style, ...rest} = props;
  const {advertHelper, windowDimensionsState} = useRoot();
  const isDesktop = expr(() => windowDimensionsState.window.width > 1000);
  const advertMobileBanner = expr(() =>
    first(advertHelper.spotByBannerList?.get(AdSpot.PaymentDesktop)),
  );
  const getBanner = () => {
    if (advertMobileBanner && isDesktop) {
      return (
        <View style={styles.banner}>
          <AdsBanner
            isDesktop={isDesktop}
            advertItem={advertMobileBanner}
            height={150}
          />
        </View>
      );
    }
  };
  const styles = useStyles((theme) => ({
    root: {
      minHeight: 150,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.background,
      borderBottomStartRadius: 10,
      borderBottomEndRadius: 10,
      borderColor: theme.palette.border,
      borderStartWidth: 1,
      borderEndWidth: 1,
      borderBottomWidth: 1,
      width: '100%',
      maxWidth: 1100,
      marginHorizontal: 'auto',
    },
    label: {
      ...theme.fontByWeight(),
      fontSize: 18,
      lineHeight: 30,
      letterSpacing: 0.0685714,
      color: theme.palette.textSecondary,
      ...theme.mediaQuery({
        [DESKTOP_BREAKPOINT]: {
          fontSize: 24,
          marginTop: 110,
        },
      }),
    },
    banner: {
      marginTop: 60,
      marginBottom: 20,
      height: 150,
      paddingHorizontal: 20,
    },
  }));
  const strings = useStrings();
  return (
    <View style={[styles.root, style]} {...rest}>
      <Text style={styles.label}>{strings['withdrawalHistory.empty']}</Text>
      {getBanner()}
    </View>
  );
});
