import {useRoot} from '../Root/hooks';
import {useCallback} from 'react';
import {ApiMode} from '../farmApi';
import {useHandleErrorAndNavigate} from '../ExceptionHandler';

export type UseGetNewWorkerIdResult = {
  getNewWorkerId: () => Promise<
    {mode: ApiMode; workerConnectionId: string} | undefined
  >;
};

export default function useGetNewWorkerId(): UseGetNewWorkerIdResult {
  const {connectedClient} = useRoot();
  const handleErrorAndNavigate = useHandleErrorAndNavigate();
  const getNewWorkerId = useCallback(async () => {
    const result = await connectedClient.call('new_worker', {
      configuration: undefined,
    });
    if (!result.success) {
      handleErrorAndNavigate(result.left);
      return;
    }
    const {worker_connection_id} = result.right;
    return {
      mode: ApiMode.Real,
      workerConnectionId: worker_connection_id,
    };
  }, [connectedClient, handleErrorAndNavigate]);
  return {getNewWorkerId};
}
