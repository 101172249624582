import React from 'react';
import {observer} from 'mobx-react-lite';
import {TouchableOpacity, TouchableOpacityProps} from 'react-native';
import Svg, {Path, SvgProps} from 'react-native-svg';
import * as Animatable from 'react-native-animatable';
import {useStyles} from '../styling';

export default observer(({style, ...rest}: TouchableOpacityProps) => {
  const styles = useStyles((theme) => ({
    root: {
      position: 'absolute',
      right: 0,
      bottom: 50,
    },
    button: {
      width: 40,
      height: 40,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary,
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));
  return (
    <Animatable.View useNativeDriver animation="fadeIn" style={styles.root}>
      <TouchableOpacity
        style={[styles.button, style]}
        activeOpacity={0.8}
        {...rest}>
        <TopArrow />
      </TouchableOpacity>
    </Animatable.View>
  );
});

const TopArrow = (props: SvgProps) => (
  <Svg width={14} height={20} viewBox="0 0 14 20" fill="none" {...props}>
    <Path
      d="M1 8l6-6 6 6M7 3v16"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </Svg>
);
