import {Location} from './Location';
import {batchDisposers, Service} from '../structure';
import DynamicLinksLocationSourceService from './DynamicLinksLocationSourceService';
import DeepLinkingLocationSourceService from './DeepLinkingLocationSourceService';
import BatchLocationSourceService from './BatchLocationSourceService';
import {LocationSource} from './LocationSource';
import {Url} from '../units';
import {EXTERNAL, SpecialLocation} from '../SpecialLocation';
import {Either} from '../fp';
import {LocalNotificationsLocationSource} from '../LocalNotifications';
import HeadlessLocationImpl from './HeadlessLocationImpl';

export default class MobileLocationService
  extends HeadlessLocationImpl
  implements Location, LocationSource, Service
{
  private _source: BatchLocationSourceService;

  constructor(
    protected readonly _root: {
      readonly localNotificationsLocationSource: LocalNotificationsLocationSource;
      readonly specialLocation: SpecialLocation;
    },
  ) {
    super();
    this._source = new BatchLocationSourceService([
      this._root.localNotificationsLocationSource,
      this._dynamicLinks,
      this._deepLinking,
    ]);
  }

  async open(locator: Url): Promise<Either<void, unknown>> {
    if (this._root.specialLocation.parse(locator).kind === EXTERNAL) {
      return super.open(locator);
    } else {
      return this._source.open(locator);
    }
  }

  private _dynamicLinks = new DynamicLinksLocationSourceService();

  private _deepLinking = new DeepLinkingLocationSourceService();

  getInitial() {
    return this._source.getInitial();
  }

  get updates() {
    return this._source.updates;
  }

  subscribe() {
    return batchDisposers(
      this._dynamicLinks.subscribe(),
      this._deepLinking.subscribe(),
      this._source.subscribe(),
    );
  }
}
