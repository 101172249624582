import {LogContent} from './LogContent';
import {ReadyState} from '../Connection';

export default class WebSocketReadyStateLogContent implements LogContent {
  constructor(
    public readonly url: string,
    public readonly connectionId: number,
    public readonly readyState: ReadyState.Connecting | ReadyState.Open,
  ) {}

  get body() {
    const readyState = ReadyState[this.readyState];
    const lines = [`#${this.connectionId} ${readyState}`];
    if (this.readyState === ReadyState.Connecting) {
      lines.push(this.url);
    }
    return lines.join('\n');
  }
}
