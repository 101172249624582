import {
  ApplyOptions,
  CallParameters,
  CallScheme,
  Client,
  DefaultError,
  NotificationScheme,
  NotifyParameters,
} from '../JsonRpc';
import {ApiStore} from '../ApiStore';
import {Either} from '../fp';

export default abstract class ContextClientImpl<
  C extends CallScheme = CallScheme,
  N extends NotificationScheme = NotificationScheme,
> implements Client<C, N>
{
  protected constructor(
    protected readonly _root: {
      readonly apiStore: ApiStore;
    },
  ) {}

  protected abstract _isAppropriateContext(): Either<void, DefaultError>;

  notify<K extends keyof N>(
    ...args: NotifyParameters<K, Parameters<N[K]>>
  ): void {
    const context_ = this._isAppropriateContext();
    if (!context_.success) {
      return;
    }
    this._root.apiStore.client.notify.apply(
      this._root.apiStore.client,
      args as never,
    );
  }

  async apply<K extends keyof C>(
    method: K,
    params: Parameters<C[K]>[0],
    options?: ApplyOptions,
  ): Promise<ReturnType<C[K]> | Either<never, DefaultError>> {
    const context_ = this._isAppropriateContext();
    if (!context_.success) {
      return context_;
    }
    // noinspection ES6MissingAwait
    return this._root.apiStore.client.apply(
      method as any,
      params,
      options,
    ) as any;
  }

  async call<K extends keyof C>(
    ...args: CallParameters<K, Parameters<C[K]>>
  ): Promise<ReturnType<C[K]> | Either<never, DefaultError>> {
    const context_ = this._isAppropriateContext();
    if (!context_.success) {
      return context_;
    }
    // noinspection ES6MissingAwait
    return this._root.apiStore.client.call(...(args as any)) as any;
  }
}
