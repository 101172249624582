import {CommonActions, useNavigation} from '@react-navigation/native';
import {useCallback} from 'react';
import {DASHBOARD} from '../RootStack/constants';

export default function useSafelyGoBack() {
  const navigation = useNavigation();
  return useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.dispatch(CommonActions.reset(DASHBOARD));
    }
  }, [navigation]);
}
