import messaging from '@react-native-firebase/messaging';
import {action, observable, runInAction, makeObservable} from 'mobx';
import {MessagingIdentity} from './MessagingIdentity';
import {Disposer, Service} from '../structure';
import {FcmToken} from '../units';

export default class MobileMessagingIdentityService
  implements MessagingIdentity, Service
{
  @observable private _token?: FcmToken;

  constructor() {
    makeObservable(this);
  }

  get token() {
    return this._token;
  }

  private async _initialize() {
    await messaging().requestPermission();
    const token = await messaging().getToken();
    runInAction(() => {
      this._token = token as FcmToken;
    });
  }

  private _listen() {
    return messaging().onTokenRefresh(
      action((token) => {
        this._token = token as FcmToken;
      }),
    ) as Disposer;
  }

  subscribe() {
    try {
      // noinspection JSIgnoredPromiseFromCall
      this._initialize();
    } catch (ignore) {}
    return this._listen();
  }
}
