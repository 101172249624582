import {observer} from 'mobx-react-lite';
import {useNavigation, useRoute} from '@react-navigation/native';
import React, {useCallback} from 'react';
import getSlotsShown from '../../getters/getSlotsShown';
import {SLOTS_SHOWN_STEP} from '../../constants';
import {merge} from 'lodash';
import WorkerGroupShowMore from './WorkerGroupShowMore';
import min from '../../../utils/min';
import {DashboardScreenProps} from '../../DashboardScreenProps';

export interface SectionFooterProps {
  groupId?: number;
  slotsLeft: number;
}

export default observer((props: SectionFooterProps) => {
  const {slotsLeft, groupId} = props;
  const navigation = useNavigation<DashboardScreenProps['navigation']>();
  const route = useRoute<DashboardScreenProps['route']>();
  const onMorePress = useCallback(() => {
    const {params} = route;
    const slotsShown = getSlotsShown(params, groupId);
    const patch: typeof params =
      groupId === undefined
        ? {emptyGroupOptions: {slotsShown: slotsShown + SLOTS_SHOWN_STEP}}
        : {
            groupOptionsById: {
              [groupId]: {slotsShown: slotsShown + SLOTS_SHOWN_STEP},
            },
          };
    const next: typeof params = {};
    merge(next, params, patch);
    navigation.setParams(next);
  }, [groupId, navigation, route]);
  const onAddPress = useCallback(
    () => navigation.navigate('Plan'),
    [navigation],
  );
  return (
    <WorkerGroupShowMore
      needShownQuantity={min(slotsLeft, SLOTS_SHOWN_STEP)}
      onMorePress={onMorePress}
      onAddPress={onAddPress}
    />
  );
});
