import {StyleProp, View, ViewStyle, Text} from 'react-native';
import React, {PropsWithChildren} from 'react';
import {Hoverable} from 'react-native-web-hooks';
import {PressableOpacity} from '../components';
import {PressableOpacityProps} from '../components/PressableOpacity';
import {useStyles} from '../styling';

type SignUpBodyProps = PropsWithChildren & {
  onPress: () => void;
  Icon: React.ReactNode;
  BtnIcon: React.ReactNode;
  btnText: string;
  error?: unknown | Error | undefined;
  showError?: boolean;
  style?: StyleProp<ViewStyle>;
};
export const SignUpBody = (props: SignUpBodyProps) => {
  const {children, onPress, Icon, BtnIcon, btnText, style, error, showError} =
    props;

  const styles = useStyles(
    (theme) =>
      ({
        panel: {
          marginTop: 20,
          marginHorizontal: 20,
          borderRadius: 15,
          backgroundColor: theme.select(
            theme.palette.background,
            theme.chroma(theme.palette.background).brighten(0.9).hex(),
          ),
        },
        content: {
          paddingHorizontal: 30,
          paddingVertical: 40,
          alignItems: 'center',
        },

        icon: {
          color: 'tomato',
        },
        buttonWrapper: {
          borderTopWidth: 1,
          borderBlockColor: theme.palette.border,
          backgroundColor: theme.palette.back,
          paddingVertical: 20,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          borderBottomRightRadius: 15,
          borderBottomLeftRadius: 15,
        },
        buttonView: {
          minWidth: 300,
        },
        buttonText: {
          borderRadius: 10,
          ...theme.fontByWeight('700'),
          fontSize: 14,
          lineHeight: 18,
          letterSpacing: 0.0371429,
          color: '#ffffff',
          textTransform: 'uppercase',
          textAlign: 'center',
        },
        error: {
          ...theme.fontByWeight('700'),
          color: theme.palette.error,
          textAlign: 'center',
        },
      } as const),
  );
  return (
    <View style={styles.panel}>
      <>
        <View style={styles.content}>
          {Icon && Icon}
          {children}
        </View>
        <View style={styles.buttonWrapper}>
          <Button
            style={styles.buttonView}
            contentStyle={[styles.buttonText, style]}
            onPress={onPress}
            Icon={BtnIcon}>
            {btnText}
          </Button>
        </View>
        {showError && error && (
          <Text style={styles.error}>
            {error instanceof Error ? error.message : JSON.stringify(error)}
          </Text>
        )}
      </>
    </View>
  );
};

type ButtonProps = PropsWithChildren &
  PressableOpacityProps & {
    Icon?: React.ReactNode;

    onPress: () => void;
    style?: StyleProp<ViewStyle>;
    contentStyle?: StyleProp<ViewStyle>;
  };

const Button = ({
  style,
  contentStyle,
  Icon,
  disabled,
  children,
  ...rest
}: ButtonProps) => {
  const styles = useStyles((theme) => ({
    root: {},
    contentDisabled: {
      backgroundColor: theme.palette.disabled,
      opacity: 0.5,
    },
    content: {
      backgroundColor: theme.palette.secondary,
      borderRadius: 10,
      padding: 15,
      columnGap: 8,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    hoveredButtonView: {
      backgroundColor: theme.palette.primary,
      color: '#ffffff',
    },
  }));
  return (
    <PressableOpacity
      accessibilityRole="button"
      style={[styles.root, style]}
      disabled={disabled}
      {...rest}>
      <Hoverable>
        {(isHovered) => (
          <View
            style={[
              styles.content,
              contentStyle,
              isHovered && styles.hoveredButtonView,
              disabled && styles.contentDisabled,
            ]}>
            {Icon && Icon}
            {children}
          </View>
        )}
      </Hoverable>
    </PressableOpacity>
  );
};
