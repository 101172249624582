import {SvgProps} from 'react-native-svg';
import {useRoot} from '../../Root/hooks';
import {sized} from '../../Svg';
import CTFarmProSvg from '../../assets/svg/colored/cryptotabFarm.svg';
import CTFarmProDarkSvg from '../../assets/svg/colored/cryptotabFarmBlack.svg';
import React from 'react';
import {observer} from 'mobx-react-lite';

export default observer((props: SvgProps) => {
  const {
    appearance: {isDark},
  } = useRoot();
  if (isDark) {
    return <CTFarmDarkIcon {...props} />;
  }
  return <CTFarmIcon {...props} />;
});

const CTFarmIcon = sized(CTFarmProSvg, 205, 40);
const CTFarmDarkIcon = sized(CTFarmProDarkSvg, 205, 40);
