import {
  PERMISSION_ERROR,
  PermissionError,
  UNKNOWN_ERROR,
  UnknownError,
} from '../Error';
import {ErrorRepository} from '../ErrorRepository';

export class BaseFileSystemImpl {
  constructor(
    protected readonly _root: {
      readonly errorRepository: ErrorRepository;
    },
  ) {}

  protected _createPermissionError(raw?: unknown) {
    return this._root.errorRepository.create<PermissionError>({
      kind: PERMISSION_ERROR,
      raw,
    });
  }

  protected _createUnknownError(raw?: unknown) {
    return this._root.errorRepository.create<UnknownError>({
      kind: UNKNOWN_ERROR,
      raw,
    });
  }
}
