import {makeRedirectUri} from 'expo-auth-session';
import {getBundleId} from 'react-native-device-info';
import {Url} from '../units';
import {compact} from 'lodash';
import {
  EXTERNAL,
  SpecialLocation,
  INTERNAL_AUTHENTICATION,
  OAUTH_REDIRECT,
  SpecialLocator,
  UNKNOWN,
} from './SpecialLocation';
import {PREFIXES} from '../LinkingOptionsProvider/constant';

export default class SpecialLocationImpl implements SpecialLocation {
  private static readonly _NATIVE_REDIRECT_URI = `${getBundleId()}://oauth/complete`;

  getOauthRedirect() {
    return makeRedirectUri({
      native: SpecialLocationImpl._NATIVE_REDIRECT_URI,
    }) as Url;
  }

  // com.test://action?test/param=1.0.0 -> action?test/param=1.0.0
  // https://test.com/action?test/param=1.0.0 -> action?test/param=1.0.0
  extractPath(locator: Url) {
    const prefix = PREFIXES.find((_) => locator.startsWith(_));
    if (prefix) {
      const path = locator.slice(prefix.length);
      if (path.startsWith('/')) {
        return path.slice(1);
      }
      return path;
    }
    return null;
  }

  parse(locator: Url): SpecialLocator {
    if (!PREFIXES.some((_) => locator.startsWith(_))) {
      return {kind: EXTERNAL};
    }
    if (locator.startsWith(SpecialLocationImpl._NATIVE_REDIRECT_URI)) {
      return {kind: OAUTH_REDIRECT};
    }
    const url = new URL(locator);
    const path = compact(url.pathname.split('/'));
    if (path[0] === 'authentication' && path[1]) {
      return {kind: INTERNAL_AUTHENTICATION, fernetToken: path[1]};
    }
    return {kind: UNKNOWN};
  }
}
