import {flow, observable, reaction, makeObservable} from 'mobx';
import {APP_WINDOW_BACKGROUND, AppWindowState} from '../AppWindow';
import {
  getLocalizationAsync,
  Localization as ExpoLocalization,
} from 'expo-localization';

export default class Localization {
  @observable.ref private _state?: ExpoLocalization;

  constructor(
    private readonly _root: {readonly appWindowState: AppWindowState},
  ) {
    makeObservable(this);
  }

  get state() {
    return this._state;
  }

  private _load = flow(function* (this: Localization) {
    this._state = yield getLocalizationAsync();
  });

  private _getLocalizationOnActive = () =>
    reaction(
      () =>
        this._root.appWindowState.status !== APP_WINDOW_BACKGROUND &&
        this._root.appWindowState.isFocused,
      async (shouldGet) => {
        if (shouldGet) {
          this._load();
        }
      },
      {fireImmediately: true},
    );

  subscribe = this._getLocalizationOnActive;
}
