import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../../Root/hooks';
import {RestoredSubscription} from '../../farmApi';
import {AuthenticationErrorReason, AuthStatus} from '../../Auth';
import {AccessRecoveryScreen} from '../../AccessRecoveryScreen';
import {AuthStackBindingProps} from './AuthStackBindingProps';

export interface ForcedAccessRecoveryBindingProps
  extends AuthStackBindingProps<'AccessRecovery'> {}

export default observer((props: ForcedAccessRecoveryBindingProps) => {
  const {navigation} = props;
  const {auth} = useRoot();
  const onRestore = useCallback(
    async (subscription: RestoredSubscription) => {
      if (subscription.type === 'Temporary') {
        await auth.restoreFarm(subscription.token, subscription.accountId);
      } else {
        navigation.navigate('Auth', {farmId: subscription.accountId});
      }
    },
    [auth, navigation],
  );
  const onRegister = useCallback(() => auth.registerNewAccount(), [auth]);
  const onRefresh = useCallback(() => auth.retrySetup(), [auth]);
  const subscriptionMap =
    auth.state &&
    auth.state.kind === 'AuthenticationFailed' &&
    auth.state.reason === AuthenticationErrorReason.AccountSwitchRequired
      ? auth.state.restoredSubscriptionMap
      : undefined;
  const getSubscriptions = useCallback(
    () => subscriptionMap && [...subscriptionMap.values()],
    [subscriptionMap],
  );
  const getIsRefreshing = useCallback(
    () => auth.status !== AuthStatus.Idle,
    [auth],
  );
  return (
    <AccessRecoveryScreen
      getSubscriptions={getSubscriptions}
      getIsRefreshing={getIsRefreshing}
      onRefresh={onRefresh}
      onRestore={onRestore}
      onRegister={onRegister}
      registerShown
    />
  );
});
