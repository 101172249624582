import {Opaque} from 'type-fest';

export interface VerifyReceiptParams {
  receipt: AppStoreReceipt;
}

export const APP_STORE_RECEIPT = Symbol(
  'Base64-encoded App Store receipt data',
);
export type AppStoreReceipt = Opaque<string, typeof APP_STORE_RECEIPT>;

export interface VerifyReceiptResult {
  transactions: AppleStoreTransaction[];
}

export interface AppleStoreTransaction {
  product_id: string;
}
