import * as StoreReview from 'expo-store-review';
import {when} from 'mobx';
import {useRoot} from '../Root/hooks';
import {useCallback} from 'react';
import useOpenBrowserReview from './useOpenBrowserReview';

export default () => {
  const {rateApp, appWindowState} = useRoot();
  const openBrowser = useOpenBrowserReview();
  return useCallback(
    async (onLeaveToReview?: () => void) => {
      await rateApp.setRatingReviewed();
      try {
        if (await StoreReview.isAvailableAsync()) {
          await StoreReview.requestReview();
          onLeaveToReview?.();
          await when(() => appWindowState.isFocused);
        } else {
          await openBrowser();
        }
        rateApp.closeModal();
      } catch (ignore) {
        await openBrowser();
      }
    },
    [appWindowState.isFocused, openBrowser, rateApp],
  );
};
