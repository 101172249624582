import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {SignUpScreen} from '../../SignUpScreen';
import {AuthStackBindingProps} from './AuthStackBindingProps';

export interface ForcedAuthBindingProps extends AuthStackBindingProps<'Auth'> {}

export default observer((props: ForcedAuthBindingProps) => {
  const {navigation, route} = props;
  const navigationRef = useRef(navigation);
  navigationRef.current = navigation;
  const routeRef = useRef(route);
  routeRef.current = route;
  const goToOAuthSplash = useCallback(
    () => navigationRef.current.navigate('OAuthSplash'),
    [],
  );
  const goToThisScreen = useCallback(
    () => navigationRef.current.navigate(routeRef.current),
    [],
  );
  const getFarmId = useCallback(() => routeRef.current.params?.farmId, []);
  return (
    <SignUpScreen
      goToOAuthSplash={goToOAuthSplash}
      onSuccess={noop}
      goToThisScreen={goToThisScreen}
      getFarmId={getFarmId}
    />
  );
});

const noop = () => {};
