import {action, observable, makeObservable} from 'mobx';

export default class SliderState {
  @observable private _value!: number;
  @observable minimum!: number;
  @observable maximum!: number;
  @observable step?: number;

  get value() {
    return this._value;
  }

  getValue = action(() => this._value);

  @observable private _isSliding = false;

  get isSliding() {
    return this._isSliding;
  }

  constructor(
    private readonly _defaultValue: number,
    minimum: number,
    maximum: number,
    step?: number,
  ) {
    makeObservable(this);
    this.minimum = minimum;
    this.maximum = maximum;
    this.step = step;
    this.reset();
  }

  @action.bound private _onSlidingStart() {
    this._isSliding = true;
  }

  @action.bound private _onSlidingComplete() {
    this._isSliding = false;
  }

  @action.bound private _onValueChange(value: number) {
    this._value = value;
  }

  get handlers() {
    return {
      value: this._value,
      onValueChange: this._onValueChange,
      onSlidingStart: this._onSlidingStart,
      onSlidingComplete: this._onSlidingComplete,
      minimumValue: this.minimum,
      maximumValue: this.maximum,
      step: this.step,
    };
  }

  @action.bound setValue(value: number) {
    this._value = value;
  }

  @action.bound reset() {
    this._value = this._defaultValue;
  }
}
