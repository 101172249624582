import {Uri, Url} from '../../units';
import {Second} from '../../Time';
import {Opaque} from 'type-fest';

export enum AdSpot {
  DashboardMobileTop = 'dashboard_mobile_top',
  DashboardDesktopTop = 'dashboard_desktop_top',
  SplashMobile = 'splash_mobile',
  SplashDesktop = 'splash_desktop',
  TutorialDesktop = 'tutorial_desktop',
  TutorialMobile = 'tutorial_mobile',
  WithdrawMobile = 'withdrawal_mobile',
  WithdrawDesktop = 'withdrawal_desktop',
  PaymentDesktop = 'payment_desktop',
}

export enum Size {
  Xs = 'xs',
  M = 'm',
  L = 'l',
  Xl = 'xl',
}

export enum AdType {
  Type1 = 'type_1',
  Type2 = 'type_2',
  Type3 = 'type_3',
}

export type AdItem = {
  is_i18n: boolean;
  id: AdId;
  project_id: string;
  spot: AdSpot;
  spots?: AdSpot[] | null; // TODO: Temporary doesn't exist
  type: AdType;
  size: Size;
  title: Record<string, string>;
  body: Record<string, string>;
  icon: Partial<Record<Size, string>>;
  image: Partial<Record<Size, Uri>>;
  html: null;
  link: Url | null;
  options: {
    can_close: boolean;
    close_time: Second | null;
    force: boolean;
    can_exclude: boolean;
    ttl: number | null;
  };
  actions: {
    title: Record<string, string>;
    action: string;
    link: Url | null;
    icon: Uri;
  }[];
};

export type GetAdsResult = {
  items: AdItem[];
};

export type GetAdsParams = {
  exclude: AdId[];
};

export const AD_ID = Symbol();
export type AdId = Opaque<string, typeof AD_ID>;
