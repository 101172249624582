import {action, observable, makeObservable} from 'mobx';
import {NotificationPermissions} from './NotificationPermissions';
import {Disposer, Service} from '../structure';
import {isNotificationApiSupported} from '../LocalNotifications/isNotificationApiSupported';

declare var Notification: {
  readonly permission: NotificationPermission;
  requestPermission(
    deprecatedCallback?: NotificationPermissionCallback,
  ): Promise<NotificationPermission>;
};

export default class WebNotificationPermissionsService
  implements NotificationPermissions, Service
{
  private readonly _isNotificationApiSupported = isNotificationApiSupported();

  @observable _granted =
    this._isNotificationApiSupported && Notification.permission === 'granted';

  constructor() {
    makeObservable(this);
  }

  get granted() {
    return this._granted;
  }

  private _onResolve = action((permission: NotificationPermission) => {
    this._granted = permission === 'granted';
  });

  ask = async () => {
    if (this._isNotificationApiSupported) {
      Notification.requestPermission(this._onResolve)?.then(this._onResolve);
    }
  };

  subscribe() {
    if (this._granted) {
      return;
    }
    // noinspection JSIgnoredPromiseFromCall
    this.ask();
    const listener = () => {
      // noinspection JSIgnoredPromiseFromCall
      this.ask();
      if (this._granted) {
        document.removeEventListener('click', listener);
      }
    };
    document.addEventListener('click', listener);
    return (() => {
      document.removeEventListener('click', listener);
    }) as Disposer;
  }
}
