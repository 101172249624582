import React from 'react';
import {observer} from 'mobx-react-lite';
import {Text} from 'react-native';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {createStylesHook} from '../../../styling';
import {View} from 'react-native';
import SlotSetupButton from '../SlotSetupButton';
import {DESKTOP_BREAKPOINT} from '../../../units/constants';

export interface DetailSlotHeaderProps {
  worker: ReadonlyDeep<WorkerEntity>;
  index: number;
}

export default observer(({worker, index}: DetailSlotHeaderProps) => {
  const styles = useStyles();
  return (
    <>
      {index !== undefined && <Text style={styles.position}>{index + 1}.</Text>}
      <View style={styles.setupButton}>
        <SlotSetupButton worker={worker} />
      </View>
    </>
  );
});

const useStyles = createStylesHook((theme) => ({
  setupButton: {
    position: 'absolute',
    right: 9,
    top: 20,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        right: 20,
      },
    }),
  },
  position: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textSecondary,
    fontSize: 12,
    lineHeight: 14,
    position: 'absolute',
    left: 9,
    top: 23,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        left: 20,
        fontSize: 14,
        lineHeight: 16,
        top: 22,
      },
    }),
  },
}));
