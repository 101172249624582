import React from 'react';
import {observer} from 'mobx-react-lite';
import {Button, ButtonProps, PressableOpacity} from '../../components';
import {View} from 'react-native';
import {createStylesHook} from '../../styling';

export type ModalButton = ButtonProps & {
  text: string;
  onPress: () => void;
  disabled?: boolean;
};

export type ButtonDirection = 'row' | 'column';

export interface ModalButtonListProps {
  buttons: ModalButton[];
  buttonsDirection?: ButtonDirection;
}

export default observer(
  ({buttons = [], buttonsDirection = 'row'}: ModalButtonListProps) => {
    const styles = useStyles();
    if (!buttons.length) {
      return null;
    }
    return (
      <View
        style={[
          styles.buttons,
          buttonsDirection === 'column' && styles.columnButtons,
        ]}>
        {buttons.map((button, i) => {
          const {disabled, style, onPress, text, ...rest} = button;
          return (
            <PressableOpacity
              key={i}
              disabled={disabled}
              style={[
                styles.touchableButton,
                buttonsDirection === 'row' && styles.fluidButton,
                style,
              ]}
              onPress={onPress}>
              <Button
                style={styles.button}
                textStyle={styles.buttonText}
                {...rest}>
                {text}
              </Button>
            </PressableOpacity>
          );
        })}
      </View>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  buttons: {
    flexDirection: 'row',
    marginHorizontal: -5,
    paddingTop: 10,
  },
  columnButtons: {
    flexDirection: 'column',
  },
  touchableButton: {
    margin: 5,
  },
  button: {
    ...theme.mediaQuery({
      1000: {
        paddingVertical: 17,
      },
    }),
  },
  fluidButton: {
    flexGrow: 1,
    flex: 1,
  },
  buttonText: {
    ...theme.mediaQuery({
      1000: {
        fontSize: 14,
        lineHeight: 16,
      },
    }),
  },
}));
