import React from 'react';
import {Platform, View, ViewProps, StyleSheet} from 'react-native';

import RNPickerSelect, {Item, PickerStyle} from 'react-native-picker-select';

import {useStyles} from '../styling';
import {sized} from '../Svg';
import CaretDownSvg from '../assets/svg/colorless/caretDown.svg';

interface DropdownProps extends ViewProps {
  items: Item[];
  value: number;
  onChange: (value: number) => void;
}

export const TimezonePicker = (props: DropdownProps) => {
  const {onChange, items, value, ...rest} = props;

  const styles: PickerStyle = useStyles((theme) => ({
    viewContainer: {
      width: 130,
    },
    inputWeb: {
      backgroundColor: theme.palette.background,
      fontSize: 14,
      height: 35,
      paddingVertical: 5,
      paddingHorizontal: 10,
      borderWidth: 1,
      borderColor: theme.palette.border,
      borderRadius: 2,
      color: theme.palette.textPrimary,
    },
    inputIOS: {
      backgroundColor: theme.palette.background,
      fontSize: 14,
      height: 35,
      paddingVertical: 5,
      paddingHorizontal: 10,
      borderWidth: 1,
      borderColor: theme.palette.border,
      borderRadius: 2,
      color: theme.palette.textPrimary,
      paddingRight: 30,
    },
    inputAndroid: {
      backgroundColor: theme.palette.background,
      fontSize: 14,
      height: 35,
      paddingVertical: 5,
      paddingHorizontal: 10,
      borderWidth: 1,
      borderColor: theme.palette.border,
      borderRadius: 2,
      paddingRight: 30,
      color: theme.palette.textPrimary,
    },
    iconContainer: {
      top: 14,
      right: 10,
    },
  }));

  const renderArrow =
    Platform.OS === 'web' ? null : (
      <CaretDownIcon
        style={styles2.caret}
        width={10}
        height={5}
        color="#D0D0D0"
      />
    );

  return (
    <View {...rest}>
      <RNPickerSelect
        style={styles}
        onValueChange={onChange}
        value={value}
        useNativeAndroidPickerStyle={false}
        // @ts-ignore
        Icon={() => renderArrow}
        fixAndroidTouchableBug
        items={items}
      />
    </View>
  );
};

const styles2 = StyleSheet.create({
  caret: {
    pointerEvents: 'none',
  },
});

const CaretDownIcon = sized(CaretDownSvg, 8);
