import {useEffect} from 'react';
import {useNavigation} from '@react-navigation/native';

export default (onBeforeRemove: () => void, numberOfChangedPoints: number) => {
  const navigation = useNavigation();
  useEffect(
    () =>
      navigation.addListener('beforeRemove', (e) => {
        if (numberOfChangedPoints > 0 && e.data.action.type !== 'RESET') {
          e.preventDefault();
          onBeforeRemove();
        }
      }),
    [navigation, numberOfChangedPoints, onBeforeRemove],
  );
};
