import React from 'react';
import {observer} from 'mobx-react-lite';
import MobileFarmInfoSection, {
  MobileFarmInfoSectionProps,
} from './mobile/MobileFarmInfoSection';
import {useTheme} from '../../../styling';
import DesktopFarmInfoSection from './desktop/DesktopFarmInfoSection';
import {useHeaderFarmInfoSectionHeight} from '../../hooks/useGetItemLayout';
import {View} from 'react-native';
import {DESKTOP_BREAKPOINT} from '../../../units/constants';

export type HeaderInfoSectionProps = MobileFarmInfoSectionProps & {};

export default observer((props: HeaderInfoSectionProps) => {
  const theme = useTheme();
  const content =
    theme.window.width >= DESKTOP_BREAKPOINT ? (
      <DesktopFarmInfoSection {...props} />
    ) : (
      <MobileFarmInfoSection {...props} />
    );
  const height = useHeaderFarmInfoSectionHeight();
  return <View style={{height}}>{content}</View>;
});
