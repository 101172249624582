import React, {MouseEvent} from 'react';
import {observer} from 'mobx-react-lite';
import {
  PanGestureHandler,
  TapGestureHandler,
  TapGestureHandlerStateChangeEvent,
  PanGestureHandlerGestureEvent,
  State,
} from 'react-native-gesture-handler';
import {Platform, StyleProp, View, ViewStyle} from 'react-native';

interface ChartPanGestureHandlerProps {
  onMouseLeave: () => void;
  onGestureEvent: (offsetX: number, isActive: boolean) => void;
  onTapEvent?: (event: TapGestureHandlerStateChangeEvent) => void;
  style: StyleProp<ViewStyle>;
}

export default observer((props: ChartPanGestureHandlerProps) => {
  const _onGestureEvent = (event: PanGestureHandlerGestureEvent) => {
    props.onGestureEvent(
      event.nativeEvent.x,
      event.nativeEvent.state === State.ACTIVE,
    );
  };
  const _onGestureEventWeb = (e: MouseEvent) =>
    props.onGestureEvent(e.nativeEvent.offsetX, true);
  const _onTouchMoveWeb = (e: TouchEvent) => {
    const node = e.touches[0].target as HTMLElement;
    const pageX = e.touches[0].pageX;
    const nodeLeft = node.getBoundingClientRect().left;
    props.onGestureEvent(pageX - nodeLeft, true);
  };
  if (Platform.OS === 'web') {
    return (
      <div
        // @ts-ignore
        onTouchMove={_onTouchMoveWeb}
        onMouseLeave={props.onMouseLeave}
        onMouseMove={_onGestureEventWeb}>
        <View style={props.style} />
      </div>
    );
  }
  return (
    <PanGestureHandler onGestureEvent={_onGestureEvent}>
      <TapGestureHandler onHandlerStateChange={props.onTapEvent}>
        <View style={props.style} />
      </TapGestureHandler>
    </PanGestureHandler>
  );
});
