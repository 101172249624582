import React, {ReactNode, useCallback, useState} from 'react';
import ActionModal from '../../../modals/ActionModal';
import {observer} from 'mobx-react-lite';
import {Button, ButtonVariant, CompositeFanIcon} from '../../../components';
import {sized} from '../../../Svg';
import PercentSvg from '../../../assets/svg/colorless/percent.svg';
import {useStyles, useTheme, variance} from '../../../styling';
import {Pressable, Text, TextInput, View} from 'react-native';
import {useRoot, useStrings} from '../../../Root/hooks';
import {getReadablePromoError} from '../../getters/getReadablePromoError';
import {CommonError, DiscountCode} from '../../../ApiStore';
import {DefaultError} from '../../../JsonRpc';
import {AccountType} from '../../../Auth';
import {DiscountSource} from '../../../PurchasePromoService';
import LoaderIndicator from '../../../components/LoaderIndicator';
import {expr} from 'mobx-utils';
import Markdown from '../../../components/Markdown';

export type AddPromoModalProps = {
  onDismiss: () => void;
  safelyGoToAffiliate: () => void;
  getAffiliateIsPending: () => boolean;
};

export default observer(function AddPromoModal({
  onDismiss,
  safelyGoToAffiliate,
  getAffiliateIsPending,
}: AddPromoModalProps) {
  const {auth, purchaseDiscount} = useRoot();
  const [promoError, setPromoError] = useState<CommonError | DefaultError>();
  const [promo, setPromo] = useState('');
  const strings = useStrings();
  const handleApply = () => {
    onApplyPromo();
  };
  const handleAffiliatePress = () => {
    if (auth.accountType !== AccountType.Permanent) {
      onDismiss();
    }
    safelyGoToAffiliate();
  };
  const onApplyPromo = useCallback(async () => {
    const verify_ = await purchaseDiscount.verifyPromoCode(
      promo as DiscountCode,
    );
    if (verify_.success) {
      setPromoError(undefined);
      purchaseDiscount.applyDiscount({
        ...verify_.right,
        code: promo as DiscountCode,
        kind: DiscountSource.User,
      });
      onDismiss();
    } else {
      setPromoError(verify_.left);
    }
  }, [onDismiss, promo, purchaseDiscount]);
  const rules = {
    em: (key: number, children: ReactNode) => {
      return (
        <>
          <Text
            style={isPending && styles.disabledLink}
            key={key}
            onPress={isPending ? undefined : handleAffiliatePress}>
            {children}
          </Text>
          {isPending && (
            <View style={styles.loaderView}>
              <LoaderIndicator />
            </View>
          )}
        </>
      );
    },
  };
  const handleChangeText = (v: string) => {
    if (v.length !== promo.length && promoError !== undefined) {
      setPromoError(undefined);
    }
    setPromo(v);
  };
  const styles = useStyles((theme) => ({
    paragraph: {
      ...theme.fontByWeight('400'),
      color: theme.palette.textPrimary,
      fontSize: 16,
      lineHeight: 24,
      textAlign: 'center',
      marginTop: 0,
      marginBottom: 10,
    },
    loaderView: {
      marginLeft: 5,
    },
    disabledLink: {
      opacity: 0.5,
    },
    link: {
      color: theme.palette.info,
      fontStyle: 'normal',
      fontSize: 16,
      lineHeight: 24,
    },
    touchableApply: {
      marginTop: 20,
      width: '100%',
    },
  }));
  const theme = useTheme();
  const disabledButton = promo.length === 0;
  const error = promoError && getReadablePromoError(promoError.code, strings);
  const hasError = error !== undefined;
  const isPending = expr(getAffiliateIsPending);
  return (
    <ActionModal
      onDismiss={onDismiss}
      Icon={() => (
        <CompositeFanIcon
          circleColor={theme.palette.success}
          Icon={PercentIcon}
        />
      )}
      title={strings['purchase.modal.promo.title']}>
      <Markdown
        rules={rules}
        styles={{
          em: styles.link,
          paragraph: styles.paragraph,
        }}>
        {strings['purchase.modal.promo.desc']}
      </Markdown>
      <PromoErrorContainer>
        {hasError && <PromoError>{error}</PromoError>}
      </PromoErrorContainer>
      <InputContainer>
        <Input
          value={promo}
          onChangeText={handleChangeText}
          placeholder={strings['purchase.modal.promo.input']}
          placeholderTextColor={theme.palette.textSecondary}
          hasError={hasError}
        />
      </InputContainer>
      <Pressable
        disabled={disabledButton}
        style={styles.touchableApply}
        onPress={handleApply}>
        <Button
          variant={
            disabledButton ? ButtonVariant.Disabled : ButtonVariant.Highlighted
          }>
          {strings['purchase.modal.promo.apply']}
        </Button>
      </Pressable>
    </ActionModal>
  );
});

const PercentIcon = sized(PercentSvg, 25);

const InputContainer = variance(View)(() => ({
  root: {
    marginTop: 10,
    width: '100%',
  },
}));

const Input = variance(TextInput)((theme) => ({
  root: {
    borderWidth: 1,
    borderColor: theme.palette.border,
    width: '100%',
    height: 44,
    borderRadius: 3,
    paddingHorizontal: 15,
    paddingVertical: 5,
    backgroundColor: theme.palette.back,
    color: theme.palette.textPrimary,
  },
  hasError: {
    borderColor: theme.palette.error,
    backgroundColor: theme.chroma(theme.palette.error).alpha(0.1).hex(),
  },
}));

const PromoErrorContainer = variance(View)(() => ({
  root: {
    alignSelf: 'flex-start',
    alignItems: 'center',
    minHeight: 20,
  },
}));

const PromoError = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.error,
    fontSize: 14,
    lineHeight: 17,
  },
}));
