import {StructuredListener, StructuredTunnel} from './StructuredTunnel';
import {RawTunnel} from './RawTunnel';
import {TunnelKind} from './TunnelKind';

export default class JsonTunnel implements StructuredTunnel {
  constructor(private readonly _tunnel: RawTunnel) {}

  readonly tunnelKind = TunnelKind.Structured;

  async send(message: unknown) {
    return this._tunnel.send(JSON.stringify(message));
  }

  listen(listener: StructuredListener) {
    return this._tunnel.listen((raw: string) => {
      let structured;
      try {
        structured = JSON.parse(raw);
      } catch (ignore) {}
      listener(structured);
    });
  }
}
