import {observer} from 'mobx-react-lite';
import {Button, ButtonVariant, ScreenCard} from '../components';
import {useStrings} from '../Root/hooks';
import React, {useMemo} from 'react';
import {PageScrollView} from '../containers';
import {createStylesHook} from '../styling';
import {Platform, TouchableWithoutFeedback} from 'react-native';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';

export interface SubscriptionRequestScreenProps {
  goToPlans: () => void;
}

export default observer((props: SubscriptionRequestScreenProps) => {
  const {goToPlans} = props;
  const styles = useStyles();
  const strings = useStrings();
  const footer = useMemo(
    () => (
      <TouchableWithoutFeedback onPress={goToPlans}>
        <Button
          textStyle={styles.buttonText}
          variant={ButtonVariant.Highlighted}>
          {strings['withdraw.subscriptionRequired.buy']}
        </Button>
      </TouchableWithoutFeedback>
    ),
    [goToPlans, strings, styles],
  );
  return (
    <>
      <DesktopWebAppHeader />
      <PageScrollView
        style={styles.root}
        contentContainerStyle={styles.container}>
        <ScreenCard
          title={strings['withdraw.subscriptionRequired.title']}
          description={strings['withdraw.subscriptionRequired.description']}
          footer={footer}
        />
      </PageScrollView>
    </>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    flex: 1,
  },
  container: {
    padding: 20,
    justifyContent: 'center',
    ...Platform.select({
      web: {
        ...theme.mediaQuery({
          1200: {
            paddingVertical: 150,
          },
        }),
      },
    }),
  },
  buttonText: {
    fontSize: 13,
    lineHeight: 15,
  },
}));
