import {ReadonlyDeep} from 'type-fest';
import {
  WorkerEntity,
  WorkerType,
} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';

export default (worker: ReadonlyDeep<WorkerEntity>) => {
  if (worker.worker_type === WorkerType.Pool) {
    const config = worker.pool_miner_config;
    if (!config) {
      return '';
    }
    return `${config.name} @${config.frequency}Mhz ${config.cores_count} cores`;
  }

  if (worker.worker_type === WorkerType.Regular) {
    if (!worker.host_info) {
      return '';
    }
    const {arch, brand} = worker.host_info.cpu;
    const {memory} = worker.host_info;

    const candidateGb = memory.total / 1024;
    const memoryStr =
      candidateGb < 1 ? `${memory.total} mb` : `${candidateGb.toFixed()} gb`;

    return `${brand}, ${arch}, ${memoryStr}`;
  }

  return '';
};
