import {TrialPeriodUnit} from '../InAppOffersService';

export default (_: string): {value: number; unit: TrialPeriodUnit} | null => {
  const regex = /^P(\d+)([YMWD])$/;
  const match = _.match(regex);

  if (!match) {
    return null;
  }

  const value = parseInt(match[1], 10);
  const unit = match[2] as TrialPeriodUnit;
  return {value, unit};
};
