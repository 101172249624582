import {Share} from './Share';
import {Share as RNShare} from 'react-native';

export default class MobileShareImpl implements Share {
  readonly isSupported = true;

  async shareMessage(message: string) {
    await RNShare.share({message});
  }
}
