import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Platform, Text, View} from 'react-native';
import TableSelectButton from './TableSelectButton';
import TotalPrice from './TotalPrice';
import {useRoot, useTemplates} from '../../Root/hooks';
import {variance} from '../../styling';
import {PaymentMethod, SlotSubscriptionOffer} from '../../InAppOffersService';
import dayjs from 'dayjs';
import getHumanizeDuration from '../getters/getHumanizeDuration';
import {Millisecond} from '../../Time';
import getDaysByUnitAndValue from '../../InAppPurchaseManager/getDaysByUnitAndValue';

export type BaseSelectButtonSectionProps = {
  visibleFreeMinerSection: boolean;
  selectedOffer: SlotSubscriptionOffer | undefined;
  onPress: () => void;
  disabled: boolean;
  selectedPaymentMethod: PaymentMethod;
};

export default observer((props: BaseSelectButtonSectionProps) => {
  const {selectedOffer, onPress, selectedPaymentMethod, disabled} = props;
  const {translation} = useRoot();
  const template = useTemplates();
  const period = selectedOffer?.interval
    ? getHumanizeDuration(
        dayjs
          .duration(selectedOffer.interval, 'month')
          .asMilliseconds() as Millisecond,
        translation.localeTag,
      )
    : undefined;
  const trial = selectedOffer?.trial;
  const description = useMemo(() => {
    if (!trial) {
      return template['slots.billed']({duration: period});
    }
    const days = getDaysByUnitAndValue(trial.value, trial.unit);
    const dayjsDuration = dayjs
      .duration(days, 'day')
      .asMilliseconds() as Millisecond;
    const humanized = getHumanizeDuration(
      dayjsDuration,
      translation.localeTag,
      ['d'],
    );
    return template['slots.billedTrial']({X: humanized, duration: period});
  }, [period, template, translation, trial]);
  return (
    <View>
      {selectedOffer && (
        <GuardTotalPrice
          selectedPaymentMethod={selectedPaymentMethod}
          selectedOffer={selectedOffer}
          period={period}
        />
      )}
      <BtnContainer>
        <TableSelectButton onPress={onPress} disabled={disabled} />
        {Platform.OS !== 'web' && (
          <CalculationText>{description}</CalculationText>
        )}
      </BtnContainer>
    </View>
  );
});

export type GuardTotalPriceProps = {
  selectedOffer: SlotSubscriptionOffer;
  period?: string;
  selectedPaymentMethod: PaymentMethod;
};

const GuardTotalPrice = ({
  selectedOffer,
  period,
  selectedPaymentMethod,
}: GuardTotalPriceProps) => {
  const payment = selectedOffer.payments.get(selectedPaymentMethod);
  if (!payment) {
    return null;
  }
  return (
    <TotalPrice
      count={selectedOffer.maxSlots}
      oldPrice={payment.uiOldPrice}
      save={payment.uiDiscountsDifferencePrice}
      pricePerCount={Number(
        Number(payment.uiPrice) / selectedOffer.maxSlots,
      ).toFixed(2)}
      discounts={payment.discounts}
      totalPrice={payment.uiPrice}
      currency={payment.currency}
      period={period}
    />
  );
};

const BtnContainer = variance(View)((theme) => ({
  root: {
    borderTopWidth: 1,
    borderTopColor: theme.palette.border,
    padding: 15,

    ...theme.mediaQuery({
      1000: {
        backgroundColor: theme.palette.back,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
      },
    }),
  },
}));

const CalculationText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 12,
    lineHeight: 14,
    letterSpacing: 0,
    textAlign: 'center',
    marginTop: 15,
    color: theme.palette.textSecondary,
  },
}));
