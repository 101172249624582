import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Text, View} from 'react-native';
import {TimezonePicker} from './TimezonePicker';
import {useStrings} from '../Root/hooks';
import {createStylesHook, useTheme} from '../styling';
import ScheduleState from './ScheduleState';
import {timezones} from './constant';

interface TimezoneProps {
  state: ScheduleState;
}

export default observer(({state}: TimezoneProps) => {
  const styles = useStyles();
  const strings = useStrings();
  const theme = useTheme();
  const items = useMemo(
    () =>
      timezones.map((timezone) => ({
        value: timezone.offset,
        label: timezone.name,
        color: theme.palette.textPrimary,
      })),
    [theme.palette.textPrimary],
  );
  return (
    <View style={styles.root}>
      <Text style={styles.timezoneText}>{strings['scheduler.timezone']}:</Text>
      <TimezonePicker
        items={items}
        value={state.userTimezoneMinutes / 60}
        onChange={(value) => state.changeTimezoneOffset(value * 60)}
      />
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    zIndex: -1,
    paddingVertical: 15,
    backgroundColor: theme.palette.background,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.palette.border,
  },
  timezoneText: {
    color: theme.palette.textPrimary,
    ...theme.fontByWeight('700'),
    letterSpacing: 0.04,
    fontSize: 14,
    marginRight: 10,
  },
}));
