import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import 'dayjs/locale/ru';

import minMax from 'dayjs/plugin/minMax';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import relativeTime from 'dayjs/plugin/relativeTime';

export const extendDayJs = () => {
  dayjs.extend(minMax);
  dayjs.extend(isoWeek);
  dayjs.extend(utc);
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  dayjs.extend(quarterOfYear);
};
