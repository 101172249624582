import React, {useCallback, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../../styling';
import {View, Text, TextInput, StyleSheet} from 'react-native';
import {Button, ButtonVariant, PressableOpacity} from '../../components';
import CopySvg from '../../assets/svg/colorless/copy.svg';
import ShareSvg from '../../assets/svg/colorless/share.svg';
import TagLinkSvg from '../../assets/svg/colorless/tagLink.svg';
import ChevronRightSvg from '../../assets/svg/colorless/chevronRight.svg';
import {colored, sized} from '../../Svg';
import {showMessage} from 'react-native-flash-message';
import {useRoot, useStrings} from '../../Root/hooks';
import FilledQuestionMarkSvg from '../../assets/svg/colorless/filledQuestionMark.svg';
import StatisticsGraphSvg from '../../assets/svg/colorless/statisticsGraph.svg';
import CheckSvg from '../../assets/svg/colorless/check.svg';
import {expr} from 'mobx-utils';
import LoaderIndicator from '../../components/LoaderIndicator';
import useTutorialMeasure from '../../InteractiveTutorial/useTutorialMeasure';
import {StackElementKey} from '../../InteractiveTutorial';
import {DESKTOP_BREAKPOINT} from '../../units/constants';
import {useHover} from 'react-native-web-hooks';
export const COPY_PROMO_HEIGHT = 115;
export const COPY_PROMO_DESKTOP_HEIGHT = 80;
export const COPY_PROMO_DESKTOP_BREAKPOINT = 870;

export type CopyPromoSectionProps = {
  code: string;
  safelyGoToPromoAffiliate: () => void;
  safelyGoToAboutAffiliate: () => void;
  goToCopyRegistrationLink: (code: string) => void;
  getAffiliateIsPending: () => boolean;
};

type Target = 'title' | 'mobile-button' | 'large-button';

const TUTORIAL_KEY: StackElementKey = 'PROMO';

export const CopyPromoSection = observer(
  ({
    code,
    safelyGoToPromoAffiliate,
    safelyGoToAboutAffiliate,
    goToCopyRegistrationLink,
    getAffiliateIsPending,
  }: CopyPromoSectionProps) => {
    const theme = useTheme();
    const {share, clipboard} = useRoot();
    const strings = useStrings();
    const shareCode = useCallback(() => {
      share.shareMessage(code);
    }, [code, share]);
    const copyCode = useCallback(() => {
      clipboard.setString(code);
      showMessage({
        message: strings['action.discountCodeCopied'],
        duration: 3000,
        type: 'success',
        icon: 'success',
        renderFlashMessageIcon: () => (
          <FlashIcon>
            <CheckIcon />
          </FlashIcon>
        ),
      });
    }, [clipboard, code, strings]);
    const copyRegistrationLink = useCallback(() => {
      goToCopyRegistrationLink(code);
    }, [code, goToCopyRegistrationLink]);

    const targetRef = useRef<Target>();

    const handleGoToPromoAffiliate = useCallback(
      (target: Target) => {
        targetRef.current = target;
        safelyGoToPromoAffiliate();
      },
      [safelyGoToPromoAffiliate],
    );

    const handleGoToAboutAffiliate = useCallback(
      (target: Target) => {
        targetRef.current = target;
        safelyGoToAboutAffiliate();
      },
      [safelyGoToAboutAffiliate],
    );

    const isPending = expr(getAffiliateIsPending);
    const isTitlePending = isPending && targetRef.current === 'title';
    const isMobileStatisticsButtonPending =
      isPending && targetRef.current === 'mobile-button';
    const isLargeStatisticsButtonPending =
      isPending && targetRef.current === 'large-button';
    const [rootRef, onLayout, getForceKey] = useTutorialMeasure(TUTORIAL_KEY);

    const ref = useRef(null);
    const isHovered = useHover(ref);

    return (
      <Container>
        <Root ref={rootRef} onLayout={onLayout} key={getForceKey()}>
          <Header>
            <Title disabled={isTitlePending}>
              {strings['dashboard.promo.title']}
            </Title>
            <PressableOpacity
              ref={ref}
              disabled={isTitlePending}
              onPress={() => handleGoToAboutAffiliate('title')}
              style={styles.questionMark}>
              <QuestionMarkIcon
                color={
                  isHovered
                    ? theme.palette.primary
                    : theme
                        .chroma(theme.palette.foreground)
                        .alpha(isTitlePending ? 0.2 : 0.3)
                        .hex()
                }
              />
              {isHovered && (
                <Tooltip>
                  <TooltipText>
                    {strings['purchase.modal.promo.desc'].split('. ')[1]}
                  </TooltipText>
                </Tooltip>
              )}
            </PressableOpacity>

            {isTitlePending && <LoaderIndicator />}
            {theme.window.width < COPY_PROMO_DESKTOP_BREAKPOINT && (
              <StatisticsButtonView>
                {isMobileStatisticsButtonPending && <LoaderIndicator />}
                <PressableOpacity
                  disabled={isMobileStatisticsButtonPending}
                  onPress={() => handleGoToPromoAffiliate('mobile-button')}>
                  <StatisticsButtonContent
                    disabled={isMobileStatisticsButtonPending}>
                    <StatisticsGraphIcon
                      color={theme.palette.secondary}
                      style={styles.statistics}
                    />
                    <StatisticsButtonText>
                      {strings['dashboard.panel.getStatistics']}
                    </StatisticsButtonText>
                  </StatisticsButtonContent>
                </PressableOpacity>
              </StatisticsButtonView>
            )}
          </Header>
          <Body>
            <Input editable={false} value={code} />
            <PressableOpacity onPress={copyCode}>
              <Button variant={ButtonVariant.Highlighted} Icon={CopyIcon} />
            </PressableOpacity>
            <PressableOpacity onPress={copyRegistrationLink}>
              <Button variant={ButtonVariant.Highlighted} Icon={TagLinkIcon} />
            </PressableOpacity>

            {share.isSupported && (
              <PressableOpacity onPress={shareCode}>
                <Button variant={ButtonVariant.Highlighted} Icon={ShareIcon} />
              </PressableOpacity>
            )}

            {theme.window.width >= COPY_PROMO_DESKTOP_BREAKPOINT && (
              <PressableOpacity
                disabled={isLargeStatisticsButtonPending}
                onPress={() => handleGoToPromoAffiliate('large-button')}>
                <Button
                  Icon={
                    isLargeStatisticsButtonPending
                      ? LoaderIndicator
                      : ChevronRightIcon
                  }>
                  {strings['dashboard.panel.getStatistics']}
                </Button>
              </PressableOpacity>
            )}
          </Body>
        </Root>
      </Container>
    );
  },
);

const styles = StyleSheet.create({
  questionMark: {
    position: 'relative',
    padding: 5,
  },
  statistics: {
    marginRight: 5,
  },
});

const QuestionMarkIcon = sized(FilledQuestionMarkSvg, 15);
const StatisticsGraphIcon = sized(StatisticsGraphSvg, 16);
const CopyIcon = colored(sized(CopySvg, 16, 18), '#fff');
const ShareIcon = colored(sized(ShareSvg, 16, 18), '#fff');
const TagLinkIcon = colored(sized(TagLinkSvg, 16, 18), '#fff');
const ChevronRightIcon = sized(ChevronRightSvg, 6, 10);
const CheckIcon = colored(sized(CheckSvg, 20), '#fff');

const Tooltip = variance(View)((theme) => ({
  root: {
    position: 'absolute',
    top: -65,
    left: 25,
    backgroundColor: theme.select(
      theme.chroma(theme.palette.foreground).alpha(0.8).hex(),
      theme.chroma(theme.palette.background).alpha(0.8).hex(),
    ),
    borderWidth: 1,
    borderColor: theme.palette.border,
    borderRadius: 2,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    width: 250,
  },
}));

const TooltipText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight(),
    fontSize: 12,
    lineHeight: 15,
    color: theme.select(
      theme.contrast(theme.palette.textPrimary),
      theme.palette.textPrimary,
    ),
  },
}));

const Container = variance(View)((theme) => ({
  root: {
    height: COPY_PROMO_HEIGHT,
    paddingBottom: 10,
    ...theme.mediaQuery({
      [COPY_PROMO_DESKTOP_BREAKPOINT]: {
        height: COPY_PROMO_DESKTOP_HEIGHT,
      },
    }),
  },
}));

const Root = variance(View)((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background,
    borderWidth: 1,
    borderColor: theme.palette.border,
    padding: 10,
    borderRadius: 15,
    ...theme.mediaQuery({
      [COPY_PROMO_DESKTOP_BREAKPOINT]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      [DESKTOP_BREAKPOINT]: {
        paddingHorizontal: 20,
      },
    }),
  },
}));

const Header = variance(View)((theme) => ({
  root: {
    marginBottom: 13,
    flexDirection: 'row',
    alignItems: 'center',
    ...theme.mediaQuery({
      [COPY_PROMO_DESKTOP_BREAKPOINT]: {
        marginBottom: 0,
        flex: 1,
      },
    }),
  },
}));

const Body = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    gap: 10,
  },
}));

const Input = variance(TextInput)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    height: 44,
    paddingHorizontal: 15,
    color: theme.palette.textPrimary,
    flex: 1,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: theme.palette.border,
    backgroundColor: theme.palette.background,
    minWidth: 0,
  },
}));

const Title = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 16,
    lineHeight: 16,
    ...theme.mediaQuery({
      400: {
        fontSize: 16,
        lineHeight: 26,
      },
    }),
  },
  disabled: {
    opacity: 0.5,
  },
}));

const StatisticsButtonView = variance(View)(() => ({
  root: {
    marginLeft: 'auto',
    flexDirection: 'row',
  },
}));

const StatisticsButtonContent = variance(View)(() => ({
  root: {
    paddingVertical: 3,
    paddingHorizontal: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.5,
  },
}));

const StatisticsButtonText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.secondary,
    fontSize: 13,
    lineHeight: 15,
    textTransform: 'uppercase',
  },
}));

const FlashIcon = variance(View)(() => ({
  root: {
    padding: 5,
  },
}));
