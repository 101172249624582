import {observable, makeObservable, runInAction} from 'mobx';
import {PromoCode} from '../../../../src/ApiStore';
import {Auth} from '../../../../src/Auth';
import {ConnectedClient} from '../../../../src/ContextClient';

export default class DashboardPromoImpl {
  @observable private _isLoading = true;

  @observable private _promos?: PromoCode[];

  constructor(
    private readonly _root: {
      readonly connectedClient: ConnectedClient;
      readonly auth: Auth;
    },
  ) {
    makeObservable(this);
  }

  get isLoading() {
    return this._isLoading;
  }

  get promos() {
    return this._promos;
  }

  getPromos = async () => {
    runInAction(() => (this._isLoading = true));
    const response = await this._root.connectedClient.call(
      'find_promo_codes',
      {},
    );
    runInAction(() => {
      if (response.success) {
        this._promos = response.right.items;
      } else {
        this._promos = [];
      }
      this._isLoading = false;
    });
  };
}
