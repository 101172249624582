import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {BaseListHeader} from '../../Header/BaseListHeader';
import {
  ScrollViewProps,
  SectionList,
  SectionListProps,
  View,
} from 'react-native';
import {createStylesHook, useTheme, variance} from '../../../styling';
import {GroupSection, WorkerItem} from '../../form/formSections';
import {DetailSlot} from './DetailSlot';
import EmptyGroupHeader from './GroupHeader/EmptyGroupHeader';
import GroupHeader from './GroupHeader/GroupHeader';
import SectionFooter from './SectionFooter';
import {useRoot} from '../../../Root/hooks';
import useGetItemLayout, {useIsDesktop} from '../../hooks/useGetItemLayout';
import {DashboardScreenProps} from '../../DashboardScreenProps';
import {useRoute} from '@react-navigation/native';
import RefreshControl from '../../../components/RefreshControl';
import ListFooter from '../ListFooter';
import DesktopDetailSlot from './DetailSlot/DesktopDetailSlot';
import {DESKTOP_BREAKPOINT} from '../../../units/constants';
import useStableSections from '../../hooks/useStableSections';

export type SectionWorkerListProps = ScrollViewProps & {
  hideRefreshControl?: boolean;
};

export type SectionWorkerListComponent = SectionList<WorkerItem, GroupSection>;

export const SectionWorkerList = observer(
  forwardRef<SectionWorkerListComponent>(
    ({hideRefreshControl, ...rest}: SectionWorkerListProps, ref) => {
      const route = useRoute<DashboardScreenProps['route']>();
      const {dashboardStore} = useRoot();
      const groups = dashboardStore.dashboardRequest.value?.groups;
      const stableSections = useStableSections(route.params ?? {});
      const getItemLayout = useGetItemLayout();
      const styles = useStyles();
      const isDesktop = useIsDesktop();
      return (
        <SectionList
          ref={ref}
          contentContainerStyle={styles.container}
          ListHeaderComponent={BaseListHeader}
          ListFooterComponent={
            <ListFooter
              hideGroupCreation={groups && groups.length > 1}
              hideAddSlotsButton={
                stableSections.length > 0 &&
                stableSections[stableSections.length - 1].slotsLeftToShow > 1
              }
            />
          }
          sections={stableSections}
          keyExtractor={keyExtractor}
          renderItem={DetailItem}
          getItemLayout={getItemLayout}
          renderSectionHeader={renderSectionHeader}
          renderSectionFooter={renderSectionFooter}
          refreshControl={
            hideRefreshControl ? undefined : (
              <RefreshControl
                refreshing={dashboardStore.isLoading}
                onRefresh={dashboardStore.fetch}
              />
            )
          }
          stickySectionHeadersEnabled={false}
          keyboardShouldPersistTaps="handled"
          initialNumToRender={isDesktop ? 10 : 2}
          maxToRenderPerBatch={6}
          windowSize={11}
          {...rest}
        />
      );
    },
  ),
);

const DetailItem = ({item, index}: {item: WorkerItem; index: number}) => {
  const theme = useTheme();
  return theme.window.width >= DESKTOP_BREAKPOINT ? (
    <DesktopDetailSlot worker={item.worker} index={index} />
  ) : (
    <DetailSlot worker={item.worker} index={index} />
  );
};

const useStyles = createStylesHook((theme) => ({
  container: {
    paddingVertical: 10,
    paddingHorizontal: 10,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        paddingHorizontal: 0,
        paddingBottom: 0,
      },
    }),
  },
  settings: {
    padding: 5,
  },
}));

export type ListProps = SectionListProps<WorkerItem, GroupSection>;

const keyExtractor: NonNullable<ListProps['keyExtractor']> = (item) => item.key;

const renderSectionHeader: ListProps['renderSectionHeader'] = ({section}) =>
  section.isEmpty ? (
    <EmptyGroupHeader />
  ) : (
    <GroupHeader
      group={section.group}
      isDefaultGroup={section.isDefaultGroup}
    />
  );

const renderSectionFooter: ListProps['renderSectionFooter'] = ({section}) =>
  section.slotsLeftToShow > 0 ? (
    <SectionFooter
      groupId={section.isEmpty ? undefined : section.group.id}
      slotsLeft={section.slotsLeftToShow}
    />
  ) : (
    <EmptyFooter demo={section.isEmpty || section.slotsLeftToShow < 0} />
  );

const EmptyFooter = variance(View)((theme) => ({
  root: {
    marginBottom: 10,
  },
  demo: {
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        marginBottom: 42,
      },
    }),
  },
}));
