import {comparer, reaction} from 'mobx';
import {MessagingIdentity} from './MessagingIdentity';
import {Service} from '../structure';
import {MESSAGING_PROTOCOL_VERSION} from '../Messaging';
import {ApiStore} from '../ApiStore';
import {Auth} from '../Auth';

export default class MessagingIdentityNotifierService implements Service {
  constructor(
    private readonly _root: {
      readonly messagingIdentity: MessagingIdentity;
      readonly apiStore: ApiStore;
      readonly auth: Auth;
    },
  ) {}

  subscribe() {
    return reaction(
      () =>
        [
          this._root.auth.state?.kind === 'Authorized' ||
            this._root.auth.state?.kind === 'Connected' ||
            this._root.auth.state?.kind === 'ConnectionFailed',
          this._root.messagingIdentity.token,
        ] as const,
      async ([authorized, token]) => {
        if (authorized && token !== undefined) {
          return this._root.apiStore.client.call('fcm_registration', {
            token,
            version: MESSAGING_PROTOCOL_VERSION,
          });
        }
      },
      {equals: comparer.shallow},
    );
  }
}
