import {action, computed, observable, makeObservable} from 'mobx';
import {bind} from '../fp';
import {LogFilter} from './LogFilter';
import omitStates from './omitStates';
import {Log} from '../Log';

export default class LogFilterImpl implements LogFilter {
  @observable private _ignoreStates = false;

  constructor(private readonly _root: {readonly log: Log}) {
    makeObservable(this);
  }

  get ignoreStates() {
    return this._ignoreStates;
  }

  toggleStatesIgnorance = action(
    bind(() => {
      this._ignoreStates = !this._ignoreStates;
    }),
  );

  @computed get records() {
    const {records} = this._root.log;
    return this._ignoreStates ? omitStates(records) : records;
  }
}
