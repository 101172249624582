import {APP_WINDOW_ACTIVE, AppWindowState} from '../AppWindow';
import {Cannon} from '../ConnectionManager';
import {autorun, observable, makeObservable} from 'mobx';
import {NoConnectionModal} from './NoConnectionModal';
import {Service} from '../structure';

export default class NoConnectionModalService
  implements NoConnectionModal, Service
{
  @observable private _shown = false;

  constructor(
    private readonly _root: {
      readonly appWindowState: AppWindowState;
      readonly cannon: Cannon;
    },
  ) {
    makeObservable(this);
  }

  get shown() {
    return this._shown;
  }

  subscribe() {
    return autorun(() => {
      if (this._root.appWindowState.status === APP_WINDOW_ACTIVE) {
        if (this._root.cannon.isFiring) {
          this._shown = true;
        }
      } else {
        this._shown = false;
      }
    });
  }
}
