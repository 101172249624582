import React from 'react';
import {observer} from 'mobx-react-lite';
import SimpleSlotHeader, {SimpleSlotHeaderProps} from './SimpleSlotHeader';
import DefaultSlotHeader, {DetailSlotHeaderProps} from './DefaultSlotHeader';
import {Mode} from '../../../DashboardMode';

export type WorkerHeaderProps =
  | ({
      variant: Mode.Simple;
    } & SimpleSlotHeaderProps)
  | ({
      variant: Mode.Regular;
    } & DetailSlotHeaderProps);

export default observer((props: WorkerHeaderProps) => {
  switch (props.variant) {
    case Mode.Regular:
      return <DefaultSlotHeader {...props} />;
    case Mode.Simple:
      return <SimpleSlotHeader {...props} />;
    default:
      return null;
  }
});
