import {BaseErrorBody} from './BaseError';

export const FETCH_OFFERS_ERROR = Symbol();
export const FETCH_OFFERS_STORE_ERROR = Symbol();
export const FETCH_OFFERS_SERVER_ERROR = Symbol();
export const FETCH_OFFERS_NO_DATA_ERROR = Symbol();

export type FetchOffersError = {
  kind: typeof FETCH_OFFERS_ERROR;
  type:
    | typeof FETCH_OFFERS_STORE_ERROR
    | typeof FETCH_OFFERS_SERVER_ERROR
    | typeof FETCH_OFFERS_NO_DATA_ERROR;
} & BaseErrorBody;
