import React, {useCallback, useState} from 'react';
import {Keyboard, Platform, Text, View} from 'react-native';
import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {useForm, Controller} from 'react-hook-form';
import {observer} from 'mobx-react-lite';

import {Button, ButtonVariant, PressableOpacity} from '../components';
import {useRoot, useStrings} from '../Root/hooks';
import {Input} from './extra/Input';
import {RootParamList} from '../Navigation';
import sleep from '../utils/sleep';
import {LoaderWrapper} from './extra/LoaderWrapper';
import Modal from '../modals/BaseModal';
import {createStylesHook, useTheme} from '../styling';

import useOpenRateModal from './useOpenRate';

export type CommentModalNavigationProps = {
  navigation: StackNavigationProp<RootParamList>;
  route: RouteProp<RootParamList, 'Comment'>;
};

export type CommentFormData = {
  email: string;
  comment: string;
};

export const CommentModal = observer(() => {
  const navigation = useNavigation<CommentModalNavigationProps['navigation']>();
  const route = useRoute<CommentModalNavigationProps['route']>();
  const {starsNumber: rating} = route.params;
  const {rateApp} = useRoot();
  const theme = useTheme();
  const strings = useStrings();
  const styles = useStyles();
  const {
    control,
    handleSubmit,
    formState: {errors},
    getValues,
  } = useForm<CommentFormData>();
  const [sending, setSending] = useState<boolean>(false);
  const openRate = useOpenRateModal();
  const openRateHandler = useCallback(() => {
    const values = getValues();
    openRate(() =>
      rateApp.sendRating({
        rating: rating,
        email: values.email,
        message: values.comment,
      }),
    );
  }, [getValues, openRate, rateApp, rating]);

  const submitForm = async (data: CommentFormData) => {
    setSending(true);
    Keyboard.dismiss();
    await Promise.all([
      sleep(1000),
      rateApp.setRatingReviewed(),
      rateApp.sendRating({
        rating: rating,
        email: data.email,
        message: data.comment,
      }),
    ]);
    setSending(false);
    navigation.replace('ThanksModal');
  };

  const handleClose = async () => {
    rateApp.closeModal();
    await rateApp.setRatingReviewed();
  };

  return (
    <Modal onDismiss={handleClose}>
      <View>
        <Text style={styles.titleText}>
          {strings['modal.rateComment.title']}
        </Text>
        <Text style={styles.subTitleText}>
          {strings['modal.rateComment.subTitle']}
        </Text>

        <View>
          <Controller
            control={control}
            render={({field: {onChange, onBlur, value}}) => (
              <Input
                onChangeText={onChange}
                onBlur={onBlur}
                value={value}
                hasError={'email' in errors}
                inputViewStyle={styles.emailInputView}
                placeholder={strings['modal.rateComment.emailPlaceholder']}
                placeholderTextColor={theme.palette.textSecondary}
                autoComplete="email"
                keyboardType="email-address"
                textContentType="emailAddress"
                autoCapitalize="none"
              />
            )}
            name="email"
            rules={{
              required: true,
              pattern: {
                message: '',
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              },
            }}
            defaultValue=""
          />

          <Controller
            control={control}
            render={({field: {onChange, onBlur, value}}) => (
              <Input
                onChangeText={onChange}
                onBlur={onBlur}
                value={value}
                hasError={'comment' in errors}
                placeholderTextColor={theme.palette.textSecondary}
                inputViewStyle={styles.commentInputView}
                placeholder={strings['modal.rateComment.commentPlaceholder']}
                multiline
              />
            )}
            name="comment"
            rules={{
              required: true,
            }}
            defaultValue=""
          />
        </View>

        <LoaderWrapper style={styles.footerView} loading={sending}>
          <PressableOpacity
            onPress={handleSubmit(submitForm)}
            style={styles.sendFeedbackButton}>
            <Button variant={ButtonVariant.Highlighted}>
              {strings['action.sendFeedback']}
            </Button>
          </PressableOpacity>
          <PressableOpacity onPress={rateApp.closeModal}>
            <Button>{strings['action.close']}</Button>
          </PressableOpacity>
          {Platform.OS === 'ios' && (
            <PressableOpacity
              style={styles.rateInAppStoreButton}
              activeOpacity={0.7}
              onPress={openRateHandler}>
              <Button>{strings['modal.rateComment.rateInAppStore']}</Button>
            </PressableOpacity>
          )}
        </LoaderWrapper>
      </View>
    </Modal>
  );
});

const useStyles = createStylesHook((theme) => ({
  titleText: {
    fontSize: 24,
    lineHeight: 32,
    marginBottom: 10,
    letterSpacing: 0.09,
    color: theme.palette.textPrimary,
    ...theme.fontByWeight('700'),
  },
  subTitleText: {
    fontSize: 14,
    lineHeight: 22,
    letterSpacing: 0.06,
    marginBottom: 25,
    color: theme.palette.textPrimary,
    ...theme.fontByWeight(),
  },
  sendFeedbackButton: {
    marginBottom: 15,
  },
  rateInAppStoreButton: {
    marginTop: 15,
  },
  footerView: {},
  emailInputView: {
    marginBottom: 15,
  },
  commentInputView: {
    marginBottom: 30,
  },
}));
