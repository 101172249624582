import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import App from './App';
import {UiHeadlessServiceFactory} from './Headless';

export default observer(() => {
  const [state] = useState(() => new UiHeadlessServiceFactory().create());
  useEffect(() => state.subscribe(), [state]);
  return state.initialized && !state.isHeadless ? (
    <App headless={state} />
  ) : null;
});
