import BasePurchaseRequesterImpl from './BasePurchaseRequesterImpl';
import {RequestPurchaseParams} from './PurchaseRequester';
import {InAppPurchaseManager, PurchaseResult} from '../InAppPurchaseManager';
import {ApiStore, FarmId, MetaItem} from '../ApiStore';
import {InAppOffers} from '../InAppOffersService';
import {ErrorRepository} from '../ErrorRepository';
import {define, WAITED_TRANSACTIONS} from '../persistence';
import {Platform} from 'react-native';
import {PendingPurchasesResolver} from '../PendingPurchasesResolver';
import {Auth} from '../Auth';
import {error} from '../fp';
import {PurchaseDiscount} from '../PurchasePromoService';
import {NOT_CONNECTED, NotConnectedError} from '../Error';
import {UtmUrlListener} from '../UtmUrlService';
import {GiftState} from '../Gift/Gift';
import {PurchaseId} from '../units';

export default class IosPurchaseRequesterImpl extends BasePurchaseRequesterImpl {
  constructor(
    protected readonly _root: {
      readonly auth: Auth;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly apiStore: ApiStore;
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly pendingPurchasesResolver: PendingPurchasesResolver;
      readonly errorRepository: ErrorRepository;
      readonly utmUrl: UtmUrlListener;
      readonly gift: GiftState;
    },
  ) {
    super(_root);
  }

  protected async requestProduct(
    params: RequestPurchaseParams,
  ): Promise<PurchaseResult> {
    const product_ = await this._root.inAppPurchaseManager.requestProduct({
      sku: params.purchaseId,
      purchaseTokenAndroid: undefined,
      obfuscatedAccountIdAndroid: undefined,
      subscriptionOfferTokenAndroid: undefined,
      obfuscatedProfileIdAndroid: undefined,
    });
    if (this._code || params.poolMinerId) {
      await this._addToPendingFinishProductTransitionIos(product_, params);
    }
    return product_;
  }

  protected async requestSubscription(
    params: RequestPurchaseParams,
  ): Promise<PurchaseResult> {
    const authState = this._root.auth.state;
    if (authState?.kind !== 'Connected') {
      return error(
        this._root.errorRepository.create<NotConnectedError>({
          kind: NOT_CONNECTED,
        }),
      );
    }
    const farmId = authState.accountId;
    const subscription_ =
      await this._root.inAppPurchaseManager.requestSubscription({
        sku: params.purchaseId,
        purchaseTokenAndroid: undefined,
        obfuscatedAccountIdAndroid: undefined,
        subscriptionOfferTokenAndroid: undefined,
        obfuscatedProfileIdAndroid: undefined,
      });
    await this._addToPendingFinishSubscriptionTransitionIos(
      subscription_,
      farmId,
      params.purchaseId,
    );
    return subscription_;
  }

  private async _addToPendingFinishProductTransitionIos(
    purchase: PurchaseResult,
    params: RequestPurchaseParams,
  ) {
    const authState = this._root.auth.state;
    if (authState?.kind !== 'Connected') {
      return error(this._root.errorRepository.create({kind: NOT_CONNECTED}));
    }
    const farmId = authState.accountId;
    if (
      purchase.success &&
      purchase.right.transactionId &&
      Platform.OS === 'ios'
    ) {
      const waitedTransactions = await getWaitedTransactions();
      const newWaitedTransactions: WaitedTransactionsRecord =
        waitedTransactions.success ? waitedTransactions.right ?? {} : {};
      let meta: MetaItem['meta'] = {
        promo: this._code,
        farm_id: farmId,
        gift: await this._getMetaGift(params.purchaseId),
        utm: await this._root.utmUrl.getUtms(),
      };
      newWaitedTransactions[purchase.right.transactionId] = {
        transaction_id: purchase.right.transactionId,
        meta: meta,
      };
      if (params.poolMinerId) {
        meta.pool_miner_id = params.poolMinerId;
      }
      await setWaitedTransactions(newWaitedTransactions);
    }
  }

  private async _addToPendingFinishSubscriptionTransitionIos(
    purchase: PurchaseResult,
    farmId: FarmId,
    purchaseId: PurchaseId,
  ) {
    if (
      purchase.success &&
      purchase.right.originalTransactionIdentifierIOS &&
      Platform.OS === 'ios'
    ) {
      const waitedTransactions = await getWaitedTransactions();
      const newWaitedTransactions: WaitedTransactionsRecord =
        waitedTransactions.success ? waitedTransactions.right ?? {} : {};
      newWaitedTransactions[purchase.right.originalTransactionIdentifierIOS] = {
        transaction_id: purchase.right.originalTransactionIdentifierIOS,
        meta: {
          farm_id: farmId,
          gift: await this._getMetaGift(purchaseId),
          utm: await this._root.utmUrl.getUtms(),
        },
      };
      await setWaitedTransactions(newWaitedTransactions);
    }
  }

  private async _getMetaGift(purchaseId: PurchaseId) {
    const gift = await this._root.gift.queryGift(purchaseId);
    if (!gift) {
      return {};
    }
    const {plan, pool_miner_config} = gift;
    return {
      plan_id: plan?.id || null,
      pool_miner_config_id: pool_miner_config?.id || null,
      type: null,
    };
  }
}

type WaitedTransactionsRecord = {
  [transactionId: string]: MetaItem;
};

export const [getWaitedTransactions, setWaitedTransactions] =
  define<WaitedTransactionsRecord>(WAITED_TRANSACTIONS);
