import {Clipboard} from './Clipboard';
import * as ExpoClipboard from 'expo-clipboard';
import BaseClipboardImpl from './BaseClipboardImpl';

export default class MobileClipboardImpl
  extends BaseClipboardImpl
  implements Clipboard
{
  setString(value: string) {
    return ExpoClipboard.setString(value);
  }
}
