import {RefParser} from './RefParser';
import {Either, error, success} from '../fp';
import {Platform} from 'react-native';
import {getBundleId} from 'react-native-device-info';

/**
 * @example com.example://ref/x/x/x/x -> x/x/x/x
 */
export default class SlashedParameterRefParserImpl implements RefParser {
  private readonly PREFIX =
    Platform.OS === 'web'
      ? `${window.location.origin}/ref/`
      : `${getBundleId()}://ref/`;

  parse(str: string): Either<string, void> {
    const prefix = this.PREFIX;
    if (str.startsWith(prefix)) {
      const suffix = str.substring(prefix.length);
      return success(suffix);
    }
    return error(undefined);
  }
}
