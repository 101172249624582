import {Millisecond, SECOND} from '../utils/time';
import {Bitcoin, WorkerRecord} from './DemonstrationDatabase';
import getWorkerSettings from './getWorkerSettings';
import {BitcoinPerHash} from './MiningService';

export default (
  lastWorkerStatsUpdate: Millisecond,
  now: Millisecond,
  worker: WorkerRecord,
  bitcoinPerHash: BitcoinPerHash,
): Bitcoin => {
  const settings = getWorkerSettings(
    now,
    worker.schedulers,
    worker.settings,
    worker.shouldIgnoreScheduleUntil,
  );
  return (
    worker.characteristics.hashrate *
    (settings.speed / 100) *
    ((now - lastWorkerStatsUpdate) / SECOND) *
    bitcoinPerHash
  );
};
