import {observer} from 'mobx-react-lite';
import React from 'react';
import CreateGroup from './CreateGroup';
import DesktopStoreBanner from '../DesktopStoreBanner';
import {DesktopWebAppFooter} from '../../DesktopWebAppFooter';
import AddSlotsSection from './AddSlotsSection';
import AdvancedSection from './AdvancedSection';
import {View, ViewProps} from 'react-native';
import {Container} from '../../components/Grid';

type ListFooterProps = ViewProps & {
  hideGroupCreation?: boolean;
  hideAddSlotsButton?: boolean;
};

export default observer(function ListFooter(props: ListFooterProps) {
  return (
    <View>
      <Container>
        {!props.hideAddSlotsButton && <AddSlotsSection />}
        <AdvancedSection />
        {props.hideGroupCreation ? null : <CreateGroup />}
        <DesktopStoreBanner />
      </Container>
      <DesktopWebAppFooter />
    </View>
  );
});
