import React from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../styling';
import AnimatableFan from '../components/AnimatableFan';

export default observer(() => {
  const styles = useStyles((theme) => ({
    root: {
      flex: 1,
      backgroundColor: theme.palette.background,
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));
  return (
    <View style={styles.root}>
      <AnimatableFan />
    </View>
  );
});
