import React from 'react';
import {observer} from 'mobx-react-lite';
import {Platform, Text, View} from 'react-native';
import FanFlashSvg from '../../assets/svg/colorless/fanFlash.svg';
import {PressableOpacity} from '../../components';
import Radio from '../../components/Radio';
import {useTheme, variance} from '../../styling';
import {SlotCount} from '../../ApiStore';
import {sized} from '../../Svg';

export interface SubscriptionSlotProps {
  price: string;
  currency: string;
  maxSlots: SlotCount;
  period: string | undefined;
  selected: boolean;
  disabled: boolean;
  onChoose: () => void;
}

export default observer((props: SubscriptionSlotProps) => {
  const {price, currency, maxSlots, period, onChoose, selected, disabled} =
    props;
  const theme = useTheme();
  const displayPrice =
    Platform.OS === 'ios' ? price : (Number(price) / maxSlots).toFixed(2);
  return (
    <Container>
      <Root
        selected={selected}
        locked={disabled}
        disabled={disabled}
        activeOpacity={1}
        onPress={onChoose}>
        <Left>
          <Radio readonly checked={selected} />
          <UnitView>
            <FanFlashIcon color={theme.palette.foreground} />
            <QuantityText>{maxSlots}</QuantityText>
          </UnitView>
        </Left>
        <Right>
          <DetailPriceAccentText>
            {displayPrice} {currency}
          </DetailPriceAccentText>
          <DetailPriceText>{period ? ` / ${period}` : ''}</DetailPriceText>
        </Right>
      </Root>
    </Container>
  );
});

const FanFlashIcon = sized(FanFlashSvg, 32, 20);

const Root = variance(PressableOpacity)((theme) => ({
  root: {
    flexDirection: 'row',
    height: 50,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.border,
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      1000: {
        backgroundColor: theme.palette.background,
        borderWidth: 1,
        marginVertical: 5,
        marginHorizontal: 10,
        borderColor: theme.palette.border,
        borderRadius: 10,
      },
    }),
  },
  locked: {
    opacity: 0.5,
  },
  selected: {
    backgroundColor: theme.palette.additionalInfo,
    ...theme.mediaQuery({
      1000: {
        borderColor: theme.palette.info,
        borderBottomColor: theme.palette.info,
      },
    }),
  },
  disabled: {
    backgroundColor: theme.palette.disabled,
  },
}));

const Container = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      1000: {
        width: '100%',
      },
    }),
  },
}));

const Left = variance(View)(() => ({
  root: {
    flexGrow: 1,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Right = variance(View)(() => ({
  root: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flexGrow: 1,
    flex: 1,
  },
}));

const UnitView = variance(View)(() => ({
  root: {
    marginLeft: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const QuantityText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    lineHeight: 20,
    fontSize: 18,
    color: theme.palette.textPrimary,
    marginLeft: 8,
  },
}));

const DetailPriceText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    fontSize: 14,
  },
}));

const DetailPriceAccentText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 16,
    lineHeight: 20,
  },
}));
