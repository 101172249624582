import React from 'react';
import {observer} from 'mobx-react-lite';
import {ModalTitle} from '../modals/components';
import PuzzleConfirmationView from './PuzzleConfirmationView';
import BaseModal from '../modals/BaseModal';
import {View, StyleSheet, Text} from 'react-native';
import SuccessLockSvg from '../assets/svg/circled/successLock.svg';
import {useRoot, useStrings} from '../Root/hooks';
import {DEFAULT_MODAL_OFFSET, MAX_MODAL_WIDTH} from './PuzzleState';
import {variance} from '../styling';
import {sized} from '../Svg';

export default observer(() => {
  const {poolMinerActivator} = useRoot();
  const strings = useStrings();
  if (!poolMinerActivator.isVisible) {
    return null;
  }
  return (
    <BaseModal
      onDismiss={poolMinerActivator.dismiss}
      contentStyle={styles.content}
      containerStyle={styles.container}>
      <View style={styles.titleContainer}>
        <SuccessLockIcon style={styles.titleIcon} />
        <ModalTitle>{strings['puzzle.title']}</ModalTitle>
      </View>
      <ModalDescription>{strings['puzzle.desc']}</ModalDescription>
      <PuzzleConfirmationView />
    </BaseModal>
  );
});

const SuccessLockIcon = sized(SuccessLockSvg, 30);

const ModalDescription = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    fontSize: 13,
    lineHeight: 16,
    marginBottom: 20,
  },
}));

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  titleIcon: {
    marginRight: 10,
  },
  content: {
    maxWidth: MAX_MODAL_WIDTH,
  },
  container: {
    padding: DEFAULT_MODAL_OFFSET,
  },
});
