import {Platform} from 'react-native';
import AdvertisingIdentifierImpl from './AdvertisingIdentifierImpl';
import AdvertisingIdentifierSubImpl from './AdvertisingIdentifierSubImpl';
import {ErrorRepository} from '../ErrorRepository';

export default class AdvertisingIdentifierFactory {
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
    },
  ) {}

  create() {
    return Platform.select({
      android: new AdvertisingIdentifierImpl(this._root),
      default: new AdvertisingIdentifierSubImpl(),
    });
  }
}
