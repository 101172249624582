import React from 'react';
import {View, Text, Platform, ViewProps} from 'react-native';
import {observer} from 'mobx-react-lite';

import {defaultContainerStyle} from './Grid';
import {createStylesHook, useTheme} from '../styling';

interface PageHeaderProps extends ViewProps {
  title: string;
  right?: React.ReactNode;
}

export default observer(({title, right, style, ...rest}: PageHeaderProps) => {
  const styles = useStyles();
  const theme = useTheme();

  if (Platform.OS !== 'web' || theme.window.width <= 1280) {
    return null;
  }
  return (
    <View style={[styles.root, style, defaultContainerStyle]} {...rest}>
      <View style={styles.titleWrapper}>
        <Text style={styles.title}>{title}</Text>
      </View>
      {right}
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    height: 76,
    borderWidth: 1,
    borderColor: theme.palette.border,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background,
    borderBottomWidth: 5,
    borderBottomColor: theme.palette.primary,
  },
  title: {
    ...theme.fontByWeight('700'),
    fontSize: 32,
    lineHeight: 37,
    color: theme.palette.textPrimary,
  },
  titleWrapper: {
    paddingHorizontal: 20,
  },
}));
