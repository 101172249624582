import {Json, JsonSerializable, JsonString} from './Json';
import {Either, error, success} from '../fp';

export default class JsonImpl implements Json {
  parse<T extends JsonSerializable = JsonSerializable>(
    source: JsonString<T>,
  ): Either<T, unknown> {
    try {
      return success(JSON.parse(source));
    } catch (raw) {
      return error(raw);
    }
  }

  stringify<T extends JsonSerializable = JsonSerializable>(
    source: T,
  ): Either<JsonString<T>, unknown> {
    try {
      return success(JSON.stringify(source) as JsonString<T>);
    } catch (raw) {
      return error(raw);
    }
  }
}
