import React from 'react';
import {observer} from 'mobx-react-lite';
import {Button, ButtonVariant, PressableOpacity} from '../../../../components';
import {sized} from '../../../../Svg';
import PlusSvg from '../../../../assets/svg/colorless/plus.svg';
import {useStrings} from '../../../../Root/hooks';
import {variance} from '../../../../styling';
import {View} from 'react-native';
import useTutorialMeasure from '../../../../InteractiveTutorial/useTutorialMeasure';
import {StackElementKey} from '../../../../InteractiveTutorial';

export const ADD_WORKER_HEIGHT = 56;

export type AddWorkerSectionProps = {
  goToAddWorker: () => void;
};

const TUTORIAL_KEY: StackElementKey = 'ADD_WORKER';

export default observer(function AddWorkerSection({
  goToAddWorker,
}: AddWorkerSectionProps) {
  const strings = useStrings();
  const [ref, onLayout, getForceKey] = useTutorialMeasure(TUTORIAL_KEY);
  return (
    <Root>
      <PressableOpacity
        style={{flex: 1}}
        onPress={goToAddWorker}
        ref={ref}
        key={getForceKey()}
        onLayout={onLayout}>
        <Button
          style={{flex: 1}}
          Icon={PlusIcon}
          variant={ButtonVariant.Highlighted}>
          {strings['dashboard.panel.button']}
        </Button>
      </PressableOpacity>
    </Root>
  );
});

const Root = variance(View)(() => ({
  root: {
    paddingBottom: 10,
    height: ADD_WORKER_HEIGHT,
  },
}));

const PlusIcon = sized(PlusSvg, 14);
