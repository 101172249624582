import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../styling';
import {View, Text, Pressable} from 'react-native';
import CloseSvg from '../assets/svg/colorless/close.svg';
import {sized} from '../Svg';
import {useStrings} from '../Root/hooks';

export type AdvertSplashCloseProps = {
  onClose: () => void;
  seconds: number;
};

export default observer(function AdvertSplashClose({
  onClose,
  seconds,
}: AdvertSplashCloseProps) {
  const strings = useStrings();
  const theme = useTheme();
  return (
    <Container>
      {seconds !== 0 && (
        <Info>
          {seconds} {strings['common.secShort'].toLowerCase()}
        </Info>
      )}
      <Close onPress={onClose}>
        <CloseIcon color={theme.palette.textSecondary} />
      </Close>
    </Container>
  );
});

const CloseIcon = sized(CloseSvg, 14, 14);

const Container = variance(View)(() => ({
  root: {
    position: 'absolute',
    top: -40,
    right: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Info = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    lineHeight: 22,
    color: theme.palette.textSecondary,
  },
}));

const Close = variance(Pressable)((theme) => ({
  root: {
    width: 30,
    height: 30,
    borderRadius: 30,
    backgroundColor: theme.palette.background,
    marginLeft: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
