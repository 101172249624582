import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../../../styling';
import {View} from 'react-native';
import {
  Button,
  ButtonProps,
  ButtonVariant,
  PressableOpacity,
} from '../../../components';

export type SelectButtonProps = ButtonProps & {
  onSelectPress?: () => void;
  title: string;
  disabled?: boolean;
};

export const SelectButton = observer((props: SelectButtonProps) => {
  const {onSelectPress, title, disabled, ...rest} = props;
  return (
    <Root>
      <PressableOpacity onPress={onSelectPress} disabled={disabled}>
        <Button
          variant={
            disabled ? ButtonVariant.Disabled : ButtonVariant.Highlighted
          }
          {...rest}>
          {title}
        </Button>
      </PressableOpacity>
    </Root>
  );
});

const Root = variance(View)((theme) => ({
  root: {
    padding: 15,
    ...theme.mediaQuery({
      1000: {
        paddingHorizontal: 0,
        maxWidth: 400,
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
      },
    }),
  },
}));
