import React from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../../Root/hooks';
import {variance} from '../../styling';
import {ApiMode} from '../../farmApi';
import CurrentPlanIsDemoSection from '../common/CurrentPlanIsDemoSection';
import FarmInformation from '../../components/FarmInformation';
import {useIsDesktop} from '../../DashboardScreen/hooks/useGetItemLayout';
import {View} from 'react-native';

export default observer(function SlotInfoSection() {
  const {apiStore, currentSubscriptionState} = useRoot();
  const subscription = currentSubscriptionState.subscription;
  const mode = apiStore.mode;
  const isDesktop = useIsDesktop();
  if (!subscription) {
    return null;
  }
  if (mode === ApiMode.Demo) {
    return (
      <Root>
        <CurrentPlanIsDemoSection />
      </Root>
    );
  }
  return (
    <Root>
      <FarmInfoView isLarge={isDesktop} />
    </Root>
  );
});

const Root = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      1000: {
        width: '100%',
        maxWidth: 1100,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
  },
}));

const FarmInfoView = variance(FarmInformation)((theme) => ({
  root: {
    height: 50,
    padding: 15,
    maxHeight: 50,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.border,
    ...theme.mediaQuery({
      1000: {
        marginVertical: 20,
        height: 70,
        padding: 20,
        maxHeight: 70,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: theme.palette.border,
        justifyContent: 'flex-start',
      },
    }),
  },
}));
