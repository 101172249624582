import {batchDisposers, Service} from '../structure';
import {success} from '../fp';
import {ApiStore} from '../ApiStore';
import {Auth} from '../Auth';

export default class AccountInfoService implements Service {
  constructor(
    private readonly _root: {
      readonly apiStore: ApiStore;
      readonly auth: Auth;
    },
  ) {}

  private _listenToServerUpdates() {
    return this._root.apiStore.server.call(
      'account_info',
      (params, response) => {
        this._root.auth.updateAccountInfo(params);
        return response.respond(success(null));
      },
    );
  }

  subscribe() {
    return batchDisposers(this._listenToServerUpdates());
  }
}
