import {Http} from './Http';
import HttpImpl from './HttpImpl';

export default class AndroidHttpImpl extends HttpImpl implements Http {
  private static readonly _TEMPORARY_REDIRECT = 307;
  private static readonly _PERMANENT_REDIRECT = 308;

  async fetch(input: RequestInfo, init?: RequestInit) {
    const response = await super.fetch(input, init);
    const redirectReceived =
      response.status === AndroidHttpImpl._TEMPORARY_REDIRECT ||
      response.status === AndroidHttpImpl._PERMANENT_REDIRECT;
    const redirectAllowed = !init?.redirect || init.redirect === 'follow';
    const method = init?.method ?? 'GET';
    if (redirectReceived && redirectAllowed && method === 'POST') {
      const location = response.headers.get('Location');
      if (!location) {
        return response;
      }
      return super.fetch(location, init);
    }
    return response;
  }
}
