import {Service} from '../structure';
import {
  DeviceIdentification,
  DeviceIdentificationStateKind as Kind,
} from '../DeviceIdentification';
import {reaction} from 'mobx';
import initializeTenjin from './initializeTenjin';

export default class TenjinInitializerService implements Service {
  constructor(
    private readonly _root: {
      readonly deviceIdentification: DeviceIdentification;
    },
  ) {}

  private _listenWhenDeviceIsRegistered() {
    return reaction(
      () => this._root.deviceIdentification.state,
      (newState, prevState, _) => {
        if (newState.kind === Kind.Registered) {
          initializeTenjin(newState.result.deviceId);
          _.dispose();
        }
      },
    );
  }

  subscribe() {
    return this._listenWhenDeviceIsRegistered();
  }
}
