import {DebugEasterEgg} from './DebugEasterEgg';
import {action, comparer, observable, makeObservable} from 'mobx';
import {bind} from '../fp';
import {Debug} from './Debug';

export const FIRST = Symbol();
export const SECOND = Symbol();
export const THIRD = Symbol();

export type Target = typeof FIRST | typeof SECOND | typeof THIRD;

export default class DebugEasterEggImpl implements DebugEasterEgg {
  private static readonly RIGHT_PATTERN = [
    THIRD,
    SECOND,
    FIRST,
    THIRD,
    SECOND,
    FIRST,
    THIRD,
    SECOND,
    FIRST,
    THIRD,
    SECOND,
    FIRST,
  ] as const;

  private readonly _stack: Target[] = observable.array();

  constructor(private readonly _root: {readonly debug: Debug}) {
    makeObservable(this);
  }

  @action private _recordTap(target: Target) {
    this._stack.push(target);
    const extra = this._stack.length - DebugEasterEggImpl.RIGHT_PATTERN.length;
    if (extra > 0) {
      this._stack.splice(0, extra);
    }
    if (
      comparer.shallow(this._stack, DebugEasterEggImpl.RIGHT_PATTERN) &&
      !this._root.debug.debugEnabled
    ) {
      // noinspection JSIgnoredPromiseFromCall
      this._root.debug.toggleDebug();
      this._stack.splice(0, this._stack.length);
    }
  }

  tapFirst = bind(() => {
    this._recordTap(FIRST);
  }, this);

  tapSecond = bind(() => {
    this._recordTap(SECOND);
  }, this);

  tapThird = bind(() => {
    this._recordTap(THIRD);
  }, this);
}
