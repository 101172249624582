import openLink, {Target} from './openLink';
import {useCallback} from 'react';

/**
 * @deprecated
 * Use Location
 */
export default () =>
  useCallback(
    (url: string, target = Target.Blank) => openLink(url, target),
    [],
  );
