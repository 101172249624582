import React from 'react';
import {observer} from 'mobx-react-lite';
import {SvgProps} from 'react-native-svg';
import {StyleProp, ViewStyle} from 'react-native';

import {openLink, Target} from '../Links';
import {useStyles, useTheme} from '../styling';
import {Hoverable} from 'react-native-web-hooks';
import {PressableOpacity} from '../components';

interface IconProps {
  Icon: React.ComponentType<SvgProps>;
  style: StyleProp<ViewStyle>;
  href: string;
}

export default observer((props: IconProps) => {
  const {Icon, style, href} = props;
  const theme = useTheme();
  const contactIconColor = theme.select(
    theme.contrast(theme.palette.icon),
    theme.palette.icon,
  );
  const styles = useStyles(() => ({
    root: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    rootHovered: {
      backgroundColor: theme.palette.primary,
      borderColor: theme.palette.primary,
    },
  }));
  return (
    <Hoverable>
      {(isHovered) => (
        <PressableOpacity
          onPress={() => openLink(href, Target.Blank)}
          style={[styles.root, style, isHovered && styles.rootHovered]}>
          <Icon color={isHovered ? '#ffffff' : contactIconColor} />
        </PressableOpacity>
      )}
    </Hoverable>
  );
});
