import React, {useCallback} from 'react';
import {DebugScreen} from '../../DebugScreen';
import {observer} from 'mobx-react-lite';
import {BottomTabBindingProps} from './BottomTabBindingProps';

export type DebugBindingProps = BottomTabBindingProps<'Debug'>;

export default observer((props: DebugBindingProps) => {
  const {navigation} = props;
  const goToEnvironmentForm = useCallback(() => {
    navigation.navigate('EnvironmentForm');
  }, [navigation]);
  const goToEnvironmentList = useCallback(() => {
    navigation.navigate('EnvironmentList');
  }, [navigation]);
  const goToLog = useCallback(() => {
    navigation.navigate('Log');
  }, [navigation]);
  const goToDatabaseImport = useCallback(
    () => navigation.navigate('DatabaseImport'),
    [navigation],
  );
  return (
    <DebugScreen
      goToEnvironmentForm={goToEnvironmentForm}
      goToEnvironmentList={goToEnvironmentList}
      goToLog={goToLog}
      goToDatabaseImport={goToDatabaseImport}
    />
  );
});
