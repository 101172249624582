import {useRoot} from '../../Root/hooks';
import {useCallback} from 'react';
import dayjs from 'dayjs';

export default function useCheckDiscount() {
  const {purchaseDiscount} = useRoot();

  const getExpirationSeconds = useCallback(() => {
    const expirationDate = purchaseDiscount.discount?.expirationDate;
    if (!expirationDate) {
      return 0;
    }
    const current = dayjs();
    return dayjs(expirationDate).diff(current, 'seconds');
  }, [purchaseDiscount]);

  const checkExpired = useCallback(() => {
    const seconds = getExpirationSeconds();
    if (!seconds) {
      return false;
    }
    return seconds <= 0;
  }, [getExpirationSeconds]);

  return {checkExpired, getExpirationSeconds};
}
