import {action, computed, flow, observable, when, makeObservable} from 'mobx';
import {DemoConfig} from '../../../../universal/features/api/entity/GetDemoConfigResponse';
import {PickerItem} from './types';
import {DashboardStore} from '../../../../universal/screen/Dashboard/model/DashboardStore';

export class SetUpWorkerDemoModal {
  @observable private _shown = false;
  @observable private _selectedItemId: number | undefined;

  private _result?: DemoConfig;

  constructor(
    private readonly _root: {readonly dashboardStore: DashboardStore},
  ) {
    makeObservable(this);
  }

  get shown() {
    return this._shown;
  }

  get selectedItem() {
    if (this._selectedItemId !== undefined) {
      return this.items[this._selectedItemId];
    }
    return undefined;
  }

  ask = flow(function* (this: SetUpWorkerDemoModal) {
    this._result = undefined;
    this._shown = true;
    yield when(() => !this._shown);
    return this._result as DemoConfig | undefined;
  }).bind(this);

  @action.bound _onPick(id?: PickerItem['id']) {
    const _id = id ?? this._selectedItemId;
    if (_id !== undefined) {
      const {getDemoConfigRequest} = this._root.dashboardStore;
      this._result = getDemoConfigRequest.value?.configs[_id];
      this.reset();
    }
  }

  @action.bound _onSelect(id?: PickerItem['id']) {
    if (id !== undefined) {
      this._selectedItemId = id;
    }
  }

  @action.bound cancel() {
    this._shown = false;
    this.reset();
  }

  @computed get items(): readonly PickerItem[] {
    return (
      this._root.dashboardStore.getDemoConfigRequest.value?.configs.map(
        (config, index) => ({
          id: index,
          title: config.name,
        }),
      ) ?? []
    );
  }

  @action reset() {
    this._shown = false;
    this._selectedItemId = undefined;
  }
}
