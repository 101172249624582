import React from 'react';
import {Text, View, ViewProps} from 'react-native';
import {observer} from 'mobx-react-lite';
import {ApiModeBadge} from '../../../../components';
import {Container} from '../../../../components/Grid';
import {createStylesHook} from '../../../../styling';
import {DESKTOP_BREAKPOINT} from '../../../../units/constants';

export interface GroupHeaderViewProps extends ViewProps {
  name: string;
  slots: number;
  maxSlots?: number;
  buttons?: React.ReactNode;
  footer?: React.ReactNode;
}

export default observer((props: GroupHeaderViewProps) => {
  const {name, slots, maxSlots, buttons, footer, style, ...rest} = props;
  const styles = useStyles();
  return (
    <Container style={[styles.root, style]} {...rest}>
      <View style={styles.header}>
        <View style={styles.left}>
          <View style={styles.titleView}>
            <Text numberOfLines={1} ellipsizeMode="tail" style={styles.title}>
              {name}
            </Text>
          </View>
          <View style={styles.emptySlotView}>
            <Text style={styles.workerCounter}>
              {maxSlots === undefined ? slots : `${slots} / ${maxSlots}`}
            </Text>
            <ApiModeBadge style={styles.badge} hideInReal />
          </View>
        </View>
        <View style={styles.right}>{buttons}</View>
      </View>
      {footer}
    </Container>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  header: {
    flexDirection: 'row',
    zIndex: 2,
    backgroundColor: theme.palette.backdrop,
    borderColor: theme.palette.border,
    borderWidth: 1,
    height: 50,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        backgroundColor: theme.palette.background,
        height: 80,
        paddingVertical: 20,
      },
    }),
  },
  left: {
    flexDirection: 'row',
    flexShrink: 1,
    flexGrow: 1,
    paddingLeft: 15,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexGrow: 0,
        gap: 10,
      },
    }),
  },
  right: {
    width: 90,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        width: 'auto',
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        paddingRight: 20,
      },
    }),
  },
  titleView: {
    flexShrink: 1,
    flexGrow: 1,
    justifyContent: 'center',
    marginRight: 5,
  },
  title: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    color: theme.palette.textPrimary,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 32,
        lineHeight: 32,
      },
    }),
  },
  emptySlotView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  workerCounter: {
    ...theme.fontByWeight('700'),
    fontSize: 11,
    lineHeight: 13,
    color: theme.palette.textSecondary,
  },
  badge: {
    marginHorizontal: 10,
  },
}));
