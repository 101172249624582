import {Millisecond} from '../../Time';
import humanizeDuration, {Unit} from 'humanize-duration';
import {Locale} from '../../Preferences';
import {LocaleTag} from '../../Localization/TranslationService';
import {IntervalType} from '../../ApiStore';

export default (
  milliseconds: Millisecond,
  localeTag: LocaleTag,
  units: Unit[] = ['mo'],
) => {
  return humanizeDuration(milliseconds, {
    units: units,
    round: true,
    language: localeTag,
    fallbacks: [Locale.English],
  });
};

export function IntervalTypeToUnit(type: IntervalType): Unit[] {
  switch (type) {
    case 'day': {
      return ['d'];
    }
    case 'month': {
      return ['mo'];
    }
  }
}
