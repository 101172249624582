import React, {useCallback, useMemo} from 'react';
import {View, Text} from 'react-native';
import {observer} from 'mobx-react-lite';

import {CoreCell} from './CoreCell';
import {ReadonlyDeep} from 'type-fest';
import {CpuState} from '../../../../../ApiStore';
import {useTheme} from '../../../../../styling';
import {DESKTOP_BREAKPOINT} from '../../../../../units/constants';

export interface CoreTableProps {
  cpus?: readonly ReadonlyDeep<CpuState>[];
  disabled: boolean;
  warning?: boolean;
}

export const CoreTable = observer(
  ({cpus, disabled, warning}: CoreTableProps) => {
    const theme = useTheme();
    const styles = useMemo(
      () =>
        ({
          root: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            borderRadius: 10,
            borderWidth: 1,
            borderColor: theme.palette.border,
            overflow: warning ? 'visible' : 'hidden',
            ...theme.mediaQuery({
              [DESKTOP_BREAKPOINT]: {
                flexGrow: 1,
              },
            }),
          },
        } as const),
      [theme, warning],
    );

    if (!cpus || !cpus.length) {
      return (
        <View style={styles.root}>
          <CoreCell disabled temperature="-" coreNumber={1} isShort={true} />
        </View>
      );
    }
    return (
      <View style={styles.root}>
        {disabled
          ? cpus.map((_, index) => (
              <CoreCell
                key={index}
                disabled
                temperature="-"
                coreNumber={index + 1}
              />
            ))
          : cpus.map((cpu, index) => (
              <CoreCellComponent
                key={index}
                cpu={cpu}
                coreNumber={index + 1}
                isShort={cpus.length <= 2}
              />
            ))}
      </View>
    );
  },
);

interface CoreCellComponentProps {
  cpu: ReadonlyDeep<CpuState>;
  coreNumber: number;
  isShort?: boolean;
}

const CoreCellComponent = observer(
  ({cpu, isShort, coreNumber}: CoreCellComponentProps) => {
    const temperature = useCallback(() => <Temperature cpu={cpu} />, [cpu]);
    return (
      <CoreCell
        temperature={temperature}
        warning={cpu.hot}
        coreNumber={coreNumber}
        isShort={isShort}
      />
    );
  },
);

interface TemperatureProps {
  cpu: ReadonlyDeep<CpuState>;
}

const Temperature = observer(({cpu}: TemperatureProps) => (
  <Text>{cpu.temperature.toFixed(0)}</Text>
));
