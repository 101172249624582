import React from 'react';
import {observer} from 'mobx-react-lite';
import {UnitSliderProps} from '../components/UnitSlider';
import {UnitSlider} from '../components';
import {PriceGuess} from '../forms/BtcSliderStateBuilder';
import {useStrings} from '../Root/hooks';

export interface BtcPriceUnitSliderManagerProps extends UnitSliderProps {
  priceGuess: PriceGuess;
}

export default observer((props: BtcPriceUnitSliderManagerProps) => {
  const strings = useStrings();
  let helperText;
  switch (props.priceGuess) {
    case PriceGuess.Positive:
      helperText = strings['statistics.howMuchSection.btc.positive'];
      break;
    case PriceGuess.Negative:
      helperText = strings['statistics.howMuchSection.btc.negative'];
      break;
    case PriceGuess.Default:
      helperText = strings['statistics.howMuchSection.btc.current'];
      break;
    default:
      helperText = null;
      break;
  }
  return <UnitSlider helperText={helperText} {...props} />;
});
