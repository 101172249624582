import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot, useStrings} from '../Root/hooks';
import {Linking, Platform} from 'react-native';
import {ResponseType, useAuthRequest} from 'expo-auth-session';
import {maybeCompleteAuthSession} from 'expo-web-browser';
import {when} from 'mobx';
import emptyFarmPresent from '../Auth/emptyFarmPresent';
import {AuthenticationErrorReason} from '../Auth';
import {DeviceIdentificationStateKind} from '../DeviceIdentification';
import {useOpenLink} from '../Links';
import common from '../Configuration/common';
import SignUpScreen from './SignUpScreen';
import {FarmId} from '../ApiStore';

maybeCompleteAuthSession();

export interface SignUpContainerProps {
  goToOAuthSplash: () => void;
  onSuccess: () => void;
  goToThisScreen: () => void;
  getFarmId: () => FarmId | undefined;
  variant?: 'default' | 'affiliate' | 'promo';
}

export default observer((props: SignUpContainerProps) => {
  const {
    goToOAuthSplash,
    onSuccess,
    goToThisScreen,
    getFarmId,
    variant = 'default',
  } = props;
  const strings = useStrings();
  const {auth} = useRoot();
  const [_error, setError] = useState<unknown>();
  const error = _error || auth.error;
  const accountSwitchRequired =
    auth.state?.kind === 'AuthenticationFailed' &&
    auth.state.reason === AuthenticationErrorReason.AccountSwitchRequired;
  const showError = !accountSwitchRequired;
  const {deviceIdentification, configuration, specialLocation} = useRoot();
  const {
    oauthClientId,
    oauthAuthorizationEndpoint,
    oauthTokenEndpoint,
    oauthRevocationEndpoint,
  } = configuration.values;
  const redirectUri = specialLocation.getOauthRedirect();
  const extraParams: Record<string, string> = {};
  if (Platform.OS === 'ios') {
    extraParams.mode = 's';
  }
  if (
    deviceIdentification.state.kind ===
      DeviceIdentificationStateKind.Registered &&
    deviceIdentification.state.result.advertId !== undefined
  ) {
    extraParams.ref = deviceIdentification.state.result.advertId;
  }
  const [, , promptAsync] = useAuthRequest(
    {
      responseType: ResponseType.Token,
      clientId: oauthClientId,
      redirectUri,
      scopes: ['read'],
      extraParams,
    },
    {
      authorizationEndpoint: oauthAuthorizationEndpoint,
      tokenEndpoint: oauthTokenEndpoint,
      revocationEndpoint: oauthRevocationEndpoint,
    },
  );
  const _startOAuth = useCallback(async () => {
    setError(undefined);
    try {
      // Event Loop bug in Firefox
      setTimeout(async () => {
        goToOAuthSplash();
        const response = await promptAsync();
        if (response?.type === 'success') {
          goToThisScreen();
          const {access_token} = response.params;
          await auth.completeOAuth(access_token, getFarmId());
          await when(() => auth.isConnected);
          onSuccess();
        } else if (response?.type === 'dismiss') {
          goToThisScreen();
        }
      }, 0);
    } catch (raw) {
      setError(raw);
    }
  }, [
    auth,
    getFarmId,
    goToOAuthSplash,
    goToThisScreen,
    onSuccess,
    promptAsync,
  ]);
  const openTermsOfUse = useCallback(
    () => Linking.openURL('https://cryptobrowser.site/en/terms/'),
    [],
  );
  const openPrivacyPolicy = useCallback(
    () => Linking.openURL('https://cryptotab.farm/en/privacy/'),
    [],
  );
  const hasEmptyFarm = auth.subscriptionMap
    ? emptyFarmPresent(auth.subscriptionMap)
    : true;
  const [modalShown, setModalShown] = useState(false);
  const show = useCallback(() => setModalShown(true), []);
  const hide = useCallback(() => setModalShown(false), []);
  const registerNewFarm = useCallback(async () => {
    setModalShown(false);
    await auth.registerNewFarm();
    onSuccess();
  }, [auth, onSuccess]);
  let description = strings['signUp.description'];
  switch (variant) {
    case 'promo':
      description = strings['signUp.promoDescription'];
      break;
    case 'affiliate':
      description = strings['signUp.affiliateDescription'];
      break;
    case 'default':
      description = strings['signUp.description'];
      break;
  }
  const goToCryptoTabFarm = useOpenLink(common.aboutUrl);
  return (
    <SignUpScreen
      onLogoPress={goToCryptoTabFarm}
      onSignUpPress={_startOAuth}
      showRegisterModal={show}
      registerModalShown={modalShown}
      hideRegisterModal={hide}
      description={description}
      hasEmptyFarm={hasEmptyFarm}
      showError={showError}
      openTermsOfUse={openTermsOfUse}
      registerNewFarm={registerNewFarm}
      error={error}
      openPrivacyPolicy={openPrivacyPolicy}
    />
  );
});
