import React from 'react';
import {observer} from 'mobx-react-lite';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextProps,
  View,
  ViewStyle,
} from 'react-native';
import {useTheme, variance} from '../../styling';
import {sized} from '../../Svg';
import PoolMinerSpeed from './PoolMinerSpeed';
import {
  WorkerEntity,
  WorkerType,
} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {CompositeFanIcon} from '../../components';
import FlashSvg from '../../assets/svg/colorless/flash.svg';
import GiftSvg from '../../assets/svg/colorless/gift.svg';
import {ReadonlyDeep} from 'type-fest';
import {expr} from 'mobx-utils';
import getWorkerName from '../getters/getWorkerName';
import {Mode} from '../../DashboardMode';

export interface SlotTitleProps extends TextProps {
  warning: boolean;
  disabled: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  mode?: Mode;
  worker: ReadonlyDeep<WorkerEntity>;
}

export default observer((props: SlotTitleProps) => {
  const {containerStyle, warning, disabled, mode, worker, ...rest} = props;
  const workerName = expr(() => getWorkerName(worker));
  const workerType = worker.worker_type;
  const theme = useTheme();
  const isWorkerGifted = worker.order_id?.includes('gift:');

  return (
    <Container style={containerStyle}>
      {workerType === WorkerType.Pool && (
        <CompositeFanIcon
          circleColor={isWorkerGifted ? theme.palette.success : undefined}
          containerStyle={styles.iconContainer}
          style={styles.icon}
          Icon={isWorkerGifted ? GiftIcon : FlashIcon}
        />
      )}
      <Title warning={warning} disabled={disabled} {...rest}>
        {workerName}
      </Title>
      {workerType === WorkerType.Pool && mode === Mode.Simple && (
        <PoolMinerSpeed
          style={styles.speed}
          speed={worker.pool_miner_config?.hash_rate}
          expired={Boolean(worker.expired)}
        />
      )}
    </Container>
  );
});

const FlashIcon = sized(FlashSvg, 7, 13);
const GiftIcon = sized(GiftSvg, 10, 10);

const styles = StyleSheet.create({
  speed: {
    marginLeft: 10,
  },
  icon: {
    width: 25,
    height: 16,
  },
  iconContainer: {
    marginRight: 5,
  },
});

const Container = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 10,
  },
}));

const Title = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 16,
    marginRight: 5,
    flexShrink: 1,
    flexGrow: 1,
  },
  warning: {
    color: theme.palette.error,
  },
  disabled: {
    color: theme.palette.disabled,
  },
}));
