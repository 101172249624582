import {
  DemonstrationDatabase,
  DemonstrationDatabaseRecord,
} from './DemonstrationDatabase';
import {ISODateString, Millisecond, toISODateString} from '../Time';
import {Translation} from '../Localization';
import dayjs from 'dayjs';
import selectAllWorkersById from './selectAllWorkersById';
import {LogController} from './LogController';
import getWorkerSettings from './getWorkerSettings';
import {cloneDeep} from 'lodash';
// noinspection ES6PreferShortImport
import {
  CryptoFarmClientCalls,
  CryptoFarmClientNotifications,
} from '../ApiStore/CryptoFarm';
// noinspection ES6PreferShortImport
import {FarmId} from '../ApiStore/units';
// noinspection ES6PreferShortImport
import {
  LogEntity,
  LogGroup,
  LogGroupMessageMap,
  LogLevel,
  LogSettings,
  WorkerId,
} from '../ApiStore/entities';
import filterLogs from './filterLogs';
import {Client, Server} from '../JsonRpc';
import getNewLogs from './getNewLogs';
import createLogId from './createLogId';
import {Op} from '../Math';
import {batchDisposers, Service} from '../structure';

export default class LogService implements LogController, Service {
  constructor(
    private readonly _root: {
      readonly db: DemonstrationDatabase;
      readonly translation: Translation;
    },
    private readonly _reverseClient: Client<
      CryptoFarmClientCalls,
      CryptoFarmClientNotifications
    >,
    private readonly _realServer: Server<
      CryptoFarmClientCalls,
      CryptoFarmClientNotifications
    >,
  ) {}

  async getSettings() {
    return cloneDeep(this._root.db.state.logSettings) as LogSettings;
  }

  async setSettings(settings: LogSettings) {
    await this._root.db.update(async (db) => ({...db, logSettings: settings}));
  }

  createPatch<
    Group extends LogGroup = LogGroup,
    Message extends LogGroupMessageMap[Group] = LogGroupMessageMap[Group],
  >(
    params: {
      farmId: FarmId;
      level: LogLevel;
      groupId: Group;
      messageId: Message;
      workerId?: WorkerId;
      title: string;
      body: string;
    },
    now: Millisecond,
    db: DemonstrationDatabaseRecord,
  ): DemonstrationDatabaseRecord {
    return {
      ...db,
      logs: [
        {
          id: createLogId(),
          timestamp: toISODateString(now),
          farm_id: params.farmId,
          level: params.level,
          group_id: params.groupId,
          message_id: params.messageId,
          worker_id: params.workerId,
          title: params.title,
          body: params.body,
        },
        ...db.logs,
      ],
    };
  }

  async queryList(
    limit: number,
    timestamp = new Date().toISOString() as ISODateString,
  ): Promise<{
    limit: number;
    total: number;
    timestamp: ISODateString;
    items: LogEntity[];
  }> {
    const db = this._root.db.state;
    const moment = new Date(timestamp).getTime();
    const from = db.logs.findIndex(
      (_) => new Date(_.timestamp).getTime() < moment,
    );
    if (from === -1) {
      return {
        limit,
        total: db.logs.length,
        timestamp,
        items: [],
      };
    }
    const late = db.logs.slice(from);
    const filtered = filterLogs(late, db.logSettings);
    const limited = filtered.slice(0, limit);
    return {
      limit,
      total: db.logs.length,
      timestamp,
      items: limited,
    };
  }

  private _purgeOldNotifications = () =>
    this._root.db.beforeUpdate(async (previous, current) => {
      const now = new Date().getTime();
      return previous.logs.length < current.logs.length
        ? {
            ...current,
            logs: current.logs.flatMap((log) =>
              dayjs(now).diff(log.timestamp, 'month', true) >= 1 ? [] : [log],
            ),
          }
        : current;
    });

  private _notifyAboutSpeedChange = () =>
    this._root.db.beforeUpdate(async (previous, current) => {
      const now = new Date().getTime() as Millisecond;
      const {strings} = this._root.translation;
      let next = current;
      const previousWorkersById = selectAllWorkersById(previous.groups);
      const workersById = selectAllWorkersById(current.groups);
      for (const [id, worker] of Object.entries(workersById)) {
        const prevWorker = previousWorkersById[id];
        if (prevWorker) {
          const {speed: prevSpeed} = getWorkerSettings(
            now,
            prevWorker.schedulers,
            prevWorker.settings,
            prevWorker.shouldIgnoreScheduleUntil,
          );
          const {speed: currSpeed} = getWorkerSettings(
            now,
            worker.schedulers,
            worker.settings,
            worker.shouldIgnoreScheduleUntil,
          );
          if (prevSpeed > 0 && currSpeed === 0) {
            next = await this.createPatch(
              {
                farmId: 0 as FarmId,
                level: LogLevel.Info,
                groupId: 'mining',
                messageId: 'mining_paused',
                workerId: id as WorkerId,
                title: strings['notifications.miningPaused'],
                body: strings['notifications.miningHasBeenPaused'],
              },
              now,
              next,
            );
          } else if (prevSpeed === 0 && currSpeed > 0) {
            next = await this.createPatch(
              {
                farmId: 0 as FarmId,
                level: LogLevel.Info,
                groupId: 'mining',
                messageId: 'mining_restarted',
                workerId: id as WorkerId,
                title: strings['notifications.miningRestarted'],
                body: strings['notifications.miningHasBeenRestarted'],
              },
              now,
              next,
            );
          }
        }
      }
      return next;
    });

  private _notifyAboutNewMiner = () =>
    this._root.db.beforeUpdate(async (previous, current) => {
      const now = new Date().getTime() as Millisecond;
      const {strings, templates} = this._root.translation;
      let next = current;
      const previousWorkersById = selectAllWorkersById(previous.groups);
      const workersById = selectAllWorkersById(current.groups);
      for (const [id, worker] of Object.entries(workersById)) {
        const prevWorker = previousWorkersById[id];
        if (!prevWorker) {
          next = await this.createPatch(
            {
              farmId: 0 as FarmId,
              level: LogLevel.Info,
              groupId: 'mining',
              messageId: 'new_miner',
              workerId: id as WorkerId,
              title: strings['notifications.newMiner'],
              body: templates['notifications.youHaveNewMinerId']({
                id: worker.name,
              }),
            },
            now,
            next,
          );
          next = await this.createPatch(
            {
              farmId: 0 as FarmId,
              level: LogLevel.Info,
              groupId: 'mining',
              messageId: 'new_miner',
              workerId: id as WorkerId,
              title: strings['notifications.minerStatus'],
              body: templates['notifications.yourMinerNameIsStatus']({
                name: worker.name,
                status: 'ONLINE',
              }),
            },
            Op.add(now, 1 as Millisecond),
            next,
          );
        }
      }
      return next;
    });

  private _routeThrough() {
    return this._realServer.call('farm_log', async (params, response) => {
      const outcome_ = await this._reverseClient.call('farm_log', params);
      if (!outcome_.success && outcome_.left.code === 32099) {
        return;
      }
      return response.respond(outcome_ as any);
    });
  }

  private _emitNewLogs() {
    return this._root.db.afterUpdate((previous, current) => {
      const newLogs = getNewLogs(previous.logs, current.logs);
      const filtered = filterLogs(newLogs, current.logSettings);
      const oldFirst = filtered.reverse();
      for (const log of oldFirst) {
        this._reverseClient.call('farm_log', log);
      }
    });
  }

  subscribe = () =>
    batchDisposers(
      this._routeThrough(),
      this._emitNewLogs(),
      this._purgeOldNotifications(),
      this._notifyAboutSpeedChange(),
      this._notifyAboutNewMiner(),
    );
}
