import {DeviceIdentification} from '../DeviceIdentification';
import {Configuration} from '../Configuration';
import ConnectionManagerService from './ConnectionManagerService';
import createWebSocket from '../ApiStore/createWebSocket';
import {Disconnect} from '../Disconnect';
import delayReject from '../utils/delayReject';
import {Http} from '../Http';

export default class ConnectionManagerServiceFactory {
  constructor(
    private readonly _root: {
      readonly http: Http;
      readonly disconnect: Disconnect;
      readonly deviceIdentification: DeviceIdentification;
      readonly configuration: Configuration;
    },
  ) {}

  private readonly _createWebSocket = async () => {
    if (this._root.disconnect.enabled) {
      return delayReject(250, () => new Error('Simulated failure'));
    }
    const registration = await this._root.deviceIdentification.fetch();
    return createWebSocket(this._root, registration.result.deviceId);
  };

  create() {
    return new ConnectionManagerService(this._createWebSocket);
  }
}
