import React from 'react';
import {observer} from 'mobx-react-lite';
import {
  Button,
  ButtonColor,
  ButtonProps,
  PressableOpacity,
} from '../components';
import {StyleSheet} from 'react-native';

export type TouchableButtonProps = {
  onPress?: () => void;
} & ButtonProps;

export default observer(function TouchableButton(props: TouchableButtonProps) {
  return (
    <PressableOpacity onPress={props.onPress}>
      <Button
        textStyle={styles.buttonText}
        color={ButtonColor.Primary}
        {...props}
      />
    </PressableOpacity>
  );
});

const styles = StyleSheet.create({
  buttonText: {
    fontSize: 14,
    lineHeight: 15,
  },
});
