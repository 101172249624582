import React from 'react';
import {BottomTabBindingProps} from './BottomTabBindingProps';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../../Root/hooks';
import {AccountType} from '../../Auth';
import {ApiMode} from '../../farmApi';
import WithdrawBinding from './WithdrawBinding';
import AuthRequestBinding from './AuthRequestBinding';
import SubscriptionRequestBinding from './SubscriptionRequestBinding';

export type WithdrawBindingProps = BottomTabBindingProps<'Withdraw'>;

export default observer((props: WithdrawBindingProps) => {
  const {auth, apiStore} = useRoot();
  if (auth.accountType === AccountType.Temporary) {
    return <AuthRequestBinding {...props} />;
  }
  if (apiStore.mode === ApiMode.Demo) {
    return <SubscriptionRequestBinding {...props} />;
  }
  return <WithdrawBinding {...props} />;
});
