import {observer} from 'mobx-react-lite';
import {GraphPanel} from '../GraphPanel';
import React from 'react';
import {useRoot, useStrings} from '../Root/hooks';
import {GraphPanelProps} from '../GraphPanel';

export interface RatesPanelProps extends Partial<GraphPanelProps> {}

export default observer((props: RatesPanelProps) => {
  const {
    dashboardStore: {ratesPanel: state},
  } = useRoot();
  const strings = useStrings();

  return (
    <GraphPanel
      onFromChange={state.onFromChange}
      from={state.period.from}
      to={state.period.to}
      onScopeChange={state.onScopeChange}
      scope={state.scope}
      isLoading={state.isLoading}
      graph={state.graph}
      title={strings['common.btcUsdPrice']}
      fractionDigits={0}
      {...props}
    />
  );
});
