import {
  computed,
  observable,
  reaction,
  makeObservable,
  action,
  runInAction,
} from 'mobx';
import {batchDisposers, Service} from '../structure';
import {Advert} from './Advert';
import {Auth} from '../Auth';
import {AdId, AdItem, AdSpot, ApiStore} from '../ApiStore';
import {FULFILLED, PENDING, REJECTED} from '../AsyncAtom';
import {ErrorRepository} from '../ErrorRepository';
import {UNKNOWN_ERROR, UnknownError} from '../Error';
import AdvertCreatorImpl from './AdvertCreatorImpl';
import {AdvertCreator} from './AdvertCreator';
import {AdvertHistory} from './AdvertHistory';
import AdvertHistoryImpl from './AdvertHistoryImpl';
import {QuickStartOffer} from '../QuickStartService';

export default class AdvertService implements Advert, Service {
  private _serverAds: AdItem[] = [];
  @observable private _state: Advert['state'];
  @observable private _anotherModalWasShown = false;

  private readonly _creator: AdvertCreator;
  private readonly _history: AdvertHistory;

  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly apiStore: ApiStore;
      readonly errorRepository: ErrorRepository;
      readonly quickStartOffer: QuickStartOffer;
    },
  ) {
    makeObservable(this);
    this._creator = new AdvertCreatorImpl();
    this._history = new AdvertHistoryImpl(_root);
  }

  get state(): Advert['state'] {
    if (this._anotherModalWasShown && this._state?.status === FULFILLED) {
      const spotByBannerList = this._state.result.spotByBannerList;
      const newSpotEntries = [...spotByBannerList.entries()].filter(
        (_) => !(_[0] === AdSpot.SplashDesktop || _[0] === AdSpot.SplashMobile),
      );
      const newSpotByBannerList = new Map(newSpotEntries);
      return {
        status: FULFILLED,
        result: {spotByBannerList: newSpotByBannerList},
      };
    }
    return this._state;
  }

  @computed
  get spotByBannerList() {
    return this.state?.status === FULFILLED
      ? this.state.result.spotByBannerList
      : undefined;
  }

  async close(id: AdId, spot: AdSpot, exclude: boolean) {
    const items = this.spotByBannerList
      ? [...this.spotByBannerList?.values()].flat()
      : [];
    const candidate = items.find((_) => _.id === id && _.spot === spot);
    if (candidate) {
      await this._history.close(candidate, exclude);
    }
    await this._process();
  }

  private _setState = action((state: Advert['state']) => {
    this._state = state;
  });

  private async _process() {
    const history_ = await this._history.get();
    if (!history_.success) {
      return this._setState({
        status: REJECTED,
        error: this._root.errorRepository.create<UnknownError>({
          kind: UNKNOWN_ERROR,
          raw: history_.left,
        }),
      });
    }
    const result = await this._creator.process(
      this._serverAds,
      history_.right,
      this._history.checkSessionClosed,
    );
    if (!result.success) {
      return this._setState({
        status: REJECTED,
        error: result.left,
      });
    }
    return this._setState({
      status: FULFILLED,
      result: {
        spotByBannerList: result.right,
      },
    });
  }

  private async _fetchAds() {
    this._setState({status: PENDING});
    const exclude = await this._history.getExcludeList();
    const ads_ = await this._root.apiStore.client.call('get_ads', {
      exclude: exclude,
    });
    if (!ads_.success) {
      this._setState({
        status: REJECTED,
        error: this._root.errorRepository.create<UnknownError>({
          kind: UNKNOWN_ERROR,
        }),
      });
      return;
    }

    this._serverAds = ads_.right.items;
    await this._process();
  }

  private _fetchOnConnected() {
    return reaction(
      () => this._root.auth.isConnected,
      (isConnected) => {
        if (isConnected) {
          // noinspection JSIgnoredPromiseFromCall
          this._fetchAds();
        }
      },
    );
  }

  private _listenToShowOtherModals() {
    return reaction(
      () => this._root.quickStartOffer.shouldShownModal,
      (should) => {
        if (should) {
          runInAction(() => (this._anotherModalWasShown = true));
        }
      },
    );
  }

  subscribe() {
    return batchDisposers(
      this._fetchOnConnected(),
      this._listenToShowOtherModals(),
    );
  }
}
