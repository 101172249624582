import {MeasureResult} from '../ReactNativeUtil';
import {Router} from '../structure';

export interface InteractiveTutorial {
  readonly isShown: boolean;
  readonly scrollToElementInProgress: boolean;
  readonly tutorialStack: StackElement[];
  readonly activeStepIndex: number;
  readonly accentElement:
    | {measure: MeasureResult | undefined; key: StackElementKey}
    | undefined;
  readonly activeStep: StackElement;
  prev(): Promise<void>;
  next(): Promise<void>;
  skip(): Promise<void>;
  start(): Promise<void>;
  finish(): Promise<void>;
  events: Router<InteractiveTutorialEvents>;
}

export type StackElementKey =
  | 'BALANCE'
  | 'ADD_WORKER'
  | 'PROMO'
  | 'TAB_BAR_MENU'
  | 'TAB_BAR_STATISTICS'
  | 'TAB_BAR_NOTIFICATIONS';

export type StackElement = {
  elementKey: StackElementKey;
  elementDesc: string;
  inScrollFlow: boolean;
};

export const TUTORIAL_STACK: StackElement[] = [
  {
    elementKey: 'BALANCE',
    elementDesc: 'Dashboard user balance',
    inScrollFlow: true,
  },
  {
    elementKey: 'ADD_WORKER',
    elementDesc: 'Dashboard add worker button',
    inScrollFlow: true,
  },
  {
    elementKey: 'PROMO',
    elementDesc: 'Dashboard promo',
    inScrollFlow: true,
  },
  {
    elementKey: 'TAB_BAR_STATISTICS',
    elementDesc: 'Tab bar statistics',
    inScrollFlow: false,
  },
  {
    elementKey: 'TAB_BAR_NOTIFICATIONS',
    elementDesc: 'Tab bar notifications',
    inScrollFlow: false,
  },
  {
    elementKey: 'TAB_BAR_MENU',
    elementDesc: 'Tab bar menu',
    inScrollFlow: false,
  },
];

export const ELEMENT_MEASURE = Symbol();
export const FORCE_ELEMENT_MEASURE = Symbol();
export const SCROLL_TO_ELEMENT = Symbol();
export const SCROLLED = Symbol();
export const FINISHED = Symbol();

export type InteractiveTutorialEvents = {
  [ELEMENT_MEASURE]: {
    key: StackElementKey;
    measure: MeasureResult;
  };
  [FORCE_ELEMENT_MEASURE]: StackElementKey;
  [SCROLL_TO_ELEMENT]: {
    key: StackElementKey;
  };
  [SCROLLED]: void;
  [FINISHED]: void;
};
