import {Platform} from 'react-native';
import {DoubleProposal} from '../../ProposalsState';

export default abstract class StaticPurchaseScreenHelper {
  static filterDoublePoolProposal<O extends DoubleProposal = DoubleProposal>(
    candidate: O,
  ) {
    switch (Platform.OS) {
      case 'android': {
        const {subscription, product, ...rest} = candidate;
        const subOffer = subscription?.offer;
        const prodOffer = product?.offer;
        if (subOffer && !subOffer.bought) {
          return {
            ...rest,
            subscription,
            product: undefined,
          };
        } else if (subOffer?.bought) {
          return {
            ...rest,
            subscription: undefined,
            product: undefined,
          };
        } else if (!subOffer && prodOffer) {
          return {
            ...rest,
            subscription: undefined,
            product: product,
          };
        }
      }
    }

    return candidate;
  }
}
