import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {SelectConfigurationModalScreen} from '../../SelectConfigurationModalScreen';
import {useRoot} from '../../Root/hooks';
import {Hashrate} from '../../ApiStore';

export type SelectConfigurationBindingProps =
  RootStackBindingProps<'SelectConfiguration'>;

export default observer(function SelectConfigurationBinding(
  props: SelectConfigurationBindingProps,
) {
  const {navigation} = props;
  const {purchaseScreenState} = useRoot();

  const handleClose = useCallback(() => {
    navigation.navigate('Plan', {index: 1});
  }, [navigation]);

  const handleSelectPress = useCallback(
    (hashrate: Hashrate) => {
      purchaseScreenState.selectHashrate(hashrate, true);
      handleClose();
    },
    [handleClose, purchaseScreenState],
  );

  return (
    <SelectConfigurationModalScreen
      configurations={purchaseScreenState.minerConfigs}
      selectedHashrate={purchaseScreenState.selectedHashrate}
      onConfigurationPress={handleSelectPress}
      onClose={handleClose}
    />
  );
});
