import {Text, View, ViewProps} from 'react-native';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import React, {useMemo} from 'react';

import {useRoot} from '../Root/hooks';
import {useTheme} from '../styling';

export enum TooltipScope {
  Date,
  Time,
}

export interface TooltipProps extends ViewProps {
  timestamp: number;
  scope: TooltipScope;
  title: string;
  value: number;
  fractionDigits: number;
  secondaryTitle?: string;
  secondaryValue?: number;
  secondaryFractionDigits?: number;
}

export default observer(
  ({
    timestamp,
    scope,
    title,
    value,
    fractionDigits,
    secondaryTitle,
    secondaryValue,
    secondaryFractionDigits,
    style,
    ...rest
  }: TooltipProps) => {
    const theme = useTheme();
    const styles = useMemo(
      () =>
        ({
          root: {
            minWidth: 140,
            backgroundColor: theme.select(
              theme.chroma(theme.palette.foreground).alpha(0.8).hex(),
              theme.chroma(theme.palette.background).alpha(0.8).hex(),
            ),
            borderWidth: 1,
            borderColor: theme.palette.border,
            borderRadius: 2,
            padding: 10,
          },
          text: {
            ...theme.fontByWeight(),
            fontSize: 11,
            lineHeight: 15,
            color: theme.select(
              theme.contrast(theme.palette.textPrimary),
              theme.palette.textPrimary,
            ),
          },
          datetime: {
            color: theme.palette.primary,
          },
          label: {
            marginTop: 7,
          },
          value: {
            marginTop: 2,
            ...theme.fontByWeight('bold'),
            fontSize: 18,
            lineHeight: 25,
          },
        } as const),
      [theme],
    );
    const {translation} = useRoot();
    const {localeTag} = translation;
    return (
      <View style={[styles.root, style]} {...rest}>
        <Text style={[styles.text, styles.datetime]}>
          {dayjs(timestamp)
            .locale(localeTag)
            .format(
              scope === TooltipScope.Date ? 'DD.MM.YYYY' : 'DD.MM.YYYY HH:mm',
            )}
        </Text>
        <Text style={[styles.text, styles.label]}>{title}:</Text>
        <Text style={[styles.text, styles.value]}>
          {value.toFixed(fractionDigits)}
        </Text>
        {secondaryTitle !== undefined &&
          secondaryValue !== undefined &&
          secondaryFractionDigits !== undefined && (
            <>
              <Text style={[styles.text, styles.label]}>{secondaryTitle}:</Text>
              <Text style={[styles.text, styles.value]}>
                {secondaryValue.toFixed(secondaryFractionDigits)}
              </Text>
            </>
          )}
      </View>
    );
  },
);
