import {Platform} from 'react-native';
import MobileInAppOffersService from './MobileInAppOffersService';
import {Auth} from '../Auth';
import {ConnectedClient} from '../ContextClient';
import {InAppPurchaseManager} from '../InAppPurchaseManager';
import {PurchaseDiscount} from '../PurchasePromoService';
import {Configuration} from '../Configuration';
import {ErrorRepository} from '../ErrorRepository';
import WebInAppOffersService from './WebInAppOffersService';
import {InAppOffers} from './InAppOffers';
import {Service} from '../structure';
import {Log} from '../Log';

export default class InAppOffersServiceFactory {
  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly connectedClient: ConnectedClient;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly configuration: Configuration;
      readonly errorRepository: ErrorRepository;
      readonly log: Log;
    },
  ) {}
  create() {
    return Platform.select<InAppOffers & Service>({
      web: new WebInAppOffersService(this._root),
      default: new MobileInAppOffersService(this._root),
    });
  }
}
