import {DatabaseExporter} from './DatabaseExporter';
import {DemonstrationDatabase} from './DemonstrationDatabase';

export default class DatabaseExporterImpl implements DatabaseExporter {
  constructor(private readonly _root: {db: DemonstrationDatabase}) {}

  async export() {
    await this._root.db.fetch();
    const record = this._root.db.state;
    return JSON.stringify(record);
  }
}
