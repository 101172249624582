import React from 'react';
import {
  NativeSyntheticEvent,
  StyleProp,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  View,
  ViewStyle,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useToggle} from '../../../universal/utils/react/hook/useToggle';
import {createStylesHook, useTheme} from '../../styling';

interface InputProps extends TextInputProps {
  inputViewStyle?: StyleProp<ViewStyle>;
  hasError?: boolean;
}

export const Input = observer((props: InputProps) => {
  const theme = useTheme();
  const {style, inputViewStyle, onBlur, hasError, multiline, ...rest} = props;
  const [focused, toggleFocus] = useToggle(false);
  const styles = useStyles();
  const inputStyle = (() => {
    const borderColor = (() => {
      if (hasError) {
        return theme.palette.error;
      }
      if (focused) {
        return theme.palette.secondary;
      }
      return theme.palette.border;
    })();
    const multilineStyle = multiline
      ? {
          paddingTop: 11,
          paddingBottom: 11,
          height: 100,
          textAlignVertical: 'top',
        }
      : {
          height: 44,
        };
    const backgroundColor = focused
      ? theme.palette.background
      : theme.palette.base;
    return {
      borderColor,
      ...multilineStyle,
      backgroundColor,
    } as TextInputProps['style'];
  })();
  const _handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    onBlur && onBlur(e);
    toggleFocus();
  };
  return (
    <View style={[styles.inputView, inputViewStyle]}>
      <TextInput
        onFocus={toggleFocus}
        onBlur={_handleBlur}
        style={[styles.input, style, inputStyle]}
        {...{multiline}}
        {...rest}
      />
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  inputView: {},
  input: {
    borderWidth: 1,
    paddingHorizontal: 15,
    borderRadius: 2,
    color: theme.palette.textPrimary,
    ...theme.fontByWeight(),
  },
}));
