import React from 'react';
import {View, Text, Platform} from 'react-native';
import {observer} from 'mobx-react-lite';
import {variance} from '../../../../styling';
import {IntervalRow, IntervalRowProps} from './IntervalRow';
import MagicSvg from '../../../../assets/svg/colorless/magicSquare.svg';
import {useIsDesktop} from '../../../../DashboardScreen/hooks/useGetItemLayout';
import {colored, sized} from '../../../../Svg';
import {useTemplates} from '../../../../Root/hooks';

export type DurationTableProps = {
  tableTitle?: string;
  items: IntervalRowProps[];
  onItemPress: (v: IntervalRowProps) => void;
  hideGift?: boolean;
};

export const ProposalTable = observer((props: DurationTableProps) => {
  const {items, onItemPress, hideGift} = props;
  const isDesktop = useIsDesktop();
  const templates = useTemplates();
  return (
    <Root>
      {items.map((item, index) => {
        return (
          <IntervalRow
            hideGift={hideGift}
            key={index}
            {...item}
            onPress={() => onItemPress(item)}
          />
        );
      })}
      {!isDesktop && !hideGift && (
        <GiftSection>
          <MagicIconView>
            <MagicIcon />
          </MagicIconView>
          <GiftText>{templates.giftBanner({duration: 12})}</GiftText>
        </GiftSection>
      )}
    </Root>
  );
});

const MagicIcon = colored(sized(MagicSvg, 20, 20), '#fff');

const Root = variance(View)((theme) => ({
  root: {
    padding: 15,
    ...theme.mediaQuery({
      1000: {
        paddingVertical: 20,
        paddingHorizontal: 10,
        flexDirection: 'row',
        alignItems: 'stretch',
      },
    }),
  },
}));

const MagicIconView = variance(View)((theme) => ({
  root: {
    padding: 10,
    borderTopLeftRadius: 9,
    borderBottomLeftRadius: 9,
    flexShrink: 0,
    justifyContent: 'center',
    backgroundColor: theme.palette.purple,
  },
}));

const GiftSection = variance(View)((theme) => ({
  root: {
    borderRadius: 10,
    padding: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
    backgroundColor: theme.palette.purple,
  },
}));

const GiftText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('600'),
    fontSize: 14,
    flex: 1,
    lineHeight: 20,
    padding: 10,
    color: theme.palette.textPrimary,
    borderTopRightRadius: 9,
    borderBottomRightRadius: 9,
    backgroundColor: theme.palette.additionalPurple,
    ...Platform.select({
      android: {
        marginBottom: 1,
      },
    }),
  },
}));
