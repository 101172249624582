import React from 'react';
import {observer} from 'mobx-react-lite';
import {Text, View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {PressableOpacity} from '../../../components';
import {useStrings} from '../../../Root/hooks';
import {useStyles} from '../../../styling';

export type RestoreButtonProps = {
  goToAccessRecovery: () => void;
};

export default observer(function RestoreButton({
  goToAccessRecovery,
}: RestoreButtonProps) {
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    root: {},
    content: {
      backgroundColor: theme.palette.back,
      padding: 17,
      paddingVertical: 25,
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      ...theme.fontByWeight('700'),
      color: theme.palette.secondary,
      textTransform: 'uppercase',
      fontSize: 13,
      lineHeight: 15,
    },
  }));
  return (
    <SafeAreaView edges={['bottom']} mode="padding">
      <PressableOpacity style={styles.root} onPress={goToAccessRecovery}>
        <View style={styles.content}>
          <Text style={styles.text}>
            {strings['subscription.restoreButton']}
          </Text>
        </View>
      </PressableOpacity>
    </SafeAreaView>
  );
});
