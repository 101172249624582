import React, {useCallback} from 'react';
import {View, Text, Pressable, useWindowDimensions} from 'react-native';
import IconSvg from '../../assets/svg/colored/productsInfoIcon.svg';
import {sized} from '../../Svg';
import {useStyles, variance} from '../../styling';
import {useStrings} from '../../Root/hooks';
import {observer} from 'mobx-react-lite';
import {LG_BREAKPOINT, MD_BREAKPOINT} from '../../units/constants';
import CloseSvg from '../../assets/svg/colorless/close.svg';

const TopInfo = observer(() => {
  const [showBanner, setShowBanner] = React.useState(true);
  const strings = useStrings();
  const hideBanner = useCallback(() => {
    setShowBanner(false);
  }, []);
  const width = useWindowDimensions().width;
  const styles = useStyles((theme) => ({
    root: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      flexShrink: 0,
      paddingHorizontal: 20,
      alignItems: 'center',
      gap: 8,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          alignItems: 'center',
          gap: 20,
        },
      }),
    },

    icon: {
      width: 60,
      height: 60,
      flexShrink: 0,
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          width: 80,
          height: 80,
        },
      }),
    },
    title: {
      ...theme.fontByWeight('700'),
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          fontSize: 23,
        },
      }),
    },
    textWrapper: {
      alignItems: 'center',
      gap: 8,

      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          gap: 20,
        },
      }),
    },
  }));
  return (
    <View style={styles.root}>
      {width > MD_BREAKPOINT && <Icon style={styles.icon} />}
      <View style={styles.textWrapper}>
        {width > MD_BREAKPOINT && (
          <Header>{strings['ct-products.title']}</Header>
        )}
        {showBanner && (
          <DescWrapper onPress={hideBanner}>
            <Desc>{strings['ct-products.desc']}</Desc>
            {width <= MD_BREAKPOINT && (
              <View>
                <CloseIcon color={'#8E8E8E'} />
              </View>
            )}
          </DescWrapper>
        )}
      </View>
    </View>
  );
});
const Icon = sized(IconSvg, 60, 60);
const CloseIcon = sized(CloseSvg, 16, 16);
const Header = variance(Text)((theme) => ({
  root: {
    color: theme.palette.textPrimary,
    textAlign: 'center',
    fontSize: 14,
    ...theme.fontByWeight('700'),
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 48,
      },
    }),
  },
}));
const DescWrapper = variance(Pressable)((theme) => ({
  root: {
    backgroundColor: '#212121',
    flexDirection: 'row',
    borderRadius: 10,
    padding: 10,
    opacity: 0.8,
    marginTop: 10,
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        backgroundColor: 'transparent',
        color: theme.palette.darkText,
        padding: 0,
        opacity: 1,
      },
    }),
  },
}));
const Desc = variance(Text)((theme) => ({
  root: {
    color: theme.palette.white,

    fontSize: 14,
    padding: 5,
    lineHeight: 24,
    ...theme.fontByWeight('400'),
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        color: theme.palette.darkText,
        padding: 0,
        textAlign: 'center',
      },
    }),
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 18,
      },
    }),
  },
}));
export default TopInfo;
