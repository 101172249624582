import React from 'react';
import {observer} from 'mobx-react-lite';
import AddPromoModal from '../shared/components/AddPromoModal';
import {useForcedContext} from '../../context';
import {PurchaseScreenContext} from '../PurchaseScreenContext';

export default observer(() => {
  const {
    visibleEnterPromoModal,
    hideEnterPromoModal,
    safelyGoToAffiliate,
    getAffiliateIsPending,
  } = useForcedContext(PurchaseScreenContext);
  if (!visibleEnterPromoModal) {
    return null;
  }
  return (
    <AddPromoModal
      onDismiss={hideEnterPromoModal}
      safelyGoToAffiliate={safelyGoToAffiliate}
      getAffiliateIsPending={getAffiliateIsPending}
    />
  );
});
