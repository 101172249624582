import {Millisecond} from '../Time';
import selectAllWorkersById from './selectAllWorkersById';
import {STATE_TIMEOUT} from './constants';
import {DemonstrationDatabase} from './DemonstrationDatabase';
import getWorkerSettings from './getWorkerSettings';
import generateMiningSpeed from './generateMiningSpeed';
import {Client, Server, WebSocketTunnelState} from '../JsonRpc';
import {
  CryptoFarmClientCalls,
  CryptoFarmClientNotifications,
  CryptoFarmServerCalls,
  CryptoFarmServerNotifications,
  FarmId,
  FarmState,
} from '../ApiStore';
import {flow, observable, reaction, makeObservable} from 'mobx';
import {APP_WINDOW_ACTIVE, AppWindowState} from '../AppWindow';
import {now} from 'mobx-utils';
import {WorkerStateController} from './WorkerStateController';
import generateCpuStates from './generateCpuStates';
import {success} from '../fp';
import toSecond from '../Time/toSecond';
import {batchDisposers, Service} from '../structure';
import {ReadyState} from '../Connection';

export default class WorkerStateService
  implements WorkerStateController, Service
{
  private static readonly DEFAULT_PING_INTERVAL = (60 * 1000) as Millisecond;

  @observable private _notifyUntil = 0 as Millisecond;

  constructor(
    private readonly _root: {
      readonly db: DemonstrationDatabase;
      readonly appWindowState: AppWindowState;
    },
    private readonly _tunnelState: WebSocketTunnelState,
    private readonly _reverseClient: Client<
      CryptoFarmClientCalls,
      CryptoFarmClientNotifications
    >,
    private readonly _realClient: Client<
      CryptoFarmServerCalls,
      CryptoFarmServerNotifications
    >,
    private readonly _realServer: Server<
      CryptoFarmClientCalls,
      CryptoFarmClientNotifications
    >,
  ) {
    makeObservable(this);
  }

  private _getWorkersState(_now: Millisecond): FarmState[] {
    const db = this._root.db.state;
    const allWorkers = selectAllWorkersById(db.groups);
    return Object.entries(allWorkers).map(([id, worker]) => {
      const settings = getWorkerSettings(
        _now,
        worker.schedulers,
        worker.settings,
        worker.shouldIgnoreScheduleUntil,
      );
      return {
        timestamp: toSecond(_now),
        farm_id: 0 as FarmId,
        worker_id: id,
        cpu_states: generateCpuStates(worker.characteristics.cores),
        current_huge_pages: 1,
        max_huge_pages: 1,
        current_settings: settings,
        mining_speed: generateMiningSpeed(
          worker.characteristics.hashrate,
          settings.speed,
        ),
      };
    });
  }

  ping = flow(function* (this: WorkerStateService) {
    yield this._realClient.call('ping');
    this._notifyUntil = (Date.now() +
      WorkerStateService.DEFAULT_PING_INTERVAL) as Millisecond;
  });

  private _stubRealCall() {
    return this._realServer.call('farm_states', async (params, response) => {
      return response.respond(success(null));
    });
  }

  private _sendPingInterval() {
    return reaction(
      () => this._tunnelState.readyState,
      async (readyState) => {
        if (readyState === ReadyState.Open) {
          await this._reverseClient.call('set_ping_interval', {
            interval: WorkerStateService.DEFAULT_PING_INTERVAL / 1000,
          });
        }
      },
      {fireImmediately: true},
    );
  }

  private readonly _sendWorkerState = () =>
    reaction(
      () =>
        [
          this._tunnelState.readyState,
          this._root.appWindowState.status,
          this._notifyUntil,
          now(STATE_TIMEOUT) as Millisecond,
        ] as const,
      async ([readyState, status, notifyUntil, _now]) => {
        if (
          readyState === ReadyState.Open &&
          status === APP_WINDOW_ACTIVE &&
          _now <= notifyUntil
        ) {
          const states = this._getWorkersState(_now);
          const result_ = await this._reverseClient.call('farm_states', {
            states,
          });
          if (!result_.success) {
            console.warn('Failed to send the worker state');
          }
        }
      },
    );

  subscribe() {
    return batchDisposers(
      this._stubRealCall(),
      this._sendPingInterval(),
      this._sendWorkerState(),
    );
  }
}
