import {action, observable, makeObservable} from 'mobx';
import {Position, SliderStatus} from './PoolMinerActivator';
import {random} from 'lodash';
import {Animated} from 'react-native';
import {
  PUZZLE_HEIGHT_RATIO,
  IMAGES,
  PERMISSIBLE_DEVIATION,
  PUZZLE_SIZE,
  PuzzleState,
  DEFAULT_MODAL_OFFSET,
  MODAL_INSET,
  MAX_MODAL_WIDTH,
} from './PuzzleState';
import {bind} from '../fp';
import {Appearance} from '../Appearance';

export default class PuzzleStateImpl implements PuzzleState {
  @observable.ref translationX = new Animated.Value(0);
  @observable private _backgroundImageIndex = 0;
  @observable private _targetPosition?: Position;
  @observable private _sliderStatus = SliderStatus.Idle;

  constructor(
    private readonly _param: {
      onConfirm: () => Promise<unknown>;
      appearance: Appearance;
    },
  ) {
    makeObservable(this);
  }

  get puzzleWidth() {
    const windowWidth = this._param.appearance.theme.window.width;
    const isMaximum = windowWidth >= MAX_MODAL_WIDTH;
    if (isMaximum) {
      return MAX_MODAL_WIDTH - MODAL_INSET * 2;
    }
    return windowWidth - DEFAULT_MODAL_OFFSET * 2 - MODAL_INSET * 2;
  }

  get puzzleHeight() {
    return this.puzzleWidth * PUZZLE_HEIGHT_RATIO;
  }

  get targetPosition() {
    return this._targetPosition;
  }

  get sliderStatus() {
    return this._sliderStatus;
  }

  get backgroundImageIndex() {
    return this._backgroundImageIndex;
  }

  private _generateTargetPosition() {
    return {
      x: random(this.puzzleWidth / 2, this.puzzleWidth - PUZZLE_SIZE.width),
      y: random(0, this.puzzleHeight - PUZZLE_SIZE.height),
    };
  }

  private static _verifyTwoPosition(
    targetPosition: Position,
    candidatePosition: Position,
  ) {
    const diff = Math.abs(targetPosition.x - candidatePosition.x);
    return diff <= PERMISSIBLE_DEVIATION;
  }

  @action changeStateStatus = bind((newSliderStatus: SliderStatus) => {
    this._sliderStatus = newSliderStatus;
  }, this);

  @action refresh = bind(() => {
    this._backgroundImageIndex = random(IMAGES.length - 1);
    this._targetPosition = this._generateTargetPosition();
  }, this);

  @action verifyPosition = bind((candidatePosition: Position) => {
    if (!this.targetPosition) {
      return null;
    }
    const verified = PuzzleStateImpl._verifyTwoPosition(
      this.targetPosition,
      candidatePosition,
    );
    if (verified) {
      this._param.onConfirm();
    } else {
      this._sliderStatus = SliderStatus.Error;
      setTimeout(() => {
        this.refresh();
        this._sliderStatus = SliderStatus.Idle;
      }, 1000);
    }
  }, this);
}
