import {Millisecond} from '../utils/time';
import {Bitcoin} from './DemonstrationDatabase';
import dayjs from 'dayjs';
import {sum} from 'lodash';

export default (
  lastWorkerStatsUpdate: Millisecond,
  updateInterval: Millisecond,
  miningResults: readonly Bitcoin[],
): {
  balance: Bitcoin;
  results: Bitcoin[];
} => {
  const boundary = dayjs(lastWorkerStatsUpdate)
    .subtract(3, 'month')
    .toDate()
    .getTime();
  const maxLength = Math.floor(
    (lastWorkerStatsUpdate - boundary) / updateInterval,
  );
  const index = miningResults.length - maxLength;
  return {
    balance: sum(miningResults.slice(0, index)),
    results: miningResults.slice(index),
  };
};
