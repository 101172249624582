import {ObjectEnumerator} from './ObjectEnumerator';
import {InfiniteGenerator} from './InfiniteGenerator';

export default class WeakObjectEnumeratorImpl<T extends object, N>
  implements ObjectEnumerator<T, N>
{
  private readonly _currentMembers = new WeakMap<T, N>();

  constructor(private readonly _upcomingValues: InfiniteGenerator<N>) {}

  getOrAssign(member: T): N {
    if (this._currentMembers.has(member)) {
      return this._currentMembers.get(member)!;
    } else {
      const value = this._upcomingValues.next();
      this._currentMembers.set(member, value);
      return value;
    }
  }
}
