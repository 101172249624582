import {
  FIRST_DEMO_MINER_ADDED,
  FIRST_EVENT_MADE,
  FIRST_GET_MINER_ID,
  FIRST_MINER_ADDED,
  FIRST_PURCHASE_MADE,
} from './AppAnalytics';

export const APP_EVENT_MAP = new Map([
  [FIRST_DEMO_MINER_ADDED, 'mdemo'],
  [FIRST_MINER_ADDED, 'mreal'],
  [FIRST_GET_MINER_ID, 'mgetid'],
  [FIRST_PURCHASE_MADE, 'mpors'],
  [FIRST_EVENT_MADE, 'mintrs'],
]);
