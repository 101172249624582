import {
  cacheDirectory,
  EncodingType,
  writeAsStringAsync,
} from 'expo-file-system';
import {shareAsync} from 'expo-sharing';

import {Either, error, success} from '../fp';
import {BaseFileSystemImpl} from './BaseFileSystemImpl';
import {FileSystem} from './FileSystem';
import {GlobalError} from '../Error';

export default class IosFileSystemImpl
  extends BaseFileSystemImpl
  implements FileSystem
{
  async save(
    content: string,
    filename: string,
    mimeType: string,
  ): Promise<Either<void, GlobalError>> {
    try {
      const uri = cacheDirectory + `/${filename}`;
      await writeAsStringAsync(uri, content, {encoding: EncodingType.UTF8});
      await shareAsync(uri, {mimeType});
      return success(undefined);
    } catch (raw) {
      return error(this._createUnknownError(raw));
    }
  }
}
