import React from 'react';
import {observer} from 'mobx-react-lite';
import {Text} from 'react-native';
import {createStylesHook, variance} from '../../../../../styling';
import {View} from 'react-native';
import SlotTitle from '../../../../common/SlotTitle';
import PoolMinerSpeed from '../../../../common/PoolMinerSpeed';
import {WorkerEntity} from '../../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {ReadonlyDeep} from 'type-fest';
import getWorkerDescription from '../../../../getters/getWorkerDescription';
import {Mode} from '../../../../../DashboardMode';
import {DESKTOP_BREAKPOINT} from '../../../../../units/constants';

export interface PoolMinerCharacteristicsProps {
  worker: ReadonlyDeep<WorkerEntity>;
  warning: boolean;
  disabled: boolean;
}

export default observer(
  ({worker, warning, disabled}: PoolMinerCharacteristicsProps) => {
    const styles = useStyles();
    return (
      <View style={styles.root}>
        <SlotTitle
          containerStyle={styles.titleView}
          warning={warning}
          disabled={disabled}
          numberOfLines={1}
          mode={Mode.Regular}
          worker={worker}
        />
        <View style={styles.content}>
          <Description numberOfLines={3} style={styles.description}>
            {getWorkerDescription(worker)}
          </Description>
          <PoolMinerSpeed
            style={styles.speed}
            expired={Boolean(worker.expired)}
            speed={worker.pool_miner_config?.hash_rate}
          />
        </View>
      </View>
    );
  },
);

const Description = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    fontSize: 11,
    lineHeight: 15,
    flexShrink: 1,
  },
}));

const useStyles = createStylesHook((theme) => ({
  root: {
    flex: 1,
  },
  titleView: {
    paddingBottom: 10,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        ...theme.fontByWeight('700'),
        fontSize: 14,
      },
    }),
  },
  description: {
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        ...theme.fontByWeight('400'),
        fontSize: 12,
        lineHeight: 22,
        color: theme.palette.darkText,
      },
    }),
  },
  content: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  speed: {
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        display: 'none',
      },
    }),
  },
}));
