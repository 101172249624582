import {FarmId} from '../units';
import {LogGroup, LogGroupMessageMap} from './LogSettings';
import {Opaque} from 'type-fest';
import {ISODateString} from '../../Time';
import {WorkerId} from './units';

export const LOG_ID = Symbol();
export type LogId = Opaque<string, typeof LOG_ID>;

export interface LogEntity<
  Group extends LogGroup = LogGroup,
  Message extends LogGroupMessageMap[Group] = LogGroupMessageMap[Group],
> {
  id: LogId;
  timestamp: ISODateString;
  farm_id: FarmId;
  level: LogLevel;
  group_id: Group;
  message_id: Message;
  worker_id?: WorkerId;
  title: string;
  body: string;
}

export enum LogLevel {
  Debug = 10,
  Info = 20,
  Warning = 30,
  Error = 40,
  Critical = 50,
}
