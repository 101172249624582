import {ConnectionManager, RECONNECT} from './ConnectionManager';
import {
  batchDisposers,
  BusImpl,
  BusSource,
  Disposer,
  RouterImpl,
  Service,
} from '../structure';
import CannonWatchService from './CannonWatchService';
import {FAIL, SUCCESS, TRY} from './WatchTarget';
import PeriodicWatchService from './PeriodicWatchService';
import SchedulerImpl from './SchedulerImpl';
import getNextRetryPeriod from './getNextRetryPeriod';
import {Millisecond} from '../Time';
import {bind} from '../fp';
import {
  SequenceNumberGeneratorImpl,
  WeakObjectEnumeratorImpl,
} from '../ObjectEnumerator';
import WebSocketCarouselImpl from '../JsonRpc/WebSocketCarouselImpl';
import {computed, makeObservable} from 'mobx';
import {ReadyState} from '../Connection';
import ObservableWebSocketStateImpl from '../JsonRpc/ObservableWebSocketStateImpl';

export default class ConnectionManagerService
  implements ConnectionManager, Service
{
  constructor(private readonly _createWebSocket: CreateWebSocket) {
    makeObservable(this);
  }

  private readonly _control = new RouterImpl<{
    [TRY]: void;
    [FAIL]: void;
    [SUCCESS]: void;
    [RECONNECT]: WebSocket;
  }>();
  private readonly _target = {control: this._control, monitor: this._control};
  private readonly _cannon = new CannonWatchService(this._target);
  private readonly _scheduler = new SchedulerImpl(getNextRetryPeriod);
  private readonly _periodic = new PeriodicWatchService(
    this._target,
    this._scheduler,
  );
  private readonly _carousel = new WebSocketCarouselImpl();
  private _connectionIds = new SequenceNumberGeneratorImpl();
  private _webSocketEnumerator = new WeakObjectEnumeratorImpl<
    WebSocket,
    number
  >(this._connectionIds);

  get connectionId() {
    return (
      this._carousel.current &&
      this._webSocketEnumerator.getOrAssign(this._carousel.current.ws)
    );
  }

  @computed
  get isConnected() {
    return this._carousel.current?.readyState === ReadyState.Open;
  }

  private readonly _connectionIdUpdates = new BusImpl<number>();

  get connectionIdUpdates(): BusSource<number> {
    return this._connectionIdUpdates;
  }

  private async _createBoundSocket() {
    const socket = await this._createWebSocket();
    const onOpen = () => {
      this._control.send(SUCCESS, undefined);
      socket.removeEventListener('open', onOpen);
    };
    socket.addEventListener('open', onOpen);
    const onClose = () => {
      if (!this._isDirectConnection) {
        this._control.send(FAIL, undefined);
      }
      socket.removeEventListener('close', onClose);
    };
    socket.addEventListener('close', onClose);
    return socket;
  }

  private _creatingSocket = false;

  private readonly _onTry = async () => {
    if (
      this._carousel.current?.readyState === WebSocket.CONNECTING ||
      this._carousel.current?.readyState === WebSocket.OPEN ||
      this._creatingSocket
    ) {
      return;
    }
    try {
      this._creatingSocket = true;
      const socket = await this._createBoundSocket();
      const id = this._webSocketEnumerator.getOrAssign(socket);
      this._connectionIdUpdates.send(id);
      this._carousel.next(new ObservableWebSocketStateImpl(socket));
      this._creatingSocket = false;
    } catch (ignore) {
      this._creatingSocket = false;
      this._control.send(FAIL, undefined);
    }
  };

  private _isDirectConnection = false;

  private readonly _onSuccess = () => {
    if (!this._isDirectConnection) {
      this._control.send(RECONNECT, this._carousel.current!.ws);
    }
    this._isDirectConnection = false;
  };

  try = bind(() => {
    this._scheduler.restart();
  }, this);

  connect() {
    this._isDirectConnection = true;
    this._carousel.current?.close();
    this._control.send(TRY, undefined);
    return new Promise<WebSocket>((resolve) =>
      this._control.once(SUCCESS, () => resolve(this._carousel.current!.ws)),
    );
  }

  get sockets() {
    return this._control;
  }

  private _watchDisposer = (() => {}) as Disposer;

  private _switchToCannon() {
    this._watchDisposer();
    this._watchDisposer = this._cannon.subscribe();
    this._control.send(TRY, undefined);
  }

  private _switchToPeriodic() {
    this._watchDisposer();
    this._watchDisposer = this._periodic.subscribe();
    if (this._carousel.current?.readyState === WebSocket.OPEN) {
      this._scheduler.stop();
    } else {
      this._scheduler.restart();
    }
  }

  private _cannonDisposer = (() => {}) as Disposer;

  async fireCannon(duration: Millisecond) {
    return new Promise<void>((resolve) => {
      this._cannonDisposer();
      this._switchToCannon();
      let id: ReturnType<typeof setTimeout>;
      const reset = (() => {
        this._switchToPeriodic();
        clearTimeout(id);
        resolve();
      }) as Disposer;
      id = setTimeout(reset, duration);
      const disposer = this._control.once(SUCCESS, reset);
      this._cannonDisposer = batchDisposers(disposer, reset);
    });
  }

  disconnect() {
    this._watchDisposer();
    this._watchDisposer = (() => {}) as Disposer;
    this._carousel.current?.close();
  }

  subscribe() {
    this._switchToPeriodic();
    return batchDisposers(
      this._control.listen(TRY, this._onTry),
      this._control.listen(SUCCESS, this._onSuccess),
      this._scheduler.subscribe(),
      (() => this._watchDisposer()) as Disposer,
    );
  }
}

export interface CreateWebSocket {
  (): Promise<WebSocket>;
}
