import {observable, makeObservable, action} from 'mobx';
import {ErrorRepository} from '../ErrorRepository';
import {ProductsProvider, Product} from './Product';
import {ConnectedClient} from '../ContextClient';
import {error} from '../fp';
import {SERVER_ERROR} from '../Error';
import ProductsImageHelper from './ProductsImageHelper';

export default class ProductsProviderImpl implements ProductsProvider {
  @observable private _products: Product[] | undefined;
  get products() {
    return this._products;
  }
  constructor(
    private readonly _root: {
      readonly connectedClient: ConnectedClient;
      readonly errorRepository: ErrorRepository;
    },
  ) {
    makeObservable(this);
  }
  @action async fetchProducts() {
    const res = await this._root.connectedClient.call('get_products');

    if (!res.success) {
      return error(
        this._root.errorRepository.create({
          kind: SERVER_ERROR,
          raw: res.left,
        }),
      );
    }
    const items: Product[] = res.right.items.map((item) => {
      return {
        ...item,
        img: ProductsImageHelper.getImage(item.img),
      };
    });
    this._products = items;
  }
}
