import React, {PropsWithChildren} from 'react';
import {StyleProp, View, TouchableOpacity, ViewStyle} from 'react-native';
import {observer} from 'mobx-react-lite';
import {createStylesHook} from '../../styling';
import {Container} from '../../components/Grid';

interface WorkerContainerProps {
  warning: boolean;
  disabled: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  isTouchable?: boolean;
  onPress?: () => void;
}

export default observer(
  ({
    warning,
    disabled,
    onPress,
    containerStyle,
    style,
    isTouchable,
    children,
  }: PropsWithChildren<WorkerContainerProps>) => {
    const styles = useStyles();
    return (
      <Container style={[styles.background, containerStyle]}>
        {isTouchable ? (
          <TouchableOpacity
            onPress={onPress}
            style={[
              styles.root,
              warning && styles.rootWarning,
              disabled && styles.rootDisabled,
              style,
            ]}>
            {children}
          </TouchableOpacity>
        ) : (
          <View
            style={[
              styles.root,
              warning && styles.rootWarning,
              disabled && styles.rootDisabled,
              style,
            ]}>
            {children}
          </View>
        )}
      </Container>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  background: {
    backgroundColor: theme.palette.background,
  },
  root: {
    flex: 1,
    backgroundColor: theme.select(
      theme.palette.background,
      theme.palette.backdrop,
    ),
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: theme.palette.border,
    paddingVertical: 21,
    paddingLeft: 37,
    paddingRight: 35,
  },
  rootWarning: {
    backgroundColor: theme
      .chroma(theme.palette.error)
      .alpha(theme.select(0.08, 0.2))
      .hex(),
  },
  rootDisabled: {
    backgroundColor: theme.select(
      theme.palette.backdrop,
      theme.palette.background,
    ),
  },
}));
