import {
  EventType,
  HeadlessLocalNotifications,
  LocalNotificationId,
} from './HeadlessLocalNotifications';
import {Either, error, success} from '../fp';
import {MessageContext} from '../Messaging';
import {Json} from '../Json';
import BaseHeadlessLocalNotificationsImpl from './BaseHeadlessLocalNotificationsImpl';
import {Analytics} from '../Analytics';
import {Location} from '../Location';
import {LocalNotificationData} from './LocalNotificationData';
import NotificationDisplayMethodError from './NotificationDisplayMethodError';
import {isNotificationApiSupported} from './isNotificationApiSupported';

declare var Notification: {
  prototype: Notification;
  new (title: string, options?: NotificationOptions): Notification;
  readonly maxActions: number;
  readonly permission: NotificationPermission;
};

export default abstract class BaseWebHeadlessLocalNotificationsImpl
  extends BaseHeadlessLocalNotificationsImpl
  implements HeadlessLocalNotifications
{
  protected constructor(
    protected readonly _root: {
      readonly json: Json;
      readonly analytics: Analytics;
      readonly location: Location;
    },
  ) {
    super(_root);
  }

  protected _getNotificationArguments(
    context: MessageContext,
    data: LocalNotificationData,
    areActionsSupported: boolean,
  ): [string, NotificationOptions?] {
    const {message, meta} = context;
    return [
      message.title,
      {
        data: data,
        body: message.body,
        actions: areActionsSupported
          ? message.actions?.map((_) => ({
              action: _.action,
              title: _.title,
              icon: _.icon,
            }))
          : undefined,
        icon: message.icon,
        badge: require('../assets/ic_small_icon.png'),
        image: message.image,
        tag: message.tags ?? meta.id,
        renotify: !!message.tags,
        requireInteraction: message.requireInteraction,
        silent: !message.vibrate && !message.sound,
        vibrate: message.vibrate ? [300, 500] : undefined,
      },
    ];
  }

  protected abstract _getRegistration(): Promise<ServiceWorkerRegistration>;

  async scheduleNotification(
    context: MessageContext,
  ): Promise<Either<void, unknown>> {
    const data_ = await this._wrapContext(context);
    if (!data_.success) {
      return data_;
    }
    if (
      !isNotificationApiSupported() ||
      Notification.permission !== 'granted'
    ) {
      return error(new Error('Notifications permission have not been granted'));
    }
    try {
      const registration = await this._getRegistration();
      const args = this._getNotificationArguments(context, data_.right, true);
      await registration.showNotification(...args);
      const [, options] = args;
      return this.handle({
        id: options?.tag as LocalNotificationId | undefined,
        type: EventType.Delivered,
        data: data_.right,
      });
    } catch (raw) {
      return error(new NotificationDisplayMethodError(raw as Error));
    }
  }

  async cancelNotification(
    id: LocalNotificationId,
  ): Promise<Either<void, unknown>> {
    try {
      const registration = await this._getRegistration();
      const notifications = await registration.getNotifications({tag: id});
      for (const notification of notifications) {
        notification.close();
      }
    } catch (raw) {
      return error(raw);
    }
    return success(undefined);
  }

  async hideNotificationDrawer(): Promise<Either<void, unknown>> {
    return success(undefined);
  }
}
