import React from 'react';
import {observer} from 'mobx-react-lite';
import ActionModal from '../modals/ActionModal';
import AddWorkerSvg from '../assets/svg/circled/addWorker.svg';
import {ButtonVariant} from '../components';
import {Text, View} from 'react-native';
import {AccountType} from '../Auth';
import {createStylesHook} from '../styling';
import {useRoot, useStrings} from '../Root/hooks';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {expr} from 'mobx-utils';
import getWorkerDescription from '../DashboardScreen/getters/getWorkerDescription';

export interface ConfirmAddWorkerModalProps {
  onSubmit: () => void;
  onClose: () => void;
  workerInfo?: WorkerEntity;
}

export default observer(
  ({onClose, onSubmit, workerInfo}: ConfirmAddWorkerModalProps) => {
    const {auth} = useRoot();
    const styles = useStyles();
    const strings = useStrings();
    const shownEmail = auth.accountType === AccountType.Permanent && auth.email;
    const specs = expr(() =>
      workerInfo ? getWorkerDescription(workerInfo) : '-',
    );
    return (
      <ActionModal
        Icon={AddWorkerSvg}
        title={strings['modal.confirmAddWorker.title']}
        buttons={[
          {
            text: strings['action.cancel'],
            onPress: onClose,
          },
          {
            text: strings['action.add'],
            onPress: onSubmit,
            variant: ButtonVariant.Highlighted,
          },
        ]}
        onDismiss={onClose}>
        <View style={styles.root}>
          <View style={styles.list}>
            <View style={[styles.card, !shownEmail && styles.lastCard]}>
              <Text style={styles.minerTitle}>
                <Text style={styles.cardLabel}>
                  {strings['modal.confirmAddWorker.name']}:{' '}
                </Text>
                {workerInfo?.name}
              </Text>
              <Text style={styles.minerDesc}>{specs}</Text>
            </View>
            {shownEmail && (
              <View style={[styles.card, styles.cardInverse, styles.lastCard]}>
                <Text style={styles.minerTitle}>
                  <Text style={styles.cardLabel}>
                    {strings['modal.confirmAddWorker.farmID']}:{' '}
                  </Text>
                  {auth.accountId}
                </Text>
                <Text style={styles.username}>{auth.email}</Text>
              </View>
            )}
          </View>
        </View>
      </ActionModal>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  root: {
    width: '100%',
  },
  list: {
    marginBottom: 1,
    borderWidth: 1,
    borderColor: theme.palette.border,
  },
  card: {
    borderBottomWidth: 1,
    borderColor: theme.palette.border,
    padding: 15,
  },
  cardInverse: {
    backgroundColor: theme.palette.background,
  },
  lastCard: {
    borderBottomWidth: 0,
  },
  minerTitle: {
    fontSize: 12,
    lineHeight: 22,
    color: theme.palette.textPrimary,
  },
  cardLabel: {
    ...theme.fontByWeight('700'),
    fontSize: 12,
    lineHeight: 22,
  },
  minerDesc: {
    ...theme.fontByWeight('400'),
    fontSize: 12,
    lineHeight: 22,
    color: theme.palette.textSecondary,
  },
  username: {
    ...theme.fontByWeight('400'),
    fontSize: 12,
    lineHeight: 22,
    color: theme.palette.textSecondary,
  },
  helperText: {
    ...theme.fontByWeight('400'),
    fontSize: 12,
    lineHeight: 22,
    color: theme.palette.textSecondary,
  },
  switchFarmButton: {
    borderWidth: 0,
    backgroundColor: theme.palette.background,
  },
}));
