import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../../styling';
import {View, Text} from 'react-native';
import {useStrings} from '../../Root/hooks';
import {ApiModeBadge} from '../../components';

export default observer(function CurrentPlanIsDemoSection() {
  const strings = useStrings();
  return (
    <RootView>
      <TitleText>{strings['subscription.currentPlan']}</TitleText>
      <ApiModeBadge />
    </RootView>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    height: 54,
    paddingVertical: 5,
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#212121',
    ...theme.mediaQuery({
      1000: {
        marginVertical: 20,
        borderRadius: 10,
      },
    }),
  },
}));

const TitleText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('bold'),
    textTransform: 'uppercase',
    color: '#ffffff',
  },
}));
