import {Millisecond, MINUTE} from '../utils/time';
import {ReadonlyDeep} from 'type-fest';
import {Scheduler, WeekMinute} from './DemonstrationDatabase';
import dayjs from 'dayjs';

export default (
  moment: Millisecond,
  schedulers: ReadonlyDeep<Scheduler[]>,
): Scheduler | undefined => {
  if (schedulers.length === 0) {
    return undefined;
  }
  const startOfWeek = dayjs(moment).startOf('isoWeek').toDate().getTime();
  const weekMinute: WeekMinute = (moment - startOfWeek) / MINUTE;
  return (
    schedulers.find(
      (schedule) => weekMinute >= schedule.from && weekMinute < schedule.to,
    ) ?? generateZeroScheduler(weekMinute)
  );
};

function generateZeroScheduler(_: WeekMinute): Scheduler {
  const hour = 60;
  const from = Math.floor(_ / hour) * hour;
  const to = from + hour;
  return {from, to, settings: {speed: 0}};
}
