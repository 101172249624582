import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import ActionModal from '../../modals/ActionModal';
import {ButtonVariant, CompositeFanIcon} from '../../components';
import {useRoot, useStrings} from '../../Root/hooks';
import {useStyles, useTheme} from '../../styling';
import {sized} from '../../Svg';
import CalendarSvg from '../../assets/svg/colorless/calendar.svg';
import Markdown from '../../components/Markdown';

export default observer(() => {
  const strings = useStrings();
  const {dashboardStore, navigationContainer} = useRoot();
  const styles = useStyles((theme) => ({
    text: {
      ...theme.fontByWeight('400'),
      fontSize: 14,
      lineHeight: 22,
      textAlign: 'center',
      letterSpacing: 0.04,
      color: theme.palette.textSecondary,
      paddingVertical: 0,
      paddingHorizontal: 15,
    },
  }));
  const conf = dashboardStore.poolMinerSpeedWarningModal;
  const dismiss = dashboardStore.closePoolMinerSpeedWarningModal;
  const goToScheduler = useCallback(() => {
    if (!conf.visible) {
      return;
    }
    navigationContainer.ref?.navigate('Schedule', {
      workerIds: [conf.workerId],
    });
    dismiss();
  }, [conf, dismiss, navigationContainer]);
  if (!conf.visible) {
    return null;
  }
  return (
    <ActionModal
      buttons={[
        {
          text: strings['action.cancel'],
          onPress: dismiss,
        },
        {
          text: strings['common.scheduler'],
          onPress: goToScheduler,
          variant: ButtonVariant.Highlighted,
        },
      ]}
      Icon={Icon}
      title={strings['modal.poolMinerSpeedWarning.title']}
      onDismiss={dismiss}>
      <Markdown
        styles={{
          paragraph: styles.text,
        }}>
        {strings['modal.poolMinerSpeedWarning.desc']}
      </Markdown>
    </ActionModal>
  );
});

const Icon = observer(() => {
  const theme = useTheme();
  return (
    <CompositeFanIcon
      circleColor={theme.palette.success}
      style={{}}
      Icon={CalendarIcon}
    />
  );
});

const CalendarIcon = sized(CalendarSvg, 23);
