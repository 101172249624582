import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {SimpleWorkerList} from './SimpleWorkerList';
import {useRoot} from '../../Root/hooks';
import {SectionWorkerList} from './SectionWorkerList';
import {Mode} from '../../DashboardMode';
import useTutorialEventsListener from './useTutorialEventsListener';

export default observer(function WorkerList() {
  const {dashboardModeService, interactiveTutorial} = useRoot();
  const {listRef, sectionListRef, ...rest} = useTutorialEventsListener();

  const renderList = useCallback(() => {
    const params = {
      hideRefreshControl: interactiveTutorial.isShown,
    };
    return dashboardModeService.mode === Mode.Simple ? (
      <SimpleWorkerList ref={listRef} {...rest} {...params} />
    ) : (
      <SectionWorkerList ref={sectionListRef} {...rest} {...params} />
    );
  }, [
    dashboardModeService,
    interactiveTutorial,
    listRef,
    rest,
    sectionListRef,
  ]);
  return renderList();
});
