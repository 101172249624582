import React from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useStyles} from '../styling';
import {expr} from 'mobx-utils';
import WorkerSlotHeader from './WorkerSlotHeader';
import {useWorkerState} from '../WorkerStateRegistry';
import isWorkerDisabled from '../DashboardScreen/checkers/isWorkerDisabled';
import isWorkerWarning from '../DashboardScreen/checkers/isWorkerWarning';
import WorkerSlotSpecs from './WorkerSlotSpecs';
import {WorkerSpeedControl} from '../DashboardScreen/common/WorkerSpeedControl';
import {CoreTable} from '../DashboardScreen/WorkerList/SectionWorkerList/DetailSlot/CoreTable';
import WorkerExtra from './WorkerExtra';
import isWorkerOffline from '../DashboardScreen/checkers/isWorkerOffline';
import {SlotMode} from '../DashboardScreen/types';

export interface WorkerSlotProps {
  worker: ReadonlyDeep<WorkerEntity>;
  onDeleted: () => void;
}

export default observer(({worker, onDeleted}: WorkerSlotProps) => {
  const state = useWorkerState(worker.id);
  const disabled = expr(
    () => isWorkerDisabled(worker) || isWorkerOffline(state),
  );
  const warning = expr(() => isWorkerWarning(state));
  const styles = useStyles((theme) => ({
    background: {
      backgroundColor: theme.palette.background,
    },
    root: {
      backgroundColor: theme.palette.background,
    },
    rootWarning: {
      backgroundColor: theme
        .chroma(theme.palette.error)
        .alpha(theme.select(0.08, 0.2))
        .hex(),
    },
    rootDisabled: {
      backgroundColor: theme.palette.backdrop,
    },
    content: {
      padding: 25,
      paddingBottom: 15,
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.border,
    },
    specs: {
      marginBottom: 15,
    },
    speed: {
      marginBottom: 15,
    },
    table: {
      marginBottom: 10,
    },
  }));
  return (
    <View style={styles.background}>
      <View
        style={[
          styles.root,
          warning && styles.rootWarning,
          disabled && styles.rootDisabled,
        ]}>
        <WorkerSlotHeader
          warning={warning}
          disabled={disabled}
          onDeleted={onDeleted}
          worker={worker}
        />
        <View style={styles.content}>
          <View style={styles.specs}>
            <WorkerSlotSpecs
              disabled={disabled}
              warning={warning}
              worker={worker}
            />
          </View>
          <View style={styles.speed}>
            <WorkerSpeedControl slotMode={SlotMode.Detail} worker={worker} />
          </View>
          <View style={styles.table}>
            <CoreTable
              cpus={state?.cpu_states}
              disabled={disabled}
              warning={warning}
            />
          </View>
          <WorkerExtra worker={worker} />
        </View>
      </View>
    </View>
  );
});
