import React from 'react';
import {BottomTabBindingProps} from './BottomTabBindingProps';
import {observer} from 'mobx-react-lite';
import {useCallback} from 'react';
import {AuthRequestScreen} from '../../AuthRequestScreen';

export type WithdrawBindingProps = BottomTabBindingProps<'Withdraw'>;

export default observer((props: WithdrawBindingProps) => {
  const {navigation} = props;
  const goToAuth = useCallback(() => navigation.navigate('Auth'), [navigation]);
  return <AuthRequestScreen goToAuth={goToAuth} />;
});
