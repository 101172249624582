import React from 'react';
import {observer} from 'mobx-react-lite';
import SuccessPuzzleSvg from '../assets/svg/successPuzzle.svg';
import {sized} from '../Svg';
import {SliderStatus} from './PoolMinerActivator';
import {useRoot} from '../Root/hooks';
import {PUZZLE_SIZE} from './PuzzleState';

export default observer(() => {
  const {
    poolMinerActivator: {
      puzzle: {targetPosition, sliderStatus},
    },
  } = useRoot();
  if (!targetPosition || sliderStatus === SliderStatus.Confirming) {
    return null;
  }
  const left = targetPosition.x;
  const top = targetPosition.y;
  return (
    <Puzzle
      style={{
        position: 'absolute',
        left,
        top,
      }}
    />
  );
});

const Puzzle = sized(SuccessPuzzleSvg, PUZZLE_SIZE.width, PUZZLE_SIZE.height);
