export enum SlotMode {
  Detail,
  Simple,
}

export enum WorkerOrder {
  Default,
  AverageHashrate,
  MinedTotal,
  Name,
  Status,
}

export interface GroupOption {
  slotsShown: number;
  workerOrder: WorkerOrder;
}
