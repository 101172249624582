import React from 'react';
import {ButtonVariant} from '../components';
import {observer} from 'mobx-react-lite';
import ActionModal from '../modals/ActionModal';
import AddWorkerSuccessSvg from '../assets/svg/circled/addWorkerSuccess.svg';
import {useStrings} from '../Root/hooks';

export interface SuccessfullyAddedModalProps {
  onSubmit: () => void;
  onClose: () => void;
}

export default observer((props: SuccessfullyAddedModalProps) => {
  const strings = useStrings();
  return (
    <ActionModal
      Icon={AddWorkerSuccessSvg}
      title={strings['modal.successfullyAddedModal.title']}
      buttons={[
        {
          text: strings['action.done'],
          onPress: props.onSubmit,
          variant: ButtonVariant.Highlighted,
        },
      ]}
      onDismiss={props.onClose}>
      {strings['modal.successfullyAddedModal.description']}
    </ActionModal>
  );
});
