import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useStyles, variance} from '../styling';
import Modal from '../modals/BaseModal';
import {
  StyleSheet,
  Image,
  View,
  Text,
  ImageProps,
  StyleProp,
  ImageStyle,
  Platform,
} from 'react-native';
import {Button, ButtonVariant, PressableOpacity} from '../components';
import AdvertSplashClose from './AdvertSplashClose';
import {useRoot} from '../Root/hooks';
import {AdInfoAction, AdInfoItem} from '../Advert';
import {SvgProps, SvgUri} from 'react-native-svg';
import {Uri} from '../units';
import Markdown from '../components/Markdown';

export type AdvertSplashScreen = {
  onButtonActionPress: (action: AdInfoAction) => void;
  onClose: () => void;
  onCrossClose: () => void;
  seconds: number;
  advertItem: AdInfoItem;
};

export const ADVERT_SPLASH_DESKTOP_BREAKPOINT = 1280;

export default observer(function AdvertSplashScreen({
  onButtonActionPress,
  onClose,
  onCrossClose,
  seconds,
  advertItem,
}: AdvertSplashScreen) {
  const {advertHelper} = useRoot();
  const {actions, title, body, image} = advertItem;
  const translatedTitle = advertHelper.translate(title);
  const translatedBody = advertHelper.translate(body);
  const actionList = useMemo(
    () =>
      actions.map((action, index) => {
        return (
          <PressableOpacity
            style={actions.length > index + 1 && styles.offset}
            key={index}
            onPress={() => onButtonActionPress(action)}>
            <Button
              Icon={
                action.icon
                  ? (_) => <ButtonIcon {..._} uri={action.icon as Uri} />
                  : undefined
              }
              variant={ButtonVariant.Highlighted}>
              {advertHelper.translate(action.title)}
            </Button>
          </PressableOpacity>
        );
      }),
    [actions, advertHelper, onButtonActionPress],
  );
  const _styles = useStyles((theme) => ({
    paragraph: {
      ...theme.fontByWeight('400'),
      fontSize: 14,
      lineHeight: 22,
      marginBottom: 25,
      textAlign: 'center',
      color: theme.palette.textSecondary,
    },
  }));
  return (
    <Modal contentStyle={styles.content} onDismiss={onClose} hideCloseIcon>
      <AdvertSplashClose seconds={seconds} onClose={onCrossClose} />
      {image !== undefined && (
        <Picture source={{uri: image}} resizeMode="cover" />
      )}
      <Body>
        {translatedTitle !== undefined && <Title>{translatedTitle}</Title>}
        {translatedBody !== undefined && (
          <Markdown styles={{paragraph: _styles.paragraph}}>
            {translatedBody}
          </Markdown>
        )}
        <View>{actionList}</View>
      </Body>
    </Modal>
  );
});

// TODO: SHOULD BE MIGRATED TO EXPO-IMAGE
const ButtonIcon = (
  props: SvgProps & {
    uri: Uri;
  },
) => {
  const adoptSvgProps = (_: SvgProps): ImageProps => ({
    style: StyleSheet.flatten([
      _.style as StyleProp<ImageStyle>,
      styles.buttonIcon,
    ]),
  });
  if (Platform.OS === 'web') {
    return <Image {...adoptSvgProps(props)} source={{uri: props.uri}} />;
  }
  return <SvgUri {...props} style={[props.style, styles.buttonIcon]} />;
};

const styles = StyleSheet.create({
  content: {
    padding: 0,
    overflow: 'visible',
    maxWidth: 335,
  },
  offset: {
    marginBottom: 15,
  },
  buttonIcon: {
    width: 20,
    height: 20,
  },
});

const Picture = variance(Image)(() => ({
  root: {
    width: '100%',
    borderTopRightRadius: 5,
    borderTopLeftRadius: 4,
    height: 167,
  },
}));

const Body = variance(View)(() => ({
  root: {
    paddingTop: 20,
    paddingHorizontal: 15,
    paddingBottom: 15,
  },
}));

const Title = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 22,
    lineHeight: 32,
    marginBottom: 10,
    textAlign: 'center',
    color: theme.palette.textPrimary,
  },
}));
