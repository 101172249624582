import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {QRCodeScannerScreen} from '../../QRCodeScannerScreen';
import {CommonActions} from '@react-navigation/routers';
import {DASHBOARD} from './constants';

export interface QRCodeScannerBindingProps
  extends RootStackBindingProps<'QRCodeScanner'> {}

export default observer((props: QRCodeScannerBindingProps) => {
  const {navigation} = props;
  const goToDashboard = useCallback(
    () => navigation.dispatch(CommonActions.reset(DASHBOARD)),
    [navigation],
  );
  return <QRCodeScannerScreen goToDashboard={goToDashboard} />;
});
