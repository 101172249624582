import {FAIL, SUCCESS, TRY, WatchTarget} from './WatchTarget';
import {batchDisposers, Service} from '../structure';
import {Scheduler, TICK} from './Scheduler';

export default class PeriodicWatchService implements Service {
  constructor(
    private readonly _target: WatchTarget,
    private readonly _scheduler: Scheduler,
  ) {}

  private readonly _onTick = () => {
    this._target.control.send(TRY, undefined);
  };

  private readonly _onSuccess = () => {
    this._scheduler.stop();
  };

  private readonly _onFail = () => {
    this._scheduler.start();
  };

  subscribe() {
    return batchDisposers(
      this._scheduler.ticks.listen(TICK, this._onTick),
      this._target.monitor.listen(SUCCESS, this._onSuccess),
      this._target.monitor.listen(FAIL, this._onFail),
    );
  }
}
