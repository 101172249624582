import {ReadonlyDeep} from 'type-fest';
import getWorkerOrder from '../getters/getWorkerOrder';
import {WorkerEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {WorkerStateRegistry} from '../../WorkerStateRegistry';
import {DashboardScreenProps} from '../DashboardScreenProps';
import {byAverageHashrate, byMinedTotal, byName, byStatus} from './sort';
import {SIMPLE_GROUP_ID} from '../constants';
import {WorkerOrder} from '../types';

export default (
  workers: readonly ReadonlyDeep<WorkerEntity>[] | undefined,
  route: DashboardScreenProps['route'],
  balance: (workerId: string) => number,
  registry: WorkerStateRegistry,
): ReadonlyDeep<WorkerEntity[]> => {
  if (!workers) {
    return [];
  }
  const sortedWorkers = workers.slice();
  const workerOrder = getWorkerOrder(route.params, SIMPLE_GROUP_ID);
  switch (workerOrder) {
    case WorkerOrder.AverageHashrate:
      sortedWorkers.sort((a, b) => byAverageHashrate(a, b, registry));
      break;
    case WorkerOrder.MinedTotal:
      sortedWorkers.sort((a, b) => byMinedTotal(a, b, balance));
      break;
    case WorkerOrder.Name:
      sortedWorkers.sort((a, b) => byName(a, b));
      break;
    case WorkerOrder.Status:
      sortedWorkers.sort((a, b) => byStatus(a, b, registry));
      break;
  }
  return sortedWorkers;
};
