import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite';
import BtcSvg from '../assets/svg/colored/btc.svg';
import CoinSvg from '../assets/svg/colored/coin.svg';
import {View, ViewProps, Text, TouchableOpacity} from 'react-native';
import {useStyles, useTheme} from '../styling';
import {Button, ButtonVariant} from '../components';
import {useStrings} from '../Root/hooks';
import WithdrawalHistoryContext from './WithdrawalHistoryContext';
import {Container} from '../components/Grid';

import {sized} from '../Svg';

export default observer((props: ViewProps) => {
  const {style, ...rest} = props;
  const {goToAffiliate, goToWithdraw} = useContext(WithdrawalHistoryContext);
  const {window} = useTheme();
  const styles = useStyles((theme) => ({
    root: {
      marginTop: 20,
      borderTopStartRadius: 10,
      borderTopEndRadius: 10,
      backgroundColor: theme.palette.background,
      borderColor: theme.palette.border,
      borderWidth: 1,
      width: '100%',
      maxWidth: 1100,
      marginHorizontal: 'auto',
    },
    content: {
      paddingVertical: 16,
      paddingHorizontal: 16,
      ...theme.mediaQuery({
        1000: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          paddingVertical: 20,
        },
      }),
    },
    titleView: {
      padding: 20,
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.border,
    },
    title: {
      ...theme.fontByWeight('700'),
      marginLeft: 10,
      fontSize: 32,
      lineHeight: 37,
      letterSpacing: 0.09,
      color: theme.palette.textPrimary,
    },
    leftButton: {
      marginBottom: 16,
      ...theme.mediaQuery({
        1000: {
          marginRight: 20,
          marginBottom: 0,
        },
      }),
    },
    rightButton: {},
    button: {
      ...theme.mediaQuery({
        1000: {
          paddingVertical: 14,
          minWidth: 300,
        },
      }),
    },
  }));
  const strings = useStrings();
  return (
    <>
      <View style={[styles.root, style]} {...rest}>
        {window.width >= 1000 && (
          <View style={styles.titleView}>
            <BtcSvg />
            <Text style={styles.title}>
              {strings['screenTitle.withdrawHistory']}
            </Text>
          </View>
        )}
        <View style={styles.content}>
          <TouchableOpacity
            activeOpacity={0.8}
            accessibilityRole="button"
            onPress={goToWithdraw}
            style={styles.leftButton}>
            <Button
              variant={ButtonVariant.Highlighted}
              style={styles.button}
              Icon={BtcIcon}>
              {strings['withdrawalHistory.button']}
            </Button>
          </TouchableOpacity>
          <TouchableOpacity
            activeOpacity={0.8}
            accessibilityRole="button"
            onPress={goToAffiliate}
            style={styles.rightButton}>
            <Button style={styles.button} Icon={CoinIcon}>
              {strings['withdrawalHistory.affiliateButton']}
            </Button>
          </TouchableOpacity>
        </View>
      </View>
      <TableHeader />
    </>
  );
});

const BtcIcon = sized(BtcSvg, 20);
const CoinIcon = sized(CoinSvg, 20);

const TableHeader = observer(() => {
  const {window} = useTheme();
  const styles = useStyles((theme) => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: theme.palette.back,
      paddingVertical: 17,
      paddingHorizontal: 20,
      borderTopWidth: 0,
      borderWidth: 1,
      borderColor: theme.palette.border,
    },
    dateView: {
      flexBasis: '33.3%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    statusView: {
      flexBasis: '33.3%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    amountView: {
      flexBasis: '33.3%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      ...theme.fontByWeight('700'),
      fontSize: 13,
      lineHeight: 18,
      letterSpacing: 0.049,
      color: theme.palette.textPrimary,
    },
  }));
  const strings = useStrings();
  if (window.width < 1000) {
    return null;
  }
  return (
    <Container style={styles.root}>
      <View style={styles.dateView}>
        <Text style={styles.title}>
          {strings['withdrawalHistory.table.date']}
        </Text>
      </View>
      <View style={styles.statusView}>
        <Text style={styles.title}>
          {strings['withdrawalHistory.table.status']}
        </Text>
      </View>
      <View style={styles.amountView}>
        <Text style={styles.title}>
          {strings['withdrawalHistory.table.amount']}
        </Text>
      </View>
    </Container>
  );
});
