import {
  action,
  computed,
  flow,
  observable,
  reaction,
  makeObservable,
} from 'mobx';
import {AsyncReturnType} from 'type-fest';
import {AccountType, AuthState} from './AuthState';
import {
  AccountInfoParams,
  ApiStore,
  CommonError,
  CryptoFarmServerCalls,
  FarmId,
} from '../ApiStore';
import authenticate from './authenticate';
import customAuthenticate from './customAuthenticate';
import authorize from './authorize';
import connect from './connect';
import completeOAuth from './completeOAuth';
import {setFernetToken, setSelectedAccountId} from '../persistence';
import {Configuration} from '../Configuration';
import {DemonstrationDatabase} from '../LocalService';
import registerNewFarm from './registerNewFarm';
import updateAccountInfo from './updateAccountInfo';
import {Either, error, success} from '../fp';
import {ConnectionManager, RECONNECT} from '../ConnectionManager';
import {DeviceIdentification} from '../DeviceIdentification';
import {batchDisposers, Service} from '../structure';
import {InAppPurchaseManager} from '../InAppPurchaseManager';
import {SpecialLocation} from '../SpecialLocation';
import {ReadyState} from '../Connection';
import {LocationSource} from '../Location';
import {PendingPurchasesResolver} from '../PendingPurchasesResolver';
import {CancellablePromise} from '../CancellablePromise';

export enum AuthStatus {
  Reconnecting,
  Authenticating,
  Authorizing,
  Connecting,
  Idle,
}

export default class Auth implements Service {
  @observable private _status = AuthStatus.Idle;
  @observable.ref private _state?: AuthState;
  @observable private _initialized = false;
  @observable private _connectionId?: number;

  constructor(
    private readonly _root: {
      readonly deviceIdentification: DeviceIdentification;
      readonly configuration: Configuration;
      readonly apiStore: ApiStore;
      readonly connectionManager: ConnectionManager;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly pendingPurchasesResolver: PendingPurchasesResolver;
      readonly db: DemonstrationDatabase;
      readonly specialLocation: SpecialLocation;
      readonly locationSource: LocationSource;
      reset(): void;
    },
  ) {
    makeObservable(this);
  }

  private _flow?: CancellablePromise<unknown>;
  private setup(...args: Parameters<Auth['_setup']>) {
    this._flow?.cancel();
    const _flow = this._setup(...args);
    _flow.catch(() => undefined);
    this._flow = _flow;
    return _flow;
  }

  /**
   * @throws {never}
   */
  private _setup = flow(function* (
    this: Auth,
    options?: {
      fernetToken?: string;
      accessToken?: string;
      accountId?: FarmId;
      forceRegister?: boolean;
      newFarm?: boolean;
      shouldReconnect?: boolean;
    },
  ) {
    const {
      fernetToken,
      accessToken,
      accountId,
      forceRegister,
      newFarm,
      shouldReconnect,
    } = options ?? {};
    try {
      if (shouldReconnect || shouldReconnect === undefined) {
        this._status = AuthStatus.Reconnecting;
        this._root.reset();
        yield this._root.apiStore.reconnect();
      }
      this._status = AuthStatus.Authenticating;
      let authentication:
        | AsyncReturnType<typeof completeOAuth>
        | AsyncReturnType<typeof authenticate>
        | AsyncReturnType<typeof customAuthenticate>;
      if (accessToken !== undefined) {
        authentication = yield completeOAuth(accessToken, this._root);
      } else if (fernetToken !== undefined) {
        authentication = yield customAuthenticate(fernetToken);
      } else if (newFarm && this.fernetToken) {
        authentication = yield registerNewFarm(this.fernetToken, this._root);
      } else {
        authentication = yield authenticate(this._root, {forceRegister});
      }
      if (authentication.kind === 'AuthenticationFailed') {
        this._state = authentication;
        return;
      }
      this._status = AuthStatus.Authorizing;
      const authorization: AsyncReturnType<typeof authorize> = yield authorize(
        authentication,
        this._root,
      );
      if (authorization.kind === 'AuthorizationFailed') {
        this._state = authorization;
        return;
      }
      this._status = AuthStatus.Connecting;
      this._state = yield connect(accountId ?? null, authorization, this._root);
    } finally {
      this._status = AuthStatus.Idle;
      this._initialized = true;
      this._connectionId = this._root.connectionManager.connectionId;
    }
  });

  /**
   * @throws {never}
   */
  registerNewAccount = async () => {
    await this._root.db.clear();
    await this.setup({forceRegister: true});
  };

  /**
   * @throws {never}
   */
  registerNewFarm = async () => {
    await this._root.db.clear();
    await this.setup({newFarm: true, shouldReconnect: false});
  };

  /**
   * @throws {never}
   */
  authorize = flow(function* (this: Auth) {
    const state = this._state;
    if (state) {
      switch (state.kind) {
        case 'Authorized':
        case 'AuthorizationFailed':
        case 'Authenticated':
          this._status = AuthStatus.Authorizing;
          this._state = yield authorize(state, this._root);
          this._status = AuthStatus.Idle;
      }
    }
  }).bind(this);

  /**
   * @throws {never}
   */
  selectAccount = flow(function* (this: Auth, accountId: FarmId) {
    const state = this._state;
    if (state) {
      switch (state.kind) {
        case 'Connected': {
          yield this.setup({accountId});
          break;
        }
        case 'ConnectionFailed':
        case 'Authorized': {
          this._status = AuthStatus.Connecting;
          this._state = yield connect(accountId, state, this._root);
          this._status = AuthStatus.Idle;
          break;
        }
      }
    }
  });

  /**
   * @throws {never}
   */
  completeOAuth = (accessToken: string, farmId?: FarmId) => {
    if (
      this._state?.kind === 'Connected' &&
      this._state.accountType === AccountType.Temporary
    ) {
      return this.setup({
        accessToken,
        shouldReconnect: false,
        accountId: farmId,
      });
    } else {
      return this.setup({accessToken, accountId: farmId});
    }
  };

  /**
   * @throws {never}
   */
  updateAccountInfo = action((params: AccountInfoParams) => {
    const state = this._state;
    if (state) {
      switch (state.kind) {
        case 'Connected':
          this._state = updateAccountInfo(state, this._root, params);
      }
    }
  });

  /**
   * @throws {never}
   */
  signOut = flow(function* (this: Auth) {
    this._root.reset();
    yield setSelectedAccountId();
    yield setFernetToken();
    yield this.setup();
  }).bind(this);

  /**
   * **DEBUG ONLY**
   * @throws {never}
   */
  authenticateByFernetToken = flow(function* (this: Auth, fernetToken: string) {
    yield this._root.db.clear();
    yield this.setup({fernetToken});
  });

  /**
   * @throws {never}
   */
  restoreFarm = flow(function* (
    this: Auth,
    fernetToken: string,
    farmId: FarmId,
  ) {
    yield this.setup({fernetToken, accountId: farmId});
  });

  /**
   * @throws {never}
   */
  authenticateByFarmId = flow(function* (this: Auth, farmId: FarmId) {
    const get_farm_token: ReturnType<CryptoFarmServerCalls['get_farm_token']> =
      yield this._root.apiStore.client.call('get_farm_token', {
        farm_id: farmId,
      });
    if (!get_farm_token.success) {
      return error(get_farm_token.left) as Either<void, CommonError>;
    }
    yield this._root.db.clear();
    yield setSelectedAccountId(farmId);
    const fernetToken = get_farm_token.right.token;
    yield this.setup({fernetToken, accountId: farmId});
    return success(undefined) as Either<void, CommonError>;
  });

  /**
   * **DEBUG ONLY**
   * @throws {never}
   */
  retrySetup = flow(function* (this: Auth) {
    yield this.setup();
  }).bind(this);

  cancel() {
    this._flow?.cancel();
  }

  get status() {
    return this._status;
  }

  get state() {
    return this._state;
  }

  get initialized() {
    return this._initialized;
  }

  @computed get subscriptionMap() {
    return this._state &&
      (this._state.kind === 'Authorized' ||
        this._state.kind === 'Connected' ||
        this._state.kind === 'ConnectionFailed')
      ? this._state.subscriptionMap
      : undefined;
  }

  @computed get fernetToken() {
    return this._state && this._state.kind !== 'AuthenticationFailed'
      ? this._state.fernetToken
      : undefined;
  }

  @computed get accountType() {
    return this._state &&
      (this._state.kind === 'Authorized' ||
        this._state.kind === 'ConnectionFailed' ||
        this._state.kind === 'Connected')
      ? this._state.accountType
      : undefined;
  }

  /**
   * @deprecated
   */
  @computed get accountIds() {
    return this._state &&
      (this._state.kind === 'Authorized' ||
        this._state.kind === 'ConnectionFailed' ||
        this._state.kind === 'Connected')
      ? this._state.accountIds
      : undefined;
  }

  @computed get accountId() {
    return this._state && this._state.kind === 'Connected'
      ? this._state.accountId
      : undefined;
  }

  @computed get email() {
    return this._state &&
      (this._state.kind === 'Authorized' ||
        this._state.kind === 'ConnectionFailed' ||
        this._state.kind === 'Connected')
      ? this._state.email
      : undefined;
  }

  @computed get error() {
    return this._state &&
      (this._state.kind === 'AuthenticationFailed' ||
        this._state.kind === 'AuthorizationFailed' ||
        this._state.kind === 'ConnectionFailed')
      ? this._state.raw
      : undefined;
  }

  @computed get isConnected() {
    const {readyState} = this._root.apiStore.tunnelState;
    const {connectionId} = this._root.connectionManager;
    return (
      readyState === ReadyState.Open &&
      this._connectionId === connectionId &&
      this._status === AuthStatus.Idle &&
      this._state?.kind === 'Connected'
    );
  }

  private _reconnectOnEnvironmentChange = () =>
    reaction(
      () => this._root.configuration.values,
      () => {
        this.signOut();
      },
    );

  private _setupOnReconnect() {
    return this._root.connectionManager.sockets.listen(RECONNECT, async () => {
      this.setup({shouldReconnect: false});
    });
  }

  subscribe() {
    return batchDisposers(
      this._reconnectOnEnvironmentChange(),
      this._setupOnReconnect(),
    );
  }

  @action reset() {
    this._state = undefined;
  }
}
