import React from 'react';
import {observer} from 'mobx-react-lite';
import BagBtcSvg from '../../assets/svg/colored/bagBtc.svg';
import TicketSvg from '../../assets/svg/colored/ticket.svg';
import {sized} from '../../Svg';
import {variance} from '../../styling';
import {Text, View} from 'react-native';
import {Button, ButtonColor, PressableOpacity} from '../../components';
import {useStrings, useTemplates} from '../../Root/hooks';
import {expr} from 'mobx-utils';
import LoaderIndicator from '../../components/LoaderIndicator';
import useTutorialMeasure from '../../InteractiveTutorial/useTutorialMeasure';
import {StackElementKey} from '../../InteractiveTutorial';

export const GENERATE_PROMO_HEIGHT = 185;
export const GENERATE_PROMO_DESKTOP_HEIGHT = 110;
export const GENERATE_PROMO_DESKTOP_BREAKPOINT = 750;

const TUTORIAL_KEY: StackElementKey = 'PROMO';

export type GenerateCodeSection = {
  safelyGoToPromoAffiliate: () => void;
  getAffiliateIsPending: () => boolean;
};

export const GeneratePromoSection = observer(
  ({safelyGoToPromoAffiliate, getAffiliateIsPending}: GenerateCodeSection) => {
    const strings = useStrings();
    const templates = useTemplates();
    const promoText = templates['dashboard.promo.text']({
      getPercent: 15,
    });
    const isPending = expr(getAffiliateIsPending);
    const [rootRef, onLayout, getForceKey] = useTutorialMeasure(TUTORIAL_KEY);
    return (
      <ContainerView>
        <RootView ref={rootRef} onLayout={onLayout} key={getForceKey()}>
          <Header>
            <BagBtcIcon />
            <HeaderText>
              <PromoText adjustsFontSizeToFit numberOfLines={3}>
                {promoText}
              </PromoText>
            </HeaderText>
          </Header>
          <ButtonContainer
            onPress={safelyGoToPromoAffiliate}
            disabled={isPending}>
            <GenerateButton
              color={isPending ? ButtonColor.Default : ButtonColor.Success}
              Icon={isPending ? LoaderIndicator : TicketIcon}>
              {strings['dashboard.promo.button.generate']}
            </GenerateButton>
          </ButtonContainer>
        </RootView>
      </ContainerView>
    );
  },
);

const BagBtcIcon = sized(BagBtcSvg, 55, 65);
const TicketIcon = sized(TicketSvg, 20, 16);

const ContainerView = variance(View)((theme) => ({
  root: {
    height: GENERATE_PROMO_HEIGHT,
    paddingBottom: 10,
    ...theme.mediaQuery({
      [GENERATE_PROMO_DESKTOP_BREAKPOINT]: {
        height: GENERATE_PROMO_DESKTOP_HEIGHT,
      },
    }),
  },
}));

const RootView = variance(View)((theme) => ({
  root: {
    flex: 1,
    borderRadius: 15,
    paddingTop: 20,
    paddingHorizontal: 15,
    paddingBottom: 15,
    borderWidth: 1,
    borderColor: theme.palette.success,
    backgroundColor: theme.chroma(theme.palette.success).alpha(0.1).hex(),
    ...theme.mediaQuery({
      [GENERATE_PROMO_DESKTOP_BREAKPOINT]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: 15,
        paddingBottom: 15,
        paddingHorizontal: 30,
      },
    }),
  },
}));

const Header = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    marginBottom: 20,
    alignItems: 'center',
    ...theme.mediaQuery({
      [GENERATE_PROMO_DESKTOP_BREAKPOINT]: {
        flex: 1,
        marginBottom: 0,
        paddingRight: 20,
      },
    }),
  },
}));

const HeaderText = variance(View)(() => ({
  root: {
    flex: 1,
    marginTop: 2,
    marginLeft: 15,
  },
}));

const ButtonContainer = variance(PressableOpacity)((theme) => ({
  root: {
    marginTop: 'auto',
    ...theme.mediaQuery({
      [GENERATE_PROMO_DESKTOP_BREAKPOINT]: {
        marginTop: 0,
      },
    }),
  },
}));

const PromoText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight(),
    color: theme.palette.textPrimary,
    fontSize: 16,
    lineHeight: 24,
  },
}));

const GenerateButton = variance(Button)((theme) => ({
  root: {
    paddingHorizontal: 20,
    ...theme.mediaQuery({
      [GENERATE_PROMO_DESKTOP_BREAKPOINT]: {
        paddingHorizontal: 40,
      },
    }),
  },
}));
