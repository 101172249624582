import React from 'react';
import {observer} from 'mobx-react-lite';
import {Text, View} from 'react-native';
import {useStyles} from '../styling';
import {expr} from 'mobx-utils';
import {ReadonlyDeep} from 'type-fest';
import {
  WorkerEntity,
  WorkerType,
} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import getWorkerDescription from '../DashboardScreen/getters/getWorkerDescription';
import PoolMinerSpeed from '../DashboardScreen/common/PoolMinerSpeed';

export interface WorkerSlotHeaderProps {
  worker: ReadonlyDeep<WorkerEntity>;
  warning: boolean;
  disabled: boolean;
}

export default observer(
  ({worker, warning, disabled}: WorkerSlotHeaderProps) => {
    const styles = useStyles((theme) => ({
      root: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },
      specs: {
        flexShrink: 1,
        fontSize: 12,
        lineHeight: 22,
        marginRight: 5,
        color: theme.palette.textSecondary,
      },
      warning: {
        color: theme.palette.error,
      },
      disabled: {
        color: theme.palette.textSecondary,
      },
    }));
    const specs = expr(() => getWorkerDescription(worker));
    return (
      <View style={styles.root}>
        <Text
          style={[
            styles.specs,
            warning && styles.warning,
            disabled && styles.disabled,
          ]}>
          {specs}
        </Text>
        {worker.worker_type === WorkerType.Pool && (
          <PoolMinerSpeed
            speed={worker.pool_miner_config?.hash_rate}
            expired={Boolean(worker.expired)}
          />
        )}
      </View>
    );
  },
);
