import {FAIL, TRY, WatchTarget} from './WatchTarget';
import {Service} from '../structure';

export default class CannonWatchService implements Service {
  constructor(private readonly _target: WatchTarget) {}

  private readonly _onFail = () => {
    setTimeout(() => {
      this._target.control.send(TRY, undefined);
    }, 0);
  };

  subscribe() {
    return this._target.monitor.listen(FAIL, this._onFail);
  }
}
