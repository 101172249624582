import React from 'react';
import {Text, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../../../../Root/hooks';
import {BtcAmount} from '../../../common/BtcAmount';
import Hashrate from '../../../common/Hashrate';
import {createStylesHook, variance} from '../../../../styling';
import {DESKTOP_BREAKPOINT} from '../../../../units/constants';

export type GroupStatsProps = {
  hashrate?: number | (() => React.ReactNode);
  mined?: number | (() => React.ReactNode);
  isEmpty?: boolean;
};

export default observer(
  ({hashrate = 0, mined = 0, isEmpty = false}: GroupStatsProps) => {
    const strings = useStrings();
    const styles = useStyles();

    return (
      <Root empty={isEmpty}>
        <View style={styles.cell}>
          <Text style={styles.cellTitle}>
            {strings['workerGroup.totalGroupSpeed']}
          </Text>
          <Hashrate value={hashrate} style={styles.value} />
        </View>
        <View style={styles.cell}>
          <Text style={styles.cellTitle}>
            {strings['workerGroup.minedByGroup']}
          </Text>
          <BtcAmount value={mined} style={styles.value} />
        </View>
      </Root>
    );
  },
);

const Root = variance(View)((theme) => ({
  root: {
    height: 70,
    paddingHorizontal: 15,
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: theme.palette.border,
    justifyContent: 'space-around',
    ...theme.mediaQuery({
      1000: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        backgroundColor: theme.palette.background,
      },
    }),
  },
  empty: {
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
}));

const useStyles = createStylesHook((theme) => ({
  root: {
    height: 70,
    paddingHorizontal: 15,
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: theme.palette.border,
    justifyContent: 'space-around',
    ...theme.mediaQuery({
      1000: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        backgroundColor: theme.palette.background,
      },
    }),
  },
  cell: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: 8,
      },
    }),
  },
  cellTitle: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textSecondary,
    fontSize: 11,
    lineHeight: 13,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 12,
        lineHeight: 14,
        marginRight: 7,
      },
    }),
  },
  value: {
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 18,
        lineHeight: 22,
      },
    }),
  },
}));
