import GroupService from './GroupService';
import {DemonstrationDatabase} from './DemonstrationDatabase';
import DashboardService from './DashboardService';
import LogService from './LogService';
import {Translation} from '../Localization';
import WorkerService from './WorkerService';
import MiningService from './MiningService';
import {
  DuplexTunnel,
  increment,
  JsonRpcClient,
  JsonRpcId,
  JsonRpcServer,
  Request,
  Response,
  StructuredTunnel,
  TunnelKind,
  WebSocketTunnelState,
} from '../JsonRpc';
import {
  CryptoFarmClientCalls,
  CryptoFarmClientNotifications,
  CryptoFarmConnectedServerCalls,
  CryptoFarmServerCalls,
  CryptoFarmServerNotifications,
} from '../ApiStore';
import WorkerStateService from './WorkerStateService';
import {AppWindowState} from '../AppWindow';
import {Configuration} from '../Configuration';
import routeDashboard from './routeDashboard';
import routeGroups from './routeGroups';
import routeNotifications from './routeLogs';
import routeWorkers from './routeWorkers';
import routeMining from './routeMining';
import routeWorkerState from './routeWorkerState';
import routeThrough from './routeThrough';
import {Millisecond} from '../Time';
import SpyService from '../ApiStore/SpyService';
import {Spy} from '../ApiStore/Spy';
import {batchDisposers, Service} from '../structure';
import {ConnectionManager} from '../ConnectionManager';

export default class DemoBackend implements Service {
  private readonly _realClient: JsonRpcClient<
    CryptoFarmServerCalls & CryptoFarmConnectedServerCalls,
    CryptoFarmServerNotifications
  >;
  private readonly _realServer: JsonRpcServer<
    CryptoFarmClientCalls,
    CryptoFarmClientNotifications
  >;

  private readonly _duplex = new DuplexTunnel(
    ({send, listen}): StructuredTunnel => ({
      tunnelKind: TunnelKind.Structured,
      send,
      listen,
    }),
  );
  private readonly _spy = new SpyService(
    this._duplex.alice as StructuredTunnel<
      Request | Response,
      Request | Response
    >,
  );
  private readonly _reverseServer = new JsonRpcServer<
    CryptoFarmServerCalls & CryptoFarmConnectedServerCalls,
    CryptoFarmServerNotifications
  >(this._duplex.bob);
  private readonly _generator = increment();
  private readonly _reverseClient: JsonRpcClient<
    CryptoFarmClientCalls,
    CryptoFarmClientNotifications
  >;

  private readonly _dashboard: DashboardService;
  private readonly _groups: GroupService;
  private readonly _logs: LogService;
  private readonly _workers: WorkerService;
  private readonly _mining: MiningService;
  private readonly _workerState: WorkerStateService;

  constructor(
    private readonly _root: {
      readonly db: DemonstrationDatabase;
      readonly translation: Translation;
      readonly appWindowState: AppWindowState;
      readonly configuration: Configuration;
      readonly connectionManager: ConnectionManager;
    },
    private readonly _tunnelState: WebSocketTunnelState,
    private readonly _realTunnel: StructuredTunnel,
    private readonly _ids: Generator<JsonRpcId, unknown>,
  ) {
    this._realClient = new JsonRpcClient(
      this._realTunnel,
      this._ids,
      this._root.configuration.values.bffTimeout,
      this._root.connectionManager.connectionIdUpdates,
    );
    this._realServer = new JsonRpcServer(this._realTunnel);
    this._reverseClient = new JsonRpcClient(
      this._duplex.bob,
      this._generator,
      this._root.configuration.values.bffTimeout,
      this._root.connectionManager.connectionIdUpdates,
    );
    this._dashboard = new DashboardService(
      this._root,
      this._reverseClient,
      this._realServer,
    );
    this._groups = new GroupService(
      this._root,
      this._reverseClient,
      this._realServer,
    );
    this._logs = new LogService(
      this._root,
      this._reverseClient,
      this._realServer,
    );
    this._workers = new WorkerService(
      this._root,
      this._reverseClient,
      this._realServer,
    );
    this._mining = new MiningService(this._root, this._logs, this._realClient);
    this._workerState = new WorkerStateService(
      this._root,
      this._tunnelState,
      this._reverseClient,
      this._realClient,
      this._realServer,
    );
  }

  private _route() {
    return batchDisposers(
      routeThrough(this._reverseServer, this._realClient),
      routeDashboard(this._reverseServer, this._dashboard),
      routeGroups(this._reverseServer, this._groups),
      routeNotifications(this._reverseServer, this._logs),
      routeWorkers(this._reverseServer, this._workers),
      routeMining(this._reverseServer, this._mining),
      routeWorkerState(this._reverseServer, this._workerState),
    );
  }

  get tunnel() {
    return this._spy.tunnel;
  }

  get spy(): Spy<Request | Response> {
    return this._spy;
  }

  getSubscription() {
    return this._dashboard.getSubscription();
  }

  setUpdateInterval(updateInterval?: Millisecond) {
    return this._mining.setUpdateInterval(updateInterval);
  }

  subscribe() {
    return batchDisposers(
      this._realClient.subscribe(),
      this._realServer.subscribe(),
      this._spy.subscribe(),
      this._reverseServer.subscribe(),
      this._reverseClient.subscribe(),
      this._route(),
      this._dashboard.subscribe(),
      this._groups.subscribe(),
      this._logs.subscribe(),
      this._workers.subscribe(),
      this._mining.subscribe(),
      this._workerState.subscribe(),
    );
  }
}
