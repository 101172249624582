import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {batchDisposers, Service} from '../../structure';
import {bind} from '../../fp';
import {minBy, sortBy} from 'lodash';
import {Auth} from '../../Auth';
import {ProposalsState, SETTLED} from '../../ProposalsState';
import {PurchaseId} from '../../units';
import {
  PurchaseMinerKind,
  PurchaseScreenState,
  PurchaseTabsKind,
} from './PurchaseScreenState';
import {CurrentSlotSubscription} from '../../CurrentSlotSubscriptionService';
import {Hashrate, Interval, SlotCount} from '../../ApiStore';
import {
  InAppOffers,
  MinerOffer,
  OfferPurchaseType,
  PaymentMethod,
} from '../../InAppOffersService';
import {InAppPurchase} from '../../InAppPurchase';
import StaticPurchaseScreenHelper from './StaticPurchaseScreenHelper';

export default abstract class BasePurchaseScreenStateImpl
  implements PurchaseScreenState, Service
{
  private static readonly DEFAULT_INTERVAL = 3 as Interval;
  private static readonly DEFAULT_SLOT_INTERVAL = 1 as Interval;
  @observable private _selectedTab: PurchaseTabsKind = PurchaseTabsKind.Slot;
  @observable private _selectedMinerPurchaseId?: PurchaseId;
  @observable private _selectedHashrate?: Hashrate;
  @observable private _selectedSlotPurchaseId?: PurchaseId;
  @observable private _kindOfMinerProposal = PurchaseMinerKind.Subscription;
  @observable private _selectedMaxSlot?: number;
  @observable private _selectedInterval: Interval =
    BasePurchaseScreenStateImpl.DEFAULT_INTERVAL as Interval;
  @observable private _selectedSlotInterval: Interval =
    BasePurchaseScreenStateImpl.DEFAULT_SLOT_INTERVAL as Interval;

  private _keepSelectedHashrate = false;

  protected constructor(
    protected readonly _root: {
      readonly auth: Auth;
      readonly proposalsState: ProposalsState;
      readonly currentSlotSubscription: CurrentSlotSubscription;
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchase: InAppPurchase;
    },
  ) {
    makeObservable(this);
  }
  abstract get selectedPaymentMethod(): PaymentMethod;
  abstract selectPaymentMethod(method: PaymentMethod): void;

  get selectedInterval() {
    return this._selectedInterval;
  }

  get selectedSlotInterval() {
    return this._selectedSlotInterval;
  }

  get selectedMaxSlot() {
    return this._selectedMaxSlot;
  }

  get doubleProposalByIntervalByHashrate() {
    return this._root.proposalsState.doubleProposalByIntervalByHashrate;
  }

  get uniqIntervalList() {
    return this._root.proposalsState.uniqIntervalList;
  }

  get slotUniqIntervalList() {
    return this._root.proposalsState.slotUniqIntervalList;
  }

  getDoubleProposalForPurchase = () => {
    if (
      this.selectedDoubleProposal?.subscription &&
      this._kindOfMinerProposal === PurchaseMinerKind.Subscription
    ) {
      return this.selectedDoubleProposal?.subscription;
    }

    if (
      this.selectedDoubleProposal?.product &&
      this._kindOfMinerProposal === PurchaseMinerKind.Product
    ) {
      return this.selectedDoubleProposal?.product;
    }

    return (
      this.selectedDoubleProposal?.subscription ||
      this.selectedDoubleProposal?.product
    );
  };

  get selectedHashrate() {
    return this._selectedHashrate;
  }

  selectHashrate = action((hashrate: Hashrate, persist?: boolean) => {
    this._updateKindOfOffers();
    this._selectedHashrate = hashrate;
    this._keepSelectedHashrate = Boolean(persist);
  });

  selectInterval = action((interval: Interval) => {
    this._updateKindOfOffers();
    this._selectedInterval = interval;
  });

  selectSlotInterval = action((interval: Interval) => {
    this._selectedSlotInterval = interval;
  });

  selectMaxSlots = action((max: number) => {
    this._selectedMaxSlot = max;
  });

  @computed
  get selectedDoubleProposal() {
    const {doubleProposalByIntervalByHashrate} = this._root.proposalsState;
    if (
      this.selectedHashrate === undefined ||
      doubleProposalByIntervalByHashrate === undefined
    ) {
      return undefined;
    }
    const candidate = doubleProposalByIntervalByHashrate
      .get(this.selectedHashrate)
      ?.get(this.selectedInterval);
    if (!candidate) {
      return undefined;
    }

    return StaticPurchaseScreenHelper.filterDoublePoolProposal(candidate);
  }

  @action.bound
  private _updateKindOfOffers() {
    if (!this.selectedDoubleProposal) {
      return;
    }
    if (
      this.selectedDoubleProposal.subscription?.offer.purchaseId &&
      this._root.inAppOffers.purchasedIds.has(
        this.selectedDoubleProposal.subscription?.offer.purchaseId,
      )
    ) {
      this._kindOfMinerProposal = PurchaseMinerKind.Product;
    } else {
      this._kindOfMinerProposal = PurchaseMinerKind.Subscription;
    }
  }

  @computed
  get minerConfigs() {
    return this._root.proposalsState.minerConfigs;
  }

  @computed
  get visibleFreeMinerSection() {
    return this._root.currentSlotSubscription.offers?.length === 0;
  }

  get selectedTab() {
    return this._selectedTab;
  }

  @computed
  get selectedSlotProposal() {
    if (this._selectedMaxSlot === undefined) {
      return undefined;
    }
    return this._root.proposalsState.slotSubscriptionProposalList.find(
      (s) =>
        s.offer.interval === this._selectedSlotInterval &&
        s.offer.maxSlots === (this._selectedMaxSlot as SlotCount),
    );
  }
  @computed
  get selectedMinerProposal() {
    const {minerProductProposalById} = this._root.proposalsState;
    if (!this._selectedMinerPurchaseId || !minerProductProposalById) {
      return undefined;
    }
    return minerProductProposalById.get(this._selectedMinerPurchaseId);
  }
  @computed
  get selectedProposal() {
    switch (this.selectedTab) {
      case PurchaseTabsKind.Slot:
        return this.selectedSlotProposal;
      case PurchaseTabsKind.Miner:
        return this.selectedMinerProposal;
      default:
        return undefined;
    }
  }

  @computed
  get slotProposals() {
    return this._root.proposalsState.slotSubscriptionProposalList;
  }

  @computed
  get selectedMinerProposalMap() {
    if (
      !this.selectedMinerProposal?.available ||
      !this.selectedMinerProposal?.offer.poolMinerConfig
    ) {
      return undefined;
    }
    const offerHashrate =
      this.selectedMinerProposal?.offer.poolMinerConfig.hash_rate;
    return this._root.proposalsState.doubleProposalByIntervalByHashrate.get(
      offerHashrate,
    );
  }
  get uniqProductIntervalList() {
    return this._root.proposalsState.uniqProductIntervalList;
  }

  @computed
  get availableHashrateConfigList() {
    if (!this.selectedMinerProposal?.available) {
      return undefined;
    }
    const interval = this.selectedMinerProposal.offer.interval;
    const {minerProductProposalList} = this._root.proposalsState;
    return minerProductProposalList.flatMap((_) =>
      _.available && _.offer.interval === interval ? [_.offer] : [],
    );
  }

  selectTab = action((newTab: PurchaseTabsKind) => {
    this._selectedTab = newTab;
  });

  selectMinerPurchaseId = bind((id?: PurchaseId) => {
    if (id === undefined) {
      return;
    }
    const {minerProductProposalById} = this._root.proposalsState;
    const candidate = minerProductProposalById?.get(id);
    if (candidate?.available) {
      runInAction(() => (this._selectedMinerPurchaseId = id));
    }
  }, this);

  selectSlotPurchaseId = bind((id?: PurchaseId) => {
    if (id === undefined) {
      return;
    }
    const {slotSubscriptionProposalById} = this._root.proposalsState;
    const candidate = slotSubscriptionProposalById?.get(id);
    if (candidate?.available) {
      runInAction(() => {
        this._selectedMaxSlot = candidate.offer.maxSlots;
        this._selectedSlotInterval = candidate.offer.interval;
      });
    }
  }, this);

  private _fetchOnProposalLoaded() {
    if (this._root.proposalsState.isLoadedIn) {
      this._selectDefaultData();
    }
    return this._root.proposalsState.events.listen(SETTLED, () => {
      this._selectDefaultData();
    });
  }

  private _resetOnChangeFarm() {
    return reaction(
      () => this._root.auth.accountId,
      () => {
        this.reset();
      },
    );
  }

  subscribe() {
    return batchDisposers(
      this._resetOnChangeFarm(),
      this._fetchOnProposalLoaded(),
    );
  }

  init = action((initialSelectedTab: PurchaseTabsKind) => {
    if (initialSelectedTab) {
      this._selectedTab = initialSelectedTab;
    }
  });

  blur = action(() => {
    this._selectedTab = PurchaseTabsKind.Slot;
  });

  reset = action(() => {
    this._selectedInterval = BasePurchaseScreenStateImpl.DEFAULT_INTERVAL;
    this._selectedSlotInterval =
      BasePurchaseScreenStateImpl.DEFAULT_SLOT_INTERVAL;
    this._selectedMaxSlot = undefined;
    this._selectedHashrate = undefined;
    this._selectedMinerPurchaseId = undefined;
  });

  private _selectDefaultData() {
    const {
      minerSubscriptionProposalList,
      minerProductProposalList,
      slotSubscriptionProposalList,
      doubleProposalList,
    } = this._root.proposalsState;

    let minerOffers: MinerOffer[] = [];
    if (
      minerSubscriptionProposalList.length > 0 &&
      minerProductProposalList.length > 0
    ) {
      minerOffers = doubleProposalList.flatMap(
        (_) =>
          (_.product?.available
            ? [_.product.offer]
            : _.subscription?.available
            ? [_.subscription.offer]
            : []) as MinerOffer[],
      );
    } else if (minerProductProposalList.length > 0) {
      minerOffers = minerProductProposalList.flatMap((_) =>
        _.available ? [_.offer] : [],
      );
    } else if (minerSubscriptionProposalList.length > 0) {
      minerOffers = minerSubscriptionProposalList.flatMap((_) =>
        _.available ? [_.offer] : [],
      );
    }
    const sortedOffers = sortBy(
      minerOffers,
      (o) => o.poolMinerConfig.hash_rate,
    );
    const priorityMinerOffer =
      minBy(sortedOffers, (o) => {
        if (
          o.purchaseType === OfferPurchaseType.MinerSubscription &&
          o.bought
        ) {
          return null; // exclude
        }
        return o.priority;
      }) || sortedOffers[0];
    if (priorityMinerOffer && !this._keepSelectedHashrate) {
      this._selectedHashrate = priorityMinerOffer.poolMinerConfig.hash_rate;
      this._selectedInterval = priorityMinerOffer.interval;
      this._updateKindOfOffers();
    }

    const slotOffers = slotSubscriptionProposalList.flatMap((_) =>
      _.available && !_.offer.bought ? [_.offer] : [],
    );

    this._selectedMaxSlot = minBy(slotOffers, (_) => _.priority)?.maxSlots;
  }
}
