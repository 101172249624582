import React from 'react';
import {observer} from 'mobx-react-lite';
import {
  TotalPriceItemContainer,
  TotalPriceItemTop,
  TotalPriceItemTopText,
} from '../../../shared/shared';
import {useStrings} from '../../../../Root/hooks';
import {useTheme, variance} from '../../../../styling';
import {Text, View} from 'react-native';
import OfferSvg from '../../../../assets/svg/colorless/offer.svg';
import {sized} from '../../../../Svg';

export type TotalPriceRegularProps = {
  oldPrice?: string;
  save?: number | string;
  currency: string;
};

export default observer(function TotalPriceRegular(
  props: TotalPriceRegularProps,
) {
  const {oldPrice, save, currency} = props;
  const strings = useStrings();
  const theme = useTheme();
  return (
    <TotalPriceItemContainer>
      <TotalPriceItemTop>
        <OfferIcon color={theme.palette.info} />
        <TotalPriceItemTopText>
          {strings['purchase.total.regularTitle']}
        </TotalPriceItemTopText>
      </TotalPriceItemTop>
      <Row main>
        <RegularText through>
          {oldPrice} {currency}
        </RegularText>
        <Row>
          <RegularText save>{strings['save.label']}</RegularText>
          <RegularText saveBold>
            {' '}
            {typeof save === 'number' ? save?.toFixed(2) : save} {currency}
          </RegularText>
        </Row>
      </Row>
    </TotalPriceItemContainer>
  );
});

const OfferIcon = sized(OfferSvg, 20, 20);

const Row = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  main: {
    ...theme.mediaQuery({
      1000: {
        flexDirection: 'row-reverse',
      },
    }),
  },
}));

const RegularText = variance(Text)((theme) => ({
  root: {},
  through: {
    ...theme.fontByWeight('700'),
    color: theme.palette.error,
    fontSize: 20,
    marginRight: 10,
    textDecorationLine: 'line-through',
    ...theme.mediaQuery({
      1000: {
        marginRight: 0,
        marginLeft: 10,
      },
    }),
  },
  save: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    fontSize: 14,
  },
  saveBold: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textSecondary,
    fontSize: 14,
  },
}));
