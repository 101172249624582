import {
  Platform,
  Text,
  TouchableWithoutFeedback,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  State,
  TapGestureHandlerStateChangeEvent,
} from 'react-native-gesture-handler';
import dayjs from 'dayjs';
import Svg, {Circle, G, Path} from 'react-native-svg';

import normalize from './normalize';
import bezierPath from './bezierPath';
import Tooltip, {TooltipScope} from './Tooltip';
import clamp from '../utils/clamp';
import {useRoot} from '../Root/hooks';
import {Millisecond} from '../utils/time';
import asymptote from './asymptote';
import ChartPanGestureHandler from './ChartPanGestureHandler';
import linearRange from './linearRange';
import significantPoints from './significantPoints';
import {useTheme} from '../styling';
import useLayout from '../ReactNativeUtil/useLayout';

export enum ChartViewScope {
  Day = 'day',
  Month = 'month',
}

export interface ChartViewProps extends ViewProps {
  series: readonly number[];
  scope: ChartViewScope;
  from: Millisecond;
  to: Millisecond;
  title: string;
  fractionDigits: number;
  tooltipHelperText?: string;
  secondaryTitle?: string;
  secondaryAxisRatio?: number;
  secondaryFractionDigits?: number;
}

export type ChartViewRef = {
  reset(): void;
};

export default observer(
  forwardRef(
    (
      {
        series: timeSeries,
        scope,
        from: fromMillis,
        to: toMillis,
        title,
        fractionDigits,
        secondaryTitle,
        secondaryAxisRatio,
        secondaryFractionDigits = fractionDigits,
        ...rest
      }: ChartViewProps,
      ref: React.ForwardedRef<ChartViewRef>,
    ) => {
      const [layout, onLayout] = useLayout();
      const xLabelsHeight = 20;
      const xLabelWidth = 20;
      const yLabelsWidth = 60;
      const y2LabelsWidth = secondaryAxisRatio === undefined ? 0 : yLabelsWidth;

      const left = yLabelsWidth;
      const top = 30;
      const right = y2LabelsWidth === 0 ? 15 : y2LabelsWidth;
      const bottom = xLabelsHeight + 30;
      const width = (layout?.width ?? 0) - left - right;
      const height = (layout?.height ?? 0) - bottom - top;
      let [minValue, maxValue, normalizedData] = normalize(timeSeries);

      const yLabelFraction = 5;

      if (minValue === maxValue) {
        [minValue, maxValue, normalizedData] = asymptote(
          minValue,
          normalizedData.length,
          yLabelFraction,
        );
      }

      const theme = useTheme();
      useImperativeHandle(ref, () => ({
        reset: () => {
          setSelectedIndex(undefined);
          hideTooltip();
        },
      }));

      const styles = useMemo(
        () =>
          ({
            xLabels: {
              position: 'absolute',
              height: xLabelsHeight,
            },
            yLabels: {
              position: 'absolute',
              width: yLabelsWidth,
              justifyContent: 'space-between',
              flexWrap: 'nowrap',
            },
            yLabelsSingleValue: {
              justifyContent: 'center',
            },
            label: {
              ...theme.fontByWeight(),
              fontSize: 10,
              lineHeight: 14,
              color: theme.palette.textSecondary,
            },
            yLabel: {
              marginEnd: 13,
              textAlign: 'right',
            },
            y2Label: {
              marginStart: 13,
              textAlign: 'left',
            },
            xLabel: {
              position: 'absolute',
              top: 0,
              bottom: 0,
              width: xLabelWidth,
              marginLeft: -xLabelWidth / 2,
              textAlign: 'center',
            },
            tooltip: {
              margin: 10,
              position: 'absolute',
              ...theme.mediaQuery({
                769: {
                  ...((Platform.select({
                    web: {
                      pointerEvents: 'none',
                    },
                  }) || {}) as ViewStyle),
                },
              }),
            },
          } as const),
        [theme],
      );

      const [_selectedIndex, setSelectedIndex] = useState<number | undefined>();
      const selectedIndex =
        _selectedIndex === undefined
          ? undefined
          : clamp(_selectedIndex, 0, timeSeries.length);
      const onGestureEvent = useCallback(
        (offsetX: number, isActive: boolean) => {
          if (isActive) {
            const maxIndex = normalizedData.length - 1;
            const rawIndex = (offsetX / width) * maxIndex;
            const clampedIndex = Math.max(
              0,
              Math.min(maxIndex, Math.round(rawIndex)),
            );
            setSelectedIndex(clampedIndex);
          }
        },
        [normalizedData.length, width],
      );
      const onTapEvent = useCallback(
        (event: TapGestureHandlerStateChangeEvent) => {
          if (event.nativeEvent.state === State.ACTIVE) {
            onGestureEvent(
              event.nativeEvent.x,
              event.nativeEvent.state === State.ACTIVE,
            );
          }
        },
        [onGestureEvent],
      );
      const hideTooltip = useCallback(() => setSelectedIndex(undefined), []);

      let timeLabelFraction: number;
      switch (scope) {
        case ChartViewScope.Day:
          const hours = dayjs(toMillis).diff(fromMillis, 'hour') + 1;
          timeLabelFraction = hours < 12 ? hours : 12;
          break;
        case ChartViewScope.Month:
          const days = dayjs(toMillis).diff(fromMillis, 'day') + 1;
          timeLabelFraction = days < 10 ? days : 10;
          break;
        default:
          throw new TypeError('Scope value not recognized');
      }

      const timeline = useMemo(
        () =>
          new Array(timeLabelFraction)
            .fill(0)
            .map(
              (_, index) =>
                fromMillis +
                index * ((toMillis - fromMillis) / (timeLabelFraction - 1)),
            ),
        [fromMillis, toMillis, timeLabelFraction],
      );
      const {translation} = useRoot();
      const {localeTag} = translation;
      const timeAxisLabels: string[] = useMemo(() => {
        switch (scope) {
          case ChartViewScope.Day:
            return timeline.map((timestamp) =>
              dayjs(timestamp).locale(localeTag).format('H'),
            );
          case ChartViewScope.Month:
            return timeline.map((timestamp) =>
              dayjs(timestamp).locale(localeTag).format('DD'),
            );
          default:
            throw new TypeError('Scope value not recognized');
        }
      }, [localeTag, scope, timeline]);

      let selectedX,
        selectedY,
        tooltipLeft,
        tooltipTop,
        tooltipRight,
        tooltipBottom;
      if (selectedIndex !== undefined && layout) {
        selectedX = selectedIndex * (width / (normalizedData.length - 1));
        selectedY = height * (1 - normalizedData[selectedIndex]);
        if (selectedX < width / 2) {
          tooltipLeft = left + selectedX;
        } else {
          tooltipRight = layout.width - (left + selectedX);
        }
        if (selectedY < height / 2) {
          tooltipTop = top + selectedY;
        } else {
          tooltipBottom = layout.height - (top + selectedY);
        }
      }

      const yLabels = useMemo(
        () =>
          linearRange(
            maxValue,
            minValue,
            significantPoints(
              minValue,
              maxValue,
              fractionDigits,
              yLabelFraction,
            ),
          ),
        [minValue, maxValue, fractionDigits],
      );

      const y2Labels = useMemo(() => {
        if (secondaryAxisRatio === undefined) {
          return undefined;
        }
        const secondaryMinValue = minValue * secondaryAxisRatio;
        const secondaryMaxValue = maxValue * secondaryAxisRatio;
        return linearRange(
          secondaryMaxValue,
          secondaryMinValue,
          significantPoints(
            secondaryMinValue,
            secondaryMaxValue,
            secondaryFractionDigits,
            yLabelFraction,
          ),
        );
      }, [maxValue, minValue, secondaryAxisRatio, secondaryFractionDigits]);

      return (
        <View onLayout={onLayout} {...rest}>
          {layout && (
            <>
              <Svg
                width={layout.width}
                height={layout.height}
                viewBox={`0 0 ${layout.width} ${layout.height}`}
                fill="none">
                <G transform={`translate(${left}, ${top})`}>
                  <Path
                    d={bezierPath(width, height, normalizedData)}
                    stroke={theme.palette.primary}
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <Path
                    d={`M0 ${height} H ${width}`}
                    stroke={theme.palette.border}
                    strokeWidth={1}
                    strokeLinecap="round"
                  />
                  {selectedIndex !== undefined &&
                    selectedX !== undefined &&
                    selectedY !== undefined && (
                      <Circle
                        cx={selectedX}
                        cy={selectedY}
                        r={6}
                        fill={theme.palette.primary}
                        stroke={theme.palette.background}
                        strokeWidth={3}
                      />
                    )}
                </G>
              </Svg>
              <View
                style={[
                  styles.yLabels,
                  yLabels.length === 1 && styles.yLabelsSingleValue,
                  {top, left: left - yLabelsWidth, bottom},
                ]}>
                {yLabels.map((label) => (
                  <Text key={label} style={[styles.label, styles.yLabel]}>
                    {label.toFixed(fractionDigits)}
                  </Text>
                ))}
              </View>
              {y2Labels !== undefined && (
                <View
                  style={[
                    styles.yLabels,
                    y2Labels.length === 1 && styles.yLabelsSingleValue,
                    {top, right: right - y2LabelsWidth, bottom},
                  ]}>
                  {y2Labels.map((label) => (
                    <Text key={label} style={[styles.label, styles.y2Label]}>
                      {label.toFixed(secondaryFractionDigits)}
                    </Text>
                  ))}
                </View>
              )}
              <View
                style={[
                  styles.xLabels,
                  {bottom: bottom - xLabelsHeight - 10, left, right},
                ]}>
                {timeAxisLabels.map((label, index) => (
                  <Text
                    key={timeline[index]}
                    style={[
                      styles.label,
                      styles.xLabel,
                      {left: index * (width / (timeAxisLabels.length - 1))},
                    ]}>
                    {label}
                  </Text>
                ))}
              </View>
              <ChartPanGestureHandler
                onMouseLeave={hideTooltip}
                onGestureEvent={onGestureEvent}
                onTapEvent={onTapEvent}
                style={{position: 'absolute', left, top, right, bottom}}
              />
              {selectedIndex !== undefined &&
                timeSeries[selectedIndex] !== undefined && (
                  <TouchableWithoutFeedback onPress={hideTooltip}>
                    <Tooltip
                      style={[
                        styles.tooltip,
                        {
                          left: tooltipLeft,
                          top: tooltipTop,
                          right: tooltipRight,
                          bottom: tooltipBottom,
                        },
                      ]}
                      timestamp={
                        fromMillis +
                        selectedIndex *
                          ((toMillis - fromMillis) / (timeSeries.length - 1))
                      }
                      scope={
                        scope === ChartViewScope.Day
                          ? TooltipScope.Time
                          : TooltipScope.Date
                      }
                      value={timeSeries[selectedIndex]}
                      title={title}
                      fractionDigits={fractionDigits}
                      secondaryTitle={secondaryTitle}
                      secondaryValue={
                        secondaryAxisRatio !== undefined
                          ? timeSeries[selectedIndex] * secondaryAxisRatio
                          : undefined
                      }
                      secondaryFractionDigits={secondaryFractionDigits}
                    />
                  </TouchableWithoutFeedback>
                )}
            </>
          )}
        </View>
      );
    },
  ),
);
