import {RefSearcher} from './RefSearcher';
import {Either, error} from '../fp';
import {LocationSource} from '../Location';
import {SlashedParameterRefParserImpl} from '../RefParser';

/**
 * @example com.example://ref/x/x/x/x -> x/x/x/x
 */
export default class SlashedParameterRefSearcherImpl implements RefSearcher {
  private readonly _parser = new SlashedParameterRefParserImpl();

  constructor(
    private readonly _root: {
      readonly locationSource: LocationSource;
    },
  ) {}

  async find(): Promise<Either<string, void>> {
    const link = await this._root.locationSource.getInitial();
    if (!link.success) {
      return error(undefined);
    }
    const url = link.right;
    if (!url) {
      return error(undefined);
    }
    return this._parser.parse(url);
  }
}
