import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import SubscriptionSlot from './SubscriptionSlot';
import {View} from 'react-native';
import {PurchaseId} from '../../units';
import {expr} from 'mobx-utils';
import {useRoot, useStrings} from '../../Root/hooks';
import {PaymentMethod, SlotSubscriptionOffer} from '../../InAppOffersService';
import dayjs from 'dayjs';
import getHumanizeDuration from '../getters/getHumanizeDuration';
import {Millisecond} from '../../Time';
import {variance} from '../../styling';

export interface TableBodyViewProps {
  offerList: readonly SlotSubscriptionOffer[];
  selectedOffer?: SlotSubscriptionOffer;
  purchasedProductIds: ReadonlySet<PurchaseId>;
  onSelectPurchase: (productId: PurchaseId) => void;
  onSelectButtonPress: () => void;
  selectedPayment: PaymentMethod;
}

export default observer((props: TableBodyViewProps) => {
  const {
    offerList,
    purchasedProductIds,
    selectedOffer,
    onSelectPurchase,
    onSelectButtonPress,
    selectedPayment,
  } = props;
  const {right, left} = useMemo(() => {
    const point = Math.ceil(offerList.length / 2);
    return {left: offerList.slice(0, point), right: offerList.slice(point)};
  }, [offerList]);
  return (
    <RootSlots>
      <Col>
        {left.map((offer) => {
          return (
            <TableRow
              key={offer.purchaseId}
              offer={offer}
              selectedPayment={selectedPayment}
              purchasedProductIds={purchasedProductIds}
              selectedOffer={selectedOffer}
              onSelectPurchase={onSelectPurchase}
              onSelectButtonPress={onSelectButtonPress}
            />
          );
        })}
      </Col>
      <Col>
        {right.map((offer) => {
          return (
            <TableRow
              key={offer.purchaseId}
              offer={offer}
              selectedPayment={selectedPayment}
              purchasedProductIds={purchasedProductIds}
              selectedOffer={selectedOffer}
              onSelectPurchase={onSelectPurchase}
              onSelectButtonPress={onSelectButtonPress}
            />
          );
        })}
      </Col>
    </RootSlots>
  );
});

const RootSlots = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      1000: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginRight: -10,
        marginLeft: -10,
      },
    }),
  },
}));

const Col = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      1000: {
        flex: 1,
      },
    }),
  },
}));

interface TableRowProps {
  offer: SlotSubscriptionOffer;
  purchasedProductIds: ReadonlySet<PurchaseId>;
  selectedOffer?: SlotSubscriptionOffer;
  onSelectPurchase: (productId: PurchaseId) => void;
  onSelectButtonPress: () => void;
  selectedPayment: PaymentMethod;
}

const TableRow = observer((props: TableRowProps) => {
  const {
    offer,
    purchasedProductIds,
    selectedOffer,
    onSelectPurchase,
    onSelectButtonPress,
    selectedPayment,
  } = props;
  const selected = expr(() => selectedOffer?.purchaseId === offer.purchaseId);
  const disabled = expr(() => purchasedProductIds.has(offer.purchaseId));
  const onChoose = useCallback(() => {
    if (selected) {
      onSelectButtonPress();
    } else {
      if (!purchasedProductIds.has(offer.purchaseId)) {
        onSelectPurchase(offer.purchaseId);
      }
    }
  }, [
    offer,
    onSelectButtonPress,
    purchasedProductIds,
    onSelectPurchase,
    selected,
  ]);
  const {translation} = useRoot();
  const strings = useStrings();
  const period = offer.interval
    ? offer.interval === 1
      ? strings['common.shortMonth']
      : getHumanizeDuration(
          dayjs
            .duration(offer.interval, 'month')
            .asMilliseconds() as Millisecond,
          translation.localeTag,
        )
    : undefined;
  const payment = offer.payments.get(selectedPayment);
  if (!payment) {
    return null;
  }
  return (
    <SubscriptionSlot
      selected={selected}
      disabled={disabled}
      onChoose={onChoose}
      price={payment.uiPrice}
      currency={payment.currency}
      maxSlots={offer.maxSlots}
      period={period}
    />
  );
});
