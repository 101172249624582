import React from 'react';
import {observer} from 'mobx-react-lite';
import {useStrings, useTemplates} from '../../../../Root/hooks';
import {variance} from '../../../../styling';
import {Platform, Text, View} from 'react-native';
import {TotalPriceSectionProps} from '../../sections/TotalPriceSection';
import {SelectButton} from '../SelectButton';
import TotalPriceGift from './TotalPriceGift';
import TotalPriceRegular from './TotalPriceRegular';
import TotalPriceDiscounts from './TotalPriceDiscounts';
import TotalPriceTotal from './TotalPriceTotal';

export const TotalPrice = observer((props: TotalPriceSectionProps) => {
  const strings = useStrings();
  const template = useTemplates();
  const {
    onAdditionalOfferPurchasePress,
    onMainOfferPurchasePress,
    productOffer,
    subscriptionOffer,
    hideBtns,
    submitSubscriptionText = strings['action.subscribe'],
  } = props;
  const offer = subscriptionOffer || productOffer;
  if (!offer) {
    return null;
  }
  return (
    <>
      <RootView>
        <TotalPriceGift />
        {offer.discounts.length !== 0 && (
          <>
            <TotalPriceRegular
              currency={offer.currency}
              oldPrice={offer.uiOldPrice}
              save={offer.uiDiscountsDifferencePrice}
            />
            <TotalPriceDiscounts discounts={offer.discounts} />
          </>
        )}
        <TotalPriceTotal
          currency={offer.currency}
          totalPrice={offer.uiPrice}
          titleCalculation={`${
            offer.name ? `${offer.name} / ` : ''
          }${offer.pricePerMonth?.toFixed(2)} ${offer.currency} ${
            strings['common.per']
          } ${strings['common.shortMonth']}`}
        />
      </RootView>
      <Footer>
        {!hideBtns && (
          <>
            {productOffer && (
              <SelectButton
                onSelectPress={onMainOfferPurchasePress}
                title={
                  Platform.OS === 'web'
                    ? strings['action.buy']
                    : `${strings['subscription.poolMiners.getAccess']} / ${offer.uiPrice} ${offer.currency}`
                }
              />
            )}
            {subscriptionOffer ? (
              <>
                <SelectButton
                  onSelectPress={onAdditionalOfferPurchasePress}
                  title={
                    Platform.OS === 'web'
                      ? strings['action.buy']
                      : `${submitSubscriptionText} / ${offer.uiPrice} ${offer.currency}`
                  }
                />
                <Billed>
                  {template['totalPrice.subscriptionDescriptionPrice']({
                    duration: `${offer.interval} ${strings['common.shortMonth']}`,
                  })}
                </Billed>
              </>
            ) : null}
          </>
        )}
      </Footer>
    </>
  );
});

const Footer = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      1000: {
        borderColor: theme.palette.border,
        backgroundColor: theme.palette.back,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      },
    }),
  },
}));

const Billed = variance(Text)((theme) => ({
  root: {
    marginBottom: 15,
    textAlign: 'center',
    color: theme.palette.textSecondary,
  },
}));

const RootView = variance(View)((theme) => ({
  root: {
    borderTopWidth: 1,
    borderColor: theme.palette.border,
    borderBottomWidth: 1,
    ...theme.mediaQuery({
      1000: {
        borderWidth: 0,
        paddingHorizontal: 15,
      },
    }),
  },
}));
