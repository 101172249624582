import React, {RefObject, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {
  FlatList,
  FlatListProps,
  StyleProp,
  ViewStyle,
  View,
} from 'react-native';
import {DesktopWebAppFooter} from '../DesktopWebAppFooter';
import {useStyles} from '../styling';
import {isElement, isValidElementType} from 'react-is';

export interface PageFlatListProps<ItemT> extends FlatListProps<ItemT> {
  footerStyle?: StyleProp<ViewStyle>;
  scrollRef?: RefObject<FlatList>;
}

export default observer(
  <ItemT extends unknown>(props: PageFlatListProps<ItemT>) => {
    const {ListFooterComponent, footerStyle, scrollRef, ...rest} = props;
    const styles = useStyles(() => ({
      content: {
        flexGrow: 1,
      },
      listFooter: {
        flexGrow: 1,
      },
      footer: {
        marginTop: 'auto',
      },
      list: {
        flexGrow: 1,
        flexShrink: 1,
      },
    }));
    const Footer = useCallback(
      () => (
        <>
          {ListFooterComponent &&
            (isElement(ListFooterComponent) ? (
              ListFooterComponent
            ) : isValidElementType(ListFooterComponent) ? (
              <ListFooterComponent />
            ) : null)}
          <View style={styles.footer}>
            <DesktopWebAppFooter style={footerStyle} />
          </View>
        </>
      ),
      [ListFooterComponent, footerStyle, styles.footer],
    );
    return (
      <FlatList
        ListFooterComponent={Footer}
        ListFooterComponentStyle={styles.listFooter}
        contentContainerStyle={styles.content}
        ref={scrollRef}
        style={styles.list}
        {...rest}
      />
    );
  },
);
