import {Text, View, ViewProps} from 'react-native';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import React, {useMemo} from 'react';

import {useRoot} from '../../../../../Root/hooks';
import {useTheme} from '../../../../../styling';

export interface TooltipProps extends ViewProps {
  timestamp: number;
  value: number;
}

export default observer(({timestamp, value, style, ...rest}: TooltipProps) => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      ({
        root: {
          minWidth: 140,
          backgroundColor: theme.select(
            theme.chroma(theme.palette.foreground).alpha(0.8).hex(),
            theme.chroma(theme.palette.background).alpha(0.8).hex(),
          ),
          borderRadius: 2,
          padding: 10,
        },
        datetime: {
          ...theme.fontByWeight(),
          fontSize: 11,
          lineHeight: 15,
          color: theme.palette.primary,
        },
        value: {
          marginTop: 2,
          ...theme.fontByWeight('bold'),
          fontSize: 18,
          lineHeight: 25,
          ...theme.fontByWeight(),
          color: theme.select(
            theme.contrast(theme.palette.textPrimary),
            theme.palette.textPrimary,
          ),
        },
      } as const),
    [theme],
  );
  const {translation} = useRoot();
  const {localeTag} = translation;
  return (
    <View style={[styles.root, style]} {...rest}>
      <Text style={styles.datetime}>
        {dayjs(timestamp).locale(localeTag).format('DD.MM.YYYY HH:mm')}
      </Text>
      <Text style={styles.value}>{value.toFixed()}</Text>
    </View>
  );
});
