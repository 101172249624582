import {ApiStore} from '../ApiStore';
import {Either, error, success} from '../fp';
import {getFernetToken, setSelectedAccountId} from '../persistence';
import {AuthenticationErrorReason, AuthenticationSummary} from './AuthState';
import {RestoredSubscriptionMap, translateRestoredAccounts} from '../farmApi';
import {Platform} from 'react-native';
import {
  DeviceIdentification,
  DeviceIdentificationStateKind,
} from '../DeviceIdentification';
import {InAppPurchaseManager} from '../InAppPurchaseManager';
import {INTERNAL_AUTHENTICATION, SpecialLocation} from '../SpecialLocation';
import {LocationSource} from '../Location';

export interface RestoreFernetTokenResult {
  summary:
    | AuthenticationSummary.InternalLink
    | AuthenticationSummary.LocalStorage
    | AuthenticationSummary.PurchaseHistory
    | AuthenticationSummary.Registration
    | AuthenticationSummary.NewFarm;
  fernetToken: string;
}

export interface RestoreFernetTokenErrorByRestoring {
  reason: AuthenticationErrorReason.AccountSwitchRequired;
  restoredSubscriptionMap: RestoredSubscriptionMap;
}

export interface RestoreFernetTokenErrorByCommon {
  reason:
    | AuthenticationErrorReason.Registration
    | AuthenticationErrorReason.UpdateRequired
    | AuthenticationErrorReason.OAuthNeeded;
  raw: unknown;
}

export type RestoreFernetTokenError =
  | RestoreFernetTokenErrorByRestoring
  | RestoreFernetTokenErrorByCommon;

export default async (
  root: {
    readonly deviceIdentification: DeviceIdentification;
    readonly apiStore: ApiStore;
    readonly inAppPurchaseManager: InAppPurchaseManager;
    readonly specialLocation: SpecialLocation;
    readonly locationSource: LocationSource;
  },
  options?: {
    forceRegister?: boolean;
    fernetToken?: string;
  },
): Promise<Either<RestoreFernetTokenResult, RestoreFernetTokenError>> => {
  if (!options?.forceRegister) {
    const initial_ = await root.locationSource.getInitial();
    if (initial_.success) {
      const parsedInitial = await root.specialLocation.parse(initial_.right);
      if (parsedInitial.kind === INTERNAL_AUTHENTICATION) {
        return success({
          summary: AuthenticationSummary.InternalLink,
          fernetToken: parsedInitial.fernetToken,
        });
      }
    }

    const fernetToken_ = await getFernetToken();
    if (!fernetToken_.success) {
      console.warn(
        'Loading Fernet token from the local storage failed',
        fernetToken_.left,
      );
    }
    if (fernetToken_.success && fernetToken_.right !== null) {
      const fernetToken = fernetToken_.right;
      return success({
        summary: AuthenticationSummary.LocalStorage,
        fernetToken,
      });
    }

    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      const restore = await root.inAppPurchaseManager.authByPurchaseHistory();
      if (restore.success) {
        const map = translateRestoredAccounts(restore.right);
        if (map.size === 1) {
          const [first] = map.values();
          if (first.type === 'Temporary') {
            return success({
              summary: AuthenticationSummary.PurchaseHistory,
              fernetToken: first.token,
            });
          }
        }
        if (map.size >= 1) {
          return error({
            reason: AuthenticationErrorReason.AccountSwitchRequired,
            restoredSubscriptionMap: map,
          });
        }
      } else {
        console.warn('Loading purchase history failed', restore.left);
      }
    }
  }

  if (Platform.OS === 'web' && !options?.forceRegister) {
    return error({
      reason: AuthenticationErrorReason.OAuthNeeded,
      raw: null,
    });
  }

  if (
    root.deviceIdentification.state.kind !==
    DeviceIdentificationStateKind.Registered
  ) {
    return error({
      reason: AuthenticationErrorReason.Registration,
      raw: new Error('Device is not registered'),
    });
  }
  const register_ = await root.apiStore.client.call('register', {
    advert_id: root.deviceIdentification.state.result.advertId,
  });
  if (!register_.success) {
    return error({
      reason: AuthenticationErrorReason.Registration,
      raw: register_.left,
    });
  }
  await setSelectedAccountId(register_.right.user_info.id);
  if (
    (register_.right.token === null ||
      typeof register_.right.user_info.master_id === 'number') &&
    options?.fernetToken
  ) {
    return success({
      summary: AuthenticationSummary.NewFarm,
      fernetToken: options?.fernetToken,
    });
  }
  if (register_.right.token === null) {
    return error({
      reason: AuthenticationErrorReason.Registration,
      raw: null,
    });
  }
  return success({
    summary: AuthenticationSummary.Registration,
    fernetToken: register_.right.token,
  });
};
