import {action, observable, reaction, makeObservable} from 'mobx';
import {Service} from '../structure';
import {
  InAppOffers,
  Offer,
  OfferPurchaseType,
  SlotSubscriptionOffer,
} from '../InAppOffersService';
import {PurchaseId} from '../units';
import {CurrentSlotSubscription} from './CurrentSlotSubscription';
import {InAppPurchase} from '../InAppPurchase';

export default class CurrentSlotSubscriptionService
  implements Service, CurrentSlotSubscription
{
  @observable.ref private _offers?: SlotSubscriptionOffer[];
  constructor(
    private readonly _root: {
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchase: InAppPurchase;
    },
  ) {
    makeObservable(this);
  }

  get offers() {
    return this._offers;
  }

  private _setNewOffers = action((newOffers?: SlotSubscriptionOffer[]) => {
    this._offers = newOffers;
  });

  private _find(offers: Offer[], skus: ReadonlySet<PurchaseId>) {
    const candidates = offers.flatMap((_) =>
      _.purchaseType === OfferPurchaseType.SlotSubscription ? [_] : [],
    );
    return candidates.filter((_) => skus.has(_.purchaseId));
  }

  private _searchWhenFulfilled() {
    return reaction(
      () => this._root.inAppOffers.isLoadedIn,
      async (isLoadedIn) => {
        if (isLoadedIn) {
          const offers = this._find(
            this._root.inAppOffers.offers,
            this._root.inAppOffers.purchasedIds,
          );
          this._setNewOffers(offers);
        }
      },
      {fireImmediately: true},
    );
  }

  subscribe() {
    return this._searchWhenFulfilled();
  }
}
