import {MessagingIdentity} from './MessagingIdentity';
import {Service} from '../structure';
import {autorun, observable, runInAction, makeObservable} from 'mobx';
import {FcmToken} from '../units';
import {getMessaging, getToken, isSupported} from '../WebFirebase/messaging';
import {VAPID_KEY} from '../Messaging';
import {NotificationPermissions} from '../NotificationPermissions';

export default class WebMessagingIdentityService
  implements MessagingIdentity, Service
{
  constructor(
    private readonly _root: {
      readonly notificationPermissions: NotificationPermissions;
    },
  ) {
    makeObservable(this);
  }

  @observable private _token?: FcmToken;

  get token() {
    return this._token;
  }

  private async _initialize() {
    if (!(await isSupported())) {
      return;
    }
    const messaging = getMessaging();
    const serviceWorkerRegistration = await navigator.serviceWorker.register(
      '/sw.js',
    );
    const token = await getToken(messaging, {
      vapidKey: VAPID_KEY,
      serviceWorkerRegistration,
    });
    runInAction(() => {
      this._token = token as FcmToken;
    });
  }

  private _reinitializeWhenGrantedPermission() {
    return autorun(() => {
      if (
        this._token === undefined &&
        this._root.notificationPermissions.granted
      ) {
        // noinspection JSIgnoredPromiseFromCall
        this._initialize();
      }
    });
  }

  subscribe() {
    return this._reinitializeWhenGrantedPermission();
  }
}
