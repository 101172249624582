import React from 'react';
import {StyleProp, View, ViewStyle, Image} from 'react-native';
import PressableOpacity, {PressableOpacityProps} from './PressableOpacity';
import {Hoverable} from 'react-native-web-hooks';
import {useStyles} from '../styling';

interface StoreButton extends PressableOpacityProps {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
}

export const GooglePlayButton = (props: StoreButton) => {
  const styles = useStyles(() => ({
    content: {
      paddingHorizontal: 30,
      width: 230,
      alignItems: 'center',
    },
  }));
  return (
    <Button
      contentStyle={styles.content}
      Icon={
        <Image
          source={require('../assets/getInGooglePlay.png')}
          style={{height: 38, width: 156}}
        />
      }
      {...props}
    />
  );
};

export const AppStoreButton = (props: StoreButton) => {
  const styles = useStyles(() => ({
    content: {
      paddingHorizontal: 30,
      width: 230,
      alignItems: 'center',
    },
  }));
  return (
    <Button
      contentStyle={styles.content}
      Icon={
        <Image
          source={require('../assets/getInAppStore.png')}
          style={{height: 38, width: 139}}
        />
      }
      {...props}
    />
  );
};

export const SimpleGooglePlayButton = ({
  contentStyle,
  ...rest
}: StoreButton) => {
  const styles = useStyles(() => ({
    content: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      height: 21,
      width: 20,
    },
  }));
  return (
    <Button
      contentStyle={[styles.content, contentStyle]}
      Icon={
        <Image
          source={require('../assets/playStore.png')}
          style={styles.image}
        />
      }
      {...rest}
    />
  );
};

export const SimpleAppStoreButton = ({contentStyle, ...rest}: StoreButton) => {
  const styles = useStyles(() => ({
    content: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    image: {
      height: 22,
      width: 22,
    },
  }));
  return (
    <Button
      contentStyle={[styles.content, contentStyle]}
      Icon={
        <Image
          source={require('../assets/appleStore.png')}
          style={styles.image}
        />
      }
      {...rest}
    />
  );
};
interface ButtonProps extends StoreButton {
  Icon?: React.ReactNode;
}

const Button = ({
  style,
  contentStyle,
  Icon,
  disabled,
  ...rest
}: ButtonProps) => {
  const styles = useStyles((theme) => ({
    root: {},
    contentDisabled: {
      backgroundColor: theme.palette.disabled,
      opacity: 0.5,
    },
    content: {
      backgroundColor: theme.select(
        theme.palette.foreground,
        theme.palette.background,
      ),
      borderWidth: 1,
      borderColor: theme.select(theme.palette.background, theme.palette.border),
      borderRadius: 10,
      padding: 10,
      paddingHorizontal: 15,
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    hoveredButtonView: {
      backgroundColor: theme.palette.primary,
      borderColor: theme.palette.primary,
    },
  }));
  return (
    <PressableOpacity
      accessibilityRole="button"
      style={[styles.root, style]}
      disabled={disabled}
      {...rest}>
      <Hoverable>
        {(isHovered) => (
          <View
            style={[
              styles.content,
              contentStyle,
              isHovered && styles.hoveredButtonView,
              disabled && styles.contentDisabled,
            ]}>
            {Icon && Icon}
          </View>
        )}
      </Hoverable>
    </PressableOpacity>
  );
};
