import React from 'react';
import {ScrollView, Text, View} from 'react-native';
import {Divider} from '../../../components/Divider';
import {useStrings} from '../../../Root/hooks';
import DoublePlusSvg from '../../../assets/svg/circled/doublePlus.svg';
import {GroupLine} from './GroupLine';
import {WorkerGroupEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../../../styling';

interface MiningGroupBody {
  groups: WorkerGroupEntity[];
  onLinePress: (groupId: number) => void;
}

export const MiningGroupBody = observer((props: MiningGroupBody) => {
  const {groups, onLinePress} = props;
  if (!groups.length) {
    return <EmptyMiningGroupBody />;
  }
  return (
    <ScrollView style={{height: 200}}>
      <Divider />
      {groups.map((group) => (
        <GroupLine
          key={group.id}
          workerName={group.name}
          onPress={() => onLinePress(group.id)}
        />
      ))}
    </ScrollView>
  );
});

const EmptyMiningGroupBody = observer(() => {
  const styles = useStyles((theme) => ({
    root: {
      padding: 15,
    },
    emptyGroupText: {
      ...theme.fontByWeight('400'),
      fontSize: 18,
      lineHeight: 28,
      textAlign: 'center',
      color: theme.palette.textPrimary,
    },
    icon: {
      alignItems: 'center',
      marginBottom: 10,
    },
  }));
  const strings = useStrings();
  return (
    <View style={styles.root}>
      <View style={styles.icon}>
        <DoublePlusSvg />
      </View>
      <Text style={styles.emptyGroupText}>
        {strings['modal.moveWorkerToGroup.emptyDescription']}
      </Text>
    </View>
  );
});
