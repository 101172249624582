import React, {useCallback, useMemo} from 'react';
import {View, Image, Pressable, Text} from 'react-native';
import {observer} from 'mobx-react-lite';
import Badge from './Badge';
import OpenLinkSvg from '../../assets/svg/colorless/openLink.svg';
import AppStoreSvg from '../../assets/svg/colored/appStoreBlue.svg';
import GoogleStoreSvg from '../../assets/svg/colored/googlePlay.svg';
import Web from '../../assets/svg/colored/web.svg';
import {useRoot} from '../../Root/hooks';
import {useTheme, variance} from '../../styling';
import {sized} from '../../Svg';
import {Hoverable} from 'react-native-web-hooks';
import {Product} from '../../Products/Product';
import {Button, ButtonColor} from '../../components';
import {useDetectPlatform} from '../../styling/hooks/useDetectPlatform';
import {Url} from '../../units';

export type ListItemProps = {
  item: Product;
  index: number;
};

const ListItem = observer(({item}: ListItemProps) => {
  const {translation} = useRoot();
  const theme = useTheme();
  const {location} = useRoot();
  const openPressButton = useCallback(
    (link?: Url) => link && location.open(link),
    [location],
  );
  const onPressHeader = () => location.open(item.link);
  const {isWeb, isIOS, isMacOS, isAndroid, isWindows} = useDetectPlatform();

  const buttons = useMemo(() => {
    const btns = [];
    const googleLink = item.googleLink;
    const webLink = item.webLink;
    const appleLink = item.appleLink;
    const webBtn = {
      label: 'Web',
      link: webLink,
      icon: WebIcon,
      onPress: () => openPressButton(webLink),
    };
    const appleBtn = {
      label: 'App Store',
      link: appleLink,
      icon: AppStoreIcon,
      onPress: () => openPressButton(appleLink),
    };
    const googleBtn = {
      label: 'Google Play',
      link: googleLink,
      icon: GoogleStoreIcon,
      onPress: () => openPressButton(googleLink),
    };
    if (!isWeb) {
      if ((isAndroid || isWindows) && googleLink) {
        btns.push(googleBtn);
      } else if ((isAndroid || isWindows) && !googleLink && webLink) {
        btns.push(webBtn);
      }

      if ((isIOS || isMacOS) && appleLink) {
        btns.push(appleBtn);
      } else if ((isIOS || isMacOS) && !appleLink && webLink) {
        btns.push(webBtn);
      }
    } else {
      if (googleLink && appleLink) {
        btns.push(googleBtn, appleBtn);
      } else {
        if (googleLink) {
          btns.push(googleBtn);
        } else if (webLink) {
          btns.push(webBtn);
        }

        if (appleLink) {
          btns.push(appleBtn);
        } else if (webLink) {
          btns.push(webBtn);
        }
      }
    }

    return btns;
  }, [item, isAndroid, isIOS, isWeb, isWindows, isMacOS, openPressButton]);
  return (
    <Root>
      <Hoverable>
        {(isHovered) => (
          <Pressable onPress={onPressHeader}>
            <TopComponent>
              <Image
                source={{uri: item.topIcon}}
                style={{width: 32, height: 32, marginRight: 12}}
              />
              <Header style={[isHovered && {color: theme.palette.primary}]}>
                {item.title}
              </Header>
              <OpenLinkIcon
                style={[{marginLeft: 'auto'}]}
                color={
                  isHovered ? theme.palette.primary : theme.palette.secondary
                }
              />
            </TopComponent>
          </Pressable>
        )}
      </Hoverable>
      <ContentBlock>
        <TextBlock>{item.text[translation.localeTag]}</TextBlock>

        {item.badge && (
          <Badge
            text={item.badge.text[translation.localeTag]}
            icon={item.badge.icon}
            type={item.badge.theme}
          />
        )}
        <Image
          style={{width: '100%', height: 140, marginTop: 'auto'}}
          source={{uri: item.img}}
        />
      </ContentBlock>
      <BottomComponent>
        {buttons.map((btn, i) => {
          return (
            <PressableButton onPress={btn.onPress} key={i}>
              <Button
                Icon={btn.icon}
                style={{paddingHorizontal: 10}}
                color={ButtonColor.Black}>
                {btn.label}
              </Button>
            </PressableButton>
          );
        })}
      </BottomComponent>
    </Root>
  );
});

const OpenLinkIcon = sized(OpenLinkSvg, 18);

const Root = variance(View)((theme) => ({
  root: {
    borderColor: theme.palette.border,
    borderWidth: 1,
    borderRadius: 12,
    flex: 1,
  },
}));
const PressableButton = variance(Pressable)(() => ({
  root: {
    flexGrow: 1,
  },
}));
const TopComponent = variance(View)((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    paddingRight: 20,
    borderBottomWidth: 1,
    flexGrow: 1,
    borderBottomColor: theme.palette.border,
  },
}));
const ContentBlock = variance(View)(() => ({
  root: {
    paddingTop: 10,
    paddingHorizontal: 15,
    flexGrow: 1,
    gap: 15,
  },
}));
const Header = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.darkText,
    fontSize: 18,
  },
}));
const TextBlock = variance(Text)((theme) => ({
  root: {
    color: theme.palette.darkText,
    lineHeight: 26,
    fontSize: 16,
    ...theme.fontByWeight('400'),
  },
}));
const BottomComponent = variance(View)((theme) => ({
  root: {
    padding: 12,
    marginTop: 'auto',
    borderTopWidth: 1,
    borderTopColor: theme.palette.border,
    backgroundColor: theme.palette.back,
    flexDirection: 'row',
    gap: 10,
    overflow: 'hidden',
    borderRadius: 12,
    flexWrap: 'wrap',
  },
}));
const AppStoreIcon = sized(AppStoreSvg, 20, 20);
const GoogleStoreIcon = sized(GoogleStoreSvg, 20, 20);
const WebIcon = sized(Web, 20, 20);

export default ListItem;
