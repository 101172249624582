import {Size} from '../ApiStore';
import {isEmpty, last} from 'lodash';
import {PixelRatio} from 'react-native';

export default abstract class StaticAdvertHelper {
  static getImage(record: Partial<Record<Size, string>>) {
    const items = Object.values(record).filter((_) => !isEmpty(_));
    const list = [1, 1.5, 2, 3, 3.5];
    const ratio = PixelRatio.get();
    const index = list.indexOf(ratio);
    if (index === -1) {
      return last(items);
    }
    const candidate = items[index];
    if (!candidate) {
      return last(items);
    }
    return candidate;
  }
}
