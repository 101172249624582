import {ConfigurationValues} from '../Configuration';
import {useRoot} from '../Root/hooks';
import {Platform} from 'react-native';
import {expr} from 'mobx-utils';
import {FULFILLED} from '../AsyncAtom';
import {AdSpot} from '../ApiStore';
import {first} from 'lodash';

export default (
  getUrl: (values: ConfigurationValues) => string,
  _doneUrl?: string,
): string => {
  const {
    configuration,
    translation,
    appearance,
    advertHelper,
    windowDimensionsState,
  } = useRoot();
  const adBanner = expr(() => {
    const state = advertHelper.state;
    if (state?.status !== FULFILLED) {
      return undefined;
    }
    return windowDimensionsState.window.width > 1280
      ? first(state.result.spotByBannerList.get(AdSpot.TutorialDesktop))
      : first(state.result.spotByBannerList.get(AdSpot.TutorialMobile));
  });

  const url = getUrl(configuration.values);
  const localizedUrl = url.replace('{lang}', translation.localeTag);
  const themedUrl = appearance.isDark ? `${localizedUrl}black` : localizedUrl;
  const doneUrl = _doneUrl ?? `${themedUrl}#done`;
  const next = encodeURIComponent(doneUrl);
  const adBannerParams = new URLSearchParams();
  if (adBanner) {
    const {title, body, icon} = adBanner;
    const firstAction = first(adBanner.actions);
    adBannerParams.set('ads_title', advertHelper.translate(title) || '');
    adBannerParams.set('ads_body', advertHelper.translate(body) || '');
    if (icon) {
      adBannerParams.set('ads_img', icon);
    }
    if (firstAction && firstAction.link !== undefined) {
      adBannerParams.set('ads_url', firstAction.link);
    }
  }
  return `${themedUrl}?next=${next}&embed=true&platform=${
    Platform.OS
  }&${adBannerParams.toString()}`;
};
