import {RouterSource} from '../structure';

export const TICK = Symbol();

export interface Scheduler {
  start(): void;
  stop(): void;
  restart(): void;
  readonly ticks: RouterSource<{[TICK]: void}>;
}
