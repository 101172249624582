import {Server} from '../JsonRpc';
import {
  CryptoFarmConnectedServerCalls,
  CryptoFarmServerCalls,
  CryptoFarmServerNotifications,
} from '../ApiStore';
import {batchDisposers} from '../structure';
import {GroupController} from './GroupController';
import {success, error} from '../fp';

export default (
  server: Server<
    CryptoFarmServerCalls & CryptoFarmConnectedServerCalls,
    CryptoFarmServerNotifications
  >,
  groups: GroupController,
) =>
  batchDisposers(
    server.call('create_group', async (params, response) => {
      const _result = await groups.create(params.name, params.worker_ids);
      if (_result.success) {
        return response.respond(success(null));
      }
      return response.respond(
        error({
          code: 3008,
          message: 'Group name is already in use',
        }),
      );
    }),
    server.call('rename_group', async (params, response) => {
      await groups.rename(params.group_id, params.name);
      return response.respond(success(null));
    }),
    server.call('delete_group', async (params, response) => {
      await groups.delete(params.group_id);
      return response.respond(success(null));
    }),
    server.call('set_workers_to_group', async (params, response) => {
      await groups.setWorkers(params.group_id, params.worker_ids);
      return response.respond(success(null));
    }),
  );
