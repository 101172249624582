import {Bitcoin, DemonstrationDatabaseRecord} from './DemonstrationDatabase';
import {ReadonlyDeep} from 'type-fest';
import {Millisecond} from '../utils/time';
import {BitcoinPerHash} from './MiningService';
import selectAllWorkersById from './selectAllWorkersById';
import getWorkerEstimatedResult from './getWorkerEstimatedResult';

export default (
  db: ReadonlyDeep<DemonstrationDatabaseRecord>,
  now: Millisecond,
  bitcoinPerHash: BitcoinPerHash,
): Bitcoin => {
  const allWorkers = Object.values(selectAllWorkersById(db.groups));
  return allWorkers
    .flatMap((_) => _.miningResults)
    .concat(Object.values(db.balanceByWorkerId))
    .concat(
      allWorkers.map((worker) =>
        getWorkerEstimatedResult(
          db.lastWorkerStatsUpdate,
          now,
          worker,
          bitcoinPerHash,
        ),
      ),
    )
    .reduce((sum, _) => sum + _, 0);
};
