import {Platform} from 'react-native';

const dsn = Platform.select({
  android: 'https://fcfa90aecadb4b51b7ab125f5e7171b7@snt.cryptotab.farm/57',
  ios: 'https://2f7cc38b7e28473883b05ce716b1c828@snt.cryptotab.farm/60',
  web: 'https://61cf7688e3ff420a9f34ab9602e145d6@snt.cryptotab.farm/75',
});

const ignoreErrors = [
  /Cannot read property 'getReadModeExtract' of undefined/i,
  /Cannot read property 'getReadModeRender' of undefined/i,
  /Cannot read property 'getReadModeConfig' of undefined/i,
];

export default {
  dsn,
  ignoreErrors,
};
