import {Opaque} from 'type-fest';

export type Satoshi = Opaque<number, 'Satoshi'>;
export type Bitcoin = Opaque<number, 'Bitcoin'>;

export const satoshiToBitcoin = (satoshi: Satoshi) =>
  (satoshi / 1e8) as Bitcoin;

export const bitcoinToSatoshi = (bitcoin: Bitcoin) =>
  (bitcoin * 1e8) as Satoshi;
