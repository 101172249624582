import {Server} from '../JsonRpc';
import {
  CryptoFarmServerCalls,
  CryptoFarmServerNotifications,
} from '../ApiStore';
import {batchDisposers} from '../structure';
import {success} from '../fp';
import {WorkerStateController} from './WorkerStateController';

export default (
  server: Server<CryptoFarmServerCalls, CryptoFarmServerNotifications>,
  workerState: WorkerStateController,
) =>
  batchDisposers(
    server.call('ping', async (params, response) => {
      workerState.ping();
      return response.respond(success('pong'));
    }),
  );
