import {HOUR, SECOND} from '../utils/time';

export const MINUTES_IN_WEEK = 7 * 24 * 60;

export const MAX_CORES = 4;

export const SLOT_COUNT = 100;

export const DEFAULT_GROUP_NAME = 'Default Group';

export const DEFAULT_SETTINGS = {speed: 50} as const;

export const STATE_TIMEOUT = SECOND;

export const MINING_INTERVAL = HOUR;

export const DEFAULT_MEMORY = {free: 16000, total: 16000};

export const DEFAULT_CPU = {
  aes: true,
  arch: 'x86_64',
  avx: true,
  avx2: true,
  avx512f: true,
  bmi2: true,
  cat_l3: true,
  l2: 1048576,
  l3: 8388608,
  nodes: 1,
  osxsave: true,
  packages: 0,
  pdpe1gb: true,
  popcnt: true,
  sse2: true,
  sse4_1: true,
  ssse3: true,
  vm: true,
  xop: true,
};

export const DEFAULT_HOST_INFO = {
  hostname: 'Demo Farm',
  autorun: true,
  os_info: 'NT',
  user_name: 'demo',
  memory: DEFAULT_MEMORY,
  cpu: DEFAULT_CPU,
};
