import {Platform} from 'react-native';
import {OffersRequester} from './OffersRequester';
import {ConnectedClient} from '../../ContextClient';
import {InAppPurchaseManager} from '../../InAppPurchaseManager';
import OfferRequesterImpl from './OfferRequesterImpl';
import {Configuration} from '../../Configuration';
import WebOffersRequesterImpl from './WebOffersRequesterImpl';
import {PurchaseDiscount} from '../../PurchasePromoService';
import {ErrorRepository} from '../../ErrorRepository';

export default class OffersRequesterFactory {
  constructor(
    protected readonly _root: {
      readonly connectedClient: ConnectedClient;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly configuration: Configuration;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly errorRepository: ErrorRepository;
    },
  ) {}

  create() {
    return Platform.select<OffersRequester>({
      web: new WebOffersRequesterImpl(this._root),
      default: new OfferRequesterImpl(this._root),
    });
  }
}
