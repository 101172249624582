import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {ScrollView, StyleProp, ViewStyle} from 'react-native';
import {createStylesHook} from '../styling';

export type RootViewProps = {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
};

export default observer(function RootView(props: RootViewProps) {
  const styles = useStyles();
  const style = useMemo(
    () => [styles.root, props.style],
    [styles.root, props.style],
  );
  const contentContainerStyle = useMemo(
    () => [styles.container, props.contentContainerStyle],
    [styles, props.contentContainerStyle],
  );
  return (
    <ScrollView style={style} contentContainerStyle={contentContainerStyle}>
      {props.children}
    </ScrollView>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
  },
  container: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
