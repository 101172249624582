import {batchDisposers, Service} from '../structure';
import {Log} from './Log';
import {autorun} from 'mobx';
import IAPLogContent from './IAPLogContent';
import {ReadyState} from '../Connection';
import {
  CONSUME_PURCHASE_ANDROID,
  CONSUME_PURCHASE_ANDROID_REQUEST,
  END_CONNECTION,
  END_CONNECTION_REQUEST,
  FINISH_TRANSACTION,
  FINISH_TRANSACTION_REQUEST,
  GET_AVAILABLE_PURCHASES,
  GET_AVAILABLE_PURCHASES_REQUEST,
  GET_PENDING_PURCHASES_IOS,
  GET_PENDING_PURCHASES_IOS_REQUEST,
  GET_PRODUCTS,
  GET_PRODUCTS_REQUEST,
  GET_PURCHASE_HISTORY,
  GET_PURCHASE_HISTORY_REQUEST,
  GET_RECEIPT_IOS,
  GET_RECEIPT_IOS_REQUEST,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_REQUEST,
  InAppPurchase,
  INIT_CONNECTION,
  INIT_CONNECTION_REQUEST,
  PURCHASE_ERROR_LISTENER,
  PURCHASE_UPDATED_LISTENER,
  REQUEST_PRODUCT,
  REQUEST_PRODUCT_REQUEST,
  REQUEST_SUBSCRIPTION,
  REQUEST_SUBSCRIPTION_REQUEST,
} from '../InAppPurchase';
import {InAppPurchaseManager} from '../InAppPurchaseManager';

export default class IAPLogBridgeService implements Service {
  constructor(
    private readonly _root: {
      readonly inAppPurchase: InAppPurchase;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly log: Log;
    },
  ) {}

  private readonly _stringify = (value: any) => {
    return JSON.stringify(value, null, 2);
  };

  private readonly _logger = (value: unknown, name?: string) => {
    this._root.log.write({
      body: new IAPLogContent(this._stringify(value), name).body,
    });
  };

  private _onChangeAppPurchaseState() {
    return autorun(() => {
      const {iapReadyState} = this._root.inAppPurchaseManager;
      this._root.log.write({
        body: new IAPLogContent(ReadyState[iapReadyState], 'IAP_CHANGE_STATE')
          .body,
      });
    });
  }

  subscribe() {
    return batchDisposers(
      this._onChangeAppPurchaseState(),
      this._root.inAppPurchase.events.listen(
        INIT_CONNECTION_REQUEST,
        (result) => this._logger(result, 'INIT_CONNECTION_REQUEST'),
      ),
      this._root.inAppPurchase.events.listen(INIT_CONNECTION, (result) =>
        this._logger(result, 'INIT_CONNECTION'),
      ),
      this._root.inAppPurchase.events.listen(END_CONNECTION, (result) =>
        this._logger(result, 'END_CONNECTION'),
      ),
      this._root.inAppPurchase.events.listen(END_CONNECTION_REQUEST, (result) =>
        this._logger(result, 'END_CONNECTION_REQUEST'),
      ),
      this._root.inAppPurchase.events.listen(
        GET_AVAILABLE_PURCHASES_REQUEST,
        (result) => this._logger(result, 'GET_AVAILABLE_PURCHASES_REQUEST'),
      ),
      this._root.inAppPurchase.events.listen(
        GET_AVAILABLE_PURCHASES,
        (result) => this._logger(result, 'GET_AVAILABLE_PURCHASES'),
      ),
      this._root.inAppPurchase.events.listen(
        GET_PURCHASE_HISTORY_REQUEST,
        (result) => this._logger(result, 'GET_PURCHASE_HISTORY_REQUEST'),
      ),
      this._root.inAppPurchase.events.listen(GET_PURCHASE_HISTORY, (result) =>
        this._logger(result, 'GET_PURCHASE_HISTORY'),
      ),
      this._root.inAppPurchase.events.listen(
        GET_SUBSCRIPTIONS_REQUEST,
        (result) => this._logger(result, 'GET_SUBSCRIPTIONS_REQUEST'),
      ),
      this._root.inAppPurchase.events.listen(GET_SUBSCRIPTIONS, (result) =>
        this._logger(result, 'GET_SUBSCRIPTIONS'),
      ),
      this._root.inAppPurchase.events.listen(
        REQUEST_SUBSCRIPTION_REQUEST,
        (result) => this._logger(result, 'REQUEST_SUBSCRIPTION_REQUEST'),
      ),
      this._root.inAppPurchase.events.listen(REQUEST_SUBSCRIPTION, (result) =>
        this._logger(result, 'REQUEST_SUBSCRIPTION'),
      ),
      this._root.inAppPurchase.events.listen(
        REQUEST_PRODUCT_REQUEST,
        (result) => this._logger(result, 'REQUEST_PRODUCT_REQUEST'),
      ),
      this._root.inAppPurchase.events.listen(GET_PRODUCTS_REQUEST, (result) =>
        this._logger(result, 'GET_PRODUCTS_REQUEST'),
      ),
      this._root.inAppPurchase.events.listen(GET_PRODUCTS, (result) =>
        this._logger(result, 'GET_PRODUCTS'),
      ),
      this._root.inAppPurchase.events.listen(REQUEST_PRODUCT, (result) =>
        this._logger(result, 'REQUEST_PRODUCT'),
      ),
      this._root.inAppPurchase.events.listen(
        CONSUME_PURCHASE_ANDROID_REQUEST,
        (result) => this._logger(result, 'CONSUME_PURCHASE_ANDROID_REQUEST'),
      ),
      this._root.inAppPurchase.events.listen(
        CONSUME_PURCHASE_ANDROID,
        (result) => this._logger(result, 'CONSUME_PURCHASE_ANDROID'),
      ),
      this._root.inAppPurchase.events.listen(
        FINISH_TRANSACTION_REQUEST,
        (result) => this._logger(result, 'FINISH_TRANSACTION_REQUEST'),
      ),
      this._root.inAppPurchase.events.listen(FINISH_TRANSACTION, (result) =>
        this._logger(result, 'FINISH_TRANSACTION'),
      ),
      this._root.inAppPurchase.events.listen(
        GET_PENDING_PURCHASES_IOS_REQUEST,
        (result) => this._logger(result, 'GET_PENDING_PURCHASES_IOS_REQUEST'),
      ),
      this._root.inAppPurchase.events.listen(
        GET_PENDING_PURCHASES_IOS,
        (result) => this._logger(result, 'GET_PENDING_PURCHASES_IOS'),
      ),
      this._root.inAppPurchase.events.listen(
        GET_RECEIPT_IOS_REQUEST,
        (result) => this._logger(result, 'GET_RECEIPT_IOS_REQUEST'),
      ),
      this._root.inAppPurchase.events.listen(GET_RECEIPT_IOS, (result) =>
        this._logger(result, 'GET_RECEIPT_IOS'),
      ),
      this._root.inAppPurchase.events.listen(
        PURCHASE_UPDATED_LISTENER,
        (result) => this._logger(result, 'PURCHASE_UPDATED_LISTENER'),
      ),
      this._root.inAppPurchase.events.listen(GET_PURCHASE_HISTORY, (result) =>
        this._logger(result, 'GET_PURCHASE_HISTORY'),
      ),
      this._root.inAppPurchase.events.listen(
        GET_PURCHASE_HISTORY_REQUEST,
        (result) => this._logger(result, 'GET_PURCHASE_HISTORY_REQUEST'),
      ),
      this._root.inAppPurchase.events.listen(
        PURCHASE_ERROR_LISTENER,
        (result) => this._logger(result, 'PURCHASE_ERROR_LISTENER'),
      ),
    );
  }
}
