import BasePurchaseRequesterImpl from './BasePurchaseRequesterImpl';
import {RequestPurchaseParams} from './PurchaseRequester';
import {InAppPurchaseManager, PurchaseResult} from '../InAppPurchaseManager';
import {ApiStore} from '../ApiStore';
import {InAppOffers} from '../InAppOffersService';
import {ErrorRepository} from '../ErrorRepository';
import {PendingPurchasesResolver} from '../PendingPurchasesResolver';
import {PurchaseDiscount} from '../PurchasePromoService';
import {Either, error, success} from '../fp';
import {getVersion} from 'react-native-device-info';
import {Auth} from '../Auth';
import {ConnectedClient} from '../ContextClient';
import {Configuration} from '../Configuration';
import {
  GlobalError,
  NOT_CONNECTED,
  NotConnectedError,
  SERVER_ERROR,
} from '../Error';
import {UtmUrlListener} from '../UtmUrlService';
import {GiftState} from '../Gift/Gift';

export default class AndroidPurchaseRequesterImpl extends BasePurchaseRequesterImpl {
  constructor(
    protected readonly _root: {
      readonly apiStore: ApiStore;
      readonly connectedClient: ConnectedClient;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly configuration: Configuration;
      readonly auth: Auth;
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly pendingPurchasesResolver: PendingPurchasesResolver;
      readonly errorRepository: ErrorRepository;
      readonly utmUrl: UtmUrlListener;
      readonly gift: GiftState;
    },
  ) {
    super(_root);
  }

  protected async requestProduct(
    params: RequestPurchaseParams,
  ): Promise<PurchaseResult> {
    const get_ = await this._getAdditionalParams(params);
    if (!get_.success) {
      return get_;
    }
    const body = {
      ...get_.right,
      subscriptionOfferTokenAndroid: undefined,
      purchaseTokenAndroid: params.purchaseTokenAndroid,
      sku: params.purchaseId,
    };
    return this._root.inAppPurchaseManager.requestProduct(body);
  }

  protected async requestSubscription(
    params: RequestPurchaseParams,
  ): Promise<PurchaseResult> {
    const get_ = await this._getAdditionalParams(params);
    if (!get_.success) {
      return get_;
    }
    const body = {
      ...get_.right,
      subscriptionOfferTokenAndroid: params.subscriptionOfferTokenAndroid,
      sku: params.purchaseId,
      purchaseTokenAndroid: params.purchaseTokenAndroid,
    };
    return this._root.inAppPurchaseManager.requestSubscription(body);
  }

  private async _getAdditionalParams({
    poolMinerId,
    purchaseId,
  }: RequestPurchaseParams): Promise<Either<ObfuscatedParams, GlobalError>> {
    const authState = this._root.auth.state;
    if (authState?.kind !== 'Connected') {
      return error(
        this._root.errorRepository.create<NotConnectedError>({
          kind: NOT_CONNECTED,
        }),
      );
    }
    const farmId = authState.accountId;
    const isDev = this._root.configuration.values.isDev;
    const appVersion = getVersion();
    const profile = new Map();
    profile.set('v', appVersion);
    if (isDev) {
      profile.set('env', 'dev');
    }

    const meta = new Map();
    const utm = await this._root.utmUrl.getUtms();
    if (utm) {
      meta.set('utm', utm);
    }
    const gift = await this._root.gift.queryGift(purchaseId);
    if (gift) {
      const metaGift = {
        pool_miner_config_id: gift.pool_miner_config?.id || null,
        plan_id: gift.plan?.id || null,
      };
      meta.set('gift', metaGift);
    }
    if (this._code !== undefined) {
      meta.set('promo', this._code);
    }
    if (poolMinerId !== undefined) {
      meta.set('pool_miner_id', poolMinerId);
    }

    if (meta.size > 0) {
      const response = await this._root.connectedClient.apply(
        'purchase_meta_create',
        {
          meta: Object.fromEntries(meta),
        },
      );
      if (!response.success) {
        return error(
          this._root.errorRepository.create({
            kind: SERVER_ERROR,
            raw: response.left,
          }),
        );
      }
      profile.set('m', response.right.id);
    }

    const obfuscatedAccountIdAndroid = farmId.toString();
    const obfuscatedProfileIdAndroid = JSON.stringify(
      Object.fromEntries(profile),
    );
    return success({obfuscatedAccountIdAndroid, obfuscatedProfileIdAndroid});
  }
}

type ObfuscatedParams = {
  obfuscatedAccountIdAndroid: string | undefined;
  obfuscatedProfileIdAndroid: string | undefined;
};
