import React, {useMemo} from 'react';
import {MainListItem} from './extra/MainListItem';
import {InsideListItem} from './extra/InsideListItem';
import {List} from './extra/List';
import {useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {CommonSectionProps} from './NotificationsSettingsScreen';
import {FarmLogVariant} from '../Notifications';

export default observer(({state, onCheck}: CommonSectionProps) => {
  const strings = useStrings();
  const styles = useMemo(
    () =>
      ({
        root: {
          marginBottom: 20,
        },
      } as const),
    [],
  );

  return (
    <List style={styles.root}>
      <MainListItem
        value={state[FarmLogVariant.Mining].value}
        onValueChange={(value) => onCheck(FarmLogVariant.Mining, value)}
        title={strings['notificationsSettings.mining']}
      />
      <InsideListItem
        value={state[FarmLogVariant.MiningPaused].value}
        onValueChange={(value) => onCheck(FarmLogVariant.MiningPaused, value)}
        title={strings['notificationsSettings.miningPaused']}
      />
      <InsideListItem
        value={state[FarmLogVariant.MiningRestarted].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.MiningRestarted, value)
        }
        title={strings['notificationsSettings.miningRestarted']}
      />
      <InsideListItem
        value={state[FarmLogVariant.NewMiner].value}
        onValueChange={(value) => onCheck(FarmLogVariant.NewMiner, value)}
        title={strings['notificationsSettings.newMinerAdded']}
      />
      <InsideListItem
        value={state[FarmLogVariant.MinerOnline].value}
        onValueChange={(value) => onCheck(FarmLogVariant.MinerOnline, value)}
        title={strings['notificationsSettings.minerIsOnline']}
      />
      <InsideListItem
        value={state[FarmLogVariant.MinerOffline].value}
        onValueChange={(value) => onCheck(FarmLogVariant.MinerOffline, value)}
        title={strings['notificationsSettings.minerIsOffline']}
      />
      <InsideListItem
        value={state[FarmLogVariant.MinerOverheated].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.MinerOverheated, value)
        }
        title={strings['notificationsSettings.minerIsOverheated']}
      />
      <InsideListItem
        value={state[FarmLogVariant.Confirmation].value}
        onValueChange={(value) => onCheck(FarmLogVariant.Confirmation, value)}
        title={strings['notificationsSettings.confirmation']}
        bordered={false}
      />
    </List>
  );
});
