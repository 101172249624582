import {action, observable, makeObservable} from 'mobx';
import {bind} from '../fp';
import {Disconnect} from './Disconnect';

export default class DisconnectImpl implements Disconnect {
  @observable private _enabled = false;

  constructor() {
    makeObservable(this);
  }

  get enabled() {
    return this._enabled;
  }

  toggle = bind(() => {
    this.setEnabled(!this._enabled);
  }, this);

  @action setEnabled(enabled: boolean) {
    this._enabled = enabled;
  }
}
