import React, {useState} from 'react';
import {Text, View, TouchableWithoutFeedback} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {observer} from 'mobx-react-lite';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  PressableOpacity,
} from '../components';
import {useRoot, useStrings} from '../Root/hooks';
import {RootParamList} from '../Navigation';
import {LoaderWrapper} from './extra/LoaderWrapper';
import sleep from '../utils/sleep';
import Modal from '../modals/BaseModal';
import {createStylesHook, useTheme} from '../styling';
import {sized} from '../Svg';
import StarSvg from '../assets/svg/colorless/star.svg';

export interface RateModalNavigationProps {
  navigation: StackNavigationProp<RootParamList>;
}

export const RateModal = observer(() => {
  const {rateApp} = useRoot();
  const strings = useStrings();
  const theme = useTheme();
  const navigation = useNavigation<RateModalNavigationProps['navigation']>();
  const [starsNumber, setStarts] = useState<number>(0);
  const styles = useStyles();
  const onSelectStar = async (rating: number) => {
    setStarts(rating);
    await Promise.all([sleep(1000), rateApp.sendEvent(rating)]);
    if (rating < 4) {
      navigation.replace('Comment', {starsNumber: rating});
    } else {
      navigation.replace('RateUs', {starsNumber: rating});
    }
  };
  const _visibleLoader = starsNumber > 0;
  return (
    <Modal contentStyle={styles.modalView} onDismiss={rateApp.closeModal}>
      <View>
        <Text style={styles.titleText}>{strings['modal.rate.title']}</Text>
        <Text style={styles.subTitleText}>
          {strings['modal.rate.subTitle']}
        </Text>
        <View style={styles.starsView}>
          {new Array(5).fill('').map((_, i) => (
            <TouchableWithoutFeedback
              disabled={starsNumber !== 0}
              key={i}
              onPress={() => onSelectStar(i + 1)}>
              <View style={styles.starView}>
                <StarIcon
                  color={
                    i < starsNumber
                      ? theme.palette.primary
                      : theme.palette.disabled
                  }
                />
              </View>
            </TouchableWithoutFeedback>
          ))}
        </View>

        <LoaderWrapper loading={_visibleLoader} style={styles.footerView}>
          <View>
            <PressableOpacity
              onPress={rateApp.skipReview}
              style={styles.skipButton}>
              <Button color={ButtonColor.Primary}>
                {strings['action.skip']}
              </Button>
            </PressableOpacity>
            <PressableOpacity onPress={rateApp.remindLater}>
              <Button variant={ButtonVariant.Highlighted}>
                {strings['action.remindMeLater']}
              </Button>
            </PressableOpacity>
          </View>
        </LoaderWrapper>
      </View>
    </Modal>
  );
});

const useStyles = createStylesHook((theme) => ({
  modalView: {
    justifyContent: 'center',
    maxWidth: 450,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  titleText: {
    fontSize: 24,
    lineHeight: 32,
    marginBottom: 10,
    letterSpacing: 0.09,
    color: theme.palette.textPrimary,
    ...theme.fontByWeight('700'),
  },
  subTitleText: {
    fontSize: 14,
    lineHeight: 22,
    letterSpacing: 0.06,
    marginBottom: 25,
    color: theme.palette.textPrimary,
    ...theme.fontByWeight(),
  },
  starsView: {
    borderWidth: 2,
    borderColor: theme.palette.primary,
    flexDirection: 'row',
    borderRadius: 4,
    paddingVertical: 35,
    justifyContent: 'center',
    backgroundColor: theme.palette.background,
    marginBottom: 30,
  },
  starView: {
    padding: 5,
  },
  skipButton: {
    marginBottom: 15,
  },
  footerView: {
    position: 'relative',
  },
}));

const StarIcon = sized(StarSvg, 42);
