import {PageRequestImpl} from '../PageRequest';
import {WithdrawalEntity} from '../ApiStore';
import {error, success} from '../fp';
import {ConnectedClient} from '../ContextClient';

export default class WithdrawalHistoryRequestFactory {
  public static readonly WITHDRAW_HISTORY_PAGE_SIZE = 30;

  constructor(
    private readonly _root: {
      readonly connectedClient: ConnectedClient;
    },
  ) {}

  create() {
    return new PageRequestImpl<WithdrawalEntity>(async (page, size) => {
      const params = {page, size};
      const result = await this._root.connectedClient.call(
        'get_withdrawals',
        params,
      );
      if (!result.success) {
        return error(result.left);
      }
      return success(result.right.items);
    }, WithdrawalHistoryRequestFactory.WITHDRAW_HISTORY_PAGE_SIZE);
  }
}
