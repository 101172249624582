import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {Text} from 'react-native';
import Switch from '../../components/Switch';
import {Mode} from '../../DashboardMode';
import {useRoot, useStrings} from '../../Root/hooks';
import {useTheme, variance} from '../../styling';
import {Container} from '../../components/Grid';
import {ADVANCED_HEIGHT} from '../constants';

export default observer(() => {
  const {dashboardModeService} = useRoot();
  const onModeChange = useCallback(
    (checked: boolean) => {
      dashboardModeService.changeMode(checked ? Mode.Regular : Mode.Simple);
    },
    [dashboardModeService],
  );
  const theme = useTheme();
  const strings = useStrings();
  return (
    <Root>
      <Title>{strings['dashboard.proMode']}</Title>
      <Switch
        value={dashboardModeService.mode === Mode.Regular}
        onValueChange={onModeChange}
        backgroundActive={theme.palette.success}
      />
    </Root>
  );
});

const Root = variance(Container)((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    borderWidth: 1,
    borderColor: theme.palette.border,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
    height: ADVANCED_HEIGHT,
    marginBottom: 10,
    borderRadius: 15,
  },
}));

const Title = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 22,
    color: theme.palette.textPrimary,
  },
}));
