import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigationGetIsFocused} from '../Navigation/hooks';
import {useRoot} from '../Root/hooks';
import {useOpenLink} from '../Links';
import ConfirmAddWorkerModal from './ConfirmAddWorkerModal';
import SuccessfullyAddedModal from './SuccessfullyAddedModal';
import ErrorModal from './ErrorModal';
import {ErrorModalProps} from './types';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useBoolean} from '../hooks';
import {autorun, observable} from 'mobx';

export type LinkWorkerModalsManagerProps = {
  linkKey: string;
  goToDashboard: () => void;
};

export default observer(function LinkWorkerModalsManager(
  props: LinkWorkerModalsManagerProps,
) {
  const {linkKey, goToDashboard} = props;
  const [visibleConfirmModal, showConfirmModal, hideConfirmModal] =
    useBoolean(false);
  const [visibleSuccessModal, showSuccessModal, hideSuccessModal] =
    useBoolean(false);
  const [errorModalState, setErrorModalState] = useState<ErrorModalProps>({
    visible: false,
  });
  const [workerInfoBox] = useState(() => observable.box<WorkerEntity>());

  const {auth, connectedClient, configuration} = useRoot();

  const hideAllModals = useCallback(() => {
    hideConfirmModal();
    setErrorModalState({visible: false});
  }, [hideConfirmModal]);

  const linkWorker = useCallback(async () => {
    hideAllModals();
    const linkWorker_ = await connectedClient.call('link_worker', {
      link_key: linkKey,
    });
    if (linkWorker_.success) {
      showSuccessModal();
    } else {
      setErrorModalState({
        visible: true,
        error: linkWorker_.left,
      });
    }
  }, [hideAllModals, connectedClient, linkKey, showSuccessModal]);

  const onSubmitConfirmModal = () => {
    hideConfirmModal();
    return linkWorker();
  };
  const onCloseConfirmModal = () => {
    hideConfirmModal();
    goToDashboard();
  };

  const onCloseSuccessModal = () => {
    hideSuccessModal();
    goToDashboard();
  };

  const onSubmitError = useOpenLink(
    `${configuration.values.aboutUrl}/feedback/`,
  );
  const onCloseError = () => {
    setErrorModalState({visible: false});
    goToDashboard();
  };

  const getIsFocused = useNavigationGetIsFocused();
  useEffect(
    () =>
      autorun(async () => {
        if (getIsFocused() && auth.isConnected) {
          // noinspection JSIgnoredPromiseFromCall
          hideAllModals();
          const worker_ = await connectedClient.call('get_worker_by_link_key', {
            link_key: linkKey,
          });
          if (worker_.success) {
            workerInfoBox.set(worker_.right.worker);
            showConfirmModal();
          } else {
            setErrorModalState({
              visible: true,
              error: worker_.left,
            });
          }
        }
      }),
    [
      connectedClient,
      auth,
      getIsFocused,
      linkKey,
      hideAllModals,
      showConfirmModal,
      workerInfoBox,
    ],
  );

  return (
    <>
      {visibleConfirmModal && (
        <ConfirmAddWorkerModal
          onSubmit={onSubmitConfirmModal}
          onClose={onCloseConfirmModal}
          workerInfo={workerInfoBox.get()}
        />
      )}
      {visibleSuccessModal && (
        <SuccessfullyAddedModal
          onSubmit={onCloseSuccessModal}
          onClose={onCloseSuccessModal}
        />
      )}
      {errorModalState.visible && (
        <ErrorModal
          onSubmit={onSubmitError}
          onClose={onCloseError}
          error={errorModalState.error}
        />
      )}
    </>
  );
});
