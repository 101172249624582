import {define, DEMONSTRATION_DATABASE} from '../persistence';
import {ReadonlyDeep} from 'type-fest';
import {action, flow, observable, makeObservable} from 'mobx';
import {MINING_INTERVAL} from './constants';
import {
  AfterUpdateListener,
  BeforeUpdateListener,
  DemonstrationDatabase,
  DemonstrationDatabaseRecord,
} from './DemonstrationDatabase';
import {defaultsDeep} from 'lodash';
import {Millisecond} from '../Time';
import {Disposer, Service} from '../structure';
import {CancellablePromise} from '../CancellablePromise';

export default class DemonstrationDatabaseService
  implements DemonstrationDatabase, Service
{
  @observable.ref private _state = DEFAULT_DATABASE;
  private _beforeUpdateListeners = new Set<BeforeUpdateListener>();
  private _afterUpdateListeners = new Set<AfterUpdateListener>();

  constructor() {
    makeObservable(this);
  }

  get state(): ReadonlyDeep<DemonstrationDatabaseRecord> {
    return this._state;
  }

  private _flow?: CancellablePromise<unknown>;

  private _fetch = flow(function* (this: DemonstrationDatabaseService) {
    this._state = yield getDatabase();
    defaultsDeep(this._state, DEFAULT_DATABASE);
    return this._state;
  });

  async fetch() {
    for (;;) {
      try {
        await this._flow;
      } catch (ignore) {}
      if (this._flow === undefined) {
        break;
      }
    }
    try {
      const _flow = this._fetch();
      this._flow = _flow;
      return await _flow;
    } finally {
      this._flow = undefined;
    }
  }

  private _update = flow(function* (
    this: DemonstrationDatabaseService,
    apply: (
      current: DemonstrationDatabaseRecord,
    ) => Promise<DemonstrationDatabaseRecord>,
  ) {
    const previous = this._state;
    let current: DemonstrationDatabaseRecord = yield apply(previous);
    for (const beforeUpdateListener of this._beforeUpdateListeners) {
      current = yield beforeUpdateListener(previous, current);
    }
    // This check assumes that the database is an immutable object
    if (this._state !== current) {
      yield setDatabase(current);
      this._state = current;
      for (const afterUpdateListener of this._afterUpdateListeners) {
        yield afterUpdateListener(previous, current);
      }
    }
  });

  async update(...args: Parameters<DemonstrationDatabaseService['_update']>) {
    for (;;) {
      try {
        await this._flow;
      } catch (ignore) {}
      if (this._flow === undefined) {
        break;
      }
    }
    try {
      const _flow = this._update(...args);
      this._flow = _flow;
      return await _flow;
    } finally {
      this._flow = undefined;
    }
  }

  beforeUpdate(listener: BeforeUpdateListener) {
    this._beforeUpdateListeners.add(listener);
    return (() => {
      this._beforeUpdateListeners.delete(listener);
    }) as Disposer;
  }

  afterUpdate(listener: AfterUpdateListener) {
    this._afterUpdateListeners.add(listener);
    return (() => {
      this._afterUpdateListeners.delete(listener);
    }) as Disposer;
  }

  resetState = flow(function* (
    this: DemonstrationDatabaseService,
    record: DemonstrationDatabaseRecord,
  ) {
    yield setDatabase(record);
    this._state = record;
  });

  clear = flow(function* (this: DemonstrationDatabaseService) {
    yield setDatabase();
    this._state = DEFAULT_DATABASE;
  });

  subscribe() {
    // noinspection JSIgnoredPromiseFromCall
    this.fetch();
    return (() => this.reset()) as Disposer;
  }

  @action reset() {
    this._state = DEFAULT_DATABASE;
    this._beforeUpdateListeners.clear();
    this._afterUpdateListeners.clear();
  }
}

export const DEFAULT_DATABASE: DemonstrationDatabaseRecord = {
  groups: [],
  balanceByWorkerId: {},
  nextGroupId: 1,
  lastWorkerStatsUpdate: new Date().getTime() as Millisecond,
  updateInterval: MINING_INTERVAL,
  logSettings: {exclude: false, groups: {}},
  logs: [],
  nextWorkerId: 1,
};

const [_getDatabase, _setDatabase] = define<DemonstrationDatabaseRecord>(
  DEMONSTRATION_DATABASE,
);

export const getDatabase = async () => {
  const list = await _getDatabase();
  return list.success && list.right !== null ? list.right : DEFAULT_DATABASE;
};

export const setDatabase = async (
  db?: ReadonlyDeep<DemonstrationDatabaseRecord>,
) => {
  await _setDatabase(db);
};
