import {observer} from 'mobx-react-lite';
import {
  createStackNavigator,
  StackNavigationOptions,
} from '@react-navigation/stack';
import {useStrings} from '../../Root/hooks';
import AuthBinding from './AuthBinding';
import BottomTab, {BottomTabParamList} from '../BottomTab/BottomTab';
import {WorkerStatisticsScreen} from '../../WorkerStatisticsScreen';
import WithdrawalHistoryBinding from './WithdrawalHistoryBinding';
import {PaymentSuccessScreen} from '../../PaymentSuccessScreen';
import NotificationsSettingsScreen from '../../NotificationsSettingsScreen';
import ScheduleBinding from './ScheduleBinding';
import {DashboardSettingsScreen} from '../../DashboardSettingsScreen';
import React from 'react';
import {Bitcoin, FarmId} from '../../ApiStore';
import {
  EnvironmentFormScreen,
  EnvironmentListScreen,
  DatabaseImportScreen,
} from '../../DebugScreen';
import LogBinding from './LogBinding';
import AccessRecoveryBinding from './AccessRecoveryBinding';
import bottomTabScreenOptions from '../BottomTab/screenOptions';
import screenOptions from './screenOptions';

import {useHeaderHeight} from '../hooks';
import {NavigationState, NavigatorScreenParams} from '@react-navigation/native';
import {PartialState} from '@react-navigation/routers/src/types';
import AccountSwitchBinding from './AccountSwitchBinding';
import OAuthSplashBinding from './OAuthSplashBinding';
import QRCodeScannerBinding from './QRCodeScannerBinding';
import FarmActionBinding from './FarmActionBinding';
import WorkerDetailsBinding from './WorkerDetailsBinding';
import PurchaseBinding from './PurchaseBinding';
import {useTheme} from '../../styling';
import AddWorkerScreenBinding from './AddWorkerScreenBinding';
import {RenewAccessBinding} from './RenewAccessBinding';
import CheckAuthBinding from './CheckAuthBinding';
import TutorialBinding from './TutorialBinding';
import {
  CommentModal,
  RateModal,
  RateUsRedirectModal,
  ThanksModal,
} from '../../RateApp';
import {RootStack} from './index';
import AdvertSplashBinding from './AdvertSplashBinding';
import QuickStartBinding from './QuickStartBinding';
import FinishQuickStartBinding from './FinishQuickStartBinding';
import QuickStartModalBinding from './QuickStartModalBinding';
import {ISODateString} from '../../Time';
import PoolMinerActivatedBinding from './PoolMinerActivatedBinding';
import PaymentCompletedBinding from './PaymentCompletedBinding';
import SelectConfigurationBinding from './SelectConfigurationBinding';
import ProductsBinding from './ProductsBinding';

export type RootParamList = {
  Root: NavigatorScreenParams<BottomTabParamList>;
  Auth?: {
    onSuccess?: PartialState<NavigationState> | NavigationState;
    farmId?: FarmId;
    variant?: 'default' | 'affiliate' | 'promo';
  };
  PaymentCompleted: undefined;
  CheckAuth: undefined;
  AccountSwitch: undefined;
  AccessRecovery: undefined;
  WithdrawalHistory: {goToAffiliateToken?: string} | undefined;
  PaymentSuccess: {
    amount: Bitcoin;
  };
  WorkerStatistics: {
    workerId: string;
  };
  WorkerDetails: {
    workerId: string;
  };
  NotificationsSettings: undefined;
  Plan?: {
    onSuccess?: PartialState<NavigationState> | NavigationState;
    index?: number;
    goToAffiliateToken?: string;
  };
  Products: undefined;
  SelectConfiguration: undefined;
  DashboardSettings: undefined;
  QRCodeScanner: undefined;
  Schedule: {
    workerIds: string[];
    ownerName?: string;
    goBackState?: PartialState<NavigationState> | NavigationState;
  };
  OAuthSplash: undefined;
  RateApp: undefined;
  EnvironmentForm: undefined;
  EnvironmentList: undefined;
  DatabaseImport: undefined;
  Log: undefined;
  FarmAction:
    | {
        key: string;
        action?: 'add';
      }
    | {
        code: string;
        action?: 'addcode';
      };
  AddWorker: undefined;
  RenewAccess: {
    workerId: string;
    goToAffiliateToken?: string;
  };
  Tutorial?: {
    onSuccess?: PartialState<NavigationState> | NavigationState;
  };
  Rate: undefined;
  Comment: {starsNumber: number};
  ThanksModal: undefined;
  RateUs: {starsNumber: number};
  NotFound: undefined;
  PoolMinerActivated:
    | {
        poolExpiredDate: ISODateString;
        poolHashrate: number;
      }
    | undefined;
  QuickStart: undefined;
  QuickStartModal: undefined;
  FinishQuickStart:
    | {
        selectedIndex: number;
        recommendedIndex: number;
      }
    | undefined;
  OAuthComplete: undefined;
  AdvertSplash: undefined;
};

const {Navigator, Screen, Group} = createStackNavigator<RootParamList>();

export default observer(() => {
  const strings = useStrings();
  const theme = useTheme();
  const headerHeight = useHeaderHeight();
  return (
    <Navigator initialRouteName="Root">
      <Group
        screenOptions={(props) => screenOptions(props, theme, headerHeight)}>
        <Screen
          name="Root"
          component={BottomTab}
          options={(props) => bottomTabScreenOptions(props, theme)}
        />
        <Screen
          name="WorkerDetails"
          options={{
            title: strings['screenTitle.workerDetails'],
            gestureResponseDistance: 30,
          }}
          component={WorkerDetailsBinding}
        />
        <Screen
          name="WorkerStatistics"
          component={WorkerStatisticsScreen}
          options={{
            title: strings['screenTitle.statistics'],
          }}
        />
        <Screen name="AccessRecovery" component={AccessRecoveryBinding} />
        <Screen name="Auth" component={AuthBinding} />
        <Screen
          name="CheckAuth"
          component={CheckAuthBinding}
          options={{
            headerShown: false,
            animationEnabled: false,
            cardStyle: {backgroundColor: 'transparent'},
          }}
        />
        <Screen
          name="OAuthSplash"
          component={OAuthSplashBinding}
          options={{headerShown: false}}
        />
        <Screen name="AccountSwitch" component={AccountSwitchBinding} />
        <Screen
          name="WithdrawalHistory"
          options={{title: strings['screenTitle.withdrawHistory']}}
          component={WithdrawalHistoryBinding}
        />
        <Screen name="PaymentSuccess" component={PaymentSuccessScreen} />
        <Screen
          name="NotificationsSettings"
          component={NotificationsSettingsScreen}
          options={{title: strings['screenTitle.notificationsSettings']}}
        />
        <Screen
          name="Plan"
          component={PurchaseBinding}
          options={{
            gestureResponseDistance: 20,
          }}
        />
        <Screen
          name="Schedule"
          component={ScheduleBinding}
          options={{title: strings['screenTitle.scheduler']}}
        />
        <Screen
          name="DashboardSettings"
          component={DashboardSettingsScreen}
          options={{
            title: strings['screenTitle.dashboardSettings'],
            gestureResponseDistance: 20,
          }}
        />
        <Screen
          name="Products"
          component={ProductsBinding}
          options={{
            title: strings['screenTitle.products'],
          }}
        />
        <Screen
          name="QRCodeScanner"
          component={QRCodeScannerBinding}
          options={{
            gestureEnabled: false,
            title: strings['screenTitle.qrCodeScanner'],
          }}
        />
        <Screen
          name="FarmAction"
          component={FarmActionBinding}
          options={{
            headerShown: false,
            animationEnabled: false,
            cardStyle: {backgroundColor: 'transparent'},
          }}
        />
        <Screen
          name="EnvironmentForm"
          options={{title: 'Environment Form'}}
          component={EnvironmentFormScreen}
        />
        <Screen
          name="EnvironmentList"
          options={{title: 'Environment List'}}
          component={EnvironmentListScreen}
        />
        <Screen
          name="DatabaseImport"
          options={{title: 'Database Import'}}
          component={DatabaseImportScreen}
        />
        <Screen name="Log" component={LogBinding} />
        <Screen
          name="AddWorker"
          options={{title: strings['screenTitle.addWorker']}}
          component={AddWorkerScreenBinding}
        />
        <Screen
          name="RenewAccess"
          options={{title: strings['screenTitle.renewAccess']}}
          component={RenewAccessBinding}
        />
        <Screen
          name="QuickStart"
          options={{title: strings['screenTitle.quickStart']}}
          component={QuickStartBinding}
        />
      </Group>
      <Group screenOptions={modalScreenOptions}>
        <Screen name="Tutorial" component={TutorialBinding} />
        <Screen name="Rate" component={RateModal} />
        <Screen name="RateUs" component={RateUsRedirectModal} />
        <Screen name="Comment" component={CommentModal} />
        <Screen
          name="PoolMinerActivated"
          component={PoolMinerActivatedBinding}
        />
        <Screen
          name="SelectConfiguration"
          component={SelectConfigurationBinding}
          options={(props) => ({
            ...screenOptions(props, theme, headerHeight),
            title: strings['chooseConfigurationScreen.title'],
            animationEnabled: false,
          })}
        />
        <Screen name="PaymentCompleted" component={PaymentCompletedBinding} />
        <Screen name="QuickStartModal" component={QuickStartModalBinding} />
        <Screen name="FinishQuickStart" component={FinishQuickStartBinding} />
        <Screen name="ThanksModal" component={ThanksModal} />
        <Screen name="OAuthComplete" component={RootStack} />
        <Screen name="AdvertSplash" component={AdvertSplashBinding} />
        <Screen name="NotFound" component={RootStack} />
      </Group>
    </Navigator>
  );
});

const modalScreenOptions: StackNavigationOptions = {
  animationTypeForReplace: 'pop',
  headerShown: false,
  cardStyle: {backgroundColor: 'transparent'},
  presentation: 'transparentModal',
  cardOverlayEnabled: false,
  gestureEnabled: false,
  cardStyleInterpolator: ({current: {progress}}) => ({
    cardStyle: {
      opacity: progress.interpolate({
        inputRange: [0, 0.5, 0.9, 1],
        outputRange: [0, 0.25, 0.7, 1],
      }),
    },
  }),
};
