import {Text, TextProps, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useStrings} from '../Root/hooks';
import {useStyles} from '../styling';

export interface SlotsDescriptionProps extends TextProps {
  slots: number;
  miners: number;
}

export default observer((props: SlotsDescriptionProps) => {
  const {slots, miners, style, ...rest} = props;
  const styles = useStyles((theme) => ({
    root: {
      alignItems: 'flex-end',
    },
    slots: {
      marginBottom: 2,
    },
    text: {
      ...theme.fontByWeight(),
      fontSize: 14,
      lineHeight: 20,
      letterSpacing: 0.0371429,
      textAlign: 'center',
      color: theme.palette.textPrimary,
    },
    bold: theme.fontByWeight('bold'),
  }));
  const strings = useStrings();
  return (
    <View style={styles.root}>
      <Text style={[styles.text, styles.slots, style]} {...rest}>
        <Text style={styles.bold}>{strings['accountSwitch.slots']}: </Text>
        {slots.toFixed()}
      </Text>
      <Text style={[styles.text, style]}>
        <Text style={styles.bold}>{strings['accountSwitch.miners']}: </Text>
        {miners.toFixed()}
      </Text>
    </View>
  );
});
