import {Opaque} from 'type-fest';
import {DiscountCode} from './VerifyPromoCodeResponse';
import {Second} from '../../Time';

export type ConnectParams = {
  farm_id: number;
  timezone: string;
  utc_offset: number;
};

export type ConnectResult = {
  tags: ConnectionTag[];
  purchase_tags: string[];
  settings: Record<string, boolean>;
  promo_code: DiscountCode | null;
  promo_code_ttl: Second | null;
};

export const CONNECTION_TAG = Symbol();
export type ConnectionTag = Opaque<string, typeof CONNECTION_TAG>;

export const NODEMO_TAG = 'nodemo' as ConnectionTag;
export const FULLTUT_TAG = 'fulltut' as ConnectionTag;
export const INTERTUT_TAG = 'intertut' as ConnectionTag;
export const NOCALC_TAG = 'nocalc' as ConnectionTag;
export const MAKEREVIEW_TAG = 'makereview' as ConnectionTag;
export const REVIEWDONE_TAG = 'reviewdone' as ConnectionTag;
export const QUICKSECOND_TAG = 'quicksecond' as ConnectionTag;
export const QUICKNOSECOND_TAG = 'quicknosecond' as ConnectionTag;
export const NOQUICKSTART_TAG = 'noquickstart' as ConnectionTag;
export const NOQUICKADD_TAG = 'noquickadd' as ConnectionTag;
export const NOQUICKTUTORIAL_TAG = 'noquicktutorial' as ConnectionTag;
