import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../../../styling';
import {View, Text, Platform} from 'react-native';
import {sized} from '../../../Svg';
import {SvgProps} from 'react-native-svg';
import {
  BnbSvg,
  BtcSvg,
  CtcSvg,
  EthSvg,
  MaticSvg,
  SolSvg,
  TrxSvg,
  UsdtSvg,
} from '../../../assets/svg/crypto';
import {useStrings} from '../../../Root/hooks';

export default observer(function WebCurrency() {
  const strings = useStrings();
  if (Platform.OS !== 'web') {
    return null;
  }
  return (
    <Container>
      <Title>{strings['cryptoCurrency.description']}</Title>
      <Icons>
        {getIcon(BtcSvg, true)}
        {getIcon(EthSvg)}
        {getIcon(CtcSvg)}
        {getIcon(MaticSvg)}
        {getIcon(UsdtSvg)}
        {getIcon(BnbSvg)}
        {getIcon(SolSvg)}
        {getIcon(TrxSvg)}
      </Icons>
    </Container>
  );
});
const getIcon = (Icon: React.ComponentType<SvgProps>, noOffset?: boolean) => {
  const SizedIcon = sized(Icon, 20, 20);
  return <SizedIcon style={noOffset ? {} : {marginLeft: 15}} />;
};

const Container = variance(View)((theme) => ({
  root: {
    marginTop: 10,
    ...theme.mediaQuery({
      1000: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
      },
    }),
  },
}));

const Title = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    marginBottom: 10,
    fontSize: 14,
    lineHeight: 18,
    ...theme.mediaQuery({
      1000: {
        marginBottom: 0,
        marginRight: 10,
        textAlign: 'right',
      },
    }),
  },
}));

const Icons = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
}));
