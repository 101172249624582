import {Theme} from './Theme';
import {Platform, ScaledSize, ViewStyle} from 'react-native';
import {Palette} from './Coloring';
import color, {UniversalColor} from '../../color';
import getContrastColor from '../util/getContrastColor';
import enrich from '../../color/color';
import {FontWeight} from './Fonts';
import chroma, {Color} from 'chroma-js';
import {MediaQuery} from './Adaptive';

export default abstract class BaseThemeImpl implements Theme {
  protected constructor(
    private readonly _window: ScaledSize,
    private readonly _screen: ScaledSize,
  ) {}

  get window() {
    return this._window;
  }
  get screen() {
    return this._screen;
  }
  mediaQuery(query: MediaQuery) {
    const queryKeys = Object.keys(query).map(Number);
    let result = {};
    const screenWidth = this.window.width;
    for (const q of queryKeys) {
      if (screenWidth >= q) {
        result = {...result, ...query[q]};
      }
    }
    return result;
  }

  abstract get palette(): Palette;
  contrast<T extends UniversalColor>(this: Theme, _color: T): T {
    const result = getContrastColor(
      enrich(_color),
      this.palette.foreground,
      this.palette.background,
    );
    return (
      typeof _color === 'object'
        ? enrich(result)
        : typeof _color === 'number'
        ? enrich(result).num()
        : result
    ) as T;
  }

  chroma(this: Theme, _color: UniversalColor): Color {
    return color(_color);
  }

  mix(_back: UniversalColor, _front: UniversalColor) {
    const back = chroma(_back);
    const front = chroma(_front);
    const alpha = front.alpha();
    const r = alpha * front.get('rgb.r') + (1 - alpha) * back.get('rgb.r');
    const g = alpha * front.get('rgb.g') + (1 - alpha) * back.get('rgb.g');
    const b = alpha * front.get('rgb.b') + (1 - alpha) * back.get('rgb.b');
    return chroma.rgb(r, g, b);
  }

  fontByWeight(weight: FontWeight = 'normal') {
    return Platform.select({
      android: {fontFamily: fontByWeightMap[weight]},
      default: {fontFamily: 'Arial', fontWeight: weight},
      web: {fontFamily: 'Inter', fontWeight: weight},
    });
  }

  bar(elevation: number, _background?: string): ViewStyle {
    const background = _background ?? this.palette.background;
    return this.select(
      {
        backgroundColor: background,
        elevation,
        shadowColor: background,
        shadowOffset: {width: 0, height: elevation / 2},
        shadowOpacity: (elevation / 24) * 0.58,
        shadowRadius: (elevation / 24) * 16,
        zIndex: elevation,
        borderWidth: 1,
        borderColor: this.palette.border,
      },
      {
        borderWidth: 1,
        borderColor: this.palette.border,
        backgroundColor: background,
        zIndex: elevation,
      },
    );
  }

  readonly spacing = [0, 4, 8, 12, 16, 24, 32, 48, 64] as const;

  select<T>(this: Theme, light: T, dark: T): T {
    return chroma(this.palette.background).luminance() >= 0.5 ? light : dark;
  }
}

export const fontByWeightMap = {
  100: 'Roboto-Thin',
  200: 'Roboto-Thin',
  300: 'Roboto-Light',
  400: 'Roboto-Regular',
  normal: 'Roboto-Regular',
  500: 'Roboto-Medium',
  600: 'Roboto-Bold',
  700: 'Roboto-Bold',
  bold: 'Roboto-Bold',
  800: 'Roboto-Black',
  900: 'Roboto-Black',
} as const;
