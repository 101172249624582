import React from 'react';
import {
  Modal,
  View,
  Text,
  TouchableWithoutFeedback,
  ScrollView,
  ModalProps,
  GestureResponderEvent,
  StyleProp,
  TextStyle,
  ViewStyle,
  TouchableOpacity,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import Button, {ButtonColor, ButtonVariant} from './Button';
import {useStyles} from '../styling';
import PressableOpacity from './PressableOpacity';

export interface PickerItem {
  readonly id: string | number;
  readonly title: string;
}

export interface RenderPickerItemProps {
  onPress: () => void | undefined;
  style: StyleProp<TextStyle>;
  title: string;
  item?: PickerItem;
}

export interface PickerModalProps extends ModalProps {
  items: readonly PickerItem[];
  pickedItemId?: PickerItem['id'];
  defaultItemTitle?: PickerItem['title'];
  onPick?: (id?: PickerItem['id']) => void;
  closeText: string;
  onPressAway?: (event: GestureResponderEvent) => void;
  onCancelPress?: (event: GestureResponderEvent) => void;
  renderPickerItem?: (props: RenderPickerItemProps) => React.ReactElement;

  modalStyle?: StyleProp<ViewStyle>;
  panelStyle?: StyleProp<ViewStyle>;
}

export default observer(
  ({
    items,
    pickedItemId,
    defaultItemTitle,
    onPick,
    closeText,
    onPressAway,
    onCancelPress,
    renderPickerItem,
    modalStyle,
    panelStyle,
    ...rest
  }: PickerModalProps) => {
    const styles = useStyles((theme) => ({
      scroll: {
        flexGrow: 1,
      },
      container: {
        flexGrow: 1,
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
      },
      panel: {
        alignSelf: 'center',
        marginVertical: 50,
        width: 360,
        maxWidth: '80%',
        backgroundColor: theme.palette.background,
        borderWidth: 1,
        borderColor: theme.palette.border,
        borderRadius: 16,
        overflow: 'hidden',
        ...theme.bar(20),
        paddingVertical: 10,
      },
      item: {
        padding: 16,
        ...theme.fontByWeight(),
        fontSize: 12,
        lineHeight: 14,
      },
      itemPicked: {
        ...theme.fontByWeight('bold'),
      },
      close: {
        alignSelf: 'center',
      },
    }));
    return (
      <>
        <Modal
          animationType="fade"
          transparent
          hardwareAccelerated
          statusBarTranslucent={false}
          style={modalStyle}
          {...rest}>
          <TouchableWithoutFeedback onPress={onPressAway}>
            <View style={styles.scroll}>
              <ScrollView
                style={styles.scroll}
                contentContainerStyle={styles.container}>
                <TouchableWithoutFeedback onPress={noop}>
                  <View style={[styles.panel, panelStyle]}>
                    {defaultItemTitle !== undefined && (
                      <Item
                        title={defaultItemTitle}
                        onPick={onPick}
                        picked={pickedItemId === undefined}
                      />
                    )}
                    {items.map((pickerItem, index) => (
                      <Item
                        key={index}
                        item={pickerItem}
                        title={pickerItem.title}
                        picked={pickerItem.id === pickedItemId}
                        onPick={onPick}
                        renderPickerItem={renderPickerItem}
                      />
                    ))}
                    <TouchableOpacity onPress={onCancelPress}>
                      <Button
                        style={styles.close}
                        variant={ButtonVariant.Text}
                        color={ButtonColor.Primary}>
                        {closeText}
                      </Button>
                    </TouchableOpacity>
                  </View>
                </TouchableWithoutFeedback>
              </ScrollView>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </>
    );
  },
);

export interface PickerModalItemProps {
  item?: PickerItem;
  title: string;
  picked: boolean;
  onPick: PickerModalProps['onPick'] | undefined;
  renderPickerItem?: (props: RenderPickerItemProps) => React.ReactElement;
  icon?: JSX.Element;
}

const Item = observer((props: PickerModalItemProps) => {
  const {item, title, picked, onPick, renderPickerItem} = props;
  const styles = useStyles((theme) => ({
    root: {
      ...theme.fontByWeight(),
      fontSize: 14,
      lineHeight: 16,
      color: theme.palette.textPrimary,
    },
    rootPicked: {
      ...theme.fontByWeight('bold'),
    },
    itemView: {
      padding: 15,
    },
  }));
  const textStyle: StyleProp<TextStyle> = [
    styles.root,
    picked && styles.rootPicked,
  ];
  const onPress = () => onPick?.(item?.id);
  return renderPickerItem ? (
    renderPickerItem({onPress, style: textStyle, title, item})
  ) : (
    <PressableOpacity onPress={onPress}>
      <View style={styles.itemView}>
        <Text style={textStyle}>{title}</Text>
      </View>
    </PressableOpacity>
  );
});

const noop = () => {};
