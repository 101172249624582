import React, {PropsWithChildren} from 'react';
import {
  Pressable,
  ScrollView,
  StyleProp,
  ViewProps,
  ViewStyle,
} from 'react-native';
import Portal from '../Portal';
import {observer} from 'mobx-react-lite';
import {createStylesHook, useTheme} from '../styling';
import {ModalBackdrop, ModalButtonList} from './components';
import {ButtonDirection, ModalButton} from './components/ModalButtonList';
import * as Animatable from 'react-native-animatable';
import {sized} from '../Svg';
import CloseSvg from '../assets/svg/colorless/close.svg';
import {Hoverable} from 'react-native-web-hooks';
import {KeyboardAvoidingView} from 'react-native-keyboard-controller';

export interface ModalProps extends ViewProps {
  onDismiss?: () => void;
  buttons?: ModalButton[];
  buttonsDirection?: ButtonDirection;
  contentStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  extraComponent?: React.ReactNode;
  scrollEnabled?: boolean;
  hideCloseIcon?: boolean;
}

export default observer(
  ({
    children,
    onDismiss,
    containerStyle,
    contentStyle,
    buttonsDirection,
    buttons,
    extraComponent,
    scrollEnabled = true,
    hideCloseIcon,
  }: PropsWithChildren<ModalProps>) => {
    const styles = useStyles();
    const theme = useTheme();
    return (
      <Portal>
        <KeyboardAvoidingView style={styles.keyboard} behavior="padding">
          <ScrollView
            keyboardShouldPersistTaps="handled"
            scrollEnabled={scrollEnabled}
            bounces={false}
            style={[styles.scroll]}
            contentContainerStyle={[styles.container, containerStyle]}>
            <ModalBackdrop onPress={onDismiss} />
            <Animatable.View
              animation="fadeIn"
              duration={300}
              style={[styles.main, contentStyle]}>
              {children}
              {buttons && buttons?.length > 0 ? (
                <ModalButtonList
                  buttons={buttons}
                  buttonsDirection={buttonsDirection}
                />
              ) : null}
              {onDismiss && !hideCloseIcon && (
                <Hoverable>
                  {(isHovered) => (
                    <Pressable
                      onPress={onDismiss}
                      style={styles.closeTouchable}
                      hitSlop={5}>
                      <CloseIcon
                        color={
                          isHovered ? theme.palette.primary : theme.palette.icon
                        }
                      />
                    </Pressable>
                  )}
                </Hoverable>
              )}
            </Animatable.View>
          </ScrollView>
        </KeyboardAvoidingView>
        {extraComponent}
      </Portal>
    );
  },
);

const CloseIcon = sized(CloseSvg, 14);

const useStyles = createStylesHook((theme) => ({
  scroll: {
    flex: 1,
    width: theme.window.width,
  },
  closeTouchable: {
    position: 'absolute',
    zIndex: 2,
    top: 10,
    right: 10,
  },
  keyboard: {
    flex: 1,
    width: theme.window.width,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
  },
  main: {
    width: '100%',
    maxWidth: 600,
    backgroundColor: theme.palette.background,
    borderWidth: theme.select(0, 1),
    borderColor: theme.palette.border,
    borderRadius: 15,
    overflow: 'hidden',
    position: 'relative',
    padding: 15,
  },
}));
