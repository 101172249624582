import {colors} from './constant';

export default (speed: number): string => {
  const color = colors.get(speed);
  if (color) {
    return color;
  }

  const closestColorIndex = speed - (speed % 5);
  const closestColor = colors.get(closestColorIndex);
  if (closestColor) {
    return closestColor;
  }
  return colors.get(0)!;
};
