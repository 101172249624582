import {Auth} from '../Auth';
import {observable, when, makeObservable} from 'mobx';
import {FULLTUT_TAG, INTERTUT_TAG} from '../ApiStore';
import {TutorialHelper, TutorialType} from './TutorialHelper';
import {TutorialStorage} from './TutorialStorage';
import {SubscriptionMap} from '../farmApi';

export default class TutorialHelperImpl implements TutorialHelper {
  @observable private _tutorialToShow?: TutorialType;
  @observable private _isReady = false;

  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly tutorialManagerStorage: TutorialStorage;
    },
  ) {
    makeObservable(this);
  }

  private static _checkForNewbie(subscription: SubscriptionMap) {
    const entries = [...subscription.entries()];
    if (entries.length === 1) {
      const record = entries[0][1];
      if (record.slots === 0 && record.poolMiners === 0) {
        return true;
      }
    }
    return false;
  }

  async getTutorialType() {
    const authState = this._root.auth.state;
    if (authState?.kind !== 'Connected') {
      return undefined;
    }
    await when(() => this._root.tutorialManagerStorage.isReady);
    if (!TutorialHelperImpl._checkForNewbie(authState.subscriptionMap)) {
      return undefined;
    }
    if (this._root.tutorialManagerStorage.record?.done) {
      return undefined;
    }
    const tags = authState.tags;
    if (tags.has(FULLTUT_TAG)) {
      return TutorialType.Video;
    } else if (tags.has(INTERTUT_TAG)) {
      return TutorialType.Interactive;
    }
  }
}
