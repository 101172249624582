import React from 'react';
import {useTheme, variance} from '../../styling';
import {View, StyleSheet, Text, Image} from 'react-native';
import {LG_BREAKPOINT} from '../../units/constants';

export type BadgeThemes = 'light-blue' | 'orange' | 'violet' | 'green';
export type BadgeProps = {
  text: string;
  icon: string;
  type: BadgeThemes;
};

export default function Badge({text, type, icon}: BadgeProps) {
  const theme = useTheme();
  const getTypeStyle = (type: string) => {
    const resultStyles = {
      icon: {backgroundColor: theme.palette.staticLightBlue},
      content: {
        backgroundColor: 'transparent',
        borderColor: theme.palette.staticLightBlue,
        borderWidth: 1,
      },
    };
    switch (type) {
      case 'orange':
        resultStyles.icon = {backgroundColor: theme.palette.primary};
        resultStyles.content = {
          backgroundColor: theme.palette.additionalPrimary,
          borderWidth: 1,
          borderColor: theme.palette.primary,
        };
        break;
      case 'violet':
        resultStyles.icon = {backgroundColor: theme.palette.purple};
        resultStyles.content = {
          backgroundColor: theme.palette.additionalPurple,
          borderWidth: 1,
          borderColor: theme.palette.purple,
        };
        break;
      case 'green':
        resultStyles.icon = {backgroundColor: theme.palette.success};
        resultStyles.content = {
          backgroundColor: theme.palette.additionalSuccess,
          borderWidth: 1,
          borderColor: theme.palette.success,
        };
        break;
      default:
        break;
    }
    return resultStyles;
  };

  const styles = StyleSheet.create({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 12,
      paddingRight: 12,
      overflow: 'hidden',
      ...getTypeStyle(type).content,
    },

    iconWrapper: {
      marginRight: 15,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 12,
      width: 44,
      ...getTypeStyle(type).icon,
    },
  });

  return (
    <View style={[styles.root]}>
      <View style={[styles.iconWrapper]}>
        <Image source={{uri: icon}} style={{width: 20, height: 20}} />
      </View>
      <Paragraph>{text}</Paragraph>
    </View>
  );
}
const Paragraph = variance(Text)((theme) => ({
  root: {
    color: theme.palette.textPrimary,
    textAlign: 'center',
    fontSize: 14,
    flexShrink: 1,
    flexWrap: 'wrap',
    ...theme.fontByWeight('600'),
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        fontSize: 16,
        textAlign: 'left',
      },
    }),
  },
}));
