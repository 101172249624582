import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useRoot} from '../../Root/hooks';
import {CommonActions} from '@react-navigation/native';
import {DASHBOARD} from './constants';
import {AccountType} from '../../Auth';
import {Platform} from 'react-native';
import {useNavigationGetIsFocused} from '../hooks';

export type CheckAuthBindingProps = RootStackBindingProps<'CheckAuth'>;

export default observer((props: CheckAuthBindingProps) => {
  const {navigation} = props;
  const {auth, navigationContainer} = useRoot();
  const getIsFocused = useNavigationGetIsFocused();
  useEffect(() => {
    if (getIsFocused() && navigationContainer.isReady) {
      if (Platform.OS === 'web') {
        return navigation.dispatch(CommonActions.reset(DASHBOARD));
      }
      const isPermanent =
        auth.state?.kind === 'Connected' &&
        auth.state.accountType === AccountType.Permanent;
      if (isPermanent) {
        navigation.dispatch(CommonActions.reset(DASHBOARD));
      } else {
        navigation.replace('Auth');
      }
    }
  }, [
    auth,
    getIsFocused,
    navigation,
    navigationContainer.isReady,
    navigationContainer.isConfigured,
  ]);
  return null;
});
