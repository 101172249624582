import {ReadonlyDeep} from 'type-fest';
import {WorkerGroupEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';
import clamp from '../../utils/clamp';
import getSlotsShown from '../getters/getSlotsShown';
import getWorkerOrder from '../getters/getWorkerOrder';
import {SectionListData} from 'react-native';
import {WorkerEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {WorkerStateRegistry} from '../../WorkerStateRegistry';
import {byAverageHashrate, byMinedTotal, byName, byStatus} from './sort';
import {WorkerOrder} from '../types';
import {DashboardParams} from '../../Navigation';

export interface WorkerItem {
  worker: ReadonlyDeep<WorkerEntity>;
  key: string;
}

export type GroupSection = GroupSectionFilled | GroupSectionEmpty;

export interface GroupSectionFilled {
  isEmpty: false;
  group: ReadonlyDeep<WorkerGroupEntity>;
  isDefaultGroup: boolean;
  slotsLeftToShow: number;
  dataToken: string;
}

export interface GroupSectionEmpty {
  isEmpty: true;
  slotsLeftToShow: number;
}

export default (
  groups: readonly ReadonlyDeep<WorkerGroupEntity>[] | undefined,
  params: DashboardParams,
  balance: (workerId: string) => number,
  emptySlotCount: number,
  registry: WorkerStateRegistry,
): readonly SectionListData<WorkerItem, GroupSection>[] => {
  if (!groups) {
    return [];
  }
  if (groups.length === 0) {
    return [{isEmpty: true, slotsLeftToShow: 0, data: []}];
  }
  const sortedGroups = groups.slice();
  sortedGroups.sort((a, b) => a.id - b.id);
  return sortedGroups.map((group) => {
    const isDefaultGroup = group.id === 0;
    const workerOrder = getWorkerOrder(params, group.id);
    const workerItems = group.workers.map(
      (worker): WorkerItem => ({worker, key: worker.id}),
    );
    switch (workerOrder) {
      case WorkerOrder.AverageHashrate:
        workerItems.sort((a, b) =>
          byAverageHashrate(a.worker, b.worker, registry),
        );
        break;
      case WorkerOrder.MinedTotal:
        workerItems.sort((a, b) => byMinedTotal(a.worker, b.worker, balance));
        break;
      case WorkerOrder.Name:
        workerItems.sort((a, b) => byName(a.worker, b.worker));
        break;
      case WorkerOrder.Status:
        workerItems.sort((a, b) => byStatus(a.worker, b.worker, registry));
        break;
    }
    const slotsShown = clamp(
      getSlotsShown(params, group.id),
      0,
      workerItems.length,
    );
    const slotsLeftToShow = workerItems.length - slotsShown;
    const data = workerItems.slice(0, slotsShown);
    return {
      isEmpty: false,
      data,
      key: String(group.id),
      group,
      isDefaultGroup,
      slotsLeftToShow,
      dataToken: data.flatMap((_) => [_.key]).join(' '),
    };
  });
};
