import React from 'react';
import {Platform, Text, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStyles, variance} from '../../styling';
import {useStrings} from '../../Root/hooks';
import WarningSvg from '../../assets/svg/circled/warning.svg';
import SignInSvg from '../../assets/svg/colorless/signIn.svg';
import {sized} from '../../Svg';
import useOpenStoreAuth from '../hooks/useOpenStoreAuth';
import {Button, ButtonVariant, PressableOpacity} from '../../components';

export default observer(() => {
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    text: {
      ...theme.fontByWeight('400'),
      color: theme.palette.textSecondary,
      fontSize: 14,
      lineHeight: 22,
      textAlign: 'center',
    },
    body: {
      alignItems: 'center',
      paddingVertical: 30,
      paddingHorizontal: 15,
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.border,
    },
    footer: {
      padding: 10,
    },
    bodyNoBorder: {
      borderBottomWidth: 0,
    },
    icon: {
      marginBottom: 20,
    },
  }));
  const description =
    Platform.OS === 'ios'
      ? strings['subscription.purchaseIsNotAvailable.description.ios']
      : strings['subscription.purchaseIsNotAvailable.description.android'];
  const openStore = useOpenStoreAuth();
  return (
    <ContainerView>
      <RootView>
        <View style={[styles.body, !openStore && styles.bodyNoBorder]}>
          <WarningIcon style={styles.icon} />
          <Text style={styles.text}>{description}</Text>
        </View>
        <View style={styles.footer}>
          {openStore && (
            <PressableOpacity onPress={openStore}>
              <Button Icon={SignInIcon} variant={ButtonVariant.Highlighted}>
                {strings['subscription.purchaseIsNotAvailable.button']}
              </Button>
            </PressableOpacity>
          )}
        </View>
      </RootView>
    </ContainerView>
  );
});

const WarningIcon = sized(WarningSvg, 60);
const SignInIcon = sized(SignInSvg, 18);

const ContainerView = variance(View)(() => ({
  root: {
    paddingHorizontal: 20,
  },
}));

const RootView = variance(View)((theme) => ({
  root: {
    borderWidth: 1,
    borderColor: theme.palette.border,
    backgroundColor: theme.select(
      theme.palette.background,
      theme.palette.backdrop,
    ),
    borderRadius: 6,
  },
}));
