import {AdvertisingId, AdvertisingIdentifier} from './AdvertisingIdentifier';
import {GlobalError, UNKNOWN_ERROR} from '../Error';
import {Either, error, success} from '../fp';

import ReactNativeIdfaAaid from '@sparkfabrik/react-native-idfa-aaid';
import {ErrorRepository} from '../ErrorRepository';

export default class AdvertisingIdentifierImpl
  implements AdvertisingIdentifier
{
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
    },
  ) {}
  async getInfo(): Promise<Either<AdvertisingId | null, GlobalError>> {
    try {
      const info = await ReactNativeIdfaAaid.getAdvertisingInfo();
      if (info.isAdTrackingLimited || !info.id) {
        return success(null);
      }
      return success(info.id as AdvertisingId);
    } catch (raw) {
      return error(
        this._root.errorRepository.create({kind: UNKNOWN_ERROR, raw}),
      );
    }
  }
}
