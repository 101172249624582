import {
  action,
  autorun,
  computed,
  observable,
  reaction,
  makeObservable,
} from 'mobx';
import {
  ActivatorStatus,
  PoolMinerActivator,
  SliderStatus,
} from './PoolMinerActivator';
import {bind, Either, success} from '../fp';
import {batchDisposers, Service} from '../structure';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import PuzzleStateImpl from './PuzzleStateImpl';
import {WorkerStateRegistryService} from '../WorkerStateRegistry';
import {Auth} from '../Auth';
import {BannerType, DashboardBannersState} from '../DashboardBannersState';
import {Appearance} from '../Appearance';
import {CurrentSubscription} from '../CurrentSubscription';
import {ConnectedClient} from '../ContextClient';
import {DefaultError} from '../JsonRpc';
import {CommonError} from '../ApiStore';
import {PuzzleState} from './PuzzleState';
import isNeedToReactivate from './isNeedToReactivate';

export default class PoolMinerActivatorService
  implements Service, PoolMinerActivator
{
  @observable private _activatorStatus = ActivatorStatus.Idle;

  private _puzzle: PuzzleState;

  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly dashboardStore: DashboardStore;
      readonly connectedClient: ConnectedClient;
      readonly workerStateRegistry: WorkerStateRegistryService;
      readonly dashboardBannersState: DashboardBannersState;
      readonly currentSubscriptionState: CurrentSubscription;
      readonly appearance: Appearance;
    },
  ) {
    makeObservable(this);
    this._puzzle = new PuzzleStateImpl({
      onConfirm: this.confirm,
      appearance: this._root.appearance,
    });
  }

  @action
  confirm = bind(
    async (): Promise<Either<void, CommonError | DefaultError>> => {
      this.puzzle.changeStateStatus(SliderStatus.Confirming);
      const activate_ = await this._root.connectedClient.call(
        'activate_pool_miners',
      );
      if (!activate_.success) {
        this.reset();
        return activate_;
      }
      await this._root.dashboardStore.fetch();
      this.puzzle.changeStateStatus(SliderStatus.Idle);
      this._activatorStatus = ActivatorStatus.Idle;
      return success(undefined);
    },
    this,
  );

  get puzzle(): PuzzleState {
    return this._puzzle;
  }

  get activatorStatus() {
    return this._activatorStatus;
  }

  @action.bound
  setStatus(status: ActivatorStatus) {
    this._activatorStatus = status;
  }

  @computed
  get isVisible() {
    return this.activatorStatus === ActivatorStatus.NeedActivate;
  }

  @action
  dismiss = bind(() => {
    this.puzzle.changeStateStatus(SliderStatus.Idle);
    this._activatorStatus = ActivatorStatus.Dismissed;
  }, this);

  private _showActivePoolMinerOnWorkerUpdate = () =>
    autorun(() => {
      if (!this._root.auth.isConnected) {
        return;
      }
      const workers = [...this._root.dashboardStore.workers.values()];
      const needActive = workers.some((_) => isNeedToReactivate(_));
      if (needActive) {
        this._activatorStatus = ActivatorStatus.NeedActivate;
      } else {
        this._activatorStatus = ActivatorStatus.Idle;
      }
    });

  private _showBannerOnChangeActivatorStatus = () =>
    autorun(() => {
      const status = this.activatorStatus;
      switch (status) {
        case ActivatorStatus.Dismissed:
        case ActivatorStatus.NeedActivate:
          return this._root.dashboardBannersState.add(BannerType.PoolMiner);
        case ActivatorStatus.Idle:
        default:
          return this._root.dashboardBannersState.delete(BannerType.PoolMiner);
      }
    });

  private _resetStatusOnReconnect = () =>
    reaction(
      () => this._root.auth.isConnected,
      (shouldReset) => {
        if (shouldReset) {
          this.reset();
        }
      },
    );

  subscribe() {
    return batchDisposers(
      this.puzzle.refresh(),
      this._showActivePoolMinerOnWorkerUpdate(),
      this._showBannerOnChangeActivatorStatus(),
      this._resetStatusOnReconnect(),
    );
  }

  @action
  reset = bind(() => {
    this._activatorStatus = ActivatorStatus.Idle;
    this.puzzle.changeStateStatus(SliderStatus.Idle);
  }, this);
}
