import React, {useCallback, useLayoutEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useRoot} from '../../Root/hooks';
import {
  RestoredSubscription,
  RestoredSubscriptionMap,
  translateRestoredAccounts,
} from '../../farmApi';
import {AccessRecoveryScreen} from '../../AccessRecoveryScreen';
import {CommonActions} from '@react-navigation/native';
import {observable} from 'mobx';
import {DASHBOARD} from './constants';
import {useHandleErrorAndNavigate} from '../../ExceptionHandler';

export type AccessRecoveryBindingProps =
  RootStackBindingProps<'AccessRecovery'>;

export default observer((props: AccessRecoveryBindingProps) => {
  const {navigation} = props;
  const {auth, inAppPurchaseManager} = useRoot();
  const onRestore = useCallback(
    async (subscription: RestoredSubscription) => {
      if (auth.subscriptionMap?.has(subscription.accountId)) {
        await auth.selectAccount(subscription.accountId);
        navigation.dispatch(CommonActions.reset(DASHBOARD));
      } else if (subscription.type === 'Temporary') {
        await auth.restoreFarm(subscription.token, subscription.accountId);
        navigation.dispatch(CommonActions.reset(DASHBOARD));
      } else {
        navigation.navigate('Auth', {farmId: subscription.accountId});
      }
    },
    [auth, navigation],
  );
  const [subscriptionMapBox] = useState(() =>
    observable.box<RestoredSubscriptionMap>(),
  );
  const [isRefreshingBox] = useState(() => observable.box(false));
  const handleErrorAndNavigate = useHandleErrorAndNavigate();
  const refresh = useCallback(async () => {
    isRefreshingBox.set(true);
    const restore = await inAppPurchaseManager.authByPurchaseHistory();
    if (restore.success) {
      const map = translateRestoredAccounts(restore.right);
      subscriptionMapBox.set(map);
    } else {
      handleErrorAndNavigate(restore.left);
      subscriptionMapBox.set(new Map());
    }
    isRefreshingBox.set(false);
  }, [
    handleErrorAndNavigate,
    inAppPurchaseManager,
    isRefreshingBox,
    subscriptionMapBox,
  ]);
  useLayoutEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    refresh();
  }, [refresh]);
  const getSubscriptions = useCallback(
    () =>
      subscriptionMapBox.get() && [
        ...(subscriptionMapBox.get() ?? []).values(),
      ],
    [subscriptionMapBox],
  );
  const getIsRefreshing = useCallback(
    () => isRefreshingBox.get(),
    [isRefreshingBox],
  );
  return (
    <AccessRecoveryScreen
      getSubscriptions={getSubscriptions}
      getIsRefreshing={getIsRefreshing}
      onRefresh={refresh}
      onRestore={onRestore}
    />
  );
});
