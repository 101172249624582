import {DemonstrationDatabase} from './DemonstrationDatabase';
import {omit} from 'lodash';
import selectWorkersById from './selectWorkersById';
import {
  CreateGroupError,
  CreateGroupErrorReason,
  GroupController,
} from './GroupController';
import {Either, error, success} from '../fp';
import {
  CryptoFarmClientCalls,
  CryptoFarmClientNotifications,
  WorkerId,
} from '../ApiStore';
import {batchDisposers} from '../structure';
import {Client, Server} from '../JsonRpc';

export default class GroupService implements GroupController {
  constructor(
    private readonly _root: {readonly db: DemonstrationDatabase},
    private readonly _reverseClient: Client<
      CryptoFarmClientCalls,
      CryptoFarmClientNotifications
    >,
    private readonly _realServer: Server<
      CryptoFarmClientCalls,
      CryptoFarmClientNotifications
    >,
  ) {}

  create(
    name: string,
    workerIds: WorkerId[],
  ): Promise<Either<void, CreateGroupError>> {
    return new Promise((resolve) =>
      this._root.db
        .update(async (db) => {
          if (db.groups.find((_) => _.name === name) !== undefined) {
            resolve(error({reason: CreateGroupErrorReason.NameConflict}));
            return db;
          }
          const {nextGroupId} = db;
          const workersById = selectWorkersById(db.groups, workerIds);
          return {
            ...db,
            groups: [
              ...db.groups.map((group) => ({
                ...group,
                workersById: omit(group.workersById, workerIds),
              })),
              {id: nextGroupId, name, workersById},
            ],
            nextGroupId: nextGroupId + 1,
          };
        })
        .then(() => resolve(success(undefined))),
    );
  }

  async rename(id: number, name: string) {
    await this._root.db.update(async (db) => ({
      ...db,
      groups: db.groups.map((group) =>
        group.id === id ? {...group, name} : group,
      ),
    }));
  }

  async delete(id: number) {
    const isDefaultGroup = id === 0;
    if (!isDefaultGroup) {
      await this._root.db.update(async (db) => ({
        ...db,
        groups: db.groups.filter((group) => group.id !== id),
      }));
    }
  }

  async setWorkers(id: number, workerIds: WorkerId[]) {
    await this._root.db.update(async (db) => {
      const workersById = selectWorkersById(db.groups, workerIds);
      return {
        ...db,
        groups: db.groups.map((group) =>
          group.id === id
            ? {
                ...group,
                workersById: {...group.workersById, ...workersById},
              }
            : {
                ...group,
                workersById: omit(group.workersById, workerIds),
              },
        ),
      };
    });
  }

  private _routeThrough() {
    return this._realServer.call('groups_info', async (params, response) => {
      const outcome_ = await this._reverseClient.call('groups_info', params);
      if (!outcome_.success && outcome_.left.code === 32099) {
        return;
      }
      return response.respond(outcome_ as any);
    });
  }

  private _notifyOnUpdate() {
    return this._root.db.afterUpdate(async (previous, current) => {
      if (
        previous.groups.length === 0 &&
        current.groups.length === 1 &&
        current.groups[0].id === 0
      ) {
        return;
      }
      if (
        previous.groups.length !== current.groups.length ||
        current.groups.some(
          (_, i) =>
            _.id !== previous.groups[i].id ||
            _.name !== previous.groups[i].name,
        )
      ) {
        // noinspection ES6MissingAwait
        this._reverseClient.call(
          'groups_info',
          current.groups.map((_) => ({id: _.id, name: _.name})),
        );
      }
    });
  }

  subscribe() {
    return batchDisposers(this._routeThrough(), this._notifyOnUpdate());
  }
}
