import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import GroupHeaderView from './GroupHeaderView';
import {DEFAULT_GROUP_NAME} from '../../../../LocalService/constants';
import {useRoot} from '../../../../Root/hooks';
import {PressableOpacity} from '../../../../components';
import {useStyles} from './GroupHeader';
import ReloadSvg from '../../../../assets/svg/colorless/reload.svg';
import GroupStats from './GroupStats';
import {sized} from '../../../../Svg';

import {useTheme} from '../../../../styling';

export default observer(() => {
  const {dashboardStore, currentSubscriptionState} = useRoot();
  const slots = currentSubscriptionState.subscription?.slots ?? 0;
  const maxSlots = currentSubscriptionState.subscription?.maxSlots;
  const styles = useStyles();
  const theme = useTheme();
  const buttons = useMemo(
    () => (
      <>
        <PressableOpacity
          style={styles.reloadButton}
          disabled={dashboardStore.isLoading}
          onPress={dashboardStore.fetch}>
          <ReloadIcon color={theme.palette.secondary} />
        </PressableOpacity>
      </>
    ),
    [
      dashboardStore.fetch,
      dashboardStore.isLoading,
      styles.reloadButton,
      theme.palette.secondary,
    ],
  );
  const footer = useMemo(
    () => <GroupStats hashrate={0} mined={0} isEmpty={true} />,
    [],
  );
  return (
    <GroupHeaderView
      name={DEFAULT_GROUP_NAME}
      slots={slots}
      maxSlots={maxSlots}
      buttons={buttons}
      footer={footer}
      style={styles.rootEmpty}
    />
  );
});

const ReloadIcon = sized(ReloadSvg, 20);
