import {LogContent} from './LogContent';
import {LocalNotificationContext, EventType} from '../LocalNotifications';
import {compact} from 'lodash';

export default class NotificationEventLogContent implements LogContent {
  constructor(public readonly context: LocalNotificationContext) {}

  get body() {
    const type = EventType[this.context.type];
    return compact([this.context.id, type, this.context.actionId]).join(' ');
  }
}
