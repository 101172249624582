import React from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../../Root/hooks';
import PurchaseTableSectionView from './PurchaseTableSectionView';
import {StyleSheet, View} from 'react-native';
import {expr} from 'mobx-utils';
import {OfferPurchaseType} from '../../InAppOffersService';
import {CryptoLoader} from '../../components/CryptoLoader';
import {useForcedContext} from '../../context';
import {PurchaseScreenContext} from '../PurchaseScreenContext';

export default observer(function SlotTableSectionBinding() {
  const {
    inAppOffers: {purchasedIds, isLoading},
  } = useRoot();
  const {state, onSelectButtonPress} = useForcedContext(PurchaseScreenContext);
  const {selectedSlotProposal, selectedSlotInterval, selectedPaymentMethod} =
    state;
  const disabledButton = !selectedSlotProposal?.available;

  const offers = expr(() =>
    (state.slotProposals || []).flatMap((_) =>
      _.available &&
      _.offer.purchaseType === OfferPurchaseType.SlotSubscription &&
      _.offer.interval === selectedSlotInterval
        ? [_.offer]
        : [],
    ),
  );
  if (!purchasedIds) {
    return null;
  }
  const content = isLoading ? (
    <View style={styles.loader}>
      <CryptoLoader />
    </View>
  ) : (
    <PurchaseTableSectionView
      offerList={offers}
      selectedPayment={selectedPaymentMethod}
      purchasedProductIds={purchasedIds}
      selectedOffer={state.selectedSlotProposal?.offer}
      onSelectPurchase={state.selectSlotPurchaseId}
      disabledButton={disabledButton}
      onSelectButtonPress={onSelectButtonPress}
      visibleFreeMinerSection={state.visibleFreeMinerSection}
    />
  );
  return <View>{content}</View>;
});

const styles = StyleSheet.create({
  loader: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
  },
});
