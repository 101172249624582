import React from 'react';
import {StyleProp, Text, TextStyle, View} from 'react-native';
import {observer} from 'mobx-react-lite';

import SpeedSvg from '../../assets/svg/colored/speed.svg';
import {useStyles} from '../../styling';
import {sized} from '../../Svg';

export interface HashrateProps {
  value: number | string | (() => React.ReactNode);
  style?: StyleProp<TextStyle>;
  unitStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
}

export default observer(
  ({value = 0, style, disabled, unitStyle}: HashrateProps) => {
    const styles = useStyles((theme) => ({
      root: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
      },
      value: {
        ...theme.fontByWeight('700'),
        fontSize: 15,
        lineHeight: 17,
        letterSpacing: 0.042,
        color: theme.palette.textPrimary,
      },
      disabled: {
        color: theme.palette.disabled,
      },
      radix: {
        ...theme.fontByWeight('700'),
        fontSize: 15,
        lineHeight: 17,
        letterSpacing: 0.04,
        color: theme.palette.textPrimary,
      },
    }));
    return (
      <View style={styles.root}>
        <SpeedIcon />
        <Text style={[styles.value, disabled ? styles.disabled : {}, style]}>
          {typeof value === 'function'
            ? value()
            : typeof value === 'number'
            ? value.toFixed(0)
            : value}
        </Text>
        <Text
          style={[styles.radix, disabled ? styles.disabled : {}, unitStyle]}>
          H/s
        </Text>
      </View>
    );
  },
);

const SpeedIcon = sized(SpeedSvg, 20);
