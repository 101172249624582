import React from 'react';
import {View, Text} from 'react-native';
import {observer} from 'mobx-react-lite';
import DisconnectSvg from '../assets/svg/circled/disconnect.svg';
import {createStylesHook} from '../styling';
import {sized} from '../Svg';

export interface ErrorPanelProps {
  title?: string;
  code?: number;
  description?: string;
  footer?: React.ReactNode;
}

export default observer(
  ({title, code, description, footer}: ErrorPanelProps) => {
    const styles = useStyles();
    return (
      <View style={styles.root}>
        <View style={styles.body}>
          <DisconnectIcon style={styles.icon} />
          {title !== undefined && <Text style={styles.title}>{title}</Text>}
          {(code !== undefined || description !== undefined) && (
            <Text style={styles.bodyText}>
              {code !== undefined && (
                <Text style={styles.bodyErrorText}>{code}</Text>
              )}
              {code !== undefined && description !== undefined && ' '}
              {description}
            </Text>
          )}
        </View>
        <View style={styles.footer}>{footer}</View>
      </View>
    );
  },
);

const DisconnectIcon = sized(DisconnectSvg, 80);

const useStyles = createStylesHook((theme) => ({
  root: {
    borderRadius: 15,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: theme.palette.border,
    backgroundColor: theme.palette.background,
    ...theme.mediaQuery({
      1281: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
  },
  body: {
    backgroundColor: theme.palette.background,
    alignItems: 'center',
    paddingVertical: 40,
    paddingHorizontal: 30,
  },
  icon: {
    marginBottom: 20,
  },
  title: {
    ...theme.fontByWeight('700'),
    textAlign: 'center',
    fontSize: 22,
    lineHeight: 32,
    marginBottom: 20,
    color: theme.palette.textPrimary,
    ...theme.mediaQuery({
      1281: {
        fontSize: 32,
        lineHeight: 40,
      },
    }),
  },
  bodyText: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    lineHeight: 22,
    letterSpacing: 0.04,
    color: theme.palette.textSecondary,
    textAlign: 'center',
    ...theme.mediaQuery({
      1281: {
        fontSize: 16,
        lineHeight: 26,
      },
    }),
  },
  bodyErrorText: {
    ...theme.fontByWeight('700'),
    color: theme.palette.error,
  },
  footer: {
    backgroundColor: theme.palette.backdrop,
    padding: 15,
    borderTopWidth: 1,
    borderTopColor: theme.palette.border,
  },
}));
