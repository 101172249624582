import React, {useCallback} from 'react';
import {Platform, StyleSheet, ViewStyle} from 'react-native';
import {observer} from 'mobx-react-lite';

import {useTheme} from '../../../styling';
import {Slider} from '@miblanchard/react-native-slider';
import {first} from 'lodash';

export type WorkerSliderProps = {
  threads?: number;
  locked: boolean;
  readonly?: boolean;
  onChange?: (value: number) => void;
  sliderValue: number;
  onSlidingStart?: () => void;
};

export default observer(
  ({
    threads = 100,
    locked,
    readonly,
    onChange,
    onSlidingStart,
    sliderValue,
  }: WorkerSliderProps) => {
    const theme = useTheme();
    const disabledColor = theme
      .mix(theme.palette.background, theme.palette.disabled)
      .hex();
    const thumbTintColor = locked ? disabledColor : theme.palette.secondary;
    const minimumTrackTintColor = locked
      ? disabledColor
      : theme.palette.secondary;
    const maximumTrackTintColor = locked
      ? disabledColor
      : theme.select(
          theme.chroma(theme.palette.foreground).brighten(5).hex(),
          theme.chroma(theme.palette.background).brighten(1.5).hex(),
        );
    const disabled = locked || readonly;
    const onSlidingComplete = useCallback(
      (value: number | Array<number>) => {
        const normalValue = Array.isArray(value) ? first(value) ?? 0 : value;
        onChange?.(normalValue);
      },
      [onChange],
    );
    return (
      <Slider
        onSlidingStart={onSlidingStart}
        thumbTintColor={thumbTintColor}
        minimumTrackTintColor={minimumTrackTintColor}
        value={sliderValue}
        containerStyle={styles.container}
        disabled={disabled}
        trackStyle={styles.root}
        maximumTrackTintColor={maximumTrackTintColor}
        onSlidingComplete={onSlidingComplete}
        minimumValue={0}
        maximumValue={100}
        step={100 / threads}
      />
    );
  },
);

const styles = StyleSheet.create({
  root: {
    height: 6,
    borderRadius: 4,
  },
  container: {
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
      default: {},
    }),
  } as ViewStyle,
});
