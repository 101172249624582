import React from 'react';
import {observer} from 'mobx-react-lite';
import {createStackNavigator} from '@react-navigation/stack';
import {AuthParamList} from './AuthParamList';
import {useRoot} from '../../Root/hooks';
import {AuthenticationErrorReason, AuthorizationErrorReason} from '../../Auth';
import ForcedAuthBinding from './ForcedAuthBinding';
import OAuthSplashBinding from './OAuthSplashBinding';
import ForcedAccessRecoveryBinding from './ForcedAccessRecoveryBinding';
import {NoConnectionBinding} from '../../NoConnectionContainer';
import UpdateRequiredBinding from './UpdateRequiredBinding';
import ForcedAccountSwitchBinding from './ForcedAccountSwitchBinding';
import {useHeaderHeight} from '../hooks';
import screenOptions from '../RootStack/screenOptions';
import {useTheme} from '../../styling';

const {Navigator, Screen} = createStackNavigator<AuthParamList>();

export default observer(() => {
  const {auth} = useRoot();
  let screens: React.ReactNode = null;
  if (
    auth.state?.kind === 'AuthenticationFailed' &&
    auth.state?.reason === AuthenticationErrorReason.OAuthNeeded
  ) {
    screens = (
      <>
        <Screen name="Auth" component={ForcedAuthBinding} />
        <Screen
          name="OAuthSplash"
          options={{headerShown: false}}
          component={OAuthSplashBinding}
        />
      </>
    );
  } else if (
    auth.state?.kind === 'AuthenticationFailed' &&
    auth.state?.reason === AuthenticationErrorReason.AccountSwitchRequired
  ) {
    screens = (
      <>
        <Screen name="AccessRecovery" component={ForcedAccessRecoveryBinding} />
        <Screen name="Auth" component={ForcedAuthBinding} />
        <Screen
          name="OAuthSplash"
          options={{headerShown: false}}
          component={OAuthSplashBinding}
        />
      </>
    );
  } else if (
    (auth.state?.kind === 'AuthenticationFailed' &&
      auth.state?.reason === AuthenticationErrorReason.UpdateRequired) ||
    (auth.state?.kind === 'AuthorizationFailed' &&
      auth.state?.reason === AuthorizationErrorReason.UpdateRequired)
  ) {
    screens = (
      <Screen
        name="Error"
        component={UpdateRequiredBinding}
        options={hiddenHeaderOptions}
      />
    );
  } else if (
    (auth.state?.kind === 'Authorized' ||
      auth.state?.kind === 'ConnectionFailed') &&
    auth.state?.accountIds.size > 1
  ) {
    screens = (
      <Screen name="AccountSwitch" component={ForcedAccountSwitchBinding} />
    );
  } else if (
    auth.state?.kind === 'AuthenticationFailed' ||
    auth.state?.kind === 'AuthorizationFailed' ||
    auth.state?.kind === 'ConnectionFailed'
  ) {
    screens = (
      <Screen
        name="Error"
        component={NoConnectionBinding}
        options={hiddenHeaderOptions}
      />
    );
  }
  const theme = useTheme();
  const headerHeight = useHeaderHeight();
  return (
    screens && (
      <Navigator
        screenOptions={(props) => screenOptions(props, theme, headerHeight)}>
        {screens}
      </Navigator>
    )
  );
});

const hiddenHeaderOptions = {headerShown: false} as const;
