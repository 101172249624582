import React, {useCallback, useMemo, useState} from 'react';
import {Platform, ScrollView, StyleSheet, View} from 'react-native';
import {Language} from './Language';
import {Divider} from '../components/Divider';
import {BtcSection} from './BtcSection';
import MenuList, {MenuListPropsBase} from './MenuList';
import {SubMenuList} from './SubMenuList';
import {SecondaryMenuItem} from './SecondaryMenuItem';
import {GetItOnGooglePlay} from './GetItOnGooglePlay';
import {useRoot, useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import ActionModal from '../modals/ActionModal';
import WarningSvg from '../assets/svg/circled/warning.svg';
import {defaultContainerStyle} from '../components/Grid';
import {AccountType} from '../Auth';
import {ButtonColor, ButtonVariant} from '../components';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';
import DarkThemeToggle from './DarkThemeToggle';
import RefreshControl from '../components/RefreshControl';

export interface MenuScreenProps extends MenuListPropsBase {
  goToAuth: () => void;
  goToDashboard: () => void;
}

export default observer((props: MenuScreenProps) => {
  const {
    goToDashboard,
    goToPlan,
    goToWithdraw,
    goToFarmSwitch,
    getAffiliateIsPending,
    safelyGoToAffiliate,
    goToWithdrawalHistory,
    goToTutorial,
    goToDescription,
    goToAuth,
    goToQRCodeScanner,
  } = props;
  const {auth, dashboardStore} = useRoot();
  const [modalShown, setModalShown] = useState(false);
  const show = useCallback(() => setModalShown(true), []);
  const hide = useCallback(() => setModalShown(false), []);
  const signOut = useCallback(async () => {
    await auth.registerNewAccount();
    setModalShown(false);
    goToDashboard();
  }, [auth, goToDashboard]);
  const strings = useStrings();
  const refreshControl = useMemo(
    () => (
      <RefreshControl
        refreshing={dashboardStore.isLoading}
        onRefresh={dashboardStore.fetch}
      />
    ),
    [dashboardStore.fetch, dashboardStore.isLoading],
  );
  return (
    <>
      <DesktopWebAppHeader />
      <ScrollView
        contentContainerStyle={[
          defaultContainerStyle,
          insets.contentScrollView,
        ]}
        refreshControl={refreshControl}>
        <BtcSection />
        <View style={insets.menuList}>
          <MenuList
            goToPlan={goToPlan}
            goToWithdraw={goToWithdraw}
            goToFarmSwitch={goToFarmSwitch}
            getAffiliateIsPending={getAffiliateIsPending}
            safelyGoToAffiliate={safelyGoToAffiliate}
            goToWithdrawalHistory={goToWithdrawalHistory}
            goToTutorial={goToTutorial}
            goToDescription={goToDescription}
            goToQRCodeScanner={goToQRCodeScanner}
          />
        </View>
        <DarkThemeToggle />
        {Platform.OS === 'web' && <GetItOnGooglePlay />}

        <View style={{marginBottom: 20}}>
          <Divider />
        </View>
        <View style={{marginBottom: 10}}>
          <SubMenuList />
        </View>
        <Divider />
        <Language />
        <View style={{marginBottom: 5}}>
          <Divider />
        </View>
        <View style={{paddingHorizontal: 20, paddingVertical: 10}}>
          {auth.accountType === AccountType.Temporary ? (
            <SecondaryMenuItem
              text={strings['menu.signUp']}
              onPress={goToAuth}
            />
          ) : (
            <>
              <SecondaryMenuItem
                text={strings['menu.resignUp']}
                onPress={goToAuth}
              />
              <SecondaryMenuItem
                text={strings['menu.signOut']}
                onPress={show}
              />
            </>
          )}
        </View>
      </ScrollView>
      {modalShown && (
        <ActionModal
          buttons={[
            {
              text: strings['action.ok'],
              onPress: signOut,
              color: ButtonColor.Default,
              variant: ButtonVariant.Highlighted,
            },
          ]}
          onDismiss={hide}
          title={strings['menu.signOut']}
          Icon={WarningSvg}>
          {strings['menu.signOutPrompt']}
        </ActionModal>
      )}
    </>
  );
});

const insets = StyleSheet.create({
  contentScrollView: {
    paddingTop: 20,
  },
  menuList: {marginBottom: 20},
  firstDivider: {marginBottom: 20},
  subMenuList: {marginBottom: 10},
  secondDivider: {marginBottom: 5},
});
