import {InfiniteGenerator} from './InfiniteGenerator';

export default class SequenceNumberGeneratorImpl
  implements InfiniteGenerator<number>
{
  private readonly _start: number;
  private _current: number;

  constructor(start = 0) {
    this._start = Math.floor(start);
    this._current = this._start;
  }

  next() {
    if (this._current === Number.MAX_SAFE_INTEGER) {
      this._current = this._start;
      return Number.MAX_SAFE_INTEGER;
    }
    return this._current++;
  }
}
