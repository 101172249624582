import {CustomDisconnect} from './CustomDisconnect';
import {action, autorun, observable, makeObservable} from 'mobx';
import {Service} from '../structure';
import {APP_WINDOW_ACTIVE, AppWindowState} from '../AppWindow';
import {Connection} from '../ConnectionManager';

export default class CustomDisconnectService
  implements CustomDisconnect, Service
{
  @observable private _active = false;

  constructor(
    private readonly _root: {
      readonly appWindowState: AppWindowState;
      readonly connection: Connection;
    },
  ) {
    makeObservable(this);
  }

  get active() {
    return this._active;
  }

  @action activate() {
    this._active = true;
  }

  @action deactivate() {
    this._active = false;
  }

  subscribe() {
    return autorun(() => {
      if (
        this._root.appWindowState.status === APP_WINDOW_ACTIVE &&
        this._root.connection.isConnected
      ) {
        this.deactivate();
      }
    });
  }
}
