import React from 'react';
import {observer} from 'mobx-react-lite';
import TouchableButton, {TouchableButtonProps} from './TouchableButton';
import {useStrings} from '../Root/hooks';
import {sized} from '../Svg';
import ReloadSvg from '../assets/svg/colorless/reload.svg';

export type RetryButtonProps = TouchableButtonProps;

export default observer(function RetryButton(props: RetryButtonProps) {
  const strings = useStrings();
  return (
    <TouchableButton Icon={ReloadIcon} {...props}>
      {props.children ?? strings['action.retry']}
    </TouchableButton>
  );
});

const ReloadIcon = sized(ReloadSvg, 20);
