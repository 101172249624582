import {useState, useCallback} from 'react';

export function useToggle(initial = false) {
  const [isToggled, setToggle] = useState(initial);

  const toggle = useCallback(() => {
    setToggle(!isToggled);
  }, [isToggled]);

  return [isToggled, toggle] as const;
}
