export const FERNET_TOKEN = 'FERNET_TOKEN';
export const SELECTED_ACCOUNT_ID = 'SELECTED_ACCOUNT_ID';
export const DEMONSTRATION_DATABASE = 'DEMONSTRATION_DATABASE';
export const PREFERENCES = 'PREFERENCES';
export const CONFIGURATION = 'CONFIGURATION_0.0.1';
export const WAITED_TRANSACTIONS = 'WAITED_TRANSACTIONS';
export const DEVICE_ID = 'DEVICE_ID';
export const DASHBOARD_MODE = 'DASHBOARD_MODE';
export const DASHBOARD_SORT = 'DASHBOARD_SORT';
export const TUTORIAL_PARAMS = 'TUTORIAL_PARAMS';
export const WORKER_UPDATE_PERIOD = 'WORKER_UPDATE_PERIOD';
export const THEME_KIND = 'THEME_KIND';
export const RATE_US_IS_ALREADY_RATE = 'RATE_US_IS_ALREADY_RATE';
export const RATE_US_SKIP_VERSION = 'RATE_US_SKIP_VERSION';
export const RATE_US_QUANTITY_LAUNCH = 'RATE_US_QUANTITY_LAUNCH';
export const INSTALL_IDENTIFICATION = 'INSTALL_IDENTIFICATION';
export const APP_LIFECYCLE = 'APP_LIFECYCLE';
export const APP_ANALYTICS = 'APP_ANALYTICS';
export const PURCHASE_PROMO_CODE = 'PURCHASE_PROMO_CODE';
export const ADVERT_BANNERS_HISTORY = 'ADVERT_BANNERS_HISTORY';
export const LIMITED_OFFER = 'LIMITED_OFFER';
export const LAST_CHECKED_VERSION = 'LAST_CHECKED_VERSION';
