import React, {forwardRef, useCallback} from 'react';
import {
  Pressable,
  PressableProps,
  PressableStateCallbackType,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';

export interface PressableOpacityProps extends PressableProps {
  disabledOpacity?: number;
  activeOpacity?: number;
  style?: StyleProp<ViewStyle>;
}

export type StyleType = (
  state: PressableStateCallbackType,
) => StyleProp<ViewStyle>;

export default forwardRef<View, PressableOpacityProps>((props, ref) => {
  const {style, disabled, activeOpacity, disabledOpacity, ...passThroughProps} =
    props;

  const getOpacity = useCallback(
    (pressed: boolean) => {
      if (disabled) {
        return disabledOpacity ?? 1;
      } else {
        return pressed ? (activeOpacity ? activeOpacity : 0.8) : 1;
      }
    },
    [activeOpacity, disabled, disabledOpacity],
  );

  const _style = useCallback<StyleType>(
    ({pressed}) => [style, {opacity: getOpacity(pressed)}],
    [getOpacity, style],
  );
  return (
    <Pressable
      ref={ref}
      style={_style}
      disabled={disabled}
      {...passThroughProps}
    />
  );
});
