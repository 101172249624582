import React, {useCallback, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {DimensionValue, Platform, View, ViewProps} from 'react-native';
import dayjs from 'dayjs';

import {PanelView} from '../components';
import ChartView, {ChartViewRef, ChartViewScope} from './ChartView';
import chroma from 'chroma-js';
import {useRoot} from '../Root/hooks';
import {Millisecond} from '../utils/time';
import {Graph} from './Graph';
import TitleView from './TitleView';
import FilterView from './FilterView';
import ButtonGroupView from './ButtonGroupView';
import {ReadonlyDeep} from 'type-fest';
import {CryptoLoader} from '../components/CryptoLoader';
import {DatePicker} from '../components/DatePicker';
import NoData from '../components/NoData';
import {createStylesHook, useTheme} from '../styling';
import {DESKTOP_BREAKPOINT} from '../units/constants';

export interface GraphPanelProps extends ViewProps {
  title: string;
  fractionDigits: number;
  tooltipHelperText?: string;
  titleRight?: React.ReactNode;
  onFromChange?: (from: Millisecond) => void;
  from: Millisecond;
  to: Millisecond;
  onScopeChange?: (scope: ChartViewScope) => void;
  scope: ChartViewScope;
  isLoading?: boolean;
  graph?: ReadonlyDeep<Graph>;
  primaryTitle?: string;
  secondaryAxisShown?: boolean;
  secondaryTitle?: string;
  secondaryAxisRatio?: number;
  secondaryFractionDigits?: number;
}

export default observer(
  ({
    title,
    fractionDigits,
    tooltipHelperText,
    titleRight,
    onFromChange,
    from,
    to,
    onScopeChange,
    scope,
    isLoading,
    graph,
    primaryTitle = title,
    secondaryAxisShown,
    secondaryTitle,
    secondaryAxisRatio,
    secondaryFractionDigits,
    ...rest
  }: GraphPanelProps) => {
    const theme = useTheme();
    const isDark = useMemo(
      () => chroma(theme.palette.background).luminance() <= 0.5,
      [theme],
    );
    const styles = useStyles();
    const {translation} = useRoot();
    const graphRef = useRef<ChartViewRef>(null);
    const {strings} = translation;

    const [modalShown, setModalShown] = useState(false);
    const toggleModal = useCallback(
      () => setModalShown(!modalShown),
      [modalShown],
    );

    const setDayScope = useCallback(() => {
      graphRef.current?.reset();
      onScopeChange?.(ChartViewScope.Day);
    }, [onScopeChange]);
    const setMonthScope = useCallback(() => {
      graphRef.current?.reset();
      onScopeChange?.(ChartViewScope.Month);
    }, [onScopeChange]);

    const onConfirm = useCallback(
      (date: Date) => {
        onFromChange?.(date.getTime());
        setModalShown(false);
      },
      [onFromChange],
    );
    const onCancel = useCallback(() => setModalShown(false), []);

    const date = useMemo(() => new Date(from), [from]);

    const secondary = secondaryAxisShown
      ? {secondaryTitle, secondaryAxisRatio, secondaryFractionDigits}
      : {};

    return (
      <>
        <PanelView {...rest}>
          <TitleView
            style={styles.title}
            title={title}
            titleRight={titleRight}
          />
          <View style={styles.headerView}>
            <View style={styles.filterViewWrapper}>
              <FilterView
                modalShown={modalShown}
                from={from}
                to={to}
                onPress={toggleModal}
              />
              <DatePicker
                styleWeb={styles.picker}
                maximumDate={dayjs().subtract(1, scope).startOf('day').toDate()}
                isDarkModeEnabledMobile={isDark}
                cancelTextIOS={strings['action.cancel']}
                confirmTextIOS={strings['action.confirm']}
                headerTextIOS={strings['action.pickADate']}
                isVisible={modalShown}
                date={date}
                onConfirm={onConfirm}
                onCancel={onCancel}
              />
            </View>
            <ButtonGroupView
              style={styles.buttonGroup}
              scope={scope}
              onDayPress={setDayScope}
              onMonthPress={setMonthScope}
            />
          </View>
          <View style={styles.chartContainer}>
            {isLoading ? (
              <View style={[styles.chartView, styles.chartViewCenter]}>
                <CryptoLoader />
              </View>
            ) : graph && graph.series.length >= 3 ? (
              <ChartView
                ref={graphRef}
                style={styles.chartView}
                series={graph.series}
                from={graph.from}
                to={graph.to}
                scope={scope}
                title={primaryTitle}
                fractionDigits={fractionDigits}
                tooltipHelperText={tooltipHelperText}
                {...secondary}
              />
            ) : (
              <View style={[styles.chartView, styles.chartViewCenter]}>
                <NoData />
              </View>
            )}
          </View>
        </PanelView>
      </>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  root: {
    borderRadius: 15,
  },
  title: {
    margin: 10,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        margin: 20,
        marginBottom: 15,
        marginRight: 10,
      },
    }),
  },
  buttonGroup: {
    marginTop: 10,
    marginHorizontal: 10,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        justifyContent: 'flex-end',
        marginTop: 0,
      },
    }),
  },
  chartContainer: {
    flexDirection: 'row',
    backgroundColor: theme.select(
      theme.palette.background,
      theme.palette.backdrop,
    ),
  },
  chartView: {
    flexGrow: 1,
    flexShrink: 1,
    height: 300,
    ...Platform.select({
      web: {
        height: `${((176 + 60) / 375) * 100}vw` as DimensionValue,
      },
      default: {
        aspectRatio: (330 + 30) / (176 + 60),
      },
    }),
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        height: 335,
      },
    }),
  },
  chartViewCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  picker: {
    position: 'absolute',
    top: 54,
    left: -1,
  },
  headerView: {
    position: 'relative',
    zIndex: 2,
    backgroundColor: theme.select(
      theme.palette.background,
      theme.palette.backdrop,
    ),
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.border,
        borderTopWidth: 1,
        borderTopColor: theme.palette.border,
        backgroundColor: theme.palette.background,
      },
    }),
  },
  filterViewWrapper: {
    position: 'relative',
    zIndex: 2,
  },
}));
