import {useCallback} from 'react';
import {Platform} from 'react-native';
import {useGoToAppStore, useGoToPlayStore} from '../Navigation/hooks';

export default () => {
  const goToAppStore = useGoToAppStore();
  const goToPlayStore = useGoToPlayStore();
  return useCallback(async () => {
    Platform.OS === 'ios' ? await goToAppStore() : await goToPlayStore();
  }, [goToAppStore, goToPlayStore]);
};
