export enum FarmLogVariant {
  ShowAll,

  Mining = 'mining',
  MiningPaused = 'mining_paused',
  MiningRestarted = 'mining_restarted',
  NewMiner = 'new_miner',
  MinerOnline = 'miner_online',
  MinerOffline = 'miner_offline',
  MinerOverheated = 'miner_overheated',
  Confirmation = 'poolminer_paused',

  Subscription = 'subscription',
  SubscriptionChanged = 'subscription_changed',
  SubscriptionActive = 'subscription_active',
  SubscriptionInactive = 'subscription_inactive',
  PoolMinerActivation = 'poolminer_activated',
  PoolMinerExpiration = 'poolminer_expired',

  Withdrawal = 'withdrawal',
  WithdrawalProcessed = 'withdrawal_processed',
  WithdrawalFailed = 'withdrawal_failed',
}
