import {Opaque} from 'type-fest';
import {PurchaseId} from '../../units';
import {ISODateString, Second} from '../../Time';
import {Locale} from '../../Preferences';
import {SlotCount} from '../units';

export enum PurchaseType {
  Subscription = 'SUBSCRIPTION',
  Product = 'PRODUCT',
}

export enum PlanType {
  PoolMiner = 'POOL_MINER',
  Slot = 'SLOT',
}

export type GetTariffsResult = {
  tariffs: Tariff[];
  discounts: Discount[];
};

export type Discount = {
  is_i18n: boolean;
  desc: Record<Locale, string>;
  discount: number;
  start_time: ISODateString;
  end_time: ISODateString;
  promo_code: null;
};

export type Tariff = {
  name: TariffName;
  description: {};
  plans: Plan[];
};

export type TariffName = 'BASIC' | 'PoolMiner' | 'Activator' | 'Trial';

export type PoolMinerConfig = {
  id?: number;
  name: string;
  desc: string;
  hash_rate: Hashrate;
  frequency: number;
  cores_count: number;
  threads_count: number;
};
export type PlanRate = {
  amount: string; // '0.0065612100'
  currency: string; // 'BTC'
  decimals: number; // 8
  rate: string; // '0.00003855'
};
export type IntervalType = 'day' | 'month';
export type Plan = {
  id: PlanId;
  name: string;
  id_on_platform: PurchaseId;
  pool_miner_config: PoolMinerConfig | null;
  bundle_id: string;
  priority: number;
  interval: Interval;
  type: PurchaseType;
  price?: number;
  product: PlanType;
  interval_type: IntervalType;
  crypto_rates: PlanRate[];
  max_slots: SlotCount | null;
  options: {
    free_pm: boolean;
    activate_pm_after: Second | null;
  } | null;
};

export const INTERVAL = Symbol();
export type Interval = Opaque<number, typeof INTERVAL>;
export const HASHRATE = Symbol();
export type Hashrate = Opaque<number, typeof HASHRATE>;

export const PLAN_ID = Symbol();
export type PlanId = Opaque<string, typeof PLAN_ID>;

export type Platform = 'web' | 'ios' | 'android';
