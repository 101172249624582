import {observer} from 'mobx-react-lite';
import {useRoot} from '../Root/hooks';
import React from 'react';
import {expr} from 'mobx-utils';
import NoConnectionBinding from './NoConnectionBinding';

export default observer(function NoConnectionContainer() {
  const {connection, noConnectionModal, customDisconnect} = useRoot();
  const visible = expr(
    () =>
      connection.isInitialized &&
      !connection.isConnected &&
      (noConnectionModal.shown || customDisconnect.active),
  );
  return visible ? <NoConnectionBinding /> : null;
});
