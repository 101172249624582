import {
  Subscription,
  SubscriptionIosPeriod,
  SubscriptionPlatform,
} from 'react-native-iap';
import {TrialPeriodUnit} from '../InAppOffersService';

export default (_: Subscription) => {
  if (_.platform !== SubscriptionPlatform.ios) {
    return null;
  }
  if (
    !_.introductoryPriceNumberOfPeriodsIOS ||
    !(_.introductoryPricePaymentModeIOS === 'FREETRIAL') ||
    !_.introductoryPriceSubscriptionPeriodIOS
  ) {
    return null;
  }
  const unit = getUnit(_.introductoryPriceSubscriptionPeriodIOS);
  if (!unit) {
    return null;
  }
  const value = parseInt(_.introductoryPriceNumberOfPeriodsIOS, 10);
  return {value, unit};
};

const getUnit = (_: SubscriptionIosPeriod): TrialPeriodUnit | undefined => {
  switch (_) {
    case 'DAY':
      return 'D';
    case 'WEEK':
      return 'W';
    case 'MONTH':
      return 'M';
    case 'YEAR':
      return 'Y';
    default:
      return undefined;
  }
};
