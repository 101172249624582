import React from 'react';
import {BottomTabBindingProps} from './BottomTabBindingProps';
import {observer} from 'mobx-react-lite';
import {useCallback} from 'react';
import {SubscriptionRequestScreen} from '../../SubscriptionRequestScreen';

export type WithdrawBindingProps = BottomTabBindingProps<'Withdraw'>;

export default observer((props: WithdrawBindingProps) => {
  const {navigation} = props;
  const goToPlans = useCallback(
    () => navigation.navigate('Plan'),
    [navigation],
  );
  return <SubscriptionRequestScreen goToPlans={goToPlans} />;
});
