import React from 'react';
import {Text, TextProps} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../../styling';

export default observer((props: TextProps) => {
  const {children, style, ...rest} = props;

  const styles = useStyles((theme) => ({
    text: {
      flexShrink: 1,
      fontSize: 22,
      lineHeight: 32,
      letterSpacing: 0.06,
      ...theme.fontByWeight('700'),
      color: theme.palette.textPrimary,
      ...theme.mediaQuery({
        1000: {
          fontSize: 32,
          lineHeight: 35,
        },
      }),
    },
  }));

  return (
    <Text style={[styles.text, style]} {...rest}>
      {children}
    </Text>
  );
});
