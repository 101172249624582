import type {FirebaseOptions} from '../WebFirebase/app';

export const FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: 'AIzaSyBNgOQKDCb2gUd3N9SQC2KHcyB_BeVrFls',
  authDomain: 'cryptotab-farm.firebaseapp.com',
  databaseURL: 'https://cryptotab-farm.firebaseio.com',
  projectId: 'cryptotab-farm',
  storageBucket: 'cryptotab-farm.appspot.com',
  messagingSenderId: '513854084139',
  appId: '1:513854084139:web:a67acbbed023c8edae866e',
  measurementId: 'G-N8MVTD9HTX',
};

export const VAPID_KEY =
  'BExjJ8Cmz8VFyK676DXukRzQURP0eG4v-HAJdku0IhtDFw58TRro3j2zkGyqWLC0nbWj24nH0yEptM16oDuWZrs';
