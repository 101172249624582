import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {ScheduleScreen} from '../../ScheduleScreen';
import {CommonActions} from '@react-navigation/native';
import {DASHBOARD} from './constants';

export type ScheduleBindingProps = RootStackBindingProps<'Schedule'>;

export default observer(({route, navigation}: ScheduleBindingProps) => {
  const {workerIds, ownerName, goBackState} = route.params;
  const leave = useCallback(() => {
    navigation.dispatch(CommonActions.reset(goBackState ?? DASHBOARD));
  }, [navigation, goBackState]);
  return (
    <ScheduleScreen ownerName={ownerName} leave={leave} workerIds={workerIds} />
  );
});
