import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {WorkerDetailsScreen} from '../../WorkerDetailsScreen';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useRoot} from '../../Root/hooks';
import {expr} from 'mobx-utils';
import {WorkerType} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {Platform} from 'react-native';

export type WorkerDetailsBindingProps = RootStackBindingProps<'WorkerDetails'>;

export default observer(({navigation, route}: WorkerDetailsBindingProps) => {
  const {dashboardStore, proposalsState} = useRoot();
  const workerId = route.params.workerId;
  const goToSchedule = useCallback(() => {
    navigation.navigate('Schedule', {
      workerIds: [workerId],
    });
  }, [navigation, workerId]);
  const onDeleted = useCallback(() => {
    navigation.goBack();
  }, [navigation]);
  const goToRenew = useCallback(() => {
    navigation.navigate('RenewAccess', {workerId: workerId});
  }, [navigation, workerId]);
  const isLoading = dashboardStore.isLoading;
  const worker = dashboardStore.getWorkerById(workerId);
  const workerHashrate = worker?.pool_miner_config?.hash_rate;
  const hashrateConfigList = expr(() =>
    proposalsState.minerProductProposalList.flatMap((_) =>
      _.available ? [_.offer.poolMinerConfig?.hash_rate] : [],
    ),
  );
  const visibleRenewButton = expr(
    () =>
      worker?.worker_type === WorkerType.Pool &&
      (workerHashrate ? hashrateConfigList.includes(workerHashrate) : false) &&
      Platform.OS === 'android',
  );
  return (
    <WorkerDetailsScreen
      onDeleted={onDeleted}
      goToScheduler={goToSchedule}
      goToRenew={goToRenew}
      isLoading={isLoading}
      visibleRenewButton={visibleRenewButton}
      worker={worker}
    />
  );
});
