import {useCallback} from 'react';
import {RootParamList} from '../RootStack';
import {ISODateString} from '../../Time';

export default function useGoToPoolMinerActivatedCallback(
  navigate: (params: RootParamList['PoolMinerActivated']) => void,
) {
  return useCallback(
    (poolExpiredDate: ISODateString, poolHashrate: number) => {
      navigate({
        poolHashrate,
        poolExpiredDate,
      });
    },
    [navigate],
  );
}
