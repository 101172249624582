import React from 'react';
import {View as AnimatableView} from 'react-native-animatable';
import {useCursorPosition} from '../hooks/useCursorPosition';
import {useStyles} from '../styling';
import {Image, PixelRatio, Platform} from 'react-native';

const LEFT_BTC_BACKGROUND =
  PixelRatio.get() === 1
    ? require('../assets/bg_btc_left.png')
    : require('../assets/bg_btc_left@2x.png');

const RIGHT_BTC_BACKGROUND =
  PixelRatio.get() === 1
    ? require('../assets/bg_btc_right.png')
    : require('../assets/bg_btc_right@2x.png');

export const Particles = () => {
  const {x, y} = useCursorPosition();
  const styles = useStyles(() => ({
    root: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      zIndex: -1,
    },
    left: {
      left: '-50%',
    },
    right: {
      right: '-50%',
    },
    background: {
      height: '100%',
      width: '100%',
    },
  }));

  return (
    <>
      <AnimatableView
        style={[
          styles.root,
          styles.left,
          {transform: [{translateX: x / 30}, {translateY: y / 30}]},
        ]}
        useNativeDriver={Platform.OS !== 'web'}>
        <Image
          style={styles.background}
          source={LEFT_BTC_BACKGROUND}
          resizeMode="contain"
        />
      </AnimatableView>
      <AnimatableView
        style={[
          styles.root,
          styles.right,
          {transform: [{translateX: x / 15}, {translateY: y / 20}]},
        ]}
        useNativeDriver={Platform.OS !== 'web'}>
        <Image
          style={styles.background}
          source={RIGHT_BTC_BACKGROUND}
          resizeMode="contain"
        />
      </AnimatableView>
    </>
  );
};
