import {action, computed, observable, makeObservable} from 'mobx';
import {SchedulerEntity} from '../../universal/features/api/entity/schedule/SchedulerEntity';
import {request} from '../../universal/mobx/RequestStore';
import {WorkerId} from '../ApiStore';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import {bind} from '../fp';
import {ConnectedClient} from '../ContextClient';
import {SocketApiService} from '../../universal/features/api/socket/SocketApiService';

export class ScheduleStore {
  constructor(
    private readonly _root: {
      readonly socketApi: SocketApiService;
      readonly connectedClient: ConnectedClient;
      readonly dashboardStore: DashboardStore;
    },
  ) {
    makeObservable(this);
  }

  @observable private _shallNotifyAboutUpdates = true;

  get shallNotifyAboutUpdate() {
    return this._shallNotifyAboutUpdates;
  }

  private _denyNotifications = action(() => {
    this._shallNotifyAboutUpdates = false;
  });

  restoreNotifications = action(() => {
    this._shallNotifyAboutUpdates = true;
  });

  saveScheduleList = bind((scheduleList: Map<WorkerId, SchedulerEntity[]>) => {
    this._denyNotifications();
    return Promise.all(
      Array.from(scheduleList).map(([id, schedules]) =>
        this._root.connectedClient.call('setup_scheduler', {
          worker_id: id,
          schedulers: schedules,
        }),
      ),
    );
  }, this);

  deleteScheduleRequest = request(async (workerIds: WorkerId[]) => {
    this._denyNotifications();
    return await Promise.all(
      workerIds.map((workerId) =>
        this._root.socketApi.deleteSchedule(workerId),
      ),
    );
  });

  @computed
  get isLoading() {
    return this._root.dashboardStore.dashboardRequest.isLoading;
  }
}
