import React, {useCallback, useState} from 'react';

import {useRoot, useStrings} from '../../Root/hooks';
import {WorkerGroupEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';
import {ReadonlyDeep} from 'type-fest';
import {observer} from 'mobx-react-lite';
import InputModal from '../../modals/InputModal';
import {ButtonColor, ButtonVariant} from '../../components';
import {useHandleErrorAndNavigate} from '../../ExceptionHandler';

interface RenameWorkerGroupModalProps {
  group: ReadonlyDeep<WorkerGroupEntity>;
  onDismiss: () => void;
}

export default observer(({group, onDismiss}: RenameWorkerGroupModalProps) => {
  const {connectedClient} = useRoot();
  const [workerGroupName, setWorkerGroupName] = useState(group.name || '');
  const strings = useStrings();
  const errorHandler = useHandleErrorAndNavigate();
  const onSubmit = useCallback(async () => {
    const result = await connectedClient.call('rename_group', {
      group_id: group.id,
      name: workerGroupName,
    });
    if (!result.success) {
      errorHandler(result.left);
    }
    onDismiss();
  }, [connectedClient, errorHandler, group.id, onDismiss, workerGroupName]);
  return (
    <InputModal
      inputProps={{
        autoFocus: true,
        defaultValue: workerGroupName,
        onChangeText: setWorkerGroupName,
        subtext: strings['modal.renameWorkerGroup.subtext'],
      }}
      onDismiss={onDismiss}
      title={strings['modal.renameWorkerGroup.title']}
      buttons={[
        {
          text: strings['action.cancel'],
          onPress: onDismiss,
          color: ButtonColor.Default,
          variant: ButtonVariant.Contained,
        },
        {
          text: strings['action.confirm'],
          onPress: onSubmit,
          variant: ButtonVariant.Highlighted,
        },
      ]}
    />
  );
});
