import {AdItem, AdSpot} from '../ApiStore';
import {AdInfoItem} from './Advert';
import {Uri} from '../units';
import {Either, success} from '../fp';
import {GlobalError} from '../Error';
import dayjs from 'dayjs';
import {AdvertCreator} from './AdvertCreator';
import {AdvertBannersHistory} from './AdvertHistoryImpl';
import {shuffle, uniq} from 'lodash';
import StaticAdvertHelper from './StaticAdvertHelper';

export default class AdvertCreatorImpl implements AdvertCreator {
  private _translateAdItemToAdvertItem(_: AdItem): AdInfoItem[] {
    const image = StaticAdvertHelper.getImage(_.image);
    const icon = StaticAdvertHelper.getImage(_.icon);
    const spots = uniq([_.spot, ...(_.spots ?? [])]);

    return spots.map((spot) => ({
      id: _.id,
      spot: spot,
      type: _.type,
      size: _.size,
      title: _.title,
      body: _.body,
      image: image as Uri,
      icon: icon as Uri,
      link: _.link || undefined,
      options: {
        canClose: _.options.can_close ?? true,
        closeTime: _.options.close_time || undefined,
        force: _.options.force,
        canExclude: _.options.can_exclude,
      },
      actions: _.actions.map((action) => ({
        title: action.title,
        link: action.link || undefined,
        icon: action.icon || undefined,
      })),
    }));
  }

  private async _createSpotByBannerMap(
    items: AdInfoItem[],
    history: AdvertBannersHistory | null,
    checkIsClosed?: (spot: AdSpot) => boolean,
  ): Promise<Either<ReadonlyMap<AdSpot, AdInfoItem[]>, GlobalError>> {
    const schedule = history?.schedule ?? {};
    const availableItems = items.filter((item) => {
      if (checkIsClosed?.(item.spot)) {
        return false;
      }
      if (item.options.force) {
        return true;
      }
      const nextShowTime = schedule[item.id]?.nextShowTime;
      if (nextShowTime === undefined) {
        return true;
      }
      return dayjs(nextShowTime).isBefore(dayjs());
    });
    const bannersBySpot = new Map<AdSpot, AdInfoItem[]>();
    for (const item of availableItems) {
      const spotItems = bannersBySpot.get(item.spot);
      if (spotItems === undefined) {
        bannersBySpot.set(item.spot, [item]);
      } else {
        bannersBySpot.set(item.spot, [...spotItems, item]);
      }
    }

    bannersBySpot.forEach((value, key) =>
      bannersBySpot.set(key, shuffle(value)),
    );

    return success(bannersBySpot);
  }

  async process(
    items: AdItem[],
    history: AdvertBannersHistory | null,
    checkIsClosed?: (spot: AdSpot) => boolean,
  ): Promise<Either<ReadonlyMap<AdSpot, AdInfoItem[]>, GlobalError>> {
    const translatedItems = items.flatMap((_) =>
      this._translateAdItemToAdvertItem(_),
    );
    const bannerMap_ = await this._createSpotByBannerMap(
      translatedItems,
      history,
      checkIsClosed,
    );
    if (!bannerMap_.success) {
      return bannerMap_;
    }
    return success(bannerMap_.right);
  }
}
