import {Interval, Matrix} from './types';
import minuteListToMatrix from './minuteListToMatrix';
import shiftMinuteListByMinutes from './shiftMinuteListByMinutes';
import {ReadonlyDeep} from 'type-fest';
import _ from 'lodash';

export default (
  intervals: readonly ReadonlyDeep<Interval>[],
  timezoneOffset: number,
): Matrix => {
  const EMPTY_TIMELINE = Array(7 * 24 * 60).fill(0);
  if (intervals.length === 0) {
    return minuteListToMatrix(EMPTY_TIMELINE);
  }

  let minuteList: number[] = EMPTY_TIMELINE;
  intervals.forEach((interval) => {
    _.fill(
      minuteList,
      interval.settings.speed,
      interval.start_time,
      interval.stop_time,
    );
  });

  return minuteListToMatrix(
    shiftMinuteListByMinutes(minuteList, timezoneOffset),
  );
};
