import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import RootView from './RootView';
import ErrorPanel from './ErrorPanel';
import layout from './layout';
import TouchableButton from './TouchableButton';
import RetryButton from './RetryButton';

export type NoConnectionScreenProps = {
  onRetry: () => void;
  onSwitchEnvironment: (() => void) | undefined;
};

export default observer(function NoConnectionScreen(
  props: NoConnectionScreenProps,
) {
  const {onRetry, onSwitchEnvironment} = props;
  const strings = useStrings();
  const footer = useMemo(
    () => (
      <>
        {onSwitchEnvironment && (
          <TouchableButton
            onPress={onSwitchEnvironment}
            style={layout.buttonGutter}>
            Switch environment
          </TouchableButton>
        )}
        <RetryButton onPress={onRetry} />
      </>
    ),
    [onRetry, onSwitchEnvironment],
  );
  return (
    <RootView style={layout.root} contentContainerStyle={layout.container}>
      <ErrorPanel
        title={strings['error.noConnection.title']}
        description={strings['error.noConnection.description']}
        footer={footer}
      />
    </RootView>
  );
});
