import {Share} from './Share';

export default class WebShareImpl implements Share {
  get isSupported() {
    return window.navigator.share !== undefined;
  }

  async shareMessage(message: string) {
    if (this.isSupported) {
      try {
        await window.navigator.share({text: message});
      } catch (ignore) {}
    }
  }
}
