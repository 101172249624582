import {RefSearcher} from './RefSearcher';
import {Either, error} from '../fp';
import UrlParameterRefSearcherImpl from './UrlParameterRefSearcherImpl';
import {LocationSource} from '../Location';
import InstallRefSearcherFactory from './InstallRefSearcherFactory';
import SlashedParameterRefSearcherImpl from './SlashedParameterRefSearcherImpl';

export default class BatchRefSearcherImpl implements RefSearcher {
  private readonly _searchers: RefSearcher[];
  constructor(
    private readonly _root: {
      readonly locationSource: LocationSource;
    },
  ) {
    this._searchers = [
      new UrlParameterRefSearcherImpl(_root),
      new SlashedParameterRefSearcherImpl(_root),
      new InstallRefSearcherFactory(_root).create(),
    ];
  }

  async find(): Promise<Either<string, void>> {
    for (const searcher of this._searchers) {
      const find_ = await searcher.find();
      if (find_.success) {
        return find_;
      }
    }
    return error(undefined);
  }
}
