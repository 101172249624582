import React, {useMemo} from 'react';
import {ButtonColor, ButtonVariant} from '../components';
import {observer} from 'mobx-react-lite';
import ActionModal from '../modals/ActionModal';
import AddWorkerSvg from '../assets/svg/circled/addWorker.svg';
import {useStrings} from '../Root/hooks';
import {JsonRpcError} from '../JsonRpc';

export interface ErrorModalProps {
  onSubmit: () => void;
  onClose: () => void;
  error: JsonRpcError;
}

export default observer((props: ErrorModalProps) => {
  const strings = useStrings();
  const title = useMemo(() => {
    const base = strings['modal.linkErrorModal.title'];
    switch (props.error.code) {
      case 2002:
        return `${strings['modal.linkErrorModal.notFound']} ${base}`;
      case 2004:
        return `${strings['modal.linkErrorModal.noSlots']} ${base}`;
      case 1000:
        return `${strings['modal.linkErrorModal.unexpected']} ${base}`;
      default:
        return `${base} Code: ${props.error.code}`;
    }
  }, [props.error.code, strings]);
  return (
    <ActionModal
      Icon={AddWorkerSvg}
      title={title}
      buttons={[
        {
          text: strings['action.ok'],
          onPress: props.onClose,
          variant: ButtonVariant.Highlighted,
        },
        {
          text: strings['action.support'],
          onPress: props.onSubmit,
          color: ButtonColor.Error,
          variant: ButtonVariant.Highlighted,
        },
      ]}
      onDismiss={props.onClose}>
      {strings['modal.linkErrorModal.description']}
    </ActionModal>
  );
});
