import React, {useMemo, useCallback, useRef} from 'react';
import {View, Text, TouchableOpacity, FlatList} from 'react-native';
import {useRoot, useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {defaultContainerStyle} from '../components/Grid';
import {FarmLogEntity} from '../Notifications';
import timestampToString from './timestampToString';
import {ScreenFluidNoData} from '../components/NoData';
import {PageFlatList} from '../containers';
import PageHeader from '../components/PageHeader';
import {Button, ButtonColor} from '../components';
import SettingsSvg from '../assets/svg/colorless/settings.svg';
import {useNavigation} from '@react-navigation/native';
import {NotificationListScreenProps} from './NotificationListScreen';
import {MoreDataActivityIndicator} from '../components/loaders';
import {ScreenFluidLoader} from '../components/CryptoLoader';
import {useStyles} from '../styling';
import useScrollHandlers from './useScrollHandlers';
import ToTopButton from './ToTopButton';
import {keyExtractor, ListProps} from './types';
import Markdown from '../components/Markdown';

export default observer(() => {
  const {
    notificationService: {list},
  } = useRoot();
  const scrollRef = useRef<FlatList>(null);
  const strings = useStrings();
  const {onScroll, scrollY} = useScrollHandlers(scrollRef);
  const navigation = useNavigation<NotificationListScreenProps['navigation']>();
  const styles = useStyles((theme) => ({
    root: {
      flex: 1,
    },
    contentScroll: {
      flexGrow: 1,
    },
    content: {
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'stretch',
    },
    buttonWrapper: {
      borderLeftWidth: 1,
      borderLeftColor: theme.palette.border,
      height: '100%',
      paddingHorizontal: 20,
      justifyContent: 'center',
    },
    pageHeader: {
      marginTop: 20,
    },
    divider: {
      height: 40,
    },
  }));
  const toSettings = useCallback(() => {
    navigation.navigate('NotificationsSettings');
  }, [navigation]);
  const toTop = useCallback(() => {
    scrollRef.current?.scrollToOffset({offset: 0, animated: true});
  }, []);
  return (
    <View style={styles.root}>
      <PageHeader
        style={styles.pageHeader}
        title={strings['screenTitle.notifications']}
        right={
          <View style={styles.content}>
            <View style={styles.buttonWrapper}>
              <TouchableOpacity activeOpacity={0.8} onPress={toSettings}>
                <Button Icon={SettingsSvg} color={ButtonColor.Primary}>
                  {strings.settings}
                </Button>
              </TouchableOpacity>
            </View>
          </View>
        }
      />
      {list.isLoading ? (
        <ScreenFluidLoader />
      ) : (
        <PageFlatList
          scrollRef={scrollRef}
          ListEmptyComponent={<ScreenFluidNoData />}
          data={list.items}
          renderItem={renderItem}
          onScroll={onScroll}
          keyExtractor={keyExtractor}
          ListHeaderComponent={<TableHead />}
          stickyHeaderIndices={[0]}
          contentContainerStyle={styles.contentScroll}
          onEndReached={list.fetchNext}
          initialNumToRender={30}
          showsVerticalScrollIndicator={false}
          ListFooterComponent={
            list.isLoading ? (
              <MoreDataActivityIndicator />
            ) : (
              <View style={styles.divider} />
            )
          }
        />
      )}
      {scrollY > 300 && <ToTopButton onPress={toTop} />}
    </View>
  );
});

const renderItem: ListProps['renderItem'] = ({item}) => (
  <View style={defaultContainerStyle}>
    <TableItem item={item} />
  </View>
);

const TableHead = observer(() => {
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    root: {
      flexDirection: 'row',
      backgroundColor: theme.palette.background,
      borderBottomColor: theme.palette.border,
      borderBottomWidth: 1,
      height: 35,
      alignItems: 'center',
    },
    dateColumn: {
      width: 210,
      paddingHorizontal: 30,
    },
    titleColumn: {},
    text: {
      ...theme.fontByWeight('700'),
      color: theme.palette.textPrimary,
      letterSpacing: 0.03,
    },
  }));

  return (
    <View style={[styles.root, defaultContainerStyle]}>
      <View style={styles.dateColumn}>
        <Text style={styles.text}>{strings['notifications.date']}</Text>
      </View>
      <View style={styles.titleColumn}>
        <Text style={styles.text}>{strings['notifications.notification']}</Text>
      </View>
    </View>
  );
});

interface TableItemProps {
  item: FarmLogEntity;
}

const TableItem = observer(({item}: TableItemProps) => {
  const {translation} = useRoot();
  const {localeTag} = translation;
  const dateString = useMemo(
    () => timestampToString(item.timestamp, localeTag),
    [item.timestamp, localeTag],
  );
  const styles = useStyles((theme) => ({
    root: {
      flexDirection: 'row',
      backgroundColor: theme.palette.background,
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.border,
      paddingTop: 11,
      paddingBottom: 13,
    },
    date: {
      ...theme.fontByWeight(),
      color: theme.palette.textSecondary,
      fontSize: 14,
      lineHeight: 15,
    },
    paragraph: {
      ...theme.fontByWeight('400'),
      fontSize: 14,
      lineHeight: 24,
      letterSpacing: 0.04,
      marginTop: 0,
      marginBottom: 0,
      color: theme.palette.textPrimary,
    },
    dateColumn: {
      width: 210,
      paddingHorizontal: 30,
      paddingTop: 5,
    },
    descriptionColumn: {
      flexShrink: 1,
    },
  }));
  return (
    <View style={styles.root}>
      <View style={styles.dateColumn}>
        <Text style={styles.date}>{dateString}</Text>
      </View>
      <View style={styles.descriptionColumn}>
        <Markdown
          styles={{
            paragraph: styles.paragraph,
          }}>
          {`**${item.title}**. ${item.body}`}
        </Markdown>
      </View>
    </View>
  );
});
