import React, {ReactNode, useCallback, useState} from 'react';
import {Platform, Text, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useTimer} from 'use-timer';

import {Button, ButtonVariant, PressableOpacity} from '../components';
import {useRoot, useStrings} from '../Root/hooks';
import {RouteProp, useRoute} from '@react-navigation/native';
import {RootParamList} from '../Navigation';
import Modal from '../modals/BaseModal';
import AppStoreSvg from '../assets/svg/colored/appStore.svg';
import GooglePlaySvg from '../assets/svg/colored/googlePlay.svg';
import {sized} from '../Svg';
import {createStylesHook, useTheme} from '../styling';
import StarSvg from '../assets/svg/colorless/star.svg';

import useOpenBrowserReview from './useOpenBrowserReview';
import useOpenRateModal from './useOpenRate';
import Markdown from '../components/Markdown';

export interface RateUsRedirectModalNavigationProps {
  route: RouteProp<RootParamList, 'RateUs'>;
}

export const RateUsRedirectModal = observer(() => {
  const route = useRoute<RateUsRedirectModalNavigationProps['route']>();
  const {starsNumber: rating} = route.params;
  const {rateApp} = useRoot();
  const strings = useStrings();
  const styles = useStyles();
  const theme = useTheme();
  const [timerWasDropped, setTimerDropped] = useState<boolean>(false);
  const openBrowserReview = useOpenBrowserReview();
  const openRate = useOpenRateModal();
  const openRateHandler = useCallback(() => {
    openRate(() => {
      rateApp.sendRating({rating});
    });
  }, [openRate, rateApp, rating]);
  const {time, reset} = useTimer({
    autostart: true,
    initialTime: 15,
    endTime: 0,
    timerType: 'DECREMENTAL',
    onTimeOver: openRateHandler,
  });
  const openBrowserReviewHandler = async () => {
    reset();
    await openBrowserReview();
    await rateApp.sendRating({rating});
  };
  const handleRatePress = async () => {
    reset();
    setTimerDropped(true);
    await openRateHandler();
  };
  const rules = {
    em: (key: number, children: ReactNode) => (
      <Text key={key} onPress={openBrowserReviewHandler}>
        {children}
      </Text>
    ),
  };
  const marketName =
    Platform.OS === 'ios'
      ? strings['modal.rateUs.iosMarketName']
      : strings['modal.rateUs.androidMarketName'];
  const description = strings['modal.rateUs.description'].replace(
    '{marketName}',
    marketName,
  );

  const Icon = Platform.select({
    ios: () => (
      <View style={styles.iconWrapper}>
        <AppStoreIcon />
      </View>
    ),
    default: () => (
      <View style={styles.iconWrapper}>
        <GooglePlayIcon />
      </View>
    ),
  });

  return (
    <Modal contentStyle={styles.modalView} onDismiss={rateApp.closeModal}>
      <View>
        <View style={styles.headerView}>
          <Icon />
          <View style={styles.starsView}>
            {new Array(5).fill('').map((_, i) => (
              <View key={i} style={styles.starView}>
                <StarIcon
                  color={
                    i < rating ? theme.palette.primary : theme.palette.disabled
                  }
                />
              </View>
            ))}
          </View>
        </View>
        <Text style={styles.titleText}>{strings['modal.rateUs.title']}</Text>
        <Markdown
          rules={rules}
          styles={{
            paragraph: styles.paragraph,
            em: styles.subTitleLink,
          }}>
          {description}
        </Markdown>

        <View style={styles.footerView}>
          <PressableOpacity
            activeOpacity={0.7}
            onPress={handleRatePress}
            style={styles.rateButtonContainerView}>
            <Button variant={ButtonVariant.Highlighted}>
              {strings['modal.rateUs.rateInStore']}
            </Button>
            {!timerWasDropped ? (
              <View style={styles.rateTimerView}>
                <Text style={styles.rateTimerText}>{time}s</Text>
              </View>
            ) : null}
          </PressableOpacity>
          <PressableOpacity activeOpacity={0.7} onPress={rateApp.closeModal}>
            <Button>{strings['action.close']}</Button>
          </PressableOpacity>
        </View>
      </View>
    </Modal>
  );
});

const StarIcon = sized(StarSvg, 24);
const AppStoreIcon = sized(AppStoreSvg, 30);
const GooglePlayIcon = sized(GooglePlaySvg, 40, 30);

const useStyles = createStylesHook((theme) => ({
  modalView: {
    maxWidth: 450,
  },
  headerView: {
    alignItems: 'center',
    marginBottom: 16,
    marginTop: 10,
  },
  starsView: {
    flexDirection: 'row',
    marginTop: 6,
  },
  starView: {
    padding: 4,
  },
  titleText: {
    fontSize: 24,
    lineHeight: 32,
    marginBottom: 10,
    letterSpacing: 0.09,
    textAlign: 'center',
    color: theme.palette.textPrimary,
    ...theme.fontByWeight('700'),
  },
  paragraph: {
    fontSize: 14,
    lineHeight: 22,
    letterSpacing: 0.06,
    marginTop: 0,
    marginBottom: 25,
    textAlign: 'center',
    color: theme.palette.textSecondary,
    ...theme.fontByWeight(),
  },
  subTitleLink: {
    color: theme.palette.secondary,
    ...theme.fontByWeight(),
    fontStyle: 'normal',
  },
  iconWrapper: {
    width: 60,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.foreground,
    borderRadius: 30,
  },
  rateButtonContainerView: {
    position: 'relative',
    marginBottom: 15,
  },
  footerView: {},
  rateTimerView: {
    position: 'absolute',
    right: 15,
    top: 14,
  },
  rateTimerText: {
    opacity: 0.5,
    color: theme.contrast(theme.palette.textPrimary),
    letterSpacing: 0.04,
    fontSize: 12,
    lineHeight: 14,
    ...theme.fontByWeight(),
  },
}));
