import {Text, View, ViewProps} from 'react-native';
import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';

import {useTheme} from '../styling';

interface TitleViewProps extends ViewProps {
  title?: string;
  titleRight?: React.ReactNode;
}

export default observer(
  ({title, titleRight, style, ...rest}: TitleViewProps) => {
    const theme = useTheme();
    const styles = useMemo(
      () =>
        ({
          root: {
            flexDirection: 'row',
            alignItems: 'center',
          },
          title: {
            flexGrow: 1,
            flexShrink: 1,
            ...theme.fontByWeight('bold'),
            fontSize: 18,
            lineHeight: 25,
            color: theme.palette.textPrimary,
            ...theme.mediaQuery({
              1000: {
                fontSize: 32,
                lineHeight: 37,
              },
            }),
          },
        } as const),
      [theme],
    );
    return (
      <View style={[styles.root, style]} {...rest}>
        <Text style={styles.title}>{title}</Text>
        {titleRight}
      </View>
    );
  },
);
