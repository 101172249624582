import {Clipboard} from './Clipboard';
import BaseClipboardImpl from './BaseClipboardImpl';
import StaticWebClipboardHelper from './StaticWebClipboardHelper';

// TODO: Use expo-clipboard after updating it
export default class WebClipboardImpl
  extends BaseClipboardImpl
  implements Clipboard
{
  setString(value: string) {
    if (!navigator.clipboard) {
      StaticWebClipboardHelper.legacySetValue(value);
      return;
    }
    StaticWebClipboardHelper.navigatorSetValue(value);
  }
}
