import {Core} from '../Core';
import {Root} from './Root';
import {batchDisposers, Service} from '../structure';
import BaseRootService from './BaseRootService';
import {MobileLinkingOptionsProviderImpl} from '../LinkingOptionsProvider';
import {MobileLocationService} from '../Location';
import {
  HeadlessLocalNotificationsFactory,
  LocalNotificationsLocationSourceImpl,
  MobileLocalNotificationsService,
} from '../LocalNotifications';
import {MobileMessagingService} from '../Messaging';
import {MobileMessagingIdentityService} from '../MessagingIdentity';
import {MobileShareImpl} from '../Share';
import {MobileSharingImpl} from '../Sharing';
import {FirebaseAnalyticsBridgeService} from '../Log';
import {FirebaseAnalyticsImpl} from '../FirebaseAnalytics';
import {
  AppAnalyticsListenerService,
  AppAnalyticsService,
} from '../AppAnalytics';
import {MobileClipboardImpl} from '../Clipboard';
import {InAppPurchaseManagerFactory} from '../InAppPurchaseManager';

export default class MobileRootService
  extends BaseRootService
  implements Root, Service
{
  constructor(protected readonly _core: Core) {
    super(_core);
  }

  subscribe() {
    return batchDisposers(
      super.subscribe(),
      this.location.subscribe(),
      this.localNotifications.subscribe(),
      this.messaging.subscribe(),
      this.messagingIdentity.subscribe(),
      this.inAppPurchaseManager.subscribe(),
      this.firebaseAnalyticsBridgeService.subscribe(),
      this.appAnalytics.subscribe(),
      this.appAnalyticsListener.subscribe(),
    );
  }

  readonly clipboard = new MobileClipboardImpl();
  readonly firebaseAnalytics = new FirebaseAnalyticsImpl();
  readonly appAnalytics = new AppAnalyticsService(this);
  readonly appAnalyticsListener = new AppAnalyticsListenerService(this);
  readonly firebaseAnalyticsBridgeService = new FirebaseAnalyticsBridgeService(
    this,
  );

  readonly localNotificationsLocationSource =
    new LocalNotificationsLocationSourceImpl(this);
  readonly location = new MobileLocationService(this);
  readonly locationSource = this.location;
  readonly linkingOptionsProvider = new MobileLinkingOptionsProviderImpl(this);
  private readonly _headlessLocalNotificationsFactory =
    new HeadlessLocalNotificationsFactory(this);
  readonly headlessLocalNotifications =
    this._headlessLocalNotificationsFactory.create();
  readonly localNotifications = new MobileLocalNotificationsService(this);
  readonly messaging = new MobileMessagingService(this);
  readonly messagingIdentity = new MobileMessagingIdentityService();
  readonly inAppPurchaseManager = new InAppPurchaseManagerFactory(
    this,
  ).create();
  readonly share = new MobileShareImpl();
  readonly sharing = new MobileSharingImpl(this);
}
