import {DEFAULT_SLOTS_SHOWN} from '../constants';
import {DashboardScreenProps} from '../DashboardScreenProps';

export default (
  params: DashboardScreenProps['route']['params'],
  groupId?: number,
) =>
  (groupId === undefined
    ? params?.emptyGroupOptions?.slotsShown
    : params?.groupOptionsById?.[groupId]?.slotsShown) ?? DEFAULT_SLOTS_SHOWN;
