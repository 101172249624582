import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {QuickStartScreen} from '../../QuickStartScreen';
import {RootStackBindingProps} from './RootStackBindingProps';
import {
  useGoToPoolMinerActivatedCallback,
  useNavigationGetIsFocused,
} from '../hooks';
import {useCommonQuickStartViewState} from '../../QuickStartService';

export default observer(function QuickStartBinding({
  navigation,
}: RootStackBindingProps<'QuickStart'>) {
  const goToPoolMinerActivated = useGoToPoolMinerActivatedCallback((params) =>
    navigation.navigate('PoolMinerActivated', params),
  );
  const getIsFocused = useNavigationGetIsFocused();
  const goToFinishQuickStart = useCallback(
    (selectedIndex: number, recommendedIndex: number) =>
      navigation.navigate('FinishQuickStart', {
        selectedIndex,
        recommendedIndex,
      }),
    [navigation],
  );
  const goToPurchases = useCallback(
    () => navigation.replace('Plan'),
    [navigation],
  );
  const {
    selectedItemIndex,
    setSelectedItemIndex,
    items,
    getSelectedItem,
    purchase,
    isLoading,
    trial,
  } = useCommonQuickStartViewState({
    goToPoolMinerActivated,
    goToFinishQuickStart,
    getIsFocused,
  });
  return (
    <QuickStartScreen
      selectItem={setSelectedItemIndex}
      selectedItemIndex={selectedItemIndex}
      onCustomizePress={goToPurchases}
      isLoading={isLoading}
      onSubscribe={purchase}
      items={items}
      selectedItem={getSelectedItem()}
      trial={trial}
    />
  );
});
