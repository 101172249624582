import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {View, TextInput, Button, Alert} from 'react-native';
import {createStylesHook} from '../styling';
import {TextInputState} from '../forms';
import {useRoot} from '../Root/hooks';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';

export default observer(() => {
  const [resetTime, setResetTime] = useState(true);
  const toggleResetTime = useCallback(() => setResetTime((_) => !_), []);

  const [rawDb] = useState(() => new TextInputState());
  const root = useRoot();
  const submit = useCallback(async () => {
    await root.databaseImporter.import(rawDb.value ?? '{}', {resetTime});
    Alert.alert(
      'Success',
      'The database has been imported',
      [{style: 'default', text: 'OK'}],
      {cancelable: true},
    );
  }, [root, rawDb.value, resetTime]);

  const styles = useStyles();
  return (
    <View style={styles.root}>
      <DesktopWebAppHeader />
      <TextInput style={styles.input} multiline {...rawDb.handlers} />
      <View style={styles.row}>
        <View style={styles.gutter}>
          <Button
            title={resetTime ? 'RESET TIME' : 'KEEP TIME'}
            onPress={toggleResetTime}
          />
        </View>
        <View style={styles.gutter}>
          <Button title="IMPORT" color="green" onPress={submit} />
        </View>
      </View>
    </View>
  );
});

const useStyles = createStylesHook(() => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
  },
  input: {
    flexGrow: 1,
    flexShrink: 1,
    padding: 8,
    margin: 0,
    textAlign: 'left',
    textAlignVertical: 'top',
  },
  row: {
    flexDirection: 'row',
  },
  gutter: {
    flex: 1,
    margin: 16,
  },
}));
