import React, {useCallback} from 'react';
import {View, Text, Platform} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStrings, useTemplates} from '../../../Root/hooks';
import {createStylesHook, useTheme} from '../../../styling';
import {Slider} from '@miblanchard/react-native-slider';
import {PressableOpacity} from '../../../components';
import {sized} from '../../../Svg';
import ChevronDownSvg from '../../../assets/svg/colorless/chevronDown.svg';
import {Formatter} from '../../../Formatter';
import {expr} from 'mobx-utils';
import {PurchaseScreenState} from '../../states/PurchaseScreenState';
import SpeedSvg from '../../../assets/svg/colored/speed.svg';
import OfferSvg from '../../../assets/svg/colorless/magicSquare.svg';
import GiftPlank from '../../shared/components/GiftPlank';
import {useIsDesktop} from '../../../DashboardScreen/hooks/useGetItemLayout';

export type HashrateSectionProps = {
  disableScroll: () => void;
  enableScroll: () => void;
  onSelectPoolMinerPress: () => void;
  state: PurchaseScreenState;
};

export const PickHashrateSection = observer(
  ({
    disableScroll,
    enableScroll,
    state,
    onSelectPoolMinerPress,
  }: HashrateSectionProps) => {
    const strings = useStrings();
    const templates = useTemplates();
    const styles = useStyles();
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const {minerConfigs, selectedHashrate} = state;
    const handleChangeSlider = useCallback(
      (sliderValue: number[] | number) => {
        const index = Array.isArray(sliderValue) ? sliderValue[0] : sliderValue;
        const candidate = minerConfigs[index];
        if (candidate) {
          state.selectHashrate(candidate.hashrate);
        }
      },
      [minerConfigs, state],
    );
    const index = expr(() =>
      minerConfigs.findIndex((_) => _.hashrate === selectedHashrate),
    );
    const maximumValue = expr(() =>
      minerConfigs.length - 1 > 0 ? minerConfigs.length - 1 : undefined,
    );
    return (
      <View style={styles.hashrate}>
        <View style={styles.largeRow}>
          <View style={styles.largeRowFirst}>
            <Text style={styles.label}>
              {strings['purchase.poolMiners.hashrate']}:
            </Text>
            <View style={styles.sliderContainer}>
              <View style={styles.hashrateSpeed}>
                <SpeedIcon />
                {selectedHashrate && (
                  <Text style={styles.hashrateValue}>
                    {Formatter.numberWithSpaces(selectedHashrate)} H/s
                  </Text>
                )}
              </View>
              {maximumValue ? (
                <View style={styles.slider}>
                  <Slider
                    maximumValue={maximumValue}
                    minimumValue={0}
                    step={1}
                    value={index}
                    onValueChange={(value) => handleChangeSlider(value)}
                    onSlidingStart={disableScroll}
                    onSlidingComplete={enableScroll}
                    thumbTintColor={theme.palette.primary}
                    minimumTrackTintColor={theme.palette.primary}
                    trackStyle={styles.track}
                    thumbStyle={styles.thumb}
                    thumbTouchSize={{
                      width: 45,
                      height: 40,
                    }}
                  />
                </View>
              ) : null}
            </View>
          </View>
          <View style={styles.largeRowSecond}>
            <Text style={styles.label}>
              {strings['selectConfiguration.label']}
            </Text>
            <PressableOpacity
              onPress={onSelectPoolMinerPress}
              style={styles.dropdownMenu}>
              {minerConfigs[index] ? (
                <Text numberOfLines={1} style={styles.dropdownSelectedValue}>
                  {minerConfigs[index].name} @{minerConfigs[index].frequency}
                </Text>
              ) : null}
              <ChevronDownIcon style={styles.icon} color={theme.palette.icon} />
            </PressableOpacity>
          </View>
        </View>
        <View style={styles.largeRow}>
          {isDesktop && (
            <View style={styles.largeRowFirst}>
              <Text style={styles.label}>{strings['special.label']}</Text>
              <View style={styles.special}>
                <OfferIcon color={theme.palette.purple} />
                <Text style={styles.specialText}>
                  {templates.giftBanner({duration: 12})}
                </Text>
              </View>
            </View>
          )}
          <GiftPlank />
        </View>
      </View>
    );
  },
);
const ChevronDownIcon = sized(ChevronDownSvg, 10, 6);
const SpeedIcon = sized(SpeedSvg, 20, 20);
const OfferIcon = sized(OfferSvg, 20, 20);
const useStyles = createStylesHook((theme) => ({
  track: {height: 6, borderRadius: 4, backgroundColor: theme.palette.border},
  thumb: {height: 24, width: 24, borderRadius: 12},
  special: {
    padding: 15,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.additionalPurple,
    borderWidth: 1,
    borderColor: theme.palette.purple,
    borderRadius: 10,
  },
  specialText: {
    ...theme.fontByWeight('600'),
    color: theme.palette.textPrimary,
    fontSize: 15,
    marginLeft: 8,
  },
  hashrate: {
    padding: 15,
    backgroundColor: theme.palette.back,
    borderColor: theme.palette.border,
    borderStyle: 'dashed',
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  largeRow: {
    ...theme.mediaQuery({
      1000: {
        flexDirection: 'row',
      },
    }),
  },
  label: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 12,
    marginBottom: 6,
    textTransform: 'uppercase',
  },
  sliderContainer: {
    backgroundColor: theme.palette.background,
    borderWidth: 1,
    borderColor: theme.palette.border,
    marginBottom: 15,
    borderRadius: 6,
    ...theme.mediaQuery({
      1000: {
        flexDirection: 'row',
        marginBottom: 0,
      },
    }),
  },
  largeRowFirst: {
    ...theme.mediaQuery({
      1000: {
        paddingRight: 20,
        flex: 8,
      },
    }),
  },
  largeRowSecond: {
    ...theme.mediaQuery({
      1000: {
        flex: 4,
      },
    }),
  },
  hashrateHeader: {},
  hashrateTitle: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 16,
    lineHeight: 20,
  },
  hashrateDesc: {
    ...theme.fontByWeight('400'),
    color: theme.chroma(theme.palette.textPrimary).alpha(0.7).hex(),
    fontSize: 14,
    lineHeight: 20,
    marginBottom: 20,
  },
  hashrateSpeed: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 14,
    borderBottomWidth: 1,
    borderColor: theme.palette.border,
    ...theme.mediaQuery({
      1000: {
        flexDirection: 'row',
        paddingVertical: 10,
        paddingHorizontal: 15,
        justifyContent: 'flex-start',
        borderBottomWidth: 0,
        borderRightWidth: 1,
      },
    }),
  },
  hashrateValue: {
    ...theme.fontByWeight('700'),
    fontSize: 26,
    lineHeight: 30,
    letterSpacing: 0.042,
    marginLeft: 10,
    color: theme.palette.textPrimary,
  },
  slider: {
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
    paddingHorizontal: 14,
    paddingVertical: 10,
    ...theme.mediaQuery({
      1000: {
        paddingVertical: 5,
        flex: 1,
      },
    }),
  },
  dropdownMenu: {
    borderWidth: 1,
    borderColor: theme.palette.border,
    paddingHorizontal: 15,
    borderRadius: 6,
    marginBottom: 15,
    height: 50,
    backgroundColor: theme.palette.background,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dropdownSelectedValue: {
    ...theme.fontByWeight('600'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 15,
  },
  icon: {
    width: 12,
    height: 12,
  },
}));
