import {Opaque, ReadonlyDeep} from 'type-fest';
import {AdvertId} from '../ApiStore';

export interface DeviceIdentification {
  state: ReadonlyDeep<DeviceIdentificationState>;
  fetch(): Promise<Registered>;
  purge(): Promise<void>;
}

export const DEVICE_ID = Symbol();
export type DeviceId = Opaque<string, typeof DEVICE_ID>;

export type DeviceIdentificationState =
  | Registering
  | Registered
  | RegistrationError;

export enum DeviceIdentificationStateKind {
  Registering,
  Registered,
  RegistrationError,
}

export interface Registering {
  kind: DeviceIdentificationStateKind.Registering;
}

export interface Registered {
  kind: DeviceIdentificationStateKind.Registered;
  result: RegisterDeviceResult;
}

export interface RegistrationError {
  kind: DeviceIdentificationStateKind.RegistrationError;
  error: RegisterDeviceError;
}

export interface RegisterDeviceResult {
  deviceId: DeviceId;
  advertId?: AdvertId;
}

export interface RegisterDeviceError {
  reason: RegisterDeviceErrorReason;
  raw: unknown;
}

export enum RegisterDeviceErrorReason {
  NoInternet,
  Timeout,
  ServiceUnavailable,
  BadResponse,
  LocalStorage,
}
