import React, {useEffect} from 'react';

import {Keyboard} from 'react-native';
import {ButtonColor, ButtonVariant} from '../components';
import {useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import WarningIcon from '../assets/svg/circled/warning.svg';
import ActionModal from '../modals/ActionModal';

export type TryAgainLaterModalProps = {
  description: string | undefined;
  onClose: () => void;
};

export default observer(({description, onClose}: TryAgainLaterModalProps) => {
  const strings = useStrings();
  useEffect(() => {
    Keyboard.dismiss();
  }, []);
  return (
    <ActionModal
      buttons={[
        {
          text: strings['action.ok'],
          onPress: onClose,
          color: ButtonColor.Default,
          variant: ButtonVariant.Contained,
        },
      ]}
      title={strings['modal.tryagain.title']}
      Icon={WarningIcon}
      onDismiss={onClose}>
      {description}
    </ActionModal>
  );
});
