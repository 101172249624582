import React, {useMemo} from 'react';
import {StyleProp, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useRoot, useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {AuthStatus} from '../Auth';
import {expr} from 'mobx-utils';
import {ApiMode} from '../farmApi';
import {useTheme} from '../styling';

interface BadgeProps {
  text: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

export const Badge = observer((props: BadgeProps) => {
  const {text, style, textStyle} = props;
  const theme = useTheme();

  const styles = useMemo(
    () =>
      ({
        container: {
          paddingVertical: 5,
          paddingHorizontal: 10,
          borderRadius: 6,
          backgroundColor: theme.palette.error,
          width: 'auto',
        },
        text: {
          ...theme.fontByWeight('700'),
          fontSize: 12,
          color: '#ffffff',
          textTransform: 'uppercase',
        },
      } as const),
    [theme],
  );

  return (
    <View style={[styles.container, style]}>
      <Text style={[styles.text, textStyle]} numberOfLines={1}>
        {text}
      </Text>
    </View>
  );
});

export type ApiModeBadgeProps = Omit<BadgeProps, 'text'> & {
  hideInReal?: boolean;
};

export const ApiModeBadge = observer((props: ApiModeBadgeProps) => {
  const {hideInReal, ...rest} = props;
  const {apiStore, auth} = useRoot();
  const strings = useStrings();
  const isReal = expr(
    () => apiStore.mode === ApiMode.Real || auth.status !== AuthStatus.Idle,
  );
  if (hideInReal && isReal) {
    return null;
  }
  let mode = isReal ? strings['mode.real'] : strings['mode.demo'];
  return <Badge {...rest} text={mode} />;
});
