import {WorkerStateMeta, WorkerStateRegistry} from './WorkerStateRegistry';
import {
  action,
  flow,
  observable,
  reaction,
  runInAction,
  makeObservable,
} from 'mobx';
import {bind, success} from '../fp';
import {now} from 'mobx-utils';
import {Millisecond, Second} from '../Time';
import {ApiStore, FarmState} from '../ApiStore';
import {FloatingAverage} from '../Filter';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import {WORKER_UPDATE_PERIOD, define} from '../persistence';
import fromSecond from '../Time/fromSecond';
import {batchDisposers, Service} from '../structure';

export default class WorkerStateRegistryService
  implements WorkerStateRegistry, Service
{
  public static readonly AVERAGE_HASHRATE_PERIOD = fromSecond(3 as Second);
  public static readonly DEFAULT_UPDATE_INTERVAL = 0 as Millisecond;
  public static readonly MINIMAL_UPDATE_INTERVAL = 250 as Millisecond;

  private readonly _states = observable.map<string, WorkerStateMeta>();
  private _lastBatch?: FarmState[];
  @observable private _period =
    WorkerStateRegistryService.DEFAULT_UPDATE_INTERVAL;

  constructor(
    private readonly _root: {
      readonly dashboardStore: DashboardStore;
      readonly apiStore: ApiStore;
    },
  ) {
    makeObservable(this);
  }

  get states() {
    return this._states;
  }

  get period() {
    return this._period;
  }

  setPeriod = bind(
    flow(function* (this: WorkerStateRegistryService, period: Millisecond) {
      yield setPeriod({period});
      this._period = period;
    }),
    this,
  );

  private async _load() {
    const getPeriod_ = await getPeriod();
    if (getPeriod_.success && getPeriod_.right !== null) {
      const {period} = getPeriod_.right;
      runInAction(() => {
        this._period = period;
      });
    }
  }

  private _receiveUpdates() {
    return this._root.apiStore.server.call(
      'farm_states',
      (params, response) => {
        this._lastBatch = params.states;
        return response.respond(success(null));
      },
    );
  }

  private _processUpdates() {
    return reaction(
      () =>
        now(this._period || WorkerStateRegistryService.MINIMAL_UPDATE_INTERVAL),
      action(() => {
        if (!this._lastBatch) {
          return;
        }
        const keysToDelete = new Set(this._states.keys());
        for (const update of this._lastBatch) {
          keysToDelete.delete(update.worker_id);
          const id = update.worker_id;
          const meta = this._states.get(id);
          if (meta === undefined) {
            this._states.set(id, {
              state: update,
              miningSpeedAverage: new FloatingAverage(
                WorkerStateRegistryService.AVERAGE_HASHRATE_PERIOD,
                update.mining_speed,
              ),
            });
          } else {
            const {state, miningSpeedAverage} = meta;
            const {cpu_states} = state;
            cpu_states.length = update.cpu_states.length;
            for (let i = 0; i < cpu_states.length; i++) {
              cpu_states[i] = cpu_states[i] ?? {};
              cpu_states[i].hot = update.cpu_states[i].hot;
              cpu_states[i].temperature = update.cpu_states[i].temperature;
            }
            state.mining_speed = update.mining_speed;
            state.max_huge_pages = update.max_huge_pages;
            state.current_settings.speed = update.current_settings.speed;
            state.current_huge_pages = update.current_huge_pages;
            const speed = update.mining_speed;
            if (speed > 0 && miningSpeedAverage.current === 0) {
              miningSpeedAverage.reset(speed);
            } else {
              miningSpeedAverage.update(speed);
            }
          }
        }
        for (const key of keysToDelete) {
          this._states.delete(key);
        }
        this._lastBatch = undefined;
      }),
    );
  }

  subscribe() {
    // noinspection JSIgnoredPromiseFromCall
    this._load();
    return batchDisposers(this._receiveUpdates(), this._processUpdates());
  }

  @action reset() {
    this._states.clear();
    this._lastBatch = undefined;
  }
}

interface WorkerUpdatePeriodRecord {
  period: Millisecond;
}

const [getPeriod, setPeriod] =
  define<WorkerUpdatePeriodRecord>(WORKER_UPDATE_PERIOD);
