import {Platform} from 'react-native';
import StubPurchaseDiscountService from './StubPurchaseDiscountService';
import PurchaseDiscountService from './PurchaseDiscountService';
import {PurchaseDiscount} from './PurchaseDiscount';
import {Auth} from '../Auth';
import {ConnectedClient} from '../ContextClient';
import {LocationSource} from '../Location';
import {SpecialLocation} from '../SpecialLocation';
import {InstallIdentification} from '../InstallIdentification';
import {Service} from '../structure';

export default class PurchaseDiscountFactory {
  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly connectedClient: ConnectedClient;
      readonly locationSource: LocationSource;
      readonly specialLocation: SpecialLocation;
      readonly installIdentification: InstallIdentification;
    },
  ) {}

  create() {
    return Platform.select<PurchaseDiscount & Service>({
      ios: new StubPurchaseDiscountService(),
      default: new PurchaseDiscountService(this._root),
    });
  }
}
