import {Millisecond} from '../utils/time';
import {ReadonlyDeep} from 'type-fest';
import {Scheduler, WorkerSettings} from './DemonstrationDatabase';
import getWorkerScheduler from './getWorkerScheduler';

export default (
  moment: Millisecond,
  schedulers: ReadonlyDeep<Scheduler[]>,
  settings: ReadonlyDeep<WorkerSettings>,
  shouldIgnoreScheduleUntil?: Millisecond,
) =>
  shouldIgnoreScheduleUntil !== undefined && moment < shouldIgnoreScheduleUntil
    ? settings
    : getWorkerScheduler(moment, schedulers)?.settings ?? settings;
