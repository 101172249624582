import {
  AndroidChannelDescription,
  AndroidChannelId,
  AndroidChannelRepository,
} from './AndroidChannelRepository';
import {compact} from 'lodash';
import notifee, {AndroidImportance, AndroidVisibility} from '../Notifee';

export default class AndroidChannelRepositoryImpl
  implements AndroidChannelRepository
{
  static readonly ANDROID_STATIC_CHANNEL_PREFIX = 'simple-static-v1';

  getId(d: AndroidChannelDescription): AndroidChannelId {
    return compact([
      AndroidChannelRepositoryImpl.ANDROID_STATIC_CHANNEL_PREFIX,
      d.lights && 'lights',
      d.vibration && 'vibration',
      d.sound && 'sound',
    ]).join('-') as AndroidChannelId;
  }

  getName(d: AndroidChannelDescription): string {
    if (!d.sound && !d.lights && !d.vibration) {
      return 'Silent notifications';
    }
    const list = compact([
      d.lights && 'lights',
      d.vibration && 'vibration',
      d.sound && 'sound',
    ]).join(' & ');
    return `Notifications with ${list}`;
  }

  *getAllDescriptions(): Generator<AndroidChannelDescription> {
    yield {lights: true, vibration: true, sound: true};
    yield {lights: true, vibration: true, sound: false};
    yield {lights: true, vibration: false, sound: true};
    yield {lights: true, vibration: false, sound: false};
    yield {lights: false, vibration: true, sound: true};
    yield {lights: false, vibration: true, sound: false};
    yield {lights: false, vibration: false, sound: true};
    yield {lights: false, vibration: false, sound: false};
  }

  async createChannels(): Promise<void> {
    await this.deleteObsoleteChannels();
    await notifee.createChannels(
      [...this.getAllDescriptions()].map((_) => ({
        id: this.getId(_),
        name: this.getName(_),
        lights: _.lights,
        vibration: _.vibration,
        ...(_.sound ? {sound: 'default'} : {}),
        importance: AndroidImportance.HIGH,
        badge: true,
        visibility: AndroidVisibility.PUBLIC,
      })),
    );
  }

  async deleteObsoleteChannels() {
    await notifee.deleteChannel('main-local');
  }
}
