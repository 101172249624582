import {computed, makeObservable} from 'mobx';
import {Locale, Preferences} from '../Preferences';
import de from '../translation/de.json';
import en from '../translation/en.json';
import es from '../translation/es.json';
import fr from '../translation/fr.json';
import it from '../translation/it.json';
import pt from '../translation/pt.json';
import ru from '../translation/ru.json';
import {TemplateExecutor, Translation} from './Translation';
import Localization from './Localization';
import {Configuration} from '../Configuration';
import {LocaleDict, LocaleKeys} from '../translation/LocaleStrings';

export default class TranslationService implements Translation {
  constructor(
    private readonly _root: {
      readonly preferences: Preferences;
      readonly localization: Localization;
      readonly configuration: Configuration;
    },
  ) {
    makeObservable(this);
  }

  @computed get locale(): Locale {
    const {locale: preferredLocale} = this._root.preferences;
    const {locale: systemLocale} = this._root.localization.state ?? {};
    return (
      preferredLocale ??
      (systemLocale ? translateLocale(systemLocale) : Locale.English)
    );
  }

  @computed get localeTag(): LocaleTag {
    switch (this.locale) {
      case Locale.PortugueseBrazil:
        return Locale.Portuguese;
      default:
        return this.locale;
    }
  }

  @computed get strings(): LocaleDict {
    const enStrings: LocaleDict = en;
    const localeEntries = Object.entries(enStrings).map(
      ([key, defaultString]) => {
        const localeKey = key as LocaleKeys;
        const preferredString = this._userLocaleStrings[localeKey];
        const string =
          preferredString === '' || preferredString === undefined
            ? defaultString
            : preferredString;
        return [localeKey, this._replaceGlobalString(string)];
      },
    );
    return Object.fromEntries(localeEntries);
  }

  @computed private get _userLocaleStrings(): Partial<LocaleDict> {
    switch (this.locale) {
      case Locale.English:
        return en;
      case Locale.French:
        return fr;
      case Locale.German:
        return de;
      case Locale.Italian:
        return it;
      case Locale.PortugueseBrazil:
        return pt;
      case Locale.Russian:
        return ru;
      case Locale.Spanish:
        return es;
      default:
        return en;
    }
  }

  get GLOBAL_REPLACE_VALUES() {
    return {
      '{appName}': this._root.configuration.values.productName,
    };
  }

  private _replaceGlobalString(str: string) {
    if (!str.includes('{')) {
      return str;
    }
    let newStr = str;
    Object.entries(this.GLOBAL_REPLACE_VALUES).forEach(([key, value]) => {
      newStr = newStr.split(key).join(value); //replaceAll
    });
    return newStr;
  }

  @computed({keepAlive: true}) get templates() {
    return Object.fromEntries(
      Object.entries(this.strings).map(([key, string]) => [
        key,
        (substitution) => {
          return string.replaceAll(
            /{(.+?)}/g,
            (match: string, subKey: string) =>
              String(substitution[subKey] ?? ''),
          );
        },
      ]),
    ) as Record<keyof LocaleDict, TemplateExecutor>;
  }
}

export const translateLocale = (locale: string) => {
  switch (locale) {
    case 'pt-BR':
      return Locale.PortugueseBrazil;
  }
  const _locale = locale.slice(0, 2);
  switch (_locale) {
    case 'en':
      return Locale.English;
    case 'fr':
      return Locale.French;
    case 'de':
      return Locale.German;
    case 'it':
      return Locale.Italian;
    case 'pt':
      return Locale.Portuguese;
    case 'ru':
      return Locale.Russian;
    case 'es':
      return Locale.Spanish;
    default:
      return Locale.English;
  }
};

export type LocaleTag = 'de' | 'en' | 'es' | 'fr' | 'it' | 'pt' | 'ru';
