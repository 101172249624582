import {Satoshi} from '../units';

export interface WithdrawalEntity {
  id: number;
  account_id: number;
  amount: Satoshi;
  status: WithdrawalStatus;
  created_at: string;
}

export enum WithdrawalStatus {
  Waiting = 'WAITING',
  Failed = 'FAILED',
  Processed = 'PROCESSED',
}
