import React, {useCallback} from 'react';
import {useRoot, useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {Platform, Text, View} from 'react-native';
import {PageScrollView} from '../containers';
import {UnitSlider} from '../components';
import {createStylesHook, useTheme} from '../styling';
import Header from './Header';
import Switch from '../components/Switch';

import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';
import {fromSecond, Second, toSecond} from '../Time';
import {Mode} from '../DashboardMode';
import {DESKTOP_BREAKPOINT} from '../units/constants';

export default observer(() => {
  const strings = useStrings();
  const {dashboardModeService, workerStateRegistry} = useRoot();
  const theme = useTheme();
  const styles = useStyles();
  const getPeriod = useCallback(
    () => Math.floor(toSecond(workerStateRegistry.period)),
    [workerStateRegistry],
  );
  const onSlidingComplete = useCallback(
    (period: number) =>
      workerStateRegistry.setPeriod(fromSecond(period as Second)),
    [workerStateRegistry],
  );
  const onModeChange = useCallback(
    (isSimple: boolean) => {
      dashboardModeService.changeMode(isSimple ? Mode.Simple : Mode.Regular);
    },
    [dashboardModeService],
  );
  return (
    <>
      <DesktopWebAppHeader />
      <PageScrollView
        style={styles.root}
        contentContainerStyle={styles.container}>
        <Header />
        <View style={styles.content}>
          <View style={styles.item}>
            <View style={styles.itemHeader}>
              <View style={styles.itemHeaderLeft}>
                <Text style={styles.itemLabel}>
                  {strings['dashboardSettings.rate.title']}
                </Text>
              </View>
            </View>
            <Text style={styles.itemDescription}>
              {strings['dashboardSettings.rate.description']}
            </Text>
            <UnitSlider
              unit={strings['common.secShort']}
              minimumValue={0}
              maximumValue={10}
              step={1}
              getValue={getPeriod}
              onSlidingComplete={onSlidingComplete}
            />
          </View>
          <View style={styles.itemSeparator} />
          <View style={styles.item}>
            <View style={styles.itemHeader}>
              <View style={styles.itemHeaderLeft}>
                <Text style={styles.itemLabel}>
                  {strings['dashboardSettings.simpleMode.title']}
                </Text>
              </View>
              <View style={styles.itemHeaderRight}>
                <Switch
                  value={dashboardModeService.mode === Mode.Simple}
                  onValueChange={onModeChange}
                  backgroundActive={theme.palette.success}
                />
              </View>
            </View>
            <Text style={styles.itemDescription}>
              {strings['dashboardSettings.simpleMode.description']}
            </Text>
          </View>
        </View>
      </PageScrollView>
    </>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {},
  container: {
    ...Platform.select({
      web: {
        ...theme.mediaQuery({
          1200: {
            paddingBottom: 230,
          },
        }),
      },
    }),
  },
  content: {
    backgroundColor: theme.palette.background,
    borderBottomWidth: 1,
    borderColor: theme.palette.border,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        borderRadius: 15,
        borderWidth: 1,
      },
    }),
  },
  item: {
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  itemSeparator: {
    height: 1,
    backgroundColor: theme.palette.border,
  },
  itemHeaderLeft: {
    flex: 1,
  },
  itemHeaderRight: {},
  itemHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  itemLabel: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    lineHeight: 26,
    color: theme.palette.textPrimary,
  },
  itemDescription: {
    ...theme.fontByWeight(),
    fontSize: 14,
    lineHeight: 22,
    letterSpacing: 0.04,
    marginBottom: 6,
    color: theme.palette.textSecondary,
  },
}));
