import {AdvertisingId, AdvertisingIdentifier} from './AdvertisingIdentifier';
import {GlobalError} from '../Error';
import {Either, success} from '../fp';

export default class AdvertisingIdentifierSubImpl
  implements AdvertisingIdentifier
{
  async getInfo(): Promise<Either<AdvertisingId | null, GlobalError>> {
    return success(null);
  }
}
