import React, {useMemo} from 'react';
import {MainListItem} from './extra/MainListItem';
import {InsideListItem} from './extra/InsideListItem';
import {List} from './extra/List';
import {observer} from 'mobx-react-lite';
import {CommonSectionProps} from './NotificationsSettingsScreen';
import {FarmLogVariant} from '../Notifications';
import {useStrings} from '../Root/hooks';

export default observer(({state, onCheck}: CommonSectionProps) => {
  const strings = useStrings();
  const styles = useMemo(
    () =>
      ({
        root: {
          marginBottom: 20,
        },
      } as const),
    [],
  );
  return (
    <List style={styles.root}>
      <MainListItem
        value={state[FarmLogVariant.Withdrawal].value}
        onValueChange={(value) => onCheck(FarmLogVariant.Withdrawal, value)}
        title={strings['notificationsSettings.withdrawal']}
      />
      <InsideListItem
        value={state[FarmLogVariant.WithdrawalProcessed].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.WithdrawalProcessed, value)
        }
        title={strings['notificationsSettings.withdrawalProcessed']}
      />
      <InsideListItem
        value={state[FarmLogVariant.WithdrawalFailed].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.WithdrawalFailed, value)
        }
        title={strings['notificationsSettings.withdrawalFailed']}
        bordered={false}
      />
    </List>
  );
});
