import {Millisecond} from '../utils/time';
import {action, observable, runInAction, makeObservable} from 'mobx';
import {Filter} from './Filter';

export default class FloatingAverage implements Filter {
  private _lastUpdateAt: Millisecond = new Date().getTime();

  @observable private _current!: number;

  constructor(private readonly _period: Millisecond, current: number) {
    makeObservable(this);
    runInAction(() => {
      this._current = current;
    });
  }

  get current() {
    return this._current;
  }

  @action update(next: number) {
    const now = new Date().getTime();
    const elapsed = now - this._lastUpdateAt;
    this._current =
      elapsed >= this._period
        ? next
        : this._current * ((this._period - elapsed) / this._period) +
          next * (elapsed / this._period);
    this._lastUpdateAt = now;
  }

  @action reset(next: number) {
    this._current = next;
    this._lastUpdateAt = new Date().getTime();
  }
}
