import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, Text} from 'react-native';
import {useTheme, variance} from '../../../styling';
import {useRoot, useStrings} from '../../../Root/hooks';
import {sized} from '../../../Svg';
import GiftSvg from '../../../assets/svg/colorless/gift.svg';
import BaseSkeleton from '../../../components/BaseSkeleton';
import useGiftHelper from '../../hooks/useGiftHelper';

export default observer(function GiftPlank() {
  const {gift} = useRoot();
  const theme = useTheme();
  const strings = useStrings();
  const {name, type, isDouble} = useGiftHelper();
  if (!gift.gift) {
    return (
      <Root>
        <Skeleton color={theme.palette.background} />
      </Root>
    );
  }
  if (!name) {
    return <Root />;
  }
  return (
    <Root>
      <View>
        <Label>{strings['gift.label']}</Label>
        <GiftContainer secondary={isDouble}>
          <GiftIcon
            color={
              isDouble ? theme.palette.purple : theme.palette.staticLightBlue
            }
          />
          <GiftText
            secondaryPurple={isDouble}
            numberOfLines={1}
            ellipsizeMode="tail">
            {name}
          </GiftText>
          <GiftText secondary secondaryPurple={isDouble}>
            {type}
          </GiftText>
        </GiftContainer>
      </View>
    </Root>
  );
});

const GiftIcon = sized(GiftSvg, 20, 20);

const Skeleton = variance(BaseSkeleton)(() => ({
  root: {
    height: 72,
  },
}));

const Label = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 12,
    marginBottom: 6,
    textTransform: 'uppercase',
  },
}));

const Root = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      1000: {
        flex: 4,
      },
    }),
  },
}));

const GiftContainer = variance(View)((theme) => ({
  root: {
    padding: 15,
    borderWidth: 1,
    borderRadius: 6,
    borderColor: theme.palette.staticLightBlue,
    flexDirection: 'row',
    alignItems: 'center',
  },
  secondary: {
    borderColor: theme.palette.purple,
    backgroundColor: theme.palette.additionalPurple,
  },
}));

const GiftText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('600'),
    color: theme.palette.textPrimary,
    flex: 1,
    fontSize: 15,
    marginLeft: 10,
    marginRight: 8,
  },
  secondary: {
    marginLeft: 'auto',
    flex: undefined,
    marginRight: 0,
    maxWidth: 19,
    color: theme.palette.staticLightBlue,
  },
  secondaryPurple: {
    color: theme.palette.purple,
  },
}));
