import React, {useCallback} from 'react';
import ActionModal from '../../modals/ActionModal';
import TrashSvg from '../../assets/svg/circled/trash.svg';
import {useRoot, useStrings} from '../../Root/hooks';
import {WorkerGroupEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';
import {ReadonlyDeep} from 'type-fest';
import {observer} from 'mobx-react-lite';
import {ButtonColor, ButtonVariant} from '../../components';
import {useHandleErrorAndNavigate} from '../../ExceptionHandler';

interface RemoveWorkerGroupModal {
  group: ReadonlyDeep<WorkerGroupEntity>;
  onDismiss: () => void;
}

export default observer(({group, onDismiss}: RemoveWorkerGroupModal) => {
  const {connectedClient} = useRoot();
  const strings = useStrings();
  const message = strings['modal.removeWorkerGroup.description'].replace(
    '{name}',
    group.name,
  );
  const handleErrorAndNavigate = useHandleErrorAndNavigate();
  const onSubmit = useCallback(async () => {
    const result = await connectedClient.call('delete_group', {
      group_id: group.id,
    });
    if (!result.success) {
      handleErrorAndNavigate(result.left);
    }
    onDismiss();
  }, [connectedClient, handleErrorAndNavigate, group.id, onDismiss]);
  return (
    <ActionModal
      onDismiss={onDismiss}
      buttons={[
        {
          text: strings['action.cancel'],
          onPress: onDismiss,
          color: ButtonColor.Default,
          variant: ButtonVariant.Contained,
        },
        {
          text: strings['action.confirm'],
          onPress: onSubmit,
          variant: ButtonVariant.Highlighted,
        },
      ]}
      Icon={TrashSvg}
      title={strings['modal.removeWorkerGroup.title']}>
      {message}
    </ActionModal>
  );
});
