import {observable, makeObservable} from 'mobx';
import {define, INSTALL_IDENTIFICATION} from '../persistence';
import {bind} from '../fp';
import {InstallIdentification} from './InstallIdentification';
import {LocationSource} from '../Location';
import {AppLifecycle} from '../AppLifecycle';
import {RefSearcher} from './RefSearcher';
import BatchRefSearcherImpl from './BatchRefSearcherImpl';

export default class InstallIdentificationImpl
  implements InstallIdentification
{
  @observable private _initialized = false;
  private _record?: InstallIdentificationRecord;
  private _searcher: RefSearcher;

  constructor(
    private readonly _root: {
      readonly locationSource: LocationSource;
      readonly appLifecycle: AppLifecycle;
    },
  ) {
    makeObservable(this);
    this._searcher = new BatchRefSearcherImpl(_root);
  }

  getRef = bind(async () => {
    await this._find();
    return this._record?.installReferrer;
  }, this);

  private async _find() {
    const identification = await getIdentification();
    if (identification.success && identification.right !== null) {
      this._record = identification.right;
      return;
    }
    if (!this._root.appLifecycle.hasJustBeenInstalled) {
      return;
    }
    const installReferrer = await this._searcher.find();
    if (installReferrer.success) {
      const newIdentification = {
        installReferrer: installReferrer.right,
      };
      await setIdentification(newIdentification);
      this._record = newIdentification;
    }
  }
}

type InstallIdentificationRecord = {
  installReferrer?: string;
};

const [getIdentification, setIdentification] =
  define<InstallIdentificationRecord>(INSTALL_IDENTIFICATION);
