import {BusSource, RouterSource} from '../structure';
import {SUCCESS, FAIL} from './WatchTarget';
import {Millisecond} from '../Time';
import {Bound} from '../fp';

export const RECONNECT = Symbol();

export interface ConnectionManager {
  readonly connectionId: number | undefined;
  readonly connectionIdUpdates: BusSource<number>;
  readonly isConnected: boolean;
  try: Bound<() => void, ConnectionManager>;
  connect(): Promise<WebSocket>;
  readonly sockets: RouterSource<{
    [RECONNECT]: WebSocket;
    [SUCCESS]: void;
    [FAIL]: void;
  }>;
  fireCannon(duration: Millisecond): Promise<void>;
  disconnect(): void;
}
