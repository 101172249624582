import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Text, View} from 'react-native';
import {useStyles} from '../../styling';
import {PressableOpacity} from '../../components';
import {PurchaseTabsKind} from '../states/PurchaseScreenState';
import {useRoot, useStrings, useTemplates} from '../../Root/hooks';
import {Proposal} from '../../ProposalsState';
import {SlotSubscriptionOffer} from '../../InAppOffersService';
import getDaysByUnitAndValue from '../../InAppPurchaseManager/getDaysByUnitAndValue';
import dayjs from 'dayjs';
import {Millisecond} from '../../Time';
import getHumanizeDuration from '../getters/getHumanizeDuration';

type Route = {
  key: string;
  title: string;
};

export type TabBarProps = {
  routes: Route[];
  selectedRoute: number;
  onSelectTab(_: number): void;
  slot: Proposal<SlotSubscriptionOffer> | undefined;
};

export default observer((props: TabBarProps) => {
  const {routes, selectedRoute, slot, onSelectTab} = props;
  const styles = useStyles((theme) => ({
    root: {
      padding: 15,
      ...theme.mediaQuery({
        1000: {
          paddingTop: 40,
          paddingBottom: 20,
        },
      }),
    },
    tabBar: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      overflow: 'hidden',
      marginBottom: 15,
      ...theme.mediaQuery({
        1000: {
          maxWidth: 345,
          width: '100%',
          marginRight: 'auto',
          marginLeft: 'auto',
          marginBottom: 40,
        },
      }),
    },
    tab: {
      flexGrow: 1,
      flex: 1,
      alignItems: 'center',
      padding: 10,
      borderWidth: 1,
      borderColor: theme.palette.border,
      borderRadius: 10,
      backgroundColor: theme.palette.background,
    },
    focusedTab: {
      backgroundColor: theme.palette.secondary,
      borderWidth: 1,
      borderColor: theme.palette.secondary,
    },
    tabLeft: {
      borderRightWidth: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    tabRight: {
      borderLeftWidth: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    description: {
      ...theme.fontByWeight('400'),
      fontSize: 14,
      textAlign: 'center',
      lineHeight: 24,
      color: theme.palette.textSecondary,
      ...theme.mediaQuery({
        1000: {
          fontSize: 18,
          lineHeight: 28,
          maxWidth: 900,
          marginRight: 'auto',
          marginLeft: 'auto',
        },
      }),
    },
    label: {
      ...theme.fontByWeight('700'),
      color: theme.palette.textPrimary,
      fontSize: 13,
      lineHeight: 15,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    focusedLabel: {
      color: '#fff',
    },
  }));
  const {translation} = useRoot();
  const strings = useStrings();
  const templates = useTemplates();
  const slotDescription = useMemo(() => {
    const offer = slot?.offer;
    if (!offer) {
      return null;
    }
    const trial = offer.trial;
    const period = offer?.interval
      ? getHumanizeDuration(
          dayjs
            .duration(offer.interval, 'month')
            .asMilliseconds() as Millisecond,
          translation.localeTag,
        )
      : undefined;
    if (!trial) {
      return strings['slotsTab.description'];
    }
    const days = getDaysByUnitAndValue(trial.value, trial.unit);
    const dayjsDuration = dayjs
      .duration(days, 'day')
      .asMilliseconds() as Millisecond;
    const humanized = getHumanizeDuration(
      dayjsDuration,
      translation.localeTag,
      ['d'],
    );
    return templates['slotsTab.descriptionTrial']({
      X: humanized,
      duration: period,
    });
  }, [slot, strings, templates, translation]);
  return (
    <View style={styles.root}>
      <View style={styles.tabBar}>
        {routes.map((route: Route, index) => {
          const {key, title} = route;
          const isActive = selectedRoute === index;
          const isLeft = index === 0;
          const isRight = index === routes.length - 1;
          return (
            <PressableOpacity
              style={[
                styles.tab,
                isLeft && styles.tabLeft,
                isRight && styles.tabRight,
                [isActive && styles.focusedTab],
              ]}
              key={key}
              onPress={() => onSelectTab(index)}>
              <Text
                numberOfLines={1}
                style={[styles.label, isActive && styles.focusedLabel]}>
                {title}
              </Text>
            </PressableOpacity>
          );
        })}
      </View>
      <Text style={styles.description}>
        {selectedRoute === PurchaseTabsKind.Slot && slotDescription}
        {selectedRoute === PurchaseTabsKind.Miner &&
          strings['poolMinerTab.description']}
      </Text>
    </View>
  );
});
