import {Text, View, ViewProps} from 'react-native';
import React, {FC} from 'react';
import {SvgProps} from 'react-native-svg';
import {observer} from 'mobx-react-lite';

import {useStyles} from '../styling';

export interface MarkedLabelProps extends ViewProps {
  Mark: FC<SvgProps>;
  label: string;
}

export default observer((props: MarkedLabelProps) => {
  const {Mark, label, style, ...rest} = props;
  const styles = useStyles((theme) => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    mark: {
      marginEnd: 5,
    },
    label: {
      flexGrow: 1,
      flexShrink: 1,
      ...theme.fontByWeight('bold'),
      color: theme.palette.textPrimary,
      fontSize: 12,
      lineHeight: 20,
      letterSpacing: 0.0371429,
    },
  }));
  return (
    <View style={[styles.root, style]} {...rest}>
      <Mark style={styles.mark} />
      <Text numberOfLines={1} style={styles.label}>
        {label}
      </Text>
    </View>
  );
});
