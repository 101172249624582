import React, {useMemo} from 'react';
import {
  ColorValue,
  Platform,
  StyleProp,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import {CustomAnimation, View as AnimatableView} from 'react-native-animatable';
import {LinearGradient} from 'expo-linear-gradient';
import {useStyles, useTheme, variance} from '../styling';
import {useRoot} from '../Root/hooks';
import useSize from '../hooks/useSize';

export enum SkeletonColor {
  Light,
  Dark,
}

export type BaseSkeletonRectProps = ViewProps & {
  width?: number;
  height?: number;
  gap?: number;
  color?: ColorValue;
  isRounded?: boolean;
  style?: StyleProp<ViewStyle>;
};

export default observer<BaseSkeletonRectProps, AnimatableView & View>(
  React.forwardRef(function BaseSkeleton(props, ref) {
    const {
      width,
      height = 15,
      isRounded = false,
      gap = 0,
      style,
      color,
      ...rest
    } = props;
    const theme = useTheme();
    const {appearance} = useRoot();
    const [{width: rootWidth}, onLayout] = useSize();
    const styles = useStyles(() => ({
      root: {
        width: width || '100%',
        height,
        marginVertical: gap,
        borderRadius: isRounded ? 9999 : 6,
        backgroundColor: color || theme.palette.back,
      },
      glare: {
        opacity: appearance.isDark ? 0.15 : 0.8,
      },
    }));

    const _animation: CustomAnimation = useMemo(() => {
      return {
        0: {
          opacity: 0,
          transform: [
            {
              translateX: -rootWidth,
            },
          ],
        },
        1: {
          opacity: 1,
          transform: [
            {
              translateX: rootWidth,
            },
          ],
        },
        easing: 'linear',
      };
    }, [rootWidth]);

    return (
      <Root
        ref={ref}
        style={[styles.root, style]}
        onLayout={onLayout}
        {...rest}>
        <GradientGlareAnimatable
          useNativeDriver={Platform.OS !== 'web'}
          animation={_animation}
          duration={1500}
          iterationCount="infinite">
          <GradientGlare
            colors={['transparent', '#ffffff', 'transparent']}
            style={styles.glare}
            start={[1, 0]}
            end={[0, 0]}
          />
        </GradientGlareAnimatable>
      </Root>
    );
  }),
);

const Root = variance(View)((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: theme.palette.back,
  },
}));

const GradientGlareAnimatable = variance(AnimatableView)(() => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
}));

const GradientGlare = variance(LinearGradient)(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));
