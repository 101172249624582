import React, {useMemo} from 'react';
import {MainListItem} from './extra/MainListItem';
import {InsideListItem} from './extra/InsideListItem';
import {List} from './extra/List';
import {useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {CommonSectionProps} from './NotificationsSettingsScreen';
import {FarmLogVariant} from '../Notifications';

export default observer(({state, onCheck}: CommonSectionProps) => {
  const strings = useStrings();
  const styles = useMemo(
    () =>
      ({
        root: {
          marginBottom: 20,
        },
      } as const),
    [],
  );
  return (
    <List style={styles.root}>
      <MainListItem
        value={state[FarmLogVariant.Subscription].value}
        onValueChange={(value) => onCheck(FarmLogVariant.Subscription, value)}
        title={strings['notificationsSettings.subscription']}
      />
      <InsideListItem
        value={state[FarmLogVariant.SubscriptionChanged].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.SubscriptionChanged, value)
        }
        title={strings['notificationsSettings.subscriptionChanged']}
      />
      <InsideListItem
        value={state[FarmLogVariant.SubscriptionActive].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.SubscriptionActive, value)
        }
        title={strings['notificationsSettings.subscriptionActive']}
      />
      <InsideListItem
        value={state[FarmLogVariant.SubscriptionInactive].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.SubscriptionInactive, value)
        }
        title={strings['notificationsSettings.subscriptionInactive']}
      />
      <InsideListItem
        value={state[FarmLogVariant.PoolMinerActivation].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.PoolMinerActivation, value)
        }
        title={strings['notificationsSettings.poolMinerActivation']}
      />
      <InsideListItem
        value={state[FarmLogVariant.PoolMinerExpiration].value}
        onValueChange={(value) =>
          onCheck(FarmLogVariant.PoolMinerExpiration, value)
        }
        title={strings['notificationsSettings.poolMinerExpiration']}
        bordered={false}
      />
    </List>
  );
});
