import React, {useCallback} from 'react';

import {observer} from 'mobx-react-lite';
import {useStrings} from '../../Root/hooks';
import {ButtonVariant} from '../../components';
import ActionModal from '../../modals/ActionModal';
import SuccessCheckSvg from '../../assets/svg/circled/successCheck.svg';
import {useForcedContext} from '../../context';
import {PurchaseScreenContext} from '../PurchaseScreenContext';

export default observer(function SuccessfulSubscriptionPurchaseModalBinding() {
  const strings = useStrings();
  const {
    hideSuccessSubscriptionModal,
    goToDashboard,
    visibleSuccessSubscriptionModal,
  } = useForcedContext(PurchaseScreenContext);
  const onSubmitSuccessModal = useCallback(() => {
    goToDashboard();
    hideSuccessSubscriptionModal();
  }, [goToDashboard, hideSuccessSubscriptionModal]);
  if (!visibleSuccessSubscriptionModal) {
    return null;
  }
  return (
    <ActionModal
      buttons={[
        {
          text: strings['action.ok'],
          onPress: onSubmitSuccessModal,
          variant: ButtonVariant.Highlighted,
        },
      ]}
      Icon={SuccessCheckSvg}
      title={strings['modal.successfulSubscription.title']}
      onDismiss={hideSuccessSubscriptionModal}>
      {strings['modal.successfulPayment.description']}
    </ActionModal>
  );
});
