import {batchDisposers, RouterImpl, Service} from '../structure';
import {ProposalsState, SETTLED} from './ProposalsState';
import {observable, reaction, makeObservable, computed} from 'mobx';
import {InAppOffers} from '../InAppOffersService';
import {PurchaseDiscount} from '../PurchasePromoService';
import {MinerProposalsState} from './MinerProposalsState';
import MinerProposalsStateImpl from './MinerProposalsStateImpl';
import SlotProposalsStateImpl from './SlotProposalsStateImpl';
import {SlotProposalsState} from './SlotProposalsState';
import makeMinerConfigsByInterval from './makeMinerConfigsByInterval';

export default class ProposalsStateImpl implements ProposalsState, Service {
  @observable private _isLoadedIn: boolean = false;

  private readonly _minerProposalsState: MinerProposalsState;
  private readonly _slotProposalsState: SlotProposalsState;

  constructor(
    protected readonly _root: {
      readonly inAppOffers: InAppOffers;
      readonly purchaseDiscount: PurchaseDiscount;
    },
  ) {
    makeObservable(this);
    this._minerProposalsState = new MinerProposalsStateImpl(_root);
    this._slotProposalsState = new SlotProposalsStateImpl(_root);
  }

  @computed
  get minerConfigsByInterval() {
    return makeMinerConfigsByInterval(
      this.minerProposalList,
      this.uniqIntervalList,
    );
  }

  get slotSubscriptionProposalById() {
    return this._slotProposalsState.slotSubscriptionProposalById;
  }
  get slotSubscriptionProposalList() {
    return this._slotProposalsState.slotSubscriptionProposalList;
  }

  get isLoadedIn() {
    return this._isLoadedIn;
  }

  get slotUniqIntervalList() {
    return this._slotProposalsState.slotUniqIntervalList;
  }

  get minerProductProposalList() {
    return this._minerProposalsState.minerProductProposalList;
  }
  get minerProductProposalById() {
    return this._minerProposalsState.minerProductProposalById;
  }
  get minerSubscriptionProposalById() {
    return this._minerProposalsState.minerSubscriptionProposalById;
  }
  get doubleProposalByIntervalByHashrate() {
    return this._minerProposalsState.doubleProposalByIntervalByHashrate;
  }
  get minerProposalById() {
    return this._minerProposalsState.minerProposalById;
  }
  get doubleProposalList() {
    return this._minerProposalsState.doubleProposalList;
  }
  get minerProposalList() {
    return this._minerProposalsState.minerProposalList;
  }
  get minerConfigByHashrate() {
    return this._minerProposalsState.minerConfigByHashrate;
  }
  get minerConfigs() {
    return this._minerProposalsState.minerConfigs;
  }

  get uniqIntervalList() {
    return this._minerProposalsState.uniqIntervalList;
  }

  get minerSubscriptionProposalList() {
    return this._minerProposalsState.minerSubscriptionProposalList;
  }

  get uniqProductIntervalList() {
    return this._minerProposalsState.uniqProductIntervalList;
  }

  private _prepareAfterLoadPurchases() {
    return reaction(
      () => this._root.inAppOffers.isLoadedIn,
      (isLoadedIn) => {
        if (!isLoadedIn) {
          return;
        }
        this._isLoadedIn = false;
        this._minerProposalsState.prepare();
        this._slotProposalsState.prepare();
        this._isLoadedIn = true;
        this.events.send(SETTLED, undefined);
      },
      {fireImmediately: true},
    );
  }

  subscribe() {
    return batchDisposers(this._prepareAfterLoadPurchases());
  }

  readonly events = new RouterImpl<{
    [SETTLED]: void;
  }>();

  reset() {
    this._minerProposalsState.reset();
    this._slotProposalsState.reset();
  }
}
