import {ConnectionDebug} from './ConnectionDebug';
import {Disposer, Service} from '../structure';
import {ApiStore} from '../ApiStore';
import {Disconnect} from '../Disconnect';
import {Millisecond} from '../Time';

export default class ConnectionDebugService
  implements ConnectionDebug, Service
{
  constructor(
    private readonly _root: {
      readonly apiStore: ApiStore;
      readonly disconnect: Disconnect;
    },
  ) {}

  private _timeout?: ReturnType<typeof setTimeout>;

  reconnectAfterDelay(delay: Millisecond) {
    this._root.disconnect.setEnabled(true);
    this._root.apiStore.disconnect();
    this._timeout = setTimeout(() => {
      this._root.disconnect.setEnabled(false);
    }, delay);
  }

  unsubscribe = (() => {
    if (this._timeout !== undefined) {
      clearTimeout(this._timeout);
    }
  }) as Disposer;

  subscribe() {
    return this.unsubscribe;
  }
}
