import {PurchaseId} from '../units';
import {MinerOffer, Offer, OfferPurchaseType} from './OfferManager';
import {GlobalError} from '../Error';
import {batchDisposers, Service} from '../structure';
import {OffersRequester} from './OfferManager/OffersRequester';
import {Either} from '../fp';
import {
  PURCHASE_PROMO_APPLIED,
  PURCHASE_PROMO_REMOVED,
  PurchaseDiscount,
} from '../PurchasePromoService';
import {
  computed,
  flow,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {InAppOffers} from './InAppOffers';
import {Auth} from '../Auth';
import {ConnectedClient} from '../ContextClient';
import {InAppPurchaseManager} from '../InAppPurchaseManager';
import {Configuration} from '../Configuration';
import {ErrorRepository} from '../ErrorRepository';
import OffersRequesterFactory from './OfferManager/OffersRequesterFactory';
import {CancellablePromise} from '../CancellablePromise';
import {Log} from '../Log';

export default abstract class BaseInAppOffersService
  implements InAppOffers, Service
{
  @observable.ref private _offers: Offer[] = [];
  @observable.ref protected _purchasedIds: ReadonlySet<PurchaseId> = new Set();

  @observable protected _isLoading: boolean = false;
  @observable private _isRefreshing: boolean = false;
  @observable private _isLoadedIn: boolean = false;
  @observable protected _error?: GlobalError;

  protected _requester: OffersRequester;

  constructor(
    protected readonly _root: {
      readonly auth: Auth;
      readonly connectedClient: ConnectedClient;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly configuration: Configuration;
      readonly errorRepository: ErrorRepository;
      readonly log: Log;
    },
  ) {
    makeObservable(this);
    this._requester = new OffersRequesterFactory(_root).create();
  }

  protected abstract _fetchPurchasedId(): Promise<void>;

  private _fetch = flow(function* (
    this: BaseInAppOffersService,
    silent?: boolean,
  ) {
    runInAction(() => {
      if (!silent) {
        this._isLoading = true;
      }
      this._isLoadedIn = false;
    });

    yield this._fetchPurchasedId();
    const purchasedSkus = [...(this.purchasedIds?.values() ?? [])];
    const fetchOffers_: Either<MinerOffer[], GlobalError> =
      yield this._requester.fetch(purchasedSkus);
    if (!fetchOffers_.success) {
      this._isLoading = false;
      this._isLoadedIn = true;
      this._error = fetchOffers_.left;
      return;
    }
    this._offers = fetchOffers_.right;
    runInAction(() => {
      this._isLoading = false;
      this._isLoadedIn = true;
    });
  });

  private _flow?: CancellablePromise<unknown>;

  refresh = flow(function* (this: BaseInAppOffersService) {
    runInAction(() => (this._isRefreshing = true));
    yield this.fetch(true).catch(() => undefined);
    runInAction(() => (this._isRefreshing = false));
  }).bind(this);

  get offers() {
    return this._offers;
  }

  get error() {
    return this._error;
  }

  get isLoading() {
    return this._isLoading;
  }

  get isRefreshing() {
    return this._isRefreshing;
  }

  get isLoadedIn() {
    return this._isLoadedIn;
  }

  get purchasedIds() {
    return this._purchasedIds;
  }

  @computed({keepAlive: true})
  get offerById() {
    return new Map(this.offers?.map((_) => [_.purchaseId, _]));
  }

  @computed({keepAlive: true})
  get offerByOfferId() {
    return new Map(this.offers?.map((_) => [_.offerId, _]));
  }

  @computed({keepAlive: true})
  get slotSubscriptionOffers() {
    return (
      this._offers?.flatMap((_) =>
        _.purchaseType === OfferPurchaseType.SlotSubscription ? [_] : [],
      ) ?? []
    );
  }

  @computed({keepAlive: true})
  get slotSubscriptionOfferById() {
    return new Map(this.slotSubscriptionOffers.map((_) => [_.purchaseId, _]));
  }

  @computed({keepAlive: true})
  get minerSubscriptionOffers() {
    return (
      this._offers?.flatMap((_) =>
        _.purchaseType === OfferPurchaseType.MinerSubscription ? [_] : [],
      ) ?? []
    );
  }

  @computed({keepAlive: true})
  get minerSubscriptionOfferById() {
    return new Map(this.minerSubscriptionOffers.map((_) => [_.purchaseId, _]));
  }

  @computed({keepAlive: true})
  get minerProductOffers() {
    return (
      this._offers?.flatMap((_) =>
        _.purchaseType === OfferPurchaseType.MinerProduct ? [_] : [],
      ) ?? []
    );
  }

  @computed({keepAlive: true})
  get minerProductOfferById() {
    return new Map(this.minerProductOffers.map((_) => [_.purchaseId, _]));
  }

  @computed({keepAlive: true})
  get activatorSubscriptionOffers() {
    return (
      this._offers?.flatMap((_) =>
        _.purchaseType === OfferPurchaseType.ActivatorSubscription ? [_] : [],
      ) ?? []
    );
  }

  @computed({keepAlive: true})
  get activatorSubscriptionOfferById() {
    return new Map(
      this.activatorSubscriptionOffers.map((_) => [_.purchaseId, _]),
    );
  }

  fetch(...args: Parameters<BaseInAppOffersService['_fetch']>) {
    this._flow?.cancel();
    const _flow = this._fetch(...args);
    _flow.catch(() => undefined);
    this._flow = _flow;
    return _flow;
  }

  private _listenDefaultInitialize() {
    return reaction(
      () => [
        this._root.purchaseDiscount.isInitialized,
        this._root.auth.isConnected,
      ],
      ([isLoadedIn, isConnected]) => {
        if (isLoadedIn && isConnected) {
          this.fetch();
        }
      },
    );
  }

  private _listenApplyDiscount() {
    return this._root.purchaseDiscount.events.listen(
      PURCHASE_PROMO_APPLIED,
      () => {
        if (this._root.purchaseDiscount.isInitialized) {
          this.fetch();
        }
      },
    );
  }

  private _listenError() {
    return reaction(
      () => this._error,
      (error) => {
        if (error) {
          this._root.log.write({
            body: `IAP ERROR \n ${JSON.stringify(this._error, null, 1)}`,
          });
        }
      },
    );
  }

  private _listenDeleteDiscount() {
    return this._root.purchaseDiscount.events.listen(
      PURCHASE_PROMO_REMOVED,
      () => {
        if (this._root.purchaseDiscount.isInitialized) {
          this.fetch();
        }
      },
    );
  }

  subscribe() {
    return batchDisposers(
      this._listenDefaultInitialize(),
      this._listenApplyDiscount(),
      this._listenDeleteDiscount(),
      this._listenError(),
    );
  }
}
