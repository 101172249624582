import {Authenticated, AuthenticationSummary} from './AuthState';
import {setFernetToken} from '../persistence';

/**
 * **DEBUG ONLY**
 * Initialize state with a custom Fernet token
 * @throws {never}
 */
export default async (fernetToken: string): Promise<Authenticated> => {
  await setFernetToken(fernetToken);
  return {
    kind: 'Authenticated',
    fernetToken,
    summary: AuthenticationSummary.Custom,
  };
};
