import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {ScrollView, Text, View} from 'react-native';
import Camera from './Camera';
import {BarCodeScanningResult} from 'expo-camera';
import {createStylesHook} from '../styling';
import {LinkWorkerModalsManager} from '../LinkWorkerModalsManager';
import {useStrings} from '../Root/hooks';
import {observable} from 'mobx';
import Markdown from '../components/Markdown';

export type QRCodeScannerScreenProps = {
  goToDashboard: () => void;
};

type ShownLinkWorkerProps = {visible: false} | {visible: true; linkKey: string};

export default observer((props: QRCodeScannerScreenProps) => {
  const strings = useStrings();
  const [linkWorkerModalBox] = useState(() =>
    observable.box<ShownLinkWorkerProps>({
      visible: false,
    }),
  );
  const onBarCodeScanned = useCallback(
    (scanningResult: BarCodeScanningResult) => {
      const url = scanningResult.data;
      const key = url.split('?key=')[1];
      if (key && !linkWorkerModalBox.get().visible) {
        linkWorkerModalBox.set({visible: true, linkKey: key});
      }
    },
    [linkWorkerModalBox],
  );
  const modal = linkWorkerModalBox.get();
  const styles = useStyles();
  return (
    <>
      <View style={styles.root}>
        <ScrollView
          style={styles.content}
          contentContainerStyle={styles.contentScroll}>
          <Text style={styles.title}>{strings['qrCode.title']}</Text>
          <Markdown
            styles={{
              link: styles.link,
              paragraph: styles.paragraph,
            }}>
            {strings['qrCode.description']}
          </Markdown>
          <Camera onBarCodeScanned={onBarCodeScanned} />
          <Text style={styles.helperText}>{strings['qrCode.helper']}</Text>
        </ScrollView>
      </View>
      {modal.visible && (
        <LinkWorkerModalsManager {...props} linkKey={modal.linkKey} />
      )}
    </>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    flex: 1,
  },
  content: {
    flex: 1,
  },
  contentScroll: {
    padding: 20,
  },
  title: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    marginBottom: 10,
    fontSize: 16,
    lineHeight: 26,
  },
  cameraView: {
    height: theme.window.width - 40,
    width: theme.window.width - 40,
    backgroundColor: '#C4C4C4',
    marginBottom: 20,
  },
  helperText: {
    fontSize: 14,
    lineHeight: 22,
    letterSpacing: 0.04,
    color: theme.palette.primary,
  },
  paragraph: {
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.04,
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    marginTop: 0,
    marginBottom: 20,
  },
  link: {
    color: theme.palette.secondary,
    fontStyle: 'normal',
    textDecorationLine: 'none',
  },
}));
