import BasePurchaseScreenStateImpl from './BasePurchaseScreenStateImpl';
import {InAppOffers, PaymentMethod} from '../../InAppOffersService';
import {Auth} from '../../Auth';
import {ProposalsState} from '../../ProposalsState';
import {makeObservable} from 'mobx';
import {CurrentSlotSubscription} from '../../CurrentSlotSubscriptionService';
import {InAppPurchase} from '../../InAppPurchase';

export default class NativePurchaseScreenStateImpl extends BasePurchaseScreenStateImpl {
  readonly selectedPaymentMethod = PaymentMethod.InApp;

  constructor(
    protected readonly _root: {
      readonly auth: Auth;
      readonly proposalsState: ProposalsState;
      readonly currentSlotSubscription: CurrentSlotSubscription;
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchase: InAppPurchase;
    },
  ) {
    super(_root);
    makeObservable(this);
  }

  selectPaymentMethod(): void {
    throw new Error('Not implemented on this platform');
  }
}
