import React, {useCallback} from 'react';
import {BottomTabBindingProps} from './BottomTabBindingProps';
import {observer} from 'mobx-react-lite';
import {WithdrawScreen} from '../../WithdrawScreen';
import {Satoshi, satoshiToBitcoin} from '../../ApiStore';
import {useStrings} from '../../Root/hooks';
import {
  NavigationState,
  PartialState,
} from '@react-navigation/routers/src/types';
import {RootParamList} from '../RootStack';

export type WithdrawBindingProps = BottomTabBindingProps<'Withdraw'>;

export default observer((props: WithdrawBindingProps) => {
  const {navigation} = props;
  const goToPaymentSuccess = useCallback(
    (amount: Satoshi) =>
      navigation.navigate('PaymentSuccess', {amount: satoshiToBitcoin(amount)}),
    [navigation],
  );
  const goToWithdrawalHistory = useCallback(
    () => navigation.navigate('WithdrawalHistory'),
    [navigation],
  );
  const strings = useStrings();
  return (
    <WithdrawScreen
      title={strings['withdraw.title']}
      goToPaymentSuccess={goToPaymentSuccess}
      goToWithdrawalHistory={goToWithdrawalHistory}
    />
  );
});

export const createWithdrawState = (): PartialState<
  NavigationState<RootParamList>
> => {
  return {
    index: 0,
    routes: [
      {
        name: 'Root',
        state: {
          routes: [{name: 'Withdraw'}],
        },
      },
    ],
  };
};
