import React from 'react';
import {observer} from 'mobx-react-lite';
import {Pressable, View, ViewProps} from 'react-native';
import {PointHour} from './types';
import {useStyles} from '../styling';
import getClosestColor from './getClosestColor';
import {Hoverable} from 'react-native-web-hooks';

export interface SpeedCellProps extends ViewProps {
  point: PointHour;
  onPress: () => void;
}

export default observer(({point, onPress, ...rest}: SpeedCellProps) => {
  const styles = useStyles(() => ({
    root: {
      width: 20,
      height: 20,
      borderRadius: 2,
      flexDirection: 'row',
      overflow: 'hidden',
    },
    cell: {
      backgroundColor: '#fff',
    },
  }));
  return (
    <Hoverable>
      {(isHovered) => (
        <Pressable onPress={onPress} {...rest}>
          <View style={[styles.root, {opacity: isHovered ? 0.5 : 1}]}>
            {point.map((_, index) => (
              <View
                key={index}
                style={[
                  {
                    backgroundColor: getClosestColor(_.speed),
                    flex: _.length,
                  },
                ]}
              />
            ))}
          </View>
        </Pressable>
      )}
    </Hoverable>
  );
});
