import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useWorkerState} from '../../../../WorkerStateRegistry';
import {expr} from 'mobx-utils';
import isWorkerDisabled from '../../../checkers/isWorkerDisabled';
import isWorkerWarning from '../../../checkers/isWorkerWarning';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import SlotBackground from '../../../common/SlotBackground';
import {WorkerSpeedControl} from '../../../common/WorkerSpeedControl';
import SlotTitle from '../../../common/SlotTitle';
import WorkerHashrate from '../../../common/WorkerHashrate';
import {useStyles} from '../../../../styling';
import {View} from 'react-native';
import Hashrate from '../../../common/Hashrate';
import Temperature from './Temperature';
import {useNavigation} from '@react-navigation/native';
import {DashboardScreenProps} from '../../../DashboardScreenProps';
import isWorkerOffline from '../../../checkers/isWorkerOffline';
import SlotHeader from '../../../common/SlotHeader';
import {meanBy} from 'lodash';
import {SlotMode} from '../../../types';
import {Mode} from '../../../../DashboardMode';
import {useForcedContext} from '../../../../context';
import {DashboardContext} from '../../../context';
import isNeedToReactivate from '../../../../PoolMinerActivator/isNeedToReactivate';

export interface SimpleWorkerSlotProps {
  worker: ReadonlyDeep<WorkerEntity>;
  index: number;
  isLast?: boolean;
}

export default observer(({worker, index, isLast}: SimpleWorkerSlotProps) => {
  const {goToActivator} = useForcedContext(DashboardContext);
  const navigation = useNavigation<DashboardScreenProps['navigation']>();
  const state = useWorkerState(worker.id);
  const warning = expr(() => isWorkerWarning(state));
  const disabled = expr(
    () => isWorkerDisabled(worker) || isWorkerOffline(state),
  );
  const hashrate = useCallback(
    () => <WorkerHashrate worker={worker} />,
    [worker],
  );
  const expired = expr(() => isNeedToReactivate(worker));
  const handleSlotPress = useCallback(() => {
    if (expired) {
      goToActivator();
    }
  }, [goToActivator, expired]);
  const goToDetails = useCallback(() => {
    navigation.navigate('WorkerDetails', {workerId: worker.id});
  }, [navigation, worker.id]);
  const styles = useStyles((theme) => ({
    title: {
      marginBottom: 20,
    },
    speedHead: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    hashrate: {
      ...theme.fontByWeight('700'),
    },
    last: {
      borderBottomLeftRadius: 15,
      borderBottomRightRadius: 15,
    },
  }));
  const temperature = (meanBy(state?.cpu_states, 'temperature') || 0).toFixed(
    0,
  );
  return (
    <SlotBackground
      isTouchable={expired}
      onPress={handleSlotPress}
      disabled={disabled}
      warning={warning}
      style={isLast && styles.last}
      containerStyle={isLast && styles.last}>
      <SlotHeader
        variant={Mode.Simple}
        index={index}
        goToDetailsPress={goToDetails}
      />
      <SlotTitle
        containerStyle={styles.title}
        numberOfLines={1}
        disabled={disabled}
        warning={warning}
        mode={Mode.Simple}
        worker={worker}
      />
      <View style={styles.speedHead}>
        <View style={styles.hashrate}>
          <Hashrate disabled={disabled} value={hashrate} />
        </View>
        <Temperature
          value={temperature}
          disabled={disabled}
          warning={warning}
        />
      </View>
      <WorkerSpeedControl
        onExpiredPress={goToDetails}
        slotMode={SlotMode.Simple}
        worker={worker}
      />
    </SlotBackground>
  );
});
