import React, {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import AdvertSplashScreen from '../../AdvertSplash';
import {RootStackBindingProps} from '../RootStack/RootStackBindingProps';
import {first} from 'lodash';
import {useRoot} from '../../Root/hooks';
import {FULFILLED, PENDING} from '../../AsyncAtom';
import {AdSpot} from '../../ApiStore';
import {expr} from 'mobx-utils';
import {useNavigationGetIsFocused, useSafelyGoBack} from '../hooks';
import {useTimer} from 'use-timer';

import {AdInfoAction} from '../../Advert';
import {ADVERT_SPLASH_DESKTOP_BREAKPOINT} from '../../AdvertSplash/AdvertSplashScreen';
import {Url} from '../../units';

export type AdvertSplashBindingProps = RootStackBindingProps<'AdvertSplash'>;

export default observer(function AdvertSplashBinding({
  navigation,
}: AdvertSplashBindingProps) {
  const {
    advertHelper: {state, close},
    windowDimensionsState: {window},
    location,
  } = useRoot();
  // TODO: Temporary hack, should be deleted after redesign
  const [internalClosed, setInternalClosed] = useState(false);
  const isDesktop = expr(
    () => window.width >= ADVERT_SPLASH_DESKTOP_BREAKPOINT,
  );
  const mobileBanner =
    state?.status === FULFILLED
      ? first(state.result.spotByBannerList.get(AdSpot.SplashMobile))
      : undefined;
  const desktopBanner =
    state?.status === FULFILLED
      ? first(state.result.spotByBannerList.get(AdSpot.SplashDesktop))
      : undefined;
  const banner = expr(() => (isDesktop && desktopBanner) || mobileBanner);

  const safelyGoBack = useSafelyGoBack();
  const navigationGetIsFocused = useNavigationGetIsFocused();
  const onClose = useCallback(
    async (exclude = true) => {
      if (!banner) {
        return;
      }
      await close(banner.id, banner.spot, exclude);
      setInternalClosed(true);
      safelyGoBack();
    },
    [banner, close, safelyGoBack],
  );

  const onCrossClose = useCallback(async () => onClose(false), [onClose]);

  const onButtonActionPress = useCallback(
    (action: AdInfoAction) => {
      if (action.link) {
        location.open(action.link as Url);
      }
    },
    [location],
  );

  const {time, start} = useTimer({
    autostart: false,
    initialTime: 60,
    endTime: 0,
    timerType: 'DECREMENTAL',
    onTimeOver: onClose,
  });

  useEffect(() => {
    if (navigationGetIsFocused()) {
      if (state && state.status === FULFILLED && banner !== undefined) {
        start();
      } else if (state && state.status !== PENDING && banner === undefined) {
        safelyGoBack();
      }
    }
  }, [banner, navigation, navigationGetIsFocused, safelyGoBack, start, state]);

  if (banner === undefined) {
    console.warn('Banner for splash spot not found');
    return null;
  }

  if (internalClosed) {
    return null;
  }

  return (
    <AdvertSplashScreen
      advertItem={banner}
      seconds={time}
      onButtonActionPress={onButtonActionPress}
      onCrossClose={onCrossClose}
      onClose={onClose}
    />
  );
});
