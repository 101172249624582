import {MessageHandler} from './MessageHandler';
import {MessageDescription} from './Message';
import {Either, success} from '../fp';
import {Json} from '../Json';
import {Analytics} from '../Analytics';
import {HeadlessLocalNotifications} from '../LocalNotifications';
import {MessageContext} from './MessageContext';
import {HeadlessMessaging} from './HeadlessMessaging';

export default class MessageHandlerImpl implements MessageHandler {
  constructor(
    protected readonly _root: {
      readonly json: Json;
      readonly analytics: Analytics;
      readonly headlessMessaging: HeadlessMessaging;
      readonly headlessLocalNotifications: HeadlessLocalNotifications;
    },
  ) {}

  async handle(
    description: MessageDescription,
  ): Promise<Either<MessageContext, unknown>> {
    const meta_ = this._root.json.parse(description.meta);
    if (!meta_.success) {
      return meta_;
    }
    const controller = this._root.analytics.createController();
    try {
      controller.batch.report(description.event_url, {
        event: 'received',
        meta: meta_.right,
        data: {},
      });
      const context_ = await this._root.headlessMessaging.fetchContext(
        description,
      );
      if (!context_.success) {
        return context_;
      }
      const schedule_ =
        await this._root.headlessLocalNotifications.scheduleNotification(
          context_.right,
        );
      if (!schedule_.success) {
        return schedule_;
      }
      return success(context_.right);
    } finally {
      await controller.flush();
    }
  }
}
