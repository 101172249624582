import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Text, View} from 'react-native';
import ChartView from './ChartView';
import {CryptoLoader} from '../../../../../components/CryptoLoader';
import {useStyles} from '../../../../../styling';
import {useIsFocused} from '@react-navigation/native';
import {useRoot} from '../../../../../Root/hooks';

export default observer(function BtcGraphRate() {
  const {
    dashboardStore: {ratesPanel: state, currentRateRequest},
  } = useRoot();

  const styles = useStyles(
    (theme) =>
      ({
        root: {
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 15,
        },
        chart: {
          width: 180,
          height: 50,
        },
        chartView: {
          flexGrow: 1,
          flexShrink: 1,
        },
        svg: {
          overflow: 'visible',
          width: 180,
          height: 50,
        },
        loaderView: {
          marginRight: 20,
        },
        ratesLabel: {
          ...theme.fontByWeight('bold'),
          fontSize: 12,
          lineHeight: 14,
          color: theme.palette.textPrimary,
          marginBottom: 5,
        },
        ratesValue: {
          ...theme.fontByWeight(),
          fontSize: 13,
          lineHeight: 15,
          color: theme.palette.textSecondary,
        },
        ratesView: {
          paddingHorizontal: 20,
        },
      } as const),
  );
  const rate = useMemo(() => {
    const usd = currentRateRequest.value?.usd;
    const btc = currentRateRequest.value?.btc;
    if (!usd || !btc) {
      return null;
    }
    return (usd / btc).toFixed(2);
  }, [currentRateRequest.value]);

  const isFocused = useIsFocused();
  const {isLoading, graph} = state;

  if (isLoading) {
    return (
      <View style={styles.loaderView}>
        <CryptoLoader />
      </View>
    );
  }
  if (!isFocused) {
    return null;
  }

  return (
    <View style={styles.root}>
      <View style={styles.chart}>
        {graph && graph.series.length >= 3 ? (
          <ChartView
            svgStyle={styles.svg}
            style={styles.chartView}
            series={graph.series}
            from={graph.from}
            to={graph.to}
          />
        ) : null}
      </View>

      <View style={styles.ratesView}>
        <Text style={styles.ratesLabel}>BTC / USD</Text>
        <Text style={styles.ratesValue}>{rate}</Text>
      </View>
    </View>
  );
});
