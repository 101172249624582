import React from 'react';
import {
  StyleProp,
  View,
  ViewStyle,
  StyleSheet,
  ColorValue,
  DimensionValue,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useTheme} from '../styling';

interface DividerProps {
  style?: StyleProp<ViewStyle>;
  height?: DimensionValue;
  color?: ColorValue;
}

export const Divider = observer((props: DividerProps) => {
  const theme = useTheme();
  const {style, height = 1, color = theme.palette.border} = props;
  return (
    <View style={[styles.root, {height, backgroundColor: color}, style]} />
  );
});

const styles = StyleSheet.create({
  root: {
    alignSelf: 'stretch',
  },
});
