import React, {useCallback} from 'react';
import {View, TouchableOpacity, Text, StyleSheet} from 'react-native';
import {observer} from 'mobx-react-lite';
import {Button, ButtonColor, PressableOpacity} from '../../../../components';
import SettingsSvg from '../../../../assets/svg/colorless/settings.svg';
import {useNavigation} from '@react-navigation/native';
import {useRoot, useStrings} from '../../../../Root/hooks';
import PageHeader from '../../../../components/PageHeader';
import {DashboardScreenProps} from '../../../DashboardScreenProps';
import {variance} from '../../../../styling';
import {AccountType} from '../../../../Auth';
import {sized} from '../../../../Svg';
import CoinSvg from '../../../../assets/svg/colored/coin.svg';

export const DESKTOP_HEADER_WEB_HEIGHT = 72 + 10; // margin-bottom;

export type DesktopHeaderWebProps = {
  goToAuth: () => void;
};

export default observer(function DesktopHeaderWeb({
  goToAuth,
}: DesktopHeaderWebProps) {
  const {auth} = useRoot();
  const navigation = useNavigation<DashboardScreenProps['navigation']>();
  const strings = useStrings();

  const toSettings = useCallback(
    () => navigation.navigate('DashboardSettings'),
    [navigation],
  );

  return (
    <PageHeader
      style={styles.root}
      title={strings['screenTitle.dashboard']}
      right={
        <Content>
          <UserInfo>
            <UserIdView>
              <Text>
                <UserId>ID: </UserId>
                <AccountId>{auth.accountId}</AccountId>
              </Text>
            </UserIdView>
            {auth.accountType === AccountType.Permanent ? (
              <EmailView>
                <CoinIcon />
                <EmailText>{auth.email}</EmailText>
              </EmailView>
            ) : (
              <SignInButton onPress={goToAuth}>
                <SignInCoinView>
                  <CoinIcon />
                </SignInCoinView>
                <SignInText>{strings['dashboard.farmInfo.signIn']}</SignInText>
              </SignInButton>
            )}
          </UserInfo>

          <SettingsButtonView>
            <TouchableOpacity activeOpacity={0.8} onPress={toSettings}>
              <Button Icon={SettingsSvg} color={ButtonColor.Primary}>
                {strings.settings}
              </Button>
            </TouchableOpacity>
          </SettingsButtonView>
        </Content>
      }
    />
  );
});

const CoinIcon = sized(CoinSvg, 16);

const SignInButton = variance(PressableOpacity)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
}));

const SignInCoinView = variance(View)(() => ({
  root: {
    marginRight: 5,
  },
}));

const SignInText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.secondary,
    textTransform: 'uppercase',
  },
}));

const Content = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
}));

const SettingsButtonView = variance(View)((theme) => ({
  root: {
    borderLeftWidth: 1,
    borderLeftColor: theme.palette.border,
    height: '100%',
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
}));

const UserIdView = variance(View)(() => ({
  root: {
    marginBottom: 5,
  },
}));

const UserId = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textPrimary,
  },
}));

const AccountId = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight(),
    color: theme.palette.textPrimary,
  },
}));

const UserInfo = variance(View)(() => ({
  root: {
    paddingHorizontal: 20,
  },
}));

const EmailView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
const EmailText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textPrimary,
    marginLeft: 5,
  },
}));

const styles = StyleSheet.create({
  root: {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    marginBottom: 10,
  },
});
