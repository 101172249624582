import {Either, error, success} from '../fp';
import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FERNET_TOKEN} from './keys';

/**
 * @throws {never}
 */
export default async (): Promise<Either<string | null, unknown>> => {
  try {
    if (
      SecureStore &&
      SecureStore.isAvailableAsync &&
      (await SecureStore.isAvailableAsync())
    ) {
      return success(await SecureStore.getItemAsync(FERNET_TOKEN));
    } else {
      return success(await AsyncStorage.getItem(FERNET_TOKEN));
    }
  } catch (raw) {
    return error(raw);
  }
};
