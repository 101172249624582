import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {UpdateRequiredScreen} from '../../ErrorScreen';
import {useGoToAppStore, useGoToPlayStore} from '../hooks';
import {Platform} from 'react-native';
import RNExitApp from 'react-native-exit-app';

export default observer(function UpdateRequiredBinding() {
  const goToAppStore = useGoToAppStore();
  const goToPlayStore = useGoToPlayStore();
  const onUpdate = useCallback(async () => {
    switch (Platform.OS) {
      case 'web':
        window.location.reload();
        break;
      case 'android':
        await goToPlayStore();
        break;
      case 'ios':
        await goToAppStore();
        break;
    }
  }, [goToAppStore, goToPlayStore]);
  return <UpdateRequiredScreen onUpdate={onUpdate} onClose={ON_CLOSE} />;
});

const ON_CLOSE = () => RNExitApp.exitApp();
