export default (begin: number, end: number, points: number): number[] => {
  if (points === 0) {
    return [];
  }
  if (points === 1) {
    const average = (begin + end) / 2;
    return [average];
  }
  const result: number[] = [];
  const step = (end - begin) / (points - 1);
  for (let i = 0; i < points; i++) {
    result.push(begin + step * i);
  }
  return result;
};
