import ConsistentInterval from './ConsistentInterval';
import LinearInterpolation from './LinearInterpolation';

export default class StaircaseFunctionIntegrator<T extends number> {
  private _toIndex: LinearInterpolation<number>;

  constructor(
    private readonly _values: readonly number[],
    private readonly _domain: ConsistentInterval<T>,
  ) {
    this._toIndex = new LinearInterpolation(
      this._domain,
      ConsistentInterval.from(this._values),
    );
  }

  private _safeGet(index: number) {
    return this._values[Math.floor(index)] ?? 0;
  }

  private _sumSlice(from: number, to: number) {
    let sum = 0;
    for (let i = from; i < to; ++i) {
      sum += this._values[i] ?? 0;
    }
    return sum;
  }

  integrate(period: ConsistentInterval<T>) {
    const from = this._toIndex.translate(period.from);
    const to = this._toIndex.translate(period.to);
    if (Math.floor(from) === Math.floor(to)) {
      return this._safeGet(from) * (to - from);
    }
    const baseFrom = Math.ceil(from);
    const baseTo = Math.floor(to);
    const leftRemainder = this._safeGet(from) * (baseFrom - from);
    const rightRemainder = this._safeGet(to) * (to - baseTo);
    let base = this._sumSlice(baseFrom, baseTo);
    return leftRemainder + base + rightRemainder;
  }
}
