import {action, observable, makeObservable} from 'mobx';
import {RawListener, RawTunnel} from './RawTunnel';
import {TunnelKind} from './TunnelKind';
import {ReadyState} from '../Connection';
import {Disposer} from '../structure';
import ObservableWebSocketStateImpl from './ObservableWebSocketStateImpl';
import {ObservableWebSocketState} from './ObservableWebSocketState';

export interface WebSocketTunnelState {
  readonly readyState: ReadyState;
  readonly errorMessage?: string;
}

export default class WebSocketTunnel
  implements RawTunnel, WebSocketTunnelState
{
  readonly tunnelKind = TunnelKind.Raw;

  private readonly _state: ObservableWebSocketState;
  private readonly _listeners = new Set<RawListener>();
  @observable private _errorMessage?: string;

  get readyState() {
    return this._state.readyState;
  }

  get errorMessage() {
    return this._errorMessage;
  }

  constructor(private readonly _ws: WebSocket) {
    makeObservable(this);
    this._state = new ObservableWebSocketStateImpl(_ws);
    this._ws.onerror = action((event) => {
      this._errorMessage = (event as any).message ?? 'Unknown error';
      this.close();
    });
    // TODO `action` is unnecessary here
    this._ws.onmessage = action((event) => {
      for (const listener of this._listeners) {
        listener(event.data);
      }
    });
  }

  close() {
    this._state.close();
  }

  async send(message: string) {
    this._ws.send(message);
  }

  listen(listener: RawListener) {
    this._listeners.add(listener);
    return (() => {
      this.forget(listener);
    }) as Disposer;
  }

  forget(listener: RawListener) {
    this._listeners.delete(listener);
  }
}
