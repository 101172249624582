import {GetRatesResponse} from '../../universal/features/api/entity/GetRatesResponse';
import {Graph} from '../GraphPanel';
import {ReadonlyDeep} from 'type-fest';

export default ({rates}: ReadonlyDeep<GetRatesResponse>): Graph | undefined =>
  rates.length >= 1
    ? {
        from: rates[0].timestamp * 1000,
        to: rates[rates.length - 1].timestamp * 1000,
        series: rates.map((_) => _.amount),
      }
    : undefined;
