import {Server} from '../JsonRpc';
import {
  CryptoFarmConnectedServerCalls,
  CryptoFarmServerCalls,
  CryptoFarmServerNotifications,
} from '../ApiStore';
import {batchDisposers} from '../structure';
import {success} from '../fp';
import {LogController} from './LogController';

export default (
  server: Server<
    CryptoFarmServerCalls & CryptoFarmConnectedServerCalls,
    CryptoFarmServerNotifications
  >,
  logs: LogController,
) =>
  batchDisposers(
    server.call('get_log_settings', async (params, response) => {
      const settings = await logs.getSettings();
      return response.respond(success({settings}));
    }),
    server.call('set_log_settings', async (params, response) => {
      await logs.setSettings(params.settings);
      return response.respond(success(null));
    }),
    server.call('get_logs_from_timestamp', async (params, response) => {
      const result = await logs.queryList(params.limit, params.timestamp);
      return response.respond(success(result));
    }),
  );
