import {useCallback, useEffect, useRef, useState} from 'react';
import {useRoot} from '../../Root/hooks';
import {
  useHeaderFarmInfoSectionHeight,
  useHeaderBannerSectionHeight,
} from '../hooks/useGetItemLayout';
import {SectionWorkerListComponent} from './SectionWorkerList';
import {SimpleWorkerListComponent} from './SimpleWorkerList';
import {observable} from 'mobx';
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
} from 'react-native';
import {inRange} from 'lodash';
import {
  SCROLL_TO_ELEMENT,
  SCROLLED,
  StackElementKey,
} from '../../InteractiveTutorial';

type ScrollingState = {scrolling: true; offset: number} | {scrolling: false};

export default function useTutorialEventsListener() {
  const {interactiveTutorial} = useRoot();
  const headerInfoBanner = useHeaderBannerSectionHeight();
  const headerFarmInfoBanner = useHeaderFarmInfoSectionHeight();
  const sectionListRef = useRef<SectionWorkerListComponent>(null);
  const listRef = useRef<SimpleWorkerListComponent>(null);
  const [scrollingStateBox] = useState(() =>
    observable.box<ScrollingState>({scrolling: false}),
  );
  const lastTopOffset = useRef<number>();
  // const lastNativeEvent = useRef<NativeScrollEvent>();

  const frameHeight = useRef<number>();
  const contentHeight = useRef<number>();

  const timeout = useRef<NodeJS.Timeout>();

  const sendScrolled = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    scrollingStateBox.set({scrolling: false});
    interactiveTutorial.events.send(SCROLLED, undefined);
  }, [interactiveTutorial, scrollingStateBox]);

  const getTopOffsetByKey = useCallback(
    (key: StackElementKey) => {
      switch (key) {
        case 'BALANCE':
          return headerInfoBanner;
        case 'ADD_WORKER':
          return headerInfoBanner;
        case 'PROMO':
          return headerInfoBanner + headerFarmInfoBanner / 4;
        default:
          return 0;
      }
    },
    [headerFarmInfoBanner, headerInfoBanner],
  );

  const getMaxPossibleOffset = useCallback(() => {
    const contentSize = contentHeight.current;
    const height = frameHeight.current;
    if (
      !contentSize ||
      !height ||
      inRange(contentSize, height - 3, height + 3)
    ) {
      return null;
    }
    return contentSize - height;
  }, []);

  useEffect(
    () =>
      interactiveTutorial.events.listen(SCROLL_TO_ELEMENT, ({key}) => {
        let top = getTopOffsetByKey(key);
        const maxPossibleOffset = getMaxPossibleOffset();
        if (maxPossibleOffset === null) {
          sendScrolled();
          return;
        }
        if (maxPossibleOffset < top) {
          top = maxPossibleOffset;
        }
        if (lastTopOffset.current === top) {
          sendScrolled();
          return;
        }

        lastTopOffset.current = top;
        scrollingStateBox.set({scrolling: true, offset: top});

        sectionListRef.current
          ?.getScrollResponder()
          ?.scrollTo({y: top, animated: true});
        listRef.current?.scrollToOffset({offset: top, animated: true});

        // If something goes wrong, and we don't scroll to the element
        timeout.current = setTimeout(() => {
          console.warn(
            'Protection mechanism triggered, unable to scroll to element',
          );
          sendScrolled();
        }, 700);
      }),
    [
      getMaxPossibleOffset,
      getTopOffsetByKey,
      headerFarmInfoBanner,
      headerInfoBanner,
      interactiveTutorial,
      scrollingStateBox,
      sendScrolled,
    ],
  );

  const compareValuesInRange = (value1: number, value2: number) =>
    inRange(value1, value2 - 3, value2 + 3);

  const onScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const state = scrollingStateBox.get();
      if (!state.scrolling) {
        return;
      }
      // lastNativeEvent.current = event.nativeEvent;
      if (
        compareValuesInRange(event.nativeEvent.contentOffset.y, state.offset)
      ) {
        sendScrolled();
      }
    },
    [scrollingStateBox, sendScrolled],
  );

  const onLayout = (event: LayoutChangeEvent) => {
    frameHeight.current = event.nativeEvent.layout.height;
  };
  const onContentSizeChange = (_: number, height: number) => {
    contentHeight.current = height;
  };

  return {sectionListRef, listRef, onScroll, onLayout, onContentSizeChange};
}
