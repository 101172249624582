import React, {useCallback} from 'react';
import Modal from '../../../modals/BaseModal';
import {ModalDescription} from '../../../modals/components';
import {Badge, ButtonColor, ButtonVariant} from '../../../components';
import {Text, View} from 'react-native';
import {DropdownPicker} from './DropdownPicker';
import {useRoot, useStrings} from '../../../Root/hooks';
import {observer} from 'mobx-react-lite';
import {createStylesHook} from '../../../styling';

export default observer(() => {
  const {
    configPickerModal: {shown, cancel, selectedItem, _onPick},
  } = useRoot();
  const strings = useStrings();
  const styles = useStyles();

  const onSubmit = useCallback(() => {
    _onPick(selectedItem?.id);
  }, [_onPick, selectedItem]);

  if (!shown) {
    return null;
  }

  const buttons = [
    {
      text: strings['action.cancel'],
      onPress: cancel,
      color: ButtonColor.Default,
      variant: ButtonVariant.Contained,
    },
    {
      text: strings['action.confirm'],
      onPress: onSubmit,
      variant: selectedItem
        ? ButtonVariant.Highlighted
        : ButtonVariant.Disabled,
      disabled: !selectedItem,
    },
  ];

  return (
    <Modal buttons={buttons} onDismiss={cancel}>
      <View style={styles.header}>
        <Text style={styles.headerTitleText}>
          {strings['modal.setUpWorkerDemo.title']}
        </Text>
        <Badge style={styles.badge} text={strings['mode.demo']} />
      </View>
      <ModalDescription style={styles.descriptionText}>
        {strings['modal.setUpWorkerDemo.description']}
      </ModalDescription>
      <View style={styles.body}>
        <DropdownPicker />
        <Text style={styles.helperText}>
          {strings['modal.setUpWorkerDemo.subtext']}
        </Text>
      </View>
    </Modal>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    paddingBottom: 20,
  },
  descriptionText: {
    textAlign: 'left',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingLeft: 15,
    paddingRight: 25,
    marginTop: 10,
    marginBottom: 15,
  },
  headerTitleText: {
    color: theme.palette.textPrimary,
    letterSpacing: 0.06,
    fontSize: 24,
    lineHeight: 27,
    marginRight: 5,
    ...theme.fontByWeight('700'),
  },
  body: {
    paddingHorizontal: 15,
    paddingTop: 30,
    marginBottom: 20,
    ...theme.mediaQuery({
      1000: {
        width: 410,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: 40,
        paddingBottom: 10,
      },
    }),
  },
  helperText: {
    fontSize: 11,
    lineHeight: 13,
    letterSpacing: 0.04,
    color: theme.palette.textSecondary,
    marginTop: 10,
  },
  badge: {},
}));
