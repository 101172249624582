import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet} from 'react-native';
import {Container} from '../components/Grid';
import {sized} from '../Svg';
import ContrastCryptotabFarmSvg from '../assets/svg/colored/cryptotabFarmContrast.svg';
import {PressableOpacity} from '../components';
import {useOpenLink} from '../Links';
import common from '../Configuration/common';

export default observer(() => {
  const goToCryptoTabFarm = useOpenLink(common.aboutUrl);
  return (
    <Container style={styles.root}>
      <PressableOpacity onPress={goToCryptoTabFarm}>
        <CryptotabFarmIcon />
      </PressableOpacity>
    </Container>
  );
});

const CryptotabFarmIcon = sized(ContrastCryptotabFarmSvg, 205, 40);

const styles = StyleSheet.create({
  root: {
    marginBottom: 25,
    paddingTop: 30,
  },
});
