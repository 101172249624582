export interface DashboardMode {
  readonly mode: Mode;
  changeMode: (mode: Mode) => void;
}

export enum Mode {
  Idle,
  Regular,
  Simple,
}
