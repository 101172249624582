import React from 'react';
import {Text, View} from 'react-native';
import {MainListItem} from './extra/MainListItem';
import {List} from './extra/List';
import {useStrings} from '../Root/hooks';

import {observer} from 'mobx-react-lite';
import {CommonSectionProps} from './NotificationsSettingsScreen';
import {FarmLogVariant} from '../Notifications';
import {createStylesHook, useTheme} from '../styling';

export default observer(({state, onCheck}: CommonSectionProps) => {
  const strings = useStrings();
  const theme = useTheme();
  const styles = useStyles();
  return (
    <View style={styles.root}>
      <View style={[styles.column, styles.leftColumn]}>
        <List>
          <View style={[styles.mainListView]}>
            <MainListItem
              value={state[FarmLogVariant.ShowAll].value}
              onValueChange={(value) => onCheck(FarmLogVariant.ShowAll, value)}
              bordered={false}
              title={strings['notificationsSettings.showNotifications']}
            />
          </View>
        </List>
      </View>
      {theme.window.width > 1280 && (
        <View style={[styles.column, styles.rightColumn]}>
          <Text style={styles.description}>
            {strings['notificationsSettings.description']}
          </Text>
        </View>
      )}
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    marginBottom: 20,
    ...theme.mediaQuery({
      1281: {
        flexDirection: 'row',
        paddingBottom: 20,
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.border,
      },
    }),
  },
  mainListView: {
    borderBottomWidth: 5,
    borderBottomColor: theme.palette.primary,
    ...theme.mediaQuery({
      1281: {
        borderBottomWidth: 0,
      },
    }),
  },
  column: {
    ...theme.mediaQuery({
      1281: {
        flexGrow: 1,
        width: '50%',
      },
    }),
  },
  leftColumn: {
    ...theme.mediaQuery({
      1281: {
        paddingRight: 10,
      },
    }),
  },
  rightColumn: {
    ...theme.mediaQuery({
      1281: {
        paddingLeft: 20,
        justifyContent: 'center',
      },
    }),
  },
  description: {
    ...theme.fontByWeight(),
    color: theme.palette.textSecondary,
    fontSize: 12,
    lineHeight: 20,
  },
}));
