import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';
import {SvgProps} from 'react-native-svg';
import {useStyles} from '../styling';

interface SwitchFarmHeaderProps {
  title: string;
  Icon: React.ComponentType<SvgProps>;
  description: string | string[];
}

export default observer(({title, Icon, description}: SwitchFarmHeaderProps) => {
  const styles = useStyles((theme) => ({
    chooseAccount: {
      alignSelf: 'center',
      marginTop: 70,
    },
    title: {
      marginTop: 20,
      marginHorizontal: 20,
      ...theme.fontByWeight('bold'),
      fontSize: 22,
      lineHeight: 32,
      letterSpacing: 0.0744974,
      color: theme.palette.textPrimary,
      textAlign: 'center',
      ...theme.mediaQuery({
        1000: {
          fontSize: 32,
        },
      }),
    },
    description: {
      marginTop: 10,
      marginHorizontal: 20,
      marginBottom: 20,
      ...theme.fontByWeight(),
      fontSize: 15,
      lineHeight: 22,
      letterSpacing: 0.0609524,
      color: theme.palette.textSecondary,
      textAlign: 'center',
    },
    bold: {
      ...theme.fontByWeight('bold'),
      color: theme.palette.textPrimary,
    },
  }));
  return (
    <View>
      <Icon style={styles.chooseAccount} width={80} height={80} />
      <Text style={styles.title}>{title}</Text>
      {typeof description === 'string' ? (
        <Text style={styles.description}>{description}</Text>
      ) : (
        <Text style={styles.description}>
          {description.map((text, index) =>
            index === 1 ? <Text style={styles.bold}>{text}</Text> : <>{text}</>,
          )}
        </Text>
      )}
    </View>
  );
});
