import {MessageContext} from './MessageContext';
import {MessageDescription} from './Message';
import {Either, success, error} from '../fp';
import {RemoteMessageStore} from './RemoteMessageStore';
import {Json} from '../Json';
import {HeadlessMessaging} from './HeadlessMessaging';

export default class HeadlessMessagingImpl implements HeadlessMessaging {
  constructor(
    protected readonly _parent: {
      readonly remoteMessageStore: RemoteMessageStore;
      readonly json: Json;
    },
  ) {}

  async fetchContext(
    description: MessageDescription,
  ): Promise<Either<MessageContext, unknown>> {
    const meta_ = this._parent.json.parse(description.meta);
    if (!meta_.success) {
      return meta_;
    }
    const meta = meta_.right;
    switch (description.type) {
      case 'stored': {
        const {url, event_url} = description;
        const message_ = await this._parent.remoteMessageStore.fetch(url, meta);
        if (!message_.success) {
          return message_;
        }
        const message = message_.right;
        return success({message, event_url, meta, url});
      }
      case 'instant': {
        const {data, event_url} = description;
        const message_ = this._parent.json.parse(data);
        if (!message_.success) {
          return message_;
        }
        const message = message_.right;
        return success({message, event_url, meta});
      }
      default:
        return error(
          new Error(
            `Unknown message description type: ${
              (description as MessageDescription).type
            }`,
          ),
        );
    }
  }
}
