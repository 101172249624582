import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet} from 'react-native';
import * as Animatable from 'react-native-animatable';
import {PressableOpacity} from '../../components';

export interface BackdropProps {
  onPress?: () => void;
}

export const ModalBackdrop = observer(({onPress}: BackdropProps) => {
  return (
    <Animatable.View animation="fadeIn" duration={300} style={styles.root}>
      <PressableOpacity
        style={styles.touchable}
        activeOpacity={0.3}
        onPress={() => onPress?.()}
      />
    </Animatable.View>
  );
});

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  touchable: {
    flex: 1,
  },
});
