import {action, flow, observable, makeObservable} from 'mobx';
import {Millisecond} from '../utils/time';
import {ChartViewScope, Graph} from '../GraphPanel';
import {ReadonlyDeep} from 'type-fest';
import {ApiStore, CryptoFarmServerCalls} from '../ApiStore';
import graphPeriod from '../GraphPanel/graphPeriod';
import ratesGraph from '../helpers/ratesGraph';
import {CancellablePromise} from '../CancellablePromise';

export default class RatesPanelState {
  @observable private _from?: Millisecond;
  @observable private _scope = ChartViewScope.Day;
  @observable.ref protected _graph?: ReadonlyDeep<Graph>;
  @observable protected _isLoading = false;

  constructor(private readonly _root: {readonly apiStore: ApiStore}) {
    makeObservable(this);
  }

  get period() {
    return graphPeriod(this._from, this._scope);
  }

  get scope() {
    return this._scope;
  }

  get graph() {
    return this._graph;
  }

  get isLoading() {
    return this._isLoading;
  }

  @action.bound onFromChange(from: Millisecond) {
    this._from = from;
    // noinspection JSIgnoredPromiseFromCall
    this.fetch();
  }

  @action.bound onScopeChange(scope: ChartViewScope) {
    this._scope = scope;
    // noinspection JSIgnoredPromiseFromCall
    this.fetch();
  }

  private _fetch = flow(function* (this: RatesPanelState) {
    this._isLoading = true;
    const {from, to} = this.period;
    const scope = this._scope;
    const result: ReturnType<CryptoFarmServerCalls['get_btc_usd_rate']> =
      yield this._root.apiStore.client.call('get_btc_usd_rate', {
        interval: scope === ChartViewScope.Day ? 'hourly' : 'daily',
        start_time: Math.floor(from / 1000),
        end_time: Math.floor(to / 1000),
      });
    if (result.success) {
      this._graph = ratesGraph(result.right);
    }
    this._isLoading = false;
  });

  private _flow?: CancellablePromise<void>;

  /**
   * @throws {never}
   */
  async fetch() {
    this._flow?.cancel();
    try {
      this._flow = this._fetch();
      await this._flow;
    } catch (ignore) {}
  }

  @action reset() {
    this._flow?.cancel();
    this._from = undefined;
    this._scope = ChartViewScope.Day;
    this._graph = undefined;
    this._isLoading = false;
  }
}
