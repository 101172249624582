import {observer} from 'mobx-react-lite';
import {useRoot} from '../../../../Root/hooks';
import {useTheme, variance} from '../../../../styling';
import {Text, View} from 'react-native';
import {Button, PressableOpacity} from '../../../../components';
import React from 'react';
import {sized} from '../../../../Svg';
import BtcSvg from '../../../../assets/svg/colored/btc.svg';
import PayoutSvg from '../../../../assets/svg/colored/payout.svg';
import useTutorialMeasure from '../../../../InteractiveTutorial/useTutorialMeasure';
import {StackElementKey} from '../../../../InteractiveTutorial';

export const BALANCE_INFO_HEIGHT = 68;

export type BalanceInfoProps = {
  goToWithdraw: () => void;
};

const TUTORIAL_KEY: StackElementKey = 'BALANCE';

export default observer(function BalanceInfo({goToWithdraw}: BalanceInfoProps) {
  const {dashboardStore} = useRoot();
  const theme = useTheme();
  const [ref, onLayout, getForceKey] = useTutorialMeasure(TUTORIAL_KEY);
  return (
    <View onLayout={onLayout} key={getForceKey()} ref={ref}>
      <RootView>
        <BtcIconView>
          <BtcIcon />
        </BtcIconView>
        <PressableOpacity onPress={goToWithdraw}>
          <BtcValue>{dashboardStore.balance.btc} BTC</BtcValue>
          <UsdValue>USD {dashboardStore.balance.approxUsd}</UsdValue>
        </PressableOpacity>
        <PayoutView>
          <PressableOpacity onPress={goToWithdraw}>
            <PayoutButton
              Icon={PayoutIcon}
              iconProps={{color: theme.palette.textPrimary}}
            />
          </PressableOpacity>
        </PayoutView>
      </RootView>
    </View>
  );
});

const RootView = variance(View)((theme) => ({
  root: {
    height: BALANCE_INFO_HEIGHT,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    backgroundColor: theme.palette.background,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.border,
  },
}));

const BtcIcon = sized(BtcSvg, 40);
const PayoutIcon = sized(PayoutSvg, 23, 16);

const BtcIconView = variance(View)(() => ({
  root: {
    marginRight: 10,
  },
}));

const BtcValue = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    letterSpacing: 0.04,
    fontSize: 22,
    marginBottom: 1,
    color: theme.palette.textPrimary,
  },
}));

const UsdValue = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 12,
    letterSpacing: 0.034,
    color: theme.palette.textSecondary,
  },
}));

const PayoutView = variance(View)(() => ({
  root: {
    marginLeft: 'auto',
  },
}));

const PayoutButton = variance(Button)(() => ({
  root: {
    width: 60,
    height: 32,
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
}));
