import {Either, error, success} from '../fp';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {SELECTED_ACCOUNT_ID} from './keys';
import {FarmId} from '../ApiStore';

/**
 * @throws {never}
 */
export default async (): Promise<Either<FarmId | null, unknown>> => {
  try {
    const item = await AsyncStorage.getItem(SELECTED_ACCOUNT_ID);
    return item === null
      ? success(null)
      : success(parseInt(item, 10) as FarmId);
  } catch (raw) {
    return error(raw);
  }
};
