import {LogEntity, LogSettings} from '../ApiStore';
import {ReadonlyDeep} from 'type-fest';

export default (logs: LogEntity[], settings: ReadonlyDeep<LogSettings>) =>
  settings.exclude
    ? []
    : logs.filter((_) => {
        const group = settings.groups[_.group_id];
        return (
          !group?.exclude &&
          !group?.exclude_messages.includes(_.message_id as never) // FIXME Fix the cast to never if you can
        );
      });
