import React from 'react';
import {observer} from 'mobx-react-lite';
import {Pressable, View} from 'react-native';
import {useTheme, variance} from '../styling';
import {MinerConfig} from '../ProposalsState/MinerProposalsState';
import {Hashrate} from '../ApiStore';
import ConfigurationsList from './ConfigurationsList';
import ConfigurationListHeader from './ConfigurationListHeader';
import {useIsDesktop} from '../DashboardScreen/hooks/useGetItemLayout';
import {ConfigurationListItemSticky} from './ConfigurationListItem';
import Modal from 'react-native-modal';
import CloseSvg from '../assets/svg/colorless/close.svg';
import {useStrings} from '../Root/hooks';

export type SelectConfigurationModalScreenProps = {
  configurations: MinerConfig[];
  selectedHashrate: Hashrate | undefined;
  onConfigurationPress: (hashrate: Hashrate) => void;
  onClose: () => void;
};

export default observer(function SelectConfigurationModalScreen(
  props: SelectConfigurationModalScreenProps,
) {
  const {onClose, configurations, selectedHashrate, onConfigurationPress} =
    props;
  const isDesktop = useIsDesktop();
  const theme = useTheme();
  const strings = useStrings();

  return isDesktop ? (
    <Modal
      isVisible
      onBackdropPress={onClose}
      onBackButtonPress={onClose}
      hideModalContentWhileAnimating
      useNativeDriver
      propagateSwipe
      deviceHeight={theme.window.height}
      deviceWidth={theme.window.width}>
      <LGRootBodyView>
        <LGRootBodyTopView>
          <CloseButton onPress={onClose}>
            <CloseIcon />
          </CloseButton>
          <ConfigurationListHeader
            title={strings['chooseConfigurationScreen.title']}
          />
        </LGRootBodyTopView>
        <Separator />
        <ConfigurationsList
          configurations={configurations}
          selectedHashrate={selectedHashrate}
          onConfigurationPress={onConfigurationPress}
          Separator={Separator}
        />
      </LGRootBodyView>
    </Modal>
  ) : (
    <SMRootView>
      <ConfigurationsList
        Header={ConfigurationListHeader}
        Separator={Separator}
        StickyListItem={<ConfigurationListItemSticky />}
        configurations={[{} as MinerConfig, ...configurations]}
        selectedHashrate={selectedHashrate}
        onConfigurationPress={onConfigurationPress}
        stickyHeaderIndices={[1]}
      />
    </SMRootView>
  );
});

const CloseIcon = variance(CloseSvg)(
  () => ({
    root: {},
  }),
  (theme) => ({
    width: 16,
    height: 16,
    color: theme.palette.textSecondary,
  }),
);

const CloseButton = variance(Pressable)(() => ({
  root: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
}));

const SMRootView = variance(View)((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.back,
  },
}));

const LGRootBodyView = variance(View)((theme) => ({
  root: {
    width: 700,
    maxHeight: 550,
    margin: 'auto',
    borderRadius: 10,
    backgroundColor: theme.palette.bar,
    overflow: 'hidden',
  },
}));

const LGRootBodyTopView = variance(View)(() => ({
  root: {
    paddingTop: 20,
    paddingHorizontal: 30,
  },
}));

const Separator = variance(View)((theme) => ({
  root: {
    height: 1,
    backgroundColor: theme.palette.border,
  },
}));
