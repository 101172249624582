import React from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import {useStyles} from '../styling';
import SlotSetupButton from '../DashboardScreen/common/SlotSetupButton';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {ActionKey} from '../DashboardScreen/common/SetupButton';
import SlotTitle from '../DashboardScreen/common/SlotTitle';

export interface WorkerSlotHeaderProps {
  worker: ReadonlyDeep<WorkerEntity>;
  onDeleted: () => void;
  warning: boolean;
  disabled: boolean;
}

export default observer(
  ({worker, onDeleted, warning, disabled}: WorkerSlotHeaderProps) => {
    const styles = useStyles((theme) => ({
      root: {
        paddingVertical: 10,
        paddingHorizontal: 10,
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.border,
        backgroundColor: theme.palette.background,
        flexDirection: 'row',
        alignItems: 'center',
      },
      title: {
        ...theme.fontByWeight('700'),
        fontSize: 18,
        lineHeight: 21,
        marginRight: 5,
      },
      left: {
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1,
        flexShrink: 1,
        paddingLeft: 10,
      },
      right: {
        paddingLeft: 10,
      },
    }));
    return (
      <View style={styles.root}>
        <View style={styles.left}>
          <SlotTitle
            worker={worker}
            warning={warning}
            disabled={disabled}
            style={styles.title}
          />
        </View>
        <View style={styles.right}>
          <SlotSetupButton
            hideKeyList={[ActionKey.Move, ActionKey.Details]}
            onDeleted={onDeleted}
            worker={worker}
          />
        </View>
      </View>
    );
  },
);
