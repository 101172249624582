import {Tunnel} from './Tunnel';
import {batchDisposers, Disposer} from '../structure';

export default class HubTunnel<T extends Tunnel> {
  private _tunnels = new Set<T>();

  private _insideListener = false;
  private _deferredTunnels?: Set<T>;

  private _getSafeTunnels() {
    if (this._insideListener) {
      this._deferredTunnels = this._deferredTunnels ?? new Set(this._tunnels);
      return this._deferredTunnels;
    } else {
      return this._tunnels;
    }
  }

  private _applyDeferredChanges() {
    if (this._deferredTunnels) {
      this._tunnels = this._deferredTunnels;
      this._deferredTunnels = undefined;
    }
  }

  connect(tunnel: T) {
    this._getSafeTunnels().add(tunnel);
    return batchDisposers(
      tunnel.listen(async (message) => {
        this._insideListener = true;
        try {
          for (const _ of this._tunnels) {
            if (_ === tunnel) {
              continue;
            }
            _.send(message).then().catch();
          }
        } finally {
          this._insideListener = false;
          this._applyDeferredChanges();
        }
      }),
      (() => {
        this._getSafeTunnels().delete(tunnel);
      }) as Disposer,
    );
  }
}
