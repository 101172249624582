import React, {useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {QuickStartModalScreen} from '../../QuickStartModalScreen';
import {
  useGoToPoolMinerActivatedCallback,
  useNavigationGetIsFocused,
  useSafelyGoBack,
} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useRoot} from '../../Root/hooks';
import {useCommonQuickStartViewState} from '../../QuickStartService';

export default observer(function QuickStartModalBinding({
  navigation,
}: RootStackBindingProps<'QuickStartModal'>) {
  const {quickStartOffer} = useRoot();
  const [isChecked, setIsCheck] = useState(false);
  const safelyGoBack = useSafelyGoBack();
  const getIsFocused = useNavigationGetIsFocused();
  const handleModalClosed = useCallback(async () => {
    await quickStartOffer.dismissModal(isChecked);
    safelyGoBack();
  }, [isChecked, quickStartOffer, safelyGoBack]);
  const skip = useCallback(async () => {
    await quickStartOffer.dismissModal(isChecked);
    safelyGoBack();
  }, [isChecked, quickStartOffer, safelyGoBack]);
  const onBeforePurchase = useCallback(async () => {
    await quickStartOffer.dismissModal(isChecked);
    safelyGoBack();
  }, [isChecked, quickStartOffer, safelyGoBack]);
  const goToPoolMinerActivated = useGoToPoolMinerActivatedCallback((params) =>
    navigation.navigate('PoolMinerActivated', params),
  );
  const goToFinishQuickStart = useCallback(
    (selectedIndex: number, recommendedIndex: number) =>
      navigation.navigate('FinishQuickStart', {
        selectedIndex,
        recommendedIndex,
      }),
    [navigation],
  );
  const {
    selectedItemIndex,
    setSelectedItemIndex,
    items,
    getSelectedItem,
    purchase,
    isLoading,
    trial,
  } = useCommonQuickStartViewState({
    goToPoolMinerActivated,
    goToFinishQuickStart,
    onBeforePurchase,
    getIsFocused,
  });
  return (
    <QuickStartModalScreen
      selectItem={setSelectedItemIndex}
      selectedItemIndex={selectedItemIndex}
      onModalClosed={handleModalClosed}
      onSetting={skip}
      onSubscribe={purchase}
      setIsCheck={setIsCheck}
      isChecked={isChecked}
      isLoading={isLoading}
      items={items}
      subscribeDisabled={getSelectedItem() === undefined}
      trial={trial}
    />
  );
});
