import {Service} from '../structure';
import {action, makeObservable, observable, runInAction} from 'mobx';
import {StorageUtms, UtmNames, Utms, UtmUrlListener} from './UtmUrlListener';
import {define} from '../persistence';
import dayjs from 'dayjs';
import {LocationSource} from '../Location';

const NAMES: UtmNames[] = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_ref',
  'utm_content',
];

const STORAGE_KEY = 'UTM_PARAMS';

export default class UtmUrlListenerService implements UtmUrlListener, Service {
  @observable private _url?: string;
  constructor(
    private readonly _root: {
      readonly locationSource: LocationSource;
    },
  ) {
    makeObservable(this);
  }

  async getUtms() {
    const params = await getStorageUtmParams();
    if (params.success && params.right) {
      return NAMES.reduce((acc, p) => {
        const param = params.right?.[p];
        if (this._isLive(param?.created)) {
          return {...acc, [p]: param?.value};
        }

        return acc;
      }, {} as Utms);
    }

    return {} as Utms;
  }
  private _listenExternalLink() {
    return this._root.locationSource.updates.listen((_) => {
      runInAction(() => (this._url = _));
      this._setParams();
    });
  }

  @action.bound
  private async _getInitialUrl() {
    const url = await this._root.locationSource.getInitial();
    if (url.success && url.right) {
      this._url = url.right;
      this._setParams();
    }
  }

  private async _setParams() {
    const prev = await getStorageUtmParams();
    let newParams = this._prepareParams();
    if (prev.success && prev.right) {
      Object.keys(prev.right).forEach((k: string) => {
        const utmName = k as UtmNames;
        if (
          prev.right?.[utmName].value === newParams[utmName]?.value &&
          this._isLive(prev.right[utmName].created)
        ) {
          newParams[utmName] = prev.right[utmName];
        }
      });
    }
    await setStorageUtmParams(newParams);
  }

  private _prepareParams() {
    const url = new URL(this._url || '');
    const params = new URLSearchParams(url.search);
    const _utms = params.get('ref');
    const utms = _utms?.split('.');
    return NAMES.reduce((acc, n, idx) => {
      const value = utms?.[idx] || params.get(n);
      return value
        ? {
            ...acc,
            [n]: {created: dayjs().valueOf(), value},
          }
        : acc;
    }, {} as Record<UtmNames, {created: number; value: string | null}>);
  }

  private _isLive(time: number | undefined) {
    return dayjs().isBefore(dayjs(time).add(1, 'day'));
  }
  subscribe() {
    this._getInitialUrl();
    return this._listenExternalLink();
  }
}

const [getStorageUtmParams, setStorageUtmParams] =
  define<StorageUtms>(STORAGE_KEY);
