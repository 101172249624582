import {Millisecond} from '../Time';

export default (period?: Millisecond) =>
  period === undefined
    ? (0 as Millisecond)
    : period === 0
    ? INITIAL_PERIOD
    : (Math.min(period * 2, MAX_PERIOD) as Millisecond);

export const INITIAL_PERIOD = 500 as Millisecond;
export const MAX_PERIOD = 30000 as Millisecond;
