import {Json} from '../Json';
import {HeadlessLocalNotifications} from './HeadlessLocalNotifications';
import {Platform} from 'react-native';
import AndroidHeadlessLocalNotificationsImpl from './AndroidHeadlessLocalNotificationsImpl';
import IosHeadlessLocalNotificationsImpl from './IosHeadlessLocalNotificationsImpl';
import WebHeadlessLocalNotificationsImpl from './WebHeadlessLocalNotificationsImpl';
import {SpecialLocation} from '../SpecialLocation';
import {Analytics} from '../Analytics';
import {Location} from '../Location';

export default class HeadlessLocalNotificationsFactory {
  constructor(
    protected readonly _root: {
      readonly json: Json;
      readonly specialLocation: SpecialLocation;
      readonly analytics: Analytics;
      readonly location: Location;
    },
  ) {}

  create(): HeadlessLocalNotifications {
    switch (Platform.OS) {
      case 'android':
        return new AndroidHeadlessLocalNotificationsImpl(this._root);
      case 'ios':
        return new IosHeadlessLocalNotificationsImpl(this._root);
      default:
        return new WebHeadlessLocalNotificationsImpl(this._root);
    }
  }
}
