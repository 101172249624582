import {
  ManagedProposalSubscription,
  SubscriptionCandidateKind,
} from '../../ProposalsState';
import {Offer, PaymentMethod} from '../../InAppOffersService';
import {SubscriptionOffer} from './sections/TotalPriceSection';

export function processMinerSubscriptionProposal(
  candidate: ManagedProposalSubscription<Offer> | undefined,
  method: PaymentMethod,
): SubscriptionOffer | undefined {
  if (!candidate) {
    return undefined;
  }
  if (candidate.kind === SubscriptionCandidateKind.Available) {
    const payment = candidate.offer.payments.get(method);
    if (!payment) {
      return undefined;
    }
    const {
      currency,
      uiPrice,
      discountsDifferencePrice,
      uiDiscountsDifferencePrice,
      discounts,
      uiOldPrice,
      pricePerMonth,
    } = payment;
    return {
      discountsDifferencePrice,
      uiDiscountsDifferencePrice,
      pricePerMonth,
      discounts: discounts ?? [],
      helperPrice: undefined,
      questionMarkVisible: false,
      uiOldPrice,
      kind: SubscriptionCandidateKind.Available,
      currency,
      interval: candidate.offer.interval,
      uiPrice,
    };
  }
  return undefined;
}
