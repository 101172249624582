import React from 'react';
import {observer} from 'mobx-react-lite';
import {Text, View} from 'react-native';
import {useRoot, useStrings} from '../../../../Root/hooks';
import {expr} from 'mobx-utils';
import getTotalWorkersSpeed from '../../../getters/getTotalWorkersSpeed';
import {variance} from '../../../../styling';
import {sized} from '../../../../Svg';
import SpeedSvg from '../../../../assets/svg/colored/speed.svg';
import FarmInformation from '../../../../components/FarmInformation';

export const SLOT_INFORMATION_HEIGHT = 115;
export const SLOT_INFORMATION_DESKTOP_HEIGHT = 65;

export default observer(function SlotInformation() {
  const {dashboardStore, workerStateRegistry} = useRoot();
  const strings = useStrings();
  const workerList = [...dashboardStore.workers.values()];
  const totalHashrate = expr(() =>
    getTotalWorkersSpeed(workerList, workerStateRegistry),
  );
  return (
    <Root>
      <FarmInformationView />
      <HashrateInfo>
        <HashrateInfoTitle>
          {strings['dashboard.panel.totalSpeed']}
        </HashrateInfoTitle>
        <Hashrate>
          <SpeedIcon />
          <HashrateValue>{totalHashrate.toFixed(0)}</HashrateValue>
          <HashrateUnit>H/s</HashrateUnit>
        </Hashrate>
      </HashrateInfo>
    </Root>
  );
});

const SpeedIcon = sized(SpeedSvg, 20);

const Root = variance(View)((theme) => ({
  root: {
    height: SLOT_INFORMATION_HEIGHT,
    ...theme.mediaQuery({
      1000: {
        height: SLOT_INFORMATION_DESKTOP_HEIGHT,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
      },
    }),
  },
}));

const FarmInformationView = variance(FarmInformation)((theme) => ({
  root: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.border,
    ...theme.mediaQuery({
      1000: {
        borderBottomWidth: 0,
        justifyContent: 'space-between',
      },
    }),
  },
}));

const HashrateInfo = variance(View)((theme) => ({
  root: {
    padding: 10,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...theme.mediaQuery({
      1000: {
        justifyContent: 'flex-end',
      },
    }),
  },
}));

const HashrateInfoTitle = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 16,
    ...theme.mediaQuery({
      1000: {
        fontSize: 18,
        lineHeight: 24,
        marginRight: 20,
      },
    }),
  },
}));

const Hashrate = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const HashrateValue = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textPrimary,
    fontSize: 18,
    lineHeight: 22,
    marginLeft: 5,
    ...theme.mediaQuery({
      1000: {
        fontSize: 24,
        lineHeight: 30,
      },
    }),
  },
}));

const HashrateUnit = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('bold'),
    fontSize: 14,
    lineHeight: 22,
    color: theme.palette.textSecondary,
    marginTop: 2,
    marginLeft: 3,
    ...theme.mediaQuery({
      1000: {
        marginTop: 5,
        marginLeft: 6,
      },
    }),
  },
}));
