import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {DimensionValue, Text, View, ViewProps} from 'react-native';

import ChevronRightSvg from '../assets/svg/colorless/chevronRight.svg';
import {Slider} from '@miblanchard/react-native-slider';
import {createStylesHook, useTheme} from '../styling';
import {sized} from '../Svg';

export interface UnitSliderProps extends ViewProps {
  label?: string;
  unit?: string;
  minimumValue: number;
  maximumValue: number;
  step?: number;
  getValue?: () => number;
  onValueChange?: (value: number) => void;
  onSlidingComplete?: (value: number) => void;
  UnitIcon?: React.ComponentType<any>;
  scaling?: ScalingValue;
  helperText?: string | null;
  pointer?: number;
}

export enum ScalingValue {
  One = 1,
  Thousand = 1000,
}
export default observer((props: UnitSliderProps) => {
  const {label, unit, UnitIcon, style, helperText, ...rest} = props;
  const styles = useStyles();
  const theme = useTheme();
  return (
    <View style={[styles.root, style]} {...rest}>
      {label && (
        <View style={styles.labelRow}>
          <View style={styles.labelMarker}>
            <ChevronRightIcon color={theme.palette.primary} />
          </View>
          <Text style={styles.labelText}>{label}</Text>
        </View>
      )}
      <SliderBinding {...props} />
      <PointerBinding {...props} />
      <View style={styles.valueRow}>
        <View style={styles.valueView}>
          <ValueBinding {...props} />
        </View>
        <View style={styles.unitView}>
          {UnitIcon && <UnitIcon />}
          <Text style={styles.unitText}>{unit}</Text>
        </View>
        {helperText ? (
          <View style={styles.helperView}>
            <Text style={styles.helperText}>{helperText}</Text>
          </View>
        ) : null}
      </View>
    </View>
  );
});

const ChevronRightIcon = sized(ChevronRightSvg, 13);

const PointerBinding = observer((props: UnitSliderProps) => {
  const {pointer, minimumValue, maximumValue} = props;
  const styles = useStyles();
  if (
    pointer === undefined ||
    maximumValue === undefined ||
    minimumValue === undefined
  ) {
    return null;
  }
  const left = ((pointer - minimumValue) / (maximumValue - minimumValue)) * 100;
  return (
    <View style={styles.triangleView}>
      <View
        style={[
          styles.triangle,
          {
            left: (left + '%') as DimensionValue,
          },
        ]}
      />
    </View>
  );
});

const SliderBinding = observer((props: UnitSliderProps) => {
  const {
    getValue,
    minimumValue,
    maximumValue,
    step,
    onValueChange,
    onSlidingComplete,
  } = props;
  const theme = useTheme();
  const styles = useStyles();
  const value = getValue?.();
  const maximumTrackTintColor = theme.select(
    theme.chroma(theme.palette.foreground).brighten(5).hex(),
    theme.chroma(theme.palette.background).brighten(1.5).hex(),
  );
  const handleChange = useCallback(
    (_: number | number[]) => {
      if (Array.isArray(_)) {
        onValueChange?.(_[0]);
      }
    },
    [onValueChange],
  );
  const handleSlidingComplete = useCallback(
    (_: number | number[]) => {
      if (Array.isArray(_)) {
        onSlidingComplete?.(_[0]);
      }
    },
    [onSlidingComplete],
  );
  return (
    <Slider
      minimumTrackTintColor={theme.palette.secondary}
      maximumTrackTintColor={maximumTrackTintColor}
      thumbTintColor={theme.palette.secondary}
      thumbStyle={styles.thumb}
      trackStyle={styles.track}
      value={value}
      minimumValue={minimumValue}
      maximumValue={maximumValue}
      step={step}
      onValueChange={handleChange}
      onSlidingComplete={handleSlidingComplete}
    />
  );
});

const ValueBinding = observer((props: UnitSliderProps) => {
  const {getValue} = props;
  const styles = useStyles();
  const realValue = getValue?.();
  if (realValue !== undefined) {
    let value;
    let unit = '';
    switch (props.scaling) {
      case ScalingValue.Thousand:
        if (realValue > 1000) {
          value = realValue / 1000;
          unit = 'k';
        } else {
          value = realValue;
        }
        break;
      case ScalingValue.One:
      default:
        value = realValue;
    }
    return (
      <Text style={styles.valueText}>
        {value?.toFixed(0)}
        {unit}
      </Text>
    );
  }
  return null;
});

const useStyles = createStylesHook((theme) => ({
  root: {},
  labelRow: {
    flexDirection: 'row',
  },
  labelMarker: {
    alignSelf: 'center',
    marginStart: -0.5,
    marginEnd: 9.5,
  },
  labelText: {
    flexGrow: 1,
    flexShrink: 1,
    ...theme.fontByWeight(),
    fontSize: 16,
    lineHeight: 22,
    color: theme.palette.textPrimary,
  },
  track: {
    height: 6,
  },
  thumb: {
    width: 18,
    height: 18,
  },
  valueView: {
    minWidth: 110,
    padding: 4,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: theme.palette.border,
  },
  valueRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  valueText: {
    ...theme.fontByWeight('bold'),
    fontSize: 22,
    lineHeight: 28,
    textAlign: 'center',
    color: theme.palette.textPrimary,
  },
  unitView: {
    flexGrow: 1,
    flexShrink: 1,
    marginStart: 15,
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 4,
  },
  unitText: {
    ...theme.fontByWeight(),
    fontSize: 16,
    lineHeight: 22,
    letterSpacing: 0.0457143,
    color: theme.palette.textPrimary,
  },
  helperView: {},
  helperText: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    fontSize: 10,
    lineHeight: 11,
  },
  triangleView: {
    marginBottom: 5,
    marginTop: -5,
  },
  triangle: {
    width: 0,
    transform: [{translateX: -5}],
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderRightWidth: 5,
    borderBottomWidth: 8,
    borderLeftWidth: 5,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: theme.palette.border,
    borderLeftColor: 'transparent',
  },
}));
