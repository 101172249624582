import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import {Text} from 'react-native';
import React from 'react';
import {useWorkerState} from '../../WorkerStateRegistry';
import getMiningSpeed from '../getters/getMiningSpeed';

export interface WorkerHashrateProps {
  worker: ReadonlyDeep<WorkerEntity>;
}

export default observer((props: WorkerHashrateProps) => {
  const {worker} = props;
  const state = useWorkerState(worker.id);
  const hashrate = expr(() => getMiningSpeed(worker, state));
  return <Text>{hashrate.toFixed(0)}</Text>;
});
