import {PurchaseDiscount, PurchaseDiscountEvents} from './PurchaseDiscount';
import {RouterImpl, Service} from '../structure';
import {Either, error} from '../fp';
import {DefaultError} from '../JsonRpc';
import {CommonError, VerifyPromoCodeResponse} from '../ApiStore';

export default class StubPurchaseDiscountService
  implements PurchaseDiscount, Service
{
  readonly discount = undefined;
  public readonly events = new RouterImpl<PurchaseDiscountEvents>();
  readonly isInitialized = true;

  static readonly ERROR = {
    code: -1,
    message: 'This platform is not supported',
  };

  applyDiscount() {}

  deleteDiscount() {}

  hasPromoCode = false;

  async verifyPromoCode(): Promise<
    Either<VerifyPromoCodeResponse, CommonError | DefaultError>
  > {
    return error(StubPurchaseDiscountService.ERROR as unknown as DefaultError);
  }

  async verifyAndApplyCodeWithTtl() {}

  subscribe() {
    return undefined;
  }
}
