import {Service} from '../structure';
import {Log} from './Log';
import {MessageDescription, Messaging} from '../Messaging';
import MessageContextLogContent from './MessageContextLogContent';

export default class MessagingLogBridgeService implements Service {
  constructor(
    private readonly _root: {readonly messaging: Messaging; readonly log: Log},
  ) {}

  private readonly _onMessage = (_: MessageDescription) => {
    this._root.log.write(new MessageContextLogContent(_));
  };

  subscribe() {
    return this._root.messaging.income.listen(this._onMessage);
  }
}
