import {CryptoResponse, IapResponse} from './PurchaseRequester';

export * from './PurchaseRequester';
export {default as PurchaseRequesterFactoryImpl} from './PurchaseRequesterFactoryImpl';
export {default as usePurchasePoolMiner} from './usePurchasePoolMiner';
export {default as usePurchaseSlotOffer} from './usePurchaseSlotOffer';
export {default as useCheckCanMinerBuy} from './useCheckCanMinerBuy';
export {default as useRenewPoolMiner} from './useRenewPoolMiner';
export enum PaymentType {
  ExternalOrder,
  InAppCompleted,
}

export type ContextPurchaseResponse =
  | ({
      kind: PaymentType.ExternalOrder;
    } & CryptoResponse)
  | ({
      kind: PaymentType.InAppCompleted;
    } & IapResponse);
