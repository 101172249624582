import React, {PropsWithChildren, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {ScrollView, ScrollViewProps, View} from 'react-native';
import {DesktopWebAppFooter} from '../DesktopWebAppFooter';
import {defaultContainerStyle} from '../components/Grid';

interface PageScrollViewProps extends ScrollViewProps {
  withFooter?: boolean;
}

export default observer((props: PropsWithChildren<PageScrollViewProps>) => {
  const {
    children,
    contentContainerStyle,
    style,
    withFooter = true,
    ...rest
  } = props;
  const styles = useMemo(
    () =>
      ({
        scroll: {
          flexGrow: 1,
          flexShrink: 1,
        },
        content: {
          flexGrow: 1,
        },
        innerContent: {
          flexGrow: 1,
        },
      } as const),
    [],
  );
  return (
    <ScrollView
      style={[styles.scroll, style]}
      contentContainerStyle={styles.content}
      {...rest}>
      <View
        style={[
          styles.innerContent,
          defaultContainerStyle,
          contentContainerStyle,
        ]}>
        {children}
      </View>
      {withFooter && <DesktopWebAppFooter />}
    </ScrollView>
  );
});
