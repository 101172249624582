import {Core} from '../Core';
import {Platform} from 'react-native';
import {Root} from './Root';
import {Service} from '../structure';
import WebRootService from './WebRootService';
import MobileRootService from './MobileRootService';

export default class RootServiceFactory {
  constructor(protected readonly _core: Core) {}

  create(): Root & Service {
    if (Platform.OS === 'web') {
      return new WebRootService(this._core);
    } else {
      return new MobileRootService(this._core);
    }
  }
}
