import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../../../../styling';
import {View} from 'react-native';
import {Button, ButtonVariant, PressableOpacity} from '../../../../components';
import {useStrings} from '../../../../Root/hooks';
import useTutorialMeasure from '../../../../InteractiveTutorial/useTutorialMeasure';
import {StackElementKey} from '../../../../InteractiveTutorial';
import PlusSvg from '../../../../assets/svg/colorless/plus.svg';
import {sized} from '../../../../Svg';

export type AddWorkerProps = {
  goToAddWorker: () => void;
};

const TUTORIAL_KEY: StackElementKey = 'ADD_WORKER';

export default observer(function AddWorker({goToAddWorker}: AddWorkerProps) {
  const strings = useStrings();
  const [ref, onLayout, getForceKey] = useTutorialMeasure(TUTORIAL_KEY);
  return (
    <Root>
      <PressableOpacity
        onPress={goToAddWorker}
        ref={ref}
        key={getForceKey()}
        onLayout={onLayout}>
        <Button variant={ButtonVariant.Highlighted} Icon={PlusIcon}>
          {strings['dashboard.panel.button']}
        </Button>
      </PressableOpacity>
    </Root>
  );
});

const PlusIcon = sized(PlusSvg, 12);

const Root = variance(View)(() => ({
  root: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    marginLeft: 'auto',
  },
}));
