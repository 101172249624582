import React from 'react';
import {ButtonColor, ButtonVariant} from '../components';
import {useStrings} from '../Root/hooks';
import ActionModal from '../modals/ActionModal';
import WarningSvg from '../assets/svg/circled/warning.svg';
import {createStylesHook} from '../styling';
import Markdown from '../components/Markdown';

interface DiscardUnsavedChangesModalProps {
  onDismiss: () => void;
  onConfirm: () => void;
  ownerName?: string;
}

export default (props: DiscardUnsavedChangesModalProps) => {
  const {onDismiss, onConfirm} = props;
  const strings = useStrings();
  const styles = useStyles();
  const description = props.ownerName
    ? strings['modal.discardUnsaved.descriptionWithName'].replace(
        '{name}',
        props.ownerName,
      )
    : strings['modal.discardUnsaved.description'];

  return (
    <ActionModal
      onDismiss={onDismiss}
      buttons={[
        {
          text: strings['action.cancel'],
          onPress: onDismiss,
          color: ButtonColor.Default,
          variant: ButtonVariant.Contained,
        },
        {
          text: strings['action.confirm'],
          onPress: onConfirm,
          color: ButtonColor.Error,
          variant: ButtonVariant.Highlighted,
        },
      ]}
      Icon={WarningSvg}
      title={strings['modal.discardUnsaved.title']}>
      <Markdown
        styles={{
          paragraph: styles.paragraph,
        }}>
        {description}
      </Markdown>
    </ActionModal>
  );
};

const useStyles = createStylesHook((theme) => ({
  paragraph: {
    ...theme.fontByWeight(),
    fontSize: 14,
    lineHeight: 22,
    color: theme.palette.textSecondary,
    paddingHorizontal: 15,
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 0,
  },
}));
