export const DEFAULT_SLOTS_SHOWN = 20;
export const SLOTS_SHOWN_STEP = 20;
export const SIMPLE_GROUP_ID = 0;

export const GROUP_HEADER_HEIGHT = 175;
export const DESKTOP_GROUP_HEADER_HEIGHT = 152;
export const EMPTY_GROUP_HEADER_HEIGHT = 120;

export const WORKER_HEIGHT = 330;
export const WORKER_DESKTOP_HEIGHT = 150;

export const GROUP_FOOTER_HEIGHT = 120;

export const DASHBOARD_HEADER_BANNER_SECTION_HEIGHT = 85;
export const DASHBOARD_MOBILE_ADS_HEIGHT = 60;
export const DASHBOARD_DESKTOP_HEADER_BANNER_SECTION_HEIGHT = 125;

export const ADD_SLOTS_HEIGHT = 85;
export const ADVANCED_HEIGHT = 70;
export const CREATE_GROUP_HEIGHT = 280;
export const DESKTOP_CREATE_GROUP_HEIGHT = 340;
export const STORE_BANNER_HEIGHT = 560;
export const DESKTOP_STORE_BANNER_HEIGHT = 730;
export const DESKTOP_WEB_APP_FOOTER_HEIGHT = 452;
