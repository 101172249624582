import {BusSource} from '../Bus';
import {BusHelper} from './BusHelper';
import {CancellablePromise} from '../../CancellablePromise';
import {Either, error, success} from '../../fp';

export default class BusHelperImpl<T> implements BusHelper<T> {
  constructor(private readonly _source: BusSource<T>) {}

  when(
    condition?: (current: T) => boolean,
  ): CancellablePromise<Either<T, unknown>> {
    let cancel: () => void;
    const promise = new Promise((resolve) => {
      const listener = (current: T) => {
        if (condition === undefined || condition(current)) {
          this._source.forget(listener);
          resolve(success(current));
        }
      };
      cancel = () => {
        this._source.forget(listener);
        resolve(error(new Error('BusHelper#when has been cancelled')));
      };
      this._source.listen(listener);
    }) as CancellablePromise<Either<T, unknown>>;
    promise.cancel = () => cancel();
    return promise;
  }
}
