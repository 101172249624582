import {Connection} from './Connection';
import {ConnectionManager} from './ConnectionManager';
import {batchDisposers, Service} from '../structure';
import {SUCCESS, FAIL} from './WatchTarget';
import {computed, createAtom, makeObservable} from 'mobx';

export default class ConnectionService implements Connection, Service {
  private _justInitialized = createAtom('ConnectionService#isConnected');
  private _isInitialized = false;

  constructor(
    private readonly _root: {
      readonly connectionManager: ConnectionManager;
    },
  ) {
    makeObservable(this);
  }

  get isConnected() {
    return this._root.connectionManager.isConnected;
  }

  @computed
  get isInitialized() {
    this._justInitialized.reportObserved();
    this._isInitialized = this._isInitialized || this.isConnected;
    return this._isInitialized;
  }

  private readonly _onInitialize = () => {
    this._justInitialized.reportChanged();
  };

  subscribe() {
    return batchDisposers(
      this._root.connectionManager.sockets.once(SUCCESS, this._onInitialize),
      this._root.connectionManager.sockets.once(FAIL, this._onInitialize),
    );
  }
}
