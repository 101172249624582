import {Location} from './Location';
import {Linking} from 'react-native';
import {Url} from '../units';
import {success, error, Either} from '../fp';

export default class HeadlessLocationImpl implements Location {
  async open(locator: Url): Promise<Either<void, unknown>> {
    try {
      await Linking.openURL(locator);
      return success(undefined);
    } catch (raw) {
      return error(raw);
    }
  }
}
