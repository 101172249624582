import React from 'react';
import {Text, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../styling';

export interface DefaultEnvironmentItemProps {
  selected: boolean;
  url: string;
  onPress: () => void;
  right?: React.ReactNode;
}

export default observer((props: DefaultEnvironmentItemProps) => {
  const {selected, url, onPress, right} = props;
  const styles = useStyles((theme) => ({
    root: {
      marginEnd: 16,
      flexDirection: 'row',
      alignItems: 'center',
    },
    url: {
      flex: 1,
      padding: 16,
      ...theme.fontByWeight(),
      color: theme.palette.textPrimary,
    },
    urlSelected: {
      ...theme.fontByWeight('bold'),
      color: theme.palette.secondary,
    },
  }));
  return (
    <View style={styles.root}>
      <Text
        style={[styles.url, selected && styles.urlSelected]}
        onPress={onPress}
        numberOfLines={1}
        ellipsizeMode="tail">
        {url}
      </Text>
      {right}
    </View>
  );
});
