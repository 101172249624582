import {
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo, useState} from 'react';
import Svg, {Circle, G, Path} from 'react-native-svg';

import normalize from '../../../../../GraphPanel/normalize';
import bezierPath from '../../../../../GraphPanel/bezierPath';
import Tooltip from './Tooltip';
import clamp from '../../../../../utils/clamp';
import {Millisecond} from '../../../../../utils/time';
import asymptote from './asymptote';
import ChartPanGestureHandler from '../../../../../GraphPanel/ChartPanGestureHandler';
import {useTheme} from '../../../../../styling';
import useLayout from '../../../../../ReactNativeUtil/useLayout';

export interface ChartViewProps extends ViewProps {
  series: readonly number[];
  from: Millisecond;
  to: Millisecond;
  svgStyle?: StyleProp<ViewStyle>;
}

export default observer(
  ({
    series: timeSeries,
    from: fromMillis,
    to: toMillis,
    svgStyle,
    ...rest
  }: ChartViewProps) => {
    const [layout, onLayout] = useLayout();
    const width = layout?.width ?? 0;
    const height = layout?.height ?? 0;
    let [minValue, maxValue, normalizedData] = normalize(timeSeries);

    if (minValue === maxValue) {
      normalizedData = asymptote(minValue, normalizedData.length);
    }
    const theme = useTheme();
    const styles = useMemo(
      () =>
        ({
          tooltip: {
            margin: 10,
            position: 'absolute',
            borderWidth: 1,
            borderColor: theme.palette.border,
            ...theme.mediaQuery({
              769: {
                ...((Platform.select({
                  web: {
                    pointerEvents: 'none',
                  },
                }) || {}) as ViewStyle),
              },
            }),
          },
        } as const),
      [theme],
    );

    const [_selectedIndex, setSelectedIndex] = useState<number>();
    const selectedIndex =
      _selectedIndex === undefined
        ? undefined
        : clamp(_selectedIndex, 0, timeSeries.length);
    const onGestureEvent = useCallback(
      (offsetX: number, isActive: boolean) => {
        if (isActive) {
          const maxIndex = normalizedData.length - 1;
          const rawIndex = (offsetX / width) * maxIndex;
          const clampedIndex = Math.max(
            0,
            Math.min(maxIndex, Math.round(rawIndex)),
          );
          setSelectedIndex(clampedIndex);
        }
      },
      [normalizedData.length, width],
    );
    const hideTooltip = useCallback(() => setSelectedIndex(undefined), []);

    let selectedX,
      selectedY,
      tooltipLeft,
      tooltipTop,
      tooltipRight,
      tooltipBottom;
    if (selectedIndex !== undefined && layout) {
      selectedX = selectedIndex * (width / (normalizedData.length - 1));
      selectedY = height * (1 - normalizedData[selectedIndex]);
      if (selectedX < width / 2) {
        tooltipLeft = selectedX;
      } else {
        tooltipRight = layout.width - selectedX;
      }
      if (selectedY < height / 1.7) {
        tooltipTop = selectedY;
      } else {
        tooltipBottom = layout.height - selectedY;
      }
    }

    return (
      <>
        <View onLayout={onLayout} {...rest}>
          {layout && (
            <>
              <Svg
                viewBox={`0 0 ${layout.width} ${layout.height}`}
                fill="none"
                style={svgStyle}>
                <G>
                  <Path
                    d={bezierPath(width, height, normalizedData)}
                    stroke={theme.palette.primary}
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  {selectedIndex !== undefined && (
                    <Circle
                      cx={selectedX}
                      cy={selectedY}
                      r={6}
                      fill={theme.palette.primary}
                      stroke={theme.palette.background}
                      strokeWidth={3}
                    />
                  )}
                </G>
              </Svg>
              <ChartPanGestureHandler
                onMouseLeave={hideTooltip}
                onGestureEvent={onGestureEvent}
                style={StyleSheet.absoluteFillObject}
              />
            </>
          )}
        </View>
        {selectedIndex !== undefined && (
          <Tooltip
            style={[
              styles.tooltip,
              {
                left: tooltipLeft,
                top: tooltipTop,
                right: tooltipRight,
                bottom: tooltipBottom,
              },
            ]}
            timestamp={
              fromMillis +
              selectedIndex *
                ((toMillis - fromMillis) / (timeSeries.length - 1))
            }
            value={timeSeries[selectedIndex]}
          />
        )}
      </>
    );
  },
);
