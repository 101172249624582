import {useMemo} from 'react';

import {useTheme} from '../../styling';

export default () => {
  const theme = useTheme();
  return useMemo(
    () =>
      ({
        root: {
          width: 300,
          backgroundColor: theme.palette.background,
          borderWidth: 1,
          borderColor: theme.palette.border,
          ...theme.fontByWeight(),
        },
      } as const),
    [theme],
  );
};
