import {InAppOffers} from '../InAppOffersService';
import {Platform} from 'react-native';
import WebRenewAccessScreenStateImpl from './WebRenewAccessScreenStateImpl';
import MobileRenewAccessScreenStateImpl from './MobileRenewAccessScreenStateImpl';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import {ProposalsState} from '../ProposalsState';
import {RenewAccessScreenState} from './RenewAccessScreenState';
import {Service} from '../structure';

export default class RenewAccessScreenStateFactory {
  constructor(
    private readonly _root: {
      readonly dashboardStore: DashboardStore;
      readonly proposalsState: ProposalsState;
      readonly inAppOffers: InAppOffers;
    },
    readonly workerId: string,
  ) {}

  create() {
    return Platform.select<RenewAccessScreenState & Service>({
      web: new WebRenewAccessScreenStateImpl(this._root, this.workerId),
      default: new MobileRenewAccessScreenStateImpl(this._root, this.workerId),
    });
  }
}
