import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, StyleSheet} from 'react-native';

import MovingPuzzle from './MovingPuzzle';
import Slider from './Slider';
import TargetPuzzle from './TargetPuzzle';
import BackgroundImage from './BackgroundImage';
import {useRoot} from '../Root/hooks';

export default observer(() => {
  const {
    poolMinerActivator: {puzzle},
  } = useRoot();
  return (
    <View style={styles.root}>
      <View
        style={{
          width: puzzle.puzzleWidth,
          height: puzzle.puzzleHeight,
        }}>
        <BackgroundImage />
        <TargetPuzzle />
        <MovingPuzzle />
      </View>
      <Slider />
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
  },
});
