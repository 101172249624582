import {AuthenticationErrorReason, AuthenticationSummary} from './AuthState';
import restoreFernetToken from './restoreFernetToken';
import {setFernetToken} from '../persistence';
import {ApiStore} from '../ApiStore';
import {DeviceIdentification} from '../DeviceIdentification';
import {InAppPurchaseManager} from '../InAppPurchaseManager';
import {SpecialLocation} from '../SpecialLocation';
import {LocationSource} from '../Location';

export default async (
  fernetToken: string,
  root: {
    readonly deviceIdentification: DeviceIdentification;
    readonly apiStore: ApiStore;
    readonly inAppPurchaseManager: InAppPurchaseManager;
    readonly specialLocation: SpecialLocation;
    readonly locationSource: LocationSource;
  },
) => {
  const fernetToken_ = await restoreFernetToken(root, {
    forceRegister: true,
    fernetToken,
  });
  if (!fernetToken_.success) {
    return {
      kind: 'AuthenticationFailed',
      reason: fernetToken_.left.reason,
      raw:
        fernetToken_.left.reason ===
        AuthenticationErrorReason.AccountSwitchRequired
          ? new Error('Unexpected error')
          : fernetToken_.left.raw,
    };
  }
  const {summary, fernetToken: nextFernetToken} = fernetToken_.right;
  if (summary !== AuthenticationSummary.LocalStorage) {
    await setFernetToken(nextFernetToken);
  }
  return {
    kind: 'Authenticated',
    fernetToken: nextFernetToken,
    summary,
  };
};
