import {LogContent} from './LogContent';

export default class WebSocketCloseLogContent implements LogContent {
  constructor(
    public readonly url: string,
    public readonly connectionId: number,
    public readonly event: WebSocketCloseEvent | CloseEvent,
  ) {}

  get body() {
    const wasClean =
      (this.event as CloseEvent).wasClean ??
      (this.event as WebSocketCloseEvent).message === undefined;
    const message = (this.event as WebSocketCloseEvent).message;
    const {code, reason} = this.event;
    const lines = [
      `#${this.connectionId} Closed ${wasClean ? 'cleanly' : 'dirtily'}`,
    ];
    if (code !== undefined) {
      lines.push(`Code: ${code}`);
    }
    if (reason !== undefined) {
      if (reason) {
        lines.push(`Reason: ${reason}`);
      } else {
        lines.push('An empty reason was specified');
      }
    }
    if (message !== undefined) {
      if (message) {
        lines.push(`Message: ${message}`);
      } else {
        lines.push('An empty message was specified');
      }
    }
    return lines.join('\n');
  }
}
