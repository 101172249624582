import {Router} from '../structure';
import {CommonError, DiscountCode, VerifyPromoCodeResponse} from '../ApiStore';
import {Either} from '../fp';
import {DefaultError} from '../JsonRpc';
import {Second} from '../Time';

export type ParsedUrlDiscountParams = {
  dd: string | null;
  code: DiscountCode;
};

export enum DiscountSource {
  Connect,
  User,
  Url,
  Referrer,
}

export type Discount = {
  code: DiscountCode;
  expirationDate?: string;
  ttl: null;
  kind: DiscountSource;
};

export interface PurchaseDiscount {
  readonly discount: Discount | undefined;
  readonly isInitialized: boolean;
  readonly events: Router<PurchaseDiscountEvents>;
  readonly hasPromoCode: boolean;
  verifyPromoCode: (
    code: DiscountCode,
  ) => Promise<Either<VerifyPromoCodeResponse, CommonError | DefaultError>>;
  deleteDiscount: () => void;
  verifyAndApplyCodeWithTtl: (
    code: DiscountCode,
    ttl?: Second,
  ) => Promise<void>;
  applyDiscount: (discount: Discount) => void;
}

export const PURCHASE_PROMO_REMOVED = Symbol();
export const PURCHASE_PROMO_APPLIED = Symbol();

export type PurchaseDiscountEvents = {
  [PURCHASE_PROMO_REMOVED]: void;
  [PURCHASE_PROMO_APPLIED]: Discount;
};
