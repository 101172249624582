import React, {useRef} from 'react';
import {TouchableOpacity, View, Text} from 'react-native';
import {observer} from 'mobx-react-lite';

import {useStrings} from '../../Root/hooks';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {expr} from 'mobx-utils';
import getWorkerDescription from '../../DashboardScreen/getters/getWorkerDescription';
import {createStylesHook, useTheme} from '../../styling';
import {CheckBox} from '../../components';
import {useHover} from 'react-native-web-hooks';

export type ScheduleGroupWorkerProps = {
  active: boolean;
  name: string | null;
  worker: ReadonlyDeep<WorkerEntity>;
  onPress: () => void;
  workerTimezone: {
    timezone: number;
    timezoneStr: string;
    differentTimezoneStr: string;
    showTimezone: boolean;
  };
  disabled: boolean;
  index: number;
};

export const ListItem = observer((props: ScheduleGroupWorkerProps) => {
  const {active, workerTimezone, index, name, worker, onPress, disabled} =
    props;
  const specs = expr(() => getWorkerDescription(worker));
  const strings = useStrings();
  const theme = useTheme();
  const styles = useStyles();
  const ref = useRef<TouchableOpacity>(null);
  const isHovered = useHover(ref);

  return (
    <TouchableOpacity
      ref={ref}
      style={[
        styles.root,
        active ? {backgroundColor: theme.palette.background} : {},
        isHovered && styles.hovered,
      ]}
      activeOpacity={1}
      onPress={onPress}>
      <View style={styles.checkboxColumn}>
        {!disabled && (
          <View style={styles.checkBoxCell}>
            <CheckBox onChange={onPress} checked={active} />
          </View>
        )}
      </View>
      <View style={styles.infoColumn}>
        <View style={styles.infoCell}>
          <Text
            style={[
              styles.title,
              active ? {color: theme.palette.textPrimary} : {},
            ]}>
            {name || `${strings['scheduler.worker']} #${index + 1}`}
          </Text>
          {specs ? (
            <Text
              style={[
                styles.description,
                active ? {color: theme.palette.textSecondary} : {},
                isHovered && styles.hoveredText,
              ]}>
              {specs}
            </Text>
          ) : null}
          {workerTimezone.showTimezone ? (
            <View style={styles.timezoneView}>
              <Text style={styles.workerTimezoneText}>
                <Text style={styles.workerTimezoneTextError}>
                  <Text style={styles.timezoneTitle}>
                    {strings['scheduler.timezone']}:
                  </Text>{' '}
                  UTC {workerTimezone.timezoneStr}
                  {'  '}
                </Text>
                <Text style={styles.userTimezoneText}>
                  ({workerTimezone.differentTimezoneStr}{' '}
                  {strings['scheduler.toYourLocalTime']}).
                </Text>
              </Text>
            </View>
          ) : null}
        </View>
      </View>
    </TouchableOpacity>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    maxWidth: '100%',
    paddingVertical: 10,
    paddingLeft: 12,
    paddingRight: 15,
    backgroundColor: theme.palette.background,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: theme.palette.border,
    borderTopWidth: 0,
  },
  hovered: {
    backgroundColor: theme.palette.primary,
  },
  hoveredText: {
    color: theme.palette.additionalPrimary,
  },
  checkboxColumn: {
    width: 25,
  },
  infoColumn: {
    flexShrink: 1,
  },
  checkBoxCell: {
    marginRight: 10,
    marginTop: 4,
  },
  infoCell: {
    display: 'flex',
  },
  title: {
    ...theme.fontByWeight('700'),
    letterSpacing: 0.04,
    lineHeight: 22,
    fontSize: 14,
    color: theme.palette.textSecondary,
  },
  description: {
    lineHeight: 18,
    fontSize: 10,
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    marginRight: 20,
  },
  timezoneView: {
    marginTop: 4,
  },
  timezoneTitle: {
    ...theme.fontByWeight('700'),
  },
  workerTimezoneText: {
    fontSize: 11,
  },
  workerTimezoneTextError: {
    ...theme.fontByWeight('400'),
    color: theme.palette.error,
  },
  userTimezoneText: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
  },
}));
