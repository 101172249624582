import React, {useCallback} from 'react';
import ActionModal from '../../modals/ActionModal';
import TrashSvg from '../../assets/svg/circled/trash.svg';
import {useRoot, useStrings} from '../../Root/hooks';
import {WorkerEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {ReadonlyDeep} from 'type-fest';
import {observer} from 'mobx-react-lite';
import getWorkerName from '../getters/getWorkerName';
import {ButtonColor, ButtonVariant} from '../../components';
import {useTemplates} from '../../Root/hooks';
import {useHandleErrorAndNavigate} from '../../ExceptionHandler';

interface RemoveWorkerModalProps {
  worker: ReadonlyDeep<WorkerEntity>;
  onDismiss: () => void;
  onDeleted?: () => void;
}

export default observer(
  ({worker, onDismiss, onDeleted = () => {}}: RemoveWorkerModalProps) => {
    const {connectedClient} = useRoot();
    const strings = useStrings();
    const templates = useTemplates();
    const handleErrorAndNavigate = useHandleErrorAndNavigate();
    const onSubmit = useCallback(async () => {
      const result = await connectedClient.call('delete_worker', {
        worker_id: worker.id,
      });
      if (!result.success) {
        handleErrorAndNavigate(result.left);
        return;
      }
      onDismiss();
      onDeleted();
    }, [
      connectedClient,
      handleErrorAndNavigate,
      onDeleted,
      onDismiss,
      worker.id,
    ]);
    const message = `${templates['modal.removeWorker.descriptionWithName']({
      name: getWorkerName(worker),
    })}`;

    return (
      <ActionModal
        onDismiss={onDismiss}
        buttons={[
          {
            text: strings['action.cancel'],
            onPress: onDismiss,
            color: ButtonColor.Default,
            variant: ButtonVariant.Contained,
          },
          {
            text: strings['action.confirm'],
            onPress: onSubmit,
            variant: ButtonVariant.Highlighted,
          },
        ]}
        Icon={TrashSvg}
        title={strings['modal.removeWorker.title']}>
        {message}
      </ActionModal>
    );
  },
);
