import {HeadlessRoot, HeadlessRootImpl} from './HeadlessRoot';
import messaging from '@react-native-firebase/messaging';
import {FIREBASE_CONFIG, MessageDescription} from './Messaging';
import notifee, {Event, EventType} from './Notifee';
import {Platform} from 'react-native';
import {
  isLocalNotificationData,
  LocalNotificationId,
} from './LocalNotifications';
import {ActionId} from './units';
import {initializeApp} from './WebFirebase/app';
import {MobileHeadlessLocalNotificationsImpl} from './LocalNotifications';
import {AndroidChannelRepositoryImpl} from './LocalNotifications';
import {IosNotificationPermissionsImpl} from './NotificationPermissions';

export default () => {
  if (Platform.OS === 'web') {
    initializeApp(FIREBASE_CONFIG);
    return;
  }
  let headlessRoot: HeadlessRoot | undefined;
  const getRoot = () => {
    headlessRoot = headlessRoot ?? new HeadlessRootImpl();
    return headlessRoot;
  };
  messaging().setBackgroundMessageHandler(async (firebaseMessage) => {
    if (firebaseMessage.notification || !firebaseMessage.data) {
      return;
    }
    const root = getRoot();
    const description = firebaseMessage.data as MessageDescription;
    const handle_ = await root.messageHandler.handle(description);
    if (!handle_.success) {
      throw handle_.left;
    }
  });
  const channelRepository = new AndroidChannelRepositoryImpl();
  // noinspection JSIgnoredPromiseFromCall
  channelRepository.createChannels();
  const notificationPermissions = new IosNotificationPermissionsImpl();
  // noinspection JSIgnoredPromiseFromCall
  notificationPermissions.ask();
  const handler = async (event: Event): Promise<void> => {
    const {data, android} = event.detail.notification ?? {};
    const {groupSummary, groupId} = android ?? {};
    if (
      (event.type === EventType.PRESS ||
        event.type === EventType.ACTION_PRESS) &&
      groupSummary &&
      groupId
    ) {
      const records = await notifee.getDisplayedNotifications();
      const members = records.filter(
        (_) =>
          _.notification.android?.groupId === groupId &&
          !_.notification.android.groupSummary,
      );
      const target = members[members.length - 1];
      if (target) {
        return handler({
          ...event,
          detail: {
            ...event.detail,
            notification: target.notification,
            pressAction: target.notification.android?.pressAction,
          },
        });
      } else {
        const {id} = event.detail.notification ?? {};
        if (id) {
          await notifee.cancelDisplayedNotification(id);
        }
      }
    }
    const type = MobileHeadlessLocalNotificationsImpl.EVENT_TYPE_MAP.get(
      event.type,
    );
    if (!data || !isLocalNotificationData(data) || !type) {
      return;
    }
    const root = getRoot();
    const handle_ = await root.headlessLocalNotifications.handle({
      id: event.detail.notification?.id as LocalNotificationId | undefined,
      type,
      data,
      actionId: event.detail.pressAction?.id as ActionId | undefined,
    });
    if (!handle_.success) {
      throw handle_.left;
    }
  };
  notifee.onBackgroundEvent(handler);
};
