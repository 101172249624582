import {Url} from '../../../../src/units';

export default class HttpError extends Error {
  constructor(
    public readonly details: {
      readonly status: number;
      readonly statusText: string;
      readonly url: Url;
    },
  ) {
    super('Http method method failed');
    if (new.target) {
      Object.setPrototypeOf(this, new.target.prototype);
    }
    this.name = HttpError.name;
  }

  static fromResponse(_: Response) {
    return new HttpError({
      status: _.status,
      statusText: _.statusText,
      url: _.url as Url,
    });
  }
}
