import {Scheduler, TICK} from './Scheduler';
import {Disposer, RouterImpl, Service} from '../structure';
import {SchedulerSequence} from './SchedulerSequence';
import {Millisecond} from '../Time';

export default class SchedulerImpl implements Scheduler, Service {
  private _period: Millisecond;

  constructor(private readonly _getPeriod: SchedulerSequence) {
    this._period = this._getPeriod();
  }

  private _timeout?: ReturnType<typeof setTimeout>;
  public readonly ticks = new RouterImpl<{[TICK]: void}>();

  private _setNextPeriod() {
    this._period = this._getPeriod(this._period);
  }

  private readonly _onTimeout = () => {
    this.ticks.send(TICK, undefined);
    this._setNextPeriod();
    this._startUnsafe();
  };

  private _startUnsafe() {
    this._timeout = setTimeout(this._onTimeout, this._period);
  }

  start() {
    if (this._timeout === undefined) {
      this._startUnsafe();
    }
  }

  stop() {
    this._period = this._getPeriod();
    if (this._timeout !== undefined) {
      clearTimeout(this._timeout);
      this._timeout = undefined;
    }
  }

  restart() {
    this.stop();
    this._startUnsafe();
  }

  subscribe() {
    return (() => this.stop()) as Disposer;
  }
}
