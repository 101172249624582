import React from 'react';
import {observer} from 'mobx-react-lite';
import FinishQuickStartView, {
  FinishQuickStartViewProps,
} from './FinishQuickStartView';
import Modal from '../modals/BaseModal';
import {StyleSheet} from 'react-native';

export type FinishQuickStartProps = FinishQuickStartViewProps & {
  onModalClosed: () => void;
};

export default observer(function FinishQuickStart(
  props: FinishQuickStartProps,
) {
  const {onModalClosed, ...rest} = props;
  return (
    <Modal contentStyle={styles.content} onDismiss={onModalClosed}>
      <FinishQuickStartView {...rest} />
    </Modal>
  );
});

const styles = StyleSheet.create({
  content: {
    padding: 0,
  },
});
