import React, {useCallback, useMemo, useRef} from 'react';
import {FlatList, Text, View, RefreshControl} from 'react-native';
import {useRoot} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import timestampToString from './timestampToString';
import NotificationLevelCircle from './NotificationLevelCircle';
import {FarmLogEntity} from '../Notifications';
import {ScreenFluidNoData} from '../components/NoData';
import {MoreDataActivityIndicator} from '../components/loaders';
import useScrollHandlers from './useScrollHandlers';
import {ScreenFluidLoader} from '../components/CryptoLoader';
import {useStyles} from '../styling';
import ToTopButton from './ToTopButton';
import {Divider} from '../components/Divider';
import {keyExtractor, ListProps} from './types';
import Markdown from '../components/Markdown';

export default observer(() => {
  const {
    notificationService: {list},
  } = useRoot();
  const scrollRef = useRef<FlatList<FarmLogEntity>>(null);
  const {onScroll, scrollY} = useScrollHandlers(scrollRef);
  const styles = useStyles(() => ({
    contentScroll: {
      flexGrow: 1,
    },
  }));
  const toTop = useCallback(() => {
    scrollRef.current?.scrollToOffset({offset: 0, animated: true});
  }, []);
  if (list.isLoading) {
    return <ScreenFluidLoader />;
  }
  return (
    <>
      <FlatList
        ItemSeparatorComponent={Divider}
        ref={scrollRef}
        refreshControl={
          <RefreshControl
            refreshing={list.isLoading}
            onRefresh={list.refresh}
          />
        }
        ListEmptyComponent={<ScreenFluidNoData />}
        ListFooterComponent={
          list.isMoreLoading ? <MoreDataActivityIndicator /> : null
        }
        data={list.items}
        contentContainerStyle={styles.contentScroll}
        onEndReached={list.fetchNext}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        onScroll={onScroll}
      />
      {scrollY > 300 && <ToTopButton onPress={toTop} />}
    </>
  );
});

const renderItem: ListProps['renderItem'] = ({item}) => (
  <NotificationView item={item} />
);

interface NotificationViewProps {
  item: FarmLogEntity;
}

const NotificationView = observer(({item}: NotificationViewProps) => {
  const {translation} = useRoot();
  const {localeTag} = translation;
  const dateString = useMemo(
    () => timestampToString(item.timestamp, localeTag),
    [item.timestamp, localeTag],
  );
  const styles = useStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingHorizontal: 20,
      paddingTop: 15,
      paddingBottom: 5,
      backgroundColor: theme.palette.background,
    },
    header: {
      flexDirection: 'row',
      alignItems: 'center',
      width: 200,
    },
    dateText: {
      ...theme.fontByWeight('400'),
      color: theme.palette.textSecondary,
      letterSpacing: 0.04,
      fontSize: 13,
      marginLeft: 10,
    },
    paragraph: {
      ...theme.fontByWeight('400'),
      fontSize: 13,
      lineHeight: 22,
      letterSpacing: 0.04,
      color: theme.palette.textPrimary,
      marginTop: 0,
      marginBottom: 0,
    },
  }));

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <NotificationLevelCircle level={item.level} />
        <Text style={styles.dateText}>{dateString}</Text>
      </View>
      <Markdown
        styles={{
          paragraph: styles.paragraph,
        }}>
        {`**${item.title}**. ${item.body}`}
      </Markdown>
    </View>
  );
});
