import {SECOND} from '../utils/time';
import {Millisecond} from '../Time';
import selectWorkersById from './selectWorkersById';
import selectTotalBalance from './selectTotalBalance';
import {Translation} from '../Localization';
import {LogController} from './LogController';
import getWorkerSettings from './getWorkerSettings';
import selectMiningResults from './selectMiningResults';
import selectAllWorkersById from './selectAllWorkersById';
import limitMiningResults from './limitMiningResults';
import {Client} from '../JsonRpc';
import {
  CryptoFarmServerCalls,
  CryptoFarmServerNotifications,
  WorkerId,
} from '../ApiStore';
import {MiningController} from './MiningController';
import generateMiningSpeed from './generateMiningSpeed';
import {
  Bitcoin,
  DemonstrationDatabaseRecord,
  WorkerRecord,
  DemonstrationDatabase,
} from './DemonstrationDatabase';
import {MINING_INTERVAL} from './constants';
import {batchDisposers, Service} from '../structure';
import getWorkerEstimatedResult from './getWorkerEstimatedResult';

export default class MiningService implements MiningController, Service {
  constructor(
    private readonly _root: {
      readonly db: DemonstrationDatabase;
      readonly translation: Translation;
    },
    private readonly _notificationController: LogController,
    private readonly _realClient: Client<
      CryptoFarmServerCalls,
      CryptoFarmServerNotifications
    >,
  ) {}

  private async _getRates() {
    const bitcoinPerHash_ = await this._realClient.call(
      'get_current_rate',
      undefined,
    );
    if (!bitcoinPerHash_.success) {
      // TODO Handle error
      console.warn('Cannot get current rate');
      return {
        bitcoinPerHash: 0,
        usdPerBitcoin: 1,
      };
    }
    const {btc, hashes_count, usd} = bitcoinPerHash_.right;
    return {
      bitcoinPerHash: btc / hashes_count,
      usdPerBitcoin: usd / btc,
    };
  }

  async getTotalBalances(
    workerIds: WorkerId[],
  ): Promise<Record<WorkerId, Bitcoin>> {
    const now = new Date().getTime() as Millisecond;
    const {bitcoinPerHash} = await this._getRates();
    if (this._shouldUpdateWorkerStats(now)) {
      await this._updateWorkerStats(now, bitcoinPerHash);
    }
    const db = this._root.db.state;
    const workersById = selectWorkersById(db.groups, workerIds);
    return Object.fromEntries(
      Object.entries(workersById).map(([id, worker]) => [
        id,
        worker.miningResults.reduce(
          (sum, _) => sum + _,
          (db.balanceByWorkerId[id] ?? 0) +
            getWorkerEstimatedResult(
              db.lastWorkerStatsUpdate,
              now,
              worker,
              bitcoinPerHash,
            ),
        ),
      ]),
    );
  }

  async getAccountBalance() {
    const now = new Date().getTime() as Millisecond;
    const {bitcoinPerHash} = await this._getRates();
    if (this._shouldUpdateWorkerStats(now)) {
      await this._updateWorkerStats(now, bitcoinPerHash);
    }
    const db = this._root.db.state;
    return {total: selectTotalBalance(db, now, bitcoinPerHash)};
  }

  async getMiningStatistic(
    workerIds: readonly WorkerId[],
    from: Millisecond,
    to: Millisecond,
    interval: Millisecond,
  ) {
    const now = new Date().getTime() as Millisecond;
    const {bitcoinPerHash} = await this._getRates();
    if (this._shouldUpdateWorkerStats(now)) {
      await this._updateWorkerStats(now, bitcoinPerHash);
    }
    const db = this._root.db.state;
    const workersById = selectWorkersById(db.groups, workerIds);
    return new Map(
      Object.entries(workersById).map(([id, worker]) => [
        id,
        selectMiningResults(
          db.lastWorkerStatsUpdate,
          now,
          db.updateInterval,
          bitcoinPerHash,
          worker,
          from,
          to,
          interval,
        ),
      ]),
    );
  }

  async setUpdateInterval(updateInterval = MINING_INTERVAL as Millisecond) {
    await this._root.db.update(async (db) => ({
      ...db,
      updateInterval,
    }));
  }

  private _shouldUpdateWorkerStats(now: Millisecond) {
    const db = this._root.db.state;
    const ticks = Math.floor(
      (now - db.lastWorkerStatsUpdate) / db.updateInterval,
    );
    return ticks >= 1;
  }

  private async _updateWorkerStats(
    now: Millisecond,
    bitcoinPerHash: BitcoinPerHash,
  ) {
    await this._root.db.update(async (db) => {
      const ticks = Math.floor(
        (now - db.lastWorkerStatsUpdate) / db.updateInterval,
      );
      if (ticks >= 1) {
        const lastWorkerStatsUpdate = (db.lastWorkerStatsUpdate +
          ticks * db.updateInterval) as Millisecond;
        let next: DemonstrationDatabaseRecord = {
          ...db,
          groups: db.groups.map((group) => ({
            ...group,
            workersById: Object.fromEntries(
              Object.entries(group.workersById).map(([id, worker]) => [
                id,
                {
                  ...worker,
                  miningResults: [
                    ...worker.miningResults,
                    ...generateMiningResults(
                      db.lastWorkerStatsUpdate,
                      db.updateInterval,
                      ticks,
                      worker,
                      bitcoinPerHash,
                    ),
                  ],
                  shouldIgnoreScheduleUntil: undefined,
                },
              ]),
            ),
          })),
          lastWorkerStatsUpdate,
        };
        return next;
      }
      return db;
    });
  }

  private _limitMiningResults = () =>
    this._root.db.beforeUpdate(async (previous, current) => {
      const previousWorkersById = selectAllWorkersById(previous.groups);
      const workersById = selectAllWorkersById(current.groups);
      let next = current;
      for (const [id, worker] of Object.entries(workersById)) {
        const prevWorker = previousWorkersById[id];
        if (
          !prevWorker ||
          worker.miningResults.length !== prevWorker.miningResults.length
        ) {
          const {results, balance} = limitMiningResults(
            next.lastWorkerStatsUpdate,
            next.updateInterval,
            worker.miningResults,
          );
          next = {
            ...next,
            groups: next.groups.map((group) => ({
              ...group,
              workersById: Object.fromEntries(
                Object.entries(group.workersById).map(([_id, _worker]) => [
                  _id,
                  _id === id ? {..._worker, miningResults: results} : _worker,
                ]),
              ),
            })),
            balanceByWorkerId: {
              ...next.balanceByWorkerId,
              [id]: (next.balanceByWorkerId[id] ?? 0) + balance,
            },
          };
        }
      }
      return next;
    });

  private _takeDeletedWorkersIntoAccount = () =>
    this._root.db.beforeUpdate(async (previous, current) => {
      const previousWorkersById = selectAllWorkersById(previous.groups);
      const workersById = selectAllWorkersById(current.groups);
      let next = current;
      for (const [id, worker] of Object.entries(previousWorkersById)) {
        const currWorker = workersById[id];
        if (!currWorker) {
          const balance = worker.miningResults.reduce((sum, _) => sum + _, 0);
          next =
            balance > 0
              ? {
                  ...next,
                  balanceByWorkerId: {
                    ...next.balanceByWorkerId,
                    [id]: (next.balanceByWorkerId[id] ?? 0) + balance,
                  },
                }
              : next;
        }
      }
      return next;
    });

  subscribe = () =>
    batchDisposers(
      this._limitMiningResults(),
      this._takeDeletedWorkersIntoAccount(),
    );
}

export type BitcoinPerHash = number;

const generateMiningResults = (
  from: Millisecond,
  interval: Millisecond,
  ticks: number,
  worker: WorkerRecord,
  bitcoinPerHash: BitcoinPerHash,
) => {
  const result: number[] = [];
  for (let tick = 0; tick < ticks; ++tick) {
    const moment = from + tick * interval;
    let settings = getWorkerSettings(
      moment,
      worker.schedulers,
      worker.settings,
      worker.shouldIgnoreScheduleUntil,
    );
    result.push(
      generateMiningSpeed(worker.characteristics.hashrate, settings.speed) *
        (interval / SECOND) *
        bitcoinPerHash,
    );
  }
  return result;
};
