import {Headless} from './Headless';
import {batchDisposers, Disposer, Service} from '../structure';
import {action, flow, observable, makeObservable} from 'mobx';
import {AsyncReturnType} from 'type-fest';
import messaging from '@react-native-firebase/messaging';
import {AppState, AppStateStatus} from 'react-native';

export default class IosHeadlessService implements Headless, Service {
  @observable private _initialized = false;
  @observable private _isHeadless = false;

  constructor() {
    makeObservable(this);
  }

  get initialized() {
    return this._initialized;
  }

  get isHeadless() {
    return this._isHeadless;
  }

  private _load = flow(function* (this: IosHeadlessService) {
    try {
      // noinspection UnnecessaryLocalVariableJS
      const isHeadless: AsyncReturnType<Headless['getIsHeadless']> =
        yield this.getIsHeadless();
      this._isHeadless = isHeadless;
    } finally {
      this._initialized = true;
    }
  });

  @action private _release() {
    this._isHeadless = false;
  }

  async getIsHeadless() {
    return (
      AppState.currentState !== 'active' && (await messaging().getIsHeadless())
    );
  }

  private _initialize() {
    const _ = this._load();
    return (() => _.cancel()) as Disposer;
  }

  private _update() {
    const listener = async (state: AppStateStatus) => {
      if (state === 'active') {
        this._release();
        subscription.remove();
      }
    };
    const subscription = AppState.addEventListener('change', listener);
    return (() => subscription.remove()) as Disposer;
  }

  subscribe() {
    return batchDisposers(this._initialize(), this._update());
  }
}
