import React, {PropsWithChildren} from 'react';
import {observer} from 'mobx-react-lite';
import {TouchableOpacityProps} from 'react-native';
import PressableOpacity from './PressableOpacity';
import {useStyles} from '../styling';

export default observer(
  ({style, children, ...rest}: PropsWithChildren<TouchableOpacityProps>) => {
    const styles = useStyles(() => ({
      root: {
        height: 40,
        width: 40,
        alignItems: 'center',
        justifyContent: 'center',
      },
    }));
    return (
      <PressableOpacity style={[styles.root, style]} {...rest}>
        {children}
      </PressableOpacity>
    );
  },
);
