import React from 'react';
import {View, Text} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../../Root/hooks';
import {createStylesHook} from '../../styling';
import {
  SimpleAppStoreButton,
  SimpleGooglePlayButton,
} from '../../components/StoreButton';

interface ItemStoreLinksProps {
  goToPlayStore: () => void;
  goToAppStore: () => void;
}

export default observer(
  ({goToPlayStore, goToAppStore}: ItemStoreLinksProps) => {
    const styles = useStyles();
    const strings = useStrings();
    return (
      <View style={styles.root}>
        <Text style={styles.title}>{strings.mobileApp}</Text>
        <View style={styles.content}>
          <SimpleGooglePlayButton
            onPress={goToPlayStore}
            style={styles.buttonContainer}
            contentStyle={styles.button}
          />
          <SimpleAppStoreButton
            onPress={goToAppStore}
            style={styles.buttonContainer}
            contentStyle={styles.button}
          />
        </View>
      </View>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  root: {
    paddingHorizontal: 20,
    paddingTop: 15,
    paddingBottom: 20,
    borderColor: theme.palette.border,
  },
  content: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  title: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 0.04,
    color: theme.chroma(theme.palette.icon).alpha(0.4).hex(),
    marginBottom: 10,
  },
  iconView: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.select(
      theme.palette.foreground,
      theme.palette.secondary,
    ),
    borderRadius: 3,
    cursor: 'pointer',
  },

  buttonContainer: {
    flex: 1,
  },
  button: {
    flex: 1,
  },
}));
