import {batchDisposers, Service} from '../structure';
import {action, autorun, observable, reaction, makeObservable} from 'mobx';
import {Auth} from '../Auth';
import {DASHBOARD_MODE, define} from '../persistence';
import {DashboardMode, Mode} from './DashboardMode';

export default class DashboardModeService implements DashboardMode, Service {
  @observable private _mode: Mode = Mode.Simple;

  constructor(
    private readonly _root: {
      readonly auth: Auth;
    },
  ) {
    makeObservable(this);
  }

  get mode() {
    return this._mode;
  }

  @action
  changeMode = (mode: Mode) => {
    this._mode = mode;
  };

  private _saveOnModeChange = () =>
    reaction(
      () => this._mode,
      async () => {
        await setDashboardMode({mode: this._mode});
      },
    );

  private _load = () =>
    autorun(async () => {
      const params = await getDashboardMode();
      if (params.success && params.right !== null) {
        this._mode = params.right.mode;
        return;
      }
    });

  subscribe() {
    return batchDisposers(this._load(), this._saveOnModeChange());
  }
}

type ModeRecord = {
  mode: Mode;
};

const [getDashboardMode, setDashboardMode] = define<ModeRecord>(DASHBOARD_MODE);
