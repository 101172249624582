import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../styling';
import {PressableOpacity} from '../components';
import {Text, View} from 'react-native';
import {colored, sized} from '../Svg';
import ChevronRightSvg from '../assets/svg/colorless/chevronRight.svg';
import {MinerConfig} from '../ProposalsState/MinerProposalsState';
import CpuColorlessSvg from '../assets/svg/colorless/cpu.svg';
import CpuSvg from '../assets/svg/colored/cpu.svg';
import SpeedSvg from '../assets/svg/colored/speed.svg';
import SpeedColorlessSvg from '../assets/svg/colorless/speed.svg';
import {useStrings} from '../Root/hooks';
import {Formatter} from '../Formatter';
import {useIsDesktop} from '../DashboardScreen/hooks/useGetItemLayout';
import {Hoverable} from 'react-native-web-hooks';

export type ConfigurationListItemProps = {
  configuration: MinerConfig;
  onPress: () => void;
  selected: boolean;
};

export default observer(function ConfigurationListItem(
  props: ConfigurationListItemProps,
) {
  const {configuration, onPress, selected} = props;
  const theme = useTheme();
  const isDesktop = useIsDesktop();

  return (
    <Hoverable>
      {(isHovered) => (
        <ConfigItem selected={selected} onPress={onPress} hovered={isHovered}>
          <ConfigItemLeftView>
            {isDesktop && (
              <IconView>
                {isHovered && !selected ? <CpuColorlessIcon /> : <CpuIcon />}
              </IconView>
            )}
            <ConfigItemText hovered={isHovered && !selected}>
              {configuration.name}
            </ConfigItemText>
          </ConfigItemLeftView>
          <ConfigItemLeftView>
            {isDesktop && (
              <IconView>
                {isHovered && !selected ? (
                  <SpeedColorlessIcon />
                ) : (
                  <SpeedIcon />
                )}
              </IconView>
            )}
            <ConfigItemSpeedText hovered={isHovered && !selected}>
              {Formatter.numberWithSpaces(configuration.hashrate)} H/s
            </ConfigItemSpeedText>
            <ChevronRightIcon
              color={isHovered && !selected ? '#fff' : theme.palette.icon}
            />
          </ConfigItemLeftView>
        </ConfigItem>
      )}
    </Hoverable>
  );
});

export const ConfigurationListItemSticky = observer(() => {
  const strings = useStrings();

  return (
    <ConfigItem stickyHeader>
      <ConfigItemRightView>
        <IconView>
          <CpuIcon />
        </IconView>
        <ConfigItemText sticky>
          {strings['chooseConfigurationScreen.cpuLabel']}
        </ConfigItemText>
      </ConfigItemRightView>
      <ConfigItemLeftView>
        <IconView>
          <SpeedIcon />
        </IconView>
        <ConfigItemText sticky>
          {strings['chooseConfigurationScreen.speedLabel']}
        </ConfigItemText>
      </ConfigItemLeftView>
    </ConfigItem>
  );
});

const CpuIcon = sized(CpuSvg, 20, 20);
const CpuColorlessIcon = colored(sized(CpuColorlessSvg, 20, 20), '#fff');
const SpeedIcon = sized(SpeedSvg, 20);
const SpeedColorlessIcon = colored(sized(SpeedColorlessSvg, 20), '#fff');
const ChevronRightIcon = sized(ChevronRightSvg, 13);

const ConfigItem = variance(PressableOpacity)((theme) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 55,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: theme.palette.background,
  },
  hovered: {
    backgroundColor: theme.palette.info,
  },
  selected: {
    backgroundColor: theme.palette.additionalInfo,
  },
  stickyHeader: {
    height: 40,
    backgroundColor: theme.palette.back,
  },
}));

const IconView = variance(View)(() => ({
  root: {
    marginRight: 10,
  },
}));

const ConfigItemText = variance(Text)((theme) => ({
  root: {
    maxWidth: 200,
    ...theme.fontByWeight('400'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 18,
    flexShrink: 1,

    ...theme.mediaQuery({
      500: {
        maxWidth: undefined,
      },
    }),
  },
  selected: {
    color: theme.contrast(theme.palette.textPrimary),
  },
  hovered: {
    color: '#fff',
  },
  sticky: {
    marginRight: 22,
    ...theme.fontByWeight('700'),
    textTransform: 'uppercase',
  },
}));

const ConfigItemRightView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const ConfigItemLeftView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const ConfigItemSpeedText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 17,
    marginRight: 10,
  },
  hovered: {
    color: '#fff',
  },
  selected: {
    color: theme.contrast(theme.palette.textPrimary),
  },
}));
