import React, {useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {createStylesHook, useTheme} from '../../styling';
import {useHover} from 'react-native-web-hooks';
import {View, Text} from 'react-native';
import {useRoot} from '../../Root/hooks';
import {CryptoLoader} from '../../components/CryptoLoader';
import BtcSvg from '../../assets/svg/colored/btc.svg';
import * as Animatable from 'react-native-animatable';
import {PressableOpacity} from '../../components';
import {sized} from '../../Svg';
import DotsSvg from '../../assets/svg/colorless/circleDots.svg';

export interface DropdownButtonProps {
  visibleMenu: boolean;
  hideMenu: () => void;
  showMenu: () => void;
}

export default observer(
  ({visibleMenu, hideMenu, showMenu}: DropdownButtonProps) => {
    const styles = useStyles();
    const {dashboardStore} = useRoot();
    const theme = useTheme();
    const btc = dashboardStore.balance.btc ?? '';
    const toggleMenu = visibleMenu ? hideMenu : showMenu;
    const isLoading = dashboardStore.balanceRequest.isLoading;

    const ref = useRef<View>(null);
    const isHovered = useHover(ref);
    return (
      <PressableOpacity
        activeOpacity={1}
        style={styles.pressable}
        onPress={toggleMenu}
        ref={ref}>
        <View
          style={[
            styles.root,
            isHovered && styles.rootHovered,
            visibleMenu && styles.rootVisible,
          ]}>
          {isLoading ? (
            <View style={styles.loaderView}>
              <CryptoLoader />
            </View>
          ) : (
            <Animatable.View
              animation="fadeIn"
              duration={100}
              style={styles.content}>
              <View style={styles.iconView}>
                <BtcIcon />
              </View>
              <View>
                {btc ? (
                  <Text style={styles.btcBalanceText}>{btc} BTC</Text>
                ) : (
                  <Text style={styles.btcBalanceText}>&mdash; BTC</Text>
                )}
              </View>
            </Animatable.View>
          )}
          <View style={[styles.dotsView, isHovered && styles.rootHovered]}>
            <DotsIcon
              color={
                isHovered ? theme.palette.primary : theme.palette.secondary
              }
            />
          </View>
        </View>
      </PressableOpacity>
    );
  },
);

const BtcIcon = sized(BtcSvg, 20);

const useStyles = createStylesHook((theme) => ({
  pressable: {
    zIndex: 2,
  },
  root: {
    borderWidth: 1,
    borderColor: theme.palette.border,
    borderRadius: 10,
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
    backgroundColor: theme.palette.background,
  },
  rootHovered: {
    borderColor: theme.palette.primary,
  },
  rootVisible: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 4,
  },
  content: {
    width: 220,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
  },
  iconView: {
    marginRight: 8,
  },
  loaderView: {
    width: 220,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btcBalanceText: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 18,
    letterSpacing: 0.051,
    color: theme.palette.textPrimary,
  },
  dotsView: {
    borderLeftWidth: 1,
    borderColor: theme.palette.border,
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const DotsIcon = sized(DotsSvg, 20);
