import React from 'react';
import {observer} from 'mobx-react-lite';
import Modal from '../modals/BaseModal';
import PaymentCompletedView, {
  PaymentCompletedViewProps,
} from './PaymentCompletedView';
import {StyleSheet} from 'react-native';

export type PaymentCompletedScreenProps = PaymentCompletedViewProps & {
  onModalClose: () => void;
};

export default observer(function PaymentCompletedScreen(
  props: PaymentCompletedScreenProps,
) {
  return (
    <Modal contentStyle={styles.modal} onDismiss={props.onModalClose}>
      <PaymentCompletedView {...props} />
    </Modal>
  );
});

const styles = StyleSheet.create({
  modal: {
    padding: 0,
  },
});
