import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../../styling';
import {View} from 'react-native';
import {GeneratePromoSection} from './GeneratePromoSection';
import {CopyPromoSection} from './CopyPromoSection';
import {CryptoLoader} from '../../components/CryptoLoader';
import {useRoot} from '../../Root/hooks';
import {last} from 'lodash';
import {useForcedContext} from '../../context';
import {DashboardContext} from '../context';

export type PromoSectionProps = {
  goToCopyRegistrationLink: (code: string) => void;
};

export const PromoSection = observer(
  ({goToCopyRegistrationLink}: PromoSectionProps) => {
    const {dashboardStore} = useRoot();
    const promos = dashboardStore.dashboardPromo;
    const lastPromo = last(promos.promos);
    const {
      safelyGoToAboutAffiliate,
      getPromoAffiliateIsPending,
      getAboutAffiliateIsPending,
      safelyGoToPromoAffiliate,
    } = useForcedContext(DashboardContext);
    const getAffiliateIsPending = useCallback(
      () => getPromoAffiliateIsPending() || getAboutAffiliateIsPending(),
      [getAboutAffiliateIsPending, getPromoAffiliateIsPending],
    );
    const _renderSection = useCallback(() => {
      if (lastPromo) {
        return (
          <CopyPromoSection
            code={lastPromo.code}
            safelyGoToAboutAffiliate={safelyGoToAboutAffiliate}
            safelyGoToPromoAffiliate={safelyGoToPromoAffiliate}
            goToCopyRegistrationLink={goToCopyRegistrationLink}
            getAffiliateIsPending={getAffiliateIsPending}
          />
        );
      }
      return (
        <GeneratePromoSection
          safelyGoToPromoAffiliate={safelyGoToPromoAffiliate}
          getAffiliateIsPending={getAffiliateIsPending}
        />
      );
    }, [
      getAffiliateIsPending,
      goToCopyRegistrationLink,
      lastPromo,
      safelyGoToAboutAffiliate,
      safelyGoToPromoAffiliate,
    ]);
    return promos.isLoading ? <Loader /> : _renderSection();
  },
);

export const PROMO_LOADER_HEIGHT = 60;

const Loader = observer(() => {
  const styles = useStyles((theme) => ({
    container: {
      paddingBottom: 10,
      height: PROMO_LOADER_HEIGHT,
    },
    root: {
      borderRadius: 2,
      flex: 1,
      backgroundColor: theme.palette.background,
      borderWidth: 1,
      borderColor: theme.palette.border,
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));
  return (
    <View style={styles.container}>
      <View style={styles.root}>
        <CryptoLoader />
      </View>
    </View>
  );
});
