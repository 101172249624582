import {Millisecond} from '../Time';
import {Bitcoin, WorkerRecord} from './DemonstrationDatabase';
import ConsistentInterval, {EmptySet} from './ConsistentInterval';
import StaircaseFunctionIntegrator from './StaircaseFunctionIntegrator';
import getWorkerEstimatedResult from './getWorkerEstimatedResult';
import {BitcoinPerHash} from './MiningService';

export default (
  lastWorkerStatsUpdate: Millisecond,
  now: Millisecond,
  miningInterval: Millisecond,
  bitcoinPerHash: BitcoinPerHash,
  worker: WorkerRecord,
  from: Millisecond,
  _to: Millisecond,
  interval: Millisecond,
): {
  from: Millisecond;
  to: Millisecond;
  results: Bitcoin[];
} => {
  const to = addRemainder(_to, interval) as Millisecond;
  const queryInterval = ConsistentInterval.safeCreate(from, to);

  const miningResults = [
    ...worker.miningResults,
    getWorkerEstimatedResult(
      lastWorkerStatsUpdate,
      now,
      worker,
      bitcoinPerHash,
    ),
  ];

  const miningTime = worker.miningResults.length * miningInterval;
  const dbInterval = ConsistentInterval.safeCreate(
    lastWorkerStatsUpdate - miningTime,
    lastWorkerStatsUpdate + interval,
  );

  const intersection = dbInterval.intersect(queryInterval);

  // noinspection SuspiciousTypeOfGuard
  if (intersection instanceof EmptySet) {
    return {
      from: queryInterval.from as Millisecond,
      to: queryInterval.to as Millisecond,
      results: [],
    };
  }

  const integrator = new StaircaseFunctionIntegrator(miningResults, dbInterval);

  const results = intersection.mapStickingToUpperBound(
    (piece) => integrator.integrate(piece),
    interval,
  );

  if (results.length === 0) {
    return {
      from: queryInterval.from as Millisecond,
      to: queryInterval.to as Millisecond,
      results: [],
    };
  }

  const resultTo = addRemainder(intersection.to, interval);
  const resultFrom = resultTo - (results.length - 1) * interval;

  return {
    from: resultFrom as Millisecond,
    to: resultTo as Millisecond,
    results,
  };
};

const addRemainder = (target: number, divider: number) =>
  Math.ceil(target / divider) * divider;
