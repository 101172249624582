import {DefaultSectionT, SectionListData, SectionListProps} from 'react-native';
import {useLayoutEffect, useRef, useState} from 'react';
import {Mutable} from 'type-fest';
import areEqual from './areEqual';

export default <ItemT, SectionT = DefaultSectionT>(
  sections: SectionListProps<ItemT, SectionT>['sections'],
  contentsAreEqual: (
    one: SectionListData<ItemT, SectionT>,
    another: SectionListData<ItemT, SectionT>,
  ) => boolean,
  deps: unknown[],
) => {
  const [stable, setStable] = useState(() => sections);
  useLayoutEffect(() => {
    setStable((prevStable) => {
      if (!areEqual(prevStable, sections, (a, b) => Object.is(a.key, b.key))) {
        return sections;
      }
      let nextStable = prevStable as Mutable<typeof prevStable>;
      for (let i = 0; i < prevStable.length; ++i) {
        if (!contentsAreEqual(prevStable[i], sections[i])) {
          nextStable =
            nextStable === prevStable ? prevStable.slice() : nextStable;
          nextStable[i] = sections[i];
        }
      }
      return nextStable as typeof prevStable;
    });
  }, [contentsAreEqual, sections]);
  const firstRunRef = useRef(true);
  const sectionsRef = useRef(sections);
  sectionsRef.current = sections;
  useLayoutEffect(() => {
    if (!firstRunRef.current) {
      setStable(sectionsRef.current);
    }
    firstRunRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
  return stable;
};
