import {InAppPurchaseManager} from './InAppPurchaseManager';
import {Platform} from 'react-native';
import AndroidInAppPurchaseManagerImpl from './AndroidInAppPurchaseManagerImpl';
import IosInAppPurchaseManagerImpl from './IosInAppPurchaseManagerImpl';
import StubInAppPurchaseManagerImpl from './StubInAppPurchaseManagerImpl';
import {ApiStore} from '../ApiStore';
import {Service} from '../structure';
import {Configuration} from '../Configuration';
import {InAppPurchase} from '../InAppPurchase';
import {ErrorRepository} from '../ErrorRepository';
import {ConnectedClient} from '../ContextClient';
import {Auth} from '../Auth';
import {PurchaseDiscount} from '../PurchasePromoService';

export default class InAppPurchaseManagerFactory {
  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly apiStore: ApiStore;
      readonly connectedClient: ConnectedClient;
      readonly configuration: Configuration;
      readonly inAppPurchase: InAppPurchase;
      readonly errorRepository: ErrorRepository;
    },
  ) {}

  create() {
    return Platform.select<InAppPurchaseManager & Service>({
      android: new AndroidInAppPurchaseManagerImpl(this._root),
      ios: new IosInAppPurchaseManagerImpl(this._root),
      default: new StubInAppPurchaseManagerImpl(this._root),
    });
  }
}
