import {OffersRequester} from './OffersRequester';
import {ConnectedClient} from '../../ContextClient';
import {InAppPurchaseManager} from '../../InAppPurchaseManager';
import {Either, error, success} from '../../fp';
import {
  MinerProductOffer,
  Offer,
  OfferPurchaseType,
  OfferType,
  PaymentMethod,
  PurchaseKind,
  SlotSubscriptionOffer,
} from './OfferManager';
import {Configuration} from '../../Configuration';
import BaseOffersRequesterImpl from './BaseOffersRequesterImpl';
import {PurchaseDiscount} from '../../PurchasePromoService';
import {
  FETCH_OFFERS_ERROR,
  FETCH_OFFERS_SERVER_ERROR,
  FetchOffersError,
  GlobalError,
} from '../../Error';
import {
  Discount,
  GetTariffsResult,
  Plan,
  PlanType,
  PurchaseType,
  Tariff,
} from '../../ApiStore';
import {ErrorRepository} from '../../ErrorRepository';
import toFixedTrunc from '../../utils/toFixedTrunc';
export default class WebOffersRequesterImpl
  extends BaseOffersRequesterImpl
  implements OffersRequester
{
  constructor(
    protected readonly _root: {
      readonly connectedClient: ConnectedClient;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly configuration: Configuration;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly errorRepository: ErrorRepository;
    },
  ) {
    super(_root);
  }

  private _translateMethod(currency: string): PaymentMethod | null {
    switch (currency) {
      case 'ETH':
        return PaymentMethod.ETH;
      case 'SOL':
        return PaymentMethod.SOL;
      case 'MATIC':
        return PaymentMethod.Matic;
      case 'USDT':
        return PaymentMethod.USDT;
      case 'BTC':
        return PaymentMethod.BTC;
      case 'CTC':
        return PaymentMethod.CT;
      case 'USD':
        return PaymentMethod.Card;
      case 'BNB':
        return PaymentMethod.BNB;
      case 'TRX':
        return PaymentMethod.TRX;
      default:
        return null;
    }
  }

  private _createPaymentsMap(plan: Plan, discounts: Discount[]) {
    const hashrate = plan.pool_miner_config?.hash_rate;
    return new Map(
      plan.crypto_rates.flatMap((rate) => {
        const price = Number(rate.amount);
        const uiPrice = rate.amount;
        const fractionDigits = rate.amount.split('.')[1]?.length ?? 0;
        const {
          discountsDifferencePrice,
          uiDiscountsDifferencePrice,
          oldPrice,
          uiOldPrice,
          offerDiscounts,
        } = this._calculateDiscountPrices(price, discounts, fractionDigits);
        const pricePerMonth = price / plan.interval;
        const uiPricePerMonth = toFixedTrunc(pricePerMonth, fractionDigits);
        const pricePerMonthPerHashrate = hashrate
          ? (pricePerMonth / hashrate) * 1000
          : undefined;
        const uiPricePerMonthPerHashrate = pricePerMonthPerHashrate
          ? toFixedTrunc(pricePerMonthPerHashrate, fractionDigits)
          : undefined;
        const method = this._translateMethod(rate.currency);
        if (method === null) {
          return [];
        }
        return [
          [
            method,
            {
              price,
              uiPrice,
              currency: rate.currency,
              uiUsdPrice: this._getUsdPrice(plan),
              pricePerMonth,
              uiPricePerMonth,
              pricePerMonthPerHashrate,
              uiPricePerMonthPerHashrate,
              discountsDifferencePrice,
              uiDiscountsDifferencePrice,
              oldPrice,
              uiOldPrice,
              discounts: offerDiscounts,
            },
          ],
        ];
      }),
    );
  }

  private async _getPoolProductOffers(
    tariffs: Tariff[],
    discounts: Discount[],
  ): Promise<Either<MinerProductOffer[], GlobalError>> {
    const tariff = tariffs.find((_) => _.name === 'PoolMiner');

    if (!tariff) {
      return success([]);
    }
    const plans = tariff.plans ?? [];
    const poolProductPlans = plans.flatMap((_) => {
      if (
        _.type === PurchaseType.Product &&
        _.product === PlanType.PoolMiner &&
        _.pool_miner_config
      ) {
        return [_];
      }
      return [];
    });
    const poolOffers = poolProductPlans.flatMap<MinerProductOffer>((plan) => {
      if (!plan.pool_miner_config) {
        return [];
      }
      return [
        {
          id: plan.id,
          offerId: this._generateOfferId(plan),
          purchaseId: plan.id_on_platform,

          payments: this._createPaymentsMap(plan, discounts),

          tariff: tariff.name,

          kind: PurchaseKind.Product,
          type: OfferType.Miner,
          purchaseType: OfferPurchaseType.MinerProduct,
          poolMinerConfig: plan.pool_miner_config,

          priority: plan.priority,
          interval: plan.interval,
          interval_type: plan.interval_type,
          subscriptionOfferTokenAndroid: undefined,
        },
      ];
    });
    return success(poolOffers);
  }

  private async _getSlotSubscriptionOffers(
    tariffs: Tariff[],
    discounts: Discount[],
  ): Promise<Either<SlotSubscriptionOffer[], GlobalError>> {
    const tariff = tariffs.find((_) => _.name === 'BASIC');

    if (!tariff) {
      return success([]);
    }
    const plans = tariff.plans ?? [];
    const slotSubscriptionPlans = plans.flatMap((_) => {
      if (
        _.type === PurchaseType.Product &&
        _.product === PlanType.Slot &&
        _.max_slots
      ) {
        return [_];
      }
      return [];
    });

    const slotOffers = slotSubscriptionPlans.flatMap<SlotSubscriptionOffer>(
      (plan) => {
        const pricePerSlotPerMonth =
          Number(plan.price) /
          (plan.max_slots || 1) /
          (plan.interval || 1) /
          100;
        const uiPricePerSlotPerMonth = toFixedTrunc(pricePerSlotPerMonth, 2);
        if (!plan.max_slots) {
          return [];
        }
        return [
          {
            id: plan.id,
            offerId: this._generateOfferId(plan),
            purchaseId: plan.id_on_platform,
            payments: this._createPaymentsMap(plan, discounts),
            pool_miner_config: plan.pool_miner_config,
            tariff: tariff.name,
            kind: PurchaseKind.Subscription,
            type: OfferType.Slot,
            purchaseType: OfferPurchaseType.SlotSubscription,
            priority: plan.priority,
            interval: plan.interval,
            interval_type: plan.interval_type,
            subscriptionOfferTokenAndroid: undefined,
            maxSlots: plan.max_slots,
            pricePerSlotPerMonth,
            uiPricePerSlotPerMonth,
            bought: false,
            trial: null,
          },
        ];
      },
    );
    return success(slotOffers);
  }

  private async _processTariffs(
    result: GetTariffsResult,
    discounts: Discount[],
  ): Promise<Either<Offer[], GlobalError>> {
    const getProductPoolOffers_ = await this._getPoolProductOffers(
      result.tariffs,
      discounts,
    );
    if (!getProductPoolOffers_.success) {
      return getProductPoolOffers_;
    }
    const getSubscriptionSlotOffers_ = await this._getSlotSubscriptionOffers(
      result.tariffs,
      discounts,
    );
    if (!getSubscriptionSlotOffers_.success) {
      return getSubscriptionSlotOffers_;
    }
    return success(
      [getProductPoolOffers_.right, getSubscriptionSlotOffers_.right].flat(),
    );
  }

  async fetch(): Promise<Either<Offer[], GlobalError>> {
    const code = this._root.purchaseDiscount.discount?.code;
    const tariffs_ = await this._fetchTariffs({code});

    if (!tariffs_.success) {
      return error(
        this._root.errorRepository.create<FetchOffersError>({
          kind: FETCH_OFFERS_ERROR,
          type: FETCH_OFFERS_SERVER_ERROR,
          raw: tariffs_.left,
        }),
      );
    }
    const discounts = tariffs_.right.discounts;
    const prepareOffers_ = await this._processTariffs(
      tariffs_.right,
      discounts,
    );
    if (!prepareOffers_.success) {
      return prepareOffers_;
    }
    return success(prepareOffers_.right);
  }
}
