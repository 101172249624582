import React, {useEffect} from 'react';
import {Platform, StyleSheet, View, ViewProps} from 'react-native';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import RNBootSplash from 'react-native-bootsplash';
import {NavigationRoot} from './Navigation';
import RootProvider from './Root/RootProvider';
import Portal from './Portal';
import {AuthSplashBinding} from './AuthSplash';
import {useStyles} from './styling';
import {Headless} from './Headless';
import {FlashMessage as CustomFlashMessage} from './components';
import FlashMessage from 'react-native-flash-message';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import ErrorModalProvider from './ExceptionHandler';
import InAppLoader from './components/InAppLoader';
import {UpdateAppCheckerModal} from './UpdateApp';
import {PushAdvertSplashContainer} from './PushAdvertSplash';
import {KeyboardProvider} from 'react-native-keyboard-controller';

export const Wrapper = ({children}: ViewProps) => {
  const styles = useStyles((theme) => ({
    root: {
      flex: 1,
      backgroundColor: theme.palette.back,
    },
  }));
  useEffect(() => {
    if (Platform.OS !== 'web') {
      // noinspection JSIgnoredPromiseFromCall
      RNBootSplash.hide();
    }
  }, []);

  return (
    <SafeAreaProvider>
      <View style={styles.root}>{children}</View>
    </SafeAreaProvider>
  );
};

export type AppProps = {
  headless: Headless;
};

const App: React.FC<AppProps> = ({headless}) => (
  <KeyboardProvider>
    <RootProvider headless={headless}>
      <GestureHandlerRootView style={{flex: 1}}>
        <Portal.Host>
          <Wrapper>
            <NavigationRoot />
            <PushAdvertSplashContainer />
            <AuthSplashBinding />
            <ErrorModalProvider />
            <UpdateAppCheckerModal />
          </Wrapper>
        </Portal.Host>
        <View style={styles.layer}>
          <InAppLoader />
        </View>
        <FlashMessage MessageComponent={CustomFlashMessage} />
      </GestureHandlerRootView>
    </RootProvider>
  </KeyboardProvider>
);

const styles = StyleSheet.create({
  layer: {
    backgroundColor: 'transparent',
    pointerEvents: 'box-none',
    ...StyleSheet.absoluteFillObject,
  },
});

export default App;
