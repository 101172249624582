import {
  IapPurchaseRequester,
  IapResponse,
  RequestPurchaseParams,
} from './PurchaseRequester';
import {Either, error} from '../fp';
import {when} from 'mobx';
import {ApiStore} from '../ApiStore';
import {InAppPurchaseManager, PurchaseResult} from '../InAppPurchaseManager';
import {InAppOffers} from '../InAppOffersService';
import {PurchaseKind} from '../InAppOffersService';
import {ErrorRepository} from '../ErrorRepository';
import {GlobalError, UNKNOWN_ERROR, UnknownError} from '../Error';
import {PendingPurchasesResolver} from '../PendingPurchasesResolver';
import {PurchaseDiscount} from '../PurchasePromoService';

export default abstract class BasePurchaseRequesterImpl
  implements IapPurchaseRequester
{
  protected constructor(
    protected readonly _root: {
      readonly apiStore: ApiStore;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly pendingPurchasesResolver: PendingPurchasesResolver;
      readonly errorRepository: ErrorRepository;
    },
  ) {}

  protected get _code() {
    return this._root.purchaseDiscount.discount?.code;
  }

  private async _requestPurchase(
    params: RequestPurchaseParams,
  ): Promise<PurchaseResult> {
    await when(() => this._root.inAppOffers.isLoadedIn);
    const offer = this._root.inAppOffers.offerById.get(params.purchaseId);
    if (!offer) {
      return error(
        this._root.errorRepository.create<UnknownError>({
          kind: UNKNOWN_ERROR,
        }),
      );
    }
    if (offer.kind === PurchaseKind.Subscription) {
      return this.requestSubscription(params);
    } else {
      return this.requestProduct(params);
    }
  }

  protected abstract requestSubscription(
    params: RequestPurchaseParams,
  ): Promise<PurchaseResult>;
  protected abstract requestProduct(
    params: RequestPurchaseParams,
  ): Promise<PurchaseResult>;

  async request(
    params: RequestPurchaseParams,
  ): Promise<Either<IapResponse, GlobalError>> {
    await this._root.pendingPurchasesResolver.resolve();
    const requestPurchase_ = await this._requestPurchase(params);
    if (!requestPurchase_.success) {
      return requestPurchase_;
    }
    return this._root.pendingPurchasesResolver.resolve();
  }
}
