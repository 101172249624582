import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {ProposalTable} from '../PoolMinersTab/components/ProposalTable';
import {useForcedContext} from '../../context';
import {PurchaseScreenContext} from '../PurchaseScreenContext';
import {useStrings} from '../../Root/hooks';
import {SlotSubscriptionOffer} from '../../InAppOffersService';
import {Proposal} from '../../ProposalsState';
import {IntervalRowProps} from '../PoolMinersTab/components/ProposalTable/IntervalRow';

export default observer(function ProposalSlotBinding() {
  const {
    state: {
      slotUniqIntervalList,
      slotProposals,
      selectedSlotInterval,
      selectedMaxSlot,
      selectMaxSlots,
      selectSlotInterval,
      selectedPaymentMethod,
    },
  } = useForcedContext(PurchaseScreenContext);
  const onItemPress = useCallback(
    (v: IntervalRowProps) => {
      if (v.maxSlot) {
        selectSlotInterval(v.duration);
        selectMaxSlots(v.maxSlot);
      }
    },
    [selectMaxSlots, selectSlotInterval],
  );
  const strings = useStrings();
  const getUiPrices = useCallback(
    (proposal: Proposal<SlotSubscriptionOffer>) => {
      const payment = proposal.offer.payments.get(selectedPaymentMethod);
      if (!payment) {
        return {
          priceText: '',
          pricePerMonthPerHashrate: '',
          discounts: [],
        };
      }
      return {
        priceText: `${payment.uiPricePerMonth} ${payment.currency}`,
        pricePerMonthPerHashrate: `${(
          Number(payment.price) / proposal.offer.maxSlots
        ).toFixed(2)} ${payment.currency} ${strings['common.perSlot']}`,
        discounts: payment.discounts.map((d) => d.percent),
      };
    },
    [strings, selectedPaymentMethod],
  );
  const items = useMemo(() => {
    return slotUniqIntervalList.map((interval, index) => {
      const find: Proposal<SlotSubscriptionOffer> | undefined =
        slotProposals?.find(
          (p) =>
            p.offer.interval === interval &&
            p.offer.maxSlots === selectedMaxSlot,
        );
      if (!find) {
        return {
          selected: false,
          disabled: true,
          isLast: index === slotUniqIntervalList.length - 1,
          duration: interval,
          priceText: strings['common.notAvailable'],
          suffix: strings['common.shortMonth'],
        };
      }
      return {
        selected:
          find.offer.interval === selectedSlotInterval &&
          find.offer.maxSlots === selectedMaxSlot,
        disabled: !find.available,
        isLast: index === slotUniqIntervalList.length - 1,
        ...getUiPrices(find),
        maxSlot: find.offer.maxSlots,
        duration: interval,
        suffix: strings['common.shortMonth'],
      };
    });
  }, [
    selectedSlotInterval,
    selectedMaxSlot,
    getUiPrices,
    slotUniqIntervalList,
    slotProposals,
    strings,
  ]);
  return <ProposalTable hideGift items={items} onItemPress={onItemPress} />;
});
