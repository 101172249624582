import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Text, View} from 'react-native';
import SpeedToggleList from './SpeedToggleList';
import {useStrings} from '../Root/hooks';
import {createStylesHook, useTheme} from '../styling';
import ScheduleState from './ScheduleState';
import {CheckBox, PressableOpacity} from '../components';

interface SpeedSwitchProps {
  state: ScheduleState;
}

export default observer(({state}: SpeedSwitchProps) => {
  const styles = useStyles();
  const strings = useStrings();
  return (
    <View style={styles.background}>
      <View style={styles.root}>
        <View style={styles.container}>
          <Text style={styles.title}>
            {strings['scheduler.setMiningSpeed']}:
          </Text>
          <SpeedToggleList
            selectedSpeed={state.selectedSpeed}
            onChangeSpeed={state.changeSpeed}
            pickedCustomSpeed={state.pickedCustomSpeed}
          />
        </View>
        <WorkAllTime
          active={state.allTimeActive}
          onPress={state.forceAllTime}
        />
      </View>
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  background: {
    backgroundColor: theme.palette.background,
    zIndex: -1,
  },
  root: {
    justifyContent: 'space-between',
    padding: 20,
    backgroundColor: theme.select(
      theme.palette.background,
      theme.palette.backdrop,
    ),
    ...theme.mediaQuery({
      1000: {
        flexDirection: 'row',
        paddingBottom: 30,
      },
    }),
  },
  container: {
    marginBottom: 10,
    ...theme.mediaQuery({
      1000: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        flexShrink: 1,
        alignItems: 'center',
        marginBottom: -12,
      },
    }),
  },
  title: {
    ...theme.fontByWeight('700'),
    marginBottom: 12,
    letterSpacing: 0.04,
    fontSize: 14,
    textAlign: 'center',
    color: theme.palette.textPrimary,
    ...theme.mediaQuery({
      1000: {
        textAlign: 'left',
        marginRight: 15,
      },
    }),
  },
}));

type WorkAllTimeCheckBoxProps = {
  active: boolean;
  onPress: () => void;
};

const WorkAllTime = observer((props: WorkAllTimeCheckBoxProps) => {
  const {active, onPress} = props;
  const strings = useStrings();
  const theme = useTheme();
  const styles = useMemo(
    () =>
      ({
        root: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },
        text: {
          color: theme.palette.textPrimary,
          letterSpacing: 0.04,
          fontSize: 14,
          ...theme.fontByWeight('400'),
          marginLeft: 10,
        },
      } as const),
    [theme],
  );
  return (
    <PressableOpacity hitSlop={5} onPress={onPress} style={styles.root}>
      <CheckBox checked={active} />
      <Text style={styles.text}>{strings['scheduler.workAllTime']}</Text>
    </PressableOpacity>
  );
});
