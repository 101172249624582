import {PartialState} from '@react-navigation/native';
import {NavigationState} from '@react-navigation/routers';
import {RootParamList} from './RootStack';

export const DASHBOARD: PartialState<NavigationState<RootParamList>> = {
  index: 0,
  routes: [
    {
      name: 'Root',
      state: {
        index: 0,
        routes: [{name: 'Dashboard'}],
      },
    },
  ],
};
