import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../styling';
import {Text, View} from 'react-native';
import RocketSvg from '../assets/svg/colored/rocket.svg';
import DesktopSvg from '../assets/svg/colorless/desktop.svg';
import TimerSvg from '../assets/svg/colorless/timer.svg';
import ShoppingCartSvg from '../assets/svg/colorless/shopping-cart.svg';
import FiltersSvg from '../assets/svg/colorless/filters.svg';

import {
  Button,
  ButtonColor,
  ButtonVariant,
  PressableOpacity,
} from '../components';
import {CryptoLoader} from '../components/CryptoLoader';
import {sized} from '../Svg';
import QuickStartItem, {
  QuickStartItemParams,
} from '../components/QuickStartItem';
import QuickStartDiscount from './QuickStartDiscount';
import {useRoot, useStrings, useTemplates} from '../Root/hooks';
import {Interval, IntervalType} from '../ApiStore';
import getHumanizeDuration, {
  IntervalTypeToUnit,
} from '../PurchaseScreen/getters/getHumanizeDuration';
import dayjs from 'dayjs';
import {Millisecond} from '../Time';
import {QuickStartOffer} from '../QuickStartService';

export type QuickStartScreenViewProps = {
  onSubscribe: () => void;
  onCustomizePress: () => void;
  isLoading: boolean;
  items: QuickStartItemParams[];
  selectItem: (val: number) => void;
  selectedItemIndex: number | undefined;
  selectedItem?: QuickStartItemParams;
  trial: QuickStartOffer['trial'];
};

export default observer(function QuickStartScreenView({
  onSubscribe,
  onCustomizePress,
  isLoading,
  items,
  selectItem,
  selectedItemIndex,
  selectedItem,
  trial,
}: QuickStartScreenViewProps) {
  const theme = useTheme();
  const strings = useStrings();
  const template = useTemplates();
  const {translation} = useRoot();
  const getDurationText = (interval: Interval, intervalType: IntervalType) => {
    return getHumanizeDuration(
      dayjs.duration(interval, intervalType).asMilliseconds() as Millisecond,
      translation.localeTag,
      IntervalTypeToUnit(intervalType),
    );
  };
  return (
    <RootView>
      <HeaderView>
        <IconView>
          <RocketIcon />
        </IconView>
        <TitleText>{strings['quickStartScreen.title']}</TitleText>
        <DescriptionText>
          {strings['quickStartScreen.description']}
        </DescriptionText>
        {trial && (
          <QuickStartContainerView>
            <QuickStartDiscount />
          </QuickStartContainerView>
        )}
      </HeaderView>
      <ContentView>
        {isLoading ? (
          <LoaderContainer>
            <CryptoLoader />
          </LoaderContainer>
        ) : (
          <TableView>
            <TableHeaderView>
              <TableHeaderText>
                {strings['quickStartScreen.table.title']}
              </TableHeaderText>
            </TableHeaderView>
            <TableContentView>
              {items.map((item, index) => (
                <TableItemView key={index} isLast={items.length === index + 1}>
                  <QuickStartItem
                    onPress={() => selectItem(index)}
                    selected={selectedItemIndex === index}
                    {...item}
                  />
                </TableItemView>
              ))}
            </TableContentView>
            {selectedItem && (
              <InfoView>
                <InfoTitleText>
                  {strings['quickStartScreen.table.quickStart']}
                </InfoTitleText>
                <InfoPriceText>
                  {selectedItem.price}
                  <InfoPriceIntervalText>
                    {' '}
                    /{' '}
                    {getDurationText(
                      selectedItem.interval,
                      selectedItem.intervalType,
                    )}
                  </InfoPriceIntervalText>
                </InfoPriceText>
                <BonusView>
                  <BonusIconView>
                    <DesktopIcon color={theme.palette.success} />
                  </BonusIconView>
                  <BonusText>
                    {template['quickStartScreen.table.billed']({
                      duration: getDurationText(
                        selectedItem.interval,
                        selectedItem.intervalType,
                      ),
                    })}
                  </BonusText>
                </BonusView>
                {trial && (
                  <BonusView>
                    <BonusIconView>
                      <TimerIcon color={theme.palette.success} />
                    </BonusIconView>
                    <BonusText>
                      {strings['quickStartScreen.table.bonus2.title']}
                    </BonusText>
                  </BonusView>
                )}
              </InfoView>
            )}
            <TableFooterView>
              <PressableOpacity
                disabled={selectedItem === undefined}
                onPress={onSubscribe}>
                <StyledButton
                  Icon={ShoppingCartSvg}
                  offsetBottom
                  variant={
                    selectedItem === undefined
                      ? ButtonVariant.Disabled
                      : ButtonVariant.Highlighted
                  }>
                  {strings['quickStartScreen.table.subscribe']}
                </StyledButton>
              </PressableOpacity>
              <PressableOpacity onPress={onCustomizePress}>
                <StyledButton Icon={FiltersSvg} color={ButtonColor.Default}>
                  {strings['quickStartScreen.table.customize']}
                </StyledButton>
              </PressableOpacity>
            </TableFooterView>
          </TableView>
        )}
      </ContentView>
    </RootView>
  );
});

const RocketIcon = sized(RocketSvg, 60);
const DesktopIcon = sized(DesktopSvg, 20);
const TimerIcon = sized(TimerSvg, 20);

const LoaderContainer = variance(View)(() => ({
  root: {
    paddingVertical: 30,
    alignItems: 'center',
  },
}));

const IconView = variance(View)(() => ({
  root: {
    alignItems: 'center',
    marginBottom: 20,
  },
}));

const RootView = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const TableView = variance(View)(() => ({
  root: {},
}));

const TableHeaderView = variance(View)((theme) => ({
  root: {
    padding: 15,
    borderWidth: 1,
    borderColor: theme.palette.border,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    backgroundColor: theme.palette.background,
  },
}));

const TableHeaderText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 20,
    lineHeight: 24,
    color: theme.palette.textPrimary,
  },
}));

const TableContentView = variance(View)((theme) => ({
  root: {
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: theme.palette.border,
    padding: 15,
    backgroundColor: theme.palette.background,
  },
}));

const InfoView = variance(View)((theme) => ({
  root: {
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: theme.palette.border,
    padding: 15,
    paddingBottom: 5,
    backgroundColor: theme.palette.background,
  },
}));

const InfoTitleText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    marginBottom: 15,
    fontSize: 14,
    lineHeight: 24,
    color: theme.palette.textPrimary,
  },
}));

const InfoPriceText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    marginBottom: 15,
    fontSize: 23,
    lineHeight: 34,
    color: theme.palette.textPrimary,
  },
}));

const InfoPriceIntervalText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    marginBottom: 15,
    fontSize: 16,
    lineHeight: 26,
    color: theme.palette.primary,
  },
}));

const BonusView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    marginBottom: 13,
  },
}));

const BonusIconView = variance(View)(() => ({
  root: {
    marginRight: 12,
  },
}));

const BonusText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    lineHeight: 18,
    color: theme.palette.textPrimary,
  },
}));

const TableItemView = variance(View)(() => ({
  root: {
    marginBottom: 20,
  },
  isLast: {
    marginBottom: 0,
  },
}));

const HeaderView = variance(View)(() => ({
  root: {
    padding: 20,
    paddingBottom: 0,
  },
}));

const ContentView = variance(View)(() => ({
  root: {
    padding: 20,
  },
}));

const TableFooterView = variance(View)((theme) => ({
  root: {
    padding: 20,
    borderTopColor: theme.palette.border,
    backgroundColor: theme.palette.background,
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: theme.palette.border,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
}));

const StyledButton = variance(Button)(() => ({
  root: {},
  offsetBottom: {
    marginBottom: 12,
  },
}));

const TitleText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 22,
    lineHeight: 32,
    color: theme.palette.textPrimary,
    textAlign: 'center',
    marginBottom: 10,
  },
}));

const DescriptionText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    lineHeight: 22,
    textAlign: 'center',
    color: theme.palette.textSecondary,
  },
}));

const QuickStartContainerView = variance(View)(() => ({
  root: {
    marginTop: 20,
  },
}));
