import {Connected, ConnectionFailed} from './AuthState';
import {AccountInfoParams, ApiStore, NODEMO_TAG} from '../ApiStore';
import {ApiMode, translateFarm} from '../farmApi';

/**
 * @throws {never}
 */
export default (
  state: Connected,
  root: {readonly apiStore: ApiStore},
  params: AccountInfoParams,
): Connected | ConnectionFailed => {
  const subscription = translateFarm(params);
  const subscriptionMap = new Map([
    ...state.subscriptionMap.entries(),
    [subscription.accountId, subscription],
  ]);
  const {accountId} = state;
  const farm = subscriptionMap.get(accountId);
  if (!farm) {
    return {
      ...state,
      kind: 'ConnectionFailed',
      raw: new Error('Requested a connection to the non-existent farm'),
    };
  }
  root.apiStore.setMode(state.tags.has(NODEMO_TAG) ? ApiMode.Real : farm.mode);
  return {
    ...state,
    email: params.email,
    purchaseTags: params.purchase_tags,
    tags: new Set(params.tags),
    apiMode: farm.mode,
    subscriptionMap,
  };
};
