import React from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';
import BaseModal, {ModalProps} from './BaseModal';
import UnderlineInput, {
  UnderlineInputProps,
} from '../components/UnderlineInput';
import {createStylesHook} from '../styling';
import {ModalTitle} from './components';

interface InputModal extends Omit<ModalProps, 'children'> {
  title: string;
  inputProps: UnderlineInputProps;
}

export default observer((props: InputModal) => {
  const {title, inputProps, ...rest} = props;
  const styles = useStyles();
  return (
    <BaseModal {...rest}>
      <View style={styles.header}>
        <ModalTitle>{title}</ModalTitle>
      </View>
      <View style={styles.content}>
        <UnderlineInput {...inputProps} />
      </View>
    </BaseModal>
  );
});

const useStyles = createStylesHook((theme) => ({
  header: {
    marginBottom: 20,
    ...theme.mediaQuery({
      1000: {
        marginTop: 15,
        paddingLeft: 20,
      },
    }),
  },
  content: {
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      1000: {
        width: 410,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: 40,
        paddingBottom: 70,
      },
    }),
  },
}));
