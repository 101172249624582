import {
  DemonstrationDatabase,
  GroupRecord,
  Scheduler,
  WorkerCharacteristicsRecord,
  WorkerRecord,
  WorkerSettings,
} from './DemonstrationDatabase';
import {v4 as uuidV4} from 'uuid';
import {Millisecond} from '../Time';
import {MINUTE} from '../utils/time';
import {omit} from 'lodash';
import {Translation} from '../Localization';
import {ReadonlyDeep} from 'type-fest';
import dayjs from 'dayjs';
import getWorkerScheduler from './getWorkerScheduler';
import {DEFAULT_GROUP_NAME, DEFAULT_SETTINGS} from './constants';
import {WorkerController} from './WorkerController';
import {Client, Server} from '../JsonRpc';
import {
  CryptoFarmClientCalls,
  CryptoFarmClientNotifications,
  WorkerId,
} from '../ApiStore';
import selectAllWorkersById from './selectAllWorkersById';
import createWorkerUpdate from './createWorkerUpdate';
import {batchDisposers, Service} from '../structure';

export default class WorkerService implements WorkerController, Service {
  constructor(
    private readonly _root: {
      readonly db: DemonstrationDatabase;
      readonly translation: Translation;
    },
    private readonly _reverseClient: Client<
      CryptoFarmClientCalls,
      CryptoFarmClientNotifications
    >,
    private readonly _realServer: Server<
      CryptoFarmClientCalls,
      CryptoFarmClientNotifications
    >,
  ) {}

  async create(characteristics: WorkerCharacteristicsRecord) {
    await this._root.db.update(async (db) => {
      const {nextWorkerId} = db;
      const name = this._root.translation.strings[
        'notifications.workerId'
      ].replace('{id}', nextWorkerId.toFixed(0));
      const defaultGroupId = 0;
      const defaultGroup = db.groups.find((_) => _.id === defaultGroupId);
      const restGroups = db.groups.filter((_) => _.id !== defaultGroupId);
      if (!defaultGroup) {
        const {nextGroupId} = db;
        return {
          ...db,
          groups: [
            {
              ...generateGroup(defaultGroupId),
              workersById: {[uuidV4()]: generateWorker(characteristics, name)},
            },
            ...restGroups,
          ],
          nextGroupId: nextGroupId + 1,
          nextWorkerId: nextWorkerId + 1,
        };
      }
      return {
        ...db,
        groups: [
          {
            ...defaultGroup,
            workersById: {
              ...defaultGroup.workersById,
              [uuidV4()]: generateWorker(characteristics, name),
            },
          },
          ...restGroups,
        ],
        nextWorkerId: nextWorkerId + 1,
      };
    });
  }

  async update(
    id: WorkerId,
    now: Millisecond,
    patch: {
      name?: string;
      settings?: WorkerSettings;
      schedulers?: Scheduler[];
    },
  ) {
    await this._root.db.update(async (db) => ({
      ...db,
      groups: db.groups.map((group) => ({
        ...group,
        workersById: Object.fromEntries(
          Object.entries(group.workersById).map(([workerId, worker]) =>
            workerId === id
              ? [
                  workerId,
                  {
                    ...worker,
                    ...patch,
                    ...(patch.schedulers || patch.settings
                      ? getIgnorancePatch(
                          now,
                          patch.schedulers ?? worker.schedulers,
                          patch.settings,
                        )
                      : {}),
                  },
                ]
              : [workerId, worker],
          ),
        ),
      })),
    }));
  }

  async delete(workerId: string) {
    await this._root.db.update(async (db) => {
      return {
        ...db,
        groups: db.groups.map((group) => ({
          ...group,
          workersById: omit(group.workersById, workerId),
        })),
      };
    });
  }

  private _routeThrough() {
    return this._realServer.call('worker_update', async (params, response) => {
      const outcome_ = await this._reverseClient.call('worker_update', params);
      if (!outcome_.success && outcome_.left.code === 32099) {
        return;
      }
      return response.respond(outcome_ as any);
    });
  }

  private _notifyOnScheduleUpdate() {
    return this._root.db.afterUpdate(async (previous, current) => {
      const previousWorkersById = selectAllWorkersById(previous.groups);
      const workersById = selectAllWorkersById(current.groups);
      const changedEntries = Object.entries(workersById).filter(
        ([id, worker]) => {
          const prevWorker = previousWorkersById[id];
          return (
            prevWorker &&
            (prevWorker.schedulers !== worker.schedulers ||
              prevWorker.name !== worker.name ||
              prevWorker.groupId !== worker.groupId)
          );
        },
      );
      for (const [id, worker] of changedEntries) {
        // noinspection ES6MissingAwait
        this._reverseClient.call(
          'worker_update',
          createWorkerUpdate({
            id,
            accountId: 0,
            characteristics: worker.characteristics,
            groupId: worker.groupId,
            name: worker.name,
            cores: worker.characteristics.cores,
            schedulers: worker.schedulers,
          }),
        );
      }
    });
  }

  subscribe() {
    return batchDisposers(this._routeThrough(), this._notifyOnScheduleUpdate());
  }
}

const generateWorker = (
  characteristics: WorkerCharacteristicsRecord,
  name: string,
): WorkerRecord => ({
  name,
  settings: DEFAULT_SETTINGS,
  characteristics,
  schedulers: [],
  miningResults: [],
});

const generateGroup = (id: number): GroupRecord => ({
  id,
  name: DEFAULT_GROUP_NAME,
  workersById: {},
});

const getIgnorancePatch = (
  moment: Millisecond,
  schedulers: ReadonlyDeep<Scheduler[]>,
  settings?: ReadonlyDeep<WorkerSettings>,
): Partial<WorkerRecord> => {
  const scheduler = getWorkerScheduler(moment, schedulers);
  if (settings && scheduler) {
    const startOfWeek = dayjs(moment)
      .startOf('isoWeek')
      .toDate()
      .getTime() as Millisecond;
    const until = (startOfWeek + scheduler.to * MINUTE) as Millisecond;
    return {shouldIgnoreScheduleUntil: until};
  }
  return {shouldIgnoreScheduleUntil: undefined};
};
