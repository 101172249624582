import {observer} from 'mobx-react-lite';
import {useRoot} from '../Root/hooks';
import React, {useCallback} from 'react';
import {ConnectingScreen} from '../ConnectingScreen';
import {expr} from 'mobx-utils';

export default observer(() => {
  const {cannon, connection} = useRoot();
  const visible = expr(
    () =>
      connection.isInitialized && !connection.isConnected && cannon.isFiring,
  );
  const getAnimateUntil = useCallback(() => cannon.firingUntil, [cannon]);
  return visible ? (
    <ConnectingScreen
      getAnimateUntil={getAnimateUntil}
      getIsFocused={GET_IS_FOCUSED}
    />
  ) : null;
});

const GET_IS_FOCUSED = () => true;
