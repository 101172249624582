import React from 'react';
import NotAllowedModal from './NotAllowedModal';
import {useRoot} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import TryAgainLaterModal from './TryAgainLaterModal';

export default observer(function ErrorModalProvider() {
  const {notAllowedModalState, basicErrorModalState} = useRoot();
  return (
    <>
      {notAllowedModalState.isVisible && (
        <NotAllowedModal
          description={notAllowedModalState.description}
          onClose={notAllowedModalState.reset}
        />
      )}
      {basicErrorModalState.isVisible && (
        <TryAgainLaterModal
          description={basicErrorModalState.description}
          onClose={basicErrorModalState.reset}
        />
      )}
    </>
  );
});
