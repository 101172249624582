import {Platform} from 'react-native';
import AndroidInstallRefSearcherImpl from './AndroidInstallRefSearcherImpl';
import SubInstallRefSearcherImpl from './SubInstallRefSearcherImpl';
import {LocationSource} from '../Location';

export default class InstallRefSearcherFactory {
  constructor(
    private readonly _root: {
      readonly locationSource: LocationSource;
    },
  ) {}

  create() {
    return Platform.select({
      android: new AndroidInstallRefSearcherImpl(this._root),
      default: new SubInstallRefSearcherImpl(),
    });
  }
}
