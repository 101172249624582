import React from 'react';
import {observer} from 'mobx-react-lite';
import {StyleSheet, View} from 'react-native';
import {useRoot} from '../../Root/hooks';
import LimitedDiscount from './components/LimitedDiscount';
import useCheckDiscount from '../hooks/useCheckDiscount';
import {useForcedContext} from '../../context';
import {PurchaseScreenContext} from '../PurchaseScreenContext';
import PoolMinerTable from './PoolMinerTable';

export type PoolMinersTabBindingProps = {
  onConfigurationPress: () => void;
  disableScroll: () => void;
  enableScroll: () => void;
};

export default observer(function PoolMinersTabBinding({
  onConfigurationPress,
  disableScroll,
  enableScroll,
}: PoolMinersTabBindingProps) {
  const {state} = useForcedContext(PurchaseScreenContext);
  const {purchaseDiscount} = useRoot();
  const onDiscountTimeOver = () => purchaseDiscount.deleteDiscount();
  const {getExpirationSeconds} = useCheckDiscount();
  const visibleDiscountSection =
    getExpirationSeconds() > 0 && purchaseDiscount.discount !== undefined;
  return (
    <View>
      {visibleDiscountSection && (
        <LimitedDiscount
          style={styles.discountSection}
          initialSeconds={getExpirationSeconds()}
          onTimeOver={onDiscountTimeOver}
        />
      )}
      <PoolMinerTable
        state={state}
        showPickHashrateModal={onConfigurationPress}
        disableScroll={disableScroll}
        enableScroll={enableScroll}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  section: {
    marginBottom: 15,
  },
  icon: {
    width: 32,
    height: 20,
  },
  discountSection: {
    marginBottom: 15,
  },
});
