import {
  endConnection,
  finishTransaction,
  getAvailablePurchases,
  getPendingPurchasesIOS,
  getProducts as getIapProducts,
  getPurchaseHistory,
  getReceiptIOS,
  getSubscriptions as getIapSubscriptions,
  initConnection,
  ProductPurchase,
  Purchase as OriginalInAppPurchase,
  PurchaseError,
  purchaseErrorListener,
  purchaseUpdatedListener,
  requestPurchase,
  requestSubscription,
  SubscriptionPurchase,
} from 'react-native-iap';
import {
  END_CONNECTION,
  END_CONNECTION_REQUEST,
  FINISH_TRANSACTION,
  FINISH_TRANSACTION_REQUEST,
  GET_AVAILABLE_PURCHASES,
  GET_AVAILABLE_PURCHASES_REQUEST,
  GET_PENDING_PURCHASES_IOS,
  GET_PENDING_PURCHASES_IOS_REQUEST,
  GET_PURCHASE_HISTORY,
  GET_PURCHASE_HISTORY_REQUEST,
  GET_RECEIPT_IOS,
  GET_RECEIPT_IOS_REQUEST,
  IapEvents,
  InAppPurchase,
  INIT_CONNECTION,
  INIT_CONNECTION_REQUEST,
  PURCHASE_ERROR_LISTENER,
  PURCHASE_UPDATED_LISTENER,
  REQUEST_PRODUCT,
  REQUEST_PRODUCT_REQUEST,
  REQUEST_SUBSCRIPTION,
  REQUEST_SUBSCRIPTION_REQUEST,
  RequestPurchaseParams,
} from './InAppPurchase';
import {RouterImpl} from '../structure';
import {PurchaseId} from '../units';
import {AppStoreReceipt} from '../ApiStore';
import {sortBy} from 'lodash';

export default class InAppPurchaseImpl implements InAppPurchase {
  async initConnection() {
    this.events.send(INIT_CONNECTION_REQUEST, undefined);
    const response = await initConnection();
    this.events.send(INIT_CONNECTION, response);
    return response;
  }

  async endConnection() {
    this.events.send(END_CONNECTION_REQUEST, undefined);
    await endConnection();
    this.events.send(END_CONNECTION, undefined);
  }

  async getAvailablePurchases() {
    this.events.send(GET_AVAILABLE_PURCHASES_REQUEST, undefined);
    const response = await getAvailablePurchases();
    this.events.send(GET_AVAILABLE_PURCHASES, response);
    return response;
  }

  async getPurchaseHistory() {
    this.events.send(GET_PURCHASE_HISTORY_REQUEST, undefined);
    const response = await getPurchaseHistory();
    this.events.send(GET_PURCHASE_HISTORY, response);
    return response;
  }

  async getSubscriptions(skus: PurchaseId[]) {
    const subscriptions = await getIapSubscriptions({skus});
    return sortBy(subscriptions, (item) =>
      skus.indexOf(item.productId as PurchaseId),
    );
  }

  async getProducts(skus: PurchaseId[]) {
    const products = await getIapProducts({skus});
    return sortBy(products, (item) =>
      skus.indexOf(item.productId as PurchaseId),
    );
  }

  async requestSubscription(params: RequestPurchaseParams) {
    this.events.send(REQUEST_SUBSCRIPTION_REQUEST, params);
    const response = await requestSubscription({
      sku: params.sku,
      andDangerouslyFinishTransactionAutomaticallyIOS: false,
      purchaseTokenAndroid: params.purchaseTokenAndroid,
      subscriptionOffers: params.subscriptionOfferTokenAndroid
        ? [{offerToken: params.subscriptionOfferTokenAndroid, sku: params.sku}]
        : [],
      prorationModeAndroid: undefined,
      obfuscatedAccountIdAndroid: params.obfuscatedAccountIdAndroid,
      obfuscatedProfileIdAndroid: params.obfuscatedProfileIdAndroid,
    });
    this.events.send(REQUEST_SUBSCRIPTION, response);
  }
  async requestProduct(params: RequestPurchaseParams) {
    this.events.send(REQUEST_PRODUCT_REQUEST, params);
    const response = await requestPurchase({
      sku: params.sku,
      skus: [params.sku],
      andDangerouslyFinishTransactionAutomaticallyIOS: false,
      obfuscatedAccountIdAndroid: params.obfuscatedAccountIdAndroid,
      obfuscatedProfileIdAndroid: params.obfuscatedProfileIdAndroid,
    });
    this.events.send(REQUEST_PRODUCT, response);
  }

  /**
   * @param purchase
   * @param {boolean} isConsumable Checks if purchase is consumable. Has effect on android.
   */
  async finishTransaction(purchase: ProductPurchase, isConsumable: boolean) {
    this.events.send(FINISH_TRANSACTION_REQUEST, purchase);
    const response = await finishTransaction({purchase, isConsumable});
    this.events.send(FINISH_TRANSACTION, response);
    return response;
  }

  async getPendingPurchasesIOS() {
    this.events.send(GET_PENDING_PURCHASES_IOS_REQUEST, undefined);
    const response = await getPendingPurchasesIOS();
    this.events.send(GET_PENDING_PURCHASES_IOS, response);
    return response;
  }

  async getReceiptIOS() {
    this.events.send(GET_RECEIPT_IOS_REQUEST, undefined);
    const response = (await getReceiptIOS({
      forceRefresh: false,
    })) as AppStoreReceipt;
    this.events.send(GET_RECEIPT_IOS, response);
    return response;
  }

  purchaseUpdatedListener(
    listener: (event: OriginalInAppPurchase | SubscriptionPurchase) => void,
  ) {
    this.events.send(PURCHASE_UPDATED_LISTENER, undefined);
    return purchaseUpdatedListener(listener);
  }

  purchaseErrorListener(listener: (errorEvent: PurchaseError) => void) {
    this.events.send(PURCHASE_ERROR_LISTENER, undefined);
    return purchaseErrorListener(listener);
  }

  public readonly events = new RouterImpl<IapEvents>();
}
