import {ReadonlyDeep} from 'type-fest';
import {GroupRecord, WorkerRecord} from './DemonstrationDatabase';
import {pick} from 'lodash';
import {WorkerId} from '../ApiStore';

export default (
  groups: ReadonlyDeep<GroupRecord[]>,
  workerIds: readonly WorkerId[],
): Record<string, WorkerRecord> =>
  workerIds.length > 0
    ? groups.reduce(
        (result, group) => ({...result, ...pick(group.workersById, workerIds)}),
        {},
      )
    : {};
