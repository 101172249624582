import {action, makeObservable, observable} from 'mobx';
import BasePurchaseScreenStateImpl from './BasePurchaseScreenStateImpl';
import {InAppOffers, PaymentMethod} from '../../InAppOffersService';
import {Auth} from '../../Auth';
import {ProposalsState} from '../../ProposalsState';
import {CurrentSlotSubscription} from '../../CurrentSlotSubscriptionService';
import {InAppPurchase} from '../../InAppPurchase';

export default class WebPurchaseScreenStateImpl extends BasePurchaseScreenStateImpl {
  @observable _selectedPaymentMethod: PaymentMethod = PaymentMethod.Card;

  constructor(
    protected readonly _root: {
      readonly auth: Auth;
      readonly proposalsState: ProposalsState;
      readonly currentSlotSubscription: CurrentSlotSubscription;
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchase: InAppPurchase;
    },
  ) {
    super(_root);
    makeObservable(this);
  }

  selectPaymentMethod = action((method: PaymentMethod) => {
    this._selectedPaymentMethod = method;
  });

  get selectedPaymentMethod(): PaymentMethod {
    return this._selectedPaymentMethod;
  }
}
