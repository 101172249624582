import {RefParser} from './RefParser';
import {Either, error, success} from '../fp';

/**
 * @example com.example://?ref=x.x.x.x -> x.x.x.x
 */
export default class UrlParameterRefParserImpl implements RefParser {
  parse(str: string): Either<string, void> {
    const url = new URL(str);
    const ref = url.searchParams.get('ref');
    if (ref) {
      return success(String(ref));
    }
    return error(undefined);
  }
}
