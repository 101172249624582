import React from 'react';
import {observer} from 'mobx-react-lite';
import {PageFlatList} from '../containers';
import {useRoot} from '../Root/hooks';
import {
  FlatListProps,
  StyleSheet,
  Button,
  View,
  ScrollView,
} from 'react-native';
import {LogRecord} from '../Log';
import LogItem from './LogItem';
import {createNullableContext, useForcedContext} from '../context';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';
import {variance} from '../styling';

export interface LogScreenProps {
  clearLog: () => void;
  copyLog: () => void;
  saveLog: () => void;
  shareLog: () => void;
}

export default observer((props: LogScreenProps) => {
  const {logFilter} = useRoot();
  return (
    <LogScreenContext.Provider value={props}>
      <DesktopWebAppHeader />
      <PageFlatList
        ListHeaderComponent={ListHeaderComponent}
        data={logFilter.records}
        extraData={logFilter.records.length}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ItemSeparatorComponent={Separator}
      />
    </LogScreenContext.Provider>
  );
});

const LogScreenContext = createNullableContext<LogScreenProps>();

type ListProps = FlatListProps<LogRecord>;

const keyExtractor: NonNullable<ListProps['keyExtractor']> = (item) =>
  String(item.id);

const renderItem: ListProps['renderItem'] = ({item}) => (
  <LogItem content={item.content} capturedAt={item.capturedAt} />
);

const Separator = variance(View)((theme) => ({
  root: {
    height: 1,
    backgroundColor: theme.palette.border,
  },
}));

const layout = StyleSheet.create({
  filter: {flexDirection: 'row'},
  criteria: {margin: 8},
});

const ListHeaderComponent = observer(() => {
  const {clearLog, copyLog, saveLog, shareLog} =
    useForcedContext(LogScreenContext);
  const {logFilter} = useRoot();
  const {ignoreStates, toggleStatesIgnorance} = logFilter;
  return (
    <ScrollView
      horizontal
      style={layout.filter}
      showsHorizontalScrollIndicator={false}>
      <View style={layout.criteria}>
        <Button onPress={clearLog} color="red" title="Clear" />
      </View>
      <View style={layout.criteria}>
        <Button
          onPress={toggleStatesIgnorance}
          title={ignoreStates ? 'Show worker states' : 'Hide worker states'}
        />
      </View>
      <View style={layout.criteria}>
        <Button onPress={copyLog} title="Copy" />
      </View>
      <View style={layout.criteria}>
        <Button onPress={shareLog} title="Share" />
      </View>
      <View style={layout.criteria}>
        <Button onPress={saveLog} title="Save" />
      </View>
    </ScrollView>
  );
});
