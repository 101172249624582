import {ReadonlyDeep} from 'type-fest';
import {GroupRecord, WorkerOfGroupRecord} from './DemonstrationDatabase';

export default (groups: ReadonlyDeep<GroupRecord[]>) =>
  groups.reduce<Record<string, WorkerOfGroupRecord>>(
    (result, group) => ({
      ...result,
      ...Object.fromEntries(
        Object.entries(group.workersById).map(([id, worker]) => [
          id,
          {...worker, groupId: group.id} as WorkerOfGroupRecord,
        ]),
      ),
    }),
    {},
  );
