import {APP_WINDOW_ACTIVE, AppWindowState} from '../AppWindow';
import {ApiStore} from '../ApiStore';
import {action, comparer, observable, reaction, makeObservable} from 'mobx';
import {Opaque} from 'type-fest';
import {SECOND} from '../utils/time';
import {success} from '../fp';
import {Auth} from '../Auth';
import {batchDisposers, Disposer, Service} from '../structure';

export default class PingService implements Service {
  constructor(
    private readonly _root: {
      readonly appWindowState: AppWindowState;
      readonly apiStore: ApiStore;
      readonly auth: Auth;
    },
  ) {
    makeObservable(this);
  }

  @observable private _interval = 0 as Opaque<number, 'Millisecond'>;

  @action private _setInterval(interval: number) {
    this._interval = (interval * SECOND) as Opaque<number, 'Millisecond'>;
  }

  private _listenToIntervalUpdates = () =>
    this._root.apiStore.server.call(
      'set_ping_interval',
      async (params, response) => {
        this._setInterval(params.interval);
        return response.respond(success(null));
      },
    );

  private _disposeInterval = () => {};

  private _pingByInterval = () =>
    reaction(
      () =>
        [
          this._interval,
          this._root.appWindowState.status,
          this._root.auth.isConnected,
        ] as const,
      async ([interval, appStatus, isConnected]) => {
        this._disposeInterval();
        if (interval > 0 && appStatus === APP_WINDOW_ACTIVE && isConnected) {
          const id = setInterval(
            () => this._root.apiStore.client.call('ping'),
            interval,
          );
          this._disposeInterval = () => clearInterval(id);
          await this._root.apiStore.client.call('ping');
        }
      },
      {fireImmediately: true, equals: comparer.shallow},
    );

  subscribe() {
    return batchDisposers(
      this._listenToIntervalUpdates(),
      this._pingByInterval(),
      (() => this._disposeInterval()) as Disposer,
    );
  }

  @action reset() {
    this._interval = 0 as Opaque<number, 'Millisecond'>;
  }
}
