import React from 'react';
import {observer} from 'mobx-react-lite';
import {View, StyleSheet} from 'react-native';
import {CannonBinding} from '../CannonBinding';
import {NoConnectionContainer} from '../NoConnectionContainer';
import StatusBar from '../components/StatusBar';
import Navigation from './Navigation';

export default observer(() => {
  return (
    <View style={styles.root}>
      <StatusBar />
      <Navigation />
      <View style={styles.layer}>
        <NoConnectionContainer />
      </View>
      <View style={styles.layer}>
        <CannonBinding />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
    overflow: 'hidden',
  },
  layer: {
    backgroundColor: 'transparent',
    pointerEvents: 'box-none',
    ...StyleSheet.absoluteFillObject,
  },
});
