import React from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {useCallback, useEffect} from 'react';
import {LinkWorkerModalsManager} from '../../LinkWorkerModalsManager';
import {useRoot} from '../../Root/hooks';
import {useResetToDashboard} from '../hooks';
import {DASHBOARD} from './constants';

export const FARM_ACTIONS = ['add', 'addcode'];

export type FarmActionBindingProps = RootStackBindingProps<'FarmAction'> & {};

export default observer((props: FarmActionBindingProps) => {
  const {navigation, route} = props;
  const action = route.params.action;
  const {navigationContainer, initialStateNavigation, appLifecycle} = useRoot();
  const resetToDashboard = useResetToDashboard();
  const goToPoolPlans = useCallback(() => {
    const params = {
      onSuccess: DASHBOARD,
      index: 1,
    };
    const state = navigation.getState();
    if (state) {
      const previousRoute = state.routes[state.routes.length - 2]; // Previous route
      if (previousRoute && previousRoute.name === 'Plan') {
        navigation.goBack();
        return;
      }
    }
    navigation.replace('Plan', params);
  }, [navigation]);
  useEffect(() => {
    if (
      navigationContainer.isReady &&
      initialStateNavigation.appNavigationIsReadyToRender
    ) {
      if (!action || !FARM_ACTIONS.includes(action)) {
        resetToDashboard();
      } else if (action === 'addcode' && appLifecycle.initialized) {
        if (appLifecycle.hasJustBeenInstalled) {
          resetToDashboard();
        } else {
          goToPoolPlans();
        }
      }
    }
  }, [
    action,
    resetToDashboard,
    goToPoolPlans,
    navigationContainer.isConfigured,
    initialStateNavigation.appNavigationIsReadyToRender,
    navigationContainer.isReady,
    appLifecycle.initialized,
    appLifecycle.hasJustBeenInstalled,
  ]);
  switch (route.params?.action) {
    case 'add':
      return (
        <LinkWorkerModalsManager
          goToDashboard={resetToDashboard}
          linkKey={route.params.key}
        />
      );
    default:
      return null;
  }
});
