import React from 'react';
import {observer} from 'mobx-react-lite';
import {Platform, Text} from 'react-native';
import {variance} from '../styling';
import {TouchableOpacity} from 'react-native-gesture-handler';
import LockSvg from '../assets/svg/colorless/lock.svg';
import ChevronRightSvg from '../assets/svg/colorless/chevronRight.svg';
import {sized} from '../Svg';
import {useForcedContext} from '../context';
import {DashboardContext} from './context';
import {useStrings} from '../Root/hooks';

export default observer(function DemoModeSection() {
  const {goToPlan} = useForcedContext(DashboardContext);
  const strings = useStrings();
  return (
    <RootTouchable activeOpacity={0.8} onPress={goToPlan}>
      <LockIcon />
      <TitleText>{strings['stop.demo']}</TitleText>
      <ChevronIcon />
    </RootTouchable>
  );
});

const LockIcon = variance(sized(LockSvg, 16))(
  () => ({root: {}}),
  () => ({color: '#ffffff'}),
);
const ChevronIcon = variance(sized(ChevronRightSvg, 6, 11))(
  () => ({root: {}}),
  () => ({color: '#ffffff'}),
);

const RootTouchable = variance(TouchableOpacity)((theme) => ({
  root: {
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 4,
    borderWidth: 0,
    height: 40,
    backgroundColor: theme.palette.error,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
  },
}));

const TitleText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: '#ffffff',
    fontSize: 14,
    lineHeight: 17,
    marginRight: 16,
    marginLeft: 10,
    textTransform: 'uppercase',
  },
}));
