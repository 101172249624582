import {Theme} from '@react-navigation/native/lib/typescript/src/types';

import {useRoot} from '../../Root/hooks';
import {DefaultTheme} from '@react-navigation/native';
import {useTheme} from '../../styling';

export default (): Theme => {
  const theme = useTheme();
  const {
    appearance: {isDark},
  } = useRoot();
  const palette = theme.palette;
  return {
    ...DefaultTheme,
    dark: isDark,
    colors: {
      ...DefaultTheme.colors,
      primary: palette.secondary,
      background: palette.back,
      card: palette.bar,
      border: palette.border,
      text: palette.icon,
    },
  };
};
