import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {ModalBackdrop, ModalTitle} from '../modals/components';
import {useStrings} from '../Root/hooks';
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  Modal,
  FlatListProps,
  Pressable,
  ListRenderItem,
} from 'react-native';
import {colors} from './constant';
import {useStyles, useTheme, variance} from '../styling';
import {sized} from '../Svg';
import ChevronRightSvg from '../assets/svg/colorless/chevronRight.svg';

import getReadableColor from './getReadableColor';
import CloseSvg from '../assets/svg/colorless/close.svg';

export interface SelectMiningSpeedModalProps {
  onSelect: (speed: number) => void;
  onDismiss: () => void;
}

export default observer(
  ({onDismiss, onSelect}: SelectMiningSpeedModalProps) => {
    const strings = useStrings();
    const styles = useStyles((theme) => ({
      root: {
        flex: 1,
        padding: 15,
        justifyContent: 'center',
        alignItems: 'center',
      },
      container: {
        width: '100%',
        maxWidth: 600,
        backgroundColor: theme.palette.background,
        borderWidth: 1,
        borderColor: theme.palette.border,
        borderRadius: 4,
        position: 'relative',
        padding: 15,
      },
      title: {
        marginBottom: 20,
      },
      closeTouchable: {
        position: 'absolute',
        zIndex: 2,
        top: 15,
        right: 10,
      },
    }));
    const theme = useTheme();
    const renderItem: ListRenderItem<[number, string]> = useCallback(
      ({item}) => (
        <ListItem onPress={() => onSelect(item[0])}>
          <Square bordered={item[0] === 0} style={{backgroundColor: item[1]}} />
          <ListItemText>{getReadableColor(item[0], strings)}</ListItemText>
          <ChevronRightIcon color={theme.palette.icon} />
        </ListItem>
      ),
      [onSelect, strings, theme.palette.icon],
    );
    return (
      <Modal
        onDismiss={onDismiss}
        onRequestClose={onDismiss}
        animationType="fade"
        transparent
        hardwareAccelerated
        statusBarTranslucent={false}>
        <View style={styles.root}>
          <ModalBackdrop onPress={onDismiss} />
          <View style={styles.container}>
            <ModalTitle style={styles.title}>
              {strings['scheduler.setMiningSpeed']}
            </ModalTitle>
            <FlatList
              style={{height: theme.window.height * 0.5}}
              showsVerticalScrollIndicator={false}
              data={Array.from(colors).reverse()}
              renderItem={renderItem}
              keyExtractor={keyExtractor}
              ItemSeparatorComponent={Separator}
            />
            {onDismiss && (
              <Pressable
                onPress={onDismiss}
                style={styles.closeTouchable}
                hitSlop={5}>
                <CloseIcon color={theme.palette.icon} />
              </Pressable>
            )}
          </View>
        </View>
      </Modal>
    );
  },
);
const CloseIcon = sized(CloseSvg, 14);
const keyExtractor: ListProps['keyExtractor'] = (item) => String(item[0]);
type ListProps = FlatListProps<[number, string]>;

const ChevronRightIcon = sized(ChevronRightSvg, 5, 10);

const ListItem = variance(TouchableOpacity)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 44,
  },
}));

const ListItemText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    flexGrow: 1,
    flex: 1,
    color: theme.palette.textPrimary,
  },
}));

const Square = variance(View)((theme) => ({
  root: {
    width: 16,
    height: 16,
    borderRadius: 2,
    marginRight: 10,
  },
  bordered: {
    borderWidth: 1,
    borderColor: theme.palette.border,
  },
}));

const Separator = variance(View)((theme) => ({
  root: {
    height: 1,
    backgroundColor: theme.palette.border,
  },
}));
