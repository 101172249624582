import {ReadonlyDeep} from 'type-fest';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {useStrings} from '../../../../Root/hooks';
import {Text, View} from 'react-native';
import {CoreTable} from './CoreTable';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useWorkerState} from '../../../../WorkerStateRegistry';
import {createStylesHook, useTheme} from '../../../../styling';
import isWorkerOffline from '../../../checkers/isWorkerOffline';
import {sized} from '../../../../Svg';
import FilledQuestionMarkSvg from '../../../../assets/svg/colorless/filledQuestionMark.svg';
import {DESKTOP_BREAKPOINT} from '../../../../units/constants';

export interface SlotContentProps {
  worker: ReadonlyDeep<WorkerEntity>;
  warning: boolean;
}

export default observer(({worker, warning}: SlotContentProps) => {
  const state = useWorkerState(worker.id);
  const strings = useStrings();
  const styles = useStyles();
  const theme = useTheme();

  return (
    <View style={styles.root}>
      <View style={[styles.row, styles.rowTemp]}>
        <Text style={styles.rowTitle}>{strings['worker.coresTableTitle']}</Text>
        {warning && <QuestionMarkIcon color={theme.palette.error} />}
      </View>
      <CoreTable
        cpus={state?.cpu_states}
        disabled={isWorkerOffline(state)}
        warning={warning}
      />
    </View>
  );
});

const QuestionMarkIcon = sized(FilledQuestionMarkSvg, 12);

const useStyles = createStylesHook((theme) => ({
  root: {
    flex: 14,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        flex: 1,
      },
    }),
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  rowSpeed: {
    marginBottom: 15,
  },
  rowTemp: {
    marginBottom: 6,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        marginBottom: 10,
      },
    }),
  },
  rowTitle: {
    ...theme.fontByWeight('700'),
    fontSize: 11,
    lineHeight: 13,
    letterSpacing: 0.03,
    color: theme.palette.textSecondary,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 12,
        lineHeight: 14,
      },
    }),
  },
}));
