import {Authorized, Connected, ConnectionFailed} from './AuthState';
import {ApiStore, FarmId, NODEMO_TAG} from '../ApiStore';
import {getSelectedAccountId, setSelectedAccountId} from '../persistence';
import {Platform} from 'react-native';
import {timezone} from 'expo-localization';
import {PendingPurchasesResolver} from '../PendingPurchasesResolver';
import {ApiMode} from '../farmApi';

/**
 * @throws {never}
 */
export default async (
  accountId: FarmId | null,
  state: Authorized | ConnectionFailed,
  root: {
    readonly apiStore: ApiStore;
    readonly pendingPurchasesResolver: PendingPurchasesResolver;
  },
): Promise<Authorized | Connected | ConnectionFailed> => {
  let stored = false;
  if (accountId === null) {
    if (state.accountIds.size === 1) {
      accountId = state.accountIds.values().next().value as FarmId;
    } else {
      const accountId_ = await getSelectedAccountId();
      if (!accountId_.success || accountId_.right === null) {
        return state;
      }
      accountId = accountId_.right;
      stored = true;
    }
  }
  if (!state.accountIds.has(accountId)) {
    return state;
  }
  if (!stored) {
    await setSelectedAccountId(accountId);
  }
  const connection_ = await root.apiStore.client.call('connect', {
    farm_id: accountId,
    timezone,
    utc_offset: -new Date().getTimezoneOffset(),
  });
  if (!connection_.success) {
    return {...state, kind: 'ConnectionFailed', raw: connection_.left};
  }
  if (Platform.OS === 'android' || Platform.OS === 'ios') {
    await root.pendingPurchasesResolver.resolve();
  }
  const farm = state.subscriptionMap.get(accountId);
  if (!farm) {
    return {
      ...state,
      kind: 'ConnectionFailed',
      raw: new Error('Requested a connection to the non-existent farm'),
    };
  }
  const response = connection_.right;
  const tags = new Set(response.tags);
  root.apiStore.setMode(tags.has(NODEMO_TAG) ? ApiMode.Real : farm.mode);

  const purchaseDiscount: Connected['purchaseDiscount'] = response.promo_code
    ? {
        code: response.promo_code,
      }
    : undefined;
  if (purchaseDiscount && response.promo_code_ttl) {
    purchaseDiscount.ttl = response.promo_code_ttl;
  }
  return {
    ...state,
    kind: 'Connected',
    accountId,
    apiMode: farm.mode,
    tags,
    purchaseTags: response.purchase_tags,
    settings: response.settings,
    purchaseDiscount,
  };
};
