import React from 'react';
import {observer} from 'mobx-react-lite';
import {LogScreen} from '../../LogScreen';
import {useCallback} from 'react';
import {useRoot} from '../../Root/hooks';
import useLogExporter from '../../LogExporter/useLogExporter';

export default observer(() => {
  const {log} = useRoot();
  const clearLogs = useCallback(() => log.reset(), [log]);
  const {copyLog, saveLog, shareLog} = useLogExporter();
  return (
    <LogScreen
      clearLog={clearLogs}
      copyLog={copyLog}
      saveLog={saveLog}
      shareLog={shareLog}
    />
  );
});
