import {GetMiningStatisticResponse} from '../../universal/features/api/entity/GetMiningStatisticResponse';
import {Graph} from '../GraphPanel';
import {Millisecond, Second} from '../utils/time';

export default (
  {points}: GetMiningStatisticResponse,
  _interval: Millisecond,
): Graph | undefined => {
  const interval: Second = Math.floor(_interval / 1000);
  if (points.length >= 1) {
    const sum = new Map<number, number>();
    let from = NaN;
    let to = NaN;
    for (const {timestamp, amount} of points) {
      const acc = sum.get(timestamp);
      sum.set(timestamp, (acc ?? 0) + amount);
      from = timestamp > from ? from : timestamp;
      to = timestamp < to ? to : timestamp;
    }
    const series: number[] = [];
    for (let t = from; t <= to; t += interval) {
      series.push((sum.get(t) ?? 0) / 1e4); // now satoshi
    }
    return {
      from: from * 1000,
      to: to * 1000,
      series,
    };
  }
  return undefined;
};
