import React from 'react';
import {observer} from 'mobx-react-lite';
import {sized} from '../Svg';
import FanCaseSvg from '../assets/svg/colorless/fanCase.svg';
import FanPropellerSvg from '../assets/svg/colorless/fanPropeller.svg';
import {Platform, View, ViewProps} from 'react-native';
import {View as AnimatableView} from 'react-native-animatable';
import {useStyles, useTheme} from '../styling';
import Svg, {Path} from 'react-native-svg';

type AnimatableFanProps = ViewProps & {
  size?: number;
};

export default observer(({style, size = 100, ...rest}: AnimatableFanProps) => {
  const styles = useStyles((theme) => ({
    root: {
      width: size,
      height: size,
    },
    layer: {
      position: 'absolute',
      start: 0,
      top: 0,
      end: 0,
      bottom: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
    proLayer: {
      position: 'absolute',
      right: 0,
      top: size / 2 - 35 / 2,
    },
    fanCase: {},
    fanPropeller: {},
    circle: {
      width: 24.4,
      height: 24.4,
      borderRadius: 12.2,
      backgroundColor: theme.select(
        theme.palette.foreground,
        theme.palette.primary,
      ),
    },
  }));
  const theme = useTheme();
  const propeller = theme.select(
    theme.palette.primary,
    theme.palette.foreground,
  );
  const fanCase = theme.select(theme.palette.foreground, theme.palette.primary);

  return (
    <View style={[styles.root, style]} {...rest}>
      <FanCase color={fanCase} />
      <View style={styles.layer}>
        <AnimatableView
          animation="rotate"
          duration={1000}
          easing="linear"
          iterationCount="infinite"
          useNativeDriver>
          <FanPropeller color={propeller} />
        </AnimatableView>
      </View>
      <View style={styles.layer}>
        <View style={styles.circle} />
      </View>
      {/* !Label Pro only for ios! */}
      {Platform.OS === 'ios' && (
        <View style={styles.proLayer}>
          <Svg width="52.5" height="35" viewBox="0 0 21 14" fill="none">
            <Path
              d="M21.6666 2L21.6666 1.33333L21 1.33333L6.66663 1.33333C3.53701 1.33333 0.999959 3.87039 0.999959 7C0.999959 10.1296 3.53702 12.6667 6.66663 12.6667L21 12.6667L21.6666 12.6667L21.6666 12L21.6666 2Z"
              fill="black"
              stroke="white"
              strokeWidth="1.33333"
            />
            <Path
              d="M4.66663 9.59561V4.40423H6.81111C7.19979 4.40423 7.53524 4.48027 7.81745 4.63236C8.10135 4.78277 8.3202 4.99316 8.47398 5.26354C8.62776 5.53224 8.70465 5.84487 8.70465 6.20144C8.70465 6.5597 8.62607 6.87318 8.46891 7.14187C8.31344 7.40888 8.09121 7.61589 7.80224 7.76291C7.51327 7.90993 7.17022 7.98344 6.77309 7.98344H5.4499V6.99485H6.53988C6.72915 6.99485 6.88716 6.9619 7.0139 6.89599C7.14233 6.83008 7.2395 6.73798 7.30541 6.61969C7.37132 6.49971 7.40427 6.36029 7.40427 6.20144C7.40427 6.0409 7.37132 5.90233 7.30541 5.78572C7.2395 5.66743 7.14233 5.57618 7.0139 5.51196C6.88547 5.44774 6.72746 5.41563 6.53988 5.41563H5.92138V9.59561H4.66663Z"
              fill="#F7931A"
            />
            <Path
              d="M9.32315 9.59561V4.40423H11.4676C11.8563 4.40423 12.1918 4.47436 12.474 4.61462C12.7579 4.75319 12.9767 4.9526 13.1305 5.21285C13.2843 5.4714 13.3612 5.77812 13.3612 6.133C13.3612 6.49295 13.2826 6.79882 13.1254 7.05062C12.9683 7.30072 12.7452 7.49168 12.4562 7.62349C12.1673 7.75362 11.8251 7.81868 11.4296 7.81868H10.0735V6.83008H11.1964C11.3857 6.83008 11.5437 6.80558 11.6704 6.75657C11.7989 6.70588 11.896 6.62983 11.9619 6.52844C12.0278 6.42535 12.0608 6.29354 12.0608 6.133C12.0608 5.97246 12.0278 5.8398 11.9619 5.73503C11.896 5.62856 11.7989 5.54914 11.6704 5.49675C11.542 5.44267 11.384 5.41563 11.1964 5.41563H10.5779V9.59561H9.32315ZM12.2458 7.22299L13.5386 9.59561H12.1698L10.9024 7.22299H12.2458Z"
              fill="#F7931A"
            />
            <Path
              d="M18.9493 6.99992C18.9493 7.57111 18.8394 8.05526 18.6197 8.45239C18.4 8.84952 18.1026 9.15117 17.7275 9.35733C17.354 9.5635 16.9349 9.66659 16.4702 9.66659C16.0038 9.66659 15.5838 9.56266 15.2104 9.3548C14.8369 9.14694 14.5403 8.84529 14.3206 8.44986C14.1026 8.05273 13.9936 7.56942 13.9936 6.99992C13.9936 6.42873 14.1026 5.94457 14.3206 5.54745C14.5403 5.15032 14.8369 4.84867 15.2104 4.6425C15.5838 4.43634 16.0038 4.33325 16.4702 4.33325C16.9349 4.33325 17.354 4.43634 17.7275 4.6425C18.1026 4.84867 18.4 5.15032 18.6197 5.54745C18.8394 5.94457 18.9493 6.42873 18.9493 6.99992ZM17.6666 6.99992C17.6666 6.66194 17.6185 6.37634 17.5221 6.14314C17.4275 5.90993 17.2906 5.73334 17.1115 5.61335C16.9341 5.49337 16.7203 5.43338 16.4702 5.43338C16.2218 5.43338 16.008 5.49337 15.8289 5.61335C15.6497 5.73334 15.512 5.90993 15.4157 6.14314C15.321 6.37634 15.2737 6.66194 15.2737 6.99992C15.2737 7.3379 15.321 7.62349 15.4157 7.8567C15.512 8.08991 15.6497 8.2665 15.8289 8.38648C16.008 8.50647 16.2218 8.56646 16.4702 8.56646C16.7203 8.56646 16.9341 8.50647 17.1115 8.38648C17.2906 8.2665 17.4275 8.08991 17.5221 7.8567C17.6185 7.62349 17.6666 7.3379 17.6666 6.99992Z"
              fill="#F7931A"
            />
          </Svg>
        </View>
      )}
    </View>
  );
});

const FanCase = sized(FanCaseSvg, 100);
const FanPropeller = sized(FanPropellerSvg, 76);
