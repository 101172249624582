import {batchDisposers, Service} from '../structure';
import {Core} from './Core';
import {AppLifecycleService} from '../AppLifecycle';
import {ConfigurationService} from '../Configuration';
import {computed, makeObservable} from 'mobx';
import {Headless} from '../Headless';

export default class CoreService implements Core, Service {
  private readonly _headless: Headless;

  constructor(private readonly _parent: {readonly headless: Headless}) {
    makeObservable(this);
    this._headless = this._parent.headless;
  }

  get headless() {
    return this._headless;
  }

  readonly appLifecycle = new AppLifecycleService();
  readonly configuration = new ConfigurationService(this);

  get debug() {
    return this.configuration;
  }

  @computed get initialized() {
    return this.appLifecycle.initialized && this.configuration.initialized;
  }

  subscribe() {
    return batchDisposers(
      this.appLifecycle.subscribe(),
      this.configuration.subscribe(),
    );
  }
}
