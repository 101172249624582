import React, {useCallback} from 'react';
import {View, TouchableOpacity, Text} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../styling';
import getClosestColor from './getClosestColor';
import SelectMiningSpeedModal from './SelectMiningSpeedModal';
import {useBoolean} from '../hooks';
import {SelectedSpeed} from './types';
import CaretDownSvg from '../assets/svg/colorless/caretDown.svg';
import {sized} from '../Svg';

import {useStrings} from '../Root/hooks';
import getReadableColor from './getReadableColor';

export interface SpeedToggleListProps {
  selectedSpeed: SelectedSpeed;
  pickedCustomSpeed: number;
  onChangeSpeed: (speed: SelectedSpeed) => void;
}

export default observer(
  ({selectedSpeed, pickedCustomSpeed, onChangeSpeed}: SpeedToggleListProps) => {
    const [visibleModal, showModal, closeModal] = useBoolean(false);
    const theme = useTheme();
    const strings = useStrings();
    const handleSelectSpeed = useCallback(
      (speed: number) => {
        closeModal();
        onChangeSpeed({speed, custom: true});
      },
      [closeModal, onChangeSpeed],
    );
    const {speed, custom} = selectedSpeed;
    return (
      <>
        <SpeedToggleList>
          <SpeedItemCol>
            <SpeedItem
              accessibilityRole="button"
              activeOpacity={0.8}
              selected={speed === 100 && !custom}
              onPress={() => onChangeSpeed({speed: 100})}>
              <Square style={{backgroundColor: getClosestColor(100)}} />
              <SpeedItemTitle>100%</SpeedItemTitle>
            </SpeedItem>
          </SpeedItemCol>
          <SpeedItemCol>
            <SpeedItem
              accessibilityRole="button"
              activeOpacity={0.8}
              selected={speed === 50 && !custom}
              onPress={() => onChangeSpeed({speed: 50})}>
              <Square style={{backgroundColor: getClosestColor(50)}} />
              <SpeedItemTitle>50%</SpeedItemTitle>
            </SpeedItem>
          </SpeedItemCol>
          <SpeedItemCol>
            <SpeedItem
              accessibilityRole="button"
              activeOpacity={0.8}
              onPress={() =>
                custom
                  ? showModal()
                  : onChangeSpeed({speed: pickedCustomSpeed, custom: true})
              }
              selected={custom}>
              <Square
                style={{backgroundColor: getClosestColor(pickedCustomSpeed)}}
              />
              <SpeedItemTitle>
                {getReadableColor(pickedCustomSpeed, strings)}
              </SpeedItemTitle>
              <SpeedItemCaret>
                <CaretDownIcon color={theme.palette.icon} />
              </SpeedItemCaret>
            </SpeedItem>
          </SpeedItemCol>
          <SpeedItemCol>
            <SpeedItem
              accessibilityRole="button"
              activeOpacity={0.8}
              selected={speed === 0 && !custom}
              onPress={() => onChangeSpeed({speed: 0})}>
              <Square style={{backgroundColor: getClosestColor(0)}} />
              <SpeedItemTitle>{strings['scheduler.power.off']}</SpeedItemTitle>
            </SpeedItem>
          </SpeedItemCol>
        </SpeedToggleList>
        {visibleModal && (
          <SelectMiningSpeedModal
            onSelect={handleSelectSpeed}
            onDismiss={closeModal}
          />
        )}
      </>
    );
  },
);

const CaretDownIcon = sized(CaretDownSvg, 10);

const SpeedToggleList = variance(View)((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 2,
    justifyContent: 'space-between',
    marginHorizontal: -10,
    ...theme.mediaQuery({
      530: {
        justifyContent: 'center',
      },
    }),
  },
}));

const SpeedItemCol = variance(View)((theme) => ({
  root: {
    flexBasis: '50%',
    ...theme.mediaQuery({
      530: {
        flexBasis: 'auto',
      },
    }),
  },
}));

const SpeedItem = variance(TouchableOpacity)((theme) => ({
  root: {
    marginBottom: 10,
    marginHorizontal: 10,
    flex: 1,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: theme.palette.border,
    paddingVertical: 8,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  selected: {
    borderWidth: 1,
    borderColor: theme.palette.secondary,
  },
}));

const SpeedItemCaret = variance(View)(() => ({
  root: {
    marginLeft: 'auto',
  },
}));

const SpeedItemTitle = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    letterSpacing: 0.04,
    fontSize: 14,
    marginLeft: 10,
  },
}));

const Square = variance(View)((theme) => ({
  root: {
    width: 16,
    height: 16,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: theme.palette.border,
  },
}));
