import React from 'react';
import {Text} from 'react-native';
import {useStyles} from '../styling';
import {FullLogo} from './FullLogo';

interface HeaderTitleProps {
  title?: string;
}

export default ({title}: HeaderTitleProps) => {
  const styles = useStyles((theme) => ({
    text: {
      ...theme.fontByWeight('700'),
      fontSize: 16,
      color: theme.palette.textPrimary,
    },
  }));
  if (title) {
    return (
      <Text numberOfLines={1} style={styles.text}>
        {title}
      </Text>
    );
  }
  return <FullLogo />;
};
