import React from 'react';
import {View, Text} from 'react-native';
import ListItem from './ListItem';
import {useStyles, variance} from '../../styling';
import {observer} from 'mobx-react-lite';
import {Product} from '../../Products/Product';
import {useStrings} from '../../Root/hooks';
import {CryptoLoader} from '../../components/CryptoLoader';
import {LG_BREAKPOINT, MD_BREAKPOINT} from '../../units/constants';

const ListCards = observer((props: {data: Product[] | undefined}) => {
  const {data} = props;
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    container: {
      paddingHorizontal: 30,
      paddingVertical: 20,
      marginHorizontal: -20,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      rowGap: 15,
      alignItems: 'stretch',
      paddingTop: 15,
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          paddingTop: 40,
          rowGap: 20,
        },
      }),
    },
    item: {
      paddingHorizontal: 10,
      width: '100%',
      ...theme.mediaQuery({
        [MD_BREAKPOINT]: {
          width: '50%',
        },
        [LG_BREAKPOINT]: {
          width: '33.3%',
        },
      }),
    },
  }));
  if (data?.length === 0) {
    return (
      <View style={{marginTop: 20}}>
        <CryptoLoader />
      </View>
    );
  }
  if (data === undefined) {
    return <ErrorText>{strings['error.occurredWithStatusCode']}</ErrorText>;
  }
  return (
    <View style={styles.container}>
      {data?.map((item, index) => (
        <View style={styles.item} key={index}>
          <ListItem key={index} item={item} index={index} />
        </View>
      ))}
    </View>
  );
});
const ErrorText = variance(Text)((theme) => ({
  root: {
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 17,
    color: theme.palette.primary,
    marginTop: 20,
  },
}));

export default ListCards;
