import React, {PropsWithChildren} from 'react';
import {Text, StyleProp, TextStyle, TextProps} from 'react-native';
import {observer} from 'mobx-react-lite';
import {createStylesHook} from '../../styling';

interface ModalDescriptionProps extends TextProps {
  children: React.ReactNode;
  style?: StyleProp<TextStyle>;
}

export default observer((props: PropsWithChildren<ModalDescriptionProps>) => {
  const {children, style, ...rest} = props;
  const styles = useStyles();
  return (
    <Text style={[styles.text, style]} {...rest}>
      {children}
    </Text>
  );
});

const useStyles = createStylesHook((theme) => ({
  text: {
    fontSize: 14,
    lineHeight: 22,
    textAlign: 'center',
    letterSpacing: 0.04,
    ...theme.fontByWeight('400'),
    paddingVertical: 0,
    paddingHorizontal: 15,
    color: theme.palette.textSecondary,
    ...theme.mediaQuery({
      1000: {
        fontSize: 15,
        lineHeight: 18,
      },
    }),
  },
}));
