import {Analytics, AnalyticsBatch, AnalyticsController} from './Analytics';
import {Http} from '../Http';
import {ReportParams} from './Report';
import {Either, error, success} from '../fp';
import {AnalyticsEventUrl, MESSAGING_PROTOCOL_VERSION} from '../Messaging';
import {Millisecond} from '../Time';

export class AnalyticsImpl implements Analytics {
  constructor(private readonly _root: {readonly http: Http}) {}

  createController() {
    return new AnalyticsControllerImpl(this._root);
  }
}

export class AnalyticsControllerImpl implements AnalyticsController {
  private readonly _reports: [AnalyticsEventUrl, ReportParams][] = [];
  public readonly batch: AnalyticsBatch;

  constructor(private readonly _root: {readonly http: Http}) {
    const that = this;
    this.batch = {
      report(eventUrl: AnalyticsEventUrl, params: ReportParams) {
        that._reports.push([eventUrl, params]);
      },
    };
  }

  async flush(): Promise<Either<void, unknown>> {
    const controller = new AbortController();
    const id = setTimeout(
      () => controller.abort(),
      AnalyticsControllerImpl.TIMEOUT,
    );
    const options = {signal: controller.signal};
    const outcome = await Promise.race(
      this._reports.map(([eventUrl, params]) =>
        this._report(eventUrl, params, options),
      ),
    );
    clearTimeout(id);
    return outcome;
  }

  private async _report(
    eventUrl: AnalyticsEventUrl,
    params: ReportParams,
    options?: {signal?: AbortSignal},
  ): Promise<Either<void, unknown>> {
    try {
      const locator = new URL(eventUrl);
      locator.searchParams.append('sv', MESSAGING_PROTOCOL_VERSION);
      const response = await this._root.http.fetch(locator.toString(), {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(params),
        mode: 'cors',
        signal: options?.signal,
      });
      if (response.ok) {
        return success(undefined);
      }
      return error(
        new Error(`Network failure ${response.status} ${response.statusText}`),
      );
    } catch (raw) {
      return error(raw);
    }
  }

  private static readonly TIMEOUT = 1000 as Millisecond;
}
