import {action, makeObservable} from 'mobx';
import {BusSource, Disposer, Service} from '../structure';
import {Log} from './Log';
import {Request, Response} from '../JsonRpc';
import JsonRpcLogContent from './JsonRpcLogContent';

export default class DuplexTunnelLogBridgeService implements Service {
  private _incoming?: BusSource<Request | Response>;
  private _outgoing?: BusSource<Request | Response>;

  constructor(private readonly _root: {readonly log: Log}) {
    makeObservable(this);
  }

  changeSource(
    incoming: BusSource<Request | Response>,
    outgoing: BusSource<Request | Response>,
  ) {
    this.unsubscribe();
    this._incoming = incoming;
    this._outgoing = outgoing;
    return this.subscribe();
  }

  @action private _onMessage(incoming: boolean, message: Request | Response) {
    this._root.log.write(new JsonRpcLogContent(incoming, message));
  }

  private _onOutgoing = (message: Request | Response) => {
    this._onMessage(false, message);
  };

  private _onIncoming = (message: Request | Response) => {
    this._onMessage(true, message);
  };

  subscribe() {
    this._incoming?.listen(this._onIncoming);
    this._outgoing?.listen(this._onOutgoing);
    return (() => this.unsubscribe()) as Disposer;
  }

  unsubscribe() {
    this._incoming?.forget(this._onIncoming);
    this._outgoing?.forget(this._onOutgoing);
  }
}
