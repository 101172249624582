import React, {useMemo} from 'react';
import {Platform, View} from 'react-native';
import {SecondaryMenuItem} from './SecondaryMenuItem';
import {useOpenLink} from '../Links';
import {useRoot, useStrings} from '../Root/hooks';

export const SubMenuList = () => {
  const {rateApp, configuration} = useRoot();
  const styles = useMemo(
    () =>
      ({
        menuContainer: {
          paddingHorizontal: 20,
        },
      } as const),
    [],
  );
  const strings = useStrings();
  const {
    values: {appStoreSubscriptionsUrl, playStoreSubscriptionsUrl, aboutUrl},
  } = configuration;
  const manageSubscriptionUrl =
    Platform.OS === 'ios'
      ? appStoreSubscriptionsUrl
      : playStoreSubscriptionsUrl;
  const openManageSubscriptions = useOpenLink(manageSubscriptionUrl);
  const openTermsOfUse = useOpenLink(`${aboutUrl}/terms/`);
  const openPrivacyPolicy = useOpenLink(`${aboutUrl}/privacy/`);
  const openSupport = useOpenLink(`${aboutUrl}/feedback/`);
  return (
    <View style={styles.menuContainer}>
      <SecondaryMenuItem
        text={strings['menu.manageSubscription']}
        onPress={openManageSubscriptions}
      />
      <SecondaryMenuItem
        text={strings['menu.termsOfUse']}
        onPress={openTermsOfUse}
      />
      <SecondaryMenuItem
        text={strings['menu.privacyPolicy']}
        onPress={openPrivacyPolicy}
      />
      <SecondaryMenuItem text={strings['menu.support']} onPress={openSupport} />
      <SecondaryMenuItem
        text={strings['menu.rate']}
        onPress={rateApp.openModal}
      />
    </View>
  );
};
