import {Core} from '../Core';
import {Root} from './Root';
import {batchDisposers, Service} from '../structure';
import {WebHeadlessLocalNotificationsImpl} from '../LocalNotifications';
import BaseRootService from './BaseRootService';
import {WebLocationImpl, DeepLinkingLocationSourceService} from '../Location';
import {WebLinkingOptionsProviderImpl} from '../LinkingOptionsProvider';
import {WebMessagingService} from '../Messaging';
import {WebMessagingIdentityService} from '../MessagingIdentity';
import {WebShareImpl} from '../Share';
import {StubSharingImpl} from '../Sharing';
import {WebNotificationPermissionsService} from '../NotificationPermissions';
import {WebClipboardImpl} from '../Clipboard';
import {StubInAppPurchaseManagerImpl} from '../InAppPurchaseManager';

export default class WebRootService
  extends BaseRootService
  implements Root, Service
{
  constructor(protected readonly _core: Core) {
    super(_core);
  }

  subscribe() {
    return batchDisposers(
      super.subscribe(),
      this.locationSource.subscribe(),
      this.messaging.subscribe(),
      this.messagingIdentity.subscribe(),
      this.inAppPurchaseManager.subscribe(),
      this.notificationPermissions.subscribe(),
    );
  }

  readonly clipboard = new WebClipboardImpl();
  readonly linkingOptionsProvider = new WebLinkingOptionsProviderImpl();
  readonly location = new WebLocationImpl(this);
  readonly locationSource = new DeepLinkingLocationSourceService();
  readonly headlessLocalNotifications = new WebHeadlessLocalNotificationsImpl(
    this,
  );
  readonly localNotifications = this.headlessLocalNotifications;
  readonly messaging = new WebMessagingService(this);
  readonly notificationPermissions = new WebNotificationPermissionsService();
  readonly messagingIdentity = new WebMessagingIdentityService(this);
  readonly inAppPurchaseManager = new StubInAppPurchaseManagerImpl(this);
  readonly share = new WebShareImpl();
  readonly sharing = new StubSharingImpl(this);
}
