import {LocalNotifications} from './LocalNotifications';
import {BusImpl, Disposer, Service} from '../structure';
import {ActionId} from '../units';
import isLocalNotificationData from './isLocalNotificationData';
import notifee from '../Notifee';
import {
  HeadlessLocalNotifications,
  LocalNotificationContext,
  LocalNotificationId,
} from './HeadlessLocalNotifications';
import MobileHeadlessLocalNotificationsImpl from './MobileHeadlessLocalNotificationsImpl';
import {LocalNotificationsLocationSource} from './LocalNotificationsLocationSource';

export default class MobileLocalNotificationsService
  implements LocalNotifications, Service
{
  constructor(
    protected readonly _root: {
      readonly headlessLocalNotifications: HeadlessLocalNotifications;
      readonly localNotificationsLocationSource: LocalNotificationsLocationSource;
    },
  ) {}

  private async _processInitial() {
    const initial =
      await this._root.localNotificationsLocationSource.getInitialContext();
    if (!initial.success) {
      return;
    }
    this.income.send(initial.right);
    await this._root.headlessLocalNotifications.handle(initial.right);
  }

  public income = new BusImpl<LocalNotificationContext>();

  private _processEvents() {
    return notifee.onForegroundEvent(async (event) => {
      const type = MobileHeadlessLocalNotificationsImpl.EVENT_TYPE_MAP.get(
        event.type,
      );
      const data = event.detail.notification?.data;
      if (!data || !isLocalNotificationData(data) || !type) {
        return;
      }
      const context: LocalNotificationContext = {
        type,
        data,
        id: event.detail.notification?.id as LocalNotificationId | undefined,
        actionId: event.detail.pressAction?.id as ActionId | undefined,
      };
      this.income.send(context);
      await this._root.headlessLocalNotifications.handle(context);
    }) as Disposer;
  }

  subscribe() {
    // noinspection JSIgnoredPromiseFromCall
    this._processInitial();
    return this._processEvents();
  }
}
