import {PurchaseId} from '../units';
import {Proposal} from './ProposalsState';
import {InAppOffers, SlotSubscriptionOffer} from '../InAppOffersService';
import {computed, observable, runInAction, makeObservable} from 'mobx';
import {SlotProposalsState} from './SlotProposalsState';
import {sortBy} from 'lodash';

export default class SlotProposalsStateImpl implements SlotProposalsState {
  @observable.ref private _slotSubscriptionProposalById?: ReadonlyMap<
    PurchaseId,
    Proposal<SlotSubscriptionOffer>
  >;
  constructor(
    protected readonly _root: {
      readonly inAppOffers: InAppOffers;
    },
  ) {
    makeObservable(this);
  }

  get slotSubscriptionProposalById() {
    return this._slotSubscriptionProposalById;
  }

  @computed
  get slotSubscriptionProposalList() {
    return [...(this.slotSubscriptionProposalById?.values() ?? [])];
  }

  @computed
  get slotUniqIntervalList() {
    return Array.from(
      new Set(this.slotSubscriptionProposalList.map((s) => s.offer.interval)),
    );
  }

  private _prepare() {
    const offersSubscription = sortBy(
      this._root.inAppOffers.slotSubscriptionOffers,
      (_) => _.maxSlots,
    );
    const proposalSubscriptionById = new Map(
      offersSubscription.map((_) => [
        _.purchaseId,
        {available: true, offer: _},
      ]),
    );
    runInAction(
      () => (this._slotSubscriptionProposalById = proposalSubscriptionById),
    );
  }

  prepare() {
    this._prepare();
  }

  reset() {
    this._slotSubscriptionProposalById = undefined;
  }
}
