import {sortBy, uniqBy} from 'lodash';
import {Proposal} from './ProposalsState';
import {MinerOffer} from '../InAppOffersService';
import {Hashrate} from '../ApiStore';
import {MinerConfig} from './MinerProposalsState';
import makeMinerConfig from './makeMinerConfig';

export default (
  list: Proposal<MinerOffer>[],
): ReadonlyMap<Hashrate, MinerConfig> => {
  const configs = sortBy(
    uniqBy(
      list.filter((_) => _.available),
      (_) => _.offer.poolMinerConfig.hash_rate,
    ).map((_) => makeMinerConfig(_.offer)),
    (_) => _.hashrate,
  );
  return new Map(configs.map((_) => [_.hashrate, _]));
};
