import {action, observable, runInAction, makeObservable} from 'mobx';
import {Alert} from 'react-native';

export class RequestStore<R, A extends any[] = [], E = any> {
  @observable
  private innerIsLoading = false;

  @observable
  private innerError?: E = undefined;

  @observable
  private innerValue?: R = undefined;

  get isLoading() {
    return this.innerIsLoading;
  }

  get error() {
    return this.innerError;
  }

  get value() {
    return this.innerValue;
  }

  constructor(
    private requestAction: (...args: A) => Promise<R>,
    private onErrorMapState?: (error: E, value: R | undefined) => R | undefined,
  ) {
    makeObservable(this);
  }

  @action.bound
  public async execute(...args: A): Promise<R> {
    runInAction(() => {
      this.innerIsLoading = true;
      this.innerError = undefined;
    });
    try {
      const result = await this.requestAction(...args);
      runInAction(() => {
        this.innerIsLoading = false;
        this.innerError = undefined;
        this.innerValue = result;
      });
      return result!;
    } catch (e) {
      if (__DEV__) {
        Alert.alert('', JSON.stringify(e, null, '  ') + JSON.stringify(args));
      }

      runInAction(() => {
        this.innerIsLoading = false;
        this.innerError = e as E;
        if (this.onErrorMapState) {
          this.innerValue = this.onErrorMapState(
            this.innerError,
            this.innerValue,
          );
        }
      });
      return Promise.reject(e);
    }
  }

  public setValue(value: R | undefined) {
    runInAction(() => {
      this.innerIsLoading = false;
      this.innerError = undefined;
      this.innerValue = value;
    });
  }

  public update(updater: (previous?: R) => R | undefined) {
    this.setValue(updater(this.value));
  }

  @action public reset() {
    this.innerIsLoading = false;
    this.innerError = undefined;
    this.innerValue = undefined;
  }
}

/**
 * Typealias for calling new RequestStore()
 * @param requestAction
 */
export function request<R, A extends any[] = [], E = any>(
  requestAction: (...args: A) => Promise<R>,
) {
  return new RequestStore<R, A, E>(requestAction);
}
