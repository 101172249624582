import {sortBy, uniqBy} from 'lodash';
import {Proposal} from './ProposalsState';
import {MinerOffer} from '../InAppOffersService';
import {Interval} from '../ApiStore';
import {MinerConfig} from './MinerProposalsState';
import makeMinerConfig from './makeMinerConfig';

export default (
  list: Proposal<MinerOffer>[],
  intervalList: Interval[],
): ReadonlyMap<Interval, MinerConfig[]> => {
  const availableList = list.filter((_) => _.available);
  return new Map(
    intervalList.map((interval) => {
      const configsForInterval = availableList.filter(
        (_) => _.offer.interval === interval,
      );
      const configs = sortBy(
        uniqBy(
          configsForInterval,
          (_) => _.offer.poolMinerConfig.hash_rate,
        ).map((_) => makeMinerConfig(_.offer)),
        (_) => _.hashrate,
      );
      return [interval, configs];
    }),
  );
};
