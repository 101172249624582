export default {
  overviewFarm: 'https://cryptotab.farm/{lang}/',
  overviewMiner: 'https://cryptotab.farm/{lang}/miner/',
  usefull: 'https://cryptotab.farm/{lang}/useful/',
  faq: 'https://cryptotab.farm/{lang}/faq/',
  cryptotab: 'https://cryptobrowser.site/{lang}/cryptotab/',
  affiliate: 'https://cryptobrowser.site/{lang}/affiliate/',
  terms: 'https://cryptotab.farm/{lang}/terms/',
  privacy: 'https://cryptotab.farm/{lang}/privacy/',
  advertise:
    'https://docs.google.com/forms/d/e/1FAIpQLSdl5hATDzld10NzUeKvUtzauFfnh14cLE4X9rNVTVOfS-Ts5A/viewform',
  cryptotabBrowser: 'https://cryptobrowser.site/{lang}/',
  cbclick: 'https://cb.click/?lang={lang}',
  nftmaning: 'https://ctnft.net/{lang}/',
  ctabs: 'https://ctabs.net/{lang}/',
  cryptotabStart: 'https://start.cryptobrowser.site/{lang}/',
  ctVpn: 'https://ct-vpn.com/{lang}/',
  feedback: 'https://cryptotab.farm/{lang}/feedback',
  mail: 'contactus@cryptotab.farm',

  facebook: 'https://www.facebook.com/CryptoTabFamilyOfficial',
  telegram: 'https://t.me/CryptoTabChannel',
  twitter: 'https://twitter.com/CryptoTabnet',
  instagram: 'https://www.instagram.com/officialcryptotab/',
  youtube: 'https://www.youtube.com/channel/UCHeEoD-Vag9EyZfZ7G8MByA',
  vkontakte: 'https://vk.com/public162654141',
};
