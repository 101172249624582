import React, {useMemo, useState, useRef, useCallback} from 'react';
import {
  View,
  Text,
  TouchableWithoutFeedback,
  StyleProp,
  TextStyle,
  Pressable,
  PressableStateCallbackType,
} from 'react-native';
import * as Animatable from 'react-native-animatable';
import {ReadonlyDeep} from 'type-fest';
import {observer} from 'mobx-react-lite';
import Portal from '../../Portal';

import {sized} from '../../Svg';
import DotsSvg from '../../assets/svg/colorless/dots.svg';
import {createStylesHook, useTheme} from '../../styling';
import {Hoverable, useHover} from 'react-native-web-hooks';

export interface ActionItem {
  title: string;
  key: ActionKey;
  onPress?: () => void;
  disabled?: boolean;
}

export enum ActionKey {
  Details,
  Schedule,
  Move,
  Rename,
  Remove,
  Statistics,
  GetWorkerID,
  CopyLink,
  ShareLink,
  Instruction,
  AddMoreSlots,
}

interface SetupButtonProps {
  title?: string;
  actions: readonly ReadonlyDeep<ActionItem>[];
  popupItemTextStyle?: StyleProp<TextStyle>;
}

export const BUTTON_SIZE = {
  width: 24,
  height: 32,
};

export const POPUP_INNER_WIDTH = 150;

export const SetupButton = observer(
  ({title, actions, popupItemTextStyle}: SetupButtonProps) => {
    const [isFocused, setFocus] = useState<boolean>(false);
    const blur = useCallback(() => setFocus(false), []);
    const [measurements, setMeasurements] = useState<{
      pageX: number;
      pageY: number;
      width: number;
    }>({width: 0, pageX: 0, pageY: 0});

    const buttonRef = useRef<View>(null);

    const theme = useTheme();
    const styles = useStyles();

    const measure = useCallback(() => {
      buttonRef.current?.measure((x, y, width, height, pageX, pageY) => {
        setMeasurements({width, pageX, pageY});
        setFocus(true);
      });
    }, []);

    const iconColor = isFocused
      ? theme.select(theme.contrast(theme.palette.icon), theme.palette.icon)
      : theme.palette.secondary;

    const popupPosition = useMemo(
      () =>
        ({
          top: BUTTON_SIZE.height + measurements.pageY - 3,
          left: measurements.pageX - POPUP_INNER_WIDTH + 25,
        } as const),
      [measurements],
    );

    return (
      <>
        <Hoverable>
          {(isHoverable) => (
            <Pressable onPress={measure}>
              <View
                style={[
                  styles.touchableContainer,
                  isFocused && styles.touchableContainerFocus,
                  isHoverable && styles.touchableContainerHover,
                ]}>
                {title && (
                  <View style={styles.touchableTextView}>
                    <Text
                      style={[
                        styles.touchableText,
                        isFocused && styles.touchableTextFocus,
                      ]}>
                      {title}
                    </Text>
                  </View>
                )}
                <View
                  ref={buttonRef}
                  style={[
                    styles.buttonContainer,
                    isFocused && styles.buttonContainerFocus,
                    title !== undefined && styles.buttonContainerTitle,
                  ]}>
                  <DotsIcon color={isHoverable ? '#ffffff' : iconColor} />
                </View>
              </View>
            </Pressable>
          )}
        </Hoverable>

        <Portal>
          {isFocused && (
            <>
              <TouchableWithoutFeedback onPress={blur}>
                <View style={styles.popupBackdrop} />
              </TouchableWithoutFeedback>
              <Animatable.View
                animation="fadeIn"
                duration={250}
                useNativeDriver
                style={[styles.popupInnerContainer, popupPosition]}>
                {actions.map((action, index) => (
                  <Action
                    key={action.key ?? index}
                    action={action}
                    blur={blur}
                    popupItemTextStyle={popupItemTextStyle}
                  />
                ))}
              </Animatable.View>
            </>
          )}
        </Portal>
      </>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  touchableContainer: {
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    borderColor: theme.palette.border,
    backgroundColor: theme.palette.bar,
    cursor: 'pointer',
  },
  touchableContainerFocus: {
    borderColor: theme.palette.secondary,
    backgroundColor: theme.palette.secondary,
  },
  touchableContainerHover: {
    borderColor: theme.palette.primary,
    backgroundColor: theme.palette.primary,
  },
  touchableTextView: {
    paddingHorizontal: 15,
  },
  touchableText: {
    ...theme.fontByWeight('700'),
    color: theme.palette.secondary,
    fontSize: 10,
    lineHeight: 11,
    textTransform: 'uppercase',
  },
  touchableTextFocus: {
    color: theme.contrast(theme.palette.textPrimary),
  },
  buttonContainer: {
    ...BUTTON_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
    borderLeftWidth: 0,
    borderLeftColor: theme.palette.border,
  },
  buttonContainerFocus: {
    borderLeftColor: theme.palette.secondary,
  },
  buttonContainerTitle: {
    borderLeftWidth: 1,
  },
  popupBackdrop: {
    flex: 1,
  },
  popupInnerContainer: {
    ...theme.bar(20),
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 3,
    paddingVertical: 10,
    backgroundColor: theme.palette.background,
    borderWidth: 1,
    borderColor: theme.palette.border,
    width: POPUP_INNER_WIDTH,
  },
}));

interface ActionProps {
  action: ReadonlyDeep<ActionItem>;
  popupItemTextStyle?: StyleProp<TextStyle>;
  blur: () => void;
}

const Action = observer(({action, popupItemTextStyle, blur}: ActionProps) => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      ({
        popupItem: {
          paddingHorizontal: 15,
          paddingVertical: 7,
          backgroundColor: theme.palette.background,
        },
        popupItemPressed: {
          backgroundColor: theme.palette.secondary,
        },
        popupItemDisabled: {
          opacity: 0.5,
        },
        popupItemText: {
          ...theme.fontByWeight('400'),
          fontSize: 14,
          lineHeight: 16,
          color: theme.palette.textPrimary,
        },
        popupItemPressedText: {
          color: theme.contrast(theme.palette.textPrimary),
        },
        hoveredView: {
          backgroundColor: theme.palette.primary,
        },
        hoveredText: {
          color: '#ffffff',
        },
      } as const),
    [theme],
  );
  const {disabled, title} = action;
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const onPress = useCallback(() => {
    action.onPress?.();
    blur();
  }, [action, blur]);
  const style = useCallback(
    ({pressed}: PressableStateCallbackType) => [
      styles.popupItem,
      pressed && styles.popupItemPressed,
      disabled && styles.popupItemDisabled,
      isHovered && !disabled && styles.hoveredView,
    ],
    [disabled, styles, isHovered],
  );
  const text = useCallback(
    ({pressed}: PressableStateCallbackType) => (
      <Text
        style={[
          styles.popupItemText,
          popupItemTextStyle,
          pressed && styles.popupItemPressedText,
          isHovered && !disabled && styles.hoveredText,
        ]}>
        {title}
      </Text>
    ),
    [popupItemTextStyle, styles, title, isHovered, disabled],
  );

  return (
    <Pressable disabled={disabled} onPress={onPress} style={style} ref={ref}>
      {text}
    </Pressable>
  );
});

const DotsIcon = sized(DotsSvg, 2, 12);
