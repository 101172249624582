import {LogExporter, FileOptions, Markdown} from './LogExporter';
import {Log} from '../Log';
import dayjs from 'dayjs';
import {Either} from '../fp';
import {GlobalError} from '../Error';
import {Sharing} from '../Sharing';
import {ErrorRepository} from '../ErrorRepository';
import {FileSystem} from '../FileSystem';

export default class LogExporterImpl implements LogExporter {
  constructor(
    private readonly _root: {
      readonly sharing: Sharing;
      readonly log: Log;
      readonly errorRepository: ErrorRepository;
      readonly fileSystem: FileSystem;
    },
  ) {}

  async save(): Promise<Either<void, GlobalError>> {
    const options = this._getFileOptions(this._root.log);
    return this._root.fileSystem.save(
      options.content,
      options.filename,
      'text/plain',
    );
  }

  async share(): Promise<Either<void, GlobalError>> {
    const options = this._getFileOptions(this._root.log);
    return this._root.sharing.shareFile(options.filename, options.content);
  }

  getContent(history: Log) {
    const body = history.records
      .map((_) => `${new Date(_.capturedAt).toISOString()}\n${_.content.body}`)
      .join('\n');
    return `${CODE}\n${body}\n${CODE}` as Markdown;
  }

  private _getFileOptions(history: Log): FileOptions {
    return {
      filename: `${dayjs().format('DD-MM-YYYY__HH-mm-ss')}_Logs.txt`,
      content: this.getContent(history),
    };
  }
}

const CODE = '```';
