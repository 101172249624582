import getItemLayout from '../getters/getItemLayout';
import {GroupSection, WorkerItem} from '../form/formSections';
import {
  WORKER_HEIGHT,
  GROUP_HEADER_HEIGHT,
  WORKER_DESKTOP_HEIGHT,
  EMPTY_GROUP_HEADER_HEIGHT,
  GROUP_FOOTER_HEIGHT,
  DASHBOARD_DESKTOP_HEADER_BANNER_SECTION_HEIGHT,
  DASHBOARD_HEADER_BANNER_SECTION_HEIGHT,
  DESKTOP_GROUP_HEADER_HEIGHT,
  DASHBOARD_MOBILE_ADS_HEIGHT,
  DESKTOP_WEB_APP_FOOTER_HEIGHT,
  ADVANCED_HEIGHT,
  DESKTOP_STORE_BANNER_HEIGHT,
  STORE_BANNER_HEIGHT,
  ADD_SLOTS_HEIGHT,
} from '../constants';

import {useMemo} from 'react';
import {useTheme} from '../../styling';
import {useRoot} from '../../Root/hooks';
import {expr} from 'mobx-utils';
import {PROMO_LOADER_HEIGHT} from '../Header/PromoSection';
import {
  COPY_PROMO_DESKTOP_BREAKPOINT,
  COPY_PROMO_DESKTOP_HEIGHT,
  COPY_PROMO_HEIGHT,
} from '../Header/CopyPromoSection';
import {
  GENERATE_PROMO_DESKTOP_BREAKPOINT,
  GENERATE_PROMO_DESKTOP_HEIGHT,
  GENERATE_PROMO_HEIGHT,
} from '../Header/GeneratePromoSection';
import {Platform} from 'react-native';
import {
  DESKTOP_FARM_INFO_SECTION_HEIGHT,
  DESKTOP_FARM_INFO_SECTION_HEIGHT_WEB,
  MOBILE_FARM_INFO_SECTION_HEIGHT,
} from '../sections';
import {first} from 'lodash';
import {AdSpot} from '../../ApiStore';
import {useRoute} from '@react-navigation/native';
import {DashboardScreenProps} from '../DashboardScreenProps';
import useStableSections from './useStableSections';

export function useHeaderBannerSectionHeight() {
  const {dashboardBannersState, advertHelper} = useRoot();
  const theme = useTheme();
  const isDesktop = expr(() => theme.window.width > 1000);
  const advertMobileBanner = first(
    advertHelper.spotByBannerList?.get(AdSpot.DashboardMobileTop),
  );
  const advertDesktopBanner = first(
    advertHelper.spotByBannerList?.get(AdSpot.DashboardDesktopTop),
  );
  if (
    !advertMobileBanner &&
    !advertDesktopBanner &&
    dashboardBannersState.targetBanner
  ) {
    return DASHBOARD_HEADER_BANNER_SECTION_HEIGHT;
  }
  if (dashboardBannersState.hasShownBanner) {
    if (isDesktop) {
      return DASHBOARD_DESKTOP_HEADER_BANNER_SECTION_HEIGHT;
    }
    return advertMobileBanner === undefined
      ? DASHBOARD_HEADER_BANNER_SECTION_HEIGHT
      : DASHBOARD_MOBILE_ADS_HEIGHT;
  }
  if (isDesktop && advertDesktopBanner !== undefined) {
    return DASHBOARD_DESKTOP_HEADER_BANNER_SECTION_HEIGHT;
  } else if (!isDesktop && advertMobileBanner !== undefined) {
    return DASHBOARD_MOBILE_ADS_HEIGHT;
  }
  return 0;
}

export function useHeaderFarmInfoSectionHeight() {
  const theme = useTheme();
  const {advertHelper} = useRoot();
  const advertMobileBanner = first(
    advertHelper.spotByBannerList?.get(AdSpot.DashboardMobileTop),
  );
  const isDesktop = useIsDesktop();
  if (Platform.OS === 'web' && theme.window.width > 1280) {
    return DESKTOP_FARM_INFO_SECTION_HEIGHT_WEB;
  }
  if (isDesktop) {
    return DESKTOP_FARM_INFO_SECTION_HEIGHT;
  }
  if (advertMobileBanner) {
    return MOBILE_FARM_INFO_SECTION_HEIGHT + DASHBOARD_MOBILE_ADS_HEIGHT;
  }
  return MOBILE_FARM_INFO_SECTION_HEIGHT;
}

export function useHeaderPromoSectionHeight() {
  const {dashboardStore} = useRoot();
  const {dashboardPromo} = dashboardStore;
  const theme = useTheme();
  if (dashboardPromo.isLoading) {
    return PROMO_LOADER_HEIGHT;
  } else if (dashboardPromo.promos && dashboardPromo.promos.length > 0) {
    return theme.window.width > COPY_PROMO_DESKTOP_BREAKPOINT
      ? COPY_PROMO_DESKTOP_HEIGHT
      : COPY_PROMO_HEIGHT;
  } else {
    return theme.window.width > GENERATE_PROMO_DESKTOP_BREAKPOINT
      ? GENERATE_PROMO_DESKTOP_HEIGHT
      : GENERATE_PROMO_HEIGHT;
  }
}

export function useDashboardHeaderHeight() {
  let listHeaderHeight = 0;

  // TOP BANNER
  listHeaderHeight += useHeaderBannerSectionHeight();

  // HEADER INFO
  listHeaderHeight += useHeaderFarmInfoSectionHeight();

  // HEADER PROMO
  listHeaderHeight += useHeaderPromoSectionHeight();

  return listHeaderHeight;
}

export function useSectionListFooterHeight() {
  const theme = useTheme();
  const route = useRoute<DashboardScreenProps['route']>();
  const isDesktop = expr(() => theme.window.width > 1000);
  const {
    dashboardStore: {dashboardRequest},
  } = useRoot();
  const groups = dashboardRequest.value?.groups;

  const stableSections = useStableSections(route.params ?? {});

  let sectionListFooterHeight = 0;
  if (
    stableSections.length > 0 &&
    stableSections[stableSections.length - 1].slotsLeftToShow > 1
  ) {
    sectionListFooterHeight += ADD_SLOTS_HEIGHT + 10;
  }

  sectionListFooterHeight += ADVANCED_HEIGHT + 10;

  if (groups && groups.length > 1) {
    sectionListFooterHeight += ADD_SLOTS_HEIGHT + 10;
  }
  sectionListFooterHeight +=
    (isDesktop ? DESKTOP_STORE_BANNER_HEIGHT : STORE_BANNER_HEIGHT) + 20;

  if (isDesktop) {
    sectionListFooterHeight += DESKTOP_WEB_APP_FOOTER_HEIGHT;
  }

  return sectionListFooterHeight;
}

/**
 * @deprecated
 */
export function useIsDesktop() {
  const theme = useTheme();
  return theme.window.width > 1000;
}

export default () => {
  const isDesktop = useIsDesktop();
  const workerHeight = isDesktop ? WORKER_DESKTOP_HEIGHT : WORKER_HEIGHT;
  const groupHeaderHeight = isDesktop
    ? DESKTOP_GROUP_HEADER_HEIGHT
    : GROUP_HEADER_HEIGHT;
  const headerHeight = useDashboardHeaderHeight();
  const sectionListFooterHeight = useSectionListFooterHeight();

  return useMemo(
    () =>
      getItemLayout<WorkerItem, GroupSection>({
        getItemHeight: () => workerHeight,
        getSectionHeaderHeight: (sectionData) =>
          sectionData.isEmpty ? EMPTY_GROUP_HEADER_HEIGHT : groupHeaderHeight,
        getSectionFooterHeight: (sectionData) =>
          sectionData.isEmpty ? 0 : GROUP_FOOTER_HEIGHT,
        listHeaderHeight: headerHeight,
        listFooterHeight: sectionListFooterHeight,
      }),
    [headerHeight, workerHeight, groupHeaderHeight, sectionListFooterHeight],
  );
};
