import {Millisecond as OpaqueMillisecond} from '../Time';

export type Millisecond = number;
export type Second = number;
export type Minute = number;
export type Hour = number;
export type Day = number;

export const MILLISECOND = 1 as OpaqueMillisecond;
export const SECOND = (1000 * MILLISECOND) as OpaqueMillisecond;
export const MINUTE = (60 * SECOND) as OpaqueMillisecond;
export const HOUR = (60 * MINUTE) as OpaqueMillisecond;
export const DAY = (24 * HOUR) as OpaqueMillisecond;
