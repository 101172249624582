export enum PluralisationKey {
  Zero,
  One,
  Few,
  Many,
  Other,
}

export default (n: number): PluralisationKey => {
  if (n === 0) {
    return PluralisationKey.Zero;
  }
  if (n % 10 === 1 && n % 100 !== 11) {
    return PluralisationKey.One;
  }
  if ([2, 3, 4].indexOf(n % 10) >= 0 && [12, 13, 14].indexOf(n % 100) < 0) {
    return PluralisationKey.Few;
  }
  if (
    n % 10 === 0 ||
    [5, 6, 7, 8, 9].indexOf(n % 10) >= 0 ||
    [11, 12, 13, 14].indexOf(n % 100) >= 0
  ) {
    return PluralisationKey.Many;
  }
  return PluralisationKey.Other;
};
