import React from 'react';
import {observer} from 'mobx-react-lite';
import {
  CalculationPriceText,
  TopPrice,
  TopPriceText,
  TotalPriceItemContainer,
} from '../../../shared/shared';
import WebCurrency from '../../../shared/components/WebCurrency';
import {useStrings} from '../../../../Root/hooks';

export type TotalPriceTotalProps = {
  totalPrice: string;
  currency: string;
  titleCalculation: string;
};
export default observer(function TotalPriceTotal(props: TotalPriceTotalProps) {
  const string = useStrings();
  const {titleCalculation, totalPrice, currency} = props;
  return (
    <TotalPriceItemContainer noBorder>
      <TopPrice>
        <TopPriceText sm>
          {string['purchase.poolMiners.totalPrice']}
        </TopPriceText>
        <TopPriceText>
          {totalPrice} {currency}
        </TopPriceText>
      </TopPrice>
      <CalculationPriceText>{titleCalculation}</CalculationPriceText>
      <WebCurrency />
    </TotalPriceItemContainer>
  );
});
