import React, {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import {sized} from '../Svg';
import Close from '../assets/svg/colorless/close.svg';
import {
  Animated,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  ViewProps,
  ViewStyle,
} from 'react-native';
import {Swipeable} from 'react-native-gesture-handler';
import {useTheme, variance} from '../styling';
import {SvgProps} from 'react-native-svg';
import {
  BannerVariant,
  InfoBannerProps,
  translateBannerVariantToColors,
} from './Banner';

export type SwipeableBannerProps = Pick<InfoBannerProps, 'variant'> &
  ViewProps & {
    children: React.ReactNode;
    containerStyle?: StyleProp<ViewStyle>;
    onDelete: () => void;
  };

export default observer(function SwipeableBanner({
  children,
  variant = BannerVariant.Primary,
  containerStyle,
  onDelete,
}: SwipeableBannerProps) {
  const strings = useStrings();
  const [isCrossIconVisible, setCrossIconVisible] = useState(true);
  const actions = [
    {
      text: strings['action.close'],
      onAction: onDelete,
      Icon: CloseIcon,
    },
  ];
  const theme = useTheme();
  const kindColor = useMemo(
    () => translateBannerVariantToColors(variant, theme),
    [variant, theme],
  );

  return (
    <Swipeable
      onSwipeableLeftOpen={onDelete}
      onSwipeableRightOpen={() => setCrossIconVisible(false)}
      onSwipeableClose={() => setCrossIconVisible(true)}
      renderRightActions={(_) => (
        <RightActions
          kindColor={kindColor}
          actions={actions}
          animatedInterpolation={_}
        />
      )}
      overshootRight={false}
      containerStyle={containerStyle}
      childrenContainerStyle={styles.childContainer}>
      {children}
      {isCrossIconVisible && (
        <AdsBannerButton onPress={onDelete}>
          <CloseIcon color={kindColor.close || kindColor.main} />
        </AdsBannerButton>
      )}
    </Swipeable>
  );
});

const styles = StyleSheet.create({
  childContainer: {
    flex: 1,
  },
});

const CloseIcon = sized(Close, 12);

export type RightActionProps = {
  actions: {text: string; onAction: () => void; Icon: React.FC<SvgProps>}[];
  kindColor: {main: string; additional: string};
  animatedInterpolation: Animated.AnimatedInterpolation<number>;
};

const RightActions = ({
  animatedInterpolation,
  actions,
  kindColor,
}: RightActionProps) => {
  const theme = useTheme();
  const opacity = animatedInterpolation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });

  return (
    <BannerActions style={{opacity, backgroundColor: kindColor.main}}>
      {actions.map((it, i) => {
        const {text: _text, Icon, onAction} = it;
        const ActionIcon = Icon ? sized(Icon, 11, 11) : null;

        return (
          <BannerAction onPress={onAction} last={i === actions.length} key={i}>
            <BannerActionText>{_text}</BannerActionText>
            {ActionIcon ? (
              <ActionIcon
                color={theme.select(
                  theme.contrast(theme.palette.textPrimary),
                  theme.palette.textPrimary,
                )}
              />
            ) : null}
          </BannerAction>
        );
      })}
    </BannerActions>
  );
};

const BannerAction = variance(Pressable)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10,
  },
  last: {
    marginLeft: 0,
  },
}));

const BannerActions = variance(Animated.View)((theme) => ({
  root: {
    paddingHorizontal: 10,
    marginLeft: -5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 100,
    backgroundColor: theme.palette.error,
    zIndex: 2,
  },
}));

const BannerActionText = variance(Text)((theme) => ({
  root: {
    marginRight: 10,
    ...theme.fontByWeight('500'),
    textTransform: 'uppercase',
    fontSize: 13,
    color: theme.select(
      theme.contrast(theme.palette.textPrimary),
      theme.palette.textPrimary,
    ),
  },
}));

const AdsBannerButton = variance(Pressable)(() => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 5,
    width: 24,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
