import SliderState from './SliderState';
import {batchDisposers, Service} from '../structure';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import {
  computed,
  observable,
  reaction,
  runInAction,
  makeObservable,
} from 'mobx';

interface BtcSliderStateBuilder {
  slider?: SliderState;
  priceGuess: PriceGuess;
}

export enum PriceGuess {
  Unknown,
  Default,
  Positive,
  Negative,
}

export default class BtcSliderStateManagerImpl
  implements BtcSliderStateBuilder, Service
{
  @observable.ref private _slider?: SliderState;
  @observable private _btcRate?: number;

  get slider() {
    return this._slider;
  }

  private static MAXIMUM_SCALING_VALUE = 1.5;
  private static MINIMUM_SCALING_VALUE = 0.5;
  private static CURRENT_SCALING_VALUE = 1.3;

  @computed
  get priceGuess() {
    if (
      !this._slider ||
      this._slider.value === undefined ||
      this._btcRate === undefined
    ) {
      return PriceGuess.Unknown;
    }
    const value = this._slider.value;
    const percent = (value / this._btcRate) * 100;
    if (percent <= 105 && percent >= 95) {
      return PriceGuess.Default;
    } else if (percent > 105) {
      return PriceGuess.Positive;
    } else if (percent < 95) {
      return PriceGuess.Negative;
    }
    return PriceGuess.Unknown;
  }

  constructor(
    private readonly _root: {readonly dashboardStore: DashboardStore},
  ) {
    makeObservable(this);
  }

  private _listenOnChangeCurrentRate = () =>
    reaction(
      () => !this._root.dashboardStore.currentRateRequest.isLoading,
      (should) => {
        if (should) {
          const rate_ = this._root.dashboardStore.currentRateRequest.value;
          if (rate_?.btc && rate_?.usd) {
            const btcRate = rate_.usd / rate_.btc;
            runInAction(() => {
              this._btcRate = btcRate;
            });
            const maximum =
              btcRate * BtcSliderStateManagerImpl.MAXIMUM_SCALING_VALUE;
            const minimum =
              btcRate * BtcSliderStateManagerImpl.MINIMUM_SCALING_VALUE;
            const initialValue =
              btcRate * BtcSliderStateManagerImpl.CURRENT_SCALING_VALUE;
            if (this._slider) {
              runInAction(() => {
                if (this._slider) {
                  this._slider.maximum = maximum;
                  this._slider.minimum = minimum;
                  this._slider.setValue(initialValue);
                }
              });
            } else {
              runInAction(() => {
                this._slider = new SliderState(
                  initialValue,
                  minimum,
                  maximum,
                  1,
                );
              });
            }
          }
          return undefined;
        }
      },
      {fireImmediately: true},
    );

  subscribe() {
    return batchDisposers(this._listenOnChangeCurrentRate());
  }
}
