import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot, useStrings} from '../../Root/hooks';
import {useNavigation} from '@react-navigation/native';
import {DashboardScreenProps} from '../DashboardScreenProps';
import {ApiMode} from '../../farmApi';
import {createStylesHook} from '../../styling';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  PressableOpacity,
} from '../../components';
import {Container} from '../../components/Grid';
import {View} from 'react-native';
import {sized} from '../../Svg';
import PlusSvg from '../../assets/svg/colorless/plus.svg';
import ArrowRightSvg from '../../assets/svg/colorless/arrowRight.svg';
import {ADD_SLOTS_HEIGHT} from '../constants';

export default observer(() => {
  const {apiStore} = useRoot();
  const styles = useStyles();
  const strings = useStrings();
  const navigation = useNavigation<DashboardScreenProps['navigation']>();
  const goToPlan = useCallback(() => {
    navigation.navigate('Plan');
  }, [navigation]);
  const isDemoMode = apiStore.mode === ApiMode.Demo;

  const buttonText = isDemoMode
    ? strings['dashboard.simpleList.demoAddSlotsButton']
    : strings['dashboard.simpleList.addSlotsButton'];
  const ButtonIcon = isDemoMode ? ArrowRightIcon : PlusIcon;
  const iconPosition = isDemoMode ? 'right' : 'left';

  return (
    <Container style={styles.footer}>
      <View style={styles.footerContent}>
        <PressableOpacity onPress={goToPlan}>
          <Button
            iconPosition={iconPosition}
            Icon={ButtonIcon}
            color={ButtonColor.Primary}
            variant={ButtonVariant.Contained}>
            {buttonText}
          </Button>
        </PressableOpacity>
      </View>
    </Container>
  );
});

const PlusIcon = sized(PlusSvg, 12);
const ArrowRightIcon = sized(ArrowRightSvg, 12);

const useStyles = createStylesHook((theme) => ({
  footer: {
    marginBottom: 10,
  },
  footerContent: {
    height: ADD_SLOTS_HEIGHT,
    padding: 20,
    borderWidth: 1,
    borderColor: theme.palette.border,
    backgroundColor: theme.palette.background,
    borderRadius: 15,
  },
  noMarginTop: {
    marginTop: 0,
  },
}));
