import React, {useCallback, useMemo, useState} from 'react';
import {
  View,
  ViewProps,
  Text,
  TouchableWithoutFeedback,
  StyleProp,
  TextStyle,
  ViewStyle,
  TextProps,
} from 'react-native';
import {observer} from 'mobx-react-lite';

import PickerModal, {PickerItem, RenderPickerItemProps} from './PickerModal';
import {sized} from '../Svg';
import CaretDownSvg from '../assets/svg/colorless/caretDown.svg';
import {useTheme} from '../styling';

export interface RenderSelectedTextProps {
  style: StyleProp<TextStyle>;
  otherTextProps: TextProps;
  item?: PickerItem;
}

export interface PickerProps extends ViewProps {
  items: readonly PickerItem[];
  pickedItemId?: PickerItem['id'];
  defaultItemTitle?: PickerItem['title'];
  onPick?: (id?: PickerItem['id']) => void;
  closeText: string;
  titleStyle?: StyleProp<TextStyle>;
  iconStyle?: StyleProp<ViewStyle>;
  modalStyle?: StyleProp<ViewStyle>;
  modalPanelStyle?: StyleProp<ViewStyle>;
  renderSelectedText?: (props: RenderSelectedTextProps) => React.ReactElement;
  renderPickerItem?: (props: RenderPickerItemProps) => React.ReactElement;
}

export default observer((props: PickerProps) => {
  const {
    items,
    pickedItemId,
    defaultItemTitle,
    onPick: _onPick,
    closeText,
    style,
    titleStyle,
    modalStyle,
    renderSelectedText,
    renderPickerItem,
    modalPanelStyle,
    ...rest
  } = props;
  const theme = useTheme();
  const styles = useMemo(
    () =>
      ({
        root: {
          borderRadius: 10,
          flexDirection: 'row',
          cursor: 'pointer',
          backgroundColor: theme.palette.background,
          borderWidth: 1,
          borderColor: theme.palette.border,
        },
        title: {
          flexGrow: 1,
          flexShrink: 1,
          marginVertical: 9,
          marginHorizontal: 10,
          ...theme.fontByWeight('bold'),
          fontSize: 12,
          lineHeight: 14,
          color: theme.palette.textPrimary,
        },
        icon: {
          marginVertical: 14,
          marginEnd: 10,
        },
        modal: {},
        scroll: {
          flexGrow: 1,
        },
        container: {
          flexGrow: 1,
          justifyContent: 'center',
          backgroundColor: 'rgba(0,0,0,0.5)',
        },
        panel: {
          alignSelf: 'center',
          marginVertical: 50,
          width: 360,
          maxWidth: '80%',
          backgroundColor: theme.palette.background,
          borderRadius: 16,
          overflow: 'hidden',
          ...theme.bar(20),
        },
        item: {
          padding: 16,
          ...theme.fontByWeight(),
          fontSize: 12,
          lineHeight: 14,
        },
        itemPicked: {
          ...theme.fontByWeight('bold'),
        },
        close: {
          alignSelf: 'center',
        },
      } as const),
    [theme],
  );
  const item = useMemo(
    () => items.find((_) => _.id === pickedItemId),
    [items, pickedItemId],
  );
  const [modalShown, setModalShown] = useState(false);
  const show = useCallback(() => setModalShown(true), []);
  const hide = useCallback(() => setModalShown(false), []);
  const onPick = useCallback(
    (id?: PickerItem['id']) => {
      _onPick?.(id);
      hide();
    },
    [_onPick, hide],
  );

  return (
    <>
      <TouchableWithoutFeedback onPress={show}>
        <View style={[styles.root, style]} {...rest}>
          {renderSelectedText ? (
            renderSelectedText({
              style: [styles.title, titleStyle],
              otherTextProps: {
                numberOfLines: 1,
                ellipsizeMode: 'tail',
              },
              item,
            })
          ) : (
            <Text
              style={[styles.title, titleStyle]}
              numberOfLines={1}
              ellipsizeMode="tail">
              {item?.title ?? defaultItemTitle}
            </Text>
          )}
          <View style={styles.icon}>
            <CaretDownIcon color={theme.palette.icon} />
          </View>
        </View>
      </TouchableWithoutFeedback>
      <PickerModal
        modalStyle={modalStyle}
        panelStyle={modalPanelStyle}
        items={items}
        pickedItemId={pickedItemId}
        defaultItemTitle={defaultItemTitle}
        onPick={onPick}
        closeText={closeText}
        visible={modalShown}
        onRequestClose={hide}
        onPressAway={hide}
        onCancelPress={hide}
        renderPickerItem={renderPickerItem}
      />
    </>
  );
});

const CaretDownIcon = sized(CaretDownSvg, 8, 4);
