import {useEffect} from 'react';
import {comparer, reaction} from 'mobx';
import {showMessage} from 'react-native-flash-message';
import {useRoot, useStrings} from '../Root/hooks';
import {useIsFocused} from '@react-navigation/native';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {ReadonlyDeep} from 'type-fest';

export default (workers: ReadonlyDeep<WorkerEntity[]>) => {
  const {scheduleStore} = useRoot();
  const isFocused = useIsFocused();
  const strings = useStrings();
  useEffect(
    () =>
      reaction(
        () => Array.from(workers).map((_) => _.schedulers),
        () => {
          if (!scheduleStore.shallNotifyAboutUpdate) {
            scheduleStore.restoreNotifications();
            return;
          }
          if (isFocused) {
            showMessage({
              message: strings['scheduler.update'],
              duration: 8000,
              type: 'info',
            });
          }
        },
        {equals: comparer.structural},
      ),
    [isFocused, scheduleStore, strings, workers],
  );
};
