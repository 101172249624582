import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useForcedContext} from '../../context';
import {PurchaseScreenContext} from '../PurchaseScreenContext';
import NeedMoreSlotsModal from '../shared/components/NeedMoreSlotsModal';
import {PurchaseTabsKind} from '../states/PurchaseScreenState';

export default observer(function NeedMoreSlotsModalBinding() {
  const {needSlotsVisible, hideNeedSlotsModal, state} = useForcedContext(
    PurchaseScreenContext,
  );
  const onSubmit = useCallback(() => {
    state.selectTab(PurchaseTabsKind.Slot);
    hideNeedSlotsModal();
  }, [hideNeedSlotsModal, state]);
  if (!needSlotsVisible) {
    return null;
  }
  return (
    <NeedMoreSlotsModal onDismiss={hideNeedSlotsModal} onSubmit={onSubmit} />
  );
});
