import {Listener, Tunnel} from './Tunnel';
import {Disposer} from '../structure';

// TODO Implement HubTunnel to mux multiple consumers
export default class DuplexTunnel<T extends Tunnel> {
  private readonly _aliceListeners = new Set<Listener>();
  private readonly _bobListeners = new Set<Listener>();
  private readonly _alice: T;
  private readonly _bob: T;

  constructor(factory: (tunnel: Tunnel) => T) {
    this._alice = this._initAlice(factory);
    this._bob = this._initBob(factory);
  }

  private _initAlice(factory: (tunnel: Tunnel) => T) {
    return factory({
      send: async (message: unknown) => {
        for (const listener of this._bobListeners) {
          listener(message);
        }
      },
      listen: (listener) => {
        this._aliceListeners.add(listener);
        return (() => {
          this._aliceListeners.delete(listener);
        }) as Disposer;
      },
    });
  }

  private _initBob(factory: (tunnel: Tunnel) => T) {
    return factory({
      send: async (message: unknown) => {
        for (const listener of this._aliceListeners) {
          listener(message);
        }
      },
      listen: (listener) => {
        this._bobListeners.add(listener);
        return (() => {
          this._bobListeners.delete(listener);
        }) as Disposer;
      },
    });
  }

  get alice() {
    return this._alice;
  }

  get bob() {
    return this._bob;
  }

  reset() {
    this._aliceListeners.clear();
    this._bobListeners.clear();
  }
}
