import {Alert, GestureResponderEvent, Text, TextProps} from 'react-native';
import React, {forwardRef, useCallback} from 'react';
import {useStyles} from '../styling';
import {useRoot} from '../Root/hooks';

interface CopyableProps extends TextProps {
  children?: string;
}

export default forwardRef<Text, CopyableProps>(
  ({children, onPress: _onPress, style, ...rest}, ref) => {
    const {clipboard} = useRoot();
    const onPress = useCallback(
      (event: GestureResponderEvent) => {
        if (children) {
          clipboard.setString(children);
          Alert.alert(
            'The value has been copied to clipboard',
            children,
            [{style: 'default', text: 'OK'}],
            {cancelable: true},
          );
        }
        if (_onPress) {
          _onPress(event);
        }
      },
      [_onPress, children, clipboard],
    );
    const styles = useStyles((theme) => ({
      root: {
        color: theme.palette.textPrimary,
      },
    }));
    return (
      <Text style={[styles.root, style]} {...rest} onPress={onPress} ref={ref}>
        {children}
      </Text>
    );
  },
);
