import {RemoteMessageStore} from './RemoteMessageStore';
import {Message, MessageMeta, RemoteStoredMessageUrl} from './Message';
import {Either, error, success} from '../fp';
import {MESSAGING_PROTOCOL_VERSION} from './units';
import {Http} from '../Http';
import {Json} from '../Json';

export default class RemoteMessageStoreImpl implements RemoteMessageStore {
  constructor(
    private readonly _root: {readonly json: Json; readonly http: Http},
  ) {}

  async fetch(
    messageUrl: RemoteStoredMessageUrl,
    meta: MessageMeta,
  ): Promise<Either<Message, unknown>> {
    try {
      const locator = new URL(messageUrl);
      locator.searchParams.append('sv', MESSAGING_PROTOCOL_VERSION);
      const init = locator.toString();
      const body_ = this._root.json.stringify(meta);
      if (!body_.success) {
        return body_;
      }
      const response = await this._root.http.fetch(init, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: body_.right,
        mode: 'cors',
      });
      if (response.ok) {
        const message = await response.json();
        return success(message);
      }
      return error(
        new Error(`Network failure ${response.status} ${response.statusText}`),
      );
    } catch (raw) {
      return error(raw);
    }
  }
}
