import React from 'react';
import {observer} from 'mobx-react-lite';
import {Text, View} from 'react-native';
import {ReadonlyDeep} from 'type-fest';
import {PressableOpacity} from '../../../components';
import {WorkerEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useStrings} from '../../../Root/hooks';
import {createStylesHook} from '../../../styling';
import WorkerSpeedSlider from './WorkerSpeedSlider';
import WorkerHugePages from './WorkerHugePages';
import {SlotMode} from '../../types';
import {Hoverable} from 'react-native-web-hooks';

export type WorkerSpeedViewProps = {
  worker: ReadonlyDeep<WorkerEntity>;
  threads?: number;
  locked: boolean;
  managed: boolean;
  onChange: (speed: number) => Promise<void>;
  setMin: () => Promise<void>;
  setMax: () => Promise<void>;
  sliderValue: number;
  slotMode?: SlotMode;
  readonly?: boolean;
  onSlidingStart?: () => void;
  onExpiredPress?: () => void;
  hideTitle?: boolean;
  disabled?: boolean;
};

export default observer(
  ({
    worker,
    threads,
    locked,
    managed,
    onChange,
    setMin,
    setMax,
    slotMode,
    sliderValue,
    readonly,
    onSlidingStart,
    onExpiredPress,
    disabled,
    hideTitle = false,
  }: WorkerSpeedViewProps) => {
    const styles = useStyles();
    const strings = useStrings();
    return (
      <View style={styles.root}>
        {!hideTitle && slotMode === SlotMode.Detail && (
          <Text style={styles.title}>{strings['worker.threadsTitle']}</Text>
        )}
        <View>
          <WorkerSpeedSlider
            threads={threads}
            locked={locked}
            onChange={onChange}
            sliderValue={sliderValue}
            readonly={readonly}
            onSlidingStart={onSlidingStart}
          />
        </View>
        <View style={styles.row}>
          <Hoverable>
            {(isHovered) => (
              <PressableOpacity
                style={styles.badgeViewTouchable}
                disabled={locked}
                onPress={setMin}>
                <View
                  style={[
                    styles.badgeView,
                    !disabled && isHovered && styles.hoveredView,
                  ]}>
                  <Text
                    style={[
                      styles.badgeText,
                      !managed && styles.badgeTextNotManaged,
                      locked && styles.badgeTextDisabled,
                      !disabled && isHovered && styles.hoveredText,
                    ]}>
                    OFF
                  </Text>
                </View>
              </PressableOpacity>
            )}
          </Hoverable>

          {slotMode === SlotMode.Detail && (
            <WorkerHugePages onExpiredPress={onExpiredPress} worker={worker} />
          )}
          <Hoverable>
            {(isHovered) => (
              <PressableOpacity
                style={styles.badgeViewTouchable}
                disabled={locked}
                onPress={setMax}>
                <View
                  style={[
                    styles.badgeView,
                    !disabled && isHovered && styles.hoveredView,
                  ]}>
                  <Text
                    style={[
                      styles.badgeText,
                      !managed && styles.badgeTextNotManaged,
                      locked && styles.badgeTextDisabled,
                      !disabled && isHovered && styles.hoveredText,
                    ]}>
                    MAX
                  </Text>
                </View>
              </PressableOpacity>
            )}
          </Hoverable>
        </View>
      </View>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  root: {},
  title: {
    ...theme.fontByWeight('700'),
    fontSize: 12,
    lineHeight: 14,
    textAlign: 'center',
    letterSpacing: 0.03,
    color: theme.palette.textPrimary,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  badgeView: {
    height: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 0,
    paddingHorizontal: 6,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: theme.palette.border,
    backgroundColor: theme.palette.background,
  },
  badgeViewTouchable: {
    padding: 10,
    margin: -10,
  },
  badgeText: {
    ...theme.fontByWeight('700'),
    fontSize: 10,
    letterSpacing: 0.03,
    color: theme.palette.textPrimary,
    textTransform: 'uppercase',
  },
  badgeTextDisabled: {
    color: theme.palette.textSecondary,
  },
  badgeTextNotManaged: {
    color: theme.palette.disabled,
  },
  hoveredView: {
    borderColor: theme.palette.primary,
    backgroundColor: theme.palette.primary,
  },
  hoveredText: {
    color: '#ffffff',
  },
}));
