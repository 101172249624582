import React, {forwardRef, PropsWithChildren} from 'react';
import {View, ViewProps} from 'react-native';

import {useTheme} from '../styling';

export default forwardRef<View, PropsWithChildren<ViewProps>>(
  ({style, ...rest}, ref) => {
    const theme = useTheme();
    const styles = {
      root: {
        borderRadius: 10,
        borderWidth: 1,
        borderColor: theme.palette.border,
        backgroundColor: theme.palette.background,
        overflow: 'hidden',
      },
    } as const;
    return <View ref={ref} style={[styles.root, style]} {...rest} />;
  },
);
