import {
  ApiStoreService,
  CryptoFarmConnectedServerCalls,
  CryptoFarmServerNotifications,
} from '../ApiStore';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import {Auth} from '../Auth';
import {ScheduleStore} from '../ScheduleScreen/ScheduleStore';
import {Localization, TranslationService} from '../Localization';
import {Preferences} from '../Preferences';
import {
  DatabaseExporterImpl,
  DatabaseImporterImpl,
  DemonstrationDatabase,
} from '../LocalService';
import {SetUpWorkerDemoModal} from '../DashboardScreen/modals/SetUpWorkerDemoModal/SetUpWorkerDemoModalState';
import {RateAppService} from '../RateAppService';
import {PingService} from '../PingService';
import {DeviceIdentificationService} from '../DeviceIdentification';
import {
  MessagingIdentityNotifierService,
  MessagingIdentity,
} from '../MessagingIdentity';
import {WorkerStateRegistryService} from '../WorkerStateRegistry';
import {NotificationRegistrationService} from '../NotificationRegistration';
import {WorkerUpdateRegistryService} from '../WorkerUpdateRegistry';
import {VideoTutorialStateImpl} from '../VideoTutorialState';
import {CurrentSubscriptionImpl} from '../CurrentSubscription';
import {DebugEasterEggImpl} from '../Configuration';
import {NotificationSettingsImpl, NotificationsService} from '../Notifications';
import {LogFilterImpl} from '../LogFilter';
import {AppearanceService} from '../Appearance';
import {CannonService, ConnectionService} from '../ConnectionManager';
import ConnectionManagerServiceFactory from '../ConnectionManager/ConnectionManagerServiceFactory';
import {NoConnectionModalService} from '../NoConnectionContainer';
import {DisconnectImpl} from '../Disconnect';
import {ConnectionDebugService} from '../ConnectionDebug';
import {InAppPurchaseManager} from '../InAppPurchaseManager';
import {InstallIdentificationImpl} from '../InstallIdentification';
import {NavigationContainerImpl} from '../NavigationContainer';
import {
  FetchLogBridgeService,
  LogProviderService,
  WebSocketLogBridgeService,
  MessagingLogBridgeService,
} from '../Log';
import {HttpFactory} from '../Http';
import {Core} from '../Core';
import {Root} from './Root';
import {batchDisposers, Service} from '../structure';
import {
  HeadlessMessagingImpl,
  MessageHandlerImpl,
  Messaging,
  RemoteMessageStoreImpl,
} from '../Messaging';
import {JsonImpl} from '../Json';
import {AnalyticsImpl} from '../Analytics';
import {SpecialLocationImpl} from '../SpecialLocation';
import {LinkingOptionsProvider} from '../LinkingOptionsProvider';
import IAPLogBridgeService from '../Log/IAPLogBridgeService';
import {Share} from '../Share';
import {Sharing} from '../Sharing';
import {CachedInAppPurchaseImpl} from '../InAppPurchase';
import {PoolMinerActivatorService} from '../PoolMinerActivator';
import {NotificationLogBridgeService} from '../Log';
import {DashboardBannersStateImpl} from '../DashboardBannersState';
import {
  HeadlessLocalNotifications,
  LocalNotifications,
} from '../LocalNotifications';
import {Location, LocationSource} from '../Location';
import {DashboardModeService} from '../DashboardMode';
import {PurchaseDiscountFactory} from '../PurchasePromoService';
import {InteractiveTutorialImpl} from '../InteractiveTutorial';
import {
  TutorialHelperImpl,
  TutorialManagerStorageService,
} from '../TutorialManager';
import {InitialStateNavigationService} from '../InitialStateNavigationService';
import {CustomDisconnectService} from '../CustomDisconnect';
import {ServerFailureWatchService} from '../ServerFailureWatch';
import ConnectedClientImpl from '../ContextClient/ConnectedClientImpl';
import {SocketApiService} from '../../universal/features/api/socket/SocketApiService';
import {Client} from '../JsonRpc';
import {ErrorRepositoryImpl} from '../ErrorRepository';
import {Clipboard} from '../Clipboard';
import {AdvertHelperImpl, AdvertService} from '../Advert';
import {
  WindowDimensionsService,
  WindowDimensionsStateService,
} from '../WindowDimensions';
import {LogExporterImpl} from '../LogExporter';
import {FileSystemFactoryImpl} from '../FileSystem';
import {ProposalsStateImpl} from '../ProposalsState';
import {InAppLoaderStateImpl} from '../InAppLoaderState';
import {CurrentSlotSubscriptionService} from '../CurrentSlotSubscriptionService';
import {PendingPurchasesResolverFactory} from '../PendingPurchasesResolver';
import InAppOffersServiceFactory from '../InAppOffersService/InAppOffersServiceFactory';
import {DeferredDeeplinkFactory} from '../DeferredDeeplink';
import {NotAllowedModalStateImpl} from '../NotAllowedModalState';
import {BasicErrorModalStateImpl} from '../BasicErrorModalState';
import {AppWindowService, AppWindowStateService} from '../AppWindow';
import TenjinInitializerService from '../Tenjin/TenjinInitializerService';
import {AdvertisingIdentifierFactory} from '../AdvertisingIdentifier';
import {AuthFarmStateHelperImpl} from '../AuthFarmStateHelper';
import {QuickStartServiceFactory} from '../QuickStartService';
import {AccountInfoService} from '../AccountInfoService';
import {UtmUrlListenerService} from '../UtmUrlService';
import GiftImpl from '../Gift/GiftImpl';
import PurchaseScreenStateFactory from '../PurchaseScreen/states/PurchaseScreenStateFactory';
import PurchaseRequesterManagerImpl from '../PurchaseRequester/PurchaseRequesterManagerImpl';
import {PushAdvertService} from '../PushAdvert';
import ProductsProviderImpl from '../Products/ProductService';

export default abstract class BaseRootService implements Root, Service {
  protected constructor(protected readonly _core: Core) {}

  get headless() {
    return this._core.headless;
  }
  get debug() {
    return this._core.debug;
  }
  get appLifecycle() {
    return this._core.appLifecycle;
  }
  get configuration() {
    return this._core.configuration;
  }
  readonly json = new JsonImpl();
  readonly analytics = new AnalyticsImpl(this);
  private readonly _httpFactory = new HttpFactory();
  readonly http = this._httpFactory.create();
  readonly remoteMessageStore = new RemoteMessageStoreImpl(this);
  readonly specialLocation = new SpecialLocationImpl();
  readonly navigationContainer = new NavigationContainerImpl();
  readonly navigationContainerBinding = this.navigationContainer;
  readonly appWindow = new AppWindowService();
  readonly appWindowState = new AppWindowStateService(this);
  readonly initialStateNavigation = new InitialStateNavigationService(this);
  readonly log = new LogProviderService(this);
  private readonly _fetchLogBridge = new FetchLogBridgeService(this);
  private readonly _webSocketLogBridge = new WebSocketLogBridgeService(this);
  private readonly _iapLogBridge = new IAPLogBridgeService(this);
  private readonly _messagingLogBridge = new MessagingLogBridgeService(this);
  private readonly _notificationLogBridge = new NotificationLogBridgeService(
    this,
  );
  readonly db = new DemonstrationDatabase();
  readonly databaseImporter = new DatabaseImporterImpl(this);
  readonly databaseExporter = new DatabaseExporterImpl(this);
  readonly advertisingIdentifier = new AdvertisingIdentifierFactory(
    this,
  ).create();
  readonly deviceIdentification = new DeviceIdentificationService(this);
  private readonly _tenjinInitializer = new TenjinInitializerService(this);
  readonly disconnect = new DisconnectImpl();
  private readonly _connectionManagerServiceFactory =
    new ConnectionManagerServiceFactory(this);
  readonly connectionManager = this._connectionManagerServiceFactory.create();
  readonly connection = new ConnectionService(this);
  readonly cannon = new CannonService(this);
  readonly apiStore = new ApiStoreService(this);
  readonly connectedClient = new ConnectedClientImpl<
    CryptoFarmConnectedServerCalls,
    CryptoFarmServerNotifications
  >(this);
  readonly socketApi = new SocketApiService(
    this,
    this.apiStore.client as Client,
    this.connectedClient as Client,
  );
  readonly pingService = new PingService(this);
  readonly localization = new Localization(this);
  readonly preferences = new Preferences();
  readonly translation = new TranslationService(this);
  readonly notificationRegistration = new NotificationRegistrationService(this);
  readonly auth = new Auth(this);
  private readonly _accountInfo = new AccountInfoService(this);
  readonly authFarmStateHelper = new AuthFarmStateHelperImpl(this);
  readonly deferredDeeplink = new DeferredDeeplinkFactory(this).create();
  readonly headlessMessaging = new HeadlessMessagingImpl(this);
  readonly messageHandler = new MessageHandlerImpl(this);
  readonly messagingIdentityNotifier = new MessagingIdentityNotifierService(
    this,
  );

  readonly fileSystem = new FileSystemFactoryImpl(this).create();
  readonly logExporter = new LogExporterImpl(this);
  readonly utmUrl = new UtmUrlListenerService(this);
  // #IAP
  readonly inAppLoaderState = new InAppLoaderStateImpl();
  readonly inAppPurchase = new CachedInAppPurchaseImpl();
  readonly purchaseRequester = new PurchaseRequesterManagerImpl(this);
  readonly quickStartOffer = new QuickStartServiceFactory(this).create();
  abstract readonly inAppPurchaseManager: InAppPurchaseManager;
  readonly pendingPurchasesResolver = new PendingPurchasesResolverFactory(
    this,
  ).create();
  readonly inAppOffers = new InAppOffersServiceFactory(this).create();
  readonly proposalsState = new ProposalsStateImpl(this);
  readonly purchaseScreenState = new PurchaseScreenStateFactory(this).create();
  readonly gift = new GiftImpl(this);
  readonly currentSlotSubscription = new CurrentSlotSubscriptionService(this);
  //
  readonly purchaseDiscount = new PurchaseDiscountFactory(this).create();
  readonly notAllowedModalState = new NotAllowedModalStateImpl();
  readonly basicErrorModalState = new BasicErrorModalStateImpl();
  readonly appearance = new AppearanceService(this);
  readonly workerStateRegistry = new WorkerStateRegistryService(this);
  readonly dashboardBannersState = new DashboardBannersStateImpl(this);
  readonly dashboardStore = new DashboardStore(this);
  readonly dashboardModeService = new DashboardModeService(this);
  readonly scheduleStore = new ScheduleStore(this);
  readonly configPickerModal = new SetUpWorkerDemoModal(this);
  readonly errorRepository = new ErrorRepositoryImpl();
  readonly rateApp = new RateAppService(this);
  readonly workerUpdateRegistry = new WorkerUpdateRegistryService(this);
  readonly currentSubscriptionState = new CurrentSubscriptionImpl(this);
  readonly notificationService = new NotificationsService(this);
  readonly notificationSettingsStore = new NotificationSettingsImpl(this);
  readonly debugEasterEgg = new DebugEasterEggImpl(this);
  readonly logFilter = new LogFilterImpl(this);
  readonly noConnectionModal = new NoConnectionModalService(this);
  readonly connectionDebugService = new ConnectionDebugService(this);
  readonly installIdentification = new InstallIdentificationImpl(this);
  readonly tutorial = new VideoTutorialStateImpl(this);
  readonly interactiveTutorial = new InteractiveTutorialImpl(this);
  readonly tutorialHelper = new TutorialHelperImpl(this);
  readonly tutorialManagerStorage = new TutorialManagerStorageService();
  readonly customDisconnect = new CustomDisconnectService(this);
  readonly serverFailureWatch = new ServerFailureWatchService(
    this,
    this.apiStore.incoming,
  );
  readonly advert = new AdvertService(this);
  readonly advertHelper = new AdvertHelperImpl(this);
  readonly pushAdvert = new PushAdvertService(this);
  readonly windowDimensions = new WindowDimensionsService();
  readonly windowDimensionsState = new WindowDimensionsStateService(this);
  readonly poolMinerActivator = new PoolMinerActivatorService(this);
  readonly productsService = new ProductsProviderImpl(this);
  subscribe() {
    return batchDisposers(
      this._fetchLogBridge.subscribe(),
      this._webSocketLogBridge.subscribe(),
      this._iapLogBridge.subscribe(),
      this._messagingLogBridge.subscribe(),
      this._notificationLogBridge.subscribe(),
      this.utmUrl.subscribe(),
      this.inAppOffers.subscribe(),
      this.quickStartOffer.subscribe(),
      this.proposalsState.subscribe(),
      this.purchaseScreenState.subscribe(),
      this.currentSlotSubscription.subscribe(),
      this.initialStateNavigation.subscribe(),
      this.dashboardBannersState.subscribe(),
      this.appWindow.subscribe(),
      this.appWindowState.subscribe(),
      this.connectionManager.subscribe(),
      this.connection.subscribe(),
      this.cannon.subscribe(),
      this.apiStore.subscribe(),
      this.pingService.subscribe(),
      this.localization.subscribe(),
      this.preferences.subscribe(),
      this.notificationRegistration.subscribe(),
      this.db.subscribe(),
      this.appearance.subscribe(),
      this.auth.subscribe(),
      this._accountInfo.subscribe(),
      this._tenjinInitializer.subscribe(),
      this.deferredDeeplink.subscribe(),
      this.messagingIdentityNotifier.subscribe(),
      this.workerStateRegistry.subscribe(),
      this.dashboardStore.subscribe(),
      this.dashboardModeService.subscribe(),
      this.rateApp.subscribe(),
      this.workerUpdateRegistry.subscribe(),
      this.notificationService.subscribe(),
      this.noConnectionModal.subscribe(),
      this.connectionDebugService.subscribe(),
      this.poolMinerActivator.subscribe(),
      this.tutorialManagerStorage.subscribe(),
      this.customDisconnect.subscribe(),
      this.serverFailureWatch.subscribe(),
      this.purchaseDiscount.subscribe(),
      this.advert.subscribe(),
      this.pushAdvert.subscribe(),
      this.windowDimensions.subscribe(),
      this.windowDimensionsState.subscribe(),
      this.gift.init(),
    );
  }

  reset() {
    this.pingService.reset();
    this.auth.reset();
    this.configPickerModal.reset();
    this.workerStateRegistry.reset();
    this.dashboardStore.reset();
    this.poolMinerActivator.reset();
    this.dashboardBannersState.reset();
  }

  abstract readonly clipboard: Clipboard;
  abstract readonly location: Location;
  abstract readonly locationSource: LocationSource;
  abstract readonly headlessLocalNotifications: HeadlessLocalNotifications;
  abstract readonly localNotifications: LocalNotifications;
  abstract readonly linkingOptionsProvider: LinkingOptionsProvider;
  abstract readonly messaging: Messaging;
  abstract readonly messagingIdentity: MessagingIdentity;
  abstract readonly share: Share;
  abstract readonly sharing: Sharing;
}
