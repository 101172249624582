import {SvgProps} from 'react-native-svg';
import {useRoot} from '../Root/hooks';
import React from 'react';
import {sized} from '../Svg';
import FanSquareSvg from '../assets/svg/colored/fanSquare.svg';
import FanSquareBlackSvg from '../assets/svg/colored/fanSquareBlack.svg';
import {observer} from 'mobx-react-lite';

const FanSquareIcon = sized(FanSquareSvg, 40);
const FanSquareBlackIcon = sized(FanSquareBlackSvg, 40);

export default observer((props: SvgProps) => {
  const {
    appearance: {isDark},
  } = useRoot();
  if (isDark) {
    return <FanSquareBlackIcon {...props} />;
  }
  return <FanSquareIcon {...props} />;
});
