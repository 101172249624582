import {LocalNotificationsLocationSource} from './LocalNotificationsLocationSource';
import {BusImpl} from '../structure';
import {ActionId, Url} from '../units';
import {Either, error, success} from '../fp';
import {Json} from '../Json';
import {MessageContext} from '../Messaging';
import {
  EventType,
  LocalNotificationContext,
  LocalNotificationId,
} from './HeadlessLocalNotifications';
import notifee, {InitialNotification} from '../Notifee';
import {Platform} from 'react-native';
import isLocalNotificationData from './isLocalNotificationData';
import {CachedConsumable, CachedConsumableImpl} from '../CachedConsumable';

export default class LocalNotificationsLocationSourceImpl
  implements LocalNotificationsLocationSource
{
  private readonly _initialNotification: CachedConsumable<InitialNotification | null>;

  constructor(protected readonly _root: {readonly json: Json}) {
    if (Platform.OS === 'android') {
      this._initialNotification = new CachedConsumableImpl(() =>
        notifee.getInitialNotification(),
      );
    } else {
      this._initialNotification = {
        async getCachedConsumable() {
          return null;
        },
      };
    }
  }

  async getInitialContext(): Promise<
    Either<LocalNotificationContext, unknown>
  > {
    let initial;
    try {
      initial = await this._initialNotification.getCachedConsumable();
    } catch (raw) {
      return error(raw);
    }
    if (!initial) {
      return error(null);
    }
    const data = initial.notification?.data;
    if (!data || !isLocalNotificationData(data)) {
      return error(new Error('Wrong data'));
    }
    return success({
      type: EventType.Initial,
      data,
      id: initial.notification.id as LocalNotificationId | undefined,
      actionId: initial.pressAction.id as ActionId,
    });
  }

  async getInitial(): Promise<Either<Url, unknown>> {
    try {
      const initial = await this._initialNotification.getCachedConsumable();
      if (!initial) {
        return error(undefined);
      }
      const data = initial.notification.data;
      if (!data || !isLocalNotificationData(data)) {
        return error(undefined);
      }
      const messageContext_ = this._root.json.parse(data.context);
      if (!messageContext_.success) {
        return error(undefined);
      }
      const actionId = initial.pressAction.id as ActionId;
      const messageContext = messageContext_.right;
      const locator = this._getLocator(actionId, messageContext);
      if (locator === undefined) {
        return error(undefined);
      }
      return success(locator);
    } catch (raw) {
      return error(raw);
    }
  }

  public updates = new BusImpl<Url>();

  private _getLocator(
    actionId: ActionId,
    messageContext: MessageContext,
  ): Url | undefined {
    const {url, actions} = messageContext.message;
    return actions?.find((_) => _.action === actionId)?.url ?? url;
  }
}
