import React, {useCallback} from 'react';
import {Text, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {ReadonlyDeep} from 'type-fest';
import Hashrate from '../../../common/Hashrate';
import {BtcAmount} from '../../../common/BtcAmount';
import {useRoot, useStrings} from '../../../../Root/hooks';
import WorkerHashrate from '../../../common/WorkerHashrate';
import {createStylesHook} from '../../../../styling';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';

export interface ExtraParametersProps {
  worker: ReadonlyDeep<WorkerEntity>;
}

export default observer(({worker}: ExtraParametersProps) => {
  const strings = useStrings();
  const {dashboardStore} = useRoot();
  const balance = dashboardStore.getWorkerBalanceById(worker.id) / 1e12;
  const styles = useStyles();
  const hashrate = useCallback(
    () => <WorkerHashrate worker={worker} />,
    [worker],
  );
  return (
    <View style={styles.root}>
      <View style={[styles.row, styles.hashrateRow]}>
        <Text style={styles.label}>{strings['worker.minedSpeed']}:</Text>
        <Hashrate value={hashrate} />
      </View>
      <View style={styles.row}>
        <Text style={styles.label}>{strings['worker.minedTotal']}:</Text>
        <BtcAmount value={balance} />
      </View>
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    flex: 10,
    justifyContent: 'space-around',
    ...theme.mediaQuery({
      1000: {
        justifyContent: 'flex-start',
        paddingRight: 10,
      },
    }),
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...theme.mediaQuery({
      1000: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    }),
  },
  hashrateRow: {
    ...theme.mediaQuery({
      1000: {
        marginBottom: 10,
      },
    }),
  },
  label: {
    ...theme.fontByWeight('700'),
    fontSize: 11,
    lineHeight: 13,
    letterSpacing: 0.03,
    color: theme.palette.textSecondary,
    ...theme.mediaQuery({
      1000: {
        marginBottom: 5,
        fontSize: 12,
        lineHeight: 14,
      },
    }),
  },
}));
