import {batchDisposers, Service} from '../structure';
import {Log} from './Log';
import AppAnalyticsService from '../AppAnalytics/AppAnalyticsService';
import {
  AppEvent,
  FIRST_DEMO_MINER_ADDED,
  FIRST_EVENT_MADE,
  FIRST_GET_MINER_ID,
  FIRST_MINER_ADDED,
  FIRST_PURCHASE_MADE,
} from '../AppAnalytics/AppAnalytics';
import {bind} from '../fp';
import {APP_EVENT_MAP} from '../AppAnalytics/constants';

export default class FirebaseAnalyticsBridgeService implements Service {
  constructor(
    private readonly _root: {
      readonly appAnalytics: AppAnalyticsService;
      readonly log: Log;
    },
  ) {}

  private readonly _logger = (event: string, value?: string) => {
    const body = value ? `${event}: ${value}` : event;
    this._root.log.write({
      body: `FIREBASE ${body}`,
    });
  };

  private _translateEventToEventName(event: AppEvent) {
    return APP_EVENT_MAP.get(event);
  }

  private _onEvent = bind((event: AppEvent) => {
    const name = this._translateEventToEventName(event);
    if (name !== undefined) {
      this._logger(name);
    }
  }, this);

  private _onFirstEvent = bind((firstEvent: AppEvent) => {
    const name = this._translateEventToEventName(FIRST_EVENT_MADE);
    const value = this._translateEventToEventName(firstEvent);
    if (name !== undefined && value !== undefined) {
      this._logger(name, value);
    }
  }, this);

  subscribe() {
    const {updates} = this._root.appAnalytics;
    return batchDisposers(
      updates.listen(FIRST_EVENT_MADE, this._onFirstEvent),
      updates.listen(FIRST_DEMO_MINER_ADDED, this._onEvent),
      updates.listen(FIRST_MINER_ADDED, this._onEvent),
      updates.listen(FIRST_GET_MINER_ID, this._onEvent),
      updates.listen(FIRST_PURCHASE_MADE, this._onEvent),
    );
  }
}
