import React from 'react';
import {observer} from 'mobx-react-lite';
import {FullLogo, PressableOpacity} from '../components';
import CloseSvg from '../assets/svg/colorless/close.svg';
import {createStylesHook, useTheme} from '../styling';
import {SafeAreaView} from 'react-native-safe-area-context';
import {View} from 'react-native';

export type HeaderProps = {
  onClose: () => void;
};

export default observer((props: HeaderProps) => {
  const {onClose} = props;
  const theme = useTheme();
  const styles = useStyles();
  if (theme.window.width > 768) {
    return null;
  }
  return (
    <SafeAreaView edges={['top']} style={styles.root}>
      <View style={styles.content}>
        <FullLogo />
        <PressableOpacity onPress={onClose} style={styles.closeTouchable}>
          <CloseSvg color={theme.palette.icon} width={14} />
        </PressableOpacity>
      </View>
    </SafeAreaView>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    ...theme.bar(4),
    backgroundColor: theme.palette.bar,
    zIndex: 2,
  },
  content: {
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeTouchable: {
    position: 'absolute',
    right: 20,
    top: 23,
    padding: 10,
    margin: -10,
  },
}));
