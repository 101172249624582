import {LinkingOptions, ParamListBase} from '@react-navigation/native';

export const PREFIXES: LinkingOptions<ParamListBase>['prefixes'] = [
  'https://ctfarm-landing.dvlt.net',
  'https://farm-mobile.dvlt.net',
  'http://farm-mobile.dvlt.net',
  'http://localhost:19006',
  'com.cryptofarm://',
  'com.cryptofarm.pro://',
  'https://cryptofarm.page.link',
  'https://app.cryptotab.farm',
  'http://app.cryptotab.farm',
];
