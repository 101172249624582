import React from 'react';
import {observer} from 'mobx-react-lite';
import QuickStartScreenView, {
  QuickStartScreenViewProps,
} from './QuickStartScreenView';
import {variance} from '../styling';
import {ScrollView} from 'react-native-gesture-handler';

export default observer(function QuickStartScreen(
  props: QuickStartScreenViewProps,
) {
  return (
    <RootScrollView>
      <QuickStartScreenView {...props} />
    </RootScrollView>
  );
});

const RootScrollView = variance(ScrollView)(
  () => ({
    root: {},
    container: {
      flexGrow: 1,
    },
  }),
  (theme, styles) => ({contentContainerStyle: styles.container}),
);
