import React from 'react';
import {observer} from 'mobx-react-lite';
import {Platform, RefreshControl, RefreshControlProps} from 'react-native';

import {useTheme} from '../styling';

export default observer((props: RefreshControlProps) => {
  const theme = useTheme();
  const tintColor = Platform.select({
    ios: theme.palette.foreground,
    default: theme.palette.foreground,
  });
  return <RefreshControl tintColor={tintColor} {...props} />;
});
