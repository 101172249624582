import React, {useMemo} from 'react';
import {Text, TouchableOpacity, TouchableOpacityProps} from 'react-native';

import {useTheme} from '../styling';

interface ISecondaryMenuItem extends TouchableOpacityProps {
  text: string;
}

export const SecondaryMenuItem = ({
  text,
  style,
  ...rest
}: ISecondaryMenuItem) => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      ({
        root: {
          height: 43,
          justifyContent: 'center',
          paddingVertical: 6,
          paddingHorizontal: 14,
          flexShrink: 1,
          flexGrow: 1,
          overflow: 'visible',
        },
        text: {
          ...theme.fontByWeight('700'),
          textTransform: 'uppercase',
          color: theme.palette.textSecondary,
          fontSize: 11,
          lineHeight: 12,
          overflow: 'visible',
        },
      } as const),
    [theme],
  );

  return (
    <TouchableOpacity style={[styles.root, style]} {...rest}>
      <Text style={styles.text}>{text}</Text>
    </TouchableOpacity>
  );
};
