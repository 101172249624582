import {batchDisposers, RouterImpl, Service} from '../structure';
import {
  action,
  computed,
  observable,
  reaction,
  runInAction,
  when,
  makeObservable,
} from 'mobx';
import {
  Discount,
  DiscountSource,
  ParsedUrlDiscountParams,
  PURCHASE_PROMO_APPLIED,
  PURCHASE_PROMO_REMOVED,
  PurchaseDiscount,
  PurchaseDiscountEvents,
} from './PurchaseDiscount';
import {define, PURCHASE_PROMO_CODE} from '../persistence';
import {Auth, Connected} from '../Auth';
import {DiscountCode} from '../ApiStore';
import {LocationSource} from '../Location';
import {SpecialLocation} from '../SpecialLocation';
import dayjs from 'dayjs';
import {Second} from '../Time';
import {InstallIdentification} from '../InstallIdentification';
import {ConnectedClient} from '../ContextClient';

export default class PurchaseDiscountService
  implements PurchaseDiscount, Service
{
  @observable private _isInitialized: boolean = false;
  @observable.ref private _discount?: Discount;

  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly connectedClient: ConnectedClient;
      readonly locationSource: LocationSource;
      readonly specialLocation: SpecialLocation;
      readonly installIdentification: InstallIdentification;
    },
  ) {
    makeObservable(this);
  }

  get isInitialized() {
    return this._isInitialized;
  }

  get discount() {
    return this._discount;
  }

  @computed
  get hasPromoCode() {
    return this._discount !== undefined;
  }

  private static readonly _durationMap: Record<string, number> = {
    qwy: 120,
    dsf: 60,
    jeb: 30,
    dd: 20,
    prn: 10,
    dfk: 5,
  } as const;

  verifyPromoCode(code: DiscountCode) {
    return this._root.connectedClient.call('verify_promo_code', {
      code,
    });
  }

  async verifyAndApplyCodeWithTtl(code: DiscountCode, ttl?: Second) {
    const verify_ = await this.verifyPromoCode(code);
    const expirationDate = ttl
      ? dayjs().add(ttl, 'seconds').format()
      : undefined;
    if (verify_.success) {
      const newDiscount = {
        ...verify_.right,
        kind: DiscountSource.Url,
        expirationDate,
        code,
      };
      this.applyDiscount(newDiscount);
    }
  }

  private async _verifyAndApplyRestoredDiscount(discount: Discount) {
    const verify_ = await this.verifyPromoCode(discount.code);
    const expiration = discount.expirationDate;
    if (expiration) {
      if (dayjs().isAfter(dayjs(expiration))) {
        await this._deleteCurrentAccountPromo();
        return;
      }
    }

    if (verify_.success) {
      this.applyDiscount({
        ...verify_.right,
        ...discount,
      });
    }
  }

  private async _verifyAndApplyReferrerDiscountCode(code: DiscountCode) {
    const verify_ = await this.verifyPromoCode(code);
    if (verify_.success) {
      const newDiscount = {
        ...verify_.right,
        kind: DiscountSource.Referrer,
        code,
      };
      this.applyDiscount(newDiscount);
    }
  }

  private async _verifyAndApplyUrlDiscount(discount: ParsedUrlDiscountParams) {
    const discountFromStorage = await this._getFromStorage();
    const currentDiscount = this._discount ?? discountFromStorage;
    if (currentDiscount?.code === discount.code) {
      return this._verifyAndApplyRestoredDiscount(currentDiscount);
    }
    const {code, dd} = discount;
    const verify_ = await this.verifyPromoCode(code);
    if (verify_.success) {
      const extraDuration = dd && PurchaseDiscountService._durationMap[dd];
      const expirationDate = extraDuration
        ? dayjs().add(extraDuration, 'minutes').format()
        : undefined;
      const newDiscount = {
        ...verify_.right,
        kind: DiscountSource.Url,
        expirationDate,
        code,
      };
      this.applyDiscount(newDiscount);
    }
  }

  applyDiscount = action((discount: Discount) => {
    this._discount = discount;
    this.events.send(PURCHASE_PROMO_APPLIED, discount);
  });

  deleteDiscount = action(() => {
    this._discount = undefined;
    this.events.send(PURCHASE_PROMO_REMOVED, undefined);
  });

  private static _parseDiscountParamFromUrl(
    url: string,
  ): ParsedUrlDiscountParams | undefined {
    const url_ = new URL(url);
    const action_ = url_.searchParams.get('action');
    const code_ = url_.searchParams.get('code');
    const dd_ = url_.searchParams.get('dd');
    if (action_ === 'addcode' && code_) {
      return {code: code_ as DiscountCode, dd: dd_};
    }
    return undefined;
  }

  private async _getFromInitialUrl(): Promise<
    ParsedUrlDiscountParams | undefined
  > {
    const initial_ = await this._root.locationSource.getInitial();
    if (initial_.success) {
      return PurchaseDiscountService._parseDiscountParamFromUrl(initial_.right);
    }
    return undefined;
  }

  private async _getFromStorage(): Promise<Discount | undefined> {
    const restored = await getPromoCodeRecord();
    const farmId = this._root.auth.accountId;
    if (!restored.success || !restored.right || !farmId) {
      return undefined;
    }
    return restored.right && restored.right[farmId];
  }

  private async _getFromConnect(): Promise<
    Connected['purchaseDiscount'] | undefined
  > {
    const auth = this._root.auth;
    if (auth.state?.kind === 'Connected' && auth.state.purchaseDiscount) {
      const {purchaseDiscount} = auth.state;
      if (purchaseDiscount) {
        return purchaseDiscount;
      }
    }
    return undefined;
  }

  private async _getFromInstallReferrer(): Promise<DiscountCode | undefined> {
    const ref = await this._root.installIdentification.getRef();
    if (ref) {
      const promo = new URLSearchParams(ref).get('utm_ref');
      if (promo) {
        return promo as DiscountCode;
      }
    }
    return undefined;
  }

  private async _findAndApplyDiscount() {
    runInAction(() => {
      this._isInitialized = false;
      this._discount = undefined;
    });
    await (async () => {
      const installReferrerCode = await this._getFromInstallReferrer();
      if (installReferrerCode) {
        return this._verifyAndApplyReferrerDiscountCode(installReferrerCode);
      }
      const codeFromConnect = await this._getFromConnect();
      if (codeFromConnect) {
        return this.verifyAndApplyCodeWithTtl(
          codeFromConnect.code,
          codeFromConnect.ttl,
        );
      }
      const discountFromUrl = await this._getFromInitialUrl();
      if (discountFromUrl) {
        return this._verifyAndApplyUrlDiscount(discountFromUrl);
      }
      const codeFromStorage = await this._getFromStorage();
      if (codeFromStorage) {
        return this._verifyAndApplyRestoredDiscount(codeFromStorage);
      }
    })();
    runInAction(() => (this._isInitialized = true));
  }

  private async _deleteCurrentAccountPromo() {
    const farmId = this._root.auth.accountId;
    if (!farmId) {
      return;
    }
    const promos = await getPromoCodeRecord();
    if (!promos.success) {
      return;
    }
    const newPromos: PromoRecord = {...promos.right};
    delete newPromos[farmId];
    await setPromoCodeRecord(newPromos);
  }

  private _runWhenFarmAvailable() {
    return reaction(
      () => [this._root.auth.accountId, this._root.auth.isConnected],
      ([, isConnected]) => {
        if (isConnected) {
          this._findAndApplyDiscount();
        }
      },
    );
  }

  public readonly events = new RouterImpl<PurchaseDiscountEvents>();

  private _listenApplyPromoCode() {
    return this.events.listen(PURCHASE_PROMO_APPLIED, async (promoCode) => {
      const farmId = this._root.auth.accountId;
      if (!farmId) {
        return;
      }
      const promos = await getPromoCodeRecord();
      if (!promos.success) {
        return;
      }
      const newPromos = promos.right ?? {};
      newPromos[farmId] = promoCode;
      await setPromoCodeRecord(newPromos);
    });
  }

  private _listenDeletePromoCode() {
    return this.events.listen(PURCHASE_PROMO_REMOVED, async () => {
      await this._deleteCurrentAccountPromo();
    });
  }

  private _listenUpdateLocationSource() {
    return this._root.locationSource.updates.listen(async (data) => {
      const discount = PurchaseDiscountService._parseDiscountParamFromUrl(data);
      if (discount) {
        await when(() => this._root.auth.isConnected);
        await this._verifyAndApplyUrlDiscount(discount);
      }
    });
  }

  subscribe() {
    return batchDisposers(
      this._runWhenFarmAvailable(),
      this._listenApplyPromoCode(),
      this._listenDeletePromoCode(),
      this._listenUpdateLocationSource(),
    );
  }
}

type PromoRecord = Record<number, Discount>;

const [getPromoCodeRecord, setPromoCodeRecord] =
  define<PromoRecord>(PURCHASE_PROMO_CODE);
