import React from 'react';

import NotificationListDesktop from './NotificationListDesktop';
import NotificationListMobile from './NotificationListMobile';
import {observer} from 'mobx-react-lite';
import {useTheme} from '../styling';

export default observer(() => {
  const theme = useTheme();
  if (theme.window.width <= 1280) {
    return <NotificationListMobile />;
  }
  return <NotificationListDesktop />;
});
