import {action, flow, observable, when, makeObservable} from 'mobx';
import {bind, Either} from '../fp';
import {last} from 'lodash';
import {ISODateString} from '../Time';

interface ItemB {
  timestamp: ISODateString;
}

export default class TimestampPaginationRequest<ItemT extends ItemB> {
  @observable.ref private _items?: ItemT[];
  @observable private _isLoading = false;
  @observable private _isMoreLoading = false;

  private _loaded = false;

  constructor(
    private readonly _fetch: (
      timestamp?: ISODateString,
    ) => Promise<FetchResult<ItemT>>,
  ) {
    makeObservable(this);
  }

  get items() {
    return this._items;
  }

  get isLoading() {
    return this._isLoading;
  }

  get isMoreLoading(): boolean {
    return this._isMoreLoading;
  }

  @action unshift(item: ItemT) {
    const rest = this._items ?? [];
    this._items = [item, ...rest];
  }

  refresh = bind(
    flow(function* (this: TimestampPaginationRequest<ItemT>) {
      this._isLoading = true;
      try {
        this._loaded = false;
        const result: FetchResult<ItemT> = yield this._fetch();
        if (!result.success) {
          return;
        }
        this._items = result.right.items;
      } finally {
        this._isLoading = false;
      }
    }),
    this,
  );

  fetchNext = bind(
    flow(function* (this: TimestampPaginationRequest<ItemT>) {
      yield when(() => !this._isLoading && !this._isMoreLoading);
      if (this._loaded) {
        return;
      }
      try {
        const list = this._items ?? [];
        const lastItemTimestamp = last(list);
        if (lastItemTimestamp === undefined) {
          return;
        }
        this._isMoreLoading = true;
        const result: FetchResult<ItemT> = yield this._fetch(
          lastItemTimestamp.timestamp,
        );
        if (!result.success) {
          return;
        }
        const items = result.right.items;
        if (items.length === 0) {
          this._loaded = true;
        } else {
          this._items = [...list, ...items];
        }
      } finally {
        this._isMoreLoading = false;
      }
    }),
    this,
  );

  @action reset() {
    this._items = undefined;
    this._isLoading = false;
  }
}

export type FetchResult<ItemT> = Either<
  {
    limit: number;
    total: number;
    items: ItemT[];
  },
  unknown
>;
