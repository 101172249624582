import React, {PropsWithChildren, useCallback, useLayoutEffect} from 'react';
import {useRoot, useStrings} from '../Root/hooks';
import ScheduleState from './ScheduleState';
import {useNavigation} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import {TouchableOpacityProps} from 'react-native';
import {useStyles, useTheme} from '../styling';
import {PressableOpacity} from '../components';
import sized from '../Svg/sized';
import CheckCircleSvg from '../assets/svg/colorless/checkCircle.svg';

export default (state: ScheduleState, leave: () => void) => {
  const strings = useStrings();
  const title =
    state.workers.length > 1
      ? strings['scheduler.scheduleGroup']
      : strings['scheduler.scheduleWorker'];
  const navigation = useNavigation();
  const {scheduleStore} = useRoot();
  const handleSave = useCallback(async () => {
    await state.save();
    leave();
  }, [leave, state]);
  useLayoutEffect(() => {
    navigation.setOptions({
      title,
      headerRight: () =>
        state.numberOfChangedPoints > 0 ? (
          <HeaderActionButton onPress={handleSave} />
        ) : null,
    });
  }, [
    handleSave,
    navigation,
    scheduleStore.isLoading,
    state.numberOfChangedPoints,
    state.save,
    title,
  ]);
};

const HeaderActionButton = observer(
  ({style, ...rest}: PropsWithChildren<TouchableOpacityProps>) => {
    const styles = useStyles(() => ({
      root: {
        height: 40,
        width: 40,
        alignItems: 'center',
        justifyContent: 'center',
      },
    }));
    const theme = useTheme();
    return (
      <PressableOpacity style={[styles.root, style]} {...rest}>
        <CheckOutlineIcon color={theme.palette.secondary} />
      </PressableOpacity>
    );
  },
);

const CheckOutlineIcon = sized(CheckCircleSvg, 24);
