import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import {ButtonColor, ButtonVariant} from '../components';
import BanSvg from '../assets/svg/colorless/ban.svg';
import ErrorPanel from './ErrorPanel';
import TouchableButton from './TouchableButton';
import RetryButton from './RetryButton';
import layout from './layout';
import {sized} from '../Svg';
import RootView from './RootView';

export type UpdateRequiredScreenProps = {
  onUpdate: () => void;
  onClose: () => void;
};

export default observer(function UpdateRequiredScreen(
  props: UpdateRequiredScreenProps,
) {
  const {onUpdate, onClose} = props;
  const strings = useStrings();
  const footer = useMemo(
    () => (
      <>
        <RetryButton onPress={onUpdate} style={layout.buttonGutter}>
          {strings['action.update']}
        </RetryButton>
        <TouchableButton
          onPress={onClose}
          Icon={BanIcon}
          color={ButtonColor.Default}
          variant={ButtonVariant.Highlighted}>
          {strings['action.closeApp']}
        </TouchableButton>
      </>
    ),
    [onClose, onUpdate, strings],
  );
  return (
    <RootView style={layout.root} contentContainerStyle={layout.container}>
      <ErrorPanel
        title={strings['updateRequired.title']}
        description={strings['updateRequired.description']}
        footer={footer}
      />
    </RootView>
  );
});

const BanIcon = sized(BanSvg, 20);
