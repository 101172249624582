import React from 'react';
import {View, FlatListProps, ViewProps, Platform} from 'react-native';
import {observer} from 'mobx-react-lite';
import {PageFlatList} from '../containers';
import useHistoryRequest from './useHistoryRequest';
import {WithdrawalEntity} from '../ApiStore';
import Withdrawal from './Withdrawal';
import ListEmpty from './ListEmpty';
import {useStyles} from '../styling';
import ListHeader from './ListHeader';
import WithdrawalHistoryContext from './WithdrawalHistoryContext';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';

export interface WithdrawalHistoryScreenPropsBase {
  goToAffiliate: () => void;
  goToWithdraw: () => void;
}

export interface WithdrawalHistoryScreenProps
  extends ViewProps,
    WithdrawalHistoryScreenPropsBase {}

export default observer((props: WithdrawalHistoryScreenProps) => {
  const {goToAffiliate, goToWithdraw, style, ...rest} = props;
  const styles = useStyles((theme) => ({
    root: {
      flexGrow: 1,
      flexShrink: 1,
    },
    list: {
      flexGrow: 1,
      flexShrink: 1,
    },
    container: {
      flexGrow: 1,
      paddingBottom: 20,
      paddingHorizontal: 20,
      ...theme.mediaQuery({
        1280: {
          paddingBottom: 0,
        },
      }),
    },
    footer: {
      marginHorizontal: -20,
      ...Platform.select({
        web: {
          ...theme.mediaQuery({
            1200: {
              marginTop: 150,
            },
          }),
        },
      }),
    },
  }));
  const request = useHistoryRequest();
  return (
    <View style={[styles.root, style]} {...rest}>
      <DesktopWebAppHeader />
      <Provider value={{goToAffiliate, goToWithdraw}}>
        <PageFlatList
          style={styles.list}
          contentContainerStyle={styles.container}
          keyboardShouldPersistTaps="handled"
          ListHeaderComponent={ListHeader}
          ListEmptyComponent={request.list ? ListEmpty : undefined}
          footerStyle={styles.footer}
          data={request.list}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          refreshing={request.isLoading}
          onRefresh={request.refresh}
          onEndReached={request.fetchNext}
          removeClippedSubviews
        />
      </Provider>
    </View>
  );
});

type ListProps = FlatListProps<WithdrawalEntity>;

const keyExtractor: NonNullable<ListProps['keyExtractor']> = (item) =>
  String(item.id);

const renderItem: ListProps['renderItem'] = ({item}) => (
  <Withdrawal
    amount={item.amount}
    status={item.status}
    createdAt={new Date(item.created_at).getTime()}
  />
);

const {Provider} = WithdrawalHistoryContext;
