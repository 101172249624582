import {
  EventSubscription,
  NativeEventEmitter,
  NativeModules,
} from 'react-native';
import {EVENT, RetrievedResult} from './GoogleDeferredDeeplink';

const NativeGoogleDeferredDeeplink = NativeModules.GoogleDeferredDeeplink;

export default abstract class StaticGoogleDeferredDeeplinkImpl {
  private static _eventEmitter = new NativeEventEmitter(
    NativeGoogleDeferredDeeplink,
  );

  static addListener(op: (result: RetrievedResult) => void): EventSubscription {
    return this._eventEmitter.addListener(EVENT, op);
  }

  static removeListeners() {
    return NativeGoogleDeferredDeeplink.removeListeners();
  }

  static async getDeferredDeepLink(): Promise<RetrievedResult> {
    return NativeGoogleDeferredDeeplink.getDeferredDeepLink();
  }
}
