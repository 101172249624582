import React, {useCallback} from 'react';
import {forwardRef} from 'react';
import {
  View,
  ViewProps,
  Text as RNText,
  StyleProp,
  TextStyle,
  Platform,
} from 'react-native';
import {SvgProps} from 'react-native-svg';
import {observer} from 'mobx-react-lite';
import {useStyles, useTheme} from '../styling';
import {Hoverable} from 'react-native-web-hooks';
import negateColor from '../styling/util/negateColor';

export enum ButtonColor {
  Default,
  Primary,
  Error,
  Success,
  Black,
}

export enum ButtonVariant {
  Text,
  Contained,
  Highlighted,
  Disabled,
}

export interface ButtonProps extends ViewProps {
  Icon?: React.ComponentType<SvgProps>;
  iconProps?: SvgProps;
  iconPosition?: IconPosition;
  variant?: ButtonVariant;
  color?: ButtonColor;
  textStyle?: StyleProp<TextStyle>;
  children?: string | number;
}

export type IconPosition = 'left' | 'right';

export default forwardRef<View, ButtonProps>((props, ref) => {
  return <ObserverButton forwardedRef={ref} {...props} />;
});

interface ObserverButtonProps extends ButtonProps {
  forwardedRef: React.Ref<View>;
}

const ObserverButton = observer((props: ObserverButtonProps) => {
  const {
    forwardedRef,
    Icon,
    iconProps,
    iconPosition = 'left',
    variant = ButtonVariant.Contained,
    color = ButtonColor.Default,
    textStyle,
    style,
    children,
    ...rest
  } = props;
  const styles = useStyles((theme) => ({
    root: {
      paddingVertical: 12,
      paddingHorizontal: 15,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 10,
    },
    text: {
      ...theme.fontByWeight('bold'),
      fontSize: 13,
      lineHeight: 18,
      letterSpacing: 0.037,
      color: theme.palette.textPrimary,
      textTransform: 'uppercase',
    },
  }));
  const rootVariants = useStyles((theme) => ({
    [ButtonVariant.Text]: {},
    [ButtonVariant.Contained]: {
      borderRadius: 10,
      borderWidth: 1,
      borderColor: theme.palette.border,
      backgroundColor: theme.palette.background,
    },
    [ButtonVariant.Highlighted]: {
      borderRadius: 10,
      backgroundColor: theme.palette.secondary,
      borderColor: theme.palette.secondary,
      borderWidth: 1,
    },
    [ButtonVariant.Disabled]: {
      borderRadius: 10,
      backgroundColor: theme.palette.disabled,
      paddingVertical: 12.5, // TODO Border width compensation, fix after button rewrite
      paddingHorizontal: 15.5,
    },
  }));
  const rootColors = useStyles((theme) => ({
    [ButtonColor.Default]: {},
    [ButtonColor.Primary]: {},
    [ButtonColor.Error]: {
      backgroundColor: theme.palette.error,
      borderColor: theme.palette.error,
      borderWidth: 1,
    },
    [ButtonColor.Success]: {
      backgroundColor: theme.palette.success,
      borderColor: theme.palette.success,
      borderWidth: 1,
    },
    [ButtonColor.Black]: {
      backgroundColor: negateColor(theme.palette.background),
      borderColor: negateColor(theme.palette.background),
    },
  }));
  const textVariants = useStyles((theme) => ({
    [ButtonVariant.Text]: {},
    [ButtonVariant.Contained]: {},
    [ButtonVariant.Highlighted]: {
      color: theme.select(
        theme.contrast(theme.palette.textPrimary),
        theme.palette.textPrimary,
      ),
    },
    [ButtonVariant.Disabled]: {
      color: theme.palette.disabled,
    },
  }));
  const textColors = useStyles((theme) => ({
    [ButtonColor.Default]: {},
    [ButtonColor.Primary]: {
      color: theme.palette.secondary,
    },
    [ButtonColor.Error]: {},
    [ButtonColor.Success]: {
      color: theme.select(
        theme.contrast(theme.palette.textPrimary),
        theme.palette.textPrimary,
      ),
    },
    [ButtonColor.Black]: {
      color: theme.palette.background,
    },
  }));
  const hoverViewVariants = useStyles((theme) => ({
    [ButtonVariant.Text]: {},
    [ButtonVariant.Contained]: {
      borderColor: theme.palette.primary,
      backgroundColor: theme.palette.primary,
    },
    [ButtonVariant.Highlighted]: {
      borderColor: theme.palette.primary,
      backgroundColor: theme.palette.primary,
    },
    [ButtonVariant.Disabled]: {},
  }));
  const hoverTextVariants = useStyles((theme) => ({
    [ButtonVariant.Text]: {
      color: theme.palette.primary,
    },
    [ButtonVariant.Contained]: {
      color: theme.palette.white,
    },
    [ButtonVariant.Highlighted]: {
      color: theme.palette.white,
    },
    [ButtonVariant.Disabled]: {},
  }));

  const theme = useTheme();

  const iconColor = useCallback(
    (isHoverable?: boolean) => {
      switch (variant) {
        case ButtonVariant.Contained:
          return isHoverable ? theme.palette.white : theme.palette.secondary;
        case ButtonVariant.Disabled:
          return;
        case ButtonVariant.Highlighted:
          return isHoverable
            ? theme.palette.white
            : theme.select(
                theme.contrast(theme.palette.textPrimary),
                theme.palette.textPrimary,
              );
        case ButtonVariant.Text:
          if (isHoverable) {
            return theme.palette.primary;
          }
          if (color === ButtonColor.Primary) {
            return theme.palette.secondary;
          }

          return theme.palette.textPrimary;
        default:
          return isHoverable ? theme.palette.white : theme.palette.textPrimary;
      }
    },
    [color, variant, theme],
  );

  const renderButton = (isHoverable?: boolean) => (
    <View
      ref={forwardedRef}
      style={[
        styles.root,
        rootVariants[variant],
        rootColors[color],
        style,
        isHoverable && hoverViewVariants[variant],
      ]}
      {...rest}>
      {Icon && iconPosition === 'left' && (
        <Icon color={iconColor(isHoverable)} {...iconProps} />
      )}
      {children && (
        <RNText
          style={[
            styles.text,
            textVariants[variant],
            textColors[color],
            textStyle,
            isHoverable && hoverTextVariants[variant],
          ]}>
          {children}
        </RNText>
      )}
      {Icon && iconPosition === 'right' && (
        <Icon color={iconColor(isHoverable)} {...iconProps} />
      )}
    </View>
  );

  if (Platform.OS === 'web') {
    return <Hoverable>{(isHoverable) => renderButton(isHoverable)}</Hoverable>;
  }

  return renderButton();
});
