import {ServerFailureWatch} from './ServerFailureWatch';
import {BusSource, Service} from '../structure';
import {isError, isResponse, Request, Response} from '../JsonRpc';
import {observable, runInAction, makeObservable} from 'mobx';
import {CustomDisconnect} from '../CustomDisconnect';
import {ConnectionManager} from '../ConnectionManager';

export default class ServerFailureWatchService
  implements ServerFailureWatch, Service
{
  @observable private _lastErrorMessage?: string;

  constructor(
    private readonly _root: {
      readonly customDisconnect: CustomDisconnect;
      readonly connectionManager: ConnectionManager;
    },
    private readonly _incoming: BusSource<Request | Response>,
  ) {
    makeObservable(this);
  }

  get lastErrorMessage() {
    return this._lastErrorMessage;
  }

  subscribe() {
    return this._incoming.listen((message) => {
      if (
        isResponse(message) &&
        isError(message) &&
        message.error.code === 9500
      ) {
        runInAction(() => {
          const title = message.error.message;
          const body = JSON.stringify(message.error.data, undefined, 2);
          this._lastErrorMessage = `${title}\n${body}`;
        });
        this._root.customDisconnect.activate();
        this._root.connectionManager.disconnect();
      }
    });
  }
}
