import React, {useCallback} from 'react';
import {ListRenderItem, Platform, View, ViewProps} from 'react-native';
import {observer} from 'mobx-react-lite';
import {expr} from 'mobx-utils';
import {useStrings, useRoot} from '../Root/hooks';
import ChooseAccountSvg from '../assets/svg/circled/chooseAccount.svg';
import {Subscription} from '../farmApi';
import {EmptyFarmView, SubscriptionView} from '../SubscriptionView';
import {AuthStatus} from '../Auth';
import {Container} from '../components/Grid';
import SwitchFarmHeader from '../components/SwitchFarmScreenHeader';
import {PageFlatList} from '../containers';
import {FarmId} from '../ApiStore';
import {useStyles} from '../styling';
import {PressableOpacity} from '../components';

export type AccountSwitchScreenProps = {
  goToDashboard: () => void;
};

export default observer((props: AccountSwitchScreenProps) => {
  const {goToDashboard} = props;
  const styles = useStyles((theme) => ({
    root: {
      flex: 1,
    },
    footer: {
      ...Platform.select({
        web: {
          ...theme.mediaQuery({
            1200: {
              marginTop: 80,
            },
          }),
        },
      }),
    },
    header: {
      marginBottom: 10,
    },
    item: {
      marginBottom: 10,
      marginHorizontal: 20,
    },
    itemSelected: {
      borderWidth: 1,
      borderColor: theme.palette.secondary,
    },
  }));
  const {auth} = useRoot();
  const refresh = useCallback(() => auth.authorize(), [auth]);
  const renderItem = useCallback<ListRenderItem<Farm>>(
    ({item}) => (
      <Item
        style={[
          styles.item,
          auth.state?.kind === 'Connected' &&
            auth.state.accountId === item.accountId &&
            styles.itemSelected,
        ]}
        farm={item}
        goToDashboard={goToDashboard}
      />
    ),
    [auth, goToDashboard, styles],
  );
  const keyExtractor = useCallback((item: Farm) => String(item.accountId), []);
  const subscriptions = expr(() => {
    if (
      auth.state &&
      (auth.state.kind === 'Authorized' ||
        auth.state.kind === 'Connected' ||
        auth.state.kind === 'ConnectionFailed')
    ) {
      const {state} = auth;
      return [...auth.state.accountIds.values()].map<Farm>((accountId) => {
        const subscription = state.subscriptionMap.get(accountId);
        return subscription
          ? {empty: false, ...subscription}
          : {empty: true, accountId};
      });
    }
    return undefined;
  });
  return (
    <View style={styles.root}>
      <PageFlatList
        ListHeaderComponent={ListHeader}
        ListHeaderComponentStyle={styles.header}
        data={subscriptions}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        footerStyle={styles.footer}
        refreshing={auth.status !== AuthStatus.Idle}
        onRefresh={refresh}
      />
    </View>
  );
});

const ListHeader = observer(() => {
  const strings = useStrings();
  const {auth} = useRoot();

  let description = auth.email
    ? strings['accountSwitch.emailDescription']
        .replace('{email}', `&${auth.email}&`)
        .split('&')
    : strings['accountSwitch.description'];
  return (
    <Container>
      <SwitchFarmHeader
        title={strings['accountSwitch.title']}
        description={description}
        Icon={ChooseAccountSvg}
      />
    </Container>
  );
});

export interface ItemProps extends ViewProps {
  farm: Farm;
  goToDashboard: () => void;
}

export type Farm = EmptyFarm | SufficientFarm;

export type SufficientFarm = Subscription & {
  empty: false;
};

export type EmptyFarm = {
  empty: true;
  accountId: FarmId;
};

export const Item = observer((props: ItemProps) => {
  const {farm, goToDashboard, ...rest} = props;
  const {auth} = useRoot();
  const {accountId} = farm;
  const onPress = useCallback(async () => {
    await auth.selectAccount(accountId);
    goToDashboard();
  }, [auth, accountId, goToDashboard]);
  return (
    <Container>
      <PressableOpacity onPress={onPress}>
        {farm.empty ? (
          <EmptyFarmView accountId={farm.accountId} />
        ) : (
          <SubscriptionView {...farm} {...rest} />
        )}
      </PressableOpacity>
    </Container>
  );
});
