import {observable, runInAction, makeObservable} from 'mobx';
import {NotificationPermissions} from './NotificationPermissions';
import notifee, {AuthorizationStatus} from '../Notifee';

export default class IosNotificationPermissionsImpl
  implements NotificationPermissions
{
  @observable _granted = false;

  constructor() {
    makeObservable(this);
  }

  get granted() {
    return this._granted;
  }

  async ask() {
    const settings = await notifee.requestPermission({
      provisional: true,
      sound: true,
      announcement: true,
      alert: true,
      badge: true,
      carPlay: false,
      criticalAlert: false,
    });
    runInAction(() => {
      this._granted =
        settings.authorizationStatus === AuthorizationStatus.AUTHORIZED ||
        settings.authorizationStatus === AuthorizationStatus.PROVISIONAL;
    });
  }
}
