import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {Text, View} from 'react-native';
import Hashrate from '../DashboardScreen/common/Hashrate';
import {BtcAmount} from '../DashboardScreen/common/BtcAmount';
import {useRoot, useStrings} from '../Root/hooks';
import WorkerHashrate from '../DashboardScreen/common/WorkerHashrate';
import {ReadonlyDeep} from 'type-fest';
import {
  WorkerEntity,
  WorkerType,
} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useStyles} from '../styling';
import dayjs from 'dayjs';

export interface WorkerExtraProps {
  worker: ReadonlyDeep<WorkerEntity>;
}

export default observer(({worker}: WorkerExtraProps) => {
  const {
    dashboardStore,
    translation: {localeTag},
  } = useRoot();
  const strings = useStrings();
  const balance = dashboardStore.getWorkerBalanceById(worker.id) / 1e12;
  const hashrate = useCallback(
    () => <WorkerHashrate worker={worker} />,
    [worker],
  );
  const styles = useStyles((theme) => ({
    root: {
      justifyContent: 'space-between',
      paddingVertical: 10,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    offsetRow: {
      marginBottom: 10,
    },
    left: {
      flexBasis: '50%',
    },
    right: {
      flexBasis: '50%',
      alignItems: 'flex-end',
    },
    label: {
      ...theme.fontByWeight('700'),
      fontSize: 12,
      lineHeight: 13,
      letterSpacing: 0.03,
      color: theme.palette.textSecondary,
    },
    date: {
      ...theme.fontByWeight('400'),
      color: theme.palette.textPrimary,
      fontSize: 13,
      lineHeight: 15,
    },
  }));
  const isPool = worker.worker_type === WorkerType.Pool;
  return (
    <View style={styles.root}>
      <View style={[styles.row, styles.offsetRow]}>
        <View style={styles.left}>
          <Text style={styles.label}>{strings['worker.minedSpeed']}:</Text>
        </View>
        <Hashrate value={hashrate} />
      </View>

      <View style={[styles.row, isPool && styles.offsetRow]}>
        <View style={styles.left}>
          <Text style={styles.label}>{strings['worker.minedTotal']}:</Text>
        </View>
        <View style={styles.right}>
          <BtcAmount value={balance} />
        </View>
      </View>

      {isPool ? (
        <>
          <View style={[styles.row, styles.offsetRow]}>
            <View style={styles.left}>
              <Text style={styles.label}>
                {strings['workerDetails.activated']}:
              </Text>
            </View>
            <View style={styles.right}>
              {worker.start_time && (
                <Text style={styles.date}>
                  {dayjs(worker.start_time)
                    .locale(localeTag)
                    .format('DD MMM YYYY')}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.left}>
              <Text style={styles.label}>
                {strings['workerDetails.expires']}:
              </Text>
            </View>
            <View style={styles.right}>
              {worker.end_time && (
                <Text style={styles.date}>
                  {dayjs(worker.end_time)
                    .locale(localeTag)
                    .format('DD MMM YYYY')}
                </Text>
              )}
            </View>
          </View>
        </>
      ) : null}
    </View>
  );
});
