import React from 'react';
import {observer} from 'mobx-react-lite';
import {PageFlatList} from '../containers';
import {useRoot} from '../Root/hooks';
import {FlatListProps} from 'react-native';
import {CustomEnvironment} from '../Configuration';
import {ReadonlyDeep} from 'type-fest';
import CustomEnvironmentItem from './CustomEnvironmentItem';
import {expr} from 'mobx-utils';
import {useCallback} from 'react';
import DefaultEnvironmentItem from './DefaultEnvironmentItem';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';

export default observer(() => {
  const {configuration} = useRoot();
  return (
    <>
      <DesktopWebAppHeader />
      <PageFlatList
        ListHeaderComponent={HeaderBinding}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        data={configuration.customEnvironments}
      />
    </>
  );
});

type ListProps = FlatListProps<CustomEnvironment>;

const keyExtractor: NonNullable<ListProps['keyExtractor']> = (item) =>
  String(item.id);

const renderItem: ListProps['renderItem'] = ({item}) => (
  <CustomEnvironmentBinding item={item} />
);

interface CustomEnvironmentBindingProps {
  item: ReadonlyDeep<CustomEnvironment>;
}

const CustomEnvironmentBinding = observer(
  (props: CustomEnvironmentBindingProps) => {
    const {item} = props;
    const {configuration} = useRoot();
    const selected = expr(
      () =>
        !configuration.current.isDefault &&
        configuration.current.id === item.id,
    );
    const onPress = useCallback(
      () => configuration.setEnvironment(item.id),
      [configuration, item.id],
    );
    const onDeletePress = useCallback(
      () => configuration.deleteEnvironment(item.id),
      [configuration, item.id],
    );
    return (
      <CustomEnvironmentItem
        url={item.values.mainSocketUrl}
        selected={selected}
        onPress={onPress}
        onDeletePress={onDeletePress}
      />
    );
  },
);

const HeaderBinding = observer(() => {
  const {configuration} = useRoot();
  const environment = configuration.defaultEnvironment;
  const selected = expr(() => configuration.current.isDefault);
  const onPress = useCallback(
    () => configuration.setEnvironment(),
    [configuration],
  );
  return (
    <DefaultEnvironmentItem
      url={environment.values.mainSocketUrl}
      selected={selected}
      onPress={onPress}
    />
  );
});
