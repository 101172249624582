import {Server} from '../JsonRpc';
import {
  Bitcoin,
  CryptoFarmConnectedServerCalls,
  CryptoFarmServerCalls,
  CryptoFarmServerNotifications,
} from '../ApiStore';
import {batchDisposers} from '../structure';
import {success} from '../fp';
import {MiningController} from './MiningController';
import {DAY, HOUR} from '../utils/time';
import {Millisecond} from '../Time';

export default (
  server: Server<
    CryptoFarmServerCalls & CryptoFarmConnectedServerCalls,
    CryptoFarmServerNotifications
  >,
  mining: MiningController,
) =>
  batchDisposers(
    server.call('get_total_balances', async (params, response) => {
      const map = await mining.getTotalBalances(params.worker_ids);
      return response.respond(
        success({
          balances: Object.entries(map).map(([id, amount]) => ({
            worker_id: id,
            amount: amount * 1e12,
          })),
        }),
      );
    }),
    server.call('get_account_balance', async (params, response) => {
      const result = await mining.getAccountBalance();
      const total = result.total as Bitcoin;
      return response.respond(success({total}));
    }),
    server.call('get_statistic', async (params, response) => {
      const local = await mining.getMiningStatistic(
        params.worker_ids,
        (params.start_time * 1000) as Millisecond,
        (params.end_time * 1000) as Millisecond,
        params.interval === 'daily' ? DAY : HOUR,
      );
      return response.respond(
        success({
          points: [...local.entries()].flatMap(([id, period]) =>
            period.results.map((amount, index) => ({
              worker_id: id,
              amount: amount * 1e12,
              timestamp:
                period.results.length === 1
                  ? (period.from + period.to) / 2 / 1000
                  : Math.floor(
                      (period.from +
                        ((period.to - period.from) /
                          (period.results.length - 1)) *
                          index) /
                        1000,
                    ),
            })),
          ),
        }),
      );
    }),
  );
