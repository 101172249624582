import {RouterSource} from '../structure';
import {Opaque} from 'type-fest';

export const REQUEST = Symbol();
export const RESPONSE = Symbol();
export const RESPONSE_BODY = Symbol();
export const NEW_WEB_SOCKET = Symbol();

export interface Http {
  fetch(input: RequestInfo, init?: RequestInit): Promise<Response>;
  createWebSocket(uri: string, protocols?: string | string[]): WebSocket;
  io: RouterSource<{
    [REQUEST]: RequestParams;
    [RESPONSE]: ResponseParams;
    [NEW_WEB_SOCKET]: NewWebSocketParams;
    [RESPONSE_BODY]: ResponseBodyParams;
  }>;
}

export interface RequestParams {
  correlationId: CorrelationId;
  input: RequestInfo;
  init?: RequestInit;
}

export interface ResponseParams {
  correlationId: CorrelationId;
  response: Response;
}

export interface NewWebSocketParams {
  correlationId: CorrelationId;
  uri: string;
  protocols?: string | string[];
  socket: WebSocket;
}

export interface ResponseBodyParams {
  correlationId: CorrelationId;
  responseBody: string;
}

export const CORRELATION_ID = Symbol();
export type CorrelationId = Opaque<number, typeof CORRELATION_ID>;
