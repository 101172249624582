import React from 'react';
import {View, Text, StyleProp, TextStyle} from 'react-native';

import BtcSvg from '../../assets/svg/colored/btc.svg';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../../styling';
import toFixedTrunc from '../../utils/toFixedTrunc';
import {sized} from '../../Svg';

interface BtcAmountProps {
  value: number | string | (() => React.ReactNode);
  style?: StyleProp<TextStyle>;
}

export const BtcAmount = observer(({value = 0, style}: BtcAmountProps) => {
  const styles = useStyles((theme) => ({
    root: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    value: {
      ...theme.fontByWeight('700'),
      fontSize: 15,
      lineHeight: 17,
      letterSpacing: 0.04,
      marginLeft: 6,
      color: theme.palette.textPrimary,
    },
  }));
  return (
    <View style={styles.root}>
      <BtcIcon />
      <Text style={[styles.value, style]}>
        {typeof value === 'function'
          ? value()
          : typeof value === 'number'
          ? toFixedTrunc(value, 8)
          : value}{' '}
        BTC
      </Text>
    </View>
  );
});

const BtcIcon = sized(BtcSvg, 20);
