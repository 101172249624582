import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {Container} from '../components/Grid';
import {variance} from '../styling';
import {useRoot} from '../Root/hooks';
import {HashrateSection} from './HashrateSection';
import {CurrentPeriodSection} from './CurrentPeriodSection';
import {RenewButtonSection} from './RenewButtonSection';
import {AccessInfo} from './AccessInfo';
import {CryptoLoader} from '../components/CryptoLoader';
import {useBoolean} from '../hooks';
import NeedMoreSlotsModal from '../PurchaseScreen/shared/components/NeedMoreSlotsModal';
import {useIsFocused} from '@react-navigation/native';
import {PurchaseNotAvailable} from './PurchaseNotAvailable';
import AddPromoModal from '../PurchaseScreen/shared/components/AddPromoModal';
import {ISODateString} from '../Time';
import {useRenewPoolMiner, useCheckCanMinerBuy} from '../PurchaseRequester';
import {useHandleErrorAndNavigate} from '../ExceptionHandler';
import TotalPriceBinding from './TotalPriceBinding';
import ProposalTableBiding from './ProposalTableBinding';
import {expr} from 'mobx-utils';
import RenewAccessScreenStateFactory from './RenewAccessScreenStateFactory';

export type RenewAccessScreenProps = {
  workerId: string;
  goToPoolPurchase: () => void;
  goToPurchase: () => void;
  safelyGoToAffiliate: () => void;
  getAffiliateIsPending: () => boolean;
  goToPoolMinerActivated: (
    poolExpiredDate: ISODateString,
    poolHashrate: number,
  ) => void;
};

export default observer((props: RenewAccessScreenProps) => {
  const {
    workerId,
    goToPoolPurchase,
    goToPurchase,
    safelyGoToAffiliate,
    getAffiliateIsPending,
    goToPoolMinerActivated,
  } = props;
  const root = useRoot();
  const {inAppOffers, purchaseDiscount, inAppLoaderState, proposalsState} =
    root;
  const [renewState] = useState(() =>
    new RenewAccessScreenStateFactory(root, workerId).create(),
  );
  const [enterPromoVisible, openEnterPromo, hideEnterPromo] = useBoolean(false);
  const {
    worker,
    isLoading,
    selectedPaymentMethod,
    selectedProposal,
    selectProposal,
  } = renewState;
  const checkCanBuy = useCheckCanMinerBuy();
  useLayoutEffect(() => renewState.subscribe(), [renewState]);
  const isFocused = useIsFocused();
  useEffect(() => {
    if (!isFocused && purchaseDiscount.hasPromoCode) {
      purchaseDiscount.deleteDiscount();
    }
  }, [isFocused, purchaseDiscount, inAppOffers]);
  const [needSlotsVisible, showNeedSlotsModal, hideNeedSlotsModal] =
    useBoolean(false);

  const errorHandler = useHandleErrorAndNavigate();
  const renew = useRenewPoolMiner({errorHandler});

  const handleRenew = useCallback(async () => {
    if (
      worker === undefined ||
      !worker.pool_miner_id ||
      worker.pool_miner_config === null ||
      selectedProposal?.available === undefined
    ) {
      return;
    }
    const canBuy = checkCanBuy();
    if (!canBuy) {
      return showNeedSlotsModal();
    }
    inAppLoaderState.addLoading();
    await renew({
      offer: selectedProposal.offer,
      poolMinerId: worker.pool_miner_id,
      goToPoolMinerActivated,
      paymentMethod: selectedPaymentMethod,
    });
    inAppLoaderState.deleteLoading();
  }, [
    selectedPaymentMethod,
    worker,
    selectedProposal,
    checkCanBuy,
    inAppLoaderState,
    renew,
    goToPoolMinerActivated,
    showNeedSlotsModal,
  ]);

  const proposalTable = expr(() => {
    const proposal = renewState.selectedProposal;
    if (!proposal || !proposal?.available || !proposal.offer.poolMinerConfig) {
      return undefined;
    }
    return proposalsState.doubleProposalByIntervalByHashrate.get(
      proposal.offer.poolMinerConfig.hash_rate,
    );
  });

  if (isLoading || !worker) {
    return <Loader />;
  }

  const _renderContent = () => {
    return (
      <Root>
        <ScrollView contentContainerStyle={styles.container}>
          <AccessInfo
            hasAtLeastOnePlan={!!selectedProposal?.available}
            worker={worker}
          />
          <ContentView>
            <HashrateSection
              worker={worker}
              hasAtLeastOnePlan={!!selectedProposal?.available}
              onAddNewPoolPress={goToPoolPurchase}
            />
            <CurrentPeriodSection worker={worker} />
            {inAppOffers.error ? (
              <PurchaseNotAvailable />
            ) : (
              <>
                <ProposalTableBiding
                  proposalTable={proposalTable}
                  selectedProposal={selectedProposal}
                  selectProposal={selectProposal}
                  selectedPaymentMethod={selectedPaymentMethod}
                />
                <TotalPriceBinding
                  selectedProposal={selectedProposal}
                  onPurchasePress={handleRenew}
                  safelyGoToAffiliate={safelyGoToAffiliate}
                  getAffiliateIsPending={getAffiliateIsPending}
                  openEnterPromoModal={openEnterPromo}
                />
                <RenewButtonSection
                  onPress={handleRenew}
                  selectedPoolOffer={selectedProposal?.offer}
                />
              </>
            )}
          </ContentView>
        </ScrollView>
      </Root>
    );
  };

  return (
    <>
      {_renderContent()}
      {needSlotsVisible && (
        <NeedMoreSlotsModal
          onDismiss={hideNeedSlotsModal}
          onSubmit={goToPurchase}
        />
      )}
      {enterPromoVisible && (
        <AddPromoModal
          onDismiss={hideEnterPromo}
          safelyGoToAffiliate={safelyGoToAffiliate}
          getAffiliateIsPending={getAffiliateIsPending}
        />
      )}
    </>
  );
});

const Loader = observer(() => {
  return (
    <LoaderRoot>
      <CryptoLoader />
    </LoaderRoot>
  );
});

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 20,
  },
});

const Root = variance(Container)(() => ({
  root: {
    flex: 1,
  },
}));

const LoaderRoot = variance(View)(() => ({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ContentView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    borderWidth: 1,
    borderColor: theme.palette.border,
  },
}));
