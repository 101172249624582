import React, {PropsWithChildren, useMemo} from 'react';
import {View, ViewProps} from 'react-native';
import {observer} from 'mobx-react-lite';

import {useTheme} from '../../styling';

export const List = observer(
  ({children, style, ...rest}: PropsWithChildren<ViewProps>) => {
    const theme = useTheme();
    const styles = useMemo(
      () =>
        ({
          root: {
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: theme.palette.border,
            ...theme.mediaQuery({
              1281: {
                borderWidth: 1,
              },
            }),
          },
        } as const),
      [theme],
    );
    return (
      <View style={[styles.root, style]} {...rest}>
        {children}
      </View>
    );
  },
);
