import React from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot, useStrings} from '../Root/hooks';
import {sized} from '../Svg';
import {variance} from '../styling';
import {
  Insets,
  Text,
  TouchableWithoutFeedback,
  View,
  ViewProps,
} from 'react-native';
import {expr} from 'mobx-utils';
import isWorkerOffline from '../DashboardScreen/checkers/isWorkerOffline';
import FanSquareLogo from './FanSquareLogo';

type FarmInformationProps = ViewProps & {isLarge?: boolean};

export default observer(function FarmInformation(props: FarmInformationProps) {
  const {isLarge, ...rest} = props;
  const strings = useStrings();
  const {
    currentSubscriptionState,
    dashboardStore,
    workerStateRegistry,
    debugEasterEgg,
  } = useRoot();
  const {subscription} = currentSubscriptionState;
  const totalSlots = subscription?.maxSlots ?? 0;
  const workerList = [...dashboardStore.workers.values()];
  const totalPoolMinerWorkers = subscription?.poolMiners ?? 0;
  const totalOfflineWorkers = expr(
    () =>
      workerList.filter((_) =>
        isWorkerOffline(workerStateRegistry.states.get(_.id)?.state),
      ).length,
  );
  const totalOnlineWorkers = workerList.length - totalOfflineWorkers;
  const totalWorkers = workerList.length;
  return (
    <SlotsInfo {...rest}>
      <TouchableWithoutFeedback
        hitSlop={HIT_SLOP}
        onPress={debugEasterEgg.tapFirst}>
        <TotalSlotsView large={isLarge}>
          {isLarge ? <LgFanSquareIcon /> : <FanSquareIcon />}
          <TotalSlotsText large={isLarge}>
            <Text>{strings['dashboard.panel.slots']}: </Text>
            <Text>{totalSlots}</Text>
          </TotalSlotsText>
        </TotalSlotsView>
      </TouchableWithoutFeedback>
      <TouchableWithoutFeedback
        hitSlop={HIT_SLOP}
        onPress={debugEasterEgg.tapSecond}>
        <OnlineSlotsView large={isLarge}>
          <OnlineSlotsText large={isLarge}>
            <OnlineSlotsTitle>
              {strings['dashboard.panel.online']}:{' '}
            </OnlineSlotsTitle>
            <Text>
              <OnlineSlotsCurrent>{totalOnlineWorkers}</OnlineSlotsCurrent>
              <OnlineSlotsTotal> / {totalWorkers}</OnlineSlotsTotal>
            </Text>
          </OnlineSlotsText>
        </OnlineSlotsView>
      </TouchableWithoutFeedback>
      <TouchableWithoutFeedback
        hitSlop={HIT_SLOP}
        onPress={debugEasterEgg.tapThird}>
        <PoolMinersView>
          <PoolMinersText large={isLarge}>
            <PoolMinersTitle>
              {strings['dashboard.panel.poolMiners']}:{' '}
            </PoolMinersTitle>
            <PoolMinersCount>{totalPoolMinerWorkers}</PoolMinersCount>
          </PoolMinersText>
        </PoolMinersView>
      </TouchableWithoutFeedback>
    </SlotsInfo>
  );
});

const HIT_SLOP: Insets = {top: 5, bottom: 5, right: 5, left: 5};

const FanSquareIcon = sized(FanSquareLogo, 20);
const LgFanSquareIcon = sized(FanSquareLogo, 32);

const SlotsInfo = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    flex: 1,
    backgroundColor: theme.palette.background,
  },
}));

const TotalSlotsView = variance(View)(() => ({
  root: {
    marginRight: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  large: {
    marginRight: 40,
  },
}));

const OnlineSlotsView = variance(View)((theme) => ({
  root: {
    marginRight: 15,
    ...theme.mediaQuery({
      1000: {
        marginLeft: 0,
      },
    }),
  },
  large: {
    marginRight: 40,
  },
}));

const PoolMinersView = variance(View)(() => ({
  root: {},
}));

const TotalSlotsText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    marginLeft: 7,
    ...theme.mediaQuery({
      1000: {
        fontSize: 18,
        lineHeight: 24,
      },
    }),
  },
  large: {
    fontSize: 20,
    lineHeight: 24,
  },
}));

const OnlineSlotsText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    ...theme.mediaQuery({
      1000: {
        fontSize: 18,
        lineHeight: 24,
      },
    }),
  },
  large: {
    fontSize: 20,
    lineHeight: 24,
  },
}));

const OnlineSlotsTitle = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.success,
  },
}));

const OnlineSlotsCurrent = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textPrimary,
  },
}));

const OnlineSlotsTotal = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textSecondary,
  },
}));

const PoolMinersText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    ...theme.mediaQuery({
      1000: {
        fontSize: 18,
        lineHeight: 24,
      },
    }),
  },
  large: {
    fontSize: 20,
    lineHeight: 24,
  },
}));

const PoolMinersTitle = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.primary,
  },
}));

const PoolMinersCount = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('bold'),
    color: theme.palette.textPrimary,
  },
}));
