import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {WorkerStateRegistry} from '../../WorkerStateRegistry';
import getMiningSpeed from '../getters/getMiningSpeed';
import getWorkerName from '../getters/getWorkerName';
import isWorkerOffline from '../checkers/isWorkerOffline';

export const byAverageHashrate = (
  a: ReadonlyDeep<WorkerEntity>,
  b: ReadonlyDeep<WorkerEntity>,
  registry: WorkerStateRegistry,
) =>
  getMiningSpeed(
    b,
    registry.states.get(b.id)?.state,
    registry.states.get(b.id)?.miningSpeedAverage.current,
  ) -
  getMiningSpeed(
    a,
    registry.states.get(a.id)?.state,
    registry.states.get(a.id)?.miningSpeedAverage.current,
  );

export const byMinedTotal = (
  a: ReadonlyDeep<WorkerEntity>,
  b: ReadonlyDeep<WorkerEntity>,
  balance: (workerId: string) => number,
) => balance(b.id) - balance(a.id);

export const byName = (
  a: ReadonlyDeep<WorkerEntity>,
  b: ReadonlyDeep<WorkerEntity>,
) => getWorkerName(a).localeCompare(getWorkerName(b));

export const byStatus = (
  a: ReadonlyDeep<WorkerEntity>,
  b: ReadonlyDeep<WorkerEntity>,
  registry: WorkerStateRegistry,
) =>
  Number(!isWorkerOffline(registry.states.get(b.id)?.state)) -
  Number(!isWorkerOffline(registry.states.get(a.id)?.state));
