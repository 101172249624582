import {shareAsync} from 'expo-sharing';
import {
  cacheDirectory,
  EncodingType,
  writeAsStringAsync,
} from 'expo-file-system';
import {Sharing} from './Sharing';
import {Either, error, success} from '../fp';
import {SHARE_ERROR, ShareError} from '../Error';
import {ErrorRepository} from '../ErrorRepository';

export default class MobileSharingImpl implements Sharing {
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
    },
  ) {}

  async shareFile(
    fileName: string,
    content: string,
  ): Promise<Either<void, ShareError>> {
    try {
      const uri = cacheDirectory + `/${fileName}`;
      await writeAsStringAsync(uri, content, {encoding: EncodingType.UTF8});
      await shareAsync(uri);
      return success(undefined);
    } catch (err) {
      return error(
        this._root.errorRepository.create({
          kind: SHARE_ERROR,
          raw: err,
        }),
      );
    }
  }
}
