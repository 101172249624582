import {
  AnalyticsInstance,
  FirebaseAnalytics,
  LogEventParams,
} from './FirebaseAnalytics';
import analytics from '@react-native-firebase/analytics';

export default class FirebaseAnalyticsImpl implements FirebaseAnalytics {
  private readonly _analytics: AnalyticsInstance = analytics();

  logEvent({name, params}: LogEventParams) {
    return this._analytics.logEvent(name, params);
  }
}
