export default (
  value: number,
  length: number,
  zeroBar: number,
): [number, number, number[]] => {
  if (value > 0) {
    return [0, value * 2, new Array(length).fill(0.5, 0, length)];
  } else if (value < 0) {
    return [value * 2, 0, new Array(length).fill(0.5, 0, length)];
  }
  return [0, zeroBar, new Array(length).fill(0, 0, length)];
};
