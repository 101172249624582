import _ from 'lodash';
import {Matrix, PointHour} from './types';

export default (minuteList: number[]): Matrix => {
  const detailHourMatrix: number[][][] = _.chunk(_.chunk(minuteList, 60), 24);
  let count = 0;
  let result: Matrix = [];
  detailHourMatrix.forEach((detailDay) => {
    let day: PointHour[] = [];
    detailDay.forEach((detailHour) => {
      let hour: PointHour = [];
      detailHour.forEach((minute, i) => {
        const nextValue = detailHour[i + 1];
        if (nextValue === minute) {
          count++;
        } else {
          hour.push({length: count + 1, speed: minute});
          count = 0;
        }
      });
      day.push(hour);
    });
    result.push(day);
  });
  return result;
};
