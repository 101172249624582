import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../../../../styling';
import {View} from 'react-native';
import {BtcGraphRate} from './BtcGraphRate';
import BtcBalance from './BtcBalance';
import AddWorker from './AddWorker';

export const BALANCE_INFO_HEIGHT = 75;

export type BalanceInfoProps = {
  goToWithdraw: () => void;
  goToAddWorker: () => void;
};

export default observer(function BalanceInfo(props: BalanceInfoProps) {
  return (
    <Root>
      <BtcBalance {...props} />
      <BtcGraphRate />
      <AddWorker {...props} />
    </Root>
  );
});

const Root = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.border,
    zIndex: 2,
    height: 75,
  },
}));
