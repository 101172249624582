import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import {Divider} from '../components/Divider';
import {useStyles} from '../styling';
import {SecondaryMenuItem} from './SecondaryMenuItem';
import Switch from '../components/Switch';
import {useRoot, useStrings} from '../Root/hooks';
import {ThemeKind} from '../Appearance';

export default observer(() => {
  const {
    appearance: {isDark, setThemeMode},
  } = useRoot();
  const strings = useStrings();
  const toggle = useCallback(
    (value: boolean) =>
      value ? setThemeMode(ThemeKind.Dark) : setThemeMode(ThemeKind.Light),
    [setThemeMode],
  );
  const styles = useStyles(() => ({
    root: {},
    content: {
      flexShrink: 1,
      borderColor: 'red',
      paddingHorizontal: 20,
      paddingVertical: 13,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }));
  return (
    <View>
      <Divider />
      <View style={styles.content}>
        <SecondaryMenuItem disabled text={strings['menu.darkTheme']} />
        <Switch value={isDark} onValueChange={toggle} />
      </View>
    </View>
  );
});
