import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {ReadonlyDeep} from 'type-fest';
import {expr} from 'mobx-utils';
import isWorkerDisabled from '../../../checkers/isWorkerDisabled';
import {useWorkerState} from '../../../../WorkerStateRegistry';
import CpuTable from './CpuTable';
import ExtraParameters from './ExtraParameters';
import SlotBackground from '../../../common/SlotBackground';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import isWorkerWarning from '../../../checkers/isWorkerWarning';
import SpeedControl from './SpeedControl';
import {createStylesHook} from '../../../../styling';
import {WORKER_DESKTOP_HEIGHT, WORKER_HEIGHT} from '../../../constants';
import SlotHeader from '../../../common/SlotHeader';
import {View} from 'react-native';
import isWorkerOffline from '../../../checkers/isWorkerOffline';
import Characteristics from './Characteristics';
import {Mode} from '../../../../DashboardMode';
import {useNavigation} from '@react-navigation/native';
import {DashboardScreenProps} from '../../../DashboardScreenProps';
import {useForcedContext} from '../../../../context';
import {DashboardContext} from '../../../context';
import isNeedToReactivate from '../../../../PoolMinerActivator/isNeedToReactivate';
import {DESKTOP_BREAKPOINT} from '../../../../units/constants';

export interface WorkerSlotProps {
  worker: ReadonlyDeep<WorkerEntity>;
  index: number;
}

export default observer((props: WorkerSlotProps) => {
  const {goToActivator} = useForcedContext(DashboardContext);
  const navigation = useNavigation<DashboardScreenProps['navigation']>();
  const {worker, index} = props;
  const state = useWorkerState(worker.id);
  const disabled = expr(
    () => isWorkerDisabled(worker) || isWorkerOffline(state),
  );
  const goToDetails = useCallback(() => {
    navigation.navigate('WorkerDetails', {workerId: worker.id});
  }, [navigation, worker.id]);
  const warning = expr(() => isWorkerWarning(state));
  const styles = useStyles();
  const expired = expr(() => isNeedToReactivate(worker));
  const handleSlotPress = useCallback(() => {
    if (expired) {
      goToActivator();
    }
  }, [goToActivator, expired]);
  return (
    <SlotBackground
      isTouchable={expired}
      onPress={handleSlotPress}
      disabled={disabled}
      warning={warning}
      containerStyle={styles.root}>
      <SlotHeader variant={Mode.Regular} worker={worker} index={index} />
      <View style={styles.content}>
        <Characteristics
          worker={worker}
          disabled={disabled}
          warning={warning}
        />
        <SpeedControl worker={worker} onExpiredPress={goToDetails} />
        <CpuTable worker={worker} warning={warning} />
        <ExtraParameters worker={worker} />
      </View>
    </SlotBackground>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    height: WORKER_HEIGHT,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        height: WORKER_DESKTOP_HEIGHT,
      },
    }),
  },
  content: {
    flex: 1,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        flexDirection: 'row',
      },
    }),
  },
}));
