import {DASHBOARD_SORT, define} from '../../persistence';
import {FarmId} from '../../ApiStore';
import {DashboardParams} from '../../Navigation';

type ParamsRecord = {
  farmId?: FarmId;
  dashboard: DashboardParams;
};

const [getGroupSortingParams, setSortingParams] =
  define<ParamsRecord>(DASHBOARD_SORT);

export {getGroupSortingParams, setSortingParams};
