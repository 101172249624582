import React from 'react';
import {observer} from 'mobx-react-lite';
import {Platform, View, ViewProps} from 'react-native';
import Top from './Top';
import Bottom from './Bottom';
import Content from './Content';

import {createStylesHook, useTheme} from '../styling';
import {DESKTOP_WEB_APP_FOOTER_HEIGHT} from '../DashboardScreen/constants';

export default observer(({style, ...rest}: ViewProps) => {
  const theme = useTheme();
  const styles = useStyles();
  if (Platform.OS !== 'web' || theme.window.width < 1281) {
    return null;
  }
  return (
    <View style={[styles.root, style]} {...rest}>
      <Top />
      <Content />
      <Bottom />
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    height: DESKTOP_WEB_APP_FOOTER_HEIGHT,
    borderTopWidth: 8,
    borderTopColor: theme.palette.primary,
    backgroundColor: theme.select(
      theme.contrast(theme.palette.background),
      theme.palette.background,
    ),
  },
}));
