import {success, error, Either} from '../fp';
import {action, flow, observable, when, makeObservable} from 'mobx';
import {JsonSerializable} from '../Json';
import {
  isSuccess,
  JsonRpcError,
  JsonRpcId,
  Response,
  Request,
  isResponse,
  isError,
} from './Protocol';
import delayResolve from '../utils/delayResolve';
import {StructuredTunnel} from './StructuredTunnel';
import {CallScheme, NotificationScheme} from './Scheme';
import {ApplyOptions, CallParameters, Client, NotifyParameters} from './Client';
import {DefaultError} from './DefaultError';
import {Millisecond} from '../Time';
import {BusSource, BusHelper, BusHelperImpl} from '../structure';

export default class JsonRpcClient<
  C extends CallScheme = CallScheme,
  N extends NotificationScheme = NotificationScheme,
> implements Client<C, N>
{
  private readonly _pendingRequestIds = new Set<JsonRpcId>();
  private readonly _responsesById = observable.map<JsonRpcId, Response>(
    undefined,
    {proxy: false, deep: false},
  );
  private readonly _connectionHelper: BusHelper<unknown>;

  constructor(
    private readonly _tunnel: StructuredTunnel,
    private readonly _ids: Generator<JsonRpcId, unknown>,
    private readonly _timeout: Millisecond,
    private readonly _connection: BusSource<unknown>,
  ) {
    makeObservable(this);
    this._connectionHelper = new BusHelperImpl(_connection);
  }

  async notify<K extends keyof N>(
    ...args: NotifyParameters<K, Parameters<N[K]>>
  ) {
    const [method, params] = args;
    try {
      await this._send(String(method), params);
    } catch (ignore) {}
  }

  private async _query(
    id: JsonRpcId,
    timeout?: Millisecond,
  ): Promise<Either<JsonSerializable, JsonRpcError>> {
    const promises: Promise<Response>[] = [
      this._receive(id),
      this._connectionHelper.when().then(() => ({
        jsonrpc: '2.0',
        id,
        error: {
          code: -32095,
          message: 'Connection aborted',
        },
      })),
    ];
    if (timeout !== undefined && isFinite(timeout)) {
      promises.push(
        delayResolve(timeout, () => ({
          jsonrpc: '2.0',
          id,
          error: {
            code: -32099,
            message: 'Response timeout',
          },
        })),
      );
    }
    const response = await Promise.race(promises);
    if (isSuccess(response)) {
      return success(response.result);
    } else if (isError(response)) {
      return error(response.error);
    } else {
      return error({
        code: -32098,
        message: 'Response parse error',
      });
    }
  }

  async apply<K extends keyof C>(
    method: K,
    params: Parameters<C[K]>[0],
    options?: ApplyOptions,
  ): Promise<ReturnType<C[K]> | Either<never, DefaultError>> {
    const id = options?.id ?? this._nextId();
    try {
      await this._send(String(method), params, id);
    } catch (raw) {
      return error(transportError(raw)) as ReturnType<C[K]>;
    }
    return (await this._query(
      id,
      options?.timeout ?? this._timeout,
    )) as ReturnType<C[K]>;
  }

  /**
   * Call the server method and wait for the result
   * @throws {never}
   */
  async call<K extends keyof C>(
    ...args: CallParameters<K, Parameters<C[K]>>
  ): Promise<ReturnType<C[K]> | Either<never, DefaultError>> {
    const [method, params, id] = args;
    return this.apply(method, params, {timeout: this._timeout, id});
  }

  @action private _nextId() {
    const next = this._ids.next();
    if (next.done) {
      console.warn('The id generator has no values left');
      return Math.random() * Number.MAX_SAFE_INTEGER;
    }
    return next.value;
  }

  private async _send(
    this: JsonRpcClient<C, N>,
    method: string,
    params?: unknown,
    id?: JsonRpcId,
  ) {
    if (id !== undefined) {
      this._pendingRequestIds.add(id);
    }
    const request: Request = {
      jsonrpc: '2.0',
      method,
      params: params as JsonSerializable,
      id,
    };
    return this._tunnel.send(request);
  }

  private _receive = flow(function* (this: JsonRpcClient<C, N>, id: JsonRpcId) {
    yield when(() => this._responsesById.has(id));
    const response = this._responsesById.get(id)!;
    this._responsesById.delete(id);
    return response;
  });

  @action private _onResponse(response: Response) {
    if (this._pendingRequestIds.has(response.id)) {
      this._pendingRequestIds.delete(response.id);
      this._responsesById.set(response.id, response);
    }
  }

  private _onMessage = (raw: unknown) => {
    if (Array.isArray(raw)) {
      for (const item of raw) {
        if (isResponse(item)) {
          this._onResponse(item);
        }
      }
    } else if (isResponse(raw)) {
      this._onResponse(raw);
    }
  };

  subscribe() {
    return this._tunnel.listen(this._onMessage);
  }
}

const transportError = (raw: unknown): JsonRpcError => ({
  code: -32097,
  message: 'Transport error',
  data: String(raw),
});
