import React, {useEffect} from 'react';
import {View, Text} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useRoot, useStrings} from '../Root/hooks';
import CheckboxTreeState from './CheckboxTreeState';
import ShowSection from './ShowSection';
import StatusSection from './StatusSection';
import PurchaseSection from './PurchaseSection';
import WithdrawalSection from './WithdrawalSection';

import {PageScrollView} from '../containers';
import PageHeader from '../components/PageHeader';
import {CryptoLoader} from '../components/CryptoLoader';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';
import {createStylesHook} from '../styling';

export interface CommonSectionProps {
  state: CheckboxTreeState['state'];
  onCheck: CheckboxTreeState['onCheck'];
}

export default observer(() => {
  const strings = useStrings();
  const {notificationSettingsStore} = useRoot();

  const styles = useStyles();

  const {state, tree, fetchLogSettings, isLoading, isLoadedIn} =
    notificationSettingsStore;

  useEffect(() => {
    fetchLogSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={styles.root}>
      <DesktopWebAppHeader />
      <PageHeader
        style={styles.pageHeader}
        title={strings['screenTitle.notificationsSettings']}
      />
      {isLoading ? (
        <View style={styles.loaderView}>
          <CryptoLoader />
        </View>
      ) : null}
      {!isLoading && isLoadedIn ? (
        <PageScrollView contentContainerStyle={styles.container}>
          <View style={styles.descView}>
            <Text style={styles.descText}>
              {strings['notificationsSettings.chooseHelper']}
            </Text>
          </View>
          <View>
            <ShowSection state={state} onCheck={tree.onCheck} />
          </View>
          <View style={styles.content}>
            <View style={[styles.column, styles.leftColumn]}>
              <StatusSection state={state} onCheck={tree.onCheck} />
            </View>
            <View style={[styles.column, styles.rightColumn]}>
              <PurchaseSection state={state} onCheck={tree.onCheck} />
              <WithdrawalSection state={state} onCheck={tree.onCheck} />
            </View>
          </View>
        </PageScrollView>
      ) : null}
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    flex: 1,
  },
  loaderView: {
    padding: 30,
    alignItems: 'center',
  },
  container: {
    paddingVertical: 20,
  },
  content: {
    ...theme.mediaQuery({
      1281: {
        flexDirection: 'row',
      },
    }),
  },
  column: {
    ...theme.mediaQuery({
      1281: {
        flexGrow: 1,
      },
    }),
  },
  leftColumn: {
    ...theme.mediaQuery({
      1281: {
        paddingRight: 10,
        width: '50%',
      },
    }),
  },
  rightColumn: {
    ...theme.mediaQuery({
      1281: {
        paddingLeft: 10,
        width: '50%',
      },
    }),
  },
  pageHeader: {
    marginTop: 20,
  },
  descView: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    marginBottom: 20,
    borderWidth: 2,
    borderColor: theme.palette.primary,
    marginHorizontal: 15,
    backgroundColor: theme.chroma(theme.palette.primary).alpha(0.03).hex(),
    borderRadius: 4,
  },
  descText: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 22,
    letterSpacing: 0.037,
  },
}));
