import {VideoTutorialState} from './VideoTutorialState';
import {TutorialStorage} from '../TutorialManager';
import {bind} from '../fp';

export default class VideoTutorialStateImpl implements VideoTutorialState {
  constructor(
    private readonly _root: {
      readonly tutorialManagerStorage: TutorialStorage;
    },
  ) {}

  complete = bind(() => {
    this._root.tutorialManagerStorage.markDone();
  }, this);
}
