import React, {useCallback, useEffect} from 'react';
import {Keyboard} from 'react-native';
import {ButtonColor, ButtonVariant} from '../components';
import {useRoot, useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import ActionModal from '../modals/ActionModal';
import WarningSvg from '../assets/svg/circled/warning.svg';

export type NotAllowedModalProps = {
  description: string | undefined;
  onClose: () => void;
};

export default observer(({description, onClose}: NotAllowedModalProps) => {
  const {navigationContainer} = useRoot();
  const strings = useStrings();
  useEffect(() => {
    Keyboard.dismiss();
  }, []);
  const onSubmit = useCallback(() => {
    onClose();
    navigationContainer.ref?.navigate('Plan');
  }, [navigationContainer, onClose]);

  return (
    <ActionModal
      Icon={WarningSvg}
      title={strings['modal.notAllowed.title']}
      buttons={[
        {
          text: strings['action.ok'],
          onPress: onClose,
          color: ButtonColor.Default,
          variant: ButtonVariant.Contained,
        },
        {
          text: strings['common.purchase'],
          onPress: onSubmit,
          color: ButtonColor.Success,
          variant: ButtonVariant.Contained,
        },
      ]}
      onDismiss={onClose}>
      {description}
    </ActionModal>
  );
});
