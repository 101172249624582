import React from 'react';
import {observer} from 'mobx-react-lite';
import ActionModal from '../../../modals/ActionModal';
import {
  ButtonColor,
  ButtonVariant,
  CompositeFanIcon,
} from '../../../components';
import {useStrings} from '../../../Root/hooks';
import {useTheme} from '../../../styling';
import {sized} from '../../../Svg';
import ComputerSvg from '../../../assets/svg/colorless/computer.svg';

export type NeedMoreSlotsModalProps = {
  onDismiss: () => void;
  onSubmit: () => void;
};

export default observer(function NeedMoreSlotsModal({
  onDismiss,
  onSubmit,
}: NeedMoreSlotsModalProps) {
  const strings = useStrings();
  return (
    <ActionModal
      buttons={[
        {
          text: strings['action.cancel'],
          onPress: onDismiss,
          color: ButtonColor.Primary,
        },
        {
          text: strings['action.addMoreSlots'],
          onPress: onSubmit,
          variant: ButtonVariant.Highlighted,
        },
      ]}
      Icon={Icon}
      title={strings['modal.needMoreSlots.title']}
      buttonsDirection="column"
      onDismiss={onDismiss}>
      {strings['modal.needMoreSlots.desc']}
    </ActionModal>
  );
});

const ComputerIcon = sized(ComputerSvg, 30);

const Icon = observer(() => {
  const theme = useTheme();
  return (
    <CompositeFanIcon Icon={ComputerIcon} circleColor={theme.palette.success} />
  );
});
