import {showMessage} from 'react-native-flash-message';
import {useRoot, useStrings} from '../Root/hooks';
import {useCallback} from 'react';
import {useHandleErrorAndNavigate} from '../ExceptionHandler';

export default function useDownloadMinerLink() {
  const {
    connectedClient,
    clipboard,
    configuration: {values},
  } = useRoot();
  const strings = useStrings();
  const errorHandler = useHandleErrorAndNavigate();
  const copyLink = useCallback(async () => {
    const result = await connectedClient.call('new_worker', {
      configuration: undefined,
    });
    if (!result.success) {
      errorHandler(result.left);
      return;
    }
    const {worker_connection_id} = result.right;
    clipboard.setString(
      values.downloadFarmMinerUrl.replace('{id}', worker_connection_id),
    );
    showMessage({
      message: strings['action.theValueHasBeenCopiedToClipboard'],
      duration: 3000,
    });
  }, [
    clipboard,
    connectedClient,
    errorHandler,
    strings,
    values.downloadFarmMinerUrl,
  ]);
  return {copyLink};
}
