import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {useWorkerState} from '../../../WorkerStateRegistry';
import {expr} from 'mobx-utils';
import isWorkerDisabled from '../../checkers/isWorkerDisabled';
import {useRoot} from '../../../Root/hooks';
import WorkerSpeedControlView from './WorkerSpeedControlView';
import isWorkerOffline from '../../checkers/isWorkerOffline';
import {SlotMode} from '../../types';
import {useHandleErrorAndNavigate} from '../../../ExceptionHandler';

export interface WorkerSpeedControlProps {
  slotMode: SlotMode;
  worker: ReadonlyDeep<WorkerEntity>;
  onExpiredPress?: () => void;
  hideTitle?: boolean;
  disabled?: boolean;
}

export default observer(
  ({
    worker,
    slotMode,
    onExpiredPress,
    hideTitle,
    disabled,
  }: WorkerSpeedControlProps) => {
    const state = useWorkerState(worker.id);
    const {id} = worker;
    const {connectedClient, currentSubscriptionState, dashboardStore} =
      useRoot();
    const handleErrorAndNavigate = useHandleErrorAndNavigate();
    const {isPlatformSupported} = currentSubscriptionState;
    const workerDisabled = expr(
      () => isWorkerDisabled(worker) || isWorkerOffline(state),
    );
    const locked = workerDisabled || !isPlatformSupported;
    const onChange = useCallback(
      async (speed: number) => {
        const result = await connectedClient.call('change_settings', {
          worker_id: id,
          settings: {speed},
        });
        if (!result.success) {
          handleErrorAndNavigate(result.left);
        }
      },
      [connectedClient, handleErrorAndNavigate, id],
    );
    const setMin = useCallback(() => onChange(0), [onChange]);
    const setMax = useCallback(() => onChange(100), [onChange]);
    const readonly = expr(() => !dashboardStore.checkAvailableSpeedChange(id));
    const onSlidingStart = useCallback(() => {
      dashboardStore.checkAvailableSpeedChangeAndShowModal(id);
    }, [dashboardStore, id]);
    const sliderValue = expr(
      () => state?.current_settings.speed ?? worker.default_settings.speed,
    );
    return (
      <WorkerSpeedControlView
        worker={worker}
        threads={worker.mining_options?.optimal_threads}
        locked={locked}
        readonly={readonly}
        onSlidingStart={onSlidingStart}
        managed={worker.managed}
        setMin={setMin}
        setMax={setMax}
        onChange={onChange}
        slotMode={slotMode}
        sliderValue={sliderValue}
        onExpiredPress={onExpiredPress}
        hideTitle={hideTitle}
        disabled={disabled}
      />
    );
  },
);
