import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../../Root/hooks';
import {sized} from '../../Svg';
import CompositeFanSvg from '../../assets/svg/compositeFan.svg';
import CompositeFanDarkSvg from '../../assets/svg/compositeFanDark.svg';
import {SvgProps} from 'react-native-svg';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';
import {useTheme} from '../../styling';

export interface CompositeFanIcon {
  Icon?: React.ComponentType<SvgProps>;
  containerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
  circleColor?: string;
}

const WIDTH = 78;
const HEIGHT = 50;

export default observer(
  ({containerStyle, Icon, circleColor, style: _style}: CompositeFanIcon) => {
    const {appearance} = useRoot();
    const theme = useTheme();
    const Component = appearance.isDark
      ? CompositeFanDarkIcon
      : CompositeFanIcon;
    const style = useMemo(
      () => StyleSheet.flatten([{width: WIDTH, height: HEIGHT}, _style]),
      [_style],
    );
    const circleColor_ = circleColor ?? theme.palette.primary;
    return (
      <View style={[styles.root, containerStyle]}>
        <Component color={circleColor_} style={style} />
        {Icon && (
          <View
            style={[
              {
                width: style.height,
                height: style.height,
              },
              styles.composite,
            ]}>
            <Icon
              color={theme.select(
                theme.contrast(theme.palette.icon),
                theme.palette.icon,
              )}
            />
          </View>
        )}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  root: {},
  composite: {
    position: 'absolute',
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const CompositeFanIcon = sized(CompositeFanSvg, WIDTH, HEIGHT);
const CompositeFanDarkIcon = sized(CompositeFanDarkSvg, WIDTH, HEIGHT);
