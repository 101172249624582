import {action, isObservableArray, makeObservable} from 'mobx';
import {
  ApiStore,
  GroupsInfoParams,
  WorkerId,
  WorkerUpdate,
  WorkerUpdateParams,
} from '../ApiStore';
import {ReadonlyDeep} from 'type-fest';
import {Either, success} from '../fp';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import {batchDisposers, Service} from '../structure';
import {Translation} from '../Localization';
import {WorkerGroupEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerGroupEntity';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {omit} from 'lodash';
import {SchedulerEntity} from '../../universal/features/api/entity/schedule/SchedulerEntity';
import {Auth} from '../Auth';

export default class WorkerUpdateRegistryService implements Service {
  constructor(
    private readonly _root: {
      readonly dashboardStore: DashboardStore;
      readonly apiStore: ApiStore;
      readonly translation: Translation;
      readonly auth: Auth;
    },
  ) {
    makeObservable(this);
  }

  private _createLocalGroup(
    groupId: number,
    accountId: number,
    workers: WorkerEntity[],
  ): Either<WorkerGroupEntity, void> {
    const name = this._root.translation.strings['workerGroup.name.default'];
    const group: WorkerGroupEntity = {
      id: groupId,
      name,
      account_id: accountId,
      workers,
    };
    return success(group);
  }

  private static _createWorkerFromUpdate(update: ReadonlyDeep<WorkerUpdate>) {
    return omit(update, ['deleted']) as WorkerEntity;
  }

  private _addGroupToTheStore(group: WorkerGroupEntity) {
    this._root.dashboardStore.dashboardRequest.update((_) =>
      _?.groups ? {..._, groups: [..._.groups, group]} : _,
    );
  }

  private _addWorkerToTheStore(worker: WorkerEntity) {
    this._root.dashboardStore.dashboardRequest.update((_) =>
      _?.groups
        ? {
            ..._,
            groups: _?.groups.map((group) =>
              group.id === worker.group_id
                ? {
                    ...group,
                    workers: [worker, ...group.workers],
                  }
                : group,
            ),
          }
        : undefined,
    );
  }

  private _removeWorkerFromTheStore(groupId: number, workerId: WorkerId) {
    this._root.dashboardStore.dashboardRequest.update((_) =>
      _?.groups
        ? {
            ..._,
            groups: _?.groups.map((group) =>
              group.id === groupId
                ? {
                    ...group,
                    workers: group.workers.filter(
                      (worker) => worker.id !== workerId,
                    ),
                  }
                : group,
            ),
          }
        : _,
    );
  }

  private static _assign<T extends object | null>(target: T, source: T): T {
    if (target === null || source === null) {
      return source;
    } else {
      return Object.assign(target, source);
    }
  }

  private static _updateWorkerAtTheStore(
    worker: WorkerEntity,
    update: ReadonlyDeep<WorkerUpdate>,
  ) {
    worker.name = update.name;
    worker.managed = update.managed;
    worker.timezone = update.timezone;
    worker.utc_offset = update.utc_offset;
    worker.slot_status = update.slot_status;
    worker.pool_miner_is_active = update.pool_miner_is_active;
    worker.versions = WorkerUpdateRegistryService._assign(
      worker.versions,
      update.versions,
    );
    if (worker.host_info === null || update.host_info === null) {
      worker.host_info = update.host_info;
    } else {
      Object.assign(
        worker.host_info,
        omit(update.host_info, ['memory', 'cpu']),
      );
      Object.assign(worker.host_info.memory, update.host_info.memory);
      Object.assign(worker.host_info.cpu, update.host_info.cpu);
    }
    worker.mining_options = WorkerUpdateRegistryService._assign(
      worker.mining_options,
      update.mining_options,
    );
    if (worker.schedulers === null || update.schedulers === null) {
      worker.schedulers = update.schedulers as SchedulerEntity[];
    } else {
      if (isObservableArray(worker.schedulers)) {
        worker.schedulers.replace(update.schedulers as SchedulerEntity[]);
      } else {
        worker.schedulers =
          update.schedulers?.map((_) => ({..._, settings: {..._.settings}})) ??
          null;
      }
    }
  }

  @action private _updateWorker(update: ReadonlyDeep<WorkerUpdateParams>) {
    const worker = this._root.dashboardStore.workers.get(update.id);
    if (update.deleted) {
      if (!worker) {
        return;
      }
      this._removeWorkerFromTheStore(worker.group_id, worker.id);
    } else {
      const groupIsPresent = this._root.dashboardStore.groups.has(
        update.group_id,
      );
      if (!groupIsPresent) {
        const newWorker =
          WorkerUpdateRegistryService._createWorkerFromUpdate(update);
        const local = this._createLocalGroup(
          update.group_id,
          update.account_id,
          [newWorker],
        );
        if (!local.success) {
          return;
        }
        const group = local.right;
        if (worker) {
          this._removeWorkerFromTheStore(worker.group_id, worker.id);
        }
        this._addGroupToTheStore(group);
      } else {
        if (worker) {
          if (update.group_id !== worker.group_id) {
            this._removeWorkerFromTheStore(worker.group_id, worker.id);
            const newWorker =
              WorkerUpdateRegistryService._createWorkerFromUpdate(update);
            this._addWorkerToTheStore(newWorker);
          } else {
            WorkerUpdateRegistryService._updateWorkerAtTheStore(worker, update);
          }
        } else {
          const newWorker =
            WorkerUpdateRegistryService._createWorkerFromUpdate(update);
          this._addWorkerToTheStore(newWorker);
        }
      }
    }
  }

  @action private _updateGroups(params: ReadonlyDeep<GroupsInfoParams>) {
    const {groups} = this._root.dashboardStore;
    const updateIds = new Set<number>();
    for (const update of params) {
      updateIds.add(update.id);
      const group = groups.get(update.id);
      if (group) {
        group.name = update.name;
      } else {
        const accountId = this._root.auth.accountId;
        if (!accountId) {
          return;
        }
        this._addGroupToTheStore({
          id: update.id,
          name: update.name,
          account_id: accountId,
          workers: [],
        });
      }
    }
    this._root.dashboardStore.dashboardRequest.update((_) =>
      _?.groups
        ? {..._, groups: _?.groups.filter((group) => updateIds.has(group.id))}
        : _,
    );
  }

  private _listenToWorkerUpdates() {
    return this._root.apiStore.server.call(
      'worker_update',
      async (params, response) => {
        this._updateWorker(params);
        return response.respond(success(null));
      },
    );
  }

  private _listenToGroupUpdates() {
    return this._root.apiStore.server.call(
      'groups_info',
      async (params, response) => {
        this._updateGroups(params);
        return response.respond(success(null));
      },
    );
  }

  subscribe() {
    return batchDisposers(
      this._listenToWorkerUpdates(),
      this._listenToGroupUpdates(),
    );
  }
}
