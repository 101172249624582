import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {View, Text} from 'react-native';
import {defaultContainerStyle} from '../components/Grid';
import {useRoot, useStrings} from '../Root/hooks';
import {createStylesHook, useTheme} from '../styling';
import negateColor from '../styling/util/negateColor';
import Switch from '../components/Switch';
import {ThemeKind} from '../Appearance';
import MoonSvg from '../assets/svg/colorless/moon.svg';
import SunSvg from '../assets/svg/colorless/sun.svg';
import {sized} from '../Svg';
import {DropdownLanguage} from '../MenuScreen/DropdownLanguage';

export default observer(() => {
  const styles = useStyles();
  const strings = useStrings();
  const theme = useTheme();
  const {
    appearance: {isDark, setThemeMode},
  } = useRoot();
  const year = new Date().getFullYear();
  const toggle = useCallback(
    (value: boolean) =>
      value ? setThemeMode(ThemeKind.Dark) : setThemeMode(ThemeKind.Light),
    [setThemeMode],
  );
  const renderIcon = () =>
    isDark ? (
      <MoonIcon color={'#ffffff'} />
    ) : (
      <SunIcon color={theme.palette.primary} />
    );
  const backgroundActive = '#494949';
  const backgroundInactive = theme.palette.primary;
  const circleInActiveColor = '#ffffff';
  const circleActiveColor = theme.palette.primary;

  return (
    <View style={styles.root}>
      <View style={[styles.content, defaultContainerStyle]}>
        <Text style={styles.copyright}>
          © {year}. {strings['footer.allRightsReserved']} CryptoCompany OÜ,
          Rebase tn 1, Tartu 50104, Estonia
        </Text>
        <View style={styles.right}>
          <Switch
            value={isDark}
            onValueChange={toggle}
            backgroundActive={backgroundActive}
            backgroundInactive={backgroundInactive}
            circleInActiveColor={circleInActiveColor}
            circleActiveColor={circleActiveColor}
            renderInsideCircle={renderIcon}
          />
          <DropdownLanguage hideLabel />
        </View>
      </View>
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    paddingVertical: 15,
    borderTopWidth: 1,
    borderTopColor: '#212121',
  },
  content: {
    paddingVertical: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  copyright: {
    color: theme.select(
      negateColor(theme.palette.textSecondary),
      theme.palette.textSecondary,
    ),
  },
  right: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
}));

const MoonIcon = sized(MoonSvg, 16);
const SunIcon = sized(SunSvg, 16);
