import {
  InAppPurchaseManager,
  ProductStorePurchase,
  Purchase,
  SubscriptionStorePurchase,
} from './InAppPurchaseManager';
import {PurchaseId} from '../units';
import {BaseInAppPurchaseManagerImpl} from './BaseInAppPurchaseManagerImpl';
import {ApiStore, GooglePlayPurchase, RestoreResult} from '../ApiStore';
import {Either, error, success} from '../fp';
import {Configuration} from '../Configuration';
import {Op} from '../Math';
import {InAppPurchase} from '../InAppPurchase';
import {ErrorRepository} from '../ErrorRepository';
import {ConnectedClient} from '../ContextClient';
import {Auth} from '../Auth';
import {PurchaseDiscount} from '../PurchasePromoService';
import {GlobalError, SERVER_ERROR} from '../Error';
import {
  Product,
  SubscriptionAndroid,
  SubscriptionPlatform,
} from 'react-native-iap';
import parseMicros from './parseMicros';
import normalizeSubscription from './normalizeSubscription';
import parsePeriod from './parsePeriod';

type Sub = SubscriptionAndroid;

export default class AndroidInAppPurchaseManagerImpl
  extends BaseInAppPurchaseManagerImpl
  implements InAppPurchaseManager
{
  constructor(
    protected readonly _root: {
      readonly auth: Auth;
      readonly apiStore: ApiStore;
      readonly connectedClient: ConnectedClient;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly configuration: Configuration;
      readonly inAppPurchase: InAppPurchase;
      readonly errorRepository: ErrorRepository;
    },
  ) {
    super(_root);
  }

  async getAvailablePurchases(): Promise<Either<Purchase[], GlobalError>> {
    try {
      const purchases = await this._root.inAppPurchase.getAvailablePurchases();
      return success(
        purchases.map((_) => ({purchaseId: _.productId as PurchaseId})),
      );
    } catch (raw) {
      return error(this.createIapError(raw));
    }
  }

  private _getPurchaseTags() {
    if (this._root.auth.state?.kind === 'Connected') {
      return this._root.auth.state.purchaseTags;
    }
    return [];
  }

  private _getSubscriptionDetails(subscription: Sub) {
    const normalized = normalizeSubscription(
      subscription,
      this._getPurchaseTags(),
    );
    if (!normalized) {
      return null;
    }
    return {
      trial: normalized.trial,
      token: normalized.token,
      price: normalized.offerPrice,
      currency: normalized.currency,
    };
  }

  async getSubscriptions(
    purchaseIds: PurchaseId[],
  ): Promise<Either<SubscriptionStorePurchase[], GlobalError>> {
    try {
      const subs = await this._root.inAppPurchase.getSubscriptions(purchaseIds);
      return success(
        subs.flatMap((_) => {
          if (_.platform !== SubscriptionPlatform.android) {
            return [];
          }
          const details = this._getSubscriptionDetails(_);
          if (!details) {
            return [];
          }
          if (!details.price || !details.token || !details.currency) {
            return [];
          }
          return [
            {
              purchaseId: _.productId as PurchaseId,
              price: details.price,
              currency: details.currency,
              period: parsePeriod(_),
              trial: details.trial,
              subscriptionOfferTokenAndroid: details.token,
            },
          ];
        }),
      );
    } catch (raw) {
      return error(this.createIapError(raw));
    }
  }

  private _getProductPrice(_: Product) {
    const micros = _.oneTimePurchaseOfferDetails?.priceAmountMicros;
    if (!micros) {
      return 0;
    }
    return parseMicros(micros);
  }

  async getProducts(
    purchaseIds: PurchaseId[],
  ): Promise<Either<ProductStorePurchase[], GlobalError>> {
    try {
      const products = await this._root.inAppPurchase.getProducts(purchaseIds);
      return success(
        products.map((_) => ({
          purchaseId: _.productId as PurchaseId,
          price: this._getProductPrice(_),
          currency: _.currency,
          title: _.title,
          description: _.description,
        })),
      );
    } catch (raw) {
      return error(this.createIapError(raw));
    }
  }

  async authByPurchaseHistory(): Promise<Either<RestoreResult[], GlobalError>> {
    try {
      const history = await this._root.inAppPurchase.getPurchaseHistory();
      const purchases = history.flatMap<GooglePlayPurchase>((_) =>
        _.signatureAndroid
          ? [
              {
                data: _.transactionReceipt,
                sign: _.signatureAndroid,
              },
            ]
          : [],
      );
      if (purchases.length > 0) {
        const timeout = Op.multiply(
          this._root.configuration.values.bffTimeout,
          2,
        );
        const response = await this._root.apiStore.client.apply(
          'auth_by_google_play_purchases',
          {purchases},
          {timeout},
        );
        if (!response.success) {
          return error(
            this._root.errorRepository.create({
              kind: SERVER_ERROR,
              raw: response.left,
            }),
          );
        }
        return response;
      }
      return success([]);
    } catch (raw) {
      return error(this.createIapError(raw));
    }
  }
}
