import {Animated} from 'react-native';
import {Position, SliderStatus} from './PoolMinerActivator';

export interface PuzzleState {
  translationX: Animated.Value;
  readonly backgroundImageIndex: number;
  readonly targetPosition: Position | undefined;
  readonly sliderStatus: SliderStatus;
  readonly puzzleWidth: number;
  readonly puzzleHeight: number;
  refresh: () => void;
  changeStateStatus: (newStateStatus: SliderStatus) => void;
  verifyPosition: (candidatePosition: Position) => void;
}
export const MAX_MODAL_WIDTH = 500;
export const DEFAULT_MODAL_OFFSET = 15;
export const MODAL_INSET = 15;
export const PUZZLE_HEIGHT_RATIO = 0.55;
export const PUZZLE_SIZE = {
  width: 65,
  height: 65,
};
export const PERMISSIBLE_DEVIATION = 10;

export const IMAGES = [
  require('./images/01.jpg'),
  require('./images/02.jpg'),
  require('./images/03.jpg'),
  require('./images/04.jpg'),
  require('./images/05.jpg'),
  require('./images/06.jpg'),
  require('./images/07.jpg'),
  require('./images/08.jpg'),
  require('./images/09.jpg'),
  require('./images/10.jpg'),
  require('./images/11.jpg'),
  require('./images/12.jpg'),
  require('./images/13.jpg'),
  require('./images/14.jpg'),
  require('./images/15.jpg'),
  require('./images/16.jpg'),
  require('./images/17.jpg'),
  require('./images/18.jpg'),
  require('./images/19.jpg'),
  require('./images/20.jpg'),
];
