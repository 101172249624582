import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {AddWorkerScreen} from '../../AddWorkerScreen';
import {RootStackBindingProps} from './RootStackBindingProps';
import {CommonActions} from '@react-navigation/native';
import {DASHBOARD} from './constants';
import {useRoot} from '../../Root/hooks';

export interface AddWorkerScreenBindingProps
  extends RootStackBindingProps<'AddWorker'> {}

export default observer(({navigation}: AddWorkerScreenBindingProps) => {
  const {quickStartOffer} = useRoot();
  const goToAddPoolMiner = useCallback(
    () =>
      quickStartOffer.shouldAddMinerRedirectToQuickStart
        ? navigation.navigate('QuickStart')
        : navigation.navigate('Plan', {index: 1}),
    [navigation, quickStartOffer],
  );
  const goToQRCodeScanner = useCallback(
    () => navigation.navigate('QRCodeScanner'),
    [navigation],
  );

  const goToDashboard = useCallback(() => {
    navigation.dispatch(CommonActions.reset(DASHBOARD));
  }, [navigation]);
  const goToProducts = useCallback(() => {
    navigation.navigate('Products');
  }, [navigation]);
  return (
    <AddWorkerScreen
      goToAddPoolMiner={goToAddPoolMiner}
      goToQRCodeScanner={goToQRCodeScanner}
      goToDashboard={goToDashboard}
      goToProducts={goToProducts}
    />
  );
});
