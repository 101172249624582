import React from 'react';
import {observer} from 'mobx-react-lite';
import {Text} from 'react-native';
import {variance} from '../../../../styling';

export interface WorkerTemperatureProps {
  value: string;
  disabled: boolean;
  warning: boolean;
}

export default observer(
  ({value, disabled, warning}: WorkerTemperatureProps) => {
    return (
      <Temperature warning={warning} disabled={disabled}>
        {value} °C
      </Temperature>
    );
  },
);

const Temperature = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    lineHeight: 18,
    color: theme.palette.textPrimary,
  },
  warning: {
    color: theme.palette.error,
  },
  disabled: {
    color: theme.palette.disabled,
  },
}));
