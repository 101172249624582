import {Platform} from 'react-native';
import IosPendingPurchasesResolverImpl from './IosPendingPurchasesResolverImpl';
import AndroidPendingPurchasesResolverImpl from './AndroidPendingPurchasesResolverImpl';
import {ApiStore} from '../ApiStore';
import {Configuration} from '../Configuration';
import {InAppPurchase} from '../InAppPurchase';
import {ErrorRepository} from '../ErrorRepository';
import {InAppPurchaseManager} from '../InAppPurchaseManager';
import SubPendingPurchasesResolverImpl from './SubPendingPurchasesResolverImpl';

export default class PendingPurchasesResolverFactory {
  constructor(
    private readonly _root: {
      readonly apiStore: ApiStore;
      readonly configuration: Configuration;
      readonly inAppPurchase: InAppPurchase;
      readonly errorRepository: ErrorRepository;
      readonly inAppPurchaseManager: InAppPurchaseManager;
    },
  ) {}

  create() {
    return Platform.select({
      ios: new IosPendingPurchasesResolverImpl(this._root),
      android: new AndroidPendingPurchasesResolverImpl(this._root),
      default: new SubPendingPurchasesResolverImpl(),
    });
  }
}
