import AsyncStorage from '@react-native-async-storage/async-storage';
import {error, success} from '../fp';
import {SELECTED_ACCOUNT_ID} from './keys';
import {SetterResult} from './common';

/**
 * @throws {never}
 */
export default async (id?: number): Promise<SetterResult> => {
  try {
    if (id === undefined) {
      await AsyncStorage.removeItem(SELECTED_ACCOUNT_ID);
    } else {
      await AsyncStorage.setItem(SELECTED_ACCOUNT_ID, id.toString(10));
    }
    return success(undefined);
  } catch (raw) {
    return error(raw);
  }
};
