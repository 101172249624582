import dayjs from 'dayjs';
import {SubscriptionAndroid} from 'react-native-iap';
import getOfferPricingPhaseValue from './getOfferPricingPhaseValue';

export default (_: SubscriptionAndroid) => {
  const duration = getOfferPricingPhaseValue(_, 'billingPeriod');
  if (!duration) {
    return undefined;
  }
  const prepareDuration = getValueFromDuration(duration);

  switch (prepareDuration?.unit) {
    case 'Y':
      return dayjs.duration(prepareDuration?.value, 'year');
    case 'M':
      return dayjs.duration(prepareDuration?.value, 'month');
    case 'W':
      return dayjs.duration(prepareDuration?.value, 'week');
    case 'D':
      return dayjs.duration(prepareDuration?.value, 'day');
    default:
      return undefined;
  }
};

export function getValueFromDuration(duration: string) {
  const regex = /^P(\d+)([YMWD])$/;
  const match = duration.match(regex);

  if (!match) {
    return undefined;
  }

  const value = parseInt(match[1], 10);
  const unit = match[2];
  return {value, unit};
}
