import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {FinishQuickStartModal} from '../../FinishQuickStartModal';
import {useGoToPoolMinerActivatedCallback, useSafelyGoBack} from '../hooks';
import {useRoot} from '../../Root/hooks';
import {expr} from 'mobx-utils';
import {RootStackBindingProps} from './RootStackBindingProps';
import {MinerOffer, PaymentMethod} from '../../InAppOffersService';
import usePurchasePoolMiner from '../../PurchaseRequester/usePurchasePoolMiner';
import {ISODateString} from '../../Time';
import {useHandleErrorAndNavigate} from '../../ExceptionHandler';
import {
  QuickStartItemParams,
  QuickStartItemVariant,
} from '../../components/QuickStartItem';
import {AVAILABLE_VARIANTS} from '../../QuickStartService';

const PAYMENT_METHOD = PaymentMethod.InApp;

export default observer(function FinishQuickStartBinding({
  navigation,
  route,
}: RootStackBindingProps<'FinishQuickStart'>) {
  const safelyGoBack = useSafelyGoBack();
  const {quickStartOffer, auth, inAppLoaderState} = useRoot();
  const {selectedIndex = 0, recommendedIndex = 0} = route.params ?? {};
  const items: QuickStartItemParams[] = useMemo(() => {
    return quickStartOffer.offers.flatMap((_, index) => {
      const payment = _.payments.get(PAYMENT_METHOD);
      if (!payment) {
        return [];
      }
      const {price, currency} = payment;
      return [
        {
          variant: AVAILABLE_VARIANTS[index] ?? QuickStartItemVariant.Low,
          name: _.poolMinerConfig.name,
          interval: _.interval,
          intervalType: _.intervalType,
          hashrate: _.poolMinerConfig.hash_rate,
          price: `${price} ${currency}`,
          info: undefined,
          label: index === selectedIndex ? 'Current' : 'Recommended',
          disabled: _.bought,
        },
      ];
    });
  }, [quickStartOffer, selectedIndex]);
  const goToPoolMinerActivated = useGoToPoolMinerActivatedCallback((params) =>
    navigation.navigate('PoolMinerActivated', params),
  );
  const onSuccess = useCallback(
    (poolExpiredDate: ISODateString, poolHashrate: number) => {
      safelyGoBack();
      goToPoolMinerActivated(poolExpiredDate, poolHashrate);
    },
    [goToPoolMinerActivated, safelyGoBack],
  );
  const errorHandler = useHandleErrorAndNavigate();
  const purchase = usePurchasePoolMiner({errorHandler});
  const buyOffer = useCallback(
    async (offer: MinerOffer) => {
      if (auth.accountId === undefined) {
        return;
      }
      inAppLoaderState.addLoading();
      await purchase({
        goToPoolMinerActivated: onSuccess,
        offer: offer,
        paymentMethod: PaymentMethod.InApp,
      });
      inAppLoaderState.deleteLoading();
    },
    [auth, inAppLoaderState, onSuccess, purchase],
  );
  const buySelected = useCallback(async () => {
    const selectedOffer = quickStartOffer.offers[selectedIndex];
    if (!selectedOffer) {
      return;
    }
    await buyOffer(selectedOffer);
  }, [buyOffer, quickStartOffer, selectedIndex]);
  const buyProfitable = useCallback(() => {
    const profitableOffer = quickStartOffer.offers[recommendedIndex];
    if (!profitableOffer) {
      return;
    }
    buyOffer(profitableOffer);
  }, [buyOffer, recommendedIndex, quickStartOffer]);
  const selectedOffer = expr(() => items[selectedIndex]);
  const moreProfitableOffer = expr(() => items[recommendedIndex]);
  return (
    <FinishQuickStartModal
      onContinue={buySelected}
      selectedItem={selectedOffer}
      maxItem={moreProfitableOffer}
      onBuyProfitablePress={buyProfitable}
      onModalClosed={safelyGoBack}
    />
  );
});
