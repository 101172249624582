import React from 'react';
import PressableOpacity, {PressableOpacityProps} from './PressableOpacity';
import {sized} from '../Svg';
import ArrowLeftSvg from '../assets/svg/colorless/arrowLeft.svg';

import {useTheme} from '../styling';

export default (props: PressableOpacityProps) => {
  const theme = useTheme();
  return (
    <PressableOpacity
      {...props}
      style={{
        height: 40,
        width: 40,
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 8,
      }}>
      <ArrowLeftIcon color={theme.palette.icon} />
    </PressableOpacity>
  );
};
const ArrowLeftIcon = sized(ArrowLeftSvg, 18, 12);
