import {RefParser} from '../RefParser/RefParser';
import {Either, error, success} from '../fp';
import {
  DeferredRefParserImpl,
  SlashedParameterRefParserImpl,
  UrlParameterRefParserImpl,
} from '../RefParser';

export default class BatchRefParserImpl implements RefParser {
  private readonly _parsers = [
    new UrlParameterRefParserImpl(),
    new SlashedParameterRefParserImpl(),
    new DeferredRefParserImpl(),
  ];

  parse(str: string): Either<string, void> {
    for (const parser of this._parsers) {
      const result = parser.parse(str);
      if (result.success) {
        return success(result.right);
      }
    }
    return error(undefined);
  }
}
