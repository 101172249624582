import {Platform} from 'react-native';
import NativePurchaseScreenStateImpl from './NativePurchaseScreenStateImpl';
import {Auth} from '../../Auth';
import {ProposalsState} from '../../ProposalsState';
import WebPurchaseScreenStateImpl from './WebPurchaseScreenStateImpl';
import {PurchaseScreenState} from './PurchaseScreenState';
import {Service} from '../../structure';
import {ConnectedClient} from '../../ContextClient';
import {CurrentSlotSubscription} from '../../CurrentSlotSubscriptionService';
import {InAppOffers} from '../../InAppOffersService';
import {InAppPurchase} from '../../InAppPurchase';

export default class PurchaseScreenStateFactory {
  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly proposalsState: ProposalsState;
      readonly connectedClient: ConnectedClient;
      readonly currentSlotSubscription: CurrentSlotSubscription;
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchase: InAppPurchase;
    },
  ) {}

  create() {
    return Platform.select<PurchaseScreenState & Service>({
      web: new WebPurchaseScreenStateImpl(this._root),
      default: new NativePurchaseScreenStateImpl(this._root),
    });
  }
}
