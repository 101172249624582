import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import React, {useMemo} from 'react';
import RefreshSvg from '../assets/svg/colored/refresh.svg';
import {SubscriptionViewProps} from './SubscriptionView';
import MarkedLabel from './MarkedLabel';
import RestoredSubscriptionView from './RestoredSubscriptionView';
import {sized} from '../Svg';

export default observer((props: SubscriptionViewProps) => {
  const strings = useStrings();
  const label = useMemo(
    () => (
      <MarkedLabel
        Mark={SmallRefresh}
        label={strings['recoveryAccess.recoveryVersion']}
      />
    ),
    [strings],
  );
  return <RestoredSubscriptionView label={label} {...props} />;
});

export const SmallRefresh = sized(RefreshSvg, 16);
