import {LogRecord} from '../Log';
import {isRequest, isResponse} from '../JsonRpc';
import {JsonRpcLogContent} from '../Log';

export default (records: readonly Readonly<LogRecord>[]) => {
  const stateIds = new Set(
    records.flatMap((_) =>
      _.content instanceof JsonRpcLogContent &&
      _.content.incoming &&
      isRequest(_.content.message) &&
      _.content.message.method === 'farm_states'
        ? [_.content.message.id]
        : [],
    ),
  );
  return records.filter(
    (_) =>
      !(_.content instanceof JsonRpcLogContent) ||
      (!(
        _.content.incoming &&
        isRequest(_.content.message) &&
        _.content.message.method === 'farm_states'
      ) &&
        !(
          !_.content.incoming &&
          isResponse(_.content.message) &&
          stateIds.has(_.content.message.id)
        )),
  );
};
