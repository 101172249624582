import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {useForcedContext} from '../../context';
import {PurchaseScreenContext} from '../PurchaseScreenContext';
import {SubscriptionCandidateKind} from '../../ProposalsState';
import {processProductOffer} from './processProductOffer';
import {processMinerSubscriptionProposal} from './processMinerSubscriptionProposal';
import {TotalPrice} from './components/TotalPrice';

export default observer(function TotalPriceBinding() {
  const {
    onPurchasePress,
    openEnterPromoModal,
    safelyGoToAffiliate,
    getAffiliateIsPending,
    state: {selectedDoubleProposal, selectedPaymentMethod},
  } = useForcedContext(PurchaseScreenContext);
  const handleBuyPress = useCallback(() => {
    if (selectedDoubleProposal?.product) {
      onPurchasePress(selectedDoubleProposal.product.offer);
    }
  }, [onPurchasePress, selectedDoubleProposal]);
  const handleSubscribePress = useCallback(() => {
    if (
      selectedDoubleProposal?.subscription?.kind ===
      SubscriptionCandidateKind.Available
    ) {
      onPurchasePress(selectedDoubleProposal?.subscription.offer);
    }
  }, [onPurchasePress, selectedDoubleProposal]);

  const productOffer = selectedDoubleProposal?.product?.offer
    ? processProductOffer(
        selectedDoubleProposal?.product.offer,
        selectedPaymentMethod,
      )
    : undefined;
  const subscriptionOffer = selectedDoubleProposal?.subscription?.offer
    ? processMinerSubscriptionProposal(
        selectedDoubleProposal?.subscription,
        selectedPaymentMethod,
      )
    : undefined;
  return (
    <TotalPrice
      productOffer={productOffer}
      subscriptionOffer={subscriptionOffer}
      onMainOfferPurchasePress={handleBuyPress}
      onAdditionalOfferPurchasePress={handleSubscribePress}
      openEnterPromoModal={openEnterPromoModal}
      safelyGoToAffiliate={safelyGoToAffiliate}
      getAffiliateIsPending={getAffiliateIsPending}
    />
  );
});
