import React from 'react';
import {observer} from 'mobx-react-lite';
import {WorkerSpeedControl} from '../../../common/WorkerSpeedControl';
import {View} from 'react-native';
import {createStylesHook} from '../../../../styling';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {SlotMode} from '../../../types';
import {DESKTOP_BREAKPOINT} from '../../../../units/constants';

export interface SpeedContainerProps {
  worker: ReadonlyDeep<WorkerEntity>;
  onExpiredPress: () => void;
  hideTitle?: boolean;
  disabled?: boolean;
}

export default observer(
  ({
    worker,
    onExpiredPress,
    hideTitle = false,
    disabled,
  }: SpeedContainerProps) => {
    const styles = useStyles();
    return (
      <View style={styles.root}>
        <WorkerSpeedControl
          onExpiredPress={onExpiredPress}
          slotMode={SlotMode.Detail}
          worker={worker}
          hideTitle={hideTitle}
          disabled={disabled}
        />
      </View>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  root: {
    flex: 16,
    justifyContent: 'center',
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        flex: 1,
        justifyContent: 'flex-start',
        width: '100%',
      },
    }),
  },
}));
