import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {ProposalTable} from '../PurchaseScreen/PoolMinersTab/components/ProposalTable';
import {useRoot, useStrings} from '../Root/hooks';
import {variance} from '../styling';
import {View} from 'react-native';
import {
  DoubleProposal,
  GroupContextProposal,
  Proposal,
  ProposalByInterval,
} from '../ProposalsState';
import {IntervalRowProps} from '../PurchaseScreen/PoolMinersTab/components/ProposalTable/IntervalRow';
import {MinerProductOffer, PaymentMethod} from '../InAppOffersService';
import {PurchaseId} from '../units';
import {useProposalTableHelper} from '../PurchaseScreen/PoolMinersTab/components/ProposalTable/ProposalTableBiding';

export type ProposalTableBindingProps = {
  selectedProposal: Proposal<MinerProductOffer> | undefined;
  selectProposal: (id: PurchaseId) => void;
  selectedPaymentMethod: PaymentMethod;
  proposalTable:
    | ProposalByInterval<GroupContextProposal<DoubleProposal>>
    | undefined;
};

export default observer(function ProposalTableBinding(
  props: ProposalTableBindingProps,
) {
  const {
    proposalTable,
    selectedProposal,
    selectedPaymentMethod,
    selectProposal,
  } = props;
  const strings = useStrings();
  const {inAppOffers, proposalsState} = useRoot();
  const {getPrice} = useProposalTableHelper(
    selectedProposal?.offer.poolMinerConfig.hash_rate,
    selectedProposal?.offer.interval,
    selectedPaymentMethod,
  );
  const handleItemPress = useCallback(
    (item: IntervalRowProps) => {
      if (!item.disabled && item.offerId) {
        const candidate = inAppOffers.offerByOfferId.get(item.offerId);
        if (candidate) {
          selectProposal(candidate.purchaseId);
        }
      }
    },
    [inAppOffers.offerByOfferId, selectProposal],
  );
  const items: IntervalRowProps[] = useMemo(() => {
    return proposalsState.uniqProductIntervalList.map(
      (interval, index, arr) => {
        const _: GroupContextProposal<DoubleProposal> | undefined =
          proposalTable?.get(interval);
        const proposal = _?.product;
        const offer = proposal?.offer;
        const payment = offer?.payments.get(PaymentMethod.InApp);
        if (!offer || !payment) {
          return {
            selected: false,
            disabled: true,
            isLast: index === arr.length - 1,
            duration: interval,
            priceText: strings['common.notAvailable'],
            suffix: strings['common.shortMonth'],
          };
        }
        const selected = offer.id === selectedProposal?.offer.id ?? false;
        return {
          selected,
          offerId: offer.offerId,
          disabled: !proposal.available,
          isLast: index === arr.length - 1,
          priceText: getPrice(proposal),
          duration: interval,
          suffix: strings['common.shortMonth'],
          hashrate: proposal.offer.poolMinerConfig.hash_rate,
        };
      },
    );
  }, [
    getPrice,
    proposalTable,
    proposalsState.uniqProductIntervalList,
    selectedProposal,
    strings,
  ]);
  return (
    <RootView>
      <ProposalTable
        items={items}
        onItemPress={handleItemPress}
        tableTitle={strings['renewAccess.tableTitle']}
      />
    </RootView>
  );
});

const RootView = variance(View)(() => ({
  root: {
    paddingTop: 10,
  },
}));
