import {useRoot} from '../Root/hooks';
import {useCallback} from 'react';
import {ApiMode} from '../farmApi';

export default function useCheckCanMinerBuy() {
  const {auth, currentSubscriptionState, apiStore} = useRoot();
  const {subscription} = currentSubscriptionState;
  return useCallback(() => {
    if (auth.state?.kind !== 'Connected') {
      return false;
    }
    return (
      subscription &&
      apiStore.mode === ApiMode.Real &&
      subscription.slots < subscription.maxSlots
    );
  }, [apiStore, auth, subscription]);
}
