import React from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import {variance} from '../../styling';
import TableHead from './TableHead';
import TableBody from './TableBody';
import {PurchaseId} from '../../units';
import SelectSection from './SelectButtonSection';
import {PaymentMethod, SlotSubscriptionOffer} from '../../InAppOffersService';
import ProposalSlotBinding from './ProposalSlotBinding';

export interface PurchaseTableSectionViewProps {
  offerList: SlotSubscriptionOffer[];
  visibleFreeMinerSection: boolean;
  purchasedProductIds: ReadonlySet<PurchaseId>;
  onSelectPurchase: (productId: PurchaseId) => void;
  selectedOffer?: SlotSubscriptionOffer;
  disabledButton: boolean;
  onSelectButtonPress: () => void;
  selectedPayment: PaymentMethod;
}

export default observer((props: PurchaseTableSectionViewProps) => {
  const {
    offerList,
    visibleFreeMinerSection,
    purchasedProductIds,
    onSelectPurchase,
    selectedOffer,
    disabledButton,
    onSelectButtonPress,
    selectedPayment,
  } = props;
  return (
    <View>
      <TableView>
        <TableHead />
        <TableBody
          offerList={offerList}
          selectedPayment={selectedPayment}
          purchasedProductIds={purchasedProductIds}
          selectedOffer={selectedOffer}
          onSelectPurchase={onSelectPurchase}
          onSelectButtonPress={onSelectButtonPress}
        />
      </TableView>
      <ProposalSlotBinding />
      <SelectSection
        onPress={onSelectButtonPress}
        selectedPaymentMethod={selectedPayment}
        disabled={disabledButton}
        selectedOffer={selectedOffer}
        visibleFreeMinerSection={visibleFreeMinerSection}
      />
    </View>
  );
});

const TableView = variance(View)((theme) => ({
  root: {
    ...theme.mediaQuery({
      1000: {
        padding: 20,
        backgroundColor: theme.palette.back,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: theme.palette.border,
        borderStyle: 'dashed',
      },
    }),
  },
}));
