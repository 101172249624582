import {View} from 'react-native';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {EmptyFarmViewProps} from './EmptyFarmView';
import ContainerView from './ContainerView';
import IdLabel from './IdLabel';
import SlotsDescription from './SlotsDescription';
import {useStyles} from '../styling';
import {useHover} from 'react-native-web-hooks';

export type SubscriptionViewProps = EmptyFarmViewProps & {
  maxSlots: number;
  poolMiners: number;
  slots: number;
};

export default observer((props: SubscriptionViewProps) => {
  const {accountId, maxSlots, slots, ...rest} = props;
  const styles = useStyles(() => ({
    content: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    flex: {
      flexGrow: 1,
      flexShrink: 1,
    },
  }));

  const rootRef = React.useRef<View>(null);
  const isHovered = useHover(rootRef);

  return (
    <ContainerView
      ref={rootRef}
      contentStyle={styles.content}
      isHovered={isHovered}
      {...rest}>
      <View style={styles.flex}>
        <IdLabel accountId={accountId} isHovered={isHovered} />
      </View>
      <SlotsDescription slots={maxSlots} miners={slots} />
    </ContainerView>
  );
});
