import {Offer, PaymentMethod} from '../InAppOffersService';
import {useRoot} from '../Root/hooks';
import {useCallback} from 'react';
import useHandleErrorAndExtractPromo from './useHandleErrorAndExtractPromo';
import {ErrorHandlerSample} from '../ExceptionHandler/useHandleErrorFactory';
import {PaymentType} from './index';
import {openLink} from '../Links';

export type HookPurchaseSlotOfferParams = {
  errorHandler: ErrorHandlerSample;
};

export type PurchaseSlotOfferParams = {
  offer: Offer;
  goToThanksForPurchase(): void;
  paymentMethod: PaymentMethod;
};

export default function usePurchaseSlotOffer({
  errorHandler,
}: HookPurchaseSlotOfferParams) {
  const {purchaseRequester} = useRoot();
  const processPurchaseResult = useHandleErrorAndExtractPromo({errorHandler});
  return useCallback(
    async (params: PurchaseSlotOfferParams) => {
      const {offer, goToThanksForPurchase, paymentMethod} = params;
      const requestParams = {
        purchaseId: offer.purchaseId,
        purchaseTokenAndroid: undefined,
        payment: paymentMethod,
        poolMinerId: undefined,
        poolMinerIdForRenew: undefined,
        subscriptionOfferTokenAndroid: offer.subscriptionOfferTokenAndroid,
      };
      const response = await purchaseRequester.request(requestParams);
      const process_ = await processPurchaseResult(response);
      if (!process_.success) {
        return;
      }
      if (response.success) {
        switch (response.right.kind) {
          case PaymentType.InAppCompleted: {
            goToThanksForPurchase();
            break;
          }
          case PaymentType.ExternalOrder: {
            if (response.right.redirectUrl) {
              await openLink(response.right.redirectUrl);
            }
            break;
          }
        }
      }
    },
    [purchaseRequester, processPurchaseResult],
  );
}
