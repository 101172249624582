import {RefParser} from './RefParser';
import {Either, error, success} from '../fp';
import {Platform} from 'react-native';
import {getBundleId} from 'react-native-device-info';

/**
 * @example com.example://deferredRef?utm_campaign=xyz&utm_source=abc -> utm_campaign=xyz&utm_source=abc
 */
export default class DeferredRefParserImpl implements RefParser {
  private readonly PREFIX =
    Platform.OS === 'web'
      ? `${window.location.origin}/deferredRef?`
      : `${getBundleId()}://deferredRef?`;

  parse(str: string): Either<string, void> {
    const prefix = this.PREFIX;
    if (str.startsWith(prefix)) {
      const suffix = str.substring(prefix.length);
      return success(suffix);
    }
    return error(undefined);
  }
}
