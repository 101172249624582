import {computed} from 'mobx';
import Auth from '../Auth/Auth';
import {AuthFarmStateHelper} from './AuthFarmStateHelper';

export default class AuthFarmStateHelperImpl implements AuthFarmStateHelper {
  constructor(
    private readonly _root: {
      readonly auth: Auth;
    },
  ) {}

  @computed
  get isNewbie() {
    const state = this._root.auth.state;
    if (state?.kind !== 'Connected') {
      return null;
    }
    const entries = [...state.subscriptionMap.entries()];
    if (entries.length === 1) {
      const record = entries[0][1];
      if (record.slots === 0 && record.poolMiners === 0) {
        return true;
      }
    }
    return false;
  }

  @computed
  get poolMinersCount() {
    const state = this._root.auth.state;
    if (state?.kind !== 'Connected') {
      return null;
    }
    const currentFarm = state.subscriptionMap.get(state.accountId);
    if (!currentFarm) {
      return null;
    }
    return currentFarm.poolMiners;
  }

  @computed
  get totalPoolMinersCount() {
    const state = this._root.auth.state;
    if (state?.kind !== 'Connected') {
      return null;
    }
    let total = 0;
    for (const accountId of [...state.accountIds.values()]) {
      const subscription = state.subscriptionMap.get(accountId);
      if (subscription) {
        total += subscription.poolMiners;
      }
    }
    return total;
  }
}
