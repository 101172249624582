import {action, observable, makeObservable} from 'mobx';
import {ObservableWebSocketState} from './ObservableWebSocketState';

export default class WebSocketCarouselImpl {
  @observable.ref private _current?: ObservableWebSocketState;

  constructor() {
    makeObservable(this);
  }

  get current() {
    return this._current;
  }

  @action
  next(state: ObservableWebSocketState) {
    this._current?.close();
    this._current?.dispose();
    this._current = state;
  }
}
