import React, {useMemo} from 'react';
import {Pressable, ScrollView, View, Text} from 'react-native';

import {observer} from 'mobx-react-lite';
import {useStrings} from '../Root/hooks';
import Svg, {SvgProps, Path} from 'react-native-svg';

import TableSpeedCell from './TableSpeedCell';
import {Hoverable} from 'react-native-web-hooks';
import {createStylesHook, useTheme} from '../styling';
import {sized} from '../Svg';
import ClockSvg from '../assets/svg/colorless/clock.svg';
import ScheduleState from './ScheduleState';

const days = Array.from({length: 7}, (_, index) => index);
const hours = Array.from({length: 24}, (_, index) => index);

export interface WeekTableProps {
  state: ScheduleState;
}

export default observer(({state}: WeekTableProps) => {
  const theme = useTheme();
  const strings = useStrings();
  const styles = useStyles();
  const daysList = useMemo(
    () => [
      strings['calendar.shortDay.monday'],
      strings['calendar.shortDay.tuesday'],
      strings['calendar.shortDay.wednesday'],
      strings['calendar.shortDay.thursdat'],
      strings['calendar.shortDay.friday'],
      strings['calendar.shortDay.saturday'],
      strings['calendar.shortDay.sunday'],
    ],
    [strings],
  );
  return (
    <View style={styles.background}>
      <ScrollView horizontal style={styles.scroll}>
        <View style={styles.root}>
          <View style={styles.content}>
            <View style={[styles.row]}>
              <View style={[styles.cell, styles.dayNameCell]}>
                <TimeIcon color={theme.palette.icon} />
              </View>
              {hours.map((h) => (
                <Pressable key={h} onPress={() => state.fillPointVertical(h)}>
                  <Hoverable>
                    {(isHovered) => (
                      <View style={styles.cell}>
                        <Text
                          style={[
                            styles.hour,
                            isHovered && styles.hoveredHour,
                          ]}>
                          {h}
                        </Text>
                      </View>
                    )}
                  </Hoverable>
                </Pressable>
              ))}
              <Pressable style={[styles.cell, styles.lastRightCell]} />
            </View>
            {days.map((d) => (
              <View
                style={[
                  styles.row,
                  d === days.length - 1 && styles.lastBottomRow,
                ]}
                key={d}>
                <Pressable onPress={() => state.fillPointHorizontal(d)}>
                  <Hoverable>
                    {(isHovered) => (
                      <View style={[styles.cell, styles.dayNameCell]}>
                        <Text
                          style={[
                            styles.dayName,
                            isHovered && styles.hoveredDayName,
                          ]}>
                          {daysList[d]}
                        </Text>
                      </View>
                    )}
                  </Hoverable>
                </Pressable>

                {hours.map((h) => {
                  const hour = state.matrix[d][h];
                  return (
                    <TableSpeedCell
                      style={styles.cell}
                      key={h}
                      onPress={() => state.fillPoint(d, h)}
                      point={hour}
                    />
                  );
                })}
                <Pressable
                  onPress={() => {
                    state.fillPointHorizontal(d);
                  }}>
                  <Hoverable>
                    {(isHovered) => (
                      <View style={[styles.cell, styles.lastRightCell]}>
                        <CheckIcon
                          stroke={
                            isHovered
                              ? theme.palette.secondary
                              : theme.palette.textSecondary
                          }
                        />
                      </View>
                    )}
                  </Hoverable>
                </Pressable>
              </View>
            ))}
          </View>
        </View>
      </ScrollView>
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  background: {
    backgroundColor: theme.palette.background,
    borderTopWidth: 1,
    borderTopColor: theme.palette.border,
    zIndex: -1,
  },
  scroll: {
    backgroundColor: theme.select(
      theme.palette.background,
      theme.palette.backdrop,
    ),
  },
  root: {
    padding: 20,
  },
  content: {
    overflow: 'hidden',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme.palette.border,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderBottomColor: theme.palette.border,
    borderBottomWidth: 1,
  },
  lastBottomRow: {
    borderBottomWidth: 0,
  },
  cell: {
    backgroundColor: theme.palette.background,
    height: 30,
    width: 30,
    borderRightColor: theme.palette.border,
    borderRightWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dayNameCell: {
    alignItems: 'flex-start',
    paddingLeft: 10,
    width: 65,
  },
  lastRightCell: {
    borderRightWidth: 0,
  },
  lastBottomCell: {
    borderBottomWidth: 0,
  },
  dayName: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    letterSpacing: 0.04,
    color: theme.palette.textPrimary,
  },
  hoveredDayName: {
    color: theme.palette.secondary,
  },
  hour: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    letterSpacing: 0.04,
    color: theme.palette.textPrimary,
  },
  hoveredHour: {
    color: theme.palette.secondary,
  },
}));

const TimeIcon = sized(ClockSvg, 16);

const CheckIcon = ({stroke = '#757575', ...rest}: SvgProps) => {
  return (
    <Svg width={11} height={11} viewBox="0 0 11 11" fill="none" {...rest}>
      <Path
        d="M1 5.286l3.571 3.571 5-7.857"
        stroke={stroke}
        strokeWidth={2}
        strokeLinecap="round"
      />
    </Svg>
  );
};
