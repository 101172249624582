import {TrialPeriodUnit} from '../InAppOffersService';

export default (v: number, u: TrialPeriodUnit) => {
  switch (u) {
    case 'D':
      return v;
    case 'W':
      return v * 7;
    case 'M':
      return v * 30;
    case 'Y':
      return v * 365;
    default:
      return -1;
  }
};
