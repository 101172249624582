import {AdvertHistory} from './AdvertHistory';
import {Millisecond, Second} from '../Time';
import {ADVERT_BANNERS_HISTORY, define} from '../persistence';
import dayjs from 'dayjs';
import {bind, Either, error, success} from '../fp';
import {GlobalError, UNKNOWN_ERROR, UnknownError} from '../Error';
import {ErrorRepository} from '../ErrorRepository';
import {AdId, AdSpot} from '../ApiStore';
import {AdInfoItem} from './Advert';

export default class AdvertHistoryImpl implements AdvertHistory {
  private readonly _sessionClosedList: AdSpot[] = [];

  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
    },
  ) {}

  checkSessionClosed = bind((spot: AdSpot) => {
    return this._sessionClosedList.includes(spot);
  }, this);

  private _createUnknownError(raw: unknown) {
    return this._root.errorRepository.create<UnknownError>({
      kind: UNKNOWN_ERROR,
      raw: raw,
    });
  }

  async close(
    ad: AdInfoItem,
    exclude: boolean,
  ): Promise<Either<void, GlobalError>> {
    const {id, options} = ad;
    const {closeTime, canExclude} = options;
    const get_ = await getAdvertBannersHistory();
    if (!get_.success) {
      return error(this._createUnknownError(get_.left));
    }
    this._sessionClosedList.push(ad.spot);
    const data = get_.right ?? {};
    const excluded = get_.right?.excluded || [];
    if (canExclude && exclude) {
      excluded.push(id);
    }
    const setExcluded_ = await setAdvertBannersHistory({
      ...data,
      excluded,
    });
    if (!setExcluded_.success) {
      return error(this._createUnknownError(setExcluded_.left));
    }

    if (closeTime === undefined) {
      return success(undefined);
    }

    const newSchedule = data.schedule ?? {};
    // @ts-ignore
    newSchedule[ad.id] = {nextShowTime: this._getNextTime(closeTime)};
    const setSchedule_ = await setAdvertBannersHistory({
      ...data,
      excluded,
      schedule: newSchedule,
    });
    if (!setSchedule_.success) {
      return error(this._createUnknownError(setSchedule_.left));
    }

    return success(undefined);
  }

  private _getNextTime(closeTime: Second) {
    return (dayjs().add(closeTime, 'seconds').unix() * 1000) as Millisecond;
  }

  async get() {
    return getAdvertBannersHistory();
  }

  async getExcludeList() {
    const get_ = await getAdvertBannersHistory();
    if (!get_.success) {
      return [];
    }
    return get_.right?.excluded ?? [];
  }
}

export type ScheduleRecord = Partial<
  Record<
    AdId,
    {
      nextShowTime: Millisecond | undefined;
    }
  >
>;

export type AdvertBannersHistory = Partial<{
  schedule: ScheduleRecord | undefined;
  excluded: AdId[];
}>;

const [getAdvertBannersHistory, setAdvertBannersHistory] =
  define<AdvertBannersHistory>(ADVERT_BANNERS_HISTORY);
