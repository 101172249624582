import ConsistentInterval from './ConsistentInterval';

export default class LinearInterpolation<T extends number> {
  constructor(
    public source: ConsistentInterval<T>,
    public target: ConsistentInterval<T>,
  ) {}

  translate(position: T) {
    const absolutePosition = (position - this.source.from) / this.source.span;
    return (this.target.from + absolutePosition * this.target.span) as T;
  }
}
