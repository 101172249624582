import React from 'react';
import {observer} from 'mobx-react-lite';
import {Text, View} from 'react-native';
import {useRoot, useStrings} from '../Root/hooks';
import {useStyles} from '../styling';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import dayjs from 'dayjs';
import {now} from '../Time';

export type CurrentPeriodSectionProps = {
  worker: ReadonlyDeep<WorkerEntity>;
};

export const CurrentPeriodSection = observer(
  (props: CurrentPeriodSectionProps) => {
    const {worker} = props;
    const root = useRoot();
    const {translation} = root;
    const styles = useStyles((theme) => ({
      root: {
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.border,
      },
      title: {
        ...theme.fontByWeight('700'),
        color: theme.palette.textPrimary,
        marginBottom: 10,
      },
      desc: {
        ...theme.fontByWeight('400'),
        color: theme.palette.textSecondary,
        fontSize: 13,
        lineHeight: 22,
      },
      expired: {
        ...theme.fontByWeight('700'),
        color: theme.palette.error,
      },
      error: {
        color: theme.palette.error,
      },
    }));
    const fromDate = dayjs(worker.start_time).locale(translation.localeTag);
    const fromDateFormatted = fromDate.format('DD MMM YYYY');
    const toDate = dayjs(worker.end_time).locale(translation.localeTag);
    const toDateFormatted = toDate.format('DD MMM YYYY');
    const expired = toDate.isBefore(now());
    const strings = useStrings();
    return (
      <View style={styles.root}>
        <Text style={styles.title}>
          {strings['renewAccess.currentPeriod']}:
        </Text>
        <Text style={styles.desc}>
          <Text>{fromDateFormatted} – </Text>
          <Text style={expired && styles.error}>{toDateFormatted}</Text>{' '}
          {expired && (
            <Text style={styles.expired}>
              ({strings['renewAccess.expired']})
            </Text>
          )}
        </Text>
      </View>
    );
  },
);
