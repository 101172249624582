import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {Text, View} from 'react-native';
import {sized} from '../Svg';
import ConfigurationSvg from '../assets/svg/colored/configuration-double.svg';
import {useStrings} from '../Root/hooks';

export type ConfigurationListHeaderProps = {
  title?: string;
};

export default observer(function ConfigurationListHeader(
  props: ConfigurationListHeaderProps,
) {
  const {title} = props;
  const strings = useStrings();

  return (
    <TopView>
      <IconView>
        <ConfigurationIcon />
      </IconView>
      {title && <TopTitle>{title}</TopTitle>}
      <TopText>{strings['chooseConfigurationScreen.description']}</TopText>
    </TopView>
  );
});

const TopView = variance(View)(() => ({
  root: {
    paddingVertical: 20,
    alignItems: 'center',
  },
}));

const TopTitle = variance(Text)((theme) => ({
  root: {
    marginTop: 20,
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 32,
    textAlign: 'center',
  },
}));

const TopText = variance(Text)((theme) => ({
  root: {
    marginTop: 15,
    ...theme.fontByWeight('400'),
    color: theme.palette.textPrimary,
    fontSize: 16,
    lineHeight: 26,
    textAlign: 'center',
  },
}));

const ConfigurationIcon = sized(ConfigurationSvg, 125, 70);

const IconView = variance(View)(() => ({
  root: {
    marginRight: 10,
  },
}));
