import {
  FinishPendingPurchasesResult,
  PendingPurchasesResolver,
} from './PendingPurchasesResolver';

export default class SubPendingPurchasesResolverImpl
  implements PendingPurchasesResolver
{
  resolve(): Promise<FinishPendingPurchasesResult> {
    throw 'Not implement on this platform';
  }
}
