import 'react-native-get-random-values';
import 'react-native-gesture-handler';
import React from 'react';
import {createRoot} from 'react-dom/client';
import Launcher from './Launcher';
import {extendDayJs} from './utils/extendDayJs';
import handleBackgroundMessages from './handleBackgroundMessages';
import {config, Sentry} from './Sentry';
import './assets/fonts';

// TODO Close after fixes https://github.com/expo/expo/issues/22333
// @ts-ignore
if (window._frameTimestamp === undefined) {
  // @ts-ignore
  window._frameTimestamp = null;
}

if (!__DEV__) {
  Sentry.init(config);
}

extendDayJs();
handleBackgroundMessages();
const element = document.getElementById('root');
const root = createRoot(element!);
root.render(<Launcher />);
