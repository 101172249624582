import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {
  ActivityIndicator,
  Animated,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {createStylesHook, useTheme} from '../styling';
import {
  HandlerStateChangeEvent,
  PanGestureHandler,
  State,
} from 'react-native-gesture-handler';
import {PressableOpacity} from '../components';
import {sized} from '../Svg';
import TripleRightArrowSvg from '../assets/svg/colorless/tripleRightArrow.svg';
import ReloadSvg from '../assets/svg/colorless/reload.svg';
import {SliderStatus} from './PoolMinerActivator';
import {useRoot, useStrings} from '../Root/hooks';

export const BUTTON_WIDTH = 55;

export default observer(() => {
  const {
    poolMinerActivator: {
      puzzle: {
        translationX,
        verifyPosition,
        changeStateStatus,
        sliderStatus,
        refresh,
        puzzleWidth,
      },
    },
  } = useRoot();
  const strings = useStrings();
  const handleGesture = Animated.event([{nativeEvent: {translationX}}], {
    useNativeDriver: true,
  });
  const translateX = translationX.interpolate({
    inputRange: [0, puzzleWidth],
    outputRange: [0, puzzleWidth],
    extrapolate: 'clamp',
  });
  const handlerStateChange = useCallback(
    (event: HandlerStateChangeEvent) => {
      if (event.nativeEvent.state === State.BEGAN) {
        changeStateStatus(SliderStatus.Swiping);
      } else if (event.nativeEvent.state === State.END) {
        const x = event.nativeEvent.translationX as number;
        const y = event.nativeEvent.translationY as number;
        verifyPosition({x, y});
        translationX.setValue(0);
      }
    },
    [changeStateStatus, translationX, verifyPosition],
  );
  const theme = useTheme();
  const styles = useStyles();
  return (
    <View style={[styles.root, {width: puzzleWidth}]}>
      <View style={styles.swipeButtonContainer}>
        <PanGestureHandler
          onGestureEvent={handleGesture}
          onHandlerStateChange={handlerStateChange}>
          <Animated.View
            style={[
              styles.swipeButton,
              {
                transform: [{translateX: translateX}],
              },
            ]}>
            <TripleRightArrowIcon color={theme.contrast(theme.palette.icon)} />
          </Animated.View>
        </PanGestureHandler>
      </View>
      {sliderStatus === SliderStatus.Idle ? (
        <View style={styles.content}>
          <Text style={styles.contentText}>
            {strings['puzzle.slider.default']}
          </Text>
          <PressableOpacity onPress={refresh} style={styles.reloadButton}>
            <ReloadIcon color={theme.palette.secondary} />
          </PressableOpacity>
        </View>
      ) : null}
      {sliderStatus === SliderStatus.Error ? (
        <View style={[styles.result, styles.resultError]}>
          <Text style={styles.resultText}>
            {strings['puzzle.slider.error']}
          </Text>
        </View>
      ) : null}
      {sliderStatus === SliderStatus.Confirming ? (
        <View style={[styles.result, styles.resultConfirming]}>
          <Text style={[styles.resultText, styles.resultTextConfirming]}>
            {strings['puzzle.slider.confirming']}
          </Text>
          <ActivityIndicator color={theme.palette.primary} />
        </View>
      ) : null}
    </View>
  );
});

const TripleRightArrowIcon = sized(TripleRightArrowSvg, 28, 16);
const ReloadIcon = sized(ReloadSvg, 20);

const useStyles = createStylesHook((theme) => ({
  root: {
    marginTop: 15,
    backgroundColor: theme.palette.background,
    borderWidth: 1,
    borderColor: theme.palette.border,
    borderRadius: 3,
    height: 44,
    paddingLeft: 70,
    paddingRight: 5,
    justifyContent: 'center',
  },
  swipeButtonContainer: {
    ...StyleSheet.absoluteFillObject,
  },
  swipeButton: {
    backgroundColor: theme.palette.secondary,
    width: BUTTON_WIDTH,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentText: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 16,
    flexShrink: 1,
  },
  reloadButton: {
    width: 34,
    height: 34,
    borderWidth: 1,
    borderColor: theme.palette.border,
    borderRadius: 3,
    backgroundColor: theme.palette.background,
    justifyContent: 'center',
    alignItems: 'center',
  },
  result: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.success,
  },
  resultError: {
    backgroundColor: theme.palette.error,
  },
  resultConfirming: {
    backgroundColor: theme.palette.success,
    flexDirection: 'row',
  },
  resultText: {
    ...theme.fontByWeight('700'),
    color: theme.select(
      theme.contrast(theme.palette.textPrimary),
      theme.palette.textPrimary,
    ),
    textTransform: 'uppercase',
  },
  resultTextConfirming: {
    marginRight: 10,
  },
}));
