import {useCallback} from 'react';
import {Alert} from 'react-native';
import {useRoot} from '../Root/hooks';

export default () => {
  const {logExporter, log, clipboard} = useRoot();
  const copyLog = useCallback(async () => {
    clipboard.setString(logExporter.getContent(log));
    Alert.alert(
      'Success',
      'The value has been copied to clipboard',
      [{style: 'default', text: 'OK'}],
      {cancelable: true},
    );
  }, [clipboard, log, logExporter]);
  const saveLog = useCallback(async () => {
    const result = await logExporter.save();
    if (!result.success) {
      const str = JSON.stringify(result.left, null, 1);
      log.write({body: `SAVE LOG ERROR: \n${str}`});
    }
    Alert.alert(
      'Success',
      'The value is successfully saved to the file',
      [{style: 'default', text: 'OK'}],
      {cancelable: true},
    );
  }, [log, logExporter]);
  const shareLog = useCallback(async () => {
    const result = await logExporter.share();
    if (!result.success) {
      const str = JSON.stringify(result.left, null, 1);
      log.write({body: `SHARE LOG ERROR: \n${str}`});
    }
  }, [log, logExporter]);
  return {copyLog, saveLog, shareLog};
};
