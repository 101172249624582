import React, {useState} from 'react';
import {Text, View} from 'react-native';
import DoublePlusSvg from '../../assets/svg/circled/doublePlus.svg';
import {AddMiningGroupModal} from '../modals';
import {useRoot, useStrings} from '../../Root/hooks';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  PressableOpacity,
} from '../../components';
import PlusSvg from '../../assets/svg/colorless/plus.svg';
import {observer} from 'mobx-react-lite';
import {Container} from '../../components/Grid';
import {createStylesHook} from '../../styling';
import {sized} from '../../Svg';
import {DESKTOP_BREAKPOINT} from '../../units/constants';
import {CREATE_GROUP_HEIGHT, DESKTOP_CREATE_GROUP_HEIGHT} from '../constants';

export default observer(() => {
  const {currentSubscriptionState} = useRoot();
  const {isPlatformSupported} = currentSubscriptionState;
  const [modalVisible, setVisible] = useState(false);
  const strings = useStrings();
  const styles = useStyles();
  return (
    <Container style={styles.background}>
      <View style={styles.root}>
        <DoublePlusIcon />
        <Text style={styles.title}>
          {strings['dashboard.multipleGroupsSection.title']}
        </Text>
        <Text style={styles.description}>
          {strings['dashboard.multipleGroupsSection.description']}
        </Text>
        <PressableOpacity
          onPress={() => setVisible(true)}
          style={styles.touchableButtonView}
          disabled={!isPlatformSupported}>
          <Button
            style={styles.button}
            Icon={PlusSvg}
            variant={
              isPlatformSupported
                ? ButtonVariant.Contained
                : ButtonVariant.Disabled
            }
            color={ButtonColor.Primary}>
            {strings['dashboard.multipleGroupsSection.button']}
          </Button>
        </PressableOpacity>
        {modalVisible && (
          <AddMiningGroupModal onDismiss={() => setVisible(false)} />
        )}
      </View>
    </Container>
  );
});

const DoublePlusIcon = sized(DoublePlusSvg, 80);

const useStyles = createStylesHook((theme) => ({
  background: {
    backgroundColor: theme.palette.background,
    marginBottom: 10,
    borderRadius: 15,
  },
  root: {
    paddingHorizontal: 25,
    paddingVertical: 25,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: theme.palette.border,
    backgroundColor: theme.select(
      theme.palette.background,
      theme.palette.backdrop,
    ),
    borderRadius: 15,
    height: CREATE_GROUP_HEIGHT,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        height: DESKTOP_CREATE_GROUP_HEIGHT,
        paddingVertical: 40,
      },
    }),
  },
  title: {
    ...theme.fontByWeight('700'),
    marginTop: 20,
    color: theme.palette.textPrimary,
    fontSize: 20,
    lineHeight: 24,
    textAlign: 'center',
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 32,
        lineHeight: 36,
        marginTop: 30,
      },
    }),
  },
  description: {
    color: theme.palette.textSecondary,
    lineHeight: 22,
    textAlign: 'center',
    fontSize: 13,
    ...theme.fontByWeight('400'),
    marginTop: 10,
    marginBottom: 30,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 18,
        lineHeight: 28,
      },
    }),
  },
  touchableButtonView: {},
  button: {
    height: 44,
    paddingRight: 25,
    paddingVertical: 5,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        paddingLeft: 60,
        paddingRight: 60,
      },
    }),
  },
}));
