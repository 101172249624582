import {RouterSource, Router} from '../structure';

export const TRY = Symbol();
export const FAIL = Symbol();
export const SUCCESS = Symbol();

export interface WatchTarget {
  readonly monitor: RouterSource<{[FAIL]: void; [SUCCESS]: void}>;
  readonly control: Router<{[TRY]: void}>;
}
