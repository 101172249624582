import {Opaque} from 'type-fest';

export interface AndroidChannelRepository {
  getId(description: AndroidChannelDescription): AndroidChannelId;
  createChannels(): Promise<void>;
}

export type AndroidChannelDescription = {
  lights: boolean;
  vibration: boolean;
  sound: boolean;
};

export const ANDROID_CHANNEL_ID = Symbol();
export type AndroidChannelId = Opaque<string, typeof ANDROID_CHANNEL_ID>;
