import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import {useStyles} from '../styling';
import Header from './Header';
import useProcessWebMessageData from './useProcessWebMessageData';
import {PostMessageResponse, TutorialViewProps} from './TutorialViewProps';
import {useRoot} from '../Root/hooks';

export default observer(function TutorialViewWeb(props: TutorialViewProps) {
  const {uri, onClose, ...rest} = props;
  const {json} = useRoot();
  const styles = useStyles(() => ({
    root: {
      flex: 1,
    },
    iframe: {
      flex: 1,
      borderWidth: 0,
    },
  }));
  const process = useProcessWebMessageData({
    onClose,
    ...rest,
  });
  useEffect(() => {
    function handler(event: MessageEvent) {
      try {
        const data_ = json.parse(event.data);
        if (data_.success && data_.right) {
          const result = data_.right as unknown as PostMessageResponse;
          process(result);
        }
      } catch (ignore) {}
    }
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, [json, process]);
  return (
    <View style={styles.root}>
      <Header onClose={onClose} />
      <iframe style={styles.iframe} src={uri} />
    </View>
  );
});
