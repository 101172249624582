import React from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../../Root/hooks';
import RestoreButton from '../shared/components/RestoreButton';
import {useForcedContext} from '../../context';
import {PurchaseScreenContext} from '../PurchaseScreenContext';

export default observer(() => {
  const {inAppOffers} = useRoot();
  const {goToAccessRecovery} = useForcedContext(PurchaseScreenContext);
  if (inAppOffers.error) {
    return null;
  }
  return <RestoreButton goToAccessRecovery={goToAccessRecovery} />;
});
