import {observer} from 'mobx-react-lite';
import {View, ViewProps} from 'react-native';

import React from 'react';
import ChevronRightSvg from '../assets/svg/colorless/chevronRight.svg';
import {useStyles, useTheme} from '../styling';

type NextButtonProps = ViewProps & {
  isHovered?: boolean;
};

export default observer((props: NextButtonProps) => {
  const {style, isHovered, ...rest} = props;
  const styles = useStyles((theme) => ({
    root: {
      width: 25,
      borderStartWidth: 1,
      borderStartColor: theme.palette.disabled,
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));
  const theme = useTheme();
  return (
    <View style={[styles.root, style]} {...rest}>
      <ChevronRightSvg
        color={isHovered ? theme.palette.primary : theme.palette.textSecondary}
      />
    </View>
  );
});
