import React from 'react';
import {Text, TouchableOpacityProps, View} from 'react-native';

import {variance} from '../styling';
import {PressableOpacity} from '../components';
import LoaderIndicator from '../components/LoaderIndicator';

export type PrimaryMenuItemProps = TouchableOpacityProps & {
  text: string;
  active?: boolean;
  loading?: boolean;
  disabled?: boolean;
};

export const PrimaryMenuItem = ({
  text,
  active,
  loading,
  disabled,
  ...rest
}: PrimaryMenuItemProps) => {
  return (
    <PressableOpacity {...rest} disabled={disabled}>
      <Item selected={active} disabled={disabled}>
        <ItemText selected={active}>{text}</ItemText>
        {loading && (
          <LoaderView>
            <LoaderIndicator />
          </LoaderView>
        )}
      </Item>
    </PressableOpacity>
  );
};

const Item = variance(View)((theme) => ({
  root: {
    height: 47,
    paddingHorizontal: 14,
    paddingVertical: 10,
    borderRadius: 6,
    flexDirection: 'row',
    alignItems: 'center',
  },
  selected: {
    backgroundColor: theme.palette.secondary,
  },
  disabled: {
    opacity: theme.select(0.4, 0.6),
  },
}));

const LoaderView = variance(View)(() => ({
  root: {
    marginLeft: 5,
  },
}));

const ItemText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    textTransform: 'uppercase',
    color: theme.palette.secondary,
    flexDirection: 'row',
  },
  selected: {
    color: theme.select(
      theme.contrast(theme.palette.textPrimary),
      theme.palette.textPrimary,
    ),
  },
}));
