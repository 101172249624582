import {LogContent} from './LogContent';

export default class WebSocketErrorLogContent implements LogContent {
  constructor(
    public readonly connectionId: number,
    public readonly event: WebSocketErrorEvent | Event,
  ) {}

  get body() {
    const lines = [`#${this.connectionId} ERROR`];
    const message = (this.event as WebSocketErrorEvent).message;
    if (message !== undefined) {
      if (message) {
        lines.join(`Message: ${message}`);
      } else {
        lines.join('An empty message was specified');
      }
    }
    return lines.join('\n');
  }
}
