import React from 'react';
import {observer} from 'mobx-react-lite';
import SaleSvg from '../../../../assets/svg/colorless/circleDiscount.svg';
import {
  TotalPriceItemContainer,
  TotalPriceItemTop,
  TotalPriceItemTopText,
} from '../../../shared/shared';
import {OfferDiscount} from '../../../../InAppOffersService';
import {sized} from '../../../../Svg';
import {useTheme, variance} from '../../../../styling';
import {Text, View} from 'react-native';
import {useStrings} from '../../../../Root/hooks';

export type TotalPriceDiscountsProps = {
  discounts: OfferDiscount[];
};

export default observer(function TotalPriceDiscounts(
  props: TotalPriceDiscountsProps,
) {
  const {discounts} = props;
  const strings = useStrings();
  const theme = useTheme();
  if (props.discounts.length === 0) {
    return null;
  }
  return (
    <TotalPriceItemContainer>
      <TotalPriceItemTop>
        <SaleIcon color={theme.palette.info} />
        <TotalPriceItemTopText>
          {strings['discounts.label']}
        </TotalPriceItemTopText>
      </TotalPriceItemTop>
      <DiscountContainer>
        {discounts.map((d, idx) => (
          <Discount
            key={d.title.en}
            offsetLeft={idx !== 0}
            second={idx % 2 === 1}
            third={idx % 3 === 2}>
            -{d.percent}%
          </Discount>
        ))}
      </DiscountContainer>
    </TotalPriceItemContainer>
  );
});

const SaleIcon = sized(SaleSvg, 20, 20);

const DiscountContainer = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
}));

const Discount = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    width: 67,
    height: 26,
    borderRadius: 20,
    backgroundColor: theme.palette.primary,
    textAlign: 'center',
    lineHeight: 26,
    color: '#fff',
  },
  offsetLeft: {
    marginLeft: -6,
  },
  second: {
    backgroundColor: theme.palette.success,
  },
  third: {
    backgroundColor: '#000',
  },
}));
