import {Platform} from 'react-native';
import QuickStartOfferService from './QuickStartOfferService';
import {InAppOffers} from '../InAppOffersService';
import {Auth} from '../Auth';
import {AppLifecycle} from '../AppLifecycle';
import WebQuickStartOfferService from './WebQuickStartOfferService';
import {QuickStartOffer} from './QuickStartOffer';
import {Service} from '../structure';
import {AuthFarmStateHelper} from '../AuthFarmStateHelper';

export default class QuickStartServiceFactory {
  constructor(
    private readonly _root: {
      readonly inAppOffers: InAppOffers;
      readonly auth: Auth;
      readonly authFarmStateHelper: AuthFarmStateHelper;
      readonly appLifecycle: AppLifecycle;
    },
  ) {}
  create() {
    return Platform.select<QuickStartOffer & Service>({
      default: new QuickStartOfferService(this._root),
      web: new WebQuickStartOfferService(),
    });
  }
}
