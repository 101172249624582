import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {useStyles} from '../styling';

interface RadioProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  readonly?: boolean;
}

export default ({checked, onChange, readonly}: RadioProps) => {
  const styles = useStyles((theme) => ({
    root: {
      width: 16,
      height: 16,
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 1,
      borderColor: theme.palette.border,
      borderRadius: 10,
    },
    rootChecked: {
      borderColor: theme.palette.secondary,
      borderWidth: 2,
    },
    point: {
      width: 6,
      height: 6,
      borderRadius: 3,
      backgroundColor: theme.palette.secondary,
    },
  }));
  const _render = () => (
    <View style={[styles.root, checked && styles.rootChecked]}>
      {checked && <View style={styles.point} />}
    </View>
  );
  if (readonly) {
    return _render();
  }
  return (
    <TouchableOpacity onPress={() => onChange && onChange(!checked)}>
      {_render()}
    </TouchableOpacity>
  );
};
