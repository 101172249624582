import {InitialStateNavigation} from './InitialStateNavigation';
import {
  TutorialHelper,
  TutorialStorage,
  TutorialType,
} from '../TutorialManager';
import {observable, reaction, makeObservable, runInAction} from 'mobx';
import {Service} from '../structure';
import {InitialState} from '@react-navigation/routers';
import {LocationSource} from '../Location';
import {getStateFromPath} from '@react-navigation/core';
import {Auth} from '../Auth';
import {
  NavigationState,
  PartialState,
} from '@react-navigation/routers/src/types';
import {createTutorialState} from '../Navigation/RootStack/TutorialBinding';
import {createDashboardState} from '../Navigation/BottomTab/DashboardBinding';
import {DASHBOARD} from '../Navigation/RootStack/constants';
import {MobileLinkingOptionsProviderImpl} from '../LinkingOptionsProvider';
import {SpecialLocation} from '../SpecialLocation';

export default class InitialStateNavigationService
  implements InitialStateNavigation, Service
{
  @observable _appNavigationIsReadyToRender = false;

  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly tutorialManagerStorage: TutorialStorage;
      readonly tutorialHelper: TutorialHelper;
      readonly locationSource: LocationSource;
      readonly specialLocation: SpecialLocation;
    },
  ) {
    makeObservable(this);
  }

  get appNavigationIsReadyToRender() {
    return this._appNavigationIsReadyToRender;
  }

  private static _getInitialState(
    tutorialType: TutorialType,
    onSuccess?: PartialState<NavigationState> | NavigationState,
  ) {
    switch (tutorialType) {
      case TutorialType.Video:
        return createTutorialState(onSuccess);
      case TutorialType.Interactive:
        return createDashboardState({
          interactiveTutorial: {
            onSuccess: onSuccess,
          },
        });
      default:
        return undefined;
    }
  }

  initialState?: InitialState;

  subscribe() {
    return reaction(
      () =>
        [
          this._root.tutorialManagerStorage.isReady,
          this._root.auth.isConnected,
        ] as const,
      async ([storageIsReady, authIsConnected], prevState, _) => {
        if (storageIsReady && authIsConnected) {
          const initial = await this._root.locationSource.getInitial();
          const tutorialType =
            await this._root.tutorialHelper.getTutorialType();
          const targetPath = initial.success
            ? this._root.specialLocation.extractPath(initial.right)
            : null;
          const nextState = targetPath
            ? getStateFromPath(
                targetPath,
                MobileLinkingOptionsProviderImpl._CONFIG,
              )
            : DASHBOARD;
          if (tutorialType !== undefined) {
            this.initialState = InitialStateNavigationService._getInitialState(
              tutorialType,
              nextState,
            );
          }
          runInAction(() => (this._appNavigationIsReadyToRender = true));

          _.dispose();
        }
      },
    );
  }
}
