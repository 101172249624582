import {NotAllowedModalState} from './NotAllowedModalState';
import {action, observable, makeObservable} from 'mobx';

export default class NotAllowedModalStateImpl implements NotAllowedModalState {
  @observable private _isVisible = false;
  @observable private _description: string | undefined;

  constructor() {
    makeObservable(this);
  }

  get isVisible() {
    return this._isVisible;
  }

  get description() {
    return this._description;
  }

  setVisibleWithDescription = action((description: string | undefined) => {
    this._isVisible = true;
    this._description = description;
  });

  reset = action(() => {
    this._isVisible = false;
    this._description = '';
  });
}
