import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {useRoot, useStrings} from '../Root/hooks';
import {Picker} from '../components';
import {StyleSheet} from 'react-native';
import DualGraphPanel, {DualGraphPanelProps} from './DualGraphPanel';
import useSatoshiUsdRate from './useSatoshiUsdRate';
import StatisticsPanelState from './StatisticsPanelState';
import {useNavigation} from '@react-navigation/native';
import {StatisticsScreenProps} from '../StatisticsScreen/StatisticsScreen';
import {Millisecond} from '../utils/time';
import {ChartViewScope} from '../GraphPanel';

export interface StatisticsPanelProps extends Partial<DualGraphPanelProps> {
  groupId?: number;
  state: StatisticsPanelState;
}

export default observer(({groupId, state, ...rest}: StatisticsPanelProps) => {
  const navigation = useNavigation<StatisticsScreenProps['navigation']>();

  const {
    dashboardStore: {dashboardRequest},
  } = useRoot();
  const strings = useStrings();

  const secondaryAxisRatio = useSatoshiUsdRate();

  const onPick = useCallback(
    (id?: number | string) => {
      if (typeof id === 'number' || id === undefined) {
        navigation.setParams({groupId: id});
        return state.fetch(id);
      }
    },
    [navigation, state],
  );

  const onFromChange = useCallback(
    (from: Millisecond) => {
      state.onFromChange(from);
      return state.fetch(groupId);
    },
    [state, groupId],
  );
  const onScopeChange = useCallback(
    (scope: ChartViewScope) => {
      state.onScopeChange(scope);
      return state.fetch(groupId);
    },
    [state, groupId],
  );

  const picker = (
    <Picker
      style={styles.picker}
      defaultItemTitle={strings['statistics.miningAmountTable.filterDefault']}
      closeText={strings['action.cancel']}
      pickedItemId={groupId}
      items={
        dashboardRequest.value?.groups.map((_) => ({
          title: _.name,
          id: _.id,
        })) ?? []
      }
      onPick={onPick}
    />
  );

  return (
    <DualGraphPanel
      onFromChange={onFromChange}
      from={state.period.from}
      to={state.period.to}
      onScopeChange={onScopeChange}
      scope={state.scope}
      isLoading={state.isLoading}
      graph={state.graph}
      title={strings['statistics.miningAmountTable.title']}
      titleRight={picker}
      fractionDigits={0}
      primaryTitle="Satoshi"
      secondaryTitle="USD"
      secondaryAxisRatio={secondaryAxisRatio}
      secondaryFractionDigits={2}
      usdThreshold={0.05}
      {...rest}
    />
  );
});

const styles = StyleSheet.create({
  picker: {
    width: 160,
  },
});
