import {RefSearcher} from './RefSearcher';
import {Either, error} from '../fp';
import {LocationSource} from '../Location';
import {UrlParameterRefParserImpl} from '../RefParser';

/**
 * @example com.example://?ref=x.x.x.x -> x.x.x.x
 */
export default class UrlParameterRefSearcherImpl implements RefSearcher {
  private readonly _parser = new UrlParameterRefParserImpl();

  constructor(
    private readonly _root: {
      readonly locationSource: LocationSource;
    },
  ) {}

  async find(): Promise<Either<string, void>> {
    const link = await this._root.locationSource.getInitial();
    if (!link.success) {
      return error(undefined);
    }
    return this._parser.parse(link.right);
  }
}
