import {Spy} from './Spy';
import {StructuredTunnel, TunnelKind} from '../JsonRpc';
import {BusImpl, Disposer, Service} from '../structure';

export default class SpyService<Message = unknown>
  implements Spy<Message>, Service
{
  private readonly _incoming = new BusImpl<Message>();
  private readonly _outgoing = new BusImpl<Message>();

  constructor(private readonly _tunnel: StructuredTunnel<Message, Message>) {}

  get incoming() {
    return this._incoming;
  }

  get outgoing() {
    return this._outgoing;
  }

  readonly tunnel: StructuredTunnel<Message, Message> = {
    tunnelKind: TunnelKind.Structured,
    send: (message) => {
      this._outgoing.send(message);
      return this._tunnel.send(message);
    },
    listen: (listener) => {
      return this._tunnel.listen(listener);
    },
  };

  private _onMessage = (message: Message) => {
    this._incoming.send(message);
  };

  subscribe() {
    return this._tunnel.listen(this._onMessage) as Disposer;
  }
}
