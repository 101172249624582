import {Matrix, UnsavedMatrix} from './types';
import {cloneDeep} from 'lodash';

export default (A_: Matrix, B_: UnsavedMatrix): Matrix => {
  const A = cloneDeep(A_);
  const B = cloneDeep(B_);
  return A.map((row, i) =>
    row.map((_, j) => {
      const alternative = B[i][j];
      if (alternative === null) {
        return _;
      }
      return alternative;
    }),
  );
};
