import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {View, Text, ViewProps, StyleProp, TextStyle} from 'react-native';

import {createStylesHook, useTheme} from '../styling';
import negateColor from '../styling/util/negateColor';

export enum BadgeVariant {
  Primary,
  Secondary,
  New,
}

interface BadgeProps extends ViewProps {
  variant?: BadgeVariant;
  title: string;
  titleStyle?: StyleProp<TextStyle>;
}

export default observer((props: BadgeProps) => {
  const {style, titleStyle, ...rest} = props;
  const styles = useStyles();
  const theme = useTheme();
  const backgroundColor = useMemo(() => {
    switch (props.variant) {
      case BadgeVariant.Secondary:
        return theme.palette.primary;
      case BadgeVariant.Primary:
        return theme.palette.secondary;
      case BadgeVariant.New:
        return theme.palette.error;
    }
  }, [props.variant, theme]);
  return (
    <View style={[styles.root, {backgroundColor}, style]} {...rest}>
      <Text style={[styles.text, titleStyle]}>{props.title}</Text>
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    minHeight: 16,
    padding: 3,
  },
  text: {
    ...theme.fontByWeight('700'),
    color: theme.select(
      negateColor(theme.palette.textPrimary),
      theme.palette.textPrimary,
    ),
    fontSize: 10,
    lineHeight: 11,
  },
}));
