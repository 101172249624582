import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {ReadonlyDeep} from 'type-fest';
import {expr} from 'mobx-utils';
import isWorkerDisabled from '../../../checkers/isWorkerDisabled';
import {useWorkerState} from '../../../../WorkerStateRegistry';
import CpuTable from './CpuTable';
import SlotBackground from '../../../common/SlotBackground';
import {WorkerEntity} from '../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import isWorkerWarning from '../../../checkers/isWorkerWarning';
import SpeedControl from './SpeedControl';
import {createStylesHook} from '../../../../styling';
import {WORKER_DESKTOP_HEIGHT} from '../../../constants';
import {View, Text} from 'react-native';
import isWorkerOffline from '../../../checkers/isWorkerOffline';
import Characteristics from './Characteristics';
import {useNavigation} from '@react-navigation/native';
import {DashboardScreenProps} from '../../../DashboardScreenProps';
import {useForcedContext} from '../../../../context';
import {DashboardContext} from '../../../context';
import isNeedToReactivate from '../../../../PoolMinerActivator/isNeedToReactivate';
import SlotSetupButton from '../../../common/SlotSetupButton';
import Hashrate from '../../../common/Hashrate';
import {useRoot, useStrings} from '../../../../Root/hooks';
import {BtcAmount} from '../../../common/BtcAmount';
import WorkerHashrate from '../../../common/WorkerHashrate';

export interface WorkerSlotProps {
  worker: ReadonlyDeep<WorkerEntity>;
  index: number;
}

export default observer((props: WorkerSlotProps) => {
  const {goToActivator} = useForcedContext(DashboardContext);
  const navigation = useNavigation<DashboardScreenProps['navigation']>();
  const {worker, index} = props;
  const state = useWorkerState(worker.id);
  const disabled = expr(
    () => isWorkerDisabled(worker) || isWorkerOffline(state),
  );
  const goToDetails = useCallback(() => {
    navigation.navigate('WorkerDetails', {workerId: worker.id});
  }, [navigation, worker.id]);
  const warning = expr(() => isWorkerWarning(state));
  const styles = useStyles();
  const strings = useStrings();
  const expired = expr(() => isNeedToReactivate(worker));
  const handleSlotPress = useCallback(() => {
    if (expired) {
      goToActivator();
    }
  }, [goToActivator, expired]);

  const {dashboardStore} = useRoot();
  const balance = dashboardStore.getWorkerBalanceById(worker.id) / 1e12;
  const hashrate = useCallback(
    () => <WorkerHashrate worker={worker} />,
    [worker],
  );
  return (
    <SlotBackground
      isTouchable={expired}
      onPress={handleSlotPress}
      disabled={disabled}
      warning={warning}
      containerStyle={styles.root}
      style={[styles.container, warning && styles.warning]}>
      <View style={styles.content}>
        <View style={styles.info}>
          {index !== undefined && (
            <Text style={styles.index}>{index + 1}.</Text>
          )}
          <Characteristics
            worker={worker}
            disabled={disabled}
            warning={warning}
          />
        </View>
        <View style={styles.stats}>
          <View style={styles.speed}>
            <Text style={styles.label}>{strings['worker.minedSpeed']}:</Text>
            <Hashrate
              value={hashrate}
              style={styles.value}
              unitStyle={styles.value}
            />
            <SpeedControl
              worker={worker}
              onExpiredPress={goToDetails}
              hideTitle={true}
              disabled={disabled}
            />
          </View>
          <CpuTable worker={worker} warning={warning} />
        </View>

        <View style={styles.setupButton}>
          <View>
            <Text style={styles.label}>{strings['worker.minedTotal']}:</Text>
            <BtcAmount value={balance} style={styles.value} />
          </View>
          <SlotSetupButton worker={worker} />
        </View>
      </View>
    </SlotBackground>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    height: WORKER_DESKTOP_HEIGHT,
  },
  container: {
    padding: 20,
  },
  warning: {
    backgroundColor: theme.chroma(theme.palette.error).alpha(0.1).hex(),
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    gap: 50,
  },
  index: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    color: theme.palette.textSecondary,
  },
  label: {
    ...theme.fontByWeight('700'),
    fontSize: 12,
    lineHeight: 14,
    color: theme.palette.textSecondary,
    marginBottom: 10,
  },
  value: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 20,
  },
  info: {
    flexDirection: 'row',
    gap: 20,
    flexBasis: 250,
    alignItems: 'flex-start',
  },
  stats: {
    flexDirection: 'row',
    gap: 40,
    flexGrow: 1,
  },
  speed: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexBasis: 200,
  },
  setupButton: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexBasis: 250,
  },
}));
