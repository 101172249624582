import {useRoot} from '../Root/hooks';
import {useCallback} from 'react';
import {Either, error, success} from '../fp';
import {IAP_ERROR} from '../Error';
import {ErrorHandlerSample} from '../ExceptionHandler/useHandleErrorFactory';
import {
  ContextPurchaseResponse,
  PaymentType,
  PurchaseRequesterError,
} from './index';
import isGlobalError from '../Error/isGlobalError';

export type HookHandleErrorAndExtractPromoParams = {
  errorHandler: ErrorHandlerSample;
};

export default function useHandleErrorAndExtractPromo({
  errorHandler,
}: HookHandleErrorAndExtractPromoParams) {
  const root = useRoot();
  const {dashboardStore, purchaseDiscount, inAppOffers} = root;
  return useCallback(
    async (
      response: Either<ContextPurchaseResponse, PurchaseRequesterError>,
    ): Promise<Either<void, void>> => {
      if (!response.success) {
        if (
          (isGlobalError(response.left) && response.left.kind !== IAP_ERROR) ||
          response.left.code !== 'E_USER_CANCELLED'
        ) {
          errorHandler(response.left);
        }
        return error(undefined);
      }
      if (response.right.kind === PaymentType.InAppCompleted) {
        if (!purchaseDiscount.discount?.ttl) {
          purchaseDiscount.deleteDiscount();
        }
        try {
          await dashboardStore.fetch();
        } catch (ignore) {}

        if (response.right && response.right.promo_code) {
          await purchaseDiscount.verifyAndApplyCodeWithTtl(
            response.right.promo_code,
            response.right.promo_code_ttl,
          );
          inAppOffers.fetch();
        }
      }
      return success(undefined);
    },
    [dashboardStore, errorHandler, inAppOffers, purchaseDiscount],
  );
}
