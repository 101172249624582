import {SocketInstance} from '../../../lib/SocketInstance';
import {BalanceResponse} from '../entity/balance/BalanceResponse';
import {DashboardResponse} from '../entity/dashboard/DashboardResponse';
import {GetCurrentRateResponse} from '../entity/GetCurrentRateResponse';
import {GetMiningStatisticRequest} from '../entity/GetMiningStatisticRequest';
import {GetMiningStatisticResponse} from '../entity/GetMiningStatisticResponse';
import {GetAccountBalanceResponse} from '../entity/GetAccountBalanceResponse';
import {
  TemporaryTokenRequest,
  TemporaryTokenResponse,
} from '../entity/TemporaryToken';
import {GetDemoConfigResponse} from '../entity/GetDemoConfigResponse';
import {Client} from '../../../../src/JsonRpc';
import {WorkerGroupEntity} from '../entity/dashboard/worker/WorkerGroupEntity';
import {Configuration} from '../../../../src/Configuration';
import delayReject from '../../../../src/utils/delayReject';
import {Http} from '../../../../src/Http';
import HttpError from './HttpError';

/**
 * @deprecated
 */
export class SocketApiService {
  private readonly _socket: SocketInstance;

  private _connectedSocket;

  constructor(
    private readonly _root: {
      readonly http: Http;
      readonly configuration: Configuration;
    },
    private readonly _client: Client,
    private readonly _connectedClient: Client,
  ) {
    this._socket = new SocketInstance(this._client);
    this._connectedSocket = new SocketInstance(this._connectedClient);
  }

  async getTemporaryToken(
    request: TemporaryTokenRequest,
  ): Promise<TemporaryTokenResponse> {
    const url = `${this._root.configuration.values.tokenUrl}me/token/`;
    const body = JSON.stringify({login: ''});
    const response = await Promise.race([
      this._root.http.fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${request.token}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body,
      }),
      delayReject(
        this._root.configuration.values.cryptotabTimeout,
        () => new Error(`POST ${url} ${body} failed with timeout`),
      ),
    ]);
    if (response.ok) {
      return response.json();
    }
    throw HttpError.fromResponse(response);
  }

  async dashboard(): Promise<DashboardResponse> {
    const groups = await this._connectedSocket.send<WorkerGroupEntity[]>(
      'get_workers',
    );
    return {groups};
  }

  balance = (workerIds: string[]) =>
    this._connectedSocket.send<BalanceResponse>('get_total_balances', {
      worker_ids: workerIds,
    });

  deleteSchedule = (workerId: string) => {
    return this._connectedSocket.send<void>('delete_scheduler', {
      worker_id: workerId,
    });
  };

  getCurrentRate = () =>
    this._socket.send<GetCurrentRateResponse>('get_current_rate');

  getMiningStatistic = (request: GetMiningStatisticRequest) =>
    this._connectedSocket.send<GetMiningStatisticResponse>(
      'get_statistic',
      request,
    );

  getAccountBalance = () =>
    this._connectedSocket.send<GetAccountBalanceResponse>(
      'get_account_balance',
    );

  getDemoConfig = async () =>
    this._socket.send<GetDemoConfigResponse>('get_demo_config');
}
