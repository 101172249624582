import React, {useMemo} from 'react';
import {StyleProp, Text, TextStyle, View, ViewStyle} from 'react-native';
import {observer} from 'mobx-react-lite';
import {SwitchProps} from 'react-native-switch';

import Switch from '../../components/Switch';

import {useTheme} from '../../styling';

export interface ListItemProps extends SwitchProps {
  title: string;
  titleStyle?: StyleProp<TextStyle>;
  listContainerStyle?: StyleProp<ViewStyle>;
  bordered?: boolean;
}

export const ListItem = observer(
  ({
    title,
    titleStyle,
    listContainerStyle,
    bordered = true,
    disabled,
    ...rest
  }: ListItemProps) => {
    const theme = useTheme();
    const styles = useMemo(
      () =>
        ({
          root: {
            backgroundColor: theme.palette.background,
            paddingHorizontal: 20,
          },
          contentView: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            paddingVertical: 15,
            borderBottomColor: theme.palette.border,
          },
          titleText: {
            fontSize: 16,
            lineHeight: 18,
            letterSpacing: 0.04,
            color: theme.palette.textPrimary,
          },
          textView: {
            flexShrink: 1,
            marginRight: 10,
          },
          switchView: {},
        } as const),
      [theme],
    );
    const titleTextColor = disabled
      ? theme.palette.disabled
      : theme.palette.textPrimary;
    return (
      <View style={[styles.root, listContainerStyle]}>
        <View style={[styles.contentView, bordered && {borderBottomWidth: 1}]}>
          <View style={styles.textView}>
            <Text
              style={[styles.titleText, titleStyle, {color: titleTextColor}]}>
              {title}
            </Text>
          </View>
          <View style={styles.switchView}>
            <Switch
              disabled={disabled}
              backgroundActive={theme.palette.primary}
              {...rest}
            />
          </View>
        </View>
      </View>
    );
  },
);
