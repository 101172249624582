import React from 'react';
import {Picker} from './Picker';
import {StyleProp, ViewStyle} from 'react-native';

export interface DatePickerProps {
  isVisible: boolean;
  cancelTextIOS?: string;
  confirmTextIOS?: string;
  headerTextIOS?: string;
  minimumDate?: Date;
  maximumDate?: Date;
  isDarkModeEnabledMobile?: boolean;
  date?: Date;
  onConfirm(date: Date): void;
  onCancel(): void;
  styleWeb?: StyleProp<ViewStyle>;
}

export const DatePicker = (props: DatePickerProps) => {
  return <Picker {...props} />;
};
