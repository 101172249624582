import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';

import {ListItem, ListItemProps} from './ListItem';

import {useTheme} from '../../styling';

export const MainListItem = observer((props: ListItemProps) => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      ({
        root: {},
        titleText: {
          ...theme.fontByWeight('700'),
        },
      } as const),
    [theme],
  );
  return (
    <ListItem
      titleStyle={styles.titleText}
      listContainerStyle={styles.root}
      {...props}
    />
  );
});
