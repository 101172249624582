import {StyleProp, View, ViewProps, ViewStyle} from 'react-native';
import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';

import NextButton from './NextButton';
import {useStyles} from '../styling';

export type ContainerViewProps = ViewProps & {
  children?: React.ReactNode;
  contentStyle?: StyleProp<ViewStyle>;
  isHovered?: boolean;
};

export default observer<ContainerViewProps, View>(
  forwardRef((props, ref) => {
    const {contentStyle, children, style, isHovered, ...rest} = props;
    const styles = useStyles((theme) => ({
      root: {
        borderRadius: 15,
        borderWidth: 1,
        borderColor: theme.palette.border,
        backgroundColor: theme.palette.background,
        flexDirection: 'row',
        overflow: 'hidden',
      },
      content: {
        paddingVertical: 10,
        paddingHorizontal: 15,
        flexGrow: 1,
        flexShrink: 1,
      },
      rootHovered: {
        borderColor: theme.palette.primary,
      },
    }));
    return (
      <View
        ref={ref}
        style={[styles.root, isHovered && styles.rootHovered, style]}
        {...rest}>
        <View style={[styles.content, contentStyle]}>{children}</View>
        <NextButton isHovered={isHovered} />
      </View>
    );
  }),
);
