import {Server} from '../JsonRpc';
import {
  CryptoFarmConnectedServerCalls,
  CryptoFarmServerCalls,
  CryptoFarmServerNotifications,
} from '../ApiStore';
import {batchDisposers} from '../structure';
import {success} from '../fp';
import {DashboardController} from './DashboardController';

export default (
  server: Server<
    CryptoFarmServerCalls & CryptoFarmConnectedServerCalls,
    CryptoFarmServerNotifications
  >,
  dashboard: DashboardController,
) =>
  batchDisposers(
    server.call('connect', async (params, response, next) => {
      dashboard.connect(params.farm_id);
      next();
    }),
    server.call('get_workers', async (params, response) => {
      return response.respond(success(await dashboard.getWorkers()));
    }),
  );
