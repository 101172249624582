import {LogContent} from './LogContent';
import {MessageDescription} from '../Messaging';

export default class MessageContextLogContent implements LogContent {
  constructor(public readonly description: MessageDescription) {}

  get body() {
    return JSON.stringify(this.description, undefined, 2);
  }
}
