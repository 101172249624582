import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import ActionModal from '../modals/ActionModal';
import {colored, sized} from '../Svg';
import PoolMinerActivatedSvg from '../assets/svg/colored/poolMinerActivated.svg';
import HashratePlusSvg from '../assets/svg/colorless/hashratePlus.svg';
import {StyleSheet, View} from 'react-native';
import {useStyles} from '../styling';
import {useRoot, useStrings} from '../Root/hooks';
import {ButtonColor, ButtonVariant, FanSquareLogo} from '../components';
import {useTemplates} from '../Root/hooks';
import {ModalDescription} from '../modals/components';
import LimitedDiscount from '../PurchaseScreen/PoolMinersTab/components/LimitedDiscount';
import useCheckDiscount from '../PurchaseScreen/hooks/useCheckDiscount';
import {ModalButton} from '../modals/components/ModalButtonList';
import {ISODateString} from '../Time';
import dayjs from 'dayjs';

export type PoolMinerActivatedModalProps = {
  onModalClosed: () => void;
  onDismiss: () => void;
  onSubmit: () => void;
  hashrate?: number;
  expireDate?: ISODateString;
};

export default observer(
  ({
    onModalClosed,
    onDismiss,
    onSubmit,
    hashrate,
    expireDate,
  }: PoolMinerActivatedModalProps) => {
    const {
      purchaseDiscount,
      translation: {localeTag},
    } = useRoot();
    const strings = useStrings();
    const templates = useTemplates();
    const text = templates['poolMinerActivated.desc']({
      hashrate: hashrate,
      expireDate: dayjs(expireDate).locale(localeTag).format('DD MMM YYYY'),
    });
    const {getExpirationSeconds} = useCheckDiscount();
    const visibleDiscountSection = useMemo(() => {
      return (
        getExpirationSeconds() > 0 && purchaseDiscount.discount !== undefined
      );
    }, [getExpirationSeconds, purchaseDiscount.discount]);
    const buttons: ModalButton[] = useMemo(() => {
      if (visibleDiscountSection) {
        return [
          {
            text: strings['purchase.poolMiners.title'],
            onPress: onDismiss,
            variant: ButtonVariant.Highlighted,
            color: ButtonColor.Success,
            Icon: HashratePlusIcon,
          },
          {
            text: strings['screenTitle.dashboard'],
            onPress: onSubmit,
            variant: ButtonVariant.Highlighted,
          },
        ];
      } else {
        return [
          {
            text: strings['screenTitle.dashboard'],
            onPress: onSubmit,
            variant: ButtonVariant.Highlighted,
          },
        ];
      }
    }, [onDismiss, onSubmit, strings, visibleDiscountSection]);
    return (
      <ActionModal
        onDismiss={onModalClosed}
        buttons={buttons}
        buttonsDirection="column"
        title={strings['poolMinerActivated.title']}
        Icon={PoolMinerIcon}>
        <ModalDescription>{text}</ModalDescription>
        {visibleDiscountSection && (
          <LimitedDiscount
            style={styles.discountSection}
            onTimeOver={purchaseDiscount.deleteDiscount}
            initialSeconds={getExpirationSeconds()}
          />
        )}
      </ActionModal>
    );
  },
);

export const PoolMinerIcon = observer(() => {
  const styles = useStyles((theme) => ({
    root: {
      flexDirection: 'row',
    },
    logo: {
      marginRight: -30,
      marginTop: 3,
    },
    pool: {
      padding: 3,
      backgroundColor: theme.palette.background,
      borderRadius: 30,
    },
  }));
  return (
    <View style={styles.root}>
      <FanSquareLogo style={styles.logo} width={60} height={60} />
      <View style={styles.pool}>
        <PoolMiner />
      </View>
    </View>
  );
});

const HashratePlusIcon = colored(sized(HashratePlusSvg, 33, 18), '#fff');

const styles = StyleSheet.create({
  discountSection: {
    width: '100%',
    marginVertical: 10,
  },
});

const PoolMiner = sized(PoolMinerActivatedSvg, 60, 60);
