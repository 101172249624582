import {ReadonlyDeep} from 'type-fest';
import {
  SlotStatus,
  WorkerEntity,
  WorkerType,
} from '../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {observer} from 'mobx-react-lite';
import {useStrings} from '../../../Root/hooks';
import {Text, View} from 'react-native';
import {createStylesHook, useTheme} from '../../../styling';
import React from 'react';
import {expr} from 'mobx-utils';
import getHugePagesColor from '../../getters/getHugePagesColor';
import {useWorkerState} from '../../../WorkerStateRegistry';
import isWorkerOffline from '../../checkers/isWorkerOffline';
import {PressableOpacity} from '../../../components';

export interface WorkerHugePagesProps {
  worker: ReadonlyDeep<WorkerEntity>;
  onExpiredPress?: () => void;
}

export default observer((props: WorkerHugePagesProps) => {
  const {worker, onExpiredPress} = props;
  const strings = useStrings();
  const styles = useStyles();
  const theme = useTheme();
  const state = useWorkerState(worker.id);
  const offline = expr(() => isWorkerOffline(state));
  const hugePagesColor = expr(() => getHugePagesColor(worker, theme, state));

  if (worker.worker_type === WorkerType.Pool && worker.expired) {
    return (
      <PressableOpacity
        style={[styles.badgeView, styles.badgeViewError]}
        onPress={onExpiredPress}
        disabled={onExpiredPress === undefined}>
        <View style={[styles.dot, styles.dotError]} />
        <Text style={styles.badgeText}>{strings['worker.expired']}</Text>
      </PressableOpacity>
    );
  }

  if (worker.slot_status === SlotStatus.Inactive) {
    return (
      <View style={[styles.badgeView, styles.badgeViewError]}>
        <View style={[styles.dot, styles.dotError]} />
        <Text style={styles.badgeText}>{strings['worker.inactive']}</Text>
      </View>
    );
  }

  if (offline) {
    return (
      <View style={[styles.badgeView, styles.badgeViewError]}>
        <View style={[styles.dot, styles.dotError]} />
        <Text style={styles.badgeText}>{strings['worker.offline']}</Text>
      </View>
    );
  }

  return (
    <View style={styles.badgeView}>
      <View style={[styles.dot, {backgroundColor: hugePagesColor}]} />
      <Text style={styles.badgeText}>HUGE PAGES</Text>
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  badgeView: {
    height: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 0,
    paddingHorizontal: 6,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: theme.palette.border,
    backgroundColor: theme.palette.background,
  },
  badgeViewError: {
    borderColor: theme.palette.error,
    borderWidth: 1,
  },
  dot: {
    borderRadius: 3,
    width: 6,
    height: 6,
    marginRight: 3,
    backgroundColor: theme.palette.icon,
  },
  dotError: {
    backgroundColor: theme.palette.error,
  },
  badgeText: {
    ...theme.fontByWeight('700'),
    fontSize: 10,
    letterSpacing: 0.03,
    color: theme.palette.textSecondary,
    textTransform: 'uppercase',
  },
}));
