import restoreFernetToken from './restoreFernetToken';
import {ApiStore} from '../ApiStore';
import {
  Authenticated,
  AuthenticationErrorReason,
  AuthenticationFailed,
  AuthenticationSummary,
} from './AuthState';
import {setFernetToken} from '../persistence';
import {DeviceIdentification} from '../DeviceIdentification';
import {InAppPurchaseManager} from '../InAppPurchaseManager';
import {SpecialLocation} from '../SpecialLocation';
import {LocationSource} from '../Location';

/**
 * @throws {never}
 */
export default async (
  root: {
    readonly deviceIdentification: DeviceIdentification;
    readonly apiStore: ApiStore;
    readonly inAppPurchaseManager: InAppPurchaseManager;
    readonly specialLocation: SpecialLocation;
    readonly locationSource: LocationSource;
  },
  options?: {
    forceRegister?: boolean;
  },
): Promise<Authenticated | AuthenticationFailed> => {
  const fernetToken_ = await restoreFernetToken(root, options);
  if (!fernetToken_.success) {
    if (
      fernetToken_.left.reason ===
      AuthenticationErrorReason.AccountSwitchRequired
    ) {
      return {
        kind: 'AuthenticationFailed',
        reason: AuthenticationErrorReason.AccountSwitchRequired,
        raw: null,
        restoredSubscriptionMap: fernetToken_.left.restoredSubscriptionMap,
      };
    }
    return {
      kind: 'AuthenticationFailed',
      reason: fernetToken_.left.reason,
      raw: fernetToken_.left.raw,
    };
  }
  const {summary, fernetToken} = fernetToken_.right;
  if (summary !== AuthenticationSummary.LocalStorage) {
    await setFernetToken(fernetToken);
  }
  return {
    kind: 'Authenticated',
    fernetToken,
    summary,
  };
};
