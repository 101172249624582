import React from 'react';
import {observer} from 'mobx-react-lite';
import {Button, ButtonVariant, PressableOpacity} from '../../components';
import {useStrings} from '../../Root/hooks';
import {PressableOpacityProps} from '../../components/PressableOpacity';
import {variance} from '../../styling';

export type TableSelectButtonProps = PressableOpacityProps & {};

export default observer(({disabled, ...rest}: TableSelectButtonProps) => {
  const strings = useStrings();
  return (
    <Container disabled={disabled} {...rest}>
      <Button
        variant={disabled ? ButtonVariant.Disabled : ButtonVariant.Highlighted}
        style={{borderRadius: 10}}>
        {strings['action.select']}
      </Button>
    </Container>
  );
});

const Container = variance(PressableOpacity)((theme) => ({
  root: {
    ...theme.mediaQuery({
      1000: {
        maxWidth: 400,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
  },
}));
