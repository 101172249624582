import React from 'react';
import {Text, View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useStyles} from '../../styling';
import {PressableOpacity} from '../../components';
import {Hoverable} from 'react-native-web-hooks';
import LoaderIndicator from '../../components/LoaderIndicator';

export type DropdownMenuItemProps = {
  isLast?: boolean;
  component?: React.ReactNode;
  title?: string;
  onPress?: () => void;
  loading?: boolean;
};

export default observer(function DropdownMenuItem({
  title,
  component,
  onPress,
  isLast,
  loading,
}: DropdownMenuItemProps) {
  const styles = useStyles((theme) => ({
    borderedRoot: {
      borderBottomWidth: 1,
      borderColor: theme.palette.border,
    },
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 20,
      paddingVertical: 17,
    },
    hoveredRoot: {
      backgroundColor: theme.palette.primary,
      cursor: 'pointer',
      borderColor: 'transparent',
    },
    text: {
      ...theme.fontByWeight('normal'),
      fontSize: 16,
      lineHeight: 18,
      letterSpacing: 0.045,
      color: theme.palette.textPrimary,
    },
    hoveredText: {
      color: '#ffffff',
    },
    lastRoot: {
      borderBottomLeftRadius: 3,
      borderBottomRightRadius: 3,
    },
    loaderView: {
      marginLeft: 5,
    },
  }));
  if (title === undefined && component === undefined) {
    return null;
  }
  if (component) {
    return (
      <View style={[styles.borderedRoot, isLast && styles.lastRoot]}>
        {component}
      </View>
    );
  } else if (title) {
    return (
      <Hoverable>
        {(isHovered) => (
          <PressableOpacity disabled={loading} onPress={onPress}>
            <View
              style={[
                styles.root,
                styles.borderedRoot,
                isHovered && styles.hoveredRoot,
                isLast && styles.lastRoot,
              ]}>
              <Text style={[styles.text, isHovered && styles.hoveredText]}>
                {title}
              </Text>
              {loading && (
                <View style={styles.loaderView}>
                  <LoaderIndicator />
                </View>
              )}
            </View>
          </PressableOpacity>
        )}
      </Hoverable>
    );
  }
  return null;
});
