import {
  GET_PRODUCTS,
  GET_PRODUCTS_REQUEST,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_REQUEST,
} from './InAppPurchase';
import {PurchaseId} from '../units';
import InAppPurchaseImpl from './InAppPurchaseImpl';
import {Product, Subscription} from 'react-native-iap';
import {cloneDeep, difference, intersection} from 'lodash';

export default class CachedInAppPurchaseImpl extends InAppPurchaseImpl {
  private _cachedSubscriptions = new Map<PurchaseId, Subscription>();
  private _cachedProducts = new Map<PurchaseId, Product>();

  private async _magicCache<I extends Product | Subscription>(
    cachedMap: Map<PurchaseId, I>,
    skus: PurchaseId[],
    request: (skus: PurchaseId[]) => Promise<I[]>,
    onRequest: () => void,
    onResult: (cached: I[], response: I[]) => void,
  ): Promise<I[]> {
    const cachedIds = Array.from(cachedMap.keys());
    const cachedIdsResult = intersection(cachedIds, skus); // ([2, 1], [2, 3]); => [2]
    const restored = cachedIdsResult.flatMap((_) => {
      const candidate = cachedMap.get(_);
      if (candidate) {
        return [candidate];
      }
      return [];
    });
    onRequest();
    const notLoadedIds = difference(skus, cachedIds); // [2, 1], [2, 3]); => [1]
    const fetchedItems = notLoadedIds.length ? await request(notLoadedIds) : [];

    for (const item of fetchedItems) {
      cachedMap.set(item.productId as PurchaseId, item);
    }
    const result = [...cloneDeep(restored), ...fetchedItems];
    onResult(restored, fetchedItems);
    return result;
  }

  async getSubscriptions(skus: PurchaseId[]) {
    return this._magicCache<Subscription>(
      this._cachedSubscriptions,
      skus,
      super.getSubscriptions,
      () => this.events.send(GET_SUBSCRIPTIONS_REQUEST, skus),
      (cached, response) =>
        this.events.send(GET_SUBSCRIPTIONS, {
          cache: cached,
          response,
        }),
    );
  }

  async getProducts(skus: PurchaseId[]) {
    return this._magicCache<Product>(
      this._cachedProducts,
      skus,
      super.getProducts,
      () => this.events.send(GET_PRODUCTS_REQUEST, skus),
      (cached, response) =>
        this.events.send(GET_PRODUCTS, {
          cache: cached,
          response,
        }),
    );
  }
}
