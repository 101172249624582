import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {WorkerStateRegistry} from '../../WorkerStateRegistry';
import getTotalOfflineWorkers from './getTotalOfflineWorkers';

export default function getTotalOnlineWorkers(
  workers: readonly ReadonlyDeep<WorkerEntity>[],
  workerStateRegistry: WorkerStateRegistry,
): number {
  const totalOfflineWorkers = getTotalOfflineWorkers(
    workers,
    workerStateRegistry,
  );
  return workers.length - totalOfflineWorkers;
}
