import {Palette} from './Coloring';

const palette: Palette = {
  background: '#fff', // фон
  foreground: '#000', // по фону
  primary: '#f7931a', // контрастные цвета
  additionalPrimary: '#FFF5E9',
  secondary: '#4285f4', // контрастные цвета
  success: '#37cc33', // семантическое подсвечивание контента
  additionalSuccess: '#ECFFEC',
  info: '#2196f3', // семантическое подсвечивание контента,
  additionalInfo: '#F0F6FF',
  error: '#f74249', // семантическое подсвечивание контента,
  purple: '#A033CC',
  additionalPurple: '#FBF2FF',
  warning: '#ffd200', // семантическое подсвечивание контента
  highlight: '#000000ee', // #111  // абстрактная подсветка
  disabled: '#00000033', // #ccc // заблокирован кмопонент (любой)
  textPrimary: '#000000ee', // #111 // для текста
  textSecondary: '#00000075', // #8a8a8a // для текста
  backdrop: '#00000010', // #efefef // для визуализации вложенности компонентов (относительный цвет)
  lift: '#ffffffaf', // #fafafa // отменяет эффект вложенности (относительный цвет)
  base: '#efefef', // абсолютный аналог backdrop который нарисовали поверх background (background + backdrop)
  bar: '#ffffff', // например для бара
  back: '#F7F7F7',
  staticLightBlue: '#1AB9E5',
  staticAdditionalLightBlue: '#E2F9FF',
  border: '#d6d6d6', // #e4e4e4 // относительный цвет для границ
  icon: '#000000b5', // #4a4a4a // относительный цвет для иконок
  dark: '#25272a',
  white: '#ffffff',
  black: '#000000',
  /**
   * @deprecated use textSecondary instead!
   */
  darkText: '#494949',
  cyan: '#C6F3FF',
};

export default palette;
