import {RouterSource} from '../structure';

export interface AppAnalytics {
  readonly updates: RouterSource<AppAnalyticsRouterMap>;
}

export type AppAnalyticsRouterMap = Record<AppEvent, AppEvent>;

export type AppEvent =
  | typeof FIRST_DEMO_MINER_ADDED
  | typeof FIRST_MINER_ADDED
  | typeof FIRST_GET_MINER_ID
  | typeof FIRST_PURCHASE_MADE
  | typeof FIRST_EVENT_MADE;

export const FIRST_DEMO_MINER_ADDED = Symbol();
export const FIRST_MINER_ADDED = Symbol();
export const FIRST_GET_MINER_ID = Symbol();
export const FIRST_PURCHASE_MADE = Symbol();
export const FIRST_EVENT_MADE = Symbol();
