import React, {useCallback, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {RenewAccessScreen} from '../../RenewAccessScreen';
import {useRoot} from '../../Root/hooks';
import {CommonActions} from '@react-navigation/native';
import {DASHBOARD} from './constants';
import {
  useGoToPoolMinerActivatedCallback,
  useSafelyGoToAffiliate,
} from '../hooks';
import {nanoid} from 'nanoid';
import {useOpenAffiliateOnUniqueToken} from './WithdrawalHistoryBinding';
import {
  NavigationState,
  PartialState,
} from '@react-navigation/routers/src/types';
import {RootParamList} from './RootStack';
import {WorkerId} from '../../ApiStore';
import {AffiliateVariant} from '../hooks/useSafelyGoToAffiliate';

export type RenewAccessBindingProps = RootStackBindingProps<'RenewAccess'>;

export const RenewAccessBinding = observer((props: RenewAccessBindingProps) => {
  const {route, navigation} = props;
  const root = useRoot();
  const {dashboardStore} = root;
  const workerId = route.params.workerId;
  const worker = dashboardStore.workers.get(workerId);
  const goToPoolPurchase = useCallback(() => {
    navigation.replace('Plan', {index: 1});
  }, [navigation]);
  const goToPurchase = useCallback(() => {
    navigation.replace('Plan');
  }, [navigation]);
  const goBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.dispatch(CommonActions.reset(DASHBOARD));
    }
  }, [navigation]);
  const goToAuthAndThenOpenAffiliate = useCallback(
    () =>
      navigation.navigate('Auth', {
        onSuccess: createRenewAccessState(workerId, nanoid()),
        variant: 'promo',
      }),
    [navigation, workerId],
  );
  const {safelyGoToAffiliate, getAffiliateIsPending} = useSafelyGoToAffiliate(
    goToAuthAndThenOpenAffiliate,
    AffiliateVariant.Promo,
  );
  const {goToAffiliateToken} = route.params ?? {};
  useOpenAffiliateOnUniqueToken(goToAffiliateToken, safelyGoToAffiliate);
  useEffect(() => {
    if (!worker && !dashboardStore.isLoading) {
      goBack();
    }
  }, [dashboardStore.isLoading, goBack, worker]);
  const goToPoolMinerActivated = useGoToPoolMinerActivatedCallback((params) =>
    navigation.navigate('PoolMinerActivated', params),
  );
  return (
    <RenewAccessScreen
      workerId={workerId}
      goToPoolPurchase={goToPoolPurchase}
      goToPoolMinerActivated={goToPoolMinerActivated}
      goToPurchase={goToPurchase}
      safelyGoToAffiliate={safelyGoToAffiliate}
      getAffiliateIsPending={getAffiliateIsPending}
    />
  );
});

export const createRenewAccessState = (
  workerId: WorkerId,
  goToAffiliateToken?: string,
): PartialState<NavigationState<RootParamList>> => {
  return {
    index: 2,
    routes: [
      {
        name: 'Root',
        state: {
          index: 0,
          routes: [
            {
              name: 'Dashboard',
            },
          ],
        },
      },
      {
        name: 'WorkerDetails',
        params: {workerId},
      },
      {
        name: 'RenewAccess',
        params: {goToAffiliateToken, workerId},
      },
    ],
  };
};
