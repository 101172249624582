import {useCallback, useState} from 'react';
import {LayoutChangeEvent, LayoutRectangle} from 'react-native';

export default (): [
  LayoutRectangle | undefined,
  (event: LayoutChangeEvent) => void,
] => {
  const [layout, setLayout] = useState<LayoutRectangle | undefined>(undefined);
  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setLayout(event.nativeEvent.layout);
  }, []);
  return [layout, onLayout];
};
