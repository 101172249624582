import React, {ReactNode} from 'react';
import {observer} from 'mobx-react-lite';
import {ButtonVariant, FanSquareLogo} from '../components';
import {useRoot, useStrings} from '../Root/hooks';
import ActionModal from '../modals/ActionModal';
import {useStyles} from '../styling';
import {Text, View} from 'react-native';
import {openLink} from '../Links';
import {sized} from '../Svg';
import Markdown from '../components/Markdown';

export type ClipboardWorkerIdSuccessModalProps = {
  id: string;
  title: string;
  description: string;
  onDismiss: () => void;
};

export default observer(function ClipboardWorkerIdSuccessModal({
  onDismiss,
  title,
  description,
  id,
}: ClipboardWorkerIdSuccessModalProps) {
  const strings = useStrings();
  const {configuration} = useRoot();
  const styles = useStyles((theme) => ({
    minerIdTitle: {
      ...theme.fontByWeight('400'),
      color: theme.palette.textPrimary,
      fontSize: 15,
      marginBottom: 5,
    },
    idView: {
      marginBottom: 7,
      padding: 8,
    },
    id: {
      ...theme.fontByWeight('bold'),
      color: theme.palette.textPrimary,
      fontSize: 29,
    },
    paragraph: {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 14,
      lineHeight: 22,
      textAlign: 'center',
      letterSpacing: 0.04,
      color: theme.palette.textPrimary,
      ...theme.fontByWeight('400'),
      paddingVertical: 0,
      paddingHorizontal: 15,
      ...theme.mediaQuery({
        1000: {
          fontSize: 15,
          lineHeight: 18,
        },
      }),
    },
    textLink: {
      ...theme.fontByWeight('400'),
      fontStyle: 'normal',
      color: theme.palette.secondary,
    },
    link: {
      ...theme.fontByWeight('bold'),
      color: theme.palette.secondary,
      fontStyle: 'normal',
      textDecorationLine: 'none',
    },
  }));
  const rules = {
    em: (key: number, children: ReactNode) => {
      return (
        <Text
          key={key}
          onPress={() => openLink(configuration.values.downloadUrl)}>
          {children}
        </Text>
      );
    },
  };
  return (
    <ActionModal
      buttons={[
        {
          text: strings['action.ok'],
          onPress: onDismiss,
          variant: ButtonVariant.Highlighted,
        },
      ]}
      Icon={Fan}
      title={title}
      onDismiss={onDismiss}>
      <Text style={styles.minerIdTitle}>{strings['worker.minerId']}:</Text>
      <View style={styles.idView}>
        <Text style={styles.id}>{id}</Text>
      </View>
      <Markdown
        rules={rules}
        styles={{
          paragraph: styles.paragraph,
          em: styles.textLink,
          link: styles.link,
        }}>
        {description}
      </Markdown>
    </ActionModal>
  );
});

const Fan = sized(FanSquareLogo, 60);
