import React from 'react';
import {StyleSheet, View, ViewProps} from 'react-native';
import {observer} from 'mobx-react-lite';

import {PrimaryMenuItem} from './PrimaryMenuItem';
import {useStrings} from '../Root/hooks';
import {expr} from 'mobx-utils';

export interface MenuListPropsBase {
  goToPlan: () => void;
  goToWithdraw: () => void;
  goToFarmSwitch: () => void;
  getAffiliateIsPending: () => boolean;
  safelyGoToAffiliate: () => void;
  goToWithdrawalHistory: () => void;
  goToTutorial: () => void;
  goToDescription: () => void;
  goToQRCodeScanner: () => void;
  qrCodeScannerDisabled?: boolean;
}

export interface MenuListProps extends MenuListPropsBase, ViewProps {}

export default observer((props: MenuListProps) => {
  const {
    goToPlan,
    goToWithdraw,
    goToFarmSwitch,
    getAffiliateIsPending,
    safelyGoToAffiliate,
    goToWithdrawalHistory,
    goToTutorial,
    goToDescription,
    goToQRCodeScanner,
    style,
    ...rest
  } = props;
  const strings = useStrings();
  return (
    <View style={[insets.menuContainer, style]} {...rest}>
      <PrimaryMenuItem
        active
        text={strings['menu.purchases']}
        onPress={goToPlan}
      />
      <PrimaryMenuItem
        text={strings['menu.withdrawBtc']}
        onPress={goToWithdraw}
      />
      <PrimaryMenuItem
        text={strings['menu.switchFarm']}
        onPress={goToFarmSwitch}
      />
      <PrimaryMenuItem
        text={strings['menu.qrCodeScanner']}
        onPress={goToQRCodeScanner}
      />
      <AffiliateMenuItem
        getAffiliateIsPending={getAffiliateIsPending}
        safelyGoToAffiliate={safelyGoToAffiliate}
      />
      <PrimaryMenuItem
        onPress={goToWithdrawalHistory}
        text={strings['menu.paymentsHistory']}
      />
      <PrimaryMenuItem onPress={goToTutorial} text={strings['menu.tutorial']} />
      <PrimaryMenuItem text={strings['menu.about']} onPress={goToDescription} />
    </View>
  );
});

const insets = StyleSheet.create({
  menuContainer: {
    paddingHorizontal: 20,
  },
});

export interface AffiliateMenuItemProps {
  getAffiliateIsPending: () => boolean;
  safelyGoToAffiliate: () => void;
}

export const AffiliateMenuItem = observer((props: AffiliateMenuItemProps) => {
  const {getAffiliateIsPending, safelyGoToAffiliate} = props;
  const strings = useStrings();
  const isPending = expr(getAffiliateIsPending);
  return (
    <PrimaryMenuItem
      text={strings['menu.affiliate']}
      loading={isPending}
      disabled={isPending}
      onPress={safelyGoToAffiliate}
    />
  );
});
