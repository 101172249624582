import React, {useCallback, useMemo, useRef} from 'react';
import {DimensionValue, Text, View} from 'react-native';
import {SecondaryMenuItem} from './SecondaryMenuItem';
import {useRoot, useStrings} from '../Root/hooks';
import {observer} from 'mobx-react-lite';
import {Locale} from '../Preferences';
import DeFlag from '../assets/svg/flags/de.svg';
import EsFlag from '../assets/svg/flags/es.svg';
import FrFlag from '../assets/svg/flags/fr.svg';
import GbFlag from '../assets/svg/flags/gb.svg';
import ItFlag from '../assets/svg/flags/it.svg';
import PtBrFlag from '../assets/svg/flags/br.svg';
import RuFlag from '../assets/svg/flags/ru.svg';
import ArrowSvg from '../assets/svg/colorless/triangle.svg';
import {PressableOpacity} from '../components';
import {SvgProps} from 'react-native-svg';
import {createStylesHook, useTheme} from '../styling';
import {useBoolean} from '../hooks';
import {Hoverable} from 'react-native-web-hooks';
import useOutside from '../../universal/utils/react/hook/useOutside.web';
import * as Animatable from 'react-native-animatable';

type DropdownLanguageProps = {
  hideLabel?: boolean;
};

type LocalePickerItem = {
  id: Locale;
  title: string;
  shortTitle: string;
  icon: (props: SvgProps) => JSX.Element;
};

const items = [
  {
    id: Locale.English,
    title: 'English',
    shortTitle: 'EN',
    icon: GbFlag,
  },
  {
    id: Locale.French,
    title: 'French',
    shortTitle: 'FR',
    icon: FrFlag,
  },
  {
    id: Locale.German,
    title: 'German',
    shortTitle: 'DE',
    icon: DeFlag,
  },
  {
    id: Locale.Italian,
    title: 'Italian',
    shortTitle: 'IT',
    icon: ItFlag,
  },
  {
    id: Locale.PortugueseBrazil,
    title: 'Portuguese (Brazil)',
    shortTitle: 'BR',
    icon: PtBrFlag,
  },
  {
    id: Locale.Russian,
    title: 'Russian',
    shortTitle: 'RU',
    icon: RuFlag,
  },
  {
    id: Locale.Spanish,
    title: 'Spanish',
    shortTitle: 'ES',
    icon: EsFlag,
  },
] as unknown as LocalePickerItem[];

export const DropdownLanguage = observer((props: DropdownLanguageProps) => {
  const {translation, preferences} = useRoot();
  const theme = useTheme();
  const [visibleMenu, showMenu, hideMenu] = useBoolean(false);
  const toggleMenu = visibleMenu ? hideMenu : showMenu;
  const strings = useStrings();
  const styles = useStyles();
  const {shortTitle, icon: Icon} = useMemo(
    () => items.find((item) => item.id === translation.locale) || items[0],
    [translation.locale],
  );

  const handleChange = (id: Locale) => {
    preferences.setLocale(id);
    hideMenu();
  };

  const wrapperRef = useRef<View>(null);
  useOutside(wrapperRef, hideMenu);

  return (
    <View style={styles.container}>
      {!props.hideLabel && (
        <SecondaryMenuItem disabled text={strings['menu.language']} />
      )}
      <View ref={wrapperRef}>
        <PressableOpacity activeOpacity={1} onPress={toggleMenu}>
          <Hoverable>
            {(isHovered) => (
              <View
                style={[
                  styles.dropdownView,
                  isHovered && styles.dropdownViewHovered,
                ]}>
                <Icon width={16} height={11} />
                <Text
                  style={[
                    styles.dropdownText,
                    visibleMenu && styles.dropdownTextActive,
                    isHovered && styles.dropdownTextHovered,
                  ]}>
                  {shortTitle}
                </Text>
                <ArrowSvg
                  color={
                    isHovered
                      ? '#ffffff'
                      : visibleMenu
                      ? theme.palette.secondary
                      : theme.chroma('#000000').luminance(0.1).hex()
                  }
                  rotation={visibleMenu ? 180 : 0}
                />
              </View>
            )}
          </Hoverable>
        </PressableOpacity>
        {visibleMenu && (
          <Animatable.View
            animation="fadeInUp"
            duration={300}
            style={styles.menu}>
            <View style={styles.listView}>
              {items.map((item) => (
                <Item onPress={handleChange} locale={item} />
              ))}
            </View>
          </Animatable.View>
        )}
      </View>
    </View>
  );
});

type ItemProps = {
  onPress: (id: Locale) => void;
  locale: LocalePickerItem;
};

const Item = (props: ItemProps) => {
  const {onPress, locale} = props;
  const {shortTitle, icon: Icon} = locale;
  const handlePress = useCallback(() => {
    onPress(locale.id);
  }, [onPress, locale]);
  const styles = useStyles();
  return (
    <PressableOpacity onPress={handlePress}>
      <Hoverable>
        {(isHovered) => (
          <View style={[styles.itemView, isHovered && styles.itemViewHovered]}>
            <Icon width={25} height={20} />
            <Text
              style={[styles.itemText, isHovered && styles.itemTextHovered]}>
              {shortTitle}
            </Text>
          </View>
        )}
      </Hoverable>
    </PressableOpacity>
  );
};

const useStyles = createStylesHook((theme) => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dropdownView: {
    backgroundColor: theme.chroma('#000000').brighten(0.6).hex(),
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    padding: 8,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.chroma('#000000').brighten(0.9).hex(),
  },
  dropdownText: {
    ...theme.fontByWeight('700'),
    fontSize: 12,
    color: theme.select(
      theme.contrast(theme.palette.textPrimary),
      theme.palette.textPrimary,
    ),
  },
  dropdownTextHovered: {
    color: '#ffffff',
  },
  dropdownTextActive: {
    color: theme.palette.secondary,
  },
  dropdownViewHovered: {
    backgroundColor: theme.palette.primary,
  },
  listView: {
    borderWidth: 1,
    borderColor: theme.chroma('#000000').brighten(0.9).hex(),
    borderRadius: 6,
  },
  itemView: {
    backgroundColor: theme.chroma('#000000').brighten(0.6).hex(),
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    padding: 8,
  },
  itemViewHovered: {
    backgroundColor: theme.palette.secondary,
  },
  itemText: {
    ...theme.fontByWeight('700'),
    fontSize: 12,
    color: theme.select(
      theme.contrast(theme.palette.textPrimary),
      theme.palette.textPrimary,
    ),
  },
  itemTextHovered: {},
  menu: {
    position: 'absolute',
    left: 0,
    bottom: 'calc(100% + 5px)' as DimensionValue,
    right: 0,
    borderRadius: 6,
  },
}));
