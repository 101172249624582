import React, {useCallback, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {PurchaseScreen} from '../../PurchaseScreen';
import {RootStackBindingProps} from './RootStackBindingProps';
import {
  useNavigationGetIsFocused,
  useResetToDashboard,
  useSafelyGoToAffiliate,
} from '../hooks';
import {DASHBOARD} from './constants';
import {nanoid} from 'nanoid';
import {
  NavigationState,
  PartialState,
} from '@react-navigation/routers/src/types';
import {RootParamList} from './RootStack';
import {useOpenAffiliateOnUniqueToken} from './WithdrawalHistoryBinding';
import {AffiliateVariant} from '../hooks/useSafelyGoToAffiliate';
import {useBoolean} from '../../hooks';
import {useRoot} from '../../Root/hooks';
import useCheckDiscount from '../../PurchaseScreen/hooks/useCheckDiscount';
import {autorun, reaction} from 'mobx';
import {PurchaseTabsKind} from '../../PurchaseScreen/states/PurchaseScreenState';
import {ISODateString} from '../../Time';
import {OfferPurchaseType} from '../../InAppOffersService';
import {
  useCheckCanMinerBuy,
  usePurchasePoolMiner,
  usePurchaseSlotOffer,
} from '../../PurchaseRequester';
import {useHandleErrorAndNavigate} from '../../ExceptionHandler';

export type SubscriptionBindingProps = RootStackBindingProps<'Plan'>;

export default observer(({navigation, route}: SubscriptionBindingProps) => {
  const initialTabNavigationIndex = route.params?.index ?? 0;
  const [needSlotsVisible, showNeedSlotsModal, hideNeedSlotsModal] =
    useBoolean(false);

  const [
    visiblePickHashrateModal,
    showPickHashrateModal,
    hidePickHashrateModal,
  ] = useBoolean(false);

  const [
    visibleSuccessSubscriptionModal,
    openSuccessSubscriptionModal,
    hideSuccessSubscriptionModal,
  ] = useBoolean(false);

  const [visibleEnterPromoModal, openEnterPromoModal, hideEnterPromoModal] =
    useBoolean(false);

  const {
    auth,
    inAppOffers,
    purchaseDiscount,
    purchaseScreenState: screenState,
    inAppLoaderState,
  } = useRoot();
  const goToPlans = useCallback(() => {
    hideNeedSlotsModal();
    screenState.selectTab(PurchaseTabsKind.Slot);
  }, [hideNeedSlotsModal, screenState]);
  const {checkExpired} = useCheckDiscount();
  const goToAccessRecovery = useCallback(
    () => navigation.navigate('AccessRecovery'),
    [navigation],
  );
  const goToAuthAndThenOpenAffiliate = useCallback(
    () =>
      navigation.navigate('Auth', {
        onSuccess: createPoolPurchaseState(nanoid()),
        variant: 'promo',
      }),
    [navigation],
  );
  const getIsFocused = useNavigationGetIsFocused();
  useEffect(
    () =>
      reaction(
        () => [auth.isConnected, getIsFocused()] as const,
        async ([isConnected, isFocused]) => {
          if (isConnected && isFocused && !inAppOffers.isLoading) {
            if (checkExpired()) {
              purchaseDiscount.deleteDiscount();
            } else {
              await inAppOffers.fetch();
            }
          }
        },
        {fireImmediately: true},
      ),
    [auth, checkExpired, getIsFocused, purchaseDiscount, inAppOffers],
  );

  useEffect(
    () =>
      autorun(() => {
        if (getIsFocused()) {
          screenState.init(initialTabNavigationIndex);
        }
      }),
    [auth, getIsFocused, initialTabNavigationIndex, screenState],
  );

  const goToPoolMinerActivated = useCallback(
    (poolExpiredDate: ISODateString, poolHashrate: number) => {
      navigation.navigate('PoolMinerActivated', {
        poolHashrate: poolHashrate,
        poolExpiredDate: poolExpiredDate,
      });
    },
    [navigation],
  );

  const errorHandler = useHandleErrorAndNavigate();
  const purchaseSlot = usePurchaseSlotOffer({errorHandler});
  const purchasePoolMiner = usePurchasePoolMiner({errorHandler});
  const checkCanBuy = useCheckCanMinerBuy();
  const onSelectButtonPress = useCallback(async () => {
    const proposal =
      screenState.selectedTab === PurchaseTabsKind.Slot
        ? screenState.selectedSlotProposal
        : screenState.getDoubleProposalForPurchase();
    if (!proposal?.available) {
      return;
    }
    if (screenState.selectedTab === PurchaseTabsKind.Miner) {
      if (!checkCanBuy()) {
        return showNeedSlotsModal();
      }
    }
    const offer = proposal.offer;
    if (!offer) {
      return;
    }
    inAppLoaderState.addLoading();
    switch (offer.purchaseType) {
      case OfferPurchaseType.SlotSubscription:
        await purchaseSlot({
          goToThanksForPurchase: openSuccessSubscriptionModal,
          offer: offer,
          paymentMethod: screenState.selectedPaymentMethod,
        });
        break;
      case OfferPurchaseType.MinerProduct:
      case OfferPurchaseType.MinerSubscription:
        await purchasePoolMiner({
          offer: offer,
          goToPoolMinerActivated,
          paymentMethod: screenState.selectedPaymentMethod,
        });
        break;
    }
    inAppLoaderState.deleteLoading();
    screenState.selectSlotPurchaseId();
  }, [
    checkCanBuy,
    goToPoolMinerActivated,
    inAppLoaderState,
    openSuccessSubscriptionModal,
    purchasePoolMiner,
    purchaseSlot,
    screenState,
    showNeedSlotsModal,
  ]);

  const {safelyGoToAffiliate, getAffiliateIsPending} = useSafelyGoToAffiliate(
    goToAuthAndThenOpenAffiliate,
    AffiliateVariant.Promo,
  );
  const {goToAffiliateToken} = route.params ?? {};
  useOpenAffiliateOnUniqueToken(goToAffiliateToken, safelyGoToAffiliate);

  const goToDashboard = useResetToDashboard(
    route.params?.onSuccess ?? DASHBOARD,
  );

  const handleConfigurationSelectPress = useCallback(() => {
    navigation.navigate('SelectConfiguration');
  }, [navigation]);

  return (
    <PurchaseScreen
      error={inAppOffers.error}
      refreshing={inAppOffers.isLoading}
      onRefresh={inAppOffers.refresh}
      goToDashboard={goToDashboard}
      onPurchasePress={onSelectButtonPress}
      goToAccessRecovery={goToAccessRecovery}
      safelyGoToAffiliate={safelyGoToAffiliate}
      getAffiliateIsPending={getAffiliateIsPending}
      showNeedSlotsModal={showNeedSlotsModal}
      initialTabNavigationIndex={initialTabNavigationIndex}
      goToPlans={goToPlans}
      hideNeedSlotsModal={hideNeedSlotsModal}
      needSlotsVisible={needSlotsVisible}
      visiblePickHashrateModal={visiblePickHashrateModal}
      showPickHashrateModal={showPickHashrateModal}
      hidePickHashrateModal={hidePickHashrateModal}
      state={screenState}
      onSelectConfigurationPress={handleConfigurationSelectPress}
      onSelectButtonPress={onSelectButtonPress}
      visibleSuccessSubscriptionModal={visibleSuccessSubscriptionModal}
      openSuccessSubscriptionModal={openSuccessSubscriptionModal}
      hideSuccessSubscriptionModal={hideSuccessSubscriptionModal}
      visibleEnterPromoModal={visibleEnterPromoModal}
      openEnterPromoModal={openEnterPromoModal}
      hideEnterPromoModal={hideEnterPromoModal}
    />
  );
});

export const createPoolPurchaseState = (
  goToAffiliateToken?: string,
): PartialState<NavigationState<RootParamList>> => {
  return {
    index: 1,
    routes: [
      {
        name: 'Root',
        state: {
          index: 0,
          routes: [
            {
              name: 'Menu',
            },
          ],
        },
      },
      {
        name: 'Plan',
        params: {goToAffiliateToken, index: 1},
      },
    ],
  };
};
