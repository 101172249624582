import {useRoot} from '../../Root/hooks';
import {useMemo} from 'react';

export default function useGiftHelper() {
  const {gift} = useRoot();
  return useMemo(
    () => ({
      name: gift.gift?.pool_miner_config?.name || gift.gift?.plan?.name,
      type: gift.gift?.type === 'double' ? 'x2' : 'x1',
      isDouble: gift.gift?.type === 'double',
    }),
    [gift.gift],
  );
}
