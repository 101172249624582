import {Service} from '../structure';
import {Log} from './Log';
import {
  LocalNotificationContext,
  LocalNotifications,
} from '../LocalNotifications';
import NotificationEventLogContent from './NotificationEventLogContent';

export default class NotificationLogBridgeService implements Service {
  constructor(
    private readonly _root: {
      readonly localNotifications: LocalNotifications;
      readonly log: Log;
    },
  ) {}

  private readonly _onMessage = (context: LocalNotificationContext) => {
    this._root.log.write(new NotificationEventLogContent(context));
  };

  subscribe() {
    return this._root.localNotifications.income.listen(this._onMessage);
  }
}
