import {Service} from '../structure';
import {Platform} from 'react-native';
import {Headless} from './Headless';
import IosHeadlessService from './IosHeadlessService';
import ConstantHeadlessService from './ConstantHeadlessService';

export default class UiHeadlessServiceFactory {
  create(): Headless & Service {
    if (Platform.OS === 'ios') {
      return new IosHeadlessService();
    }
    return new ConstantHeadlessService(false);
  }
}
