import {AdId, AdItem} from '../ApiStore';
import {Uri} from '../units';
import {AdInfoItem} from '../Advert';
import StaticAdvertHelper from '../Advert/StaticAdvertHelper';

export default abstract class StaticAdvertCreatorHelper {
  private static _translateAdItemToAdvertItem(_: AdItem): AdInfoItem {
    const image = StaticAdvertHelper.getImage(_.image);
    const icon = StaticAdvertHelper.getImage(_.icon);
    return {
      id: _.id,
      spot: _.spot,
      type: _.type,
      size: _.size,
      title: _.title,
      body: _.body,
      image: image as Uri,
      icon: icon as Uri,
      link: _.link || undefined,
      options: {
        canClose: _.options.can_close ?? true,
        closeTime: _.options.close_time || undefined,
        force: _.options.force,
        canExclude: _.options.can_exclude,
      },
      actions: _.actions.map((action) => ({
        title: action.title,
        link: action.link || undefined,
        icon: action.icon || undefined,
      })),
    };
  }

  static process(_: AdItem[]) {
    const translatedItems = _.map(this._translateAdItemToAdvertItem);
    const spotByBannerList = new Map<AdId, AdInfoItem>();
    for (const item of translatedItems) {
      spotByBannerList.set(item.id, item);
    }
    return spotByBannerList;
  }
}
