import React from 'react';
import ThemeContext from './ThemeContext';
import {Theme} from './Theme';
import {observer} from 'mobx-react-lite';

export type ThemeProviderProps = {
  theme: Theme;
  children: React.ReactNode;
};

export default observer(function ThemeProvider({
  theme,
  ...rest
}: ThemeProviderProps) {
  return <ThemeContext.Provider value={theme} {...rest} />;
});
