import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {FarmState} from '../../ApiStore';
import {Theme} from '../../styling';

export default (
  worker: ReadonlyDeep<WorkerEntity>,
  theme: Theme,
  state?: ReadonlyDeep<FarmState>,
): string =>
  !state || !worker.mining_options?.is_huge_pages_enabled
    ? theme.palette.error
    : state.current_huge_pages === 0 || state.max_huge_pages === 0
    ? theme.palette.primary
    : theme.palette.success;
