import {Platform} from 'react-native';
import AndroidDeferredDeeplinkService from './AndroidDeferredDeeplinkService';
import SubDeferredDeeplinkService from './SubDeferredDeeplinkService';
import {Auth} from '../Auth';
import {ConnectedClient} from '../ContextClient';

export default class DeferredDeeplinkFactory {
  constructor(
    private readonly _root: {
      readonly auth: Auth;
      readonly connectedClient: ConnectedClient;
    },
  ) {}
  create() {
    return Platform.select({
      android: new AndroidDeferredDeeplinkService(this._root),
      default: new SubDeferredDeeplinkService(),
    });
  }
}
