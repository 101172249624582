import {useCallback} from 'react';
import {Platform} from 'react-native';
import {useGoToPlayStore} from '../../Navigation/hooks';
import {useRoot} from '../../Root/hooks';

export default () => {
  const {inAppOffers} = useRoot();
  const goToPlayStore = useGoToPlayStore();
  const goToPlayStoreSubscriptions = useCallback(async () => {
    await goToPlayStore();
  }, [goToPlayStore]);
  return useCallback(() => {
    switch (Platform.OS) {
      case 'android':
        return goToPlayStoreSubscriptions();
      case 'ios':
        return inAppOffers.refresh();
    }
  }, [goToPlayStoreSubscriptions, inAppOffers]);
};
