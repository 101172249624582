import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {Pressable, Text, View} from 'react-native';
import RocketSvg from '../assets/svg/colored/rocket.svg';
import ShoppingCartSvg from '../assets/svg/colorless/shopping-cart.svg';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  PressableOpacity,
} from '../components';
import {CryptoLoader} from '../components/CryptoLoader';
import {sized} from '../Svg';
import QuickStartListItem, {
  QuickStartItemParams,
} from '../components/QuickStartItem';
import {useStrings} from '../Root/hooks';
import QuickStartDiscount from '../QuickStartScreen/QuickStartDiscount';
import {QuickStartOffer} from '../QuickStartService';

export type QuickStartScreenViewProps = {
  onSubscribe: () => void;
  onSetting: () => void;
  showSkip?: boolean;
  subscribeDisabled: boolean;
  isLoading: boolean;
  items: QuickStartItemParams[];
  selectItem: (val: number) => void;
  selectedItemIndex: number | undefined;
  isChecked: boolean;
  setIsCheck: (v: boolean) => void;
  trial: QuickStartOffer['trial'];
};

export default observer(function QuickStartScreenView({
  onSubscribe,
  onSetting,
  showSkip,
  subscribeDisabled,
  isLoading,
  items,
  selectItem,
  selectedItemIndex,
  isChecked,
  setIsCheck,
  trial,
}: QuickStartScreenViewProps) {
  const strings = useStrings();
  return (
    <RootView>
      <HeaderView>
        <IconView>
          <RocketIcon />
        </IconView>
        <View>
          <TitleText>{strings['quickStartModal.title']}</TitleText>
          <DescriptionText>
            {strings['quickStartModal.description']}
          </DescriptionText>
          {trial && (
            <QuickStartContainerView>
              <QuickStartDiscount />
            </QuickStartContainerView>
          )}
        </View>
      </HeaderView>
      <ContentView>
        {isLoading ? (
          <LoaderContainer>
            <CryptoLoader />
          </LoaderContainer>
        ) : (
          <ProposalListView>
            {items.map((item, index) => (
              <ListItemView key={index} isLast={items.length === index + 1}>
                <QuickStartListItem
                  onPress={() => selectItem(index)}
                  selected={selectedItemIndex === index}
                  {...item}
                />
              </ListItemView>
            ))}
          </ProposalListView>
        )}
      </ContentView>
      <FooterView>
        <PressableOpacity disabled={subscribeDisabled} onPress={onSubscribe}>
          <StyledButton
            Icon={ShoppingCartSvg}
            offsetBottom
            variant={ButtonVariant.Highlighted}>
            {strings['quickStartModal.subscribe']}
          </StyledButton>
        </PressableOpacity>
        <PressableOpacity disabled={subscribeDisabled} onPress={onSetting}>
          <StyledButton color={ButtonColor.Default}>
            {showSkip
              ? strings['quickStartModal.skip']
              : strings['quickStartModal.reject']}
          </StyledButton>
        </PressableOpacity>
        <CheckRow onPress={() => setIsCheck(!isChecked)}>
          <Check isChecked={isChecked} />
          <CheckText>{strings['quickStartModal.dontShowAgain']}</CheckText>
        </CheckRow>
      </FooterView>
    </RootView>
  );
});

const RocketIcon = sized(RocketSvg, 60);

const LoaderContainer = variance(View)(() => ({
  root: {
    paddingVertical: 30,
    alignItems: 'center',
  },
}));

const IconView = variance(View)(() => ({
  root: {
    alignItems: 'center',
    marginBottom: 20,
  },
}));

const RootView = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const ProposalListView = variance(View)(() => ({
  root: {},
}));

const ListItemView = variance(View)(() => ({
  root: {
    marginBottom: 20,
  },
  isLast: {
    marginBottom: 0,
  },
}));

const HeaderView = variance(View)(() => ({
  root: {
    padding: 20,
    paddingBottom: 0,
  },
}));

const ContentView = variance(View)(() => ({
  root: {
    padding: 20,
  },
}));

const FooterView = variance(View)((theme) => ({
  root: {
    padding: 20,
    backgroundColor: theme.palette.base,
    borderTopWidth: 1,
    borderTopColor: theme.palette.border,
  },
}));

const StyledButton = variance(Button)(() => ({
  root: {},
  offsetBottom: {
    marginBottom: 12,
  },
}));

const TitleText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 22,
    lineHeight: 32,
    color: theme.palette.textPrimary,
    textAlign: 'center',
    marginBottom: 10,
  },
}));

const DescriptionText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 14,
    lineHeight: 22,
    textAlign: 'center',
    color: theme.palette.textSecondary,
  },
}));

const QuickStartContainerView = variance(View)(() => ({
  root: {
    marginTop: 20,
  },
}));

const CheckRow = variance(Pressable)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
  },
}));

const CheckText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
  },
}));

const Check = variance(View)((theme) => ({
  root: {
    width: 14,
    height: 14,
    borderWidth: 1,
    borderRadius: 3,
    borderColor: theme.palette.border,
    marginRight: 12,
  },
  isChecked: {
    backgroundColor: theme.palette.border,
  },
}));
