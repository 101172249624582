import React from 'react';
import {observer} from 'mobx-react-lite';
import LightningSvg from '../assets/svg/circled/lightning.svg';
import {Image, ImageStyle, Platform, Text, View} from 'react-native';
import {useStrings} from '../Root/hooks';
import {AppStoreButton, GooglePlayButton} from '../components/StoreButton';
import {createStylesHook} from '../styling';
import {Container} from '../components/Grid';
import {useGoToPlayStore, useGoToAppStore} from '../Navigation/hooks';
import {sized} from '../Svg';
import {DESKTOP_BREAKPOINT} from '../units/constants';
import {DESKTOP_STORE_BANNER_HEIGHT, STORE_BANNER_HEIGHT} from './constants';

export default observer(() => {
  const styles = useStyles();
  const strings = useStrings();
  const goToPlayStore = useGoToPlayStore();
  const goToAppStore = useGoToAppStore();
  if (Platform.OS !== 'web') {
    return null;
  }
  return (
    <Container style={styles.background}>
      <View style={styles.root}>
        <LightningIcon />
        <Text style={styles.title}>
          {strings['dashboard.manageWorkersGroup.title']}
        </Text>
        <Text style={styles.description}>
          {strings['dashboard.manageWorkersGroup.description']}
        </Text>
        <View style={styles.storeButtons}>
          <GooglePlayButton
            style={styles.googlePlayButton}
            onPress={goToPlayStore}
          />
          <AppStoreButton onPress={goToAppStore} />
        </View>
        <Image
          resizeMode="contain"
          style={styles.image}
          source={require('../assets/desktopStoreBanner.png')}
        />
      </View>
    </Container>
  );
});

const LightningIcon = sized(LightningSvg, 80);

const useStyles = createStylesHook((theme) => ({
  background: {
    backgroundColor: theme.palette.background,
    marginBottom: 20,
    borderRadius: 15,
  },
  root: {
    height: STORE_BANNER_HEIGHT,
    paddingHorizontal: 25,
    paddingTop: 25,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: theme.palette.border,
    backgroundColor: theme.select(
      theme.palette.background,
      theme.palette.backdrop,
    ),
    borderRadius: 15,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        height: DESKTOP_STORE_BANNER_HEIGHT,
        paddingHorizontal: 145,
        paddingTop: 40,
      },
    }),
  },
  title: {
    ...theme.fontByWeight('700'),
    marginTop: 20,
    color: theme.palette.textPrimary,
    fontSize: 20,
    lineHeight: 24,
    textAlign: 'center',
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 32,
        lineHeight: 36,
        marginTop: 30,
      },
    }),
  },
  description: {
    color: theme.palette.textSecondary,
    lineHeight: 22,
    textAlign: 'center',
    fontSize: 13,
    ...theme.fontByWeight('400'),
    marginTop: 10,
    marginBottom: 30,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 18,
        lineHeight: 28,
      },
    }),
  },
  image: {
    width: 440,
    height: 208,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        width: 740,
        height: 330,
      },
    }),
  } as ImageStyle,
  googlePlayButton: {
    marginRight: 10,
    marginBottom: 10,
  },
  storeButtons: {
    flexDirection: 'column',
    marginBottom: 45,
    ...theme.mediaQuery({
      500: {
        flexDirection: 'row',
      },
    }),
  },
}));
