import React from 'react';
import {Switch as RNSwitch, SwitchProps} from 'react-native-switch';
import {observer} from 'mobx-react-lite';

import {useStyles} from '../styling';
import {Platform, ViewStyle} from 'react-native';

export default observer(({disabled, ...rest}: SwitchProps) => {
  const styles = useStyles(() => ({
    root: Platform.select({
      web: {
        cursor: 'pointer',
      } as ViewStyle,
      default: {},
    }),
  }));
  return (
    <RNSwitch
      containerStyle={styles.root}
      disabled={disabled}
      circleSize={25}
      barHeight={30}
      circleBorderWidth={0}
      renderActiveText={false}
      renderInActiveText={false}
      switchRightPx={3}
      switchLeftPx={3}
      {...rest}
    />
  );
});
