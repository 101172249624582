import {ReadonlyDeep} from 'type-fest';
import {LocaleDict} from '../../translation/LocaleStrings';

export function getReadablePromoError(
  code: number,
  strings: ReadonlyDeep<LocaleDict>,
) {
  switch (code) {
    case 10000:
      return strings['purchase.promo.error.notFound'];
    case 10001:
      return strings['purchase.promo.error.expired'];
    case 10002:
      return strings['purchase.promo.error.cantUseOwnCode'];
    default:
      return strings['error.somethingWentWrong'];
  }
}
