import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTemplates} from '../Root/hooks';
import {useStyles} from '../styling';
import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import Markdown from '../components/Markdown';

export type AccessInfoProps = {
  hasAtLeastOnePlan: boolean;
  worker: ReadonlyDeep<WorkerEntity>;
};

export const AccessInfo = observer((props: AccessInfoProps) => {
  const {hasAtLeastOnePlan, worker} = props;
  const templates = useTemplates();
  const styles = useStyles((theme) => ({
    paragraph: {
      ...theme.fontByWeight('400'),
      color: theme.palette.textSecondary,
      marginTop: 0,
      marginBottom: 20,
      fontSize: 14,
      lineHeight: 22,
    },
    error: {
      color: theme.palette.error,
    },
  }));
  const hashrate = worker.pool_miner_config?.hash_rate ?? '-';
  const title = hasAtLeastOnePlan
    ? templates['renewAccess.desc']({
        hashrate: hashrate,
      })
    : templates['renewAccess.unavailableDesc']({
        hashrate: hashrate,
      });

  // FIXME
  let style = styles.paragraph;
  if (!hasAtLeastOnePlan) {
    style = {...style, ...styles.error};
  }
  return (
    <Markdown
      styles={{
        paragraph: style,
      }}>
      {title}
    </Markdown>
  );
});
