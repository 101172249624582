import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {RootStackBindingProps} from './RootStackBindingProps';
import {OAuthSplashScreen} from '../../OAuthSplashScreen';

export type OAuthSplashBindingProps = RootStackBindingProps<'OAuthSplash'>;

export default observer((props: OAuthSplashBindingProps) => {
  const {navigation} = props;
  const onCancel = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.pop();
    }
  }, [navigation]);
  return <OAuthSplashScreen onCancel={onCancel} />;
});
