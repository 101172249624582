import {LinkingOptions, ParamListBase} from '@react-navigation/native';
import {LocationSource} from '../Location';
import {Url} from '../units';
import {LinkingOptionsProvider} from './LinkingOptionsProvider';
import {SpecialLocation, UNKNOWN} from '../SpecialLocation';
import {when} from 'mobx';
import {NavigationContainer} from '../NavigationContainer';
import {PREFIXES} from './constant';
import {RootParamList} from '../Navigation';

export default class MobileLinkingOptionsProviderImpl
  implements LinkingOptionsProvider
{
  constructor(
    private readonly _root: {
      readonly navigationContainer: NavigationContainer;
      readonly locationSource: LocationSource;
      readonly specialLocation: SpecialLocation;
    },
  ) {}

  static readonly _CONFIG: LinkingOptions<RootParamList>['config'] = {
    initialRouteName: 'Root',
    screens: {
      Root: {
        initialRouteName: 'Dashboard',
        screens: {
          Dashboard: {
            path: 'dashboard',
            parse: {
              emptyGroupOptions: JSON.parse,
              groupOptionsById: JSON.parse,
              interactiveTutorial: JSON.parse,
            },
            stringify: {
              emptyGroupOptions: JSON.stringify,
              groupOptionsById: JSON.stringify,
              interactiveTutorial: JSON.stringify,
            },
          },
          Statistics: {
            path: 'statistics/:groupId?',
            parse: {
              groupId: (id: string) => {
                const _id = parseInt(id, 10);
                return isFinite(_id) ? _id : undefined;
              },
            },
            stringify: {
              groupId: JSON.stringify,
            },
          },
          Withdraw: 'withdraw',
          Notifications: 'notifications',
          Menu: {
            path: 'menu',
            parse: {goToAffiliateToken: JSON.parse},
            stringify: {goToAffiliateToken: JSON.stringify},
          },
          Debug: 'debug',
        },
      },
      NotificationsSettings: 'notificationsSettings',
      DashboardSettings: 'dashboardSettings',
      Auth: {
        path: 'auth',
        parse: {onSuccess: JSON.parse, farmId: (_) => parseInt(_, 10)},
        stringify: {
          onSuccess: JSON.stringify,
          farmId: (_: number) => _.toString(10),
        },
      },
      CheckAuth: 'checkauth',
      PaymentSuccess: {
        path: 'paymentSuccess',
        parse: {amount: (id) => parseInt(id, 10)},
        stringify: {amount: (id: number) => id.toString(10)},
      },
      Schedule: {
        path: 'scheduler',
        parse: {
          workerIds: JSON.parse,
          schedulerOwnerName: JSON.parse,
        },
        stringify: {
          workerIds: JSON.stringify,
          schedulerOwnerName: JSON.stringify,
        },
      },
      WorkerStatistics: {
        path: 'workerStatistics',
      },
      PaymentCompleted: {
        path: 'paymentCompleted',
      },
      Plan: {
        path: 'subscriptions',
        parse: {onSuccess: JSON.parse, index: JSON.parse},
        stringify: {
          onSuccess: JSON.stringify,
          index: JSON.stringify,
        },
      },
      Products: 'products',
      QuickStart: 'quickstart',
      FarmAction: 'farmAction',
      WithdrawalHistory: {
        path: 'withdrawalHistory',
        parse: {goToAffiliateToken: JSON.parse},
        stringify: {goToAffiliateToken: JSON.stringify},
      },
      Tutorial: {
        path: 'tutorial',
        parse: {onSuccess: JSON.parse},
        stringify: {onSuccess: JSON.stringify},
      },
      AdvertSplash: 'advertSplash',
      Rate: 'rate',
      AddWorker: 'addWorker',
      OAuthComplete: {
        path: 'oauth/complete#:anchor?',
        exact: false,
      },
      NotFound: 'Home',
    },
  };

  private _filterSupported(address: Url): Url | null {
    if (this._root.specialLocation.parse(address).kind === UNKNOWN) {
      return address;
    }
    return null;
  }

  async getInitial() {
    const url = await this._root.locationSource.getInitial();
    if (!url.success) {
      return null;
    }
    return this._filterSupported(url.right);
  }

  listen(listener: (url: string) => void) {
    this._root.locationSource.updates.listen(async (_address) => {
      await when(() => this._root.navigationContainer.isConfigured);
      const address = this._filterSupported(_address);
      if (address !== null) {
        listener(address);
      }
    });
  }

  readonly linkingOptions: LinkingOptions<ParamListBase> = {
    prefixes: PREFIXES,
    config: MobileLinkingOptionsProviderImpl._CONFIG,
    getInitialURL: () => this.getInitial(),
    subscribe: (listener) => this.listen(listener),
  };
}
