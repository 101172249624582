import React from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';

import {variance} from '../../../../styling';
import SlotInformation, {
  SLOT_INFORMATION_HEIGHT,
} from '../common/SlotInformation';
import AddWorker, {ADD_WORKER_HEIGHT} from './AddWorker';
import FarmInfo, {FARM_INFO_HEIGHT} from './FarmInfo';
import BalanceInfo, {BALANCE_INFO_HEIGHT} from './BalanceInfo';
import {useHeaderBannerSectionHeight} from '../../../hooks/useGetItemLayout';
import AdsMobileBanner from '../../../Header/AdsMobileBanner';
import {AdInfoItem} from '../../../../Advert';

export const MOBILE_FARM_INFO_SECTION_HEIGHT =
  FARM_INFO_HEIGHT +
  BALANCE_INFO_HEIGHT +
  SLOT_INFORMATION_HEIGHT +
  ADD_WORKER_HEIGHT +
  10 + // padding bottom
  2; // border width

export type MobileFarmInfoSectionProps = {
  goToAuth: () => void;
  goToAddWorker: () => void;
  goToWithdraw: () => void;
  banner?: AdInfoItem;
};

export default observer(function FarmInfoSection(
  props: MobileFarmInfoSectionProps,
) {
  const {goToAddWorker, goToAuth, goToWithdraw, banner} = props;
  const height = useHeaderBannerSectionHeight();
  const getBanner = () => {
    if (banner) {
      return (
        <AdsMobileBanner
          isDesktop={false}
          advertItem={banner}
          height={height}
        />
      );
    }
  };
  return (
    <ContainerView>
      <RootView>
        <FarmInfo goToAuth={goToAuth} />
        <BalanceInfo goToWithdraw={goToWithdraw} />
        {getBanner()}
        <SlotInformation />
      </RootView>
      <AddWorker goToAddWorker={goToAddWorker} />
    </ContainerView>
  );
});

const ContainerView = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));

const RootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: theme.palette.border,
    marginBottom: 10,
    overflow: 'hidden',
  },
}));
