import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import getMiningSpeed from './getMiningSpeed';
import {WorkerStateRegistry} from '../../WorkerStateRegistry';

export default (
  workers: readonly ReadonlyDeep<WorkerEntity>[],
  workerStateRegistry: WorkerStateRegistry,
): number =>
  workers.reduce(
    (total, worker) =>
      total +
      getMiningSpeed(worker, workerStateRegistry.states.get(worker.id)?.state),
    0,
  );
