import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {Text, View} from 'react-native';
import {
  ApiModeBadge,
  Button,
  ButtonColor,
  ButtonVariant,
  PressableOpacity,
} from '../../../components';
import {Container} from '../../../components/Grid';
import {createStylesHook} from '../../../styling';
import {useRoot, useStrings} from '../../../Root/hooks';
import {HeaderFilter} from '../HeaderFilter';
import getWorkerOrder from '../../getters/getWorkerOrder';
import {useNavigation, useRoute} from '@react-navigation/native';
import {DashboardScreenProps} from '../../DashboardScreenProps';
import {sized} from '../../../Svg';
import ReloadSvg from '../../../assets/svg/colorless/reload.svg';
import {WorkerOrder} from '../../types';
import {setSortingParams} from '../groupSortingParams';
import {expr} from 'mobx-utils';
import getTotalOnlineWorkers from '../../getters/getTotalOnlineWorkers';
export default observer(() => {
  const route = useRoute<DashboardScreenProps['route']>();
  const navigation = useNavigation<DashboardScreenProps['navigation']>();
  const strings = useStrings();
  const {workerStateRegistry, dashboardStore, auth} = useRoot();
  const styles = useStyles();
  const onlineWorkerCount = expr(() =>
    getTotalOnlineWorkers(
      [...dashboardStore.workers.values()],
      workerStateRegistry,
    ),
  );
  const workers = expr(() => [...dashboardStore.workers.keys()].length);
  const workerOrder = getWorkerOrder(route.params, 0);
  const onOrderChange = useCallback(
    async (nextWorkerOrder: WorkerOrder) => {
      const params = {
        groupOptionsById: {
          0: {workerOrder: nextWorkerOrder},
        },
      };
      navigation.setParams(params);
      await setSortingParams({dashboard: params, farmId: auth.accountId});
    },
    [auth.accountId, navigation],
  );
  return (
    <Container>
      <View style={styles.header}>
        <View style={styles.left}>
          <View style={styles.titleView}>
            <Text numberOfLines={1} ellipsizeMode="tail" style={styles.title}>
              {strings['dashboard.simpleList.title']}
            </Text>
          </View>
          <View style={styles.emptySlotView}>
            <Text style={styles.workerCounter}>
              {`${onlineWorkerCount} / ${workers}`}
            </Text>
            <ApiModeBadge style={styles.badge} hideInReal />
          </View>
        </View>
        <View style={styles.right}>
          <PressableOpacity
            disabled={dashboardStore.isLoading}
            onPress={dashboardStore.fetch}>
            <Button
              style={styles.reloadButton}
              variant={ButtonVariant.Text}
              color={ButtonColor.Primary}
              Icon={ReloadIcon}
            />
          </PressableOpacity>
        </View>
      </View>
      <HeaderFilter order={workerOrder} onOrderChange={onOrderChange} />
    </Container>
  );
});

const ReloadIcon = sized(ReloadSvg, 20);

const useStyles = createStylesHook((theme) => ({
  root: {},
  header: {
    flexDirection: 'row',
    zIndex: 2,
    backgroundColor: theme.palette.background,
    borderColor: theme.palette.border,
    borderWidth: 1,
    height: 50,
    paddingLeft: 10,
    paddingRight: 15,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  left: {
    flexDirection: 'row',
    flex: 1,
    flexGrow: 1,
  },
  right: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 15,
  },
  titleView: {
    flex: 1,
    justifyContent: 'center',
    marginRight: 5,
  },
  title: {
    ...theme.fontByWeight('700'),
    fontSize: 18,
    color: theme.palette.textPrimary,
  },
  emptySlotView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  workerCounter: {
    ...theme.fontByWeight('700'),
    fontSize: 11,
    lineHeight: 13,
    color: theme.palette.textSecondary,
  },
  badge: {
    marginHorizontal: 10,
  },
  reloadButton: {
    paddingHorizontal: 0,
  },
}));
