import React from 'react';
import {observer} from 'mobx-react-lite';
import GiftSvg from '../../../../assets/svg/colorless/gift.svg';
import {sized} from '../../../../Svg';
import {useTheme, variance} from '../../../../styling';
import {
  TotalPriceItemContainer,
  TotalPriceItemTop,
  TotalPriceItemTopText,
} from '../../../shared/shared';
import {View, Text} from 'react-native';
import {useRoot, useStrings} from '../../../../Root/hooks';
import BaseSkeleton from '../../../../components/BaseSkeleton';
import useGiftHelper from '../../../hooks/useGiftHelper';

export default observer(function TotalPriceGift() {
  const {gift} = useRoot();
  const theme = useTheme();
  const strings = useStrings();
  const {name, type, isDouble} = useGiftHelper();
  if (!gift.gift) {
    return <Skeleton />;
  }
  if (!name) {
    return null;
  }
  return (
    <TotalPriceItemContainer>
      <TotalPriceItemTop>
        <GiftIcon color={theme.palette.info} />
        <TotalPriceItemTopText>{strings['gift.label']}</TotalPriceItemTopText>
      </TotalPriceItemTop>
      <GiftContainer double={isDouble}>
        <GiftText numberOfLines={1} ellipsizeMode="tail">
          {name}
        </GiftText>
        <GiftText count>{type}</GiftText>
      </GiftContainer>
    </TotalPriceItemContainer>
  );
});

const GiftIcon = sized(GiftSvg, 20, 20);

const GiftContainer = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 26,
    outlineWidth: 0,
    backgroundColor: theme.palette.staticLightBlue,
    padding: 2,
  },
  double: {
    backgroundColor: theme.palette.purple,
  },
}));

const Skeleton = variance(BaseSkeleton)((theme) => ({
  root: {
    height: 93,
    ...theme.mediaQuery({
      1000: {
        height: 63,
      },
    }),
  },
}));

const GiftText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 28,
    color: theme.palette.textPrimary,
    backgroundColor: theme.palette.background,
    height: 28,
    paddingHorizontal: 10,
    borderRadius: 26,
  },
  count: {
    marginLeft: 8,
    marginRight: 7,
    paddingHorizontal: 0,
    fontSize: 17,
    backgroundColor: 'transparent',
    color: theme.palette.background,
  },
}));
