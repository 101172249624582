import {first} from 'lodash';
import {SubscriptionAndroid, PricingPhaseAndroid} from 'react-native-iap';

export default <K extends keyof PricingPhaseAndroid>(
  _: SubscriptionAndroid,
  key: K,
): PricingPhaseAndroid[K] | undefined => {
  const phases = first(_.subscriptionOfferDetails)?.pricingPhases;
  const firstPhase = first(phases?.pricingPhaseList);
  return firstPhase?.[key];
};
