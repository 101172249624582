import React from 'react';
import {observer} from 'mobx-react-lite';
import {View} from 'react-native';
import {variance} from '../../styling';
import {PickHashrateSection} from './sections/PickHashrateSection';
import {useRoot} from '../../Root/hooks';
import {CryptoLoader} from '../../components/CryptoLoader';
import {PurchaseScreenState} from '../states/PurchaseScreenState';
import ProposalTableBiding from './components/ProposalTable/ProposalTableBiding';
import TotalPriceBinding from './TotalPriceBinding';

export type PoolMinerTableProps = {
  state: PurchaseScreenState;
  showPickHashrateModal: () => void;
  disableScroll: () => void;
  enableScroll: () => void;
};

export default observer(function PoolMinerTable({
  state,
  disableScroll,
  enableScroll,
  showPickHashrateModal,
}: PoolMinerTableProps) {
  const {inAppOffers} = useRoot();
  if (inAppOffers.isLoading) {
    return (
      <LoaderContainerView>
        <CryptoLoader />
      </LoaderContainerView>
    );
  }
  return (
    <View>
      <PickHashrateSection
        disableScroll={disableScroll}
        enableScroll={enableScroll}
        state={state}
        onSelectPoolMinerPress={showPickHashrateModal}
      />
      <ProposalTableBiding />
      <TotalPriceBinding />
    </View>
  );
});

const LoaderContainerView = variance(View)((theme) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
    ...theme.mediaQuery({
      1280: {
        minHeight: 732,
      },
    }),
  },
}));
