import {observer} from 'mobx-react-lite';
import React from 'react';
import {View, ViewProps} from 'react-native';
import {LogLevel} from '../ApiStore';
import {useStyles} from '../styling';

interface NotificationLevelCircleProps extends ViewProps {
  level: LogLevel;
}

export default observer((props: NotificationLevelCircleProps) => {
  const {level, style, ...rest} = props;

  const styles = useStyles((theme) => ({
    root: {
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor: theme.palette.secondary,
    },
    debugRoot: {backgroundColor: theme.palette.info},
    infoRoot: {backgroundColor: theme.palette.info},
    warningRoot: {backgroundColor: theme.palette.warning},
    errorRoot: {backgroundColor: theme.palette.error},
    criticalRoot: {backgroundColor: theme.palette.error},
  }));

  return (
    <View
      style={[
        styles.root,
        level === LogLevel.Debug && styles.debugRoot,
        level === LogLevel.Info && styles.infoRoot,
        level === LogLevel.Warning && styles.warningRoot,
        level === LogLevel.Error && styles.errorRoot,
        level === LogLevel.Critical && styles.criticalRoot,
        style,
      ]}
      {...rest}
    />
  );
});
