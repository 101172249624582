import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';

import {ListItem, ListItemProps} from './ListItem';

export const InsideListItem = observer((props: ListItemProps) => {
  const styles = useMemo(
    () =>
      ({
        root: {
          paddingLeft: 40,
        },
      } as const),
    [],
  );
  return <ListItem listContainerStyle={styles.root} {...props} />;
});
