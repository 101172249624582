import {
  getMessaging,
  onMessage,
  isSupported,
  MessagePayload,
} from '../WebFirebase/messaging';
import {BusImpl, Disposer, Service} from '../structure';
import {Messaging} from './Messaging';
import {MessageDescription} from './Message';
import {MessageHandler} from './MessageHandler';

export default class WebMessagingService implements Messaging, Service {
  constructor(
    private readonly _parent: {readonly messageHandler: MessageHandler},
  ) {}

  public readonly income = new BusImpl<MessageDescription>();

  private _disposer?: Disposer;
  private async _initialize() {
    this._disposer?.();
    if (await isSupported()) {
      const messaging = getMessaging();
      this._disposer = onMessage(messaging, this._onMessage) as Disposer;
    }
  }

  subscribe() {
    // noinspection JSIgnoredPromiseFromCall
    this._initialize();
    return (() => this._disposer?.()) as Disposer;
  }

  private readonly _onMessage = async (message: MessagePayload) => {
    if (message.notification) {
      const registration = await window.navigator.serviceWorker.ready;
      await registration.showNotification(
        message.notification.title ?? 'CryptoTab Farm',
        {
          body: message.notification.body,
          image: message.notification.image,
        },
      );
    } else {
      if (message.data) {
        const description = message.data as MessageDescription;
        this.income.send(description);
        const context_ = await this._parent.messageHandler.handle(description);
        if (!context_.success) {
          return;
        }
      }
    }
  };
}
