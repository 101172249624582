import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';

import {PoolMinerActivatedModal} from '../../PooMinerActivatedModal';
import {useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {PurchaseTabsKind} from '../../PurchaseScreen/states/PurchaseScreenState';

export default observer(function PoolMinerActivatedBinding({
  navigation,
  route,
}: RootStackBindingProps<'PoolMinerActivated'>) {
  const {poolHashrate, poolExpiredDate} = route.params ?? {};
  const safelyGoBack = useSafelyGoBack();
  const toPlan = useCallback(() => {
    safelyGoBack();
    navigation.navigate('Plan', {index: PurchaseTabsKind.Miner});
  }, [navigation, safelyGoBack]);
  const toDashboard = useCallback(async () => {
    safelyGoBack();
    navigation.navigate('Root');
  }, [navigation, safelyGoBack]);
  return (
    <PoolMinerActivatedModal
      expireDate={poolExpiredDate}
      hashrate={poolHashrate}
      onModalClosed={safelyGoBack}
      onSubmit={toDashboard}
      onDismiss={toPlan}
    />
  );
});
