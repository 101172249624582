import React from 'react';
import {observer} from 'mobx-react-lite';
import TopInfo from './components/TopInfo';
import ListCards from './components/ListCards';
import {ScrollView} from 'react-native-gesture-handler';
import {Product} from '../Products/Product';
import {MD_BREAKPOINT} from '../units/constants';
import {DesktopWebAppHeader} from '../DesktopWebAppHeader';
import {PageScrollView} from '../containers';
import {variance} from '../styling';
export type ProductsScreenProps = {
  products: Product[] | undefined;
};

export default observer(function ProductsScreen(props: ProductsScreenProps) {
  const {products} = props;

  return (
    <>
      <DesktopWebAppHeader />
      <PageScrollView>
        <Container>
          <TopInfo />
          <ListCards data={products} />
        </Container>
      </PageScrollView>
    </>
  );
});
const Container = variance(ScrollView)((theme) => ({
  root: {
    paddingVertical: 15,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    backgroundColor: 'transparent',

    paddingTop: 15,
    ...theme.mediaQuery({
      [MD_BREAKPOINT]: {
        marginHorizontal: 18,
        borderWidth: 1,
        borderColor: theme.palette.border,
        paddingVertical: 24,
        borderRadius: 10,
        backgroundColor: theme.palette.background,
        marginTop: 40,
        marginBottom: 80,
      },
    }),
  },
}));
