import React, {useCallback} from 'react';
import {RootStackBindingProps} from '../RootStack/RootStackBindingProps';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../../Root/hooks';
import useTutorialUrl from '../../TutorialModal/useTutorialUrl';
import {ApiMode} from '../../farmApi';
import {CommonActions} from '@react-navigation/native';
import {DASHBOARD} from './constants';
import {
  NavigationState,
  PartialState,
} from '@react-navigation/routers/src/types';
import {TutorialModal} from '../../TutorialModal';
import {expr} from 'mobx-utils';
import {FULFILLED, PENDING} from '../../AsyncAtom';
import {first} from 'lodash';
import {AdSpot} from '../../ApiStore';
import {useSafelyGoBack} from '../hooks';
import {openLink, Target} from '../../Links';
import {Url} from '../../units';
import {RootParamList} from './RootStack';

export type TutorialBindingProps = RootStackBindingProps<'Tutorial'>;

export default observer(function TutorialBinding(props: TutorialBindingProps) {
  const {navigation, route} = props;
  const {auth, apiStore, tutorial, advertHelper, windowDimensionsState} =
    useRoot();
  const tutorialUrl = useTutorialUrl((_) =>
    apiStore.mode === ApiMode.Real ? _.tutorialReal : _.tutorialDemo,
  );
  const onUrlPress = useCallback((url: Url) => openLink(url, Target.Blank), []);
  const onComplete = useCallback(() => {
    tutorial.complete();
    navigation.dispatch(
      CommonActions.reset(route.params?.onSuccess ?? DASHBOARD),
    );
  }, [navigation, route, tutorial]);
  const safelyGoBack = useSafelyGoBack();
  const isDesktop = expr(() => windowDimensionsState.window.width > 1280);
  const banner = expr(() => {
    if (advertHelper.state?.status !== FULFILLED) {
      return undefined;
    }
    return isDesktop
      ? first(
          advertHelper.state.result.spotByBannerList.get(
            AdSpot.TutorialDesktop,
          ),
        )
      : first(
          advertHelper.state.result.spotByBannerList.get(AdSpot.TutorialMobile),
        );
  });
  const onAdvertPress = useCallback(async () => {
    if (!banner) {
      return;
    }
    const firstAction = first(banner.actions);
    if (!firstAction) {
      return;
    }
    if (firstAction.link) {
      await openLink(firstAction.link, Target.Blank);
    }
    advertHelper.close(banner.id, banner.spot, true);
    safelyGoBack();
  }, [advertHelper, banner, safelyGoBack]);
  const visible = expr(
    () =>
      auth.initialized &&
      auth.isConnected &&
      advertHelper.state !== undefined &&
      advertHelper.state.status !== PENDING,
  );
  return visible ? (
    <TutorialModal
      visible={visible}
      onClose={onComplete}
      onComplete={onComplete}
      uri={tutorialUrl}
      onAdvertPress={onAdvertPress}
      onUrlPress={onUrlPress}
    />
  ) : null;
});

export const createTutorialState = (
  onSuccess?: PartialState<NavigationState> | NavigationState,
): PartialState<NavigationState<RootParamList>> => {
  return {
    index: 1,
    routes: [
      {
        name: 'Root',
      },
      {
        name: 'Tutorial',
        params: {
          onSuccess: onSuccess,
        },
      },
    ],
  };
};
