import {
  AppAnalytics,
  AppEvent,
  FIRST_DEMO_MINER_ADDED,
  FIRST_EVENT_MADE,
  FIRST_GET_MINER_ID,
  FIRST_MINER_ADDED,
  FIRST_PURCHASE_MADE,
} from './AppAnalytics';
import {batchDisposers, Service} from '../structure';
import {APP_EVENT_MAP} from './constants';
import {FirebaseAnalytics} from '../FirebaseAnalytics';
import {bind} from '../fp';

export default class AppAnalyticsListenerService implements Service {
  constructor(
    private readonly _root: {
      readonly appAnalytics: AppAnalytics;
      readonly firebaseAnalytics: FirebaseAnalytics;
    },
  ) {}

  private _onEvent = bind((event: AppEvent) => {
    const name = this._translateEventToEventName(event);
    if (name !== undefined) {
      this._root.firebaseAnalytics.logEvent({
        name,
      });
    }
  }, this);

  private _onFirstEvent = bind((firstEvent: AppEvent) => {
    const name = this._translateEventToEventName(FIRST_EVENT_MADE);
    const value = this._translateEventToEventName(firstEvent);
    if (name !== undefined && value !== undefined) {
      this._root.firebaseAnalytics.logEvent({
        name,
        params: {
          event: value,
        },
      });
    }
  }, this);

  private _translateEventToEventName(event: AppEvent) {
    return APP_EVENT_MAP.get(event);
  }

  subscribe() {
    const {updates} = this._root.appAnalytics;
    return batchDisposers(
      updates.listen(FIRST_EVENT_MADE, this._onFirstEvent),
      updates.listen(FIRST_DEMO_MINER_ADDED, this._onEvent),
      updates.listen(FIRST_MINER_ADDED, this._onEvent),
      updates.listen(FIRST_GET_MINER_ID, this._onEvent),
      updates.listen(FIRST_PURCHASE_MADE, this._onEvent),
    );
  }
}
