import React from 'react';
import {observer} from 'mobx-react-lite';
import {BottomTabNavigationProp} from '@react-navigation/bottom-tabs';
import {StackNavigationProp} from '@react-navigation/stack';
import {BottomTabParamList, RootParamList} from '../Navigation';
import {CompositeNavigationProp} from '@react-navigation/native';
import NotificationList from './NotificationList';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';

export interface NotificationListScreenProps {
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<BottomTabParamList, 'Notifications'>,
    StackNavigationProp<RootParamList>
  >;
}

export default observer(() => {
  return (
    <>
      <DesktopWebAppHeader />
      <NotificationList />
    </>
  );
});
