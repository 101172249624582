import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {PaymentCompletedScreen} from '../../PaymentCompletedScreen';
import {useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';

export type PaymentCompletedBindingProps =
  RootStackBindingProps<'PaymentCompleted'>;
export default observer(function PaymentCompletedBinding({
  navigation,
}: PaymentCompletedBindingProps) {
  const safelyGoBack = useSafelyGoBack();
  const toDashboard = useCallback(() => {
    safelyGoBack();
    navigation.navigate('Root');
  }, [safelyGoBack, navigation]);
  return (
    <PaymentCompletedScreen
      toDashboard={toDashboard}
      onModalClose={safelyGoBack}
    />
  );
});
