import footerConfig from './footerConfig';
import {Millisecond} from '../Time';
import {Platform} from 'react-native';

export default {
  ...footerConfig,
  appName: 'cryptofarm',
  productName: Platform.OS === 'ios' ? 'CT Farm Pro' : 'CryptoTab Farm',
  webAppId: 'com.cryptofarm.web',
  webBundleId: 'com.cryptofarm',
  webAppUrl: 'https://app.cryptotab.farm/',
  androidPackageName: 'com.cryptofarm',
  playStoreUrl:
    'https://play.google.com/store/apps/details?id=com.cryptofarm&referrer=utm_source%3Dsite%26utm_medium%3Dwebdash',
  appStoreUrl:
    'https://apps.apple.com/app/cryptotab-farm/id1633191121?action=write-review',
  installationUrl: 'https://cryptotab.farm/{lang}/installation/',
  playStoreSubscriptionsUrl:
    'https://play.google.com/store/account/subscriptions',
  appStoreSubscriptionsUrl: 'https://apps.apple.com/account/subscriptions',
  aboutUrl: 'https://cryptotab.farm',
  downloadUrl: 'https://cryptotab.farm/download',
  downloadFarmMinerUrl: 'https://cryptotab.farm/download/farm_miner_{id}',
  promoRegistrationLink: 'https://cryptotab.farm/go/{code}',
  tutorialIntro: 'https://cryptotab.farm/{lang}/tutorial_intro/',
  tutorialDemo: 'https://cryptotab.farm/{lang}/tutorial_demo/',
  tutorialReal: 'https://cryptotab.farm/{lang}/tutorial_real/',
  aboutMinerUrl: 'https://cryptotab.farm/miner/',
  goDiscount: 'https://cryptotab.farm/go/{discount}',
  affiliateSuspendUrl: 'https://cryptobrowser.site/app/banned',
  cryptotabTimeout: 10000 as Millisecond,
  bffTimeout: 15000 as Millisecond,
  cannonDuration: 5000 as Millisecond,
  iapPasswordIos: '5da17b39321a484c8c06f6c54be2f30d',
};
