import {LogContent} from './LogContent';

export default class IAPLogContent implements LogContent {
  constructor(
    public readonly responseBody: string,
    public readonly event?: string,
  ) {}

  get body() {
    const str = this.event
      ? `${this.event} ${this.responseBody}`
      : this.responseBody;
    return `#IAP ${str}`;
  }
}
