import React, {useCallback} from 'react';
import {View} from 'react-native';
import {observer} from 'mobx-react-lite';
import {useNavigation} from '@react-navigation/native';
import * as Animatable from 'react-native-animatable';
import Item from './Item';
import ItemStoreLinks from './ItemStoreLinks';
import ItemAvailableSlots from './ItemAvailableSlots';
import {WebAppHeaderWebProps} from '../DesktopWebAppHeader';
import {useRoot, useStrings} from '../../Root/hooks';
import {AccountType} from '../../Auth';
import {useOpenLink} from '../../Links';
import {createStylesHook} from '../../styling';
import {
  useGoToPlayStore,
  useGoToAppStore,
  useSafelyGoToAffiliate,
} from '../../Navigation/hooks';
import {expr} from 'mobx-utils';

interface MenuProps {
  hide: () => void;
  logout: () => void;
}

export default observer(({hide, logout}: MenuProps) => {
  const {auth, configuration, navigationContainer} = useRoot();
  const {navigate} = useNavigation<WebAppHeaderWebProps['navigation']>();
  const styles = useStyles();
  const strings = useStrings();
  const goToAccountSwitch = useCallback(
    () => navigate('AccountSwitch'),
    [navigate],
  );
  const goToPaymentsHistory = useCallback(
    () => navigate('WithdrawalHistory'),
    [navigate],
  );
  const goToSubscriptions = useCallback(() => {
    hide();
    navigate('Plan');
  }, [hide, navigate]);
  const goToNotifications = useCallback(() => {
    hide();
    navigate('Notifications');
  }, [hide, navigate]);
  const goToAuth = useCallback(() => navigate('Auth'), [navigate]);
  const goToTutorial = useCallback(() => {
    hide();
    navigate('Tutorial');
  }, [hide, navigate]);
  const goToPlayStore = useGoToPlayStore();
  const goToAppStore = useGoToAppStore();
  const goToDescription = useOpenLink(configuration.values.aboutUrl);
  const goToPlayStoreSubscriptions = useOpenLink(
    configuration.values.playStoreSubscriptionsUrl,
  );
  const goToAppStoreSubscriptions = useOpenLink(
    configuration.values.appStoreSubscriptionsUrl,
  );
  const goToAuthAndThenOpenAffiliate = useCallback(
    () => navigationContainer.ref?.navigate('Auth'),
    [navigationContainer],
  );
  const {safelyGoToAffiliate, getAffiliateIsPending} = useSafelyGoToAffiliate(
    goToAuthAndThenOpenAffiliate,
  );
  const isLoading = expr(getAffiliateIsPending);
  return (
    <Animatable.View animation="fadeInDown" duration={300} style={styles.root}>
      <View style={styles.content}>
        <View style={styles.listView}>
          <ItemAvailableSlots
            goToSubscriptions={goToSubscriptions}
            goToPlayStoreSubscriptions={goToPlayStoreSubscriptions}
            goToAppStoreSubscriptions={goToAppStoreSubscriptions}
          />
          <Item
            onPress={goToAccountSwitch}
            title={strings['menu.switchFarm']}
          />
          <Item
            onPress={goToPaymentsHistory}
            title={strings['menu.paymentsHistory']}
          />
          <Item
            onPress={goToNotifications}
            title={strings['menu.notifications']}
          />
          <Item onPress={goToTutorial} title={strings['menu.tutorial']} />
          <Item onPress={goToDescription} title={strings['menu.about']} />
          <Item
            loading={isLoading}
            onPress={safelyGoToAffiliate}
            title={strings['menu.affiliate']}
          />

          {auth.accountType === AccountType.Temporary ? (
            <Item title={strings['menu.signUp']} onPress={goToAuth} isLast />
          ) : (
            <>
              <Item title={strings['menu.resignUp']} onPress={goToAuth} />
              <Item title={strings['menu.signOut']} onPress={logout} isLast />
            </>
          )}
          <ItemStoreLinks
            goToPlayStore={goToPlayStore}
            goToAppStore={goToAppStore}
          />
        </View>
      </View>
    </Animatable.View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    top: '100%',
    right: 0,
    backgroundColor: theme.palette.background,
    shadowColor: 'rgba(0, 0, 0, 0.3)',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 4,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: theme.palette.border,
    zIndex: 2,
    borderTopWidth: 0,
  },
  content: {
    backgroundColor: theme.select(
      theme.palette.background,
      theme.palette.backdrop,
    ),
  },
  listView: {},

  itemView: {
    paddingHorizontal: 20,
    paddingVertical: 17,
  },
  itemText: {},
}));
