import React, {useCallback, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot} from '../Root/hooks';
import {useOpenLinkCallback} from '../Links';
import {AdInfoAction} from '../Advert';
import {useTimer} from 'use-timer';
import AdvertSplashScreen from '../AdvertSplash';

export default observer(function PushAdvertSplashContainer() {
  const {pushAdvert} = useRoot();

  const onClose = useCallback(() => pushAdvert.close(), [pushAdvert]);

  const openLink = useOpenLinkCallback();
  const onButtonActionPress = useCallback(
    async (action: AdInfoAction) => {
      if (action.link) {
        await openLink(action.link);
      }
      onClose();
    },
    [onClose, openLink],
  );

  const {time, start} = useTimer({
    autostart: false,
    initialTime: 60,
    endTime: 0,
    timerType: 'DECREMENTAL',
    onTimeOver: onClose,
  });

  const banner = pushAdvert.adToShow
    ? pushAdvert.state?.get(pushAdvert.adToShow)
    : null;

  useEffect(() => {
    if (banner) {
      start();
      pushAdvert.onAdShown();
    }
  }, [banner, pushAdvert, start]);

  if (pushAdvert.adToShow === undefined || pushAdvert.state === undefined) {
    return null;
  }

  if (!banner) {
    return null;
  }

  return (
    <AdvertSplashScreen
      seconds={time}
      onButtonActionPress={onButtonActionPress}
      onCrossClose={onClose}
      advertItem={banner}
      onClose={onClose}
    />
  );
});
