import {
  InAppPurchaseManager,
  ProductStorePurchase,
  Purchase,
  PurchaseResult,
  SubscriptionStorePurchase,
} from './InAppPurchaseManager';
import {Either} from '../fp';
import {ReadyState} from '../Connection';
import {RouterImpl} from '../structure';
import {RestoreResult} from '../ApiStore';
import {ErrorRepository} from '../ErrorRepository';
import {FinishPendingPurchasesResult} from '../PendingPurchasesResolver';
import {
  Purchase as OriginalInAppPurchase,
  SubscriptionPurchase,
} from 'react-native-iap';
import {GlobalError} from '../Error';

export default class StubInAppPurchaseManagerImpl
  implements InAppPurchaseManager
{
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
    },
  ) {}

  get iapReadyState() {
    return ReadyState.Closed;
  }

  async reconnect() {}

  async getAvailablePurchases(): Promise<Either<Purchase[], GlobalError>> {
    throw 'Not implemented on this platform';
  }

  async getSubscriptions(): Promise<
    Either<SubscriptionStorePurchase[], GlobalError>
  > {
    throw 'Not implemented on this platform';
  }

  async getOriginalAvailablePurchases(): Promise<
    Either<(OriginalInAppPurchase | SubscriptionPurchase)[], GlobalError>
  > {
    throw 'Not implemented on this platform';
  }

  async requestSubscription(): Promise<PurchaseResult> {
    throw 'Not implemented on this platform';
  }

  async finishPendingPurchases(): Promise<FinishPendingPurchasesResult> {
    throw 'Not implemented on this platform';
  }

  async authByPurchaseHistory(): Promise<Either<RestoreResult[], GlobalError>> {
    throw 'Not implemented on this platform';
  }

  async getProducts(): Promise<Either<ProductStorePurchase[], GlobalError>> {
    throw 'Not implemented on this platform';
  }

  async requestProduct(): Promise<PurchaseResult> {
    throw 'Not implemented on this platform';
  }

  async requestPurchase(): Promise<PurchaseResult> {
    throw 'Not implemented on this platform';
  }

  public readonly events = new RouterImpl();

  subscribe() {
    return undefined;
  }
}
