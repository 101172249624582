import React from 'react';
import {observer} from 'mobx-react-lite';
import Svg, {Circle, Path} from 'react-native-svg';
import {View as AnimatableView} from 'react-native-animatable';
import {variance} from '../styling';

export default observer(function LoaderIndicator() {
  return (
    <RootView animation="rotate" easing="linear" iterationCount="infinite">
      <Svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <Circle
          opacity="0.5"
          cx="10.144"
          cy="10.1448"
          r="8"
          transform="rotate(54.9137 10.144 10.1448)"
          stroke="#F7931A"
          strokeWidth="3"
        />
        <Path
          d="M3.59767 14.7432C1.058 11.1278 1.93008 6.13814 5.5455 3.59847C9.16092 1.05881 14.1506 1.93088 16.6903 5.5463"
          stroke="#F7931A"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </Svg>
    </RootView>
  );
});

const RootView = variance(AnimatableView)(() => ({
  root: {
    width: 20,
    height: 20,
  },
}));
