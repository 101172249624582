import {intersection, sortBy} from 'lodash';
import {SubscriptionAndroid} from 'react-native-iap';
import parseMicros from './parseMicros';
import getOfferPricingPhaseValue from './getOfferPricingPhaseValue';
import {Trial} from '../InAppOffersService';
import parseAndroidTrialPeriod from './parseAndroidTrialPeriod';

export type NormalizeSubscriptionResult = {
  token: string;
  offerPrice: number;
  trial: Trial | null;
  currency: string;
} | null;

export default (
  sub: SubscriptionAndroid,
  priorityTags: string[],
): NormalizeSubscriptionResult => {
  // Sort by count of priority tags to prioritize first
  const sortedDetails = sortBy(sub.subscriptionOfferDetails, (_) => {
    const diff = intersection(_.offerTags, priorityTags).length;
    const max = Math.max(_.offerTags.length, priorityTags.length);
    if (max === 0) {
      return 0;
    }
    return -(diff / max);
  });
  const offer = sortedDetails[0];
  if (!offer) {
    return null;
  }

  const {pricingPhaseList} = offer.pricingPhases;
  const offerFreePeriodPhaseIndex = pricingPhaseList.findIndex(
    (_) => parseMicros(_.priceAmountMicros) === 0,
  );
  const offerPaidPeriodPhaseIndex = pricingPhaseList.findIndex(
    (_) => parseMicros(_.priceAmountMicros) !== 0,
  );

  const offerMicros =
    offerPaidPeriodPhaseIndex > -1
      ? pricingPhaseList[offerPaidPeriodPhaseIndex].priceAmountMicros
      : undefined;
  const offerPrice = offerMicros ? parseMicros(offerMicros) : undefined;

  const freePeriod =
    offerFreePeriodPhaseIndex > -1
      ? pricingPhaseList[offerFreePeriodPhaseIndex]?.billingPeriod
      : undefined;
  const offerToken = offer.offerToken;
  const currency = getOfferPricingPhaseValue(sub, 'priceCurrencyCode');

  if (offerPrice === undefined || currency === undefined) {
    return null;
  }

  const parsedTrial = freePeriod ? parseAndroidTrialPeriod(freePeriod) : null;

  const trial = parsedTrial
    ? {
        value: parsedTrial.value,
        unit: parsedTrial.unit,
      }
    : null;

  return {
    token: offerToken,
    offerPrice: offerPrice,
    trial,
    currency,
  };
};
