import {
  DeviceId,
  RegisterDeviceError,
  RegisterDeviceErrorReason,
  RegisterDeviceResult,
} from './DeviceIdentification';
import {UniqueId} from './getUniqueId';
import {Either, error, success} from '../fp';
import delayResolve from '../utils/delayResolve';
import {Millisecond} from '../utils/time';
import {define, DEVICE_ID} from '../persistence';
import {AdvertId, PlatformName} from '../ApiStore';
import {Http} from '../Http';
import {JsonSerializable} from '../Json';

export interface RegisterDeviceParams {
  install_referrer?: string;
  platform: PlatformName;
  version: string;
  device_id: UniqueId;
  attribution_map?: Partial<Record<string, JsonSerializable>>; // Optional. Example: {"tenjin": {"aifa": "eb3758ee-ef20-11ed-902d-acde48001122"}}
  app_instance_id?: string;
}

/**
 * @throws {never}
 */
export default async (
  root: {readonly http: Http},
  params: RegisterDeviceParams,
  apiUrl: string,
  timeout: Millisecond,
): Promise<Either<RegisterDeviceResult, RegisterDeviceError>> => {
  const getDevice_ = await getDevice();
  if (!getDevice_.success) {
    return error({
      reason: RegisterDeviceErrorReason.LocalStorage,
      raw: getDevice_.left,
    });
  }
  if (getDevice_.right !== null && getDevice_.right.advert_id !== undefined) {
    return success({
      deviceId: getDevice_.right.id,
      advertId: getDevice_.right.advert_id ?? undefined,
    });
  }
  let response;
  try {
    response = await Promise.race([
      root.http.fetch(`${apiUrl}registration/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        credentials: 'omit',
        body: JSON.stringify(params),
      }),
      delayResolve(timeout, () => null),
    ]);
  } catch (raw) {
    return error({reason: RegisterDeviceErrorReason.NoInternet, raw});
  }
  if (response === null) {
    return error({
      reason: RegisterDeviceErrorReason.Timeout,
      raw: new Error('Timeout'),
    });
  }
  try {
    if (response.ok) {
      const body: DeviceRecord = await response.json();
      if (typeof body === 'object' && typeof body.id === 'string') {
        await setDevice(body);
        return success({
          deviceId: body.id,
          advertId: body.advert_id ?? undefined,
        });
      }
      return error({
        reason: RegisterDeviceErrorReason.BadResponse,
        raw: new Error('Inappropriate HTTP response body'),
      });
    } else {
      if (response.status >= 500 && response.status < 600) {
        return error({
          reason: RegisterDeviceErrorReason.ServiceUnavailable,
          raw: new Error('Service unavailable'),
        });
      }
      return error({
        reason: RegisterDeviceErrorReason.BadResponse,
        raw: new Error('Inappropriate HTTP response status'),
      });
    }
  } catch (raw) {
    return error({reason: RegisterDeviceErrorReason.BadResponse, raw});
  }
};

export const purge = () => setDevice();

interface DeviceRecord {
  id: DeviceId;
  advert_id: AdvertId | null;
}

const [getDevice, setDevice] = define<DeviceRecord>(DEVICE_ID);
