import {useRoot} from '../Root/hooks';
import {useMemo} from 'react';

export default () => {
  const root = useRoot();
  return useMemo(
    () =>
      root.configuration.customEnvironments.length > 0
        ? root.configuration.nextEnvironment
        : undefined,
    [root],
  );
};
