import React from 'react';
import {observer} from 'mobx-react-lite';
import ContainerView from './ContainerView';
import {View, Text} from 'react-native';
import IdLabel from './IdLabel';
import {SubscriptionViewProps} from './SubscriptionView';
import {useStyles} from '../styling';
import {useStrings} from '../Root/hooks';

export type RestoredSubscriptionViewProps = SubscriptionViewProps & {
  label: React.ReactNode;
};

export default observer((props: RestoredSubscriptionViewProps) => {
  const {accountId, maxSlots, slots, label, ...rest} = props;
  const strings = useStrings();
  const styles = useStyles((theme) => ({
    root: {
      flexDirection: 'row',
    },
    col: {
      flex: 1,
    },
    idCol: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    rightCol: {
      alignItems: 'flex-end',
    },
    idRow: {
      alignItems: 'center',
    },
    id: {
      marginEnd: 10,
    },
    bottomRow: {
      marginTop: 5,
      flexDirection: 'row',
    },
    shrink: {},
    slotsText: {
      ...theme.fontByWeight('700'),
      fontSize: 14,
      lineHeight: 17,
      color: theme.palette.textPrimary,
    },
    slotsCountText: {
      ...theme.fontByWeight('400'),
    },
  }));
  return (
    <ContainerView {...rest}>
      <View style={styles.root}>
        <View style={[styles.col, styles.idCol]}>
          <IdLabel style={styles.id} accountId={accountId} />
        </View>
        <View style={[styles.col, styles.rightCol]}>
          <View>{label}</View>
          <View style={styles.bottomRow}>
            <Text style={styles.slotsText}>
              {strings['accountSwitch.slots']}:{' '}
              <Text style={styles.slotsCountText}>
                {slots} / {maxSlots}
              </Text>
            </Text>
          </View>
        </View>
      </View>
    </ContainerView>
  );
});
