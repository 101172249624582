import {
  ActivatorOffer,
  MinerOffer,
  Offer,
  PaymentMethod,
} from '../InAppOffersService';
import {sortBy} from 'lodash';
import {Platform} from 'react-native';

export default abstract class StaticProposalsHelper {
  private static readonly PERCENT = 0.5;

  private static _discard<O extends Offer>(offers: O[]) {
    if (Platform.OS === 'web') {
      return offers.map((_) => ({available: true, offer: _}));
    }
    return offers.flatMap((_, index) => {
      const payment = _.payments.get(PaymentMethod.InApp);
      const prev = offers[index - 1];
      if (!payment) {
        return [];
      }
      if (!prev) {
        return [
          {
            offer: _,
            available: true,
          },
        ];
      }
      const prevPayment = prev.payments.get(PaymentMethod.InApp);
      if (prevPayment) {
        const diff = 100 - (prevPayment.price / payment.price) * 100;
        if (diff <= StaticProposalsHelper.PERCENT) {
          return [
            {
              offer: _,
              available: false,
            },
          ];
        }
      }
      return [
        {
          offer: _,
          available: true,
        },
      ];
    });
  }

  static discardUnprofitableMiners<O extends MinerOffer>(offers: O[]) {
    const sorted = sortBy(offers, (offer) =>
      Number(offer.poolMinerConfig.hash_rate),
    );
    return StaticProposalsHelper._discard(sorted);
  }
  static discardUnprofitableActivators<O extends ActivatorOffer>(offers: O[]) {
    const sorted = sortBy(offers, (offer) =>
      Number(offer.options.activate_pm_after),
    );
    return StaticProposalsHelper._discard(sorted);
  }
}
