import {useStrings} from '../Root/hooks';
import {LocaleDict} from '../translation/LocaleStrings';

const getReadableColor = (speed: number, strings: LocaleDict): string => {
  switch (speed) {
    case 0:
      return strings['scheduler.power.off'];
    default:
      return String(speed) + '%';
  }
};

export default getReadableColor;

export const useGetReadableColor = (speed: number) => {
  const strings = useStrings();
  return getReadableColor(speed, strings);
};
