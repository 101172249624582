export default <T extends any>(
  one: readonly T[],
  another: readonly T[],
  compare: (a: T, b: T, index: number) => boolean,
) =>
  one.length === another.length &&
  one.every((a, i) => {
    const b = another[i];
    return compare(a, b, i);
  });
