import {useCallback} from 'react';
import useHandleErrorFactory, {
  ErrorHandlerSample,
  HookHandleErrorFactoryParams,
  OccurredErrorResult,
} from './useHandleErrorFactory';
import {useRoot, useStrings} from '../Root/hooks';
import {showMessage} from 'react-native-flash-message';

export type HookHandleErrorAndNavigateParams = {
  overridden?: Partial<HookHandleErrorFactoryParams>;
};

export default function useHandleErrorAndNavigate(
  params?: HookHandleErrorAndNavigateParams,
): ErrorHandlerSample {
  const {basicErrorModalState, notAllowedModalState} = useRoot();
  const strings = useStrings();
  const onOccurredError = useCallback(
    (error: unknown, result: OccurredErrorResult) =>
      basicErrorModalState.setVisibleWithDescription(result.localizedError),
    [basicErrorModalState],
  );
  const onWorkerNotManaged = useCallback(() => {
    showMessage({
      message: strings['error.3007'],
      duration: 3000,
      type: 'danger',
    });
  }, [strings]);
  const onGroupNameIsAlreadyInUse = useCallback(
    () =>
      showMessage({
        message: strings['error.3008'],
        type: 'danger',
        duration: 3000,
      }),
    [strings],
  );
  const onMethodNotAllowedForYourSubscription = useCallback(() => {
    notAllowedModalState.setVisibleWithDescription(
      strings['error.groupAction'],
    );
  }, [notAllowedModalState, strings]);
  const {overridden = {}} = params ?? {};
  return useHandleErrorFactory({
    onOccurredError,
    onWorkerNotManaged,
    onGroupNameIsAlreadyInUse,
    onMethodNotAllowedForYourSubscription,
    ...overridden,
  });
}
