import React from 'react';
import {View, Text} from 'react-native';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  PressableOpacity,
} from '../../../components';
import PlusSvg from '../../../assets/svg/colorless/plus.svg';
import {useRoot, useStrings} from '../../../Root/hooks';
import {observer} from 'mobx-react-lite';
import {Container} from '../../../components/Grid';
import {createStylesHook, useTheme} from '../../../styling';
import {GROUP_FOOTER_HEIGHT} from '../../constants';
import {sized} from '../../../Svg';
import ChevronDownSvg from '../../../assets/svg/colorless/chevronDown.svg';
import {DESKTOP_BREAKPOINT} from '../../../units/constants';
import {Hoverable} from 'react-native-web-hooks';

interface WorkerGroupShowMoreProps {
  onAddPress: () => void;
  onMorePress: () => void;
  needShownQuantity: number;
}

export default observer((props: WorkerGroupShowMoreProps) => {
  const {onMorePress, onAddPress, needShownQuantity} = props;
  const {currentSubscriptionState} = useRoot();
  const {isPlatformSupported} = currentSubscriptionState;
  const strings = useStrings();
  const theme = useTheme();
  const styles = useStyles();

  return (
    <Container>
      <View style={styles.root}>
        <Hoverable>
          {(isHovered) => (
            <PressableOpacity style={styles.showMore} onPress={onMorePress}>
              <Text
                style={[
                  styles.showMoreSlotsText,
                  isHovered && styles.hoveredShowMoreSlotsText,
                ]}>
                {strings['workerGroup.showMore']}
              </Text>
              <View style={styles.slotNumber}>
                <Text style={styles.slotNumberText}>{needShownQuantity}</Text>
              </View>
              <ChevronDownIcon color={theme.palette.textPrimary} />
            </PressableOpacity>
          )}
        </Hoverable>
        <View style={styles.addSlots}>
          <PressableOpacity
            onPress={onAddPress}
            style={styles.touchableButtonView}
            disabled={!isPlatformSupported}>
            <Button
              style={styles.button}
              Icon={PlusIcon}
              color={ButtonColor.Primary}
              variant={
                isPlatformSupported
                  ? ButtonVariant.Contained
                  : ButtonVariant.Disabled
              }>
              {strings['workerGroup.addMoreSlots']}
            </Button>
          </PressableOpacity>
        </View>
      </View>
    </Container>
  );
});

const PlusIcon = sized(PlusSvg, 12);

const ChevronDownIcon = sized(ChevronDownSvg, 12, 10);

const useStyles = createStylesHook((theme) => ({
  root: {
    borderTopWidth: 3,
    borderTopColor: theme.palette.primary,
    borderWidth: 1,
    borderColor: theme.palette.border,
    height: GROUP_FOOTER_HEIGHT,
    marginBottom: 10,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    overflow: 'hidden',
  },
  showMore: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    backgroundColor: theme.palette.backdrop,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.border,
  },
  showMoreSlotsText: {
    ...theme.fontByWeight('700'),
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: 14,
    color: theme.palette.secondary,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 13,
        lineHeight: 15,
      },
    }),
  },
  hoveredShowMoreSlotsText: {
    color: theme.palette.primary,
  },
  slotNumber: {
    backgroundColor: theme.palette.backdrop,
    borderRadius: 10,
    paddingHorizontal: 6,
    paddingBottom: 1,
    paddingTop: 2,
    marginLeft: 5,
    marginRight: 6,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        marginLeft: 6,
        paddingBottom: 1,
        paddingTop: 1,
      },
    }),
  },
  slotNumberText: {
    ...theme.fontByWeight('700'),
    fontSize: 10,
    lineHeight: 11,
    color: theme.palette.textSecondary,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 11,
        lineHeight: 13,
      },
    }),
  },
  addSlots: {
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: theme.palette.background,
    paddingVertical: 15,
    paddingHorizontal: 40,
    flexGrow: 1,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
      },
    }),
  },
  touchableButtonView: {
    width: '100%',
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        alignSelf: 'center',
        width: undefined,
      },
    }),
  },
  button: {
    flexGrow: 1,
    paddingRight: 25,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        paddingLeft: 70,
        paddingRight: 70,
      },
    }),
  },
}));
