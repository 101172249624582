import React, {useCallback, useMemo} from 'react';
import {StyleProp, Text, View, ViewStyle} from 'react-native';
import {PanelView, Picker, PickerItem} from '../../components';
import {useStrings} from '../../Root/hooks';
import {observer} from 'mobx-react-lite';
import {createStylesHook} from '../../styling';
import {WorkerOrder} from '../types';
import {DESKTOP_BREAKPOINT} from '../../units/constants';

export interface HeaderFilterProps {
  onOrderChange: (order: WorkerOrder) => void;
  order: WorkerOrder;
  style?: StyleProp<ViewStyle>;
  isEmptyGroup?: boolean;
}

export const HeaderFilter = observer((props: HeaderFilterProps) => {
  const {order, onOrderChange, style, isEmptyGroup} = props;

  const strings = useStrings();
  const items = useMemo(
    () =>
      [
        {
          title: strings['groupFilter.name'],
          id: WorkerOrder.Name,
        },
        {
          title: strings['groupFilter.miningSpeed'],
          id: WorkerOrder.AverageHashrate,
        },
        {
          title: strings['groupFilter.minedTotal'],
          id: WorkerOrder.MinedTotal,
        },
        {
          title: strings['groupFilter.status'],
          id: WorkerOrder.Status,
        },
      ] as const,
    [strings],
  );

  const onPick = useCallback(
    (id?: PickerItem['id']) => {
      if (id === undefined) {
        onOrderChange(WorkerOrder.Default);
      }
      if (typeof id !== 'number') {
        return;
      }
      onOrderChange(id);
    },
    [onOrderChange],
  );

  const styles = useStyles();

  return (
    <View style={[styles.root, isEmptyGroup && styles.emptyGroup, style]}>
      <PanelView style={styles.panelView}>
        <View style={styles.panelLabelView}>
          <Text style={styles.panelLabelText}>{strings['common.sortBy']}</Text>
        </View>
        <Picker
          modalPanelStyle={styles.modalPanel}
          defaultItemTitle={strings['groupFilter.default']}
          style={styles.pickerView}
          items={items}
          pickedItemId={order}
          onPick={onPick}
          closeText={strings['action.cancel']}
        />
      </PanelView>
    </View>
  );
});

const useStyles = createStylesHook((theme) => ({
  root: {
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: theme.palette.border,
    padding: 10,
    height: 55,
    backgroundColor: theme.palette.backdrop,
    overflow: 'hidden',
  },
  panelView: {
    borderRadius: 10,
    flexDirection: 'row',
  },
  panelLabelView: {
    paddingVertical: 7,
    paddingHorizontal: 19,
    borderRightWidth: 1,
    borderRightColor: theme.palette.border,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: theme.palette.backdrop,
    justifyContent: 'center',
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        paddingVertical: 7,
        paddingHorizontal: 19,
        backgroundColor: theme.palette.backdrop,
        justifyContent: 'center',
      },
    }),
  },
  panelLabelText: {
    ...theme.fontByWeight('bold'),
    fontSize: 12,
    lineHeight: 14,
    color: theme.palette.textSecondary,
  },
  pickerView: {
    flex: 1,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    borderWidth: 0,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        borderLeftWidth: 1,
      },
    }),
  },
  modalPanel: {
    width: 220,
  },
  emptyGroup: {
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
}));
