import React from 'react';
import {
  TouchableOpacity,
  View,
  Text,
  TouchableOpacityProps,
} from 'react-native';
import {Divider} from '../../../components/Divider';
import {sized} from '../../../Svg';
import PlusSvg from '../../../assets/svg/colorless/plus.svg';
import {useStyles, useTheme} from '../../../styling';

interface GroupLineProps extends TouchableOpacityProps {
  workerName: string;
}

export const GroupLine = (props: GroupLineProps) => {
  const {workerName, ...rest} = props;
  const styles = useStyles((theme) => ({
    content: {
      paddingVertical: 13,
      paddingHorizontal: 15,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      ...theme.fontByWeight('700'),
      color: theme.palette.textPrimary,
      fontSize: 15,
      marginRight: 5,
    },
    dividerWrapper: {
      paddingHorizontal: 15,
    },
    leftSide: {
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
  }));
  const theme = useTheme();

  return (
    <>
      <TouchableOpacity delayPressIn={0} {...rest}>
        <View style={styles.content}>
          <View style={styles.leftSide}>
            <Text style={styles.title}>{workerName}</Text>
          </View>
          <PlusIcon color={theme.palette.icon} />
        </View>
      </TouchableOpacity>
      <View style={styles.dividerWrapper}>
        <Divider color={theme.palette.border} />
      </View>
    </>
  );
};

const PlusIcon = sized(PlusSvg, 12);
