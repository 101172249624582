import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {
  Banner,
  BannerVariant,
  ButtonVariant,
  SwipeableBanner,
  translateBannerVariantToColors,
} from '../components';
import {Image, View} from 'react-native';

import {sized} from '../Svg';
import AdsBannerBasicIconSvg from '../assets/svg/colorless/ads-banner-basic-icon.svg';
import {createStylesHook, useTheme, variance} from '../styling';
import Button from '../components/Button';
import {AdInfoItem} from '../Advert';
import {useRoot} from '../Root/hooks';
import {useOpenLinkCallback} from '../Links';
import useLayout from '../ReactNativeUtil/useLayout';
import {first} from 'lodash';
import {DESKTOP_BREAKPOINT} from '../units/constants';
import ArrowRightSvg from '../assets/svg/colorless/arrow.svg';
const VARIANT = BannerVariant.Primary;

export type AdsBannerProps = {
  isDesktop: boolean;
  advertItem: AdInfoItem;
};

export default observer(function AdsBanner({
  isDesktop,
  advertItem,
}: AdsBannerProps) {
  const {advertHelper} = useRoot();
  const openLink = useOpenLinkCallback();
  const theme = useTheme();
  const {id, actions, title, icon, spot} = advertItem;
  const kindColor = useMemo(
    () => translateBannerVariantToColors(VARIANT, theme),
    [theme],
  );
  const styles = useStyles();
  const action = first(actions);
  const onPress = useCallback(() => {
    if (!action) {
      return;
    }
    if (action.link) {
      openLink(action.link);
    }
    advertHelper.close(id, spot, true);
  }, [action, advertHelper, id, openLink, spot]);

  const hasAction = action !== undefined;
  const right =
    action !== undefined && isDesktop ? (
      <View style={styles.actions}>
        <Button
          iconPosition="left"
          style={styles.actionButton}
          Icon={ArrowIcon}
          variant={ButtonVariant.Highlighted}
        />
      </View>
    ) : null;
  const translatedTitle = advertHelper.translate(title);

  const [layout, onLayout] = useLayout();

  const visibleLeftIcon = layout ? layout.width > 300 : true;
  const content = (
    <View onLayout={onLayout}>
      <Banner
        onPress={hasAction ? onPress : undefined}
        variant={VARIANT}
        title={translatedTitle}
        leftAccessory={
          visibleLeftIcon &&
          (layout && icon !== undefined ? (
            <LeftView>
              <BannerImage
                source={{
                  uri: icon,
                }}
                resizeMode="contain"
              />
            </LeftView>
          ) : (
            <LeftTicketView>
              <TicketIcon color={kindColor.main} />
            </LeftTicketView>
          ))
        }
        leftContainerStyle={styles.leftContainerStyle}
        contentContainerStyle={styles.contentContainerStyle}
        rightAccessory={right}
        visibleChevron={hasAction}
        style={styles.bannerStyle}
        wrapperStyle={styles.bannerWrapperStyle}
      />
    </View>
  );
  if (isDesktop && advertItem.options.canClose) {
    return (
      <SwipeableBanner
        variant={VARIANT}
        onDelete={() =>
          advertHelper.close(advertItem.id, advertItem.spot, false)
        }>
        {content}
      </SwipeableBanner>
    );
  }
  return <View>{content}</View>;
});

const useStyles = createStylesHook((theme) => ({
  actions: {
    flexDirection: 'row',
  },
  actionButton: {width: 60},
  bannerStyle: {
    borderRadius: 10,
  },
  bannerWrapperStyle: {
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  offset: {
    marginRight: 5,
  },
  leftContainerStyle: {
    paddingVertical: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  contentContainerStyle: {
    paddingLeft: 15,
  },
  titleStyle: {
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 20,
        lineHeight: 32,
      },
    }),
  },
  descriptionStyle: {
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        fontSize: 16,
        lineHeight: 26,
        color: theme.palette.darkText,
      },
    }),
  },
}));
const ArrowIcon = sized(ArrowRightSvg, 20, 20);
const TicketIcon = sized(AdsBannerBasicIconSvg, 48);

const BannerImage = variance(Image)(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
}));

const LeftTicketView = variance(View)(() => ({
  root: {
    paddingLeft: 15,
  },
}));

const LeftView = variance(View)((theme) => ({
  root: {
    width: 86,
    height: 50,
    ...theme.mediaQuery({
      [DESKTOP_BREAKPOINT]: {
        height: 70,
        width: 121,
      },
    }),
  },
}));
