import {LocationSource} from './LocationSource';
import {BusImpl, Disposer, Service} from '../structure';
import {Either, error, success} from '../fp';
import {Url} from '../units';
import dynamicLinks from '@react-native-firebase/dynamic-links';
import {CachedConsumableImpl} from '../CachedConsumable';

export default class DynamicLinksLocationSourceService
  implements LocationSource, Service
{
  private readonly _initialLink = new CachedConsumableImpl(() =>
    dynamicLinks().getInitialLink(),
  );

  async getInitial(): Promise<Either<Url, unknown>> {
    try {
      const link = await this._initialLink.getCachedConsumable();
      if (link) {
        return success(link.url as Url);
      }
      return error(undefined);
    } catch (raw) {
      return error(raw);
    }
  }

  public readonly updates = new BusImpl<Url>();

  subscribe() {
    return dynamicLinks().onLink((link) =>
      this.updates.send(link.url as Url),
    ) as Disposer;
  }
}
