import {action, flow, observable, makeObservable} from 'mobx';
import {DAY, HOUR, Millisecond} from '../utils/time';
import {ChartViewScope, Graph} from '../GraphPanel';
import {ReadonlyDeep} from 'type-fest';
import graphPeriod from '../GraphPanel/graphPeriod';
import {DashboardStore} from '../../universal/screen/Dashboard/model/DashboardStore';
import {GetMiningStatisticResponse} from '../../universal/features/api/entity/GetMiningStatisticResponse';
import miningStatisticGraph from '../helpers/miningStatisticGraph';
import {SocketApiService} from '../../universal/features/api/socket/SocketApiService';
import {CancellablePromise} from '../CancellablePromise';

export default class StatisticsPanelState {
  @observable private _from?: Millisecond;
  @observable private _scope = ChartViewScope.Day;
  @observable.ref protected _graph?: ReadonlyDeep<Graph>;
  @observable protected _isLoading = false;

  constructor(
    private readonly _root: {
      readonly dashboardStore: DashboardStore;
      readonly socketApi: SocketApiService;
    },
  ) {
    makeObservable(this);
  }

  get period() {
    return graphPeriod(this._from, this._scope);
  }

  get scope() {
    return this._scope;
  }

  get graph() {
    return this._graph;
  }

  get isLoading() {
    return this._isLoading;
  }

  @action.bound onFromChange(from: Millisecond) {
    this._from = from;
  }

  @action.bound onScopeChange(scope: ChartViewScope) {
    this._scope = scope;
  }

  private _fetch = flow(function* (
    this: StatisticsPanelState,
    groupId?: number,
  ) {
    const allGroups = this._root.dashboardStore.dashboardRequest.value?.groups;
    const groups =
      groupId !== undefined
        ? allGroups?.filter((_) => _.id === groupId)
        : allGroups;
    const workerIds = groups?.flatMap((group) =>
      group.workers.map((_) => _.id),
    );
    if (workerIds !== undefined) {
      try {
        this._isLoading = true;
        const {from, to} = this.period;
        const scope = this._scope;
        const result: GetMiningStatisticResponse =
          yield this._root.socketApi.getMiningStatistic({
            interval: scope === ChartViewScope.Day ? 'hourly' : 'daily',
            start_time: Math.floor(from / 1000),
            end_time: Math.floor(to / 1000),
            worker_ids: workerIds,
          });
        this._graph = miningStatisticGraph(
          result,
          scope === ChartViewScope.Day ? HOUR : DAY,
        );
      } catch (error) {
      } finally {
        this._isLoading = false;
      }
    }
  });

  private _flow?: CancellablePromise<void>;

  /**
   * @throws {never}
   */
  async fetch(...args: Parameters<StatisticsPanelState['_fetch']>) {
    this._flow?.cancel();
    try {
      this._flow = this._fetch(...args);
      await this._flow;
    } catch (ignore) {}
  }
}
