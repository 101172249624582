import {
  DoubleProposal,
  GroupContextProposal,
  ManagedProposalSubscription,
  Proposal,
  ProposalByInterval,
} from '../../ProposalsState';
import {
  MinerOffer,
  MinerProductOffer,
  MinerSubscriptionOffer,
  PaymentMethod,
  SlotSubscriptionOffer,
} from '../../InAppOffersService';
import {PurchaseId} from '../../units';
import {Hashrate, Interval} from '../../ApiStore';
import {MinerConfig} from '../../ProposalsState/MinerProposalsState';

export interface PurchaseScreenState {
  readonly visibleFreeMinerSection: boolean;
  readonly selectedTab: PurchaseTabsKind;
  readonly selectedSlotProposal: Proposal<SlotSubscriptionOffer> | undefined;
  readonly slotProposals: Proposal<SlotSubscriptionOffer>[] | undefined;
  readonly selectedHashrate?: Hashrate;
  selectHashrate(hashrate: Hashrate, persist?: boolean): void;
  readonly selectedInterval?: Interval;
  selectInterval(interval: Interval): void;
  selectSlotInterval(interval: Interval): void;
  selectMaxSlots(max: number): void;
  readonly selectedSlotInterval?: Interval;
  readonly selectedMaxSlot?: number;
  getDoubleProposalForPurchase: () =>
    | Proposal<MinerProductOffer>
    | ManagedProposalSubscription<MinerSubscriptionOffer>
    | undefined;
  readonly selectedDoubleProposal: DoubleProposal | undefined;
  readonly minerConfigs: MinerConfig[];
  readonly availableHashrateConfigList: MinerOffer[] | undefined;
  readonly doubleProposalByIntervalByHashrate: ReadonlyMap<
    Hashrate,
    ProposalByInterval<GroupContextProposal<DoubleProposal>>
  >;
  readonly uniqIntervalList: Interval[];
  readonly slotUniqIntervalList: Interval[];
  selectSlotPurchaseId: (id?: PurchaseId) => void;
  selectedPaymentMethod: PaymentMethod;
  selectPaymentMethod(method: PaymentMethod): void;
  selectTab(newTab: PurchaseTabsKind): void;
  init(initialTab: PurchaseTabsKind): void;
  blur(): void;
}

export enum PurchaseMinerKind {
  Product,
  Subscription,
}

export enum PurchaseTabsKind {
  Slot,
  Miner,
}
