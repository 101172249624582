import React from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {Text, View} from 'react-native';
import QuickStartItem, {
  QuickStartItemParams,
} from '../components/QuickStartItem';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  PressableOpacity,
} from '../components';
import CashInBagSvg from '../assets/svg/colored/cashInBag.svg';
import SwitchSvg from '../assets/svg/colorless/switch.svg';
import {sized} from '../Svg';
import {useStrings} from '../Root/hooks';

export type FinishQuickStartViewProps = {
  selectedItem: QuickStartItemParams | undefined;
  maxItem: QuickStartItemParams;
  onContinue: () => void;
  onBuyProfitablePress: () => void;
};

export default observer(function FinishQuickStartView(
  props: FinishQuickStartViewProps,
) {
  const {selectedItem, maxItem, onBuyProfitablePress, onContinue} = props;
  const strings = useStrings();
  return (
    <RootView>
      <IconView>
        <CashInBagIcon />
      </IconView>
      <TitleText>{strings['finishQuickStart.title']}</TitleText>
      <DescriptionText>
        {strings['finishQuickStart.description']}
      </DescriptionText>
      {selectedItem !== undefined && (
        <SelectedItemView>
          <QuickStartItem onPress={noop} selected={false} {...selectedItem} />
        </SelectedItemView>
      )}
      <RecommendedItemView>
        <QuickStartItem
          onPress={noop}
          selected={false}
          recommended
          {...maxItem}
        />
      </RecommendedItemView>
      <PressableOpacity onPress={onBuyProfitablePress}>
        <StyledButton
          Icon={SwitchSvg}
          offsetBottom
          variant={ButtonVariant.Highlighted}>
          {strings['finishQuickStart.switch']}
        </StyledButton>
      </PressableOpacity>
      <PressableOpacity onPress={onContinue}>
        <StyledButton color={ButtonColor.Primary}>
          {strings['finishQuickStart.keep']}
        </StyledButton>
      </PressableOpacity>
    </RootView>
  );
});

const CashInBagIcon = sized(CashInBagSvg, 60);

const noop = () => {};

const RootView = variance(View)(() => ({
  root: {
    paddingHorizontal: 16,
    paddingBottom: 24,
    paddingTop: 28,
  },
}));

const IconView = variance(View)(() => ({
  root: {
    alignItems: 'center',
    marginBottom: 20,
  },
}));

const SelectedItemView = variance(View)(() => ({
  root: {
    marginBottom: 20,
  },
}));

const RecommendedItemView = variance(View)(() => ({
  root: {
    marginBottom: 20,
  },
}));

const TitleText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 22,
    lineHeight: 32,
    textAlign: 'center',
    marginBottom: 10,
  },
}));

const DescriptionText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textPrimary,
    textAlign: 'center',
    fontSize: 14,
    lineHeight: 22,
    marginBottom: 20,
  },
}));

const StyledButton = variance(Button)(() => ({
  root: {},
  offsetBottom: {
    marginBottom: 15,
  },
}));
