import {Sharing} from './Sharing';
import {ErrorRepository} from '../ErrorRepository';
import {PLATFORM_IS_NOT_SUPPORTED, PlatformIsNotSupported} from '../Error';
import {Either, error} from '../fp';

export default class StubSharingImpl implements Sharing {
  constructor(
    private readonly _root: {
      readonly errorRepository: ErrorRepository;
    },
  ) {}

  async shareFile(): Promise<Either<void, PlatformIsNotSupported>> {
    return error(
      this._root.errorRepository.create({
        kind: PLATFORM_IS_NOT_SUPPORTED,
      }),
    );
  }
}
