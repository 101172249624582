import {Url} from '../units';
import {useCallback} from 'react';
import {PostMessageResponse} from './TutorialViewProps';

export type TutorialProcessParams = {
  onClose: () => void;
  onComplete: () => void;
  onAdvertPress: (url: Url) => void;
  onUrlPress: (url: Url) => void;
};

export default function useProcessWebMessageData({
  onComplete,
  onAdvertPress,
  onClose,
  onUrlPress,
}: TutorialProcessParams) {
  return useCallback(
    (data: PostMessageResponse) => {
      switch (data.action) {
        case 'OpenDashboardFromTutorial':
          onComplete();
          break;
        case 'TutorialWillBeClosed':
          onClose();
          break;
        case 'ClickedOnBanner':
          onAdvertPress(data.url);
          break;
        case 'OpenLink':
          onUrlPress(data.url);
          break;
      }
    },
    [onAdvertPress, onClose, onComplete, onUrlPress],
  );
}
