import {ApiStore, FernetToken} from '../ApiStore';
import {
  AccountType,
  Authenticated,
  AuthorizationErrorReason,
  AuthorizationFailed,
  Authorized,
} from './AuthState';
import {translateAccount} from '../farmApi';
import {setFernetToken} from '../persistence';

/**
 * @throws {never}
 */
export default async (
  state: Authorized | Authenticated | AuthorizationFailed,
  root: {readonly apiStore: ApiStore},
): Promise<Authorized | AuthorizationFailed> => {
  const auth_ = await root.apiStore.client.call('auth', {
    token: state.fernetToken as FernetToken,
  });
  if (!auth_.success) {
    await setFernetToken();
    const reason =
      auth_.left.code === 5008
        ? AuthorizationErrorReason.UpdateRequired
        : AuthorizationErrorReason.Unknown;
    return {
      ...state,
      kind: 'AuthorizationFailed',
      reason,
      raw: auth_.left,
    };
  }
  const account = auth_.right;
  return {
    ...state,
    kind: 'Authorized',
    accountType:
      account.type === 'farm' ? AccountType.Temporary : AccountType.Permanent,
    accountIds: new Set(
      account.type === 'farm' ? [account.id] : account.farms.map((_) => _.id),
    ),
    email: account.type === null ? account.email : undefined,
    subscriptionMap: translateAccount(account),
  };
};
