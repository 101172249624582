import {JsonSerializable} from '../Json';

export type JsonRpcVersion = '2.0';
export type JsonRpcId = number | string | null;

export interface Request<P extends JsonSerializable = JsonSerializable> {
  jsonrpc: JsonRpcVersion;
  method: string;
  params?: P;
  id?: JsonRpcId;
}

export interface SuccessResponse<
  R extends JsonSerializable = JsonSerializable,
> {
  jsonrpc: JsonRpcVersion;
  id: JsonRpcId;
  result: R;
}

export interface JsonRpcError<T extends JsonSerializable = JsonSerializable> {
  code: number;
  message: string;
  data?: T;
}

export interface ErrorResponse<E extends JsonRpcError = JsonRpcError> {
  jsonrpc: JsonRpcVersion;
  id: JsonRpcId;
  error: E;
}

export type Response<
  R extends JsonSerializable = JsonSerializable,
  E extends JsonRpcError = JsonRpcError,
> = SuccessResponse<R> | ErrorResponse<E>;

export const isRequest = (request: unknown): request is Request =>
  typeof request === 'object' &&
  request !== null &&
  !Array.isArray(request) &&
  request.hasOwnProperty('jsonrpc') &&
  (request as any).jsonrpc === '2.0' &&
  request.hasOwnProperty('method') &&
  typeof (request as any).method === 'string' &&
  (!request.hasOwnProperty('id') ||
    typeof (request as any).id === 'number' ||
    typeof (request as any).id === 'string' ||
    (request as any).id === null);

export const isResponse = (response: unknown): response is Response =>
  typeof response === 'object' &&
  response !== null &&
  !Array.isArray(response) &&
  response.hasOwnProperty('jsonrpc') &&
  (response as any).jsonrpc === '2.0' &&
  (response.hasOwnProperty('result') || response.hasOwnProperty('error')) &&
  response.hasOwnProperty('id') &&
  (typeof (response as any).id === 'number' ||
    typeof (response as any).id === 'string' ||
    (response as any).id === null);

export const isSuccess = (response: Response): response is SuccessResponse =>
  response.hasOwnProperty('result');

export const isError = (response: Response): response is ErrorResponse =>
  response.hasOwnProperty('error');
