import {ObservableWebSocketState} from './ObservableWebSocketState';
import {createAtom} from 'mobx';
import {ReadyState} from '../Connection';

export default class ObservableWebSocketStateImpl
  implements ObservableWebSocketState
{
  private readonly _readyState = createAtom('WebSocket#readyState');
  private _closing = false;

  private readonly _changed = () => this._readyState.reportChanged();

  constructor(public readonly ws: WebSocket) {
    this.ws.addEventListener('open', this._changed);
    this.ws.addEventListener('close', this._changed);
  }

  get readyState() {
    this._readyState.reportObserved();
    return this.ws.readyState as ReadyState;
  }

  close() {
    const {readyState} = this.ws;
    this.ws.close();
    if (
      readyState !== ReadyState.Closing &&
      readyState !== ReadyState.Closed &&
      !this._closing
    ) {
      this._closing = true;
      this._readyState.reportChanged();
    }
  }

  dispose() {
    this.ws.removeEventListener('open', this._changed);
    this.ws.removeEventListener('close', this._changed);
  }
}
