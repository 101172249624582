import React, {useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Text, View, ViewProps} from 'react-native';
import {SliderState} from '../forms';
import {useRoot, useStrings} from '../Root/hooks';
import {PanelView, UnitSlider} from '../components';
import BtcSvg from '../assets/svg/colored/btc.svg';
import CoinSvg from '../assets/svg/colored/coin.svg';
import ChevronDownSvg from '../assets/svg/colorless/chevronDown.svg';
import {
  getPluralizationDaysUnit,
  getPluralizationHoursUnit,
} from '../utils/getPluralizationUnit';
import {sized} from '../Svg';
import {useTheme} from '../styling';
import {random} from 'lodash';
import {ScalingValue} from '../components/UnitSlider';
import BtcSliderStateManager from '../forms/BtcSliderStateBuilder';
import BtcPriceUnitSliderManager from './BtcPriceUnitSliderManager';

export default observer(({style, ...rest}: ViewProps) => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      ({
        background: {
          backgroundColor: theme.palette.background,
        },
        root: {
          backgroundColor: theme.select(
            theme.palette.background,
            theme.palette.backdrop,
          ),
        },
        circle: {
          alignSelf: 'center',
          marginTop: 25,
          width: 60,
          height: 60,
          borderRadius: 30,
          borderWidth: 4,
          borderColor: theme.palette.secondary,
          alignItems: 'center',
          justifyContent: 'center',
        },
        calcTitle: {
          marginTop: 15,
          marginHorizontal: 20,
          ...theme.fontByWeight('bold'),
          textAlign: 'center',
          fontSize: 20,
          lineHeight: 30,
          color: theme.palette.textPrimary,
          ...theme.mediaQuery({
            1000: {
              fontSize: 32,
              lineHeight: 32,
            },
          }),
        },
        calcSubtitle: {
          marginTop: 10,
          marginBottom: 30,
          marginHorizontal: 20,
          ...theme.fontByWeight('400'),
          textAlign: 'center',
          fontSize: 13,
          lineHeight: 22,
          color: theme.palette.textSecondary,
          ...theme.mediaQuery({
            1000: {
              fontSize: 18,
              lineHeight: 28,
            },
          }),
        },
        itemWrapperView: {
          ...theme.mediaQuery({
            1000: {
              flexDirection: 'row',
              justifyContent: 'center',
              flexWrap: 'wrap',
            },
          }),
        },
        item: {
          marginBottom: 27,
          marginStart: 43,
          marginEnd: 42,
          ...theme.mediaQuery({
            1000: {
              width: 280,
              marginStart: 15,
              marginEnd: 15,
            },
          }),
        },
        unitIcon: {
          marginEnd: 3,
          marginTop: 3,
        },
        section: {
          paddingVertical: 20,
          paddingHorizontal: 20,
          borderTopWidth: 1,
          borderTopColor: theme.palette.border,
        },
        resultTitleMarker: {
          marginEnd: 7,
        },
        resultTitleView: {
          marginBottom: 15,
          alignSelf: 'center',
          flexDirection: 'row',
          alignItems: 'center',
        },
        resultTitleText: {
          ...theme.fontByWeight(),
          fontSize: 16,
          lineHeight: 22,
          color: theme.palette.textPrimary,
        },
        btcResultValueView: {
          marginBottom: 15,
          paddingVertical: 15,
          paddingHorizontal: 5,
          borderRadius: 3,
          borderWidth: 1,
          borderColor: theme.palette.border,
          flexDirection: 'row',
          justifyContent: 'center',
          ...theme.mediaQuery({
            500: {
              justifyContent: 'center',
              alignItems: 'center',
              width: 500,
              marginLeft: 'auto',
              marginRight: 'auto',
            },
          }),
        },
        resultUnitIconView: {
          alignSelf: 'center',
          marginEnd: 10,
          ...theme.mediaQuery({
            700: {
              marginEnd: 15,
            },
          }),
        },
        btcResultValue: {
          ...theme.fontByWeight('bold'),
          fontSize: 32,
          lineHeight: 44,
          letterSpacing: 0.0914286,
          color: theme.palette.textPrimary,
          ...theme.mediaQuery({
            700: {
              fontSize: 46,
              lineHeight: 53,
              letterSpacing: 0.13,
            },
          }),
        },
        resultValueUnitText: {
          ...theme.fontByWeight('400'),
        },
        resultApprox: {
          marginBottom: 27,
          ...theme.fontByWeight('400'),
          fontSize: 12,
          lineHeight: 16,
          letterSpacing: 0.034,
          textAlign: 'center',
          color: theme.palette.textSecondary,
        },
        resultApproxValue: {
          ...theme.fontByWeight('bold'),
        },
        resultDescription: {
          ...theme.fontByWeight(),
          fontSize: 13,
          lineHeight: 19,
          color: theme.palette.textSecondary,
          textAlign: 'center',
          ...theme.mediaQuery({
            800: {
              fontSize: 18,
              lineHeight: 28,
              ...theme.fontByWeight('400'),
            },
          }),
        },
      } as const),
    [theme],
  );
  const strings = useStrings();
  const root = useRoot();
  const {
    dashboardStore: {
      currentRateRequest: {value: rate},
    },
  } = root;

  const [hashrate] = useState(
    () => new SliderState(random(950000, 1050000), 20000, 9999999, 100),
  );
  const [dailySlider] = useState(() => new SliderState(...median(1, 24, 1)));
  const [yearlySlider] = useState(() => new SliderState(...median(1, 365, 1)));
  const [btcSliderStateManager] = useState(
    () => new BtcSliderStateManager(root),
  );
  const btcSlider = btcSliderStateManager.slider;

  useEffect(() => {
    btcSliderStateManager.subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const totalHashes =
    hashrate.value * 60 * 60 * dailySlider.value * yearlySlider.value;
  const btc =
    rate?.btc !== undefined && rate?.hashes_count !== undefined
      ? (rate.btc / rate.hashes_count) * totalHashes
      : undefined;
  const btcRate =
    rate?.usd !== undefined && rate?.btc !== undefined
      ? rate.usd / rate.btc
      : undefined;
  const usd =
    btcSlider !== undefined && btc !== undefined
      ? btc * btcSlider.value
      : undefined;
  return (
    <View style={[styles.background, style]} {...rest}>
      <PanelView style={styles.root}>
        <View style={styles.circle}>
          <BtcIcon />
        </View>
        <Text style={styles.calcTitle}>
          {strings['statistics.howMuchSection.title']}
        </Text>
        <Text style={styles.calcSubtitle}>
          {strings['statistics.howMuchSection.subtitle']}
        </Text>
        <View style={styles.itemWrapperView}>
          <UnitSlider
            {...hashrate.handlers}
            getValue={hashrate.getValue}
            scaling={ScalingValue.Thousand}
            style={styles.item}
            label={strings['statistics.howMuchSection.averageSlider']}
            unit="H/s"
            UnitIcon={() => (
              <View style={styles.unitIcon}>
                <CoinIcon />
              </View>
            )}
          />
          <UnitSlider
            {...dailySlider.handlers}
            getValue={dailySlider.getValue}
            style={styles.item}
            label={strings['statistics.howMuchSection.workingSlider']}
            unit={getPluralizationHoursUnit(dailySlider.value)}
          />
          <UnitSlider
            {...yearlySlider.handlers}
            getValue={yearlySlider.getValue}
            style={styles.item}
            label={strings['statistics.howMuchSection.periodSliderTitle']}
            unit={getPluralizationDaysUnit(yearlySlider.value)}
          />
          {btcSlider && (
            <BtcPriceUnitSliderManager
              {...btcSlider.handlers}
              getValue={btcSlider.getValue}
              scaling={ScalingValue.Thousand}
              pointer={btcRate}
              style={styles.item}
              label={strings['statistics.howMuchSection.btcPrice']}
              unit="USD"
              priceGuess={btcSliderStateManager.priceGuess}
            />
          )}
        </View>
        <View style={styles.section}>
          <View style={styles.resultTitleView}>
            <View style={styles.resultTitleMarker}>
              <ChevronDownIcon color={theme.palette.primary} />
            </View>
            <Text style={styles.resultTitleText}>
              {strings['statistics.howMuchSection.estimatedEarnings']}
            </Text>
          </View>
          <View style={styles.btcResultValueView}>
            <View style={styles.resultUnitIconView}>
              <BtcIcon />
            </View>
            <Text style={styles.btcResultValue}>
              {btc?.toFixed(7) ?? ''}
              <Text style={styles.resultValueUnitText}> BTC</Text>
            </Text>
          </View>
          <Text style={styles.resultApprox}>
            {usd ? (
              <>
                {strings['common.approxShort']}{' '}
                <Text style={styles.resultApproxValue}>
                  USD ${usd.toFixed(2)}
                </Text>
              </>
            ) : (
              ''
            )}
          </Text>
          <Text style={styles.resultDescription}>
            {strings['statistics.howMuchSection.description']}
          </Text>
        </View>
      </PanelView>
    </View>
  );
});

const BtcIcon = sized(BtcSvg, 36);
const CoinIcon = sized(CoinSvg, 16);
const ChevronDownIcon = sized(ChevronDownSvg, 12);

const median = (min: number, max: number, step: number) => {
  const delta = max - min;
  const start = min + Math.floor(delta / step / 2) * step;
  return [start, min, max, step] as const;
};
