import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {variance} from '../styling';
import {Pressable, View, Text} from 'react-native';
import BitcoinSvg from '../assets/svg/colored/bitcoin.svg';
import DoubleBitcoinSvg from '../assets/svg/colored/double-bitcoin.svg';
import TripleBitcoinSvg from '../assets/svg/colored/triple-bitcoin.svg';
import {sized} from '../Svg';
import {Interval, IntervalType} from '../ApiStore';
import {useRoot, useStrings} from '../Root/hooks';
import getHumanizeDuration, {
  IntervalTypeToUnit,
} from '../PurchaseScreen/getters/getHumanizeDuration';
import dayjs from 'dayjs';
import {Millisecond} from '../Time';

export enum QuickStartItemVariant {
  Low,
  Standard,
  Maximum,
}

export type QuickStartListLabel = 'Recommended' | 'Current';

export type QuickStartItemParams = {
  variant: QuickStartItemVariant;
  name: string;
  hashrate: number;
  price: string;
  interval: Interval;
  intervalType: IntervalType;
  label: QuickStartListLabel | undefined;
  disabled: boolean;
};

export type QuickStartItemProps = QuickStartItemParams & {
  selected: boolean;
  onPress: () => void;
  recommended?: boolean;
};

export default observer(function QuickStartItem(props: QuickStartItemProps) {
  const {
    onPress,
    variant,
    name,
    hashrate,
    price,
    label,
    selected,
    disabled,
    recommended,
    interval,
    intervalType,
  } = props;
  const {translation} = useRoot();
  const strings = useStrings();
  const contentMap = useMemo(
    () => ({
      [QuickStartItemVariant.Low]: {
        Icon: <BitcoinIcon />,
        title: strings['quickStart.lowIncome'],
      },
      [QuickStartItemVariant.Standard]: {
        Icon: <DoubleBitcoinIcon />,
        title: strings['quickStart.standardIncome'],
      },
      [QuickStartItemVariant.Maximum]: {
        Icon: <TripleBitcoinIcon />,
        title: strings['quickStart.maxIncome'],
      },
    }),
    [strings],
  );
  const durationText = getHumanizeDuration(
    dayjs.duration(interval, intervalType).asMilliseconds() as Millisecond,
    translation.localeTag,
    IntervalTypeToUnit(intervalType),
  );
  return (
    <RootPressable
      recommended={recommended}
      disabled={disabled}
      selected={selected}
      onPress={onPress}>
      <ContentView>
        <RowContentView>
          <IconView>{contentMap[variant].Icon}</IconView>
          <TitleText>{contentMap[variant].title}</TitleText>
        </RowContentView>
        <PriceText>
          {price} / {durationText}
        </PriceText>
      </ContentView>
      {selected && (
        <InfoView>
          <InfoText>{name}</InfoText>
          <InfoHashrateText>{hashrate} H/s</InfoHashrateText>
        </InfoView>
      )}
      {label !== undefined && (
        <LabelView orange={label === 'Recommended'}>
          <LabelText>
            {label === 'Recommended'
              ? strings['quickStart.recommended']
              : strings['quickStart.current']}
          </LabelText>
        </LabelView>
      )}
    </RootPressable>
  );
});

const BitcoinIcon = sized(BitcoinSvg, 32);
const DoubleBitcoinIcon = sized(DoubleBitcoinSvg, 32);
const TripleBitcoinIcon = sized(TripleBitcoinSvg, 32);

const RootPressable = variance(Pressable)((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: theme.palette.background,
    borderWidth: 1,
    borderColor: theme.palette.border,
  },
  disabled: {
    backgroundColor: theme.select(
      theme.chroma(theme.palette.foreground).brighten(5).hex(),
      theme.chroma(theme.palette.background).brighten(1.5).hex(),
    ),
  },
  selected: {
    borderColor: theme.palette.info,
    backgroundColor: theme.chroma(theme.palette.info).alpha(0.1).hex(),
  },
  recommended: {
    borderColor: theme.palette.success,
    backgroundColor: theme.chroma(theme.palette.success).alpha(0.1).hex(),
  },
}));

const IconView = variance(View)(() => ({
  root: {
    marginRight: 10,
  },
}));

const TitleText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.textPrimary,
  },
}));

const LabelView = variance(View)((theme) => ({
  root: {
    position: 'absolute',
    right: 12,
    top: -8,
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: '#B3B3B3',
    borderRadius: 15,
  },
  orange: {
    backgroundColor: theme.palette.success,
  },
}));

const LabelText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: '#FFFFFF',
    fontSize: 10,
    lineHeight: 12,
    textTransform: 'uppercase',
  },
}));

const RowContentView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 10,
  },
}));

const ContentView = variance(View)(() => ({
  root: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 18,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const PriceText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
    lineHeight: 20,
    flex: 1,
    marginBottom: 2,
    textAlign: 'right',
  },
}));

const InfoView = variance(View)((theme) => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    padding: 10,
    borderTopWidth: 1,
    borderTopColor: theme.palette.info,
  },
}));

const InfoText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textPrimary,
    fontSize: 12,
    lineHeight: 20,
  },
}));

const InfoHashrateText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.info,
    fontSize: 12,
    lineHeight: 12,
  },
}));
