import React from 'react';
import {observer} from 'mobx-react-lite';
import Modal from '../modals/BaseModal';
import QuickStartScreenView, {
  QuickStartScreenViewProps,
} from './QuickStartScreenView';
import {StyleSheet} from 'react-native';

export type QuickStartModalScreenProps = QuickStartScreenViewProps & {
  onModalClosed: () => void;
};

export default observer(function QuickStartModalScreen(
  props: QuickStartModalScreenProps,
) {
  const {onModalClosed, ...rest} = props;
  return (
    <Modal contentStyle={styles.modal} onDismiss={onModalClosed}>
      <QuickStartScreenView {...rest} />
    </Modal>
  );
});

const styles = StyleSheet.create({
  modal: {
    padding: 0,
  },
});
