import {Platform} from 'react-native';
import AndroidPurchaseRequesterImpl from './AndroidPurchaseRequesterImpl';
import {ApiStore} from '../ApiStore';
import {InAppOffers} from '../InAppOffersService';
import {InAppPurchaseManager} from '../InAppPurchaseManager';
import {ErrorRepository} from '../ErrorRepository';
import {IapPurchaseRequester} from './PurchaseRequester';
import IosPurchaseRequesterImpl from './IosPurchaseRequesterImpl';
import SubPurchaseRequesterImpl from './SubPurchaseRequesterImpl';
import {PendingPurchasesResolver} from '../PendingPurchasesResolver';
import {Auth} from '../Auth';
import {PurchaseDiscount} from '../PurchasePromoService';
import {ConnectedClient} from '../ContextClient';
import {Configuration} from '../Configuration';
import {UtmUrlListener} from '../UtmUrlService';
import {GiftState} from '../Gift/Gift';

export default class PurchaseRequesterFactoryImpl {
  constructor(
    private readonly _root: {
      readonly apiStore: ApiStore;
      readonly connectedClient: ConnectedClient;
      readonly purchaseDiscount: PurchaseDiscount;
      readonly configuration: Configuration;
      readonly auth: Auth;
      readonly inAppOffers: InAppOffers;
      readonly inAppPurchaseManager: InAppPurchaseManager;
      readonly pendingPurchasesResolver: PendingPurchasesResolver;
      readonly errorRepository: ErrorRepository;
      readonly utmUrl: UtmUrlListener;
      readonly gift: GiftState;
    },
  ) {}

  create() {
    return Platform.select<IapPurchaseRequester>({
      android: new AndroidPurchaseRequesterImpl(this._root),
      ios: new IosPurchaseRequesterImpl(this._root),
      default: new SubPurchaseRequesterImpl(this._root),
    });
  }
}
