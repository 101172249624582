import {ReadonlyDeep} from 'type-fest';
import {WorkerEntity} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import isWorkerOffline from '../checkers/isWorkerOffline';
import {WorkerStateRegistry} from '../../WorkerStateRegistry';

export default function getTotalOfflineWorkers(
  workers: readonly ReadonlyDeep<WorkerEntity>[],
  workerStateRegistry: WorkerStateRegistry,
): number {
  return workers.filter((_) =>
    isWorkerOffline(workerStateRegistry.states.get(_.id)?.state),
  ).length;
}
