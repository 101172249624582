import React from 'react';
import {observer} from 'mobx-react-lite';
import {createStylesHook} from '../styling';
import TutorialScreen from './TutorialView';
import {Modal, Platform, TouchableOpacity, View} from 'react-native';
import {TutorialViewProps} from './TutorialViewProps';

export type TutorialModalProps = TutorialViewProps & {
  visible: boolean;
};

export default observer(
  ({visible, onComplete, onClose, uri, ...rest}: TutorialModalProps) => {
    const styles = useStyles();
    return (
      <Modal
        visible={visible}
        animated
        transparent
        animationType={Platform.OS === 'web' ? 'fade' : 'slide'}
        onRequestClose={onClose}>
        {visible && (
          <>
            <View style={styles.root}>
              <TouchableOpacity
                style={styles.overlay}
                onPress={onClose}
                activeOpacity={1}
              />
              <View style={styles.content}>
                <TutorialScreen
                  uri={uri}
                  onClose={onClose}
                  onComplete={onComplete}
                  {...rest}
                />
              </View>
            </View>
          </>
        )}
      </Modal>
    );
  },
);

const useStyles = createStylesHook((theme) => ({
  root: {
    flex: 1,
  },
  content: {
    flex: 1,
    backgroundColor: theme.palette.background,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.chroma(theme.palette.foreground).alpha(0.3).hex(),
  },
}));
