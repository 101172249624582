import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useRoot, useStrings} from '../../../../Root/hooks';
import {AccountType} from '../../../../Auth';
import {Text, View} from 'react-native';
import {PressableOpacity} from '../../../../components';
import {createStylesHook, variance} from '../../../../styling';
import {sized} from '../../../../Svg';
import CoinSvg from '../../../../assets/svg/colored/coin.svg';

export const FARM_INFO_HEIGHT = 40;

export type FarmInfoProps = {
  goToAuth: () => void;
};

export default observer(function FarmInfo(props: FarmInfoProps) {
  const {goToAuth} = props;
  const {auth} = useRoot();
  const strings = useStrings();
  const styles = useStyles();
  const right = useMemo(() => {
    if (auth.accountType === AccountType.Permanent) {
      return (
        <View style={styles.rightView}>
          <CoinIcon style={styles.rightIcon} />
          <Text style={styles.email}>{auth.email}</Text>
        </View>
      );
    }
    return (
      <PressableOpacity onPress={goToAuth}>
        <View style={styles.rightView}>
          <CoinIcon style={styles.rightIcon} />
          <Text style={styles.signIn}>
            {strings['dashboard.farmInfo.signIn']}
          </Text>
        </View>
      </PressableOpacity>
    );
  }, [auth.accountType, auth.email, goToAuth, strings, styles]);
  return (
    <FarmInfoContainer>
      <View>
        <Text>
          <UserId>ID: </UserId>
          <AccountId>{auth.accountId}</AccountId>
        </Text>
      </View>
      {right}
    </FarmInfoContainer>
  );
});

const CoinIcon = sized(CoinSvg, 16);

const FarmInfoContainer = variance(View)((theme) => ({
  root: {
    height: FARM_INFO_HEIGHT,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.border,
    backgroundColor: theme.palette.backdrop,
  },
}));
const UserId = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 13,
    lineHeight: 20,
    letterSpacing: 0.037,
    color: theme.palette.textPrimary,
    marginRight: 3,
  },
}));
const AccountId = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 13,
    lineHeight: 20,
    color: theme.palette.textPrimary,
  },
}));

const useStyles = createStylesHook((theme) => ({
  rightView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightIcon: {
    marginRight: 5,
  },
  email: {
    ...theme.fontByWeight('700'),
    fontSize: 13,
    lineHeight: 20,
    letterSpacing: 0.037,
    color: theme.palette.textPrimary,
  },
  signIn: {
    ...theme.fontByWeight('700'),
    fontSize: 11,
    color: theme.palette.secondary,
    textTransform: 'uppercase',
  },
}));
