import {HeadlessRoot} from './HeadlessRoot';
import {JsonImpl} from '../Json';
import {AnalyticsImpl} from '../Analytics';
import {
  HeadlessMessagingImpl,
  RemoteMessageStoreImpl,
  MessageHandlerImpl,
} from '../Messaging';
import {ConstantHeadlessService} from '../Headless';
import {HeadlessLocalNotificationsFactory} from '../LocalNotifications';
import {HttpFactory} from '../Http';
import {SpecialLocationImpl} from '../SpecialLocation';
import {HeadlessLocationImpl} from '../Location';

export default class HeadlessRootImpl implements HeadlessRoot {
  readonly json = new JsonImpl();
  private readonly _httpFactory = new HttpFactory();
  readonly http = this._httpFactory.create();
  readonly headless = new ConstantHeadlessService(true);
  readonly analytics = new AnalyticsImpl(this);
  readonly remoteMessageStore = new RemoteMessageStoreImpl(this);
  readonly headlessMessaging = new HeadlessMessagingImpl(this);
  readonly location = new HeadlessLocationImpl();
  private readonly _headlessLocalNotificationsFactory =
    new HeadlessLocalNotificationsFactory(this);
  readonly headlessLocalNotifications =
    this._headlessLocalNotificationsFactory.create();
  readonly specialLocation = new SpecialLocationImpl();
  readonly messageHandler = new MessageHandlerImpl(this);
}
