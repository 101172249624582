import {CommonError} from '../ApiStore';
import {DefaultError} from '../JsonRpc';

const isRpcError = (error: any): error is CommonError | DefaultError =>
  typeof error === 'object' &&
  error !== null &&
  typeof error.code === 'number' &&
  typeof error.message === 'string';

export default isRpcError;
