import React, {useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {FlatList} from 'react-native';
import {MinerConfig} from '../ProposalsState/MinerProposalsState';
import {ListProps} from '../PurchaseScreen/PoolMinersTab/PickConfigurationModal';
import {Hashrate} from '../ApiStore';
import ConfigurationListItem from './ConfigurationListItem';

export type ConfigurationsListProps = {
  configurations: MinerConfig[];
  selectedHashrate: Hashrate | undefined;
  onConfigurationPress: (hashrate: Hashrate) => void;
  Header?: React.FC;
  StickyListItem?: React.ReactElement;
  Separator?: React.ComponentType;
  stickyHeaderIndices?: number[];
};
export default observer(function ConfigurationsList(
  props: ConfigurationsListProps,
) {
  const {
    Header,
    StickyListItem,
    stickyHeaderIndices,
    Separator,
    configurations,
    selectedHashrate,
    onConfigurationPress,
  } = props;

  const renderItem: ListProps['renderItem'] = useCallback(
    ({item, index}: {item: MinerConfig; index: number}) => {
      const selected = item.hashrate === selectedHashrate;

      if (index === 0 && StickyListItem) {
        return StickyListItem;
      }

      return (
        <ConfigurationListItem
          configuration={item}
          onPress={() => onConfigurationPress(item.hashrate)}
          selected={selected}
        />
      );
    },
    [selectedHashrate, StickyListItem, onConfigurationPress],
  );

  return (
    <FlatList
      ListHeaderComponent={Header ? <Header /> : null}
      stickyHeaderIndices={stickyHeaderIndices}
      data={configurations}
      ItemSeparatorComponent={Separator}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
    />
  );
});

const keyExtractor: ListProps['keyExtractor'] = (config) =>
  String(config.hashrate);
