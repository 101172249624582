import {action, computed, observable, makeObservable} from 'mobx';
import {
  NavigationContainerRef,
  NavigationState,
} from '@react-navigation/native';
import {NavigationContainerBinding} from './NavigationContainerBinding';
import {NavigationContainer} from './NavigationContainer';
import {Platform} from 'react-native';
import RNBootSplash from 'react-native-bootsplash';
import {RootParamList} from '../Navigation';

export default class NavigationContainerImpl
  implements NavigationContainer, NavigationContainerBinding
{
  @observable.ref private _ref?: NavigationContainerRef<RootParamList>;
  @observable.ref private _state?: NavigationState;
  @observable private _isReady = false;

  constructor() {
    makeObservable(this);
  }

  get ref() {
    return this._ref;
  }

  get isReady() {
    return this._isReady;
  }

  get state() {
    return this._state;
  }

  @computed
  get currentRouteName() {
    if (this.state) {
      return this.state.routeNames[this.state.index];
    }
    return undefined;
  }

  @computed
  get isConfigured() {
    return this._isReady && this._state !== undefined;
  }

  readonly props = {
    onStateChange: action((state?: NavigationState) => {
      this._state = state;
    }),
    onReady: action(() => {
      if (Platform.OS !== 'web') {
        // noinspection JSIgnoredPromiseFromCall
        RNBootSplash.hide();
      }
      this._isReady = true;
    }),
    ref: action((ref: NavigationContainerRef<RootParamList> | null) => {
      this._ref = ref ?? undefined;
    }),
  };
}
