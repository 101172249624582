import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {SetUpWorkerDemoModal} from './modals';
import {WorkerList} from './WorkerList';
import DesktopWebAppHeader from '../DesktopWebAppHeader/DesktopWebAppHeader';
import PuzzleConfirmationModal from '../PoolMinerActivator/PuzzleConfirmationModal';
import {useIsFocused} from '@react-navigation/native';
import useRestoreSortParams from './WorkerList/useRestoreSortParams';
import {useRoot} from '../Root/hooks';
import {variance} from '../styling';
import {View} from 'react-native';
import RegistrationLinkModal from './modals/RegistrationLinkModal';
import {InteractiveTutorialView} from '../InteractiveTutorial';
import Portal from '../Portal';
import {DashboardContext} from './context';
import DemoModeSection from './DemoModeSection';

export type DashboardScreenProps = {
  safelyGoToAboutAffiliate: () => void;
  getAboutAffiliateIsPending: () => boolean;
  safelyGoToPromoAffiliate: () => void;
  getPromoAffiliateIsPending: () => boolean;
  goToPlan: () => void;
  goToActivator: () => void;
  isDemo: boolean;
  goToCopyRegistrationLink: (code: string) => void;
  registrationLinkModalState: RegistrationLinkModalState;
  dismissRegistrationLinkModal: () => void;
};

export type RegistrationLinkModalState =
  | {
      visible: false;
    }
  | {
      visible: true;
      code: string;
    };

export default observer(function DashboardScreen(props: DashboardScreenProps) {
  const isFocused = useIsFocused();
  const {dashboardStore, auth} = useRoot();
  useEffect(() => {
    if (isFocused && auth.isConnected) {
      // noinspection JSIgnoredPromiseFromCall
      dashboardStore.updateBalance();
    }
  }, [auth.isConnected, dashboardStore, isFocused]);
  const restoreParams = useRestoreSortParams();
  useEffect(() => {
    restoreParams();
  }, [restoreParams]);
  const {isDemo, registrationLinkModalState, dismissRegistrationLinkModal} =
    props;

  return (
    <DashboardContext.Provider value={props}>
      <Root>
        <DesktopWebAppHeader />
        {isDemo && <DemoModeSection />}
        <WorkerList />
        <SetUpWorkerDemoModal />

        {isFocused && <PuzzleConfirmationModal />}
        {registrationLinkModalState.visible && (
          <RegistrationLinkModal
            code={registrationLinkModalState.code}
            onDismiss={dismissRegistrationLinkModal}
          />
        )}
        <Portal>
          <InteractiveTutorialView />
        </Portal>
      </Root>
    </DashboardContext.Provider>
  );
});

const Root = variance(View)(() => ({
  root: {
    flex: 1,
  },
}));
