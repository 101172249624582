import {SubscriptionMap, RestoredSubscriptionMap, ApiMode} from '../farmApi';
import {ConnectionTag, DiscountCode, FarmId} from '../ApiStore';
import {Second} from '../Time';

export enum AuthenticationSummary {
  /**
   * **DEBUG ONLY**
   * The Fernet token has been provided by the user
   */
  Custom,
  /**
   * Direct authentication by the link like /authentication/fernet-token
   */
  InternalLink,
  /**
   * The used Fernet token has been loaded from the local key-value storage
   */
  LocalStorage,
  /**
   * The used Fernet token has been requested from the server by the latest record in the local purchase history
   */
  PurchaseHistory,
  /**
   * A freshly generated Fernet token has been requested from the server
   */
  Registration,
  /**
   * The current Fernet token is saved with a new farm registered
   */
  NewFarm,
  /**
   * The used Fernet token has been requested from the server by the successful OAuth access token
   */
  Link,
}

export enum AuthenticationErrorReason {
  /**
   * A registration of the new farm has been failed
   */
  Registration,
  /**
   * A linking of the current farm with the remote ones failed
   */
  Link,
  /**
   * Farm cannot be registered on the Web
   */
  OAuthNeeded,
  /**
   * The API is not reachable due to an old version of the application
   */
  UpdateRequired,
  /**
   * An authentication by purchase history made it possible to log into multiple accounts
   */
  AccountSwitchRequired,
  /**
   * Fernet token cannot be obtained by farm id in the current environment
   */
  InappropriateEnvironment,
}

export interface AuthenticatedBase {
  kind: 'Authenticated';
  fernetToken: string;
}

export interface AuthenticatedByLink extends AuthenticatedBase {
  summary: AuthenticationSummary.Link;
  accessToken: string;
}

export interface AuthenticatedByCommon extends AuthenticatedBase {
  summary: Exclude<AuthenticationSummary, AuthenticationSummary.Link>;
}

export type Authenticated = AuthenticatedByLink | AuthenticatedByCommon;

export interface AuthenticationFailedBase {
  kind: 'AuthenticationFailed';
  raw: unknown;
}

export interface AuthenticationFailedByLink extends AuthenticationFailedBase {
  reason: AuthenticationErrorReason.Link;
  accessToken: string;
}

export interface AuthenticationFailedByRestoring
  extends AuthenticationFailedBase {
  reason: AuthenticationErrorReason.AccountSwitchRequired;
  restoredSubscriptionMap: RestoredSubscriptionMap;
}

export interface AuthenticationFailedByCommon extends AuthenticationFailedBase {
  reason: Exclude<
    AuthenticationErrorReason,
    | AuthenticationErrorReason.Link
    | AuthenticationErrorReason.AccountSwitchRequired
  >;
}

export type AuthenticationFailed =
  | AuthenticationFailedByLink
  | AuthenticationFailedByRestoring
  | AuthenticationFailedByCommon;

export enum AccountType {
  Temporary,
  Permanent,
}

export interface Authorized extends Omit<Authenticated, 'kind'> {
  kind: 'Authorized';
  email?: string;
  accountType: AccountType;
  accountIds: ReadonlySet<FarmId>;
  subscriptionMap: SubscriptionMap;
}

export enum AuthorizationErrorReason {
  Unknown,
  UpdateRequired,
}

export interface AuthorizationFailed extends Omit<Authenticated, 'kind'> {
  kind: 'AuthorizationFailed';
  reason: AuthorizationErrorReason;
  raw: unknown;
}

export interface Connected extends Omit<Authorized, 'kind'> {
  kind: 'Connected';
  accountId: FarmId;
  apiMode: ApiMode;
  tags: Set<ConnectionTag>;
  purchaseTags: string[];
  settings: Record<string, boolean>;
  purchaseDiscount?: {
    code: DiscountCode;
    ttl?: Second;
  };
}

export interface ConnectionFailed extends Omit<Authorized, 'kind'> {
  kind: 'ConnectionFailed';
  raw: unknown;
}

export type AuthState =
  | Authenticated
  | AuthenticationFailed
  | Authorized
  | AuthorizationFailed
  | Connected
  | ConnectionFailed;
