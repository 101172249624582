import {batchDisposers, Service} from '../structure';
import {
  Http,
  REQUEST,
  RequestParams,
  RESPONSE,
  RESPONSE_BODY,
  ResponseBodyParams,
  ResponseParams,
} from '../Http';
import {Log} from './Log';
import FetchRequestLogContent from './FetchRequestLogContent';
import FetchResponseLogContent from './FetchResponseLogContent';
import FetchResponseBodyLogContent from './FetchResponseBodyLogContent';

export default class FetchLogBridgeService implements Service {
  constructor(
    private readonly _root: {readonly http: Http; readonly log: Log},
  ) {}

  private readonly _onRequest = (params: RequestParams) => {
    this._root.log.write(
      new FetchRequestLogContent(
        params.correlationId,
        params.input,
        params.init,
      ),
    );
  };

  private readonly _onResponse = async (params: ResponseParams) => {
    this._root.log.write(
      new FetchResponseLogContent(params.correlationId, params.response),
    );
  };

  private readonly _onResponseBody = async (params: ResponseBodyParams) => {
    this._root.log.write(
      new FetchResponseBodyLogContent(
        params.correlationId,
        params.responseBody,
      ),
    );
  };

  subscribe() {
    return batchDisposers(
      this._root.http.io.listen(REQUEST, this._onRequest),
      this._root.http.io.listen(RESPONSE, this._onResponse),
      this._root.http.io.listen(RESPONSE_BODY, this._onResponseBody),
    );
  }
}
