import {APP_ANALYTICS, define} from '../persistence';

export default abstract class StaticAppAnalyticsHelper {
  static setRecord(key: keyof Record) {
    return getAnalyticsRecord()
      .then((res) => {
        res.success &&
          setAnalyticsRecord({
            ...res.right,
            [key]: true,
          });
      })
      .catch((ignore) => console.warn(JSON.stringify(ignore)));
  }

  static getIsMarked(key: keyof Record): Promise<boolean | null> {
    return getAnalyticsRecord()
      .then((res) => {
        if (!res.success) {
          return null;
        }
        return res.right?.[key] || false;
      })
      .catch((ignore) => {
        console.warn(ignore);
        return null;
      });
  }

  static callIfNotMarked(key: keyof Record, callback: () => void) {
    return StaticAppAnalyticsHelper.getIsMarked(key).then((marked) => {
      if (!marked) {
        StaticAppAnalyticsHelper.setRecord(key);
        callback();
      }
    });
  }
}

type Record = Partial<{
  firstDemoMinerAdded: boolean;
  firstMinerAdded: boolean;
  firstGetMinerId: boolean;
  firstPurchaseMade: boolean;
  firstEventMade: boolean;
}>;

const [getAnalyticsRecord, setAnalyticsRecord] = define<Record>(APP_ANALYTICS);
