import React from 'react';
import {observer} from 'mobx-react-lite';
import RegularCharacteristics from './RegularCharacteristics';
import {View} from 'react-native';
import {variance} from '../../../../../styling';
import PoolMinerCharacteristics from './PoolMinerCharacteristics';
import {
  WorkerEntity,
  WorkerType,
} from '../../../../../../universal/features/api/entity/dashboard/worker/WorkerEntity';
import {ReadonlyDeep} from 'type-fest';

export type CharacteristicsProps = {
  worker: ReadonlyDeep<WorkerEntity>;
  warning: boolean;
  disabled: boolean;
};

export default observer((props: CharacteristicsProps) => {
  const {worker, warning, disabled} = props;
  const workerType = worker.worker_type;
  if (workerType === WorkerType.Regular) {
    return (
      <Wrapper>
        <RegularCharacteristics
          worker={worker}
          disabled={disabled}
          warning={warning}
        />
      </Wrapper>
    );
  } else if (workerType === WorkerType.Pool) {
    return (
      <Wrapper>
        <PoolMinerCharacteristics
          worker={worker}
          warning={warning}
          disabled={disabled}
        />
      </Wrapper>
    );
  }
  return null;
});

const Wrapper = variance(View)((theme) => ({
  root: {
    flex: 13,
    ...theme.mediaQuery({
      1000: {
        paddingRight: 15,
      },
    }),
  },
}));
