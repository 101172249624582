import {useRoot, useStrings} from '../../Root/hooks';

export default () => {
  const {
    translation: {localeTag},
  } = useRoot();
  const strings = useStrings();
  const weekdaysLong = [
    strings['calendar.fullDay.sunday'],
    strings['calendar.fullDay.monday'],
    strings['calendar.fullDay.tuesday'],
    strings['calendar.fullDay.wednesday'],
    strings['calendar.fullDay.thursday'],
    strings['calendar.fullDay.friday'],
    strings['calendar.fullDay.saturday'],
  ];
  const weekdaysShort = [
    strings['calendar.shortDay.sunday'],
    strings['calendar.shortDay.monday'],
    strings['calendar.shortDay.tuesday'],
    strings['calendar.shortDay.wednesday'],
    strings['calendar.shortDay.thursdat'],
    strings['calendar.shortDay.friday'],
    strings['calendar.shortDay.saturday'],
  ];
  const months = [
    strings['calendar.month.january'],
    strings['calendar.month.february'],
    strings['calendar.month.march'],
    strings['calendar.month.april'],
    strings['calendar.month.may'],
    strings['calendar.month.june'],
    strings['calendar.month.july'],
    strings['calendar.month.august'],
    strings['calendar.month.september'],
    strings['calendar.month.october'],
    strings['calendar.month.november'],
    strings['calendar.month.december'],
  ];
  let firstDayOfWeek = 1;
  switch (localeTag) {
    case 'en':
      firstDayOfWeek = 0;
      break;
    default:
      firstDayOfWeek = 1;
  }
  return {
    weekdaysLong,
    weekdaysShort,
    months,
    firstDayOfWeek,
    locale: localeTag,
  };
};
