import React, {useCallback, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTheme, variance} from '../styling';
import {
  View,
  Text,
  StyleSheet,
  Animated,
  Platform,
  ViewProps,
} from 'react-native';
import Svg, {Defs, Mask, Path, Rect} from 'react-native-svg';
import {v4 as uuidV4} from 'uuid';
import {useRoot, useStrings} from '../Root/hooks';
import {MeasureResult, useMeasure} from '../ReactNativeUtil';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  PressableOpacity,
} from '../components';
import {StackElementKey} from './InteractiveTutorial';
import clamp from '../utils/clamp';
import {observable} from 'mobx';
import {sized} from '../Svg';
import Close from '../assets/svg/colorless/close.svg';

const BOTTOM_TAB_KEYS = [
  'TAB_BAR_STATISTICS',
  'TAB_BAR_NOTIFICATIONS',
  'TAB_BAR_MENU',
];

export default observer(function InteractiveTutorial() {
  const {interactiveTutorial} = useRoot();
  const theme = useTheme();
  const [isTranslatingBox] = useState(() => observable.box<boolean>(false));
  const startTranslate = useCallback(
    () => isTranslatingBox.set(true),
    [isTranslatingBox],
  );
  const completeTranslate = useCallback(
    () => isTranslatingBox.set(false),
    [isTranslatingBox],
  );
  const getIsProgress = useCallback(
    () =>
      isTranslatingBox.get() || interactiveTutorial.scrollToElementInProgress,
    [interactiveTutorial, isTranslatingBox],
  );
  if (!interactiveTutorial.isShown) {
    return null;
  }

  if (Platform.OS === 'web') {
    console.warn('Interactive tutorial not optimized for the web');
    return null;
  }

  const {
    accentElement,
    activeStep,
    next,
    prev,
    skip,
    finish,
    activeStepIndex,
    tutorialStack,
  } = interactiveTutorial;
  return (
    <RootView>
      <BackgroundMask />
      <Card
        count={tutorialStack.length}
        activeIndex={activeStepIndex}
        accentElement={accentElement}
        activeStepKey={activeStep.elementKey}
        onNext={next}
        onPrev={prev}
        onSkip={skip}
        onFinish={finish}
        onTranslateStart={startTranslate}
        onTranslateEnd={completeTranslate}
        getTranslating={getIsProgress}
      />
      <PressableOpacity
        style={styles.closeButton}
        onPress={interactiveTutorial.finish}>
        <CloseIcon color={theme.palette.secondary} />
      </PressableOpacity>
    </RootView>
  );
});

const styles = StyleSheet.create({
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 0,
    padding: 20,
    opacity: 0.9,
  },
});

const CloseIcon = sized(Close, 32);

const BackgroundMask = observer(() => {
  const {
    interactiveTutorial,
    appearance: {isDark},
  } = useRoot();
  const {
    window: {width, height},
  } = useTheme();
  const {
    accentElement: el,
    activeStep,
    scrollToElementInProgress,
  } = interactiveTutorial;
  const maskId = uuidV4();
  let extraX = 0;
  let extraY = 0;
  switch (activeStep.elementKey) {
    case 'PROMO':
    case 'ADD_WORKER':
      extraX = 10;
      extraY = 10;
      break;
    case 'BALANCE':
      extraX = 10;
      break;
  }
  return (
    <Svg style={StyleSheet.absoluteFillObject} width="100%" height="100%">
      <Defs>
        <Mask id={maskId} x="0" y="0">
          <Rect
            x="0"
            y="0"
            width={width}
            height={height}
            fill={isDark ? '#a6a6a6' : '#666666'}
          />
          {!scrollToElementInProgress && el?.measure && (
            <Rect
              x={el.measure.pageX - extraX / 2}
              y={el.measure.pageY - extraY / 2}
              width={el.measure.width + extraX}
              height={el.measure.height + extraY}
              fill="#000"
            />
          )}
        </Mask>
      </Defs>
      <Rect
        width="100%"
        height="100%"
        fill="#000000"
        mask={`url(#${maskId})`}
      />
    </Svg>
  );
});

const RootView = variance(View)(() => ({
  root: {
    ...StyleSheet.absoluteFillObject,
  },
}));

type CardProps = {
  accentElement:
    | {measure: MeasureResult | undefined; key: StackElementKey}
    | undefined;
  onNext: () => void;
  onPrev: () => void;
  onSkip: () => void;
  onFinish: () => void;
  count: number;
  activeIndex: number;
  activeStepKey: StackElementKey;
  onTranslateStart: () => void;
  onTranslateEnd: () => void;
  getTranslating: () => boolean;
};

const Card = observer(
  ({
    accentElement,
    onNext,
    onPrev,
    onSkip,
    onFinish,
    count,
    activeIndex,
    activeStepKey,
    onTranslateStart,
    onTranslateEnd,
    getTranslating,
  }: CardProps) => {
    const fadeAnim = useRef(new Animated.Value(0)).current;
    const translateAnim = useRef(new Animated.Value(0)).current;
    const strings = useStrings();
    const cardRef = useRef<View>(null);

    const [getResult, onLayout] = useMeasure(cardRef);

    const {offset, isTopAlignArrow} = useTopOffset(
      accentElement?.measure,
      activeStepKey,
      getResult()?.height ?? 0,
    );
    console.log('offset', offset);

    useEffect(() => {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }).start();
    }, [fadeAnim]);

    useEffect(() => {
      onTranslateStart();
      Animated.timing(translateAnim, {
        toValue: offset,
        duration: 500,
        useNativeDriver: true,
      }).start((result) => {
        if (result.finished) {
          onTranslateEnd();
        }
      });
    }, [onTranslateEnd, onTranslateStart, offset, translateAnim]);

    const animatedStyle = {
      transform: [
        {
          translateY: translateAnim,
        },
      ],
      opacity: fadeAnim,
    };

    const shownFinish = activeIndex + 1 === count;
    const shownSkip = activeIndex === 0;

    const getCardText = useGetCardText(activeStepKey);

    return (
      <ContainerView>
        {accentElement?.measure && (
          <>
            <BottomTabArrow
              activeElement={{
                measure: accentElement.measure,
                key: accentElement.key,
              }}
              activeStepKey={activeStepKey}
              isTopAlignArrow={isTopAlignArrow}
            />
            {!getTranslating() && (
              <ContentArrow
                activeElement={{
                  measure: accentElement.measure,
                  key: accentElement.key,
                }}
                activeStepKey={activeStepKey}
                isTopAlignArrow={isTopAlignArrow}
              />
            )}
          </>
        )}
        <CardContainerView style={[animatedStyle]}>
          <CardRootView ref={cardRef} onLayout={onLayout}>
            <CardContentView>
              <CardText>{getCardText()}</CardText>
            </CardContentView>
            <CardFooterView>
              <FooterLeftView>
                <StepText>
                  <StepText accent>{activeIndex + 1}</StepText> / {count}
                </StepText>
              </FooterLeftView>
              <FooterRightView>
                <FooterButtonGroupView>
                  {shownSkip ? (
                    <PressableOpacity onPress={onSkip}>
                      <Button
                        variant={ButtonVariant.Text}
                        color={ButtonColor.Primary}>
                        {strings['interactiveTutorial.skip']}
                      </Button>
                    </PressableOpacity>
                  ) : (
                    <PressableOpacity onPress={onPrev}>
                      <Button
                        variant={ButtonVariant.Text}
                        color={ButtonColor.Primary}>
                        {strings['interactiveTutorial.previous']}
                      </Button>
                    </PressableOpacity>
                  )}

                  {shownFinish ? (
                    <PressableOpacity onPress={onFinish}>
                      <Button
                        variant={ButtonVariant.Text}
                        color={ButtonColor.Primary}>
                        {strings['interactiveTutorial.finish']}
                      </Button>
                    </PressableOpacity>
                  ) : (
                    <PressableOpacity onPress={onNext}>
                      <Button
                        variant={ButtonVariant.Text}
                        color={ButtonColor.Primary}>
                        {strings['interactiveTutorial.next']}
                      </Button>
                    </PressableOpacity>
                  )}
                </FooterButtonGroupView>
              </FooterRightView>
            </CardFooterView>
          </CardRootView>
        </CardContainerView>
      </ContainerView>
    );
  },
);

type ArrowProps = {
  activeElement: {measure: MeasureResult; key: StackElementKey};
  activeStepKey: StackElementKey;
  isTopAlignArrow: boolean;
};

const ARROW_WIDTH = 18;
const ARROW_HEIGHT = 57;

const ContentArrow = observer(
  ({activeElement, activeStepKey, isTopAlignArrow}: ArrowProps) => {
    if (!activeElement || activeElement.key !== activeStepKey) {
      return null;
    }
    if (isBottomTabKey(activeStepKey)) {
      return null;
    }
    const measure = activeElement.measure;
    const translateY = isTopAlignArrow
      ? measure.pageY - ARROW_HEIGHT - 10
      : measure.pageY + measure.height + 10;
    const Arrow = isTopAlignArrow ? ArrowBottom : ArrowTop;
    return (
      <View
        style={[
          {
            position: 'absolute',
            top: 0,
            left: 0,
          },
          {
            transform: [
              {
                translateX:
                  -ARROW_WIDTH / 2 + measure.pageX + measure.width / 2,
              },
            ],
          },
        ]}>
        <Arrow
          style={{
            transform: [
              {
                translateY: translateY,
              },
            ],
          }}
        />
      </View>
    );
  },
);

const BottomTabArrow = observer(
  ({activeElement, activeStepKey}: ArrowProps) => {
    if (!activeElement || activeElement.key !== activeStepKey) {
      return null;
    }
    if (!isBottomTabKey(activeStepKey)) {
      return null;
    }
    const measure = activeElement.measure;
    return (
      <View
        style={[
          {
            position: 'absolute',
            top: 0,
            left: 0,
          },
          {
            transform: [
              {
                translateX:
                  -ARROW_WIDTH / 2 + measure.pageX + measure.width / 2,
              },
            ],
          },
        ]}>
        <ArrowBottom
          style={{
            transform: [
              {
                translateY: measure.pageY - 70,
              },
            ],
          }}
        />
      </View>
    );
  },
);

const ArrowBottom = observer(({style}: ViewProps) => {
  return (
    <View style={style}>
      <Svg width={ARROW_WIDTH} height={ARROW_HEIGHT} fill="none">
        <Path
          d="m2 46 7 8 7-8M9 2v52"
          stroke="#F7931A"
          strokeWidth={3}
          strokeLinecap="round"
        />
      </Svg>
    </View>
  );
});

const ArrowTop = observer(({style}: ViewProps) => {
  return (
    <View style={style}>
      <Svg
        width={ARROW_WIDTH}
        height={ARROW_HEIGHT}
        viewBox="0 0 18 57"
        fill="none">
        <Path
          d="M2 11L9 3L16 11"
          stroke="#F7931A"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <Path
          d="M9 55V3"
          stroke="#F7931A"
          strokeWidth={3}
          strokeLinecap="round"
        />
      </Svg>
    </View>
  );
});

function useTopOffset(
  measure: MeasureResult | undefined,
  activeStepKey: StackElementKey,
  cardHeight: number,
) {
  const {window} = useTheme();

  if (!measure) {
    return {isTopAlignArrow: true, offset: 100};
  }
  if (BOTTOM_TAB_KEYS.includes(activeStepKey)) {
    return {isTopAlignArrow: false, offset: measure.pageY - cardHeight - 80};
  }

  const {pageY, height} = measure;

  const pageYContentBottom = pageY + height;

  const isTopAlignArrow = pageY + height / 2 > window.height / 2;

  const result = isTopAlignArrow
    ? clamp(
        pageY - cardHeight - ARROW_HEIGHT - 20,
        0,
        window.height - cardHeight,
      )
    : clamp(
        pageYContentBottom + ARROW_HEIGHT + 20,
        0,
        window.height - cardHeight,
      );

  return {offset: result, isTopAlignArrow} as const;
}

function isBottomTabKey(key: StackElementKey) {
  return BOTTOM_TAB_KEYS.includes(key);
}

function useGetCardText(key: StackElementKey) {
  const strings = useStrings();
  return useCallback(() => {
    switch (key) {
      case 'BALANCE':
        return strings['interactiveTutorial.balance'];
      case 'PROMO':
        return strings['interactiveTutorial.affiliate'];
      case 'ADD_WORKER':
        return strings['interactiveTutorial.addWorker'];
      case 'TAB_BAR_NOTIFICATIONS':
        return strings['interactiveTutorial.notifications'];
      case 'TAB_BAR_STATISTICS':
        return strings['interactiveTutorial.statistics'];
      case 'TAB_BAR_MENU':
        return strings['interactiveTutorial.menu'];
      default:
        console.warn(`No translations found for ${key} element`);
        return '-';
    }
  }, [key, strings]);
}

const ContainerView = variance(View)(() => ({
  root: {
    ...StyleSheet.absoluteFillObject,
  },
}));

const CardContainerView = variance(Animated.View)(() => ({
  root: {
    paddingHorizontal: 15,
    flex: 1,
  },
}));

const CardRootView = variance(View)((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    borderWidth: 1,
    borderColor: theme.palette.border,
    borderRadius: 6,
  },
}));

const CardContentView = variance(View)(() => ({
  root: {
    padding: 20,
    paddingBottom: 5,
  },
}));

const CardFooterView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 5,
    paddingBottom: 5,
    paddingTop: 0,
  },
}));

const FooterLeftView = variance(View)(() => ({
  root: {},
}));
const FooterRightView = variance(View)(() => ({
  root: {},
}));
const FooterButtonGroupView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
  },
}));

const CardText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('normal'),
    color: theme.palette.textPrimary,
    fontSize: 16,
    lineHeight: 24,
  },
}));

const StepText = variance(Text)((theme) => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textPrimary,
  },
  accent: {
    ...theme.fontByWeight('bold'),
  },
}));
