import React from 'react';
import {Text, View, ViewProps} from 'react-native';
import BtcSvg from '../assets/svg/colored/btc.svg';
import {observer} from 'mobx-react-lite';
import {sized} from '../Svg';
import {useStyles} from '../styling';

export interface PanelTitleProps extends ViewProps {
  children?: string;
}

export default observer((props: PanelTitleProps) => {
  const {children, style, ...rest} = props;
  const styles = useStyles((theme) => ({
    root: {
      flexDirection: 'row',
      ...theme.mediaQuery({
        1281: {
          alignItems: 'center',
        },
      }),
    },
    title: {
      marginStart: 10,
      flexGrow: 1,
      flexShrink: 1,
      ...theme.fontByWeight('bold'),
      fontSize: 22,
      lineHeight: 30,
      letterSpacing: 0.0628572,
      color: theme.palette.textPrimary,
      ...theme.mediaQuery({
        1281: {
          fontSize: 32,
          lineHeight: 40,
        },
      }),
    },
  }));
  return (
    <View style={[styles.root, style]} {...rest}>
      <BtcIcon />
      <Text style={styles.title}>{children}</Text>
    </View>
  );
});

const BtcIcon = sized(BtcSvg, 28);
