import {CallScheme, Client, DefaultError, NotificationScheme} from '../JsonRpc';
import {ApiStore} from '../ApiStore';
import {Auth} from '../Auth';
import {Either, error, success} from '../fp';
import ContextClientImpl from './ContextClientImpl';

export default class ConnectedClientImpl<
    C extends CallScheme = CallScheme,
    N extends NotificationScheme = NotificationScheme,
  >
  extends ContextClientImpl<C, N>
  implements Client<C, N>
{
  constructor(
    protected readonly _root: {
      readonly apiStore: ApiStore;
      readonly auth: Auth;
    },
  ) {
    super(_root);
  }

  protected _isAppropriateContext(): Either<void, DefaultError> {
    if (this._root.auth.isConnected) {
      return success(undefined);
    }
    return error({
      code: -32096,
      message: 'Not connected',
    });
  }
}
