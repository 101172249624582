export default class ConsistentInterval<T extends number> {
  static safeCreate<T extends number>(from: T, to: T) {
    return new ConsistentInterval(Math.min(from, to), Math.max(from, to));
  }

  static from(array: readonly unknown[]) {
    return new ConsistentInterval(0, array.length);
  }

  private constructor(public readonly from: T, public readonly to: T) {}

  intersect(other: ConsistentInterval<T>): ConsistentInterval<T> | EmptySet {
    if (other.to < this.from) {
      return new EmptySet();
    }
    if (this.to < other.from) {
      return new EmptySet();
    }
    return new ConsistentInterval(
      Math.max(this.from, other.from),
      Math.min(this.to, other.to),
    );
  }

  mapStickingToUpperBound<E>(
    iteratee: (piece: ConsistentInterval<T>, index: number) => E,
    interval: T,
  ): E[] {
    const length = Math.ceil(this.span / interval);
    if (length === 0) {
      return [];
    }
    const baseFrom = (this.to - interval * (length - 1)) as T;
    const result: E[] = [];
    result.push(iteratee(new ConsistentInterval(this.from, baseFrom), 0));
    for (let i = 1; i < length; i++) {
      const from = (baseFrom + (i - 1) * interval) as T;
      const to = (from + interval) as T;
      result.push(iteratee(new ConsistentInterval(from, to), i));
    }
    return result;
  }

  get span(): number {
    return this.to - this.from;
  }
}

export class EmptySet {}
