import {useRoot} from '../../Root/hooks';
import {clamp} from 'lodash';
import formSections from '../form/formSections';
import useSectionStableOrder from './useSectionStableOrder';
import groupsContentAreEqual from '../WorkerList/SectionWorkerList/groupsContentAreEqual';
import {DashboardParams} from '../../Navigation';

export default function useStableSections(params: DashboardParams) {
  const {
    dashboardStore: {dashboardRequest, getWorkerBalanceById},
    currentSubscriptionState,
    workerStateRegistry,
  } = useRoot();
  const groups = dashboardRequest.value?.groups;

  const {subscription} = currentSubscriptionState;
  const slots = subscription?.slots ?? 0;
  const maxSlots = subscription?.maxSlots ?? 0;
  const emptySlotCount = clamp(maxSlots - slots, 0);

  const liveSections = formSections(
    groups,
    params,
    getWorkerBalanceById,
    emptySlotCount,
    workerStateRegistry,
  );

  return useSectionStableOrder(liveSections, groupsContentAreEqual, [
    groups,
    emptySlotCount,
  ]);
}
