import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {useRoot, useStrings} from '../Root/hooks';
import {Picker} from '../components';
import {StyleSheet} from 'react-native';
import DualGraphPanel, {DualGraphPanelProps} from './DualGraphPanel';
import useSatoshiUsdRate from './useSatoshiUsdRate';
import WorkerStatisticsPanelState from './WorkerStatisticsPanelState';
import {useNavigation} from '@react-navigation/native';
import {WorkerStatisticsScreenProps} from '../WorkerStatisticsScreen/WorkerStatisticsScreen';
import {Millisecond} from '../utils/time';
import {ChartViewScope} from '../GraphPanel';
import {getWorkerName} from '../DashboardScreen';
import {WorkerId} from '../ApiStore';

export interface WorkerStatisticsPanelProps
  extends Partial<DualGraphPanelProps> {
  workerId: WorkerId;
  state: WorkerStatisticsPanelState;
}

export default observer(
  ({workerId, state, ...rest}: WorkerStatisticsPanelProps) => {
    const navigation =
      useNavigation<WorkerStatisticsScreenProps['navigation']>();

    const {
      dashboardStore: {dashboardRequest},
    } = useRoot();
    const strings = useStrings();

    const secondaryAxisRatio = useSatoshiUsdRate();

    const onPick = useCallback(
      (id?: number | string) => {
        if (typeof id === 'string') {
          navigation.setParams({workerId: id});
          return state.fetch(id);
        }
      },
      [navigation, state],
    );

    const onFromChange = useCallback(
      (from: Millisecond) => {
        state.onFromChange(from);
        return state.fetch(workerId);
      },
      [state, workerId],
    );
    const onScopeChange = useCallback(
      (scope: ChartViewScope) => {
        state.onScopeChange(scope);
        return state.fetch(workerId);
      },
      [state, workerId],
    );

    const picker = (
      <Picker
        style={styles.picker}
        items={
          dashboardRequest.value?.groups
            .flatMap((_) => _.workers)
            .map((_) => ({
              title: getWorkerName(_),
              id: _.id,
            })) ?? []
        }
        pickedItemId={workerId}
        onPick={onPick}
        closeText={strings['action.cancel']}
      />
    );

    return (
      <DualGraphPanel
        onFromChange={onFromChange}
        from={state.period.from}
        to={state.period.to}
        onScopeChange={onScopeChange}
        scope={state.scope}
        isLoading={state.isLoading}
        graph={state.graph}
        title={strings['statistics.miningAmountTable.title']}
        titleRight={picker}
        fractionDigits={0}
        primaryTitle="Satoshi"
        secondaryTitle="USD"
        secondaryAxisRatio={secondaryAxisRatio}
        secondaryFractionDigits={2}
        usdThreshold={0.05}
        {...rest}
      />
    );
  },
);

const styles = StyleSheet.create({
  picker: {
    width: 160,
  },
});
